<div class="container-fluid" style="height:calc(100vh - 60px);position: relative;">
  <div class="row h-100">
    <div class="map h-100" id="wrapper">
      <google-map #gmapContainer height="100%" width="100%" [center]="center" [zoom]="zoom" [options]="mapStyles"
        (mapClick)="closeAllInfo()">

        <map-marker #marker *ngFor="let a of automats; let i = index"
          [position]="{lat:a.location.coordinates[0],lng:a.location.coordinates[1]}"
          [options]="{draggable:false, icon:getMarkerImage(a.status)}" (mapClick)="openInfoWindow(marker,a,i)">

        </map-marker>
        <map-info-window class="map-window">
          <ng-template class="map-window" [ngIf]="selectedMarker">
            <div class="container map-window">
              <div class="row">
                <ng-template [ngIf]="selectedMarker.image !== null && selectedMarker?.image !== ''"
                  [ngIfElse]="checkModelImage">
                  <div class="col map-image">
                    <img alt="Otomat resmi" src="{{selectedMarker?.image | image: AutomatImagePath}}"
                      style="height:150px" />
                  </div>
                </ng-template>
                <ng-template #checkModelImage>
                  <div class="col map-image"
                    *ngIf="selectedMarker?.automatModel?.image  && selectedMarker?.automatModel?.image !== null && selectedMarker?.automatModel?.image !== ''; else noImage">
                    <img alt="Otomat resmi"
                      src="{{selectedMarker?.automatModel?.image | image: AutomatImagePath}}" style="height:150px" />
                  </div>
                  <ng-template #noImage>
                    <div class="col map-image">
                      <img alt="Otomat resmi" src="../../assets/img/vending-machine.png"
                        style="height:150px" />
                    </div>
                  </ng-template>
                </ng-template>
                <div class="col-6 d-flex">
                  <div class="container my-auto">
                    <div class="row">
                      <strong>{{selectedMarker?.name}}</strong>
                    </div>

                    <div class="row">
                      <p>{{selectedMarker?.description}}</p>
                    </div>

                    <!-- <div *ngIf="!selectedMarker?.isHot" class="row">
                        <span style="color: #26b999;font-size: 15px;font-weight: 700;">
                          {{getAutomatProductsCount(selectedMarkerIndex)}}
                          Ürün / {{ getAutomatProductsTotalPrice(selectedMarkerIndex) | number | commaDotSwapper}} ₺
                        </span>
                    </div> -->
                    <div *ngIf="selectedMarker?.isHot" class="row">
                      <span style="color: #26b999;font-size: 15px;font-weight: 700;">
                        Sıcak Otomat
                      </span>
                    </div>

                    <div class="row my-2">
                      <a (click)="changeAutomatId(selectedMarker?._id)" class="btn btn-sm mx-auto marker-bubble-button">Detaylar</a>
                    </div>

                    <div class="d-flex mx-auto">
                      <div class="row mt-2 justify-content-end">
                        <i [ngClass]="selectedMarker?.isCard ? 'active-icon' : 'disable-icon'"
                          class="far fa-credit-card mx-1"></i>
                        <i [ngClass]="selectedMarker?.isTelemetry ? 'active-icon' : 'disable-icon'"
                          class="fa fa-wifi mx-1"></i>
                        <i [ngClass]="selectedMarker?.isMobil ? 'active-icon' : 'disable-icon'"
                          class="fa fa-mobile mx-1"></i>
                        <i [ngClass]="selectedMarker?.isCoin ? 'active-icon' : 'disable-icon'"
                          class="fa fa-money-bill-alt mx-1"></i>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </map-info-window>

      </google-map>
      <div class="container-fluid" id="map-bubbles">
        <div class="row justify-content-center">

          <div id="gunluk-satislar" class="map-single-bubble">
            <div class="media">

              <div class="align-self-center mr-3">
                <img src="../../assets/img/gunluksaticion.svg" width="70">
              </div>

              <div class="media-body">
                <p class="bubble-header-title">{{'DASHBOARD.DAILY_SALES' | translate}}</p>
                <div class="row m-0">

                  <div class="col pl-0">
                    <p class="bubble-content-number">
                      <a routerLink="/sales">{{dailySale.count === null ? '-' : dailySale.count}}</a>
                    </p>
                    <span class="bubble-content-text">{{'DASHBOARD.PRODUCT' | translate}}</span>
                  </div>

                  <div class="col pr-0">
                    <p class="bubble-content-number">
                      <a routerLink="/sales">{{dailySale.amount === null ? '-' : dailySale.amount | number |
                        commaDotSwapper}}</a>
                    </p>
                    <span class="bubble-content-text" style="font-size: 17px;">₺</span>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div id="toplam-otomat" class="map-single-bubble">
            <div class="media">

              <div class="align-self-center mr-3">
                <img src="../../assets/img/otomaticon.svg" width="53">
              </div>

              <div class="media-body">
                <p class="bubble-header-title">{{'DASHBOARD.TOTAL_VENDING' | translate}}</p>
                <div class="row m-0">

                  <div class="col px-0">
                    <p class="bubble-content-number">
                      <a routerLink="/automats">{{automatCounts === undefined ? '0' : automatCounts}}</a>
                    </p>
                    <span class="bubble-content-text">{{'DASHBOARD.PIECE' | translate}}</span>
                  </div>

                  <div class="col">
                    <div class="row">
                      <div class="col-12 d-inline-flex mb-1">
                        <div class="col px-0">
                          <a>
                            <span class="badge badge-success badge-automat-active">
                              {{automatActiveCount === undefined ? '0' : automatActiveCount}}
                            </span>
                          </a>
                          <a routerLink="/">
                            <span class="col-10 ml-1 px-0 badge-automat-active-text">{{'DASHBOARD.ACTIVE' |
                              translate}}</span>
                          </a>
                        </div>
                      </div>
                      <div class="col-12 d-inline-flex">
                        <div class="col px-0">
                          <a routerLink="/service-care">
                            <span class="badge badge-warning badge-automat-passive">
                              {{automatPassiveCount === undefined ? '0' : automatPassiveCount}}
                            </span>
                          </a>
                          <a routerLink="/service-care">
                            <span class="col-10 ml-1 px-0 badge-automat-passive-text">{{'DASHBOARD.PASSIVE' |
                              translate}}</span>
                          </a>
                        </div>

                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div id="urun-bilgi" class="map-single-bubble">
            <div class="media">

              <div class="align-self-center mr-3">
                <img *ngIf="socketBasket.paymentKind === 'Cash'" src="../../assets/img/money.svg" width="35">
                <img *ngIf="socketBasket.paymentKind === 'Mobile' " src="../../assets/img/mobilodeme-icon.svg"
                  width="35">
                <img *ngIf="socketBasket.paymentKind === 'Credit_Card'" src="../../assets/img/rate_credit-card.svg"
                  width="35">
                <img *ngIf="socketBasket.paymentKind === 'Offline_Card'" src="../../assets/img/rate_offlineCard.svg"
                  width="35">
              </div>

              <div class="media-body">
                <div class="row m-0">
                  <div class="col p-0 pt-1">
                    <span class="socket-product">{{socketBasket.productName}}</span><br>
                    <span class="socket-otomat">{{socketBasket.automatName}}</span><br>
                    <span class="socket-amount">{{socketBasket.totalAmount | number | commaDotSwapper}} ₺</span>
                  </div>
                  <div class="col p-0 text-right">
                    <div class="socket-image">
                      <img *ngIf="socketBasket.image !== '' " class="socket-prodimage"
                        [src]="socketBasket.image | image: ProductImagePath" width="70px">
                      <img *ngIf="socketBasket.image === '' " class="socket-prodimage"
                        src="../../assets/img/gorsel_yok.svg" width="70px">
                      <img class="socket-logo" src="../../assets/img/bigcheck.svg" alt="check" width="30px">
                    </div>
                  </div>

                </div>


              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div style="position: absolute;top:0px;right:20px;width: 280px;transform:scale(0.8);">
    <div class="container mt-4 " style="background-color: white; border-radius: 20px;min-height: 225px;">
      <div class="py-3">
        <div style="position: relative;height: 175px;">
          <div class="container h-100" style="min-height: 175px" [ngClass]="{'chartcollapsed': !stockChart}">
            <div class="row stok-orani">
              <div class="col stok-orani-arrows">
                <i class="fas fa-angle-left"></i>
              </div>
              <div class="col-8 px-0 align-self-center">
                {{'DASHBOARD.PRODUCT_STOCK_RATE' | translate}}
              </div>
              <div class="col stok-orani-arrows">
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
            <div class="row h-100 d-flex">
              <div class="chartdiv text-up mx-auto" id="stok-orani-chart" style="font-size: 85% !important;"></div>
            </div>
          </div>
          <ng-template [ngIf]="!stockChart" [ngTemplateOutlet]="loadingRef"></ng-template>
        </div>

      </div>
    </div>

    <div class="container mt-4 " style="background-color: white;border-radius: 20px;min-height: 225px;">
      <div class="py-3">
        <div style="position: relative;height: 225px;">
          <div class="container h-100" style="min-height: 225px" [ngClass]="{'chartcollapsed': !saleChart}">
            <div class="row mb-2 stok-orani">
              <div class="col px-0 stok-orani-arrows">
                <a (click)="changeMonthlySale(false)">
                  <i class="fas fa-angle-left"></i>
                </a>
              </div>
              <div class="col-8 align-self-center">
                {{ dateMonthName[this.monthIndex] }}, {{ yearIndex }}
              </div>
              <div class="col px-0 stok-orani-arrows">
                <a (click)="changeMonthlySale(true)">
                  <i class="fas fa-angle-right"></i>
                </a>
              </div>
            </div>
            <div class="row justify-content-center">
              <p class="dashboard-small">{{'DASHBOARD.PRODUCT_SALES_THIS_MONTH' | translate}}
                <span class="dashboard-number ml-3">{{ totalSale }} {{'DASHBOARD.PIECE' | translate}}</span>
              </p>
            </div>
            <div class="row d-flex h-100">
              <div class="chartdiv mx-auto" id="satis-orani-chart"></div>
            </div>
          </div>
          <ng-template [ngIf]="!saleChart" [ngTemplateOutlet]="loadingRef"></ng-template>

        </div>

      </div>
    </div>

    <div class="container mt-4" style="background-color: white; border-radius: 20px;min-height: 245px;">
      <div class="py-3" style="height: 245px;position:relative">
        <div *ngIf="popularProductsArray && popularProductsArray.length else loadingRef">
          <div class="container">

            <div class="row mb-2">
              <p class="dashboard-small m-auto">
                {{'DASHBOARD.POPULAR_PRODUCTS_OF_THIS_MONTH' | translate}}: <span
                  class="dashboard-number  ml-3">{{totalPopularProduct}} {{'DASHBOARD.PIECE' | translate}}</span>
              </p>
            </div>
            <div class="product" *ngFor="let popularProduct of popularProductsArray; let i=index">
              <div class="row ">
                <div *ngIf="popularProduct" class="col-md-7 overflow-hidden text-nowrap pl-0"
                  [ngClass]="{'slide-text': popularProduct?.name.length > 20}">
                  {{ popularProduct?.name }}
                </div>
                <div *ngIf="popularProduct" class="col-md text-right">
                  {{popularProduct?.sales }} {{'DASHBOARD.PIECE' | translate}}
                </div>
                <div *ngIf="!popularProduct" class="col-md pl-0">
                  {{'DASHBOARD.NO_PRODUCT' | translate}}
                </div>
                <div *ngIf="!popularProduct" class="col-md text-right">
                  0 Adet
                </div>
              </div>
              <div class="row progress">
                <div *ngIf="popularProduct != null"
                  class="progress-bar progress-bar-striped progress-bar-animated width-fill" role="progressbar"
                  [ngStyle]="{'width': getPopularProductPercent(popularProduct.sales)+'%', 'background-color':barColor(i)}">
                </div>
                <div *ngIf="popularProduct == null" class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar" [ngStyle]="{'width': '1%', 'background-color':barColor(i)}">
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #loadingRef>
  <div class="col chartdiv d-flex flex-column" style="position: absolute; top :35%">
    <div class="loader fadeIn" style="font-size: 30%;">{{'DASHBOARD.LOADING' | translate}}...</div>
  </div>
</ng-template>