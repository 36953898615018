import {FormTemplate} from './formTemplate';
import {PaymentSystems} from './paymentSystems';

export class PaymentSystemsVerifone implements PaymentSystems {
  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }

  _id: string;
  status;
  paymentSystemsFirmId = '';
  paymentSystemsFirmName = '';
  paymentSystemsFirmCode = 5;



  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({})
    ];

    return template;
  }

}
