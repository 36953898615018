<div class="modal-header">
  <h4 class="modal-title">Kart paketine kullanıcı ekle</h4>
  <button class="btn btn-success newbtn"
          data-toggle="modal" data-backdrop="true" data-target="#videoModal">
          <i class="fas fa-video mr-4"></i> Bu işlem Nasıl yapılır ?
  </button>
</div>
<div class="modal-body">
  <div class="container">

    <ul ngbNav #nav="ngbNav" class="nav nav-pills kart-paketi-nav modal-tab-bg mb-3">
      <li ngbNavItem>
        <a ngbNavLink>Toplu Kart Paketi</a>
        <ng-template ngbNavContent>
          <div class="container mt-3">
            <div class="row mb-4 justify-content-center">
              <form class="w-100" #myForm="ngForm">
                <div class="d-flex my-5">
                  <div class="container inputField" (click)="uploader.click()" appDragDrop (fileDropped)="onFileDropped($event)">
                    <div class="row inputFieldRow">
                      <input #pickXMLFile type="file"
                                                (change)="onFileChange($event)"
                                                #uploader
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                hidden/>
                      <div class="col">
                        <img src="../../../assets/img/file.svg" alt="excel">
                      </div>
                      <div class="col mt-auto">
                        <h1 class="descriptipn">Excel Dosyası seçin</h1>
                        <p class="descriptipnAlert">{{fileName ? fileName:'Seçilmiş Dosya yok'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <button class="btn btn-outline-dark mx-1 px-4 text-center" (click)="downloadSampleExcel()">
                      <i class="fas fa-download mr-4"></i> Örnek XLS Dosyası
                    </button>
                  </div>
                  <div class="mr-1">
                    <button #clear (click)="closeModal(myForm)" class="btn btn-outline-dark px-3 text-center">İptal</button>
                  </div>
                  <div class="ml-1">
                    <button class="btn btn-dark mx-1 px-4 text-right" (click)="sendToDatabase()">
                      Kaydet
                    </button>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Tek</a>
        <ng-template ngbNavContent>
          <div class="container mt-5">

            <div class="row mt-5 mb-4">
              <div class="col-md-8 offset-2">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.name" #name="ngModel" type="text" name="name"
                       placeholder="Kullanıcı Adı">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.sicilNo" #name="ngModel" type="text" name="sicilNo"
                       placeholder="Sicil Numarası">
              </div>
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.cardId" #name="ngModel" type="text" name="cardId"
                       placeholder="Kart UID (16 tabanlı)" (ngModelChange)="InputIdChange($event,true)">
              </div>
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.cardIdDec" #name="ngModel" type="text" name="cardIdDec"
                       placeholder="Kart UID (10 tabanlı)" (ngModelChange)="InputIdChange($event,false)">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.gorev" #name="ngModel" type="text" name="gorev"
                       placeholder="Görev">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.birim" #name="ngModel" type="text" name="birim"
                       placeholder="Birim">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="input-group col-md-6">
                <input class="form-control no-border metin-girisi text-center" (keydown)="validatePrice($event)"
                       [(ngModel)]="offlineUser.firmBalance" #name="ngModel" type="number" name="firmBalance"
                       placeholder="0.00" min="0">
                <div class="input-group-append">
                  <span class="input-group-text no-border metin-girisi">₺</span>
                </div>

              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation1" #name="ngModel" type="text" name="explanation1"
                       placeholder="Açıklama 1">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation2" #name="ngModel" type="text" name="explanation2"
                       placeholder="Açıklama 2">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation3" #name="ngModel" type="text" name="explanation3"
                       placeholder="Açıklama 3">
              </div>
            </div>


            <div class="row mt-2 mb-4 justify-content-end">
              <button class="btn btn-primary" (click)="createOfflineUser()">Kaydet</button>
            </div>

          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>


  </div>
</div>

<div id="videoModal" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
        <iframe width="900" height="415"
                src="https://www.youtube.com/embed/Awtpq4A8ZdI?autoplay=0&loop=0&controls=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
    </div>
  </div>
</div>
