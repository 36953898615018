import {Component, OnInit} from '@angular/core';
import {NotificationFirm} from '../_models/notificationfirm';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  Notifications: NotificationFirm[];
  listfilter: string[] = ['aqua', 'yellow', 'red'];
  notificationTypes: any[] = [{name: 'Bilgilendirme', value: 'aqua'}, {name: 'Otomat', value: 'yellow'}, {name: 'Uyarı', value: 'red'}];
  DiffTimes = new Array<{
    day: number,
    hour: number,
    minute: number,
  }>();

  constructor() {
  }

  ngOnInit() {
    this.Notifications = [
      {
        _id: '',
        type: 'aqua',
        createdAt: new Date(2020, 2, 12, 10, 30, 0).valueOf(),
        message: 'Bildirim4',
        isRead: false,
        firmId: '',
        url: 'bildirim4.url',
      },
      {
        _id: '',
        type: 'yellow',
        createdAt: new Date(2020, 2, 12, 9, 0, 0).valueOf(),
        message: 'Bildirim3',
        isRead: false,
        firmId: '',
        url: 'bildirim3.url',
      },
      {
        _id: '',
        type: 'aqua',
        createdAt: new Date(2020, 2, 1, 12, 30, 0).valueOf(),
        message: 'Bildirim2',
        isRead: true,
        firmId: '',
        url: 'bildirim2.url',
      },
      {
        _id: '',
        type: 'red',
        createdAt: new Date(2019, 12, 31, 12, 50, 10).valueOf(),
        message: 'Bildirim1',
        isRead: true,
        firmId: '',
        url: 'bildirim1.url',
      },
    ];

    this.Notifications.forEach(data => {
      const diff = Math.abs(Date.now() - data.createdAt);
      this.DiffTimes.push({
        day: Math.ceil(diff / (1000 * 60 * 60 * 24)),
        hour: Math.ceil(diff / (1000 * 60 * 60)),
        minute: Math.ceil(diff / (1000 * 60))
      });
    });
  }


  markNotification(notification: NotificationFirm, read: boolean) {
    notification.isRead = read;
  }

  deleteNotification(notification: NotificationFirm, index: number) {
    this.Notifications.splice(index, 1);
  }

  navigateNotification(notification: NotificationFirm) {
    this.markNotification(notification, true);
  }

  listFilterChange(filter: string) {
    if (this.listfilter.includes(filter)) {
      this.listfilter = this.listfilter.filter(e => e !== filter);
    } else {
      this.listfilter.push(filter);
    }

  }
}
