export class ProductBrand {
  createdAt: string;
  name: string;
  status: number;
  updatedAt: string;
  _id: string;

  constructor() {
    this._id = '';
  }
}
