import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {StaffKindService} from '../_services/staff-kind.service';
import {StaffKind} from '../_models/staffKind';
import {Staff} from '../_models/staff';
import {StaffService} from '../_services/staff.service';
import {ImageService} from '../_services/image.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-personels',
  templateUrl: './personels.component.html',
  styleUrls: ['./personels.component.css']
})
export class PersonelsComponent implements OnInit, AfterViewInit {

  public page = 1;
  public pages = 1;
  public staff: Staff = new Staff();
  public staffKinds: [StaffKind];
  public staffs: Array<Staff>;
  StaffImagePath: string;

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)

  @ViewChild('editModal', {static: false}) editModal: NgbModal;

  swalOptions = new SwalHelper();

  // Resim ekleme değişkenleri
  cropInput: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;

  // END : Resim ekleme değişkenleri

  isEdit = false;

  constructor(private staffKindService: StaffKindService,
              private staffService: StaffService,
              private router: Router,
              private imageService: ImageService,
              private loadingSpinner: NgxSpinnerService,
              private modalService: NgbModal) {

    this.StaffImagePath = environment.StaffImagePath;
  }


  ngOnInit() {

    this.staffKindService.getStaffKinds().subscribe(Response => {
      this.staffKinds = Response;
    });

    this.getStaffs();
  }

  ngAfterViewInit() {
  }

  onScroll() {
    this.page = this.page + 1;
    this.addScrollStaffs();
  }

  convertToFile(base64Str: string, fileName: string) {

    const binary = atob(base64Str.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new File([new Uint8Array(array)], fileName, {type: 'image/jpeg', lastModified: Date.now()});
  }

  getStaffs() {
    this.page = 1;
    this.staffService.getStaffs(this.page).subscribe(Response => {
      this.staffs = Response.data.docs;
      this.pages = Response.data.pages;
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Personel listesi sunucudan getirilirken hata oluştu!'
      });
    });
  }

  addScrollStaffs() {
    this.loadingSpinner.show();
    this.staffService.getStaffs(this.page).subscribe(Response => {
      for (const item of Response.data.docs) {
        this.staffs.push(item);
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Personellerin devamı yüklenemedi!'
      });
    });
  }

  addStaff() {
    if (!this.checkIsFieldsOkay()) {
      return;
    }
    this.loadingSpinner.show();
    this.staffService.addStaff(this.staff).subscribe(Response => {
      if (this.croppedImage && this.croppedImage !== '') {
        let fileName = this.cropInput[0].name;
        fileName = fileName.split('.');
        const formData = new FormData();
        const ImageFile = this.convertToFile(this.croppedImage,
          fileName[0] + '_' + Response._id + '.' + fileName[1]);
        formData.append('upfile', ImageFile);
        this.imageService.addStaffImage(formData, Response._id).subscribe(res => {
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Personel başarıyla oluşturuldu!'
          });
          this.modalService.dismissAll();
          this.getStaffs();
        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Personel resmi yüklenirken hata oluştu!'
          });
        });
        this.loadingSpinner.hide();
      } else {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Personel resimsiz şekilde başarıyla oluşturuldu!'
        });
        this.modalService.dismissAll();
        this.getStaffs();
      }
    }, error => {

      this.loadingSpinner.hide();
      if (error.status === 500) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kullanıcı Adı Zaten Mevcut!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Personel oluşturulamadı!'
        });
      }
    });
  }

  setStaff(staff) {
    this.isEdit = true;
    this.staff = Object.assign({}, staff);
    this.openModal();
  }

  deleteStaff(id, index) {

    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Bu personeli silmek istediğinize emin misiniz?'
    }).then((result) => {
      if (result.value) {
        this.loadingSpinner.show();
        this.staffService.deleteStaff(id).subscribe(res => {
          if (res.success) {
            this.staffs.splice(index, 1);
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Personel başarıyla silindi!'
            });
          } else {
            if (res.code === 200) {
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Personel sorumlu olduğu otomatlardan çıkarılmalı'
              });
            } else {
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Personel silme işlemi başarısız!'
              });
            }

          }
          this.loadingSpinner.hide();
        }, err => {
          if (err.error.code === 200) {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Personel sorumlu olduğu otomatlardan çıkarılmalı'
            });
          } else {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Personel silme işlemi başarısız!'
            });
          }
          this.loadingSpinner.hide();
        });
      }

    });

  }

  clearStaff() {
    this.staff = new Staff();
    this.staff.staffKind = new StaffKind();
    this.staff.staffKind._id = '-1';
  }

  openModal() {
    const modalRef = this.modalService.open(this.editModal, {centered: true});
    modalRef.result.then(() => {
      this.resetModal();
    }, () => {
      this.resetModal();
    });
  }

  openNewModal() {
    this.isEdit = false;
    this.clearStaff();
    this.openModal();
  }

  resetModal() {
    this.resetImageCrop();
    this.clearStaff();
  }

  resetImageCrop() {
    // Reset image croppers
    /*this.cropInput.nativeElement.value = '';*/
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.cropperReady = false;
    // END : Reset image croppers
  }

  updateStaff() {
    if (!this.checkIsFieldsOkay()) {
      return;
    }
    this.loadingSpinner.show();
    this.staffService.updateStaff(this.staff, this.staff._id).subscribe(Response => {
      if (this.croppedImage && this.croppedImage !== '') {
        let fileName = this.cropInput[0].name;
        fileName = fileName.split('.');
        const formData = new FormData();
        const ImageFile = this.convertToFile(this.croppedImage,
          fileName[0] + '_' + this.staff._id + '.' + fileName[1]);
        formData.append('upfile', ImageFile);
        this.imageService.addStaffImage(formData, this.staff._id).subscribe(res => {
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Personel başarıyla güncellendi!'
          });
          this.modalService.dismissAll();
          this.getStaffs();
        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Personel resmi güncellenirken sorun oluştu!'
          });
        });
        this.loadingSpinner.hide();
      } else {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Personel resimsiz şekilde başarıyla güncellendi!'
        });
        this.modalService.dismissAll();
        this.getStaffs();
      }
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Personel güncellenemedi!'
      });
    });
  }

  checkIsFieldsOkay(): boolean {
    let isOkay = true;
    if (this.staff.name === '' || this.staff.name === undefined) {
      isOkay = false;
    }
    if (this.staff.phone === '' || this.staff.phone === undefined) {
      isOkay = false;
    }
    if (this.staff.email === '' || this.staff.email === undefined) {
      isOkay = false;
    }
    if (this.staff.staffKind._id === '-1') {
      isOkay = false;
    }

    if (!isOkay) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen tüm alanları doldurunuz!'
      });
      return false;
    } else {
      return true;
    }
  }

  // Image crop functions
  fileChangeEvent(event: any): void {
    this.cropInput = event.target.files;
    this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageLoadFailed() {
    Swal.fire({
      ...this.swalOptions.error,
      text: 'Personel listesi sunucudan getirilirken hata oluştu!'
    });
  }

  // END : Image crop functions
}
