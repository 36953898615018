import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginationTableListComponent} from './pagination-table-list.component';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {PipesModule} from '../../_pipes/pipes.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [PaginationTableListComponent],
  exports: [PaginationTableListComponent],
  imports: [
    CommonModule,
    NgbPaginationModule,
    NgbAlertModule,
    PipesModule,
    FormsModule
  ]
})
export class PaginationTableListModule {
}
