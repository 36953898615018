export class OfflineUser {
  name: string;
  cardId: string;
  cardIdDec: string;
  sicilNo: string;
  gorev: string;
  birim: string;
  balance: number;
  firmBalance: number;
  offlineUserGroups: Array<string>;
  automatGroup: Array<any>;
  explanation1: string;
  explanation2: string;
  explanation3: string;
  status: number;

  constructor() {
    this.name = '';
    this.cardId = '';
    this.cardIdDec = '';
    this.sicilNo = '';
    this.gorev = '';
    this.birim = '';
    this.balance = 0;
    this.firmBalance = 0;
    this.offlineUserGroups = [];
    this.automatGroup = [];
    this.explanation1 = '';
    this.explanation2 = '';
    this.explanation3 = '';
    this.status = 1;
  }
}
