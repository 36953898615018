import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MarkerAutomat} from '../../_models/markerAutomat';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-list-view-automats',
  templateUrl: './list-view-automats.component.html',
  styleUrls: ['./list-view-automats.component.css']
})
export class ListViewAutomatsComponent implements OnInit {

  animatedtextIndex = -1;
  AutomatImagePath = environment.AutomatImagePath;

  //Otomat aktiflik durumu değişkenleri
  onlineAutomat = '../../assets/img/aktif-otomat.svg';
  offlineAutomat = '../../assets/img/otomat-arizali.svg';
  status: boolean;
  statusDesc: string;
  whenOnline: Number;
  oneMinute = 60000;
  oneHour = 3600000;
  oneDay = 86400000;
  //END : Otomat aktiflik durumu değişkenleri

  automats: Array<MarkerAutomat>;
  automatsFiltered: Array<MarkerAutomat>;
  automatStats = Array<{ price: number, stock: number }>();

  @Input()
  set automatList(data: Array<MarkerAutomat>) {
    this.automats = data || [];
  }

  @Input()
  set automatListFiltered(data: Array<MarkerAutomat>) {
    this.automatsFiltered = data || [];
  }

  @Input()
  set automatListStats(data: Array<{ price: number, stock: number }>) {
    this.automatStats = data || [];
  }

  @Output() submit = new EventEmitter<{ success: boolean, message: string, data: any }>();


  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  getAutomatStatus(automatStatus: string): boolean {
  
    const current = new Date();
    const currentDate = new Date().getTime();
    const timeOffset = new Date().getTimezoneOffset();
    const automatDate = new Date(automatStatus.slice(0, 19)).getTime();
    const difference = currentDate - automatDate + (timeOffset * 60000);
    if (difference <= environment.WaitingTimeLimit) {
      this.status = true;
      this.statusDesc = '';
    } else {
      this.status = false;
      if (difference <= this.oneHour) {
        this.whenOnline = Math.floor(difference / this.oneMinute);
        this.statusDesc = 'En son ' + this.whenOnline + ' dakika önce aktifti';
      } else if (difference > this.oneHour && difference <= this.oneDay) {
        this.whenOnline = Math.floor(difference / this.oneHour);
        this.statusDesc = 'En son ' + this.whenOnline + ' saat önce aktifti.';

      } else if (difference > this.oneDay) {
        this.whenOnline = Math.floor(difference / this.oneDay);
        this.statusDesc = 'En son ' + this.whenOnline + ' gün önce aktifti.';
      }
    }
    return this.status;
  }

  slideText(enter, index?) {
    if (enter) {
      this.animatedtextIndex = index;
    } else {
      this.animatedtextIndex = -1;
    }
  }

  viewAutomatInfo(automat, index) {
    this.submit.emit({success: true, message: null, data: {automat: automat, index: index}});
  }

  goToAutomatDetail(automatId: string) {
    this.router.navigate(['/automat-detail', automatId]);
  }
}
