
<div class="container-fluid top-bg pt-4 pb-3">
  <div class="row animated fadeInDown">
    <div class="col-md-2 col-lg-2 col-xl-2 align-self-center">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" #dropdownFilter type="button" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'COLLECTION.APPLY_FILTER' | translate}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownFilter">
          <form>
            <div class="container p-4">
              <div class="row">
                <div class="col">
                  <select [(ngModel)]="filter.group" name="group" (change)="getAutomatNameID()" class="custom-select select-product-modal select-custom-icon">
                    <option value="">{{'COLLECTION.VENDING_GRUOP' | translate}}</option>
                    <option
                      *ngFor="let automatGroup of automatGroups;"
                      value="{{ automatGroup._id }}">{{automatGroup.name}}</option>
                  </select>
                  <select [(ngModel)]="filter.automat" name="nameID" class="custom-select select-product-modal select-custom-icon mt-4">
                    <option value="">{{'COLLECTION.VENDING_NAME_ID' | translate}}</option>
                    <option
                      *ngFor="let automatNameID of automatNameIDs;"
                      value="{{ automatNameID._id }}">{{automatNameID.name}}</option>
                  </select>
                  <select [(ngModel)]="filter.paymentType" name="paymentType" class="custom-select select-product-modal select-custom-icon mt-4">
                    <option value="-1">{{'COLLECTION.PAYMENT_METHOD' | translate}}</option>
                    <option value="1">{{'COLLECTION.CREDIT_CART' | translate}}</option>
                    <option value="2">{{'COLLECTION.CASH' | translate}}</option>
                    <option value="3">{{'COLLECTION.PRE_PAID_CARD' | translate}}</option>
                    <option value="5">{{'COLLECTION.QR' | translate}}</option>
                  </select>
                  <!--<input type="text" ngxDaterangepickerMd [locale]="{applyLabel: 'uygula'}" [(ngModel)]="selected" name="selected"
                         placeholder="Tarih aralığı seçiniz" [locale]="locale" (change)="getDateForProceedsFilter()"
                         class="custom-select select-product-modal select-custom-icon mt-4"/>-->
                  <button class="btn btn-success mt-5" (click)="applyFilter()" style="width: 100%">{{'COLLECTION.APPLY_FILTER_CAPS' | translate}}</button>
                  <button class="btn btn-danger mt-2" (click)="resetFilter()" style="width: 100%">{{'COLLECTION.RESET_FILTER' | translate}}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-lg-8 col-xl-8 text-center">
      <span class="title ml-2">{{'COLLECTION.COMPANY_GENERAL_COLLECTION_TABLE' | translate}}</span>
      <!-- undefined olan timezone -->
    </div>

    <div class="col-md-2 col-lg-2 col-xl-2 text-right">
      <div class="container">
        <div class="row justify-content-end text-center">
          <button class="btn btn-success mr-1" (click)="downloadExcel()">{{'COLLECTION.EXCEL' | translate}}</button>
          <!--<div (click)="writeConsole('Sol Tık')" id="left-arrow"></div>
          <div (click)="writeConsole('Sağ Tık')" id="right-arrow"></div>
          <select [(ngModel)]="selectedDay" class="custom-select select-filter select-day-icon">
            <option
              *ngFor="let day of days; let i = index"
              value="{{ i }}">{{day}}</option>
          </select>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th scope="col" class="table-header">{{'COLLECTION.VENDING' | translate}}</th>
      <th scope="col" class="table-header">{{'COLLECTION.GROUP' | translate}}</th>

      <th scope="col" class="table-header">{{'COLLECTION.GIRO' | translate}}</th>
      <th scope="col" class="table-header">{{'COLLECTION.ADVANCE_PAYMENT' | translate}}</th>

      <th scope="col" class="table-header">{{'COLLECTION.STAFF' | translate}}</th>

      <th scope="col" class="table-header">{{'COLLECTION.START' | translate}}</th>
      <th scope="col" class="table-header">{{'COLLECTION.COLLECTION' | translate}}</th>

      <th scope="col" class="table-header">{{'COLLECTION.DETAIL' | translate}}</th>
    </tr>
    </thead>

    <!-- infiniteScrollThrottle milisaniye cinsinden bir değer -->
    <tbody
      infiniteScroll
      [infiniteScrollDistance]="pages"
      [infiniteScrollThrottle]="20"
      (scrolled)="onScroll()"
      id="table-datas">
    <tr *ngFor="let data of rows">
      <td *ngIf="data.automat !== null" style="text-align: left">{{data.automat?.name}}</td>
      <td *ngIf="data.automat === null" style="text-align: left">{{'COLLECTION.DELETED_VENDING' | translate}}</td>
      <td *ngIf="data.automatGroup !== null">{{data.automatGroup?.name}}</td>
      <td *ngIf="data.automatGroup === null">{{'COLLECTION.DELETED_GROUP' | translate}}</td>

      <td>{{data.totalPrice | number | commaDotSwapper}}₺</td>
      <td>{{data.headStart | number | commaDotSwapper}}₺</td>

      <td>
        {{
          data.closedTelemetryUser && data.closedTelemetryUser !== '' ? data.closedTelemetryUser?.name :
          (data.closedPersonel && data.closedPersonel !== '' ? data.closedPersonel?.name : '')
        }}
      </td>

      <td>{{data.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
      <td>{{data.endedAt | date: 'dd.MM.yyyy HH:mm:ss'}}</td>

      <td>
        <button class="btn btn-outline-primary" data-toggle="modal" (click)="getEndOfAutomatSaleHistory(data._id)"
                data-backdrop="true" data-target="#tahsilatIncele">{{'COLLECTION.REVIEW' | translate}}</button>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<div class="container mb-2" id="page-loader" style="display: none">
  <div class="row justify-content-center">
    <img src="../../assets/img/page-loader.svg" width="80">
  </div>
</div>

<a (click)="topFunction()" id="topButton" title="En yukarı çık">
  <img src="../../assets/img/up-arrow.svg" width="36">
</a>

<!-- Tahsilat İncele -->
<div class="modal fade" id="tahsilatIncele" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="container">
          <div class="row justify-content-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row modal-baslik justify-content-center">
            {{'COLLECTION.COLLECTION_DETAIL' | translate}}
          </div>
          <div class="row">
            <div class="col-md animated fadeInDown">
              <div class="row">
                <div class="col-md-4">
                  <strong><p class="ml-2">{{'COLLECTION.VENDING' | translate}}: {{selectedEOASH?.automat?.name}}</p></strong>
                </div>
                <div class="col-md-4">
                  <strong><p class="ml-2">{{'COLLECTION.GROUP' | translate}}: {{selectedEOASH?.automatGroup?.name}}</p></strong>
                </div>
                <div class="col-md-4">
                  <strong><p class="ml-2">{{'COLLECTION.DATE' | translate}}: {{selectedEOASH?.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}} - {{selectedEOASH?.endedAt | date: 'dd.MM.yyyy HH:mm:ss'}}</p></strong>
                </div>
              </div>
              <hr>
              <div class="row mt-4">
                <div class="col-md-3 text-left">
                  <strong>{{'COLLECTION.ADVANCE_PAYMENT' | translate}}: </strong><a class="price-type">{{selectedEOASH?.headStart | number | commaDotSwapper}}₺</a>
                </div>
                <div class="col-md-3 text-center">
                  <strong>{{'COLLECTION.ADVANCE_PAYMENT' | translate}} ({{'COLLECTION.METALLIC' | translate}}): </strong><a class="price-type">{{selectedEOASH?.filledCoins | number | commaDotSwapper}}₺</a>
                </div>
                <div class="col-md-3 text-center">
                  <strong>{{'COLLECTION.ADVANCE_PAYMENT' | translate}} ({{'COLLECTION.PAPER' | translate}}): </strong><a class="price-type">{{selectedEOASH?.filledBanknote | number | commaDotSwapper}}₺</a>
                </div>
                <div class="col-md-3 text-right">
                  <strong>{{'COLLECTION.TOTAL_GIRO' | translate}}: </strong><a class="price-type">{{selectedEOASH?.totalPrice | number | commaDotSwapper}}₺</a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 border container-height">
                  <div *ngIf="selectedEOASH?.payments?.length > 0; else noData" class="container">
                    <div *ngFor="let paymentType of selectedEOASH?.payments; let i = index" class="row border-bottom p-2 mb-2">

                      <div class="col-md-4">
                        <a class="number-radius mr-2" [ngStyle]="{'color': textColor(i)[0],'background-color': textColor(i)[1] }"> {{i + 1}} </a>{{paymentType.paymentName}}
                      </div>
                      <div class="col-md-8">
                        <a class="subtext-type">Ciro</a><a class="price-type"> {{paymentType.totalPrice | number | commaDotSwapper}} ₺</a>
                      </div>

                    </div>
                  </div>
                  <ng-template #noData>

                    <div class="container">
                      <div class="row border-bottom p-2 mb-2">

                        <div class="col-md-4">
                          <a class="number-radius mr-2" [ngStyle]="{'color': textColor(0)[0],'background-color': textColor(0)[1] }"> 1 </a> Nakit
                        </div>
                        <div class="col-md-8">
                          <a class="subtext-type">Ciro</a><a class="price-type"> 0 ₺</a>
                        </div>

                      </div>
                      <div class="row border-bottom p-2 mb-2">

                        <div class="col-md-4">
                          <a class="number-radius mr-2" [ngStyle]="{'color': textColor(1)[0],'background-color': textColor(1)[1] }"> 2 </a> Kredi Kartı
                        </div>
                        <div class="col-md-8">
                          <a class="subtext-type">Ciro</a><a class="price-type"> 0 ₺</a>
                        </div>

                      </div>
                    </div>

                  </ng-template>

                </div>
                <div class="col-md-6 border container-height justify-content-center">
                  <div class="container-fluid">
                    <div class="chartdiv mt-3" id="payDist"></div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <strong> {{'COLLECTION.PERSONNEL_MAKING_COLLECTION' | translate}} </strong>
              </div>
              <div *ngIf="selectedEOASH?.closedTelemetryUser === null" class="row personel-satiri my-2 mt-3 mb-5">
                <div class="col-sm col-md-2 align-self-center">
                  <img *ngIf="selectedEOASH?.closedPersonel?.photo === ''" class="rounded-circle" src="../../assets/img/user_image.png" width="50">
                  <img *ngIf="selectedEOASH?.closedPersonel?.photo !== ''" class="rounded-circle" src="{{selectedEOASH?.closedPersonel?.photo | image: StaffImgPath }}" width="50">
                </div>
                <div class="col-sm-2 align-self-center">
                  <strong>{{selectedEOASH?.closedPersonel?.name}}</strong>
                </div>
                <div class="col-sm-2 align-self-center">
                  <span class="font-weight-light">{{selectedEOASH?.closedPersonel?.staffKind.name}}</span>
                </div>
                <div class="col-sm-2 align-self-center">
                  <strong>{{selectedEOASH?.closedPersonel?.phone}}</strong>
                </div>
                <div class="col-sm-3 align-self-center">
                  <span class="font-weight-light">{{selectedEOASH?.closedPersonel?.email}}</span>
                </div>
              </div>
              <div *ngIf="selectedEOASH?.closedPersonel === null" class="row personel-satiri my-2 mt-3 mb-5">
                <div class="col-sm col-md-2 align-self-center">
                  <img *ngIf="selectedEOASH?.closedTelemetryUser?.photo === ''" class="rounded-circle" src="../../assets/img/user_image.png" width="50">
                  <img *ngIf="selectedEOASH?.closedTelemetryUser?.photo !== ''" class="rounded-circle" src="{{selectedEOASH?.closedTelemetryUser?.photo | image: ProfileImagePath }}" width="50">
                </div>
                <div class="col-sm-4 align-self-center">
                  <strong>{{selectedEOASH?.closedTelemetryUser?.name}}</strong>
                </div>
                <div class="col-sm-6 align-self-center">
                  <span class="font-weight-light">{{selectedEOASH?.closedTelemetryUser?.username}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Tahsilat Incele: END -->


<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
