import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsAutomatComponent} from './notifications-automat.component';
import {PipesModule} from '../../_pipes/pipes.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

const dec_exp = [
  NotificationsAutomatComponent
];

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    InfiniteScrollModule,
  ],
  declarations: [dec_exp],
  exports: [dec_exp]
})
export class NotificationsAutomatModule {
}
