export class OfflineUserGroup {
  _id: string;
  name: string;
  firm: string;
  status: number;
  automatGroup: string[];
  offlineUser: string[];
  balanceTransfer: boolean;
  isPeriod: boolean;
  sendMail: boolean;
  mailAddress: string;
  nextStartDate: string;
  monthlyPeriod: string;
  periodBalance: number;
  updatedAt: string;
  createdAt: string;
  constantDay: string;

  constructor() {

    this.name = null;
    this.firm = null;
    this.status = 1;
    this.balanceTransfer = true;
    this.isPeriod = false;
    this.sendMail = false;
    this.mailAddress = '';
    this.nextStartDate = '';
    this.monthlyPeriod = '0';
    this.periodBalance = null;
    this.updatedAt = '';
    this.constantDay = '';
  }
}
