<div class="container mb-4">
  <div class="row">
    <div class="col-md-12 col-xl-12 col-lg-12 content-header-page">
      <label>Otomat Ödeme Yöntemi</label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-xl-12 col-lg-12">
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-accordion-header btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Otomatlara Yeni Ödeme Yöntemi Belirleme
              </button>
            </h2>
          </div>

          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 col-lg-4 col-xl-4">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div class="automat-payments-stage-header">
                        <label>1. Aşama: Otomat Belirleme</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div class="border automat-payments-stage-content">
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12 mt-3">
                            <select [(ngModel)]="selectedGroup" (change)="onChangeGroup(selectedGroup)" class="custom-select select-product select-custom-icon mb-2">
                              <option value="-1" disabled>Otomat Grubu Seçiniz</option>
                              <option
                                *ngFor="let automatGroup of automatGroups;"
                                value="{{ automatGroup._id }}">{{automatGroup.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div *ngIf="automatList.length > 0 && isReady">
                          <div class="row">
                            <div class="col-md-6 col-lg-6 col-xl-6 mt-3">
                              <label *ngIf="automatList.length > 0">Otomatlar</label>
                            </div>
                            <div *ngIf="checkedAutomatList.length > 0" class="col-md-6 col-lg-6 col-xl-6 mt-2 text-right">
                              <button *ngIf="automatList.length > 0" class="btn btn-link"
                                      (click)="changeCheckedAutomatList(false)">Seçimleri Kaldır</button>
                            </div>
                            <div *ngIf="checkedAutomatList.length <= 0" class="col-md-6 col-lg-6 col-xl-6 mt-2 text-right">
                              <button *ngIf="automatList.length > 0" class="btn btn-link"
                                      (click)="changeCheckedAutomatList(true)">Tümünü Seç</button>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12 col-lg-12 col-xl-12 mt-1 text-center">
                              <div *ngIf="automatList.length > 0" class="automat-checklist-content">
                                <div *ngFor="let automat of automatList" class="border priority-border">
                                  <div class="row">
                                    <div class="col-md-2 col-lg-2 col-xl-2 text-center">
                                      <input id="automatCheckBox" class="automatCheckBox" type="checkbox" aria-label="automat-checkbox"
                                             (change)="onChangeAutomatChecked($event, automat)" checked>
                                    </div>
                                    <div class="col-md-10 col-lg-10 col-xl-10 text-left">
                                      <label>{{automat.name}}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12 col-lg-12 col-xl-12 mt-3 text-center">
                              <button *ngIf="checkedAutomatList.length > 0 && stageTwoButton" class="btn btn-outline-dark" (click)="stageJump(2)">2. Aşamaya Geç</button>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="automatList.length === 0 && isReady">
                          <div class="row" *ngIf="selectedGroup !== '-1'">
                            <div class="col-md-12 col-lg-12 col-xl-12 mt-3 text-center text-danger">
                              <span>Otomat Bulunamadı !</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="!isReady" [ngTemplateOutlet]="dataLoading"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-4">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div class="automat-payments-stage-header">
                        <label>2. Aşama: Ödeme Yöntemi Belirleme</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div *ngIf="paymentsStageTwo" class="border automat-payments-stage-content">
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12 mt-3">
                            <select [(ngModel)]="selectedTemplateId" (change)="onChangeTemplate(selectedTemplateId)"
                                    class="custom-select select-product select-custom-icon mb-2">
                              <option value="-1" disabled>Ödeme Şablonu Seçiniz</option>
                              <option *ngFor="let automatPaymentSystem of automatPaymentSystems"
                                      value="{{automatPaymentSystem._id}}"> {{automatPaymentSystem.name}} </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-xl-6 mt-3">
                            <label *ngIf="isTemplate">Şablon Yapısı</label>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-6 mt-3 text-right">
                            <a *ngIf="isTemplate" style="color: #2fb43f; cursor: pointer;" (click)="reloadPaymentTemplate()">Yenile</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12">
                            <div *ngIf="isTemplate && selectedTemplate?.paymentSystems?.length > 0" class="automat-checklist-content">
                              <div *ngFor="let paymentSystem of selectedTemplate?.paymentSystems" class="border priority-border text-center">
                                <label>{{paymentSystem.paymentSystemsFirmPriority + 1}}. {{paymentSystem.paymentSystemsFirmName}}</label>
                              </div>
                            </div>
                            <div *ngIf="isTemplate && selectedTemplate?.paymentSystems?.length <= 0" class="automat-checklist-content">
                              <div class="text-center mt-5">
                                <label>Şablona ait herhangi bir ödeme yöntemi bulunamadı.
                                  Bu durumda ödeme şablonunun herhangi bir işlevi olmayacaktır.
                                  Bu durumda şablonun tanımlandığı otomatlar sadece nakit satışa açık olacaktır.</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12 mt-3 text-center">
                            <button *ngIf="isTemplate && stageThreeButton" class="btn btn-outline-dark" (click)="stageJump(3)">3. Aşamaya Geç</button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!paymentsStageTwo" class="border automat-payments-stage-content-nonactive">
                        <div class="nonactive-content text-center">
                          <h3>2. Aşama</h3>
                          <h4>Ödeme Yöntemi Belirleme</h4>
                          <p style="color: #696969">Önce 1. Aşamayı Tamamlayınız.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-4">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div class="automat-payments-stage-header">
                        <label>3. Aşama: Onaylama</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-12">
                      <div *ngIf="paymentsStageThree" class="border automat-payments-stage-content">
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12 mt-3 text-center">
                            <label><strong>Dikkat!</strong></label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-11 col-lg-11 col-xl-11 m-1 mt-3 text-center">
                            <label><strong>İşlemi tamamlamak üzeresiniz.</strong></label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 col-lg-12 col-xl-12">
                            <div class="automat-checklist-content text-center">
                              <p>Seçtiğiniz otomatlara, seçtiğiniz ödeme şablonunu atamak üzeresiniz.
                                Yaptığınız seçimleri kontorl edebilir sonrasında onaylayabilir veya iptal edip başa dönebilirsiniz.
                              </p>
                              <p>
                                Bu işlem, otomatlardan kredi kartı ile ödeme alırken, sırayla hangi ödeme yöntemine gideceğini belirler.
                                İlk ödeme yöntemi başarı ile sonuçlandığında diğer ödeme yöntemlerini denemez.
                                Başarısız olduğu taktirde sıradaki ödeme yöntemini deneyecektir.
                              </p>
                              <p>
                                Seçimleri onaylıyor musunuz?
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-xl-6 mt-3 text-center">
                            <button class="btn btn-primary" (click)="stageJump(4)">İptal</button>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-6 mt-3 text-center">
                            <button class="btn btn-outline-dark" (click)="stageJump(5)">Onayla</button>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="!paymentsStageThree" class="border automat-payments-stage-content-nonactive">
                        <div class="nonactive-content text-center">
                          <h3>3. Aşama</h3>
                          <h4>Onaylama</h4>
                          <p style="color: #696969">Önce 2. Aşamayı Tamamlayınız.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button class="btn btn-accordion-header btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Otomatların Ödeme Yöntemlerini Görüntüle
              </button>
            </h2>
          </div>
          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
              <div class="table-responsive" style="height: 520px; overflow: scroll;">
                <table class="table" id="main-table">
                  <thead>
                  <tr>
                    <th scope="col" class="table-header text-center">
                      <div class="gri-yuvarlak-cerceve align-items-center justify-content-center mb-2">
                        Otomat Adı
                      </div>
                    </th>
                    <th scope="col" class="table-header text-center">
                      <select [(ngModel)]="selectedGroupTable" (change)="onChangeGroupTable(selectedGroupTable)" class="custom-select select-product select-custom-icon mb-2">
                        <option value="-1">Otomat Grubu</option>
                        <option
                          *ngFor="let automatGroup of automatGroups;"
                          value="{{ automatGroup._id }}">{{automatGroup.name}}</option>
                      </select>
                    </th>
                    <th scope="col" class="table-header text-center">
                      <select [(ngModel)]="selectedTemplateIdTable" (change)="onChangeTemplateTable(selectedTemplateIdTable)"
                              class="custom-select select-product select-custom-icon mb-2">
                        <option value="-1">Ödeme Şablonu</option>
                        <option *ngFor="let automatPaymentSystem of automatPaymentSystems"
                                value="{{automatPaymentSystem._id}}"> {{automatPaymentSystem.name}} </option>
                      </select>
                    </th>
                    <th *ngIf="checkedTemplateAutomatList.length <= 0 && false" scope="col" class="table-header text-center">
                      <button class="btn btn-link" (click)="changeCheckedTemplateList(true)">Tümünü Seç</button></th>
                    <th *ngIf="checkedTemplateAutomatList.length > 0 && false" scope="col" class="table-header text-center">
                      <button class="btn btn-link" (click)="changeCheckedTemplateList(false)">Seçimleri Kaldır</button></th>
                    <th *ngIf="checkedTemplateAutomatList.length <= 0 && false" scope="col" class="table-header">
                      <button class="btn btn-danger" (click)="changeCheckedTemplateList(false)" disabled>Sil</button></th>
                    <th *ngIf="checkedTemplateAutomatList.length > 0 && false" scope="col" class="table-header">
                      <button  class="btn btn-danger"
                               (click)="deleteAutomatsOnAutomatPaymentSystem()">Sil</button></th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr *ngFor="let automat of automats; let i = index">
                    <td class="text-center">{{automat.name}}</td>
                    <td class="text-center">{{automat.group.name}}</td>
                    <td class="text-center">
                      <button *ngIf="automat.automatPaymentSystemId !== null" class="btn btn-link" data-toggle="modal" data-backdrop="true"
                              data-target="#automatPaymentTemplateModal"
                              (click)="selectAutomatPaymentTemplate(automat)">{{automat.automatPaymentSystemName}}</button>
                      <button *ngIf="automat.automatPaymentSystemId === null || automat.automatPaymentSystemId === undefined" class="btn btn-link" data-toggle="modal" data-backdrop="true"
                              data-target="#automatPaymentTemplateModal"> Şablon Yok, Nakit Satış</button>
                    </td>
                    <td *ngIf="automat.automatPaymentSystemId !== null" class="text-center">
                      <input *ngIf="false" id="templateCheckBox" class="templateCheckBox" type="checkbox"
                             aria-label="automat-checkbox" (change)="onChangeTemplateChecked($event, automat)">
                    </td>
                    <td *ngIf="automat.automatPaymentSystemId === null && false" class="text-center">
                      <input id="templateCheckBoxNone" class="templateCheckBoxNone" type="checkbox"
                             aria-label="automat-checkbox" disabled>
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ############## MODALS STARTED ############## -->

<!-- paymentSystemsFirm Modal: End -->
<div class="modal fade" id="automatPaymentTemplateModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <button #closeAutomatPaymentTemplateModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="container">
          <div class="row modal-baslik justify-content-center">
            Şablon Yapısı
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 text-center">
              <h4 *ngIf="selectedTemplateOnAutomat?.length > 0">{{selectedTemplateName}}</h4>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4 col-lg-4 col-xl-4">
            </div>
            <div *ngIf="selectedTemplateOnAutomat?.length > 0" class="col-md-4 col-lg-4 col-xl-4">
              <div *ngFor="let paymentSystem of selectedTemplateOnAutomat" class="border priority-border text-center">
                <label>{{paymentSystem.paymentSystemsFirmPriority + 1}}. {{paymentSystem.paymentSystemsFirmName}}</label>
              </div>
            </div>
            <div *ngIf="selectedTemplateOnAutomat?.length <= 0" class="col-md-4 col-lg-4 col-xl-4 text-center">
              <h4>Şablon bulunamadı. Şablon bulunmadığı için bu otomat sadece nakit satış yapabilir.</h4>
            </div>
            <div class="col-md-4 col-lg-4 col-xl-4">
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 text-center">
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- paymentSystemsFirm Modal: End -->

<ng-template #dataLoading>
  <div class="col chartdivPayment d-flex flex-column">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%">Yükleniyor...</div>
    </div>
  </div>
</ng-template>
