<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="container">

    <div class="row ">
      <div *ngFor="let question of _questions" class="form-group col-12 col-sm-6">
        <div class="mx-2 border-bottom">
          <app-dynamic-form-question [question]="question" [form]="form"></app-dynamic-form-question>
        </div>
      </div>
    </div>

  </form>

</div>
