import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineUserService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getOfflineUser(id): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'offline-user/' + id,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getOfflineUserTransactionsByGroupId(id: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'offline-user-transactions-by-group/' + id,
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  deleteOfflineUserTransactionXls(fileName: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'delete-offline-user-transaction-xls-download/' + fileName,
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getOfflineUserTransactionsXls(offlineUserId: string, cardId: string): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'offline-user-transaction-xls-download',
      {offlineUserId: offlineUserId, cardId: cardId},
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  downloadXlsx(fileName) {
    return window.open(environment.xlsxPath + 'docs/transaction-xlsx/' + fileName, "_blank");
  }
}
