import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';


import {environment} from '../../environments/environment';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserPermissionsService} from './user-permissions.service';
import {FirmUserService} from './firm-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // private headers = new Headers({'Content-Type': 'application/json'});
  // private headers =  new HttpHeaders().set('Authorization', `Bearer ${token}`);
  private headers = new HttpHeaders({'Content-Type': 'application/json'});
  private authHeaders;
  public token: string;
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,
              private permissionService: NgxPermissionsService,
              private userpermService: UserPermissionsService) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.token = currentUser.token;
      this.user$.next(currentUser.user);
      this.authHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

    }
  }

  login(username: string, password: string) {
    return this.http.post(environment.apiBaseUrl + 'firm-user-login',
      JSON.stringify({username: username, password: password}),
      {headers: this.headers}).pipe(
      map((response: any) => {
        // login successful if there's a jwt token in the response
        if (response) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.clear();
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.token = response.token;
          this.user$.next(response.user);
          this.userpermService.userPermissions(response.user.telemetryPermission);
          return response;
        }
      }));
  }

  loginWithSub(bayiCode: string, password: string) {
    return this.http.post(environment.apiBaseUrl + 'sub-firm-user-login',
      JSON.stringify({username: bayiCode, password: password}),
      {headers: this.headers}).pipe(
      map((response: any) => {
        // login successful if there's a jwt token in the response
        if (response) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // localStorage.clear();
          localStorage.setItem('currentUser', JSON.stringify(response));
          this.token = response.token;
          this.user$.next(response.user);
          this.userpermService.userPermissions(response.user.telemetryPermission);
          return response;
        }
      }));
  }

  forgotPassword(username: string) {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'forgot-password', JSON.stringify({username: username}), {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
    /*eslint-enable */
  }

  changePassword(password: string, newPassword: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'change-password/',
      JSON.stringify({password: password, newPassword: newPassword}),
      {headers: this.authHeaders}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  changePasswordUsingOld(oldPassword: string, newPassword: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'change-password-using-old/',
      JSON.stringify({oldPassword: oldPassword, newPassword: newPassword}),
      {headers: this.authHeaders}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  sendNewPasswordWithToken(code: string, newpassword: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'new-password/',
      JSON.stringify({password: newpassword}),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + code
        })
      }).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  sendChangePasswordToken(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'send-change-password-token',
      {headers: this.authHeaders}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  requestChangePasswordToken(username: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'send-change-password', {username: username}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  logout(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'firm-user-logout').pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
    /*eslint-enable */
  }
  subLogout(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'sub-firm-user-logout').pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
    /*eslint-enable */
  }

  logoutCleanup() {
    localStorage.removeItem('currentUser');
    this.token = null;
    this.user$.next(null);
    this.permissionService.flushPermissions();
  }

}
