<div class="row my-2 fade-in-animation">
  <div *ngFor="let a of automatsFiltered; let i = index" class="col-12 my-1">
    <div class="container element">


      <div class="row">
        <div class="col-md-1 col-2 p-0 rowheight">
          <ng-template [ngIf]="a.image !== null && a?.image !== ''" [ngIfElse]="checkModelImage">
            <img class="center" src="{{a?.image | image: AutomatImagePath}}"
                 height="100%">
          </ng-template>
          <ng-template #checkModelImage>
            <img *ngIf="a?.automatModel?.image !== null && a?.automatModel?.image !== ''; else noImage" class="center"
                 src="{{a?.automatModel?.image | image: AutomatImagePath}}"
                 height="100%">
            <ng-template #noImage>
              <img class="center" src="../../../assets/img/vending-machine.png" height="100%">
            </ng-template>
          </ng-template>
        </div>

        <div class="col-md-3 col-5 rowheight">
          <div class="container overflow-hidden" style="max-height: 80%;">
            <div class="row ml-1 mt-3 mb-1 overflow-hidden text-nowrap">
              <span class="automatname"
                    [ngClass]="{'slide-text': a.name.length > 25}">
                {{a.name}}
              </span>
            </div>
            <div class="row overflow-hidden ml-1">
              <div class="automatdesc"
                   [ngClass]="{'scroll': a.description && a.description.length > 50}">
                {{a.description ? a.description : 'Açıklama Yok'}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-5 rowheight d-flex flex-column">
          <div class="row mb-1 mt-auto automatdesc">
            {{a.locationText}}
          </div>
          <div *ngIf="(a.lastInformed != undefined && a.lastInformed!= null)" class="row align-items-center mb-auto">
            <img src="{{getAutomatStatus(a.lastInformed) ? onlineAutomat : offlineAutomat}}" height="15px">
            <div [class]="status ? 'automatdesc mx-1 active' : 'automatdesc mx-1 passive'">
              {{status ? 'Aktif' : 'Pasif:'}}
            </div>
            <div class="automatdesc">{{statusDesc}}</div>
          </div>
          <div *ngIf="(a.lastInformed != undefined && a.lastInformed == null)" class="row align-items-center mb-auto"
               data-toggle="tooltip" data-placement="bottom" title="Son aktif olduğu tarih bilgisi alınamadı.">
            <img src="../../../assets/img/uyari.svg" height="15px">
          </div>
          <div *ngIf="(a.lastInformed == undefined)" class="row align-items-center mb-auto"
               data-toggle="tooltip" data-placement="bottom" title="Aktiflik bilgisi tanımlı değil.">
            <img src="../../../assets/img/uyari.svg" height="15px">
          </div>
        </div>

        <div class="col-md-2 col-6 rowheight d-flex flex-column">
          <div class="row ml-1 my-auto automatname">
            <!-- {{automatStats[i].stock}} Ürün / {{automatStats[i].price}} ₺ -->
          </div>
        </div>

        <div class="col-md-3 col-6 rowheight d-flex align-items-center">
          <div class="row mx-auto">
            <button class="btn btn-outline-primary listbutton m-1" (click)="viewAutomatInfo(a,i)">Hareketler</button>
            <button class="btn btn-primary listbutton m-1" (click)="goToAutomatDetail(a._id)">Detaylar</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
