<div class="mx-5 mt-5 animated fadeIn" xmlns="http://www.w3.org/1999/html">

  <div class="row mb-3">
    <div class="col-2 p-0">
      <h3 class="text-center">Oluşturma Tarihi</h3>
      <div class="col-12">
        <div class="row">
          <div class="filter-label-show-min-width ">Baş. Tarih :</div>
          <div class="col-8 filter-show-min-width">
            <div class="dp-hidden position-absolute" style="width: 0px">
              <div class="input-group">
                <input
                  name="datepicker"
                  class="form-control"
                  ngbDatepicker
                  #datepicker="ngbDatepicker"
                  [autoClose]="true"
                  (dateSelect)="onDateSelection($event, 'createdDate')"
                  [displayMonths]="2"
                  [dayTemplate]="t"
                  outsideDays="hidden"
                  [startDate]="this.filter.createdDate_start!"
                  tabindex="-1"
                />
                <ng-template #t let-date let-focused="focused">
                            <span
                              class="custom-day"
                              [class.focused]="focused"
                              [class.range]="isRange(date, 'createdDate')"
                              [class.faded]="isHovered(date, 'createdDate') || isInside(date, 'createdDate')"
                              (mouseenter)="hoveredDateCreated = date"
                              (mouseleave)="hoveredDateCreated = null"
                            >
                              {{ date.day }}
                            </span>
                </ng-template>
              </div>
            </div>
            <div class="input-group">
              <input
                #createdDate_start
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="createAt_first"
                [value]="formatter.format(filter.createdDate_start)"
                (input)="filter.createdDate_start = validateInput(filter.createdDate_start, createdDate_start.value)"
              />
              <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i
                class="fa fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="filter-label-show-min-width ">Bit. Tarih :</div>
          <div class="col-8 filter-show-min-width">
            <div class="input-group">
              <input
                #createdDate_end
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpToDate"
                [value]="formatter.format(filter.createdDate_end)"
                (input)="filter.createdDate_end = validateInput(filter.createdDate_end, createdDate_end.value)"
              />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()"
                      type="button"><i class="fa fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-2 p-0">
      <h3 class="text-center">Son Kullanım Tarihi</h3>
      <div class="col-12">
        <div class="row">
          <div class="filter-label-show-min-width">Baş. Tarih :</div>
          <div class="col-8 filter-show-min-width">
            <div class="dp-hidden position-absolute" style="width: 0px">
              <div class="input-group">
                <input
                  name="datepicker"
                  class="form-control"
                  ngbDatepicker
                  #datepickerExp="ngbDatepicker"
                  [autoClose]="true"
                  (dateSelect)="onDateSelection($event, 'expiredDate')"
                  [displayMonths]="2"
                  [dayTemplate]="tk"
                  outsideDays="hidden"
                  [startDate]="this.filter.expiredDate_start!"
                  tabindex="-1"
                />
                <ng-template #tk let-date let-focused="focused">
                          <span
                            class="custom-day"
                            [class.focused]="focused"
                            [class.range]="isRange(date, 'expiredDate')"
                            [class.faded]="isHovered(date, 'expiredDate') || isInside(date, 'expiredDate')"
                            (mouseenter)="hoveredDateExpired = date"
                            (mouseleave)="hoveredDateExpired = null"
                          >
                            {{ date.day }}
                          </span>
                </ng-template>
              </div>
            </div>
            <div class="input-group">
              <input
                #expiredDate_first
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="expiredDate_first"
                [value]="formatter.format(filter.expiredDate_start)"
                (input)="filter.expiredDate_start = validateInput(filter.expiredDate_start, expiredDate_first.value)"
              />
              <button class="btn btn-outline-secondary" (click)="datepickerExp.toggle()" type="button"><i
                class="fa fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="filter-label-show-min-width">Bit. Tarih :</div>
          <div class="col-8 filter-show-min-width">
            <div class="input-group">
              <input
                #expiredDate_end
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dpToDate"
                [value]="formatter.format(filter.expiredDate_end)"
                (input)="filter.expiredDate_end = validateInput(filter.expiredDate_end, expiredDate_end.value)"
              />
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepickerExp.toggle()"
                      type="button"><i class="fa fa-calendar-alt"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 p-0">
      <h3 class="text-center">Tutar</h3>
      <div class="col-12">
        <div class="row">
          <div class="filter-label-show-min-width">Başlangıç :</div>
          <div class="col-8 filter-show-min-width">
            <div class="input-group hidden-arrow">
              <input [ngModel]="filter.price_start" (ngModelChange)="changeQrPrice($event,'start')" placeholder="0.00" type="number" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="filter-label-show-min-width">Bitiş :</div>
          <div class="col-8 filter-show-min-width">
            <div class="input-group hidden-arrow">
              <input [ngModel]="filter.price_end" type="number" (ngModelChange)="changeQrPrice($event,'end')"  placeholder="0.00" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 p-0">
      <h3 class="text-center">Qr</h3>
      <div class="col-12">
        <div class="row">
          <div class="filter-label-show-min-width">Tip :</div>
          <div class="col-8 filter-show-min-width">
            <select class="form-select form-control" aria-label="Default select example" ngModel
                    [ngModel]="selectedQrType" (ngModelChange)="changeQrType($event)">
              <option [ngValue]="type" *ngFor="let type of categories">{{type.name}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="filter-label-show-min-width">Durum :</div>
          <div class="col-8 filter-show-min-width">
            <select class="form-select form-control" aria-label="Default select example" ngModel
                    [ngModel]="selectedStatus" (ngModelChange)="changeQrStatus($event)">
              <option [ngValue]="null">Tümü</option>
              <option [ngValue]="1"> Aktif</option>
              <option [ngValue]="8">Pasif</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="filter-label-show-min-width">Yazdırılma :</div>
          <div class="col-8 filter-show-min-width">
            <select class="form-select form-control" aria-label="Default select example" ngModel
                    [ngModel]="selectedPrint" (ngModelChange)="changeQrPrint($event)">
              <option [ngValue]="null">Tümü</option>
              <option [ngValue]="true">Yazdırıldı</option>
              <option [ngValue]="false">Yazdırılmadı</option>
            </select>
          </div>
        </div>

      </div>
    </div>
    <div class="col-2 p-0">
      <h3 class="ml-4 text-center">Otomat Grup</h3>
      <div class="col-12">
        <div class="row">
          <div class="filter-label-show-min-width" style="visibility: hidden">O</div>
          <div class="col-7 filter-show-min-width">
            <ng-select
                       [items]="autoamtGroupData"
                       appendTo="body"
                       [(ngModel)]="selectedGroup"
                       [multiple]="true"
                       [virtualScroll]="true"
                       placeholder="QR Otomat Grubu"
                       [loading]="loading"
                       (change)="selectQrAutomat($event)"
                       bindLabel="name" bindValue="" name="qrAutoamtGroup">
              <ng-template ng-label-tmp let-item="item">
                <span class="align-self-center">{{item.name}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{item.name}}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-4 pt-3">
        <div class="row arama-bar mb-2">
          <input type="text" id="arama" name="search" (input)="searchQrCodes($event.target.value,'code')"
                 placeholder="Kod Arayın">
        </div>
      </div>
      <div class="col pr-5 pt-3 text-right row justify-content-end">
        <button class="btn btn-secondary btn-sm" (click)="getAllQrCodes({})">Filtre Uygula</button>
        <button class="btn btn-outline-danger btn-sm ml-4 mr-5" (click)="clearFilter()">Filtreyi Temizle</button>
        <button class="btn btn-success btn-sm-lg border-line" (click)="generateQrCodes()" data-toggle="modal" data-backdrop="true"
          data-target="#urunDuzenle">
          <span class="fa fa-plus mr-3"></span> QR Oluştur
        </button>
        <button class="mx-2 btn btn-secondary btn-sm-lg" (click)="exportPdf()" target="_blank" data-toggle="modal"
          data-backdrop="true" data-target="#qrPdf">
          <span class="fa fa-file-pdf mr-3"></span> Listeyi Yazdır
        </button>
        <button class="mx-2 btn btn-danger btn-sm-lg border-line" (click)="deleteSelectedQr()" data-toggle="modal" data-backdrop="true"
          data-target="#qrPdf">
          <span class="fa fa-trash-alt mr-3"></span> Seçilenleri Sil
        </button>
        <button class="mx-2 btn btn-danger btn-sm-lg border-line" (click)="deleteAllQrCodes()" data-toggle="modal" data-backdrop="true"
          data-target="#qrPdf">
          <span class="fa fa-trash-alt mr-3"></span> Tümünü Sil
        </button>
        <div class="col-12">
          <p class="pr-3 pt-2"><span class="text-danger">* </span> Sadece Listedeki <span class="text-success" style="text-decoration: underline"> Aktif QR </span>'lar Pdf e Eklenir</p>
        </div>
      </div>
    </div>


  </div>
  <div style="height: calc( 100vh - 400px )">
  <app-pagination-table-list [paginationOption]="paginationOption"
                               (changePageValue)="getAllQrCodes($event)"
                               (buttonActionEmit)="buttonAction($event)"></app-pagination-table-list>
  </div>
  <div class="container mb-2" id="page-loader" style="display: none">
    <div class="row justify-content-center">
      <img src="../../assets/img/page-loader.svg" width="80">
    </div>
  </div>

  <a (click)="topFunction()" id="topButton" title="En yukarı çık">
    <img src="../../assets/img/up-arrow.svg" width="36">
  </a>


  <!-- Yükleme spinner -->
  <ngx-spinner
    [bdColor]=loadingSpinnerBdColor
    [size]=loadingSpinnerSize
    [color]=loadingSpinnerColor
    [type]=loadingSpinnerType
  >
  </ngx-spinner>
  <!-- Yükleme spinner : SON -->
