import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class GeocodingHelperService {
  url = "https://maps.googleapis.com/maps/api/geocode/json?";
  constructor(private http: HttpClient) { }

  getLatLngFromAdress(province: string, district: string, neighborhood: string) : Observable<any>{
    return this.http.get(this.url + "address=" + province+district+neighborhood + "&key=AIzaSyBzU7oDFOIKAdDF9lMYB9qdyeSCV_hjdfI");
  }
}
