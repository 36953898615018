import {Component, OnInit} from '@angular/core';
import {PaymentSystemsFirm} from '../../../_models/paymentSystemsTypes';
import {PaymentService} from '../../../_services/payment.service';
import {FirmService} from '../../../_services/firm.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalHelper} from '../../../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {environment} from '../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PaymentSystemsZiraat} from '../../../_models/paymentSystemsZiraat';
import {PaymentSystemsParatika} from '../../../_models/paymentSystemsParatika';
import {EditMethodPaymentComponent} from './edit-method-payment/edit-method-payment.component';
import {PaymentSystemsIyzicoMobilPersonal} from '../../../_models/paymentSystemsIyzicoMobil-personal';
import {PaymentSystemsIyzico} from '../../../_models/paymentSystemsIyzico';
import {PaymentSystemsPaynet} from '../../../_models/paymentSystemsPaynet';
import {PaymentSystemsVerifone} from '../../../_models/paymentSystemsVerifone';
import {PaymentSystems} from '../../../_models/paymentSystems';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-method-payment',
  templateUrl: './method-payment.component.html',
  styleUrls: ['./method-payment.component.css']
})
export class MethodPaymentComponent implements OnInit {

  PaymentTypeImage = environment.PaymentImagePath;

  paymentSystemsFirms: PaymentSystemsFirm[] = [];

  selPSFirm: PaymentSystems;
  firmInfo: PaymentSystems[];
  firmid: string;
  options = {
    selectedPaymentType: null,
    marketplace: false,
    operation: 'update'
  };

  swalOptions = new SwalHelper();

  constructor(private paymentService: PaymentService,
              private firmService: FirmService,
              private loadingSpinner: NgxSpinnerService,
              private modalService: NgbModal,
              private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.firmid = this.auth.user$.getValue().firm._id;
    this.load();
  }

  load() {
    this.loadingSpinner.show();
    this.paymentService.getPaymentSystemsFirms().subscribe(response => {
      console.log('firms', response);
      this.paymentSystemsFirms = response.data;
      this.listPSFirmsInfo();
      this.loadingSpinner.hide();
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Ödeme Firma bilgileri alınamadı'
      });
    });
  }

  selectPaymentSystemsFirm(paymentSystemsFirm: PaymentSystemsFirm) {
    this.selPSFirm = null;

    for (const data of this.firmInfo) {
      if (data.paymentSystemsFirmCode === paymentSystemsFirm.code) {
        switch (paymentSystemsFirm.code) {
          case 0:
              this.selPSFirm = new PaymentSystemsIyzico(data);
              this.options.marketplace = false;
              break;
          case 1:
              this.selPSFirm = new PaymentSystemsParatika(data);
              this.options.marketplace = false;
              break;
          case 2:
              this.selPSFirm = new PaymentSystemsZiraat(data);
              this.options.marketplace = false;
              break;
          case 3:
              this.selPSFirm = new PaymentSystemsPaynet(data);
              this.options.marketplace = false;
              break;
          case 4:
            this.selPSFirm = new PaymentSystemsIyzicoMobilPersonal(data);
            this.options.marketplace = true;
          case 5:
            this.selPSFirm = new PaymentSystemsVerifone(data);
            this.options.marketplace = false;

            break;
        }

        this.selPSFirm.paymentSystemsFirmId = paymentSystemsFirm._id;
        this.selPSFirm.paymentSystemsFirmName = paymentSystemsFirm.name;
        this.selPSFirm.paymentSystemsFirmCode = paymentSystemsFirm.code;
        this.selPSFirm.status = paymentSystemsFirm.status ? paymentSystemsFirm.status: false;

      }
    }

    if (!this.selPSFirm) {
      console.log('firma bulunamadı');
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
      return;
    }

    //edit modal
    const modalRef = this.modalService.open(EditMethodPaymentComponent, {centered: true, size: 'xl', scrollable: true});
    modalRef.componentInstance.options = this.options;
    modalRef.componentInstance.paymentObj = this.selPSFirm;
    modalRef.componentInstance.submitState.subscribe((newState: boolean) => {
      //update-state
      this.statusChangePaymentSystemsFirm(newState);
    });
    modalRef.componentInstance.submitUpdate.subscribe((res: { data, subType, operation }) => {
      //update-info
      switch (res.operation) {
        case 'create':
          this.createPaymentSystemsFirm(res.data, res.subType);
          break;
        case 'update':
          this.updatePaymentSystemsFirm(res.data);
          break;
      }

    });

  }

  statusChangePaymentSystemsFirm(newState: boolean) {
    Swal.fire({
      ...this.swalOptions.question,
      text: 'İşlemi onaylıyor musunuz?'
    }).then((result) => {
      if (result.value) {
        this.updateStatusPaymentSystemsFirm(newState);
      }
    });
  }

  updatePaymentSystemsFirm(newValues: any) {
    console.log('target', this.selPSFirm.paymentSystemsFirmCode);
    console.log('data', newValues);
    this.firmService.updateFirmPaymentSystemsFirms(this.firmid, newValues, this.selPSFirm.paymentSystemsFirmCode).subscribe(res => {
      if (res.success) {
        this.load();
        this.modalService.dismissAll();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      }
    }, error => {
      if (error.error.code === '0001') {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error.message + ' Önce Ödeme yöntemini şablonun içinden kaldırınız.'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    });

  }

  createPaymentSystemsFirm(newValues: any, subType: string) {
    console.log('subType', subType);
    console.log('data', newValues);

    this.firmService.createPaymentSytemFirm(this.firmid, newValues, subType).subscribe(res => {
      if (res.success) {
        this.load();
        this.modalService.dismissAll();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      }

    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'İşlem Başarısız!'
      });
    });

  }


  updateStatusPaymentSystemsFirm(newState: boolean) {
    console.log('target', this.selPSFirm.paymentSystemsFirmCode);
    console.log('data', newState);
    this.firmService.changeFirmPaymentSystemState(this.firmid, newState, this.selPSFirm.paymentSystemsFirmCode).subscribe(res => {
      console.log('stateupdate', res);
      if (res.success) {
        this.load();
        this.modalService.dismissAll();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      }
    }, error => {
      if (error.error.code === '0001') {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error.message + ' Önce Ödeme yöntemini şablonun içinden kaldırınız.'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    });

  }

  listPSFirmsInfo() {
    this.firmService.getFirmPaymentSystemsInfo(this.firmid).subscribe(res => {
      if (res.success && res.data.length) {
        this.firmInfo = res.data;
        this.firmInfo.forEach((firm) => {
            this.paymentSystemsFirms.forEach((payment) => {
              if (firm.paymentSystemsFirmCode === payment.code) {
                payment.status = firm.status;
              }
            });
        });
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Ödeme firma bilgileri alınamadı'
      });
    });
  }


}
