<div class="container my-5 animated fadeIn">
  <div class="container">
    <div class="row justify-content-end mb-4">
      <button *ngIf="groupData.length != 0" class="btn btn-success newbtn mr-2"
              (click)="createUserReport()">
        <i class="fas fa-file-alt mr-3"></i> {{'CARD_PACKAGES.CREATE_REPORT' | translate}}
      </button>
      <button *ngxPermissionsOnly="['card-packets-edit']" class="btn btn-success newbtn"
              data-toggle="modal" (click)="clearGroup()" data-backdrop="true" data-target="#offlineUserGroup">
        <i class="fa fa-plus mr-3"></i> {{'CARD_PACKAGES.CREATE_CARD_PACKAGE' | translate}}
      </button>
    </div>
  </div>

  <!-- Başlık -->
  <div *ngIf="groupData.length != 0" class="row animated fadeIn">
    <div class="col-3">
      <small class="font-weight-light ml-3 text-muted">{{'CARD_PACKAGES.PACKAGE_NAME' | translate}}</small>
    </div>
    <div class="col-2 ">
      <small class="font-weight-light text-muted">{{'CARD_PACKAGES.CREATED_AT' | translate}}</small>
    </div>
    <div class="col-1 ">
      <small class="font-weight-light text-muted">{{'CARD_PACKAGES.NUMBER_OF_CARDS' | translate}}</small>
    </div>
    <div class="col-2 ">
      <small class="font-weight-light text-muted">{{'CARD_PACKAGES.CARD_STATUS' | translate}}</small>
    </div>
    <div class="col-2 ">
      <small class="font-weight-light text-muted">{{'CARD_PACKAGES.TRANSFER_OF_BALANCE' | translate}}</small>
    </div>

  </div>
  <!-- Grup Satırı -->

  <div
    *ngFor="let group of groupData; let i = index"
    class="list-group-item container-fluid my-2 animated fadeIn">

    <div class="row mb-5">
      <div class="col-3 ">
        <a>
          <span class="row-automat-bold font-weight-bold rowcolor ">{{group.offlineUserGroup.name}}</span>
        </a>
      </div>
      <div class="col-2 ">
          <span
            class="row-automat-date text-muted">{{group.offlineUserGroup.createdAt | date: 'dd.MM.yyyy HH:mm'}}</span>
      </div>
      <div class="col-1 ">
        <span class="font-weight-bold rowcolor">{{ group.totalUser }}</span>
      </div>
      <div class="col-2 ">
        <img class="mr-1"
             [src]="group.offlineUserGroup.status == 0 ? '../../assets/img/cancelCustom.svg' : '../../assets/img/tick.svg'"
             width="15px">
        <span class="font-weight-bold"
              [ngStyle]="{'color': group.offlineUserGroup.status == 0 ? '#ff0507' : '#22b217'  }">{{(group.offlineUserGroup.status == 0 ? 'CARD_PACKAGES.PASSIVE' : 'CARD_PACKAGES.ACTIVE') | translate}}</span>
      </div>
      <div class="col-2">

        <img class="mr-1"
             [src]="group.offlineUserGroup.balanceTransfer ? '../../assets/img/tick.svg' : '../../assets/img/cancelCustom.svg'"
             width="15px">
        <span class="font-weight-bold"
              [ngStyle]="{'color': group.offlineUserGroup.balanceTransfer ? '#22b217' : '#ff0507' }">{{(group.offlineUserGroup.balanceTransfer ? 'CARD_PACKAGES.OPEN' : 'CARD_PACKAGES.CLOSE') | translate}}</span>
      </div>
      <div *ngxPermissionsOnly="['card-packets-edit']" class="col-2">
        <div class="row justify-content-center">
          <a class="mr-2"
             (click)="setGroupForNewPacket(group.offlineUserGroup, i, group.offlineUserGroup._id)">
            <img width="auto" height="20" class="m-1" src="../../assets/img/duzenle.svg" alt="kart">
          </a>
          <a class="mt-1" (click)="deleteGroup(group.offlineUserGroup._id,i)">
            <i class="fas fa-trash mr-2" style="font-size: 20px; color: #E53935"></i>
          </a>
        </div>
      </div>


    </div>

    <div *ngxPermissionsOnly="['card-packets-edit']" class="row mt-2">
      <!--buton rowu-->
      <div class="col-3">
        <button class="btn btn-outline-primary packetbuttons pbtn2 " (click)="goToGroupUsers(group.offlineUserGroup)">
          <i class="fas fa-credit-card mr-2"></i>
          {{'CARD_PACKAGES.BALANCE_AND_EXPENDITURES' | translate}}
        </button>
      </div>
      <div class="col-2">
      </div>
      <div class="col-2">
        <button class="btn btn-outline-primary packetbuttons "
                (click)="setGroup(group.offlineUserGroup, i, group.offlineUserGroup._id)">
          <i class="fas fa-arrow-alt-circle-up mr-2"></i>
          <span>{{'CARD_PACKAGES.LOAD_BALANCE' | translate}}</span>
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-primary packetbuttons "
                (click)="deductGroupBalance(group.offlineUserGroup, i, group.offlineUserGroup._id)">
          <i class="fas fa-arrow-alt-circle-down mr-2"></i>
          <span>{{'CARD_PACKAGES.DEDUCT_BALANCE' | translate}}</span>
        </button>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-primary packetbuttons "
                (click)="addOfflineUser(group.offlineUserGroup._id)">
          <i class="fas fa-user mr-2"></i>
          {{'CARD_PACKAGES.ADD_USER' | translate}}
        </button>

      </div>
      <div class="col-3 ">
        <button class="btn btn-outline-primary packetbuttons " *ngIf="group.offlineUserGroup.isPeriod"
                (click)="goToOfflineCardGroupPeriod(group.offlineUserGroup)">
          <i class="fas fa-file-alt mr-2"></i>
          {{'CARD_PACKAGES.LOADING_REPORT' | translate}}
        </button>
      </div>
    </div>
  </div>


  <!-- Grup Satırı : SON -->
  <!-- Boş liste uyarı -->
  <div *ngIf="groupData.length == 0" class="row justify-content-center animated flipInX">
    <h1 class="h3 mt-5" style="color: gray">{{'CARD_PACKAGES.NO_CARD_PACKAGE' | translate}}</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>
