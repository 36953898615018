import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {environment} from '../../environments/environment';
import {TicketService} from '../_services/ticket.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {Subscription} from 'rxjs';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {AutomatComponent} from '../automat/automat.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  swalOptions = new SwalHelper();
  private sub: Subscription;

  public currentUser: any = {};
  public ProfileImagePath = environment.ProfileImagePath;
  public ticketCount = 0;

  permissions;
  visible = {
    automats: false,
    cards: false,
    sales: false,
    qrCode: true
  };

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private ticketService: TicketService,
              private modalService: NgbModal,
              private permissionService: NgxPermissionsService) {
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover'
    });
    this.sub = this.authenticationService.user$.subscribe(res => {
      this.currentUser = res;
    });
    this.getTicketCounts();

    const sub2 = this.permissionService.permissions$.subscribe(value => {
      this.permissions = value;

      if (this.permissions['automats'] || this.permissions['add-automat'] || this.permissions['automat-groups']) {
        this.visible.automats = true;
      }
      if (this.permissions['card-packets']) {
        this.visible.cards = true;
      }
      if (this.permissions['sales'] || this.permissions['proceeds'] ||
        this.permissions['sales-report'] || this.permissions['basket-report']
        || this.permissions['transaction-statement']) {
        this.visible.sales = true;
      }
    });

    this.sub.add(sub2);


  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sendMail() {
    this.authenticationService.sendChangePasswordToken().subscribe(res => {
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Şifrenizi e-posta adresinize gönderilen bağlantıdan değiştirebilirsiniz!'
      });
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Şifre değiştirme e-postası gönderilemedi!'
      });
    });
  }

  logout() {
    this.authenticationService.logout().subscribe(res => {
      if (res.success) {
        this.authenticationService.logoutCleanup();
        this.router.navigate(['auth/login']);
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Oturum kapatılamadı!'
        });

      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Oturum kapatılamadı!'
      });

    });
  }

  getTicketCounts() {
    this.ticketService.getTickets(1).subscribe(res => {
      this.ticketCount = res.result.total;
    }, error => {
      if (error.status === 403) {
      }
    });
  }

  openAutomatModal() {
    const modalRef = this.modalService.open(AutomatComponent, {centered: true, scrollable: true});
    modalRef.componentInstance.selectedAutomat = ' ';
  }

}
