import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AutomatService} from '../../_services/automat.service';
import {Router} from '@angular/router';
import {MarkerAutomat} from '../../_models/markerAutomat';
import * as moment from 'moment';

@Component({
  selector: 'app-card-view-automats',
  templateUrl: './card-view-automats.component.html',
  styleUrls: ['./card-view-automats.component.css']
})
export class CardViewAutomatsComponent implements OnInit {

  AutomatImagePath = environment.AutomatImagePath;

  mouseOnTitle = false;
  mouseOnDescription = false;
  mouseOnAutomatId: string;


  automats: Array<MarkerAutomat>;
  automatsFiltered: Array<MarkerAutomat>;
  automatStats = Array<{ price: number, stock: number }>();

  @Input()
  set automatList(data: Array<MarkerAutomat>) {
    this.automats = data || [];
  }

  @Input()
  set automatListFiltered(data: Array<MarkerAutomat>) {
    this.automatsFiltered = data || [];
  }

  @Input()
  set automatListStats(data: Array<{ price: number, stock: number }>) {
    this.automatStats = data || [];
  }

  constructor(
    private automatService: AutomatService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  startMarquee(id: string, where: string) {
    if (where === 'title') {
      this.mouseOnTitle = true;
      this.mouseOnAutomatId = id;
    } else if (where === 'description') {
      this.mouseOnDescription = true;
      this.mouseOnAutomatId = id;
    }
  }

  stopMarquee(where: string) {
    if (where === 'title') {
      this.mouseOnTitle = false;
    } else if (where === 'description') {
      this.mouseOnDescription = false;
    }
  }

  getAutomatStatus(automatStatus?: string): boolean {
    const automatDate = moment(automatStatus);
    const difference = moment().diff(automatDate);
    return difference <= environment.WaitingTimeLimit;
  }

    getAutomatIsActive(automatStatus: number): boolean {
        return automatStatus === 1 ? true : false;
    }

  getAutomatStatusText(automatStatus: string): string {
    let whenOnline: Number;
    const oneMinute = 60000;
    const oneHour = 3600000;
    const oneDay = 86400000;

    const automatDate = moment(automatStatus);
    const difference = moment().diff(automatDate);
    if (difference <= environment.WaitingTimeLimit) {
      return 'Aktif';

    } else {
      if (difference > environment.WaitingTimeLimit && difference <= oneHour) {
        whenOnline = Math.floor(difference / oneMinute);
        return 'En son ' + whenOnline + ' dakika önce aktifti.';
      } else if (difference > oneHour && difference <= oneDay) {
        whenOnline = Math.floor(difference / oneHour);
        return 'En son ' + whenOnline + ' saat önce aktifti.';
      } else if (difference > oneDay) {
        whenOnline = Math.floor(difference / oneDay);
        return 'En son ' + whenOnline + ' gün önce aktifti.';
      }
    }
  }

  goToAutomatDetail(automatId: string) {
    this.router.navigate(['/automat-detail', automatId]);
  }


}
