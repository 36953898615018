import {Component, OnInit} from '@angular/core';
import {SwalHelper} from '../../_helpers/swal.helper';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../_services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-qr-login',
    templateUrl: './qr-login.component.html',
    styleUrls: ['./qr-login.component.css']
})
export class QrLoginComponent implements OnInit {

    swalOptions = new SwalHelper();
    //Ngx-spinner değişkenleri (Loading)
    public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
    public loadingSpinnerSize = environment.loadingSpinnerSize;
    public loadingSpinnerColor = environment.loadingSpinnerColor;
    public loadingSpinnerType = environment.loadingSpinnerType;
    public loadingSpinnerText = environment.loadingSpinnerText;
    //END : Ngx-spinner değişkenleri (Loading)

    user: {
        bayiCode: string,
        password: string
    } = {
        bayiCode: null,
        password: null
    };

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private loadingSpinner: NgxSpinnerService) {
    }

    ngOnInit() {
        if (this.authenticationService.user$.getValue()) {
            this.router.navigate(['/login/qr']);
        }
    }

    redirect(pagename: string) {
        this.router.navigate(['/' + pagename]);
    }

    login() {
        if (!this.user.password || !this.user.bayiCode) {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Lütfen alanları doldurunuz'
            });
            return;
        }
        this.loadingSpinner.show();
        this.authenticationService.loginWithSub(this.user.bayiCode, this.user.password).subscribe(data => {
            if (data.token) {
                this.router.navigate(['/qr/']);
            }
            this.loadingSpinner.hide();
        }, error => {
            this.loadingSpinner.hide();
            Swal.fire({
                ...this.swalOptions.error,
                title: 'Oturum açma başarısız',
                text: 'Lütfen farklı kullanıcı adı veya şifre ile deneyiniz'
            });
        });
    }

}
