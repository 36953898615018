<div class="container my-5">
  <div class="d-flex flex-column">
      <div class="jumbotron">
              <img src="../../../../assets/img/payter-logo.jpg" alt="payter">
      </div>
  </div>
  <div class="d-flex flex-row">
    <span class="flex-grow-1 mx-3 my-auto font-weight-bold">{{'APPS.API_SETTINGS' | translate}}</span>
    <span class="mx-3 my-auto">{{'APPS.API_KEY_CONNECTION' | translate}}</span>
    <ui-switch [checked]="isChecked" class="my-auto"
                     (valueChange)="stateChanged($event)"
                     defaultBgColor="red"></ui-switch>
                     <span class="my-auto mx-3" [ngClass]="{passive: !isChecked, active: isChecked}">{{(isChecked ? 'APPS.ACTIVE': 'APPS.PASSIVE') | translate}}</span>
  </div>
  <form [formGroup]="apiSettingsForm" class="mt-5" (ngSubmit)="save()">
    <div class="d-flex flex-column">
      <mat-form-field appearance="standard" class="matInputText">
        <mat-label>{{'APPS.DOMAIN' | translate}}</mat-label>
        <input type="text" matInput placeholder="{{'APPS.DOMAIN' | translate}}" formControlName="domain">
      </mat-form-field>
      <mat-form-field appearance="standard" class="matInputText">
        <mat-label>{{'APPS.USER_NAME' | translate}}</mat-label>
        <input type="text" matInput placeholder="{{'APPS.USER_NAME' | translate}}" formControlName="username">
      </mat-form-field>
      <mat-form-field appearance="standard" class="matInputText mb-5">
        <mat-label>{{'APPS.PASSWORD' | translate}}</mat-label>
        <input type="password" matInput placeholder="{{'APPS.PASSWORD' | translate}}" formControlName="password">
      </mat-form-field>
      <div class="d-lex my-4">
        <mat-icon aria-hidden="false" aria-label="Example chat icon" class="mx-4">chat</mat-icon>
        <span>
          {{'APPS.PAYTER_DOMAIN' | translate}}</span>
      </div>
      <div class="d-lex my-4">
        <button mat-flat-button color="accent" class="save">{{'APPS.SAVE' | translate}}</button>
      </div>
    </div>
  </form>
</div>
