import { Component, OnInit } from '@angular/core';
import {TimeZone} from '../../settings.component';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.css']
})
export class OrderPaymentComponent implements OnInit {



  currentTimeZone: TimeZone = TimeZone.ALL;
  text: any;


  constructor() { }

  ngOnInit() {
  }

}
