import {Component, NgModule, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {PaginationTableOptions} from '../../_models/paginationTableOptions';
import {Moment} from 'moment/moment';
import {AutomatStatus} from '../../_enums/automatStatus';
import {generate} from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-pagination-table-list',
    templateUrl: './pagination-table-list.component.html',
    styleUrls: ['./pagination-table-list.component.css']
})
export class PaginationTableListComponent implements OnInit {

    @Output() changePageValue = new EventEmitter<any>();
    @Output() buttonActionEmit = new EventEmitter<any>();
    _paginationOption: PaginationTableOptions;
    isAllSelect = true ;
    

    statusEnum = {
        0: 'YENİ',
        1: 'AKTİF',
        2: 'BEKLEMEDE',
        3: 'İPTAL',
        7: 'SATILDI',
        8: 'PASİF',
        9: 'SİLİNMİŞ',
        10: 'SÜRESİ DOLMUŞ',
    };

    @Input()
    set paginationOption(val: PaginationTableOptions) {
        this._paginationOption = val;
    }

    constructor() {
    }

    ngOnInit(): void {
    }

    generateEmptyList() {
        const val = this._paginationOption;
        const emptyDataList = [];
        if (val.itemList.length < val.limit) {
            const empyData = val.limit - val.itemList.length;
            for (let i = 0; i < empyData; i++) {
                emptyDataList.push(val.itemList[0]);
            }
        }
        if (!val.emptyListMessage) {
            this._paginationOption.emptyListMessage = 'Liste Boş';
        }
        return emptyDataList;
    }

    changePageValueFunc() {
        console.log(this._paginationOption);
        this.changePageValue.emit({page: this._paginationOption.page, limit: this._paginationOption.limit});
    }

    changeValue(value) {
        this._paginationOption.page = parseInt(value);
        this.changePageValueFunc();
    }

    changeLimitValue(limit) {
        this._paginationOption.limit = parseInt(limit);
        this.changePageValueFunc();
    }

    getStatus(status) {
        return this.statusEnum[status] || status;
    }

    getValue(item, property) {
        if (!item) {
            return '';
        }
        const propertyList = property.split('.');
        if (propertyList.length === 1) {
            return item[property];
        }
        let defaultVal = Object.assign({}, item);
        let result;
        for (const prop of propertyList) {
            defaultVal = defaultVal[prop];
            if (!defaultVal) {
                return '';
            }
            result = defaultVal;
        }
        return result;
    }

    buttonAction(action, item) {           
        if (action) {
            this.buttonActionEmit.emit({action: action, item});
        }
    }

    selectItem(item) {
        item.isSelected = !item.isSelected;
        
        
    }

    selectAlItem(itemSelected) {
        for (const item of this._paginationOption.itemList) {
            item.isSelected = itemSelected.target.checked;
        }
    }

    isAllSelected() {
        this.isAllSelect = this._paginationOption.itemList.filter(item => item.isSelected).length == this._paginationOption.limit;
        return this.isAllSelect;
    }

    convertDate(date) {
        return moment(date).format('LLLL');
    }
}
