<div class="container mb-4">
  <div class="row">
    <div class="col-md-12 col-xl-12 col-lg-12 content-header-page">
      <label>Ödeme Şablonları</label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-4 col-xl-4">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="priority-header-border content-header">
            <label>Şablonlar</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="bilgi-paragraf">
            <button class="priority-create-button btn btn-outline-dark text-center"
                    data-toggle="modal" data-backdrop="true" data-target="#templateModal"
                    (click)="routeTemplateModal('create', '')"> Yeni Oluştur</button>
          </div>
        </div>
      </div>
      <div *ngIf="paymentTemplates?.length > 0" style="height: 520px; overflow-y: scroll">
        <div *ngFor="let template of paymentTemplates; let y = index" class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div *ngIf="y === selectedIndex" class="border" [ngClass]="y === selectedIndex ? 'priority-border-selected':
                          selectedIndex === null || y !== selectedIndex ? 'priority-border': 'priority-border'">
              <div class="row">
                <div class="col-md-5 col-lg-5 col-xl-5 col-sm-5 col-5" (mouseenter)="startMarquee(y)" (mouseleave)="stopMarquee()">
                  <marquee *ngIf="mouseOnTemplate === y" class="wrap-text-one-line" style="cursor: pointer;"
                           behaivor="scroll" direction="left" scrollamount="4">
                    <label style="cursor: pointer;">{{template.name}}</label>
                  </marquee>
                  <label *ngIf="mouseOnTemplate === null || mouseOnTemplate != y" class="wrap-text-one-line-ellipsis" style="width: 100px !important;">{{template.name}}</label>
                </div>
                <div class="col-md-5 col-lg-5 col-xl-5 col-sm-5 col-5 text-center">
                  <label style="cursor: pointer; color: #2fb43f;" (click)="saveOrderedTemplate()">Kaydet</label>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-2 text-right">
                  <a (click)="cancelSelect()">
                    <i class="fas fa-times" style="font-size: 20px; color: #ed7060"></i>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="y !== selectedIndex && selectedIndex === null" class="border" [ngClass]="y === selectedIndex ? 'priority-border-selected':
                         selectedIndex === null || y !== selectedIndex ? 'priority-border': 'priority-border'">
              <div class="row">
                <div class="col-md-8 col-lg-8 col-xl-8 col-sm-8 col-8" (mouseenter)="startMarquee(y)" (mouseleave)="stopMarquee()" (click)="selectTemplate(y, template)">
                  <marquee *ngIf="mouseOnTemplate === y" class="wrap-text-one-line" style="cursor: pointer;"
                           behaivor="scroll" direction="left" scrollamount="4">
                    <label style="cursor: pointer;" (click)="selectTemplate(y, template)">{{template.name}}</label>
                  </marquee>
                  <label *ngIf="mouseOnTemplate === null || mouseOnTemplate != y" class="wrap-text-one-line-ellipsis">{{template.name}}</label>
                </div>
                <div class="col-md-1 col-lg-1 col-xl-1 col-sm-1 col-1 text-center">
                  <a data-toggle="modal" data-backdrop="true" data-target="#templateModal"
                     (click)="routeTemplateModal('update', template)">
                    <i class="fas fa-edit" style="font-size: 20px; color: #818181"></i>
                  </a>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 col-sm-2 col-1 text-center">
                  <a (click)="deleteTemplate(template)">
                    <i class="fas fa-trash" style="font-size: 20px; color: #ed7060"></i>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="y !== selectedIndex && selectedIndex !== null" class="border priority-border-unselected">
              <div class="row">
                <div class="col-md-9 col-lg-9 col-xl-9">
                </div>
                <div class="col-md-3 col-lg-3 col-xl-3 text-right">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="paymentTemplates?.length <= 0">
        <div class="priority-border-non2 border text-center">
          <label class="priority-content-non">Yeni Şablon Oluşturmak için Yukarıdaki Butonu Kullanabilirsiniz</label>
        </div>
      </div>
    </div>
    <div *ngIf="selectedIndex !==null" class="col-md-6 col-lg-4 col-xl-4 border-left">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="priority-header-border content-header">
            <label>Ödeme Yöntemleri</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="bilgi-paragraf">
            <p>Firmanıza ait ödeme yöntemlerini şablon yapısına sürekleyip şablonu yapılandırabilirsiniz.</p>
          </div>
        </div>
      </div>
      <div class="border p-2" style="height: 520px; overflow-y: scroll">
        <div *ngFor="let firm of tempAvailablePaymentSystemsFirms" class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div [dndDraggable]="firm"
                 [dndEffectAllowed]="draggable.effectAllowed"
                 [dndDisableIf]="draggable.disable"
                 (dndStart)="onDragStart($event, 'paymentFirms')"
                 (dndCopied)="onDraggableCopied($event)"
                 (dndLinked)="onDraggableLinked($event)"
                 (dndMoved)="onDraggableMoved($event)"
                 (dndCanceled)="onDragCanceled($event)"
                 (dndEnd)="onDragEnd($event)"
                 class="border priority-border-pointer text-center">
              <label>{{firm.paymentSystemsFirmName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedIndex !== null" class="col-md-6 col-lg-4 col-xl-4">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="priority-header-border content-header">
            <label>Şablon Yapısı</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="bilgi-paragraf">
            <p>Şablon içerisinden sıralama yaparak ödeme yöntemi önceliğini belirleyebilirsiniz.</p>
          </div>
        </div>
      </div>
      <div class="border p-2" style="height: 520px; overflow-y: scroll">
        <section dndDropzone
                 (dndDragover)="onDragover($event)"
                 (dndDrop)="onDrop($event)">
          <div *ngFor="let template of orderedPaymentTemplate?.paymentSystems; let i = index"
               [dndDraggable]="template"
               [dndEffectAllowed]="draggable.effectAllowed"
               [dndDisableIf]="draggable.disable"
               (dndStart)="onDragStart($event, 'sorting')"
               (dndCopied)="onDraggableCopied($event)"
               (dndLinked)="onDraggableLinked($event)"
               (dndMoved)="onDraggableMoved($event)"
               (dndCanceled)="onDragCanceled($event)"
               (dndEnd)="onDragEnd($event)"
               class="border priority-border-pointer">
            <div class="row">
              <div class="col-md-9 col-lg-9 col-xl-9 text-left">
                <label>{{i + 1}}. {{template.paymentSystemsFirmName}}</label>
              </div>
              <div class="col-md-3 col-lg-3 col-xl-3 text-right">
                <a (click)="deleteElementInTemplate(template)">
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="orderedPaymentTemplate?.paymentSystems?.length <= 0" class="border priority-border-pointer text-center">
            <label>Buraya sürükleyiniz.</label>
          </div>
          <!--optional placeholder element for dropzone-->
          <!--will be removed from DOM on init-->
          <div style="border: 1px orangered solid; border-radius: 5px; padding: 15px;"
               dndPlaceholderRef>
            Bırakabilirsiniz
          </div>

        </section>
      </div>
    </div>
    <div *ngIf="selectedIndex === null" class="col-md-12 col-lg-8 col-xl-8">
      <div class="text-center priority-border-non border">
        <h3 class="priority-content-non"> Lütfen Şablon Seçiniz. </h3>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="templateModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <button #closeTemplateModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

        <div *ngIf="templateModalRouter === 'create'" class="container">
          <div class="row modal-baslik justify-content-center">
            Şablon Oluştur
          </div>
          <hr>
          <div class="row">
            <div class="col-md-5 col-lg-5 col-xl-5 text-right">
              <label>Şablon Adı:</label>
            </div>
            <div class="col-md-5 col-lg-5 col-xl-5 text-left">
              <input class="form-control" [(ngModel)]="automatPaymentSystemName" type="text" placeholder="Şablon Adı">
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 text-center">
              <button class="btn btn-outline-dark" (click)="createAutomatPaymentSystem()">Oluştur</button>
            </div>
          </div>
        </div>

        <div *ngIf="templateModalRouter === 'update'" class="container">
          <div class="row modal-baslik justify-content-center">
            Şablonu Güncelle
          </div>
          <hr>
          <div class="row">
            <div class="col-md-5 col-lg-5 col-xl-5 text-right">
              <label>Şablon Adı:</label>
            </div>
            <div class="col-md-5 col-lg-5 col-xl-5 text-left">
              <input class="form-control" [(ngModel)]="automatPaymentSystemName" type="text" placeholder="Şablon Adı">
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 text-center">
              <button class="btn btn-outline-dark" (click)="updateAutomatPaymentSystem()">Güncelle</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
