import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AutomatGroupService} from '../_services/automat-group.service';
import {Router} from '@angular/router';
import {CashlessService} from '../_services/cashless.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {SwalHelper} from '../_helpers/swal.helper';
import { AutomatService } from '../_services/automat.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

declare var $: any;


@Component({
  selector: 'app-automat-groups',
  templateUrl: './automat-groups.component.html',
  styleUrls: ['./automat-groups.component.css']
})
export class AutomatGroupsComponent implements OnInit, AfterViewInit, OnDestroy{

  swalOptions = new SwalHelper();
  @ViewChild('CloseAutomatGroupModal', {static: false}) CloseAutomatGroupModal: ElementRef;
  @ViewChild('CloseOfflineGroupModal', {static: false}) CloseOfflineGroupModal: ElementRef;

  isNetworkActive: boolean;

  groupData = {result: [], groupCounts: []};
  controlVariables = {isInEdit: false, index: -1};
  group: Group = {name: null, automats: []};
  offlineUserGroups: any;
  offlineUserGroupIds: string[] = [];
  automatList = [];
  automats: any[] = [];
  newAutomatFilter = false;
  selectedOfflineUserGroupData = {offlineUserGroupId: '', automatGroupId: ''};

  public currentUser: any = {};
  private sub: Subscription;

  page = 1;
  pages = 1;
  total = 0;
  loading = false;

  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  // END : Ngx-spinner değişkenleri (Loading)

  constructor(private automatGroupService: AutomatGroupService,
              private automatService: AutomatService,
              private cashlessService: CashlessService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getGroups();
    this.getAllAutomats();
    this.sub = this.authenticationService.user$.subscribe(res => {
      this.currentUser = res;
    });
  }

  ngAfterViewInit() {
    $('#automatGroup').on('hidden.bs.modal', (() => {
      this.unselectAllAutomat();
    }));
    $('#addofflineUserGroup').on('hidden.bs.modal', (() => {
      this.unselectAllCarts();
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  getGroups() {
    this.loadingSpinner.show();
    this.isNetworkActive = true;
    this.automatGroupService.getFirmAutomatGroups().subscribe(res => {
        this.groupData = res;
        this.isNetworkActive = false;
        this.loadingSpinner.hide();
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomat grupları sunucudan getirilemedi!'
        });
      });
  }

  getAutomatsIDArray(): Array<string> {
    const ret = [];
    this.automats.forEach(automat => {
      if(automat)
        ret.push(automat._id);
    });
    return ret;
  }


  getAllAutomats(){
    this.automats = [];
    this.automatService.getAllFirmAutomats(this.page).subscribe(response => {
      this.automatList = response.automats.docs;
    });
  }

  onSelectUpdate() {
    this.newAutomatFilter = true;
    this.group.automats = this.getAutomatsIDArray();
  }

  onSelectCartUpdate() {
    console.log(this.offlineUserGroupIds);
  }

  onSelectClose() {
    this.onChangeAutomat(this.automats);
    this.group.automats = this.getAutomatsIDArray();
  }

  onSelectCartClose() {
    console.log(this.offlineUserGroupIds);
  }

  onChangeAutomat(automat) {
    if (automat === undefined) {
      automat = [];
    }
  }

  selectAllAutomat() {
    this.automats = this.automatList.map(x => x);
  }

  selectAllCarts() {
    this.offlineUserGroupIds = this.offlineUserGroups.map(x => x._id);
  }

  unselectAllAutomat() {
    this.automats = [];
  }

  unselectAllCarts() {
    this.offlineUserGroupIds = [];
  }


  getOfflineUserGroups() {
    this.loadingSpinner.show();
    this.cashlessService.getOfflineUserGroupList(this.selectedOfflineUserGroupData.automatGroupId).subscribe(res => {
        this.loadingSpinner.hide();
        if (res.success) {
          this.offlineUserGroups = res.offlineUserGroup;
        } else {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart paketleri sunucudan getirilemedi!'
          });
        }
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart paketleri sunucudan getirilemedi!'
        });
      });
  }

  getGroupAutomatCount(groupId): number {
    if (this.groupData.groupCounts.length > 0) {
      const result = this.groupData.groupCounts.find(group => group._id === groupId);
      if (result) {
        return result.count;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  goToGroupAutomats(id) {
    this.automatGroupService.changeGroupId(id);
    this.router.navigate(['/group-automats']);
  }

  setGroup(group, index) {
    this.group = Object.assign({}, group);
    this.automatGroupService.getGroupAutomats(group._id).subscribe(res => {
      if (res.success) {
        this.automats = res.automats;
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomatlar sunucudan getirilemedi!'
        });
      }
    },
    error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomatlar sunucudan getirilemedi!'
      });
    });
    this.controlVariables.index = index;
    this.controlVariables.isInEdit = true;
  }

  clearGroup() {
    this.group = {_id: null, name: null, firm: null, status: 0, automats: []};
    this.controlVariables.isInEdit = false;
  }

  deleteGroup(groupId, index) {
    this.controlVariables.index = index;
    Swal.fire({
        ...this.swalOptions.delete,
        text: 'Grubu silmek istediğinize emin misiniz?'
      }
    ).then((result) => {
      if (result.value) {
        this.loadingSpinner.show();
        this.automatGroupService.deleteAutomatGroup(groupId).subscribe(res => {
          this.groupData.result.splice(this.controlVariables.index, 1);
          this.loadingSpinner.hide();
          Swal.fire(
            {
              ...this.swalOptions.success,
              text: 'Silindi'
            }
          )
          ;
        });
      }
    });
  }

  setGroupId(id, index) {
    this.selectedOfflineUserGroupData.offlineUserGroupId = '-1';
    this.offlineUserGroups = [];
    this.selectedOfflineUserGroupData.automatGroupId = id;
    this.getOfflineUserGroups();
    this.controlVariables.index = index;
  }

  removeOfflineUserGroup(offlineUserGroupId, automatGroupId, index) {
    this.selectedOfflineUserGroupData.offlineUserGroupId = offlineUserGroupId;
    this.selectedOfflineUserGroupData.automatGroupId = automatGroupId;
    this.controlVariables.index = index;

    Swal.fire({
      ...this.swalOptions.question,
      text: 'Kart paketini bu gruptan çıkarmak istediğinize emin misiniz?'
    }).then((result) => {
      if (!result.isDismissed) {
        this.loadingSpinner.show();
        this.automatGroupService.removeOfflineUserGroupToAutomatGroup(
          this.selectedOfflineUserGroupData.offlineUserGroupId, this.selectedOfflineUserGroupData.automatGroupId).subscribe(res => {
            this.CloseOfflineGroupModal.nativeElement.click();
            this.groupData.result[this.controlVariables.index] = res;
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Kart paketi otomat grubundan başarıyla çıkarıldı!'
            });
          },
          error => {
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Kart paketi otomat grubundan çıkarılamadı!'
            });
          });
      }
    });

  }

  addOfflineUserGroup() {
    this.loadingSpinner.show();
    this.automatGroupService.addOfflineUserGroupToAutomatGroup(
      this.offlineUserGroupIds, this.selectedOfflineUserGroupData.automatGroupId).subscribe(res => {
        this.CloseOfflineGroupModal.nativeElement.click();
        this.groupData.result[this.controlVariables.index] = res;
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Kart paketi otomat grubuna başarıyla eklendi!'
        });

      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart paketi otomat grubuna eklenemedi!'
        });
      });
  }

  handleGroup() {
    if (!this.checkField()) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen gerekli alanları boş bırakmayın!'
      });
      return false;
    } else {
      if (this.controlVariables.isInEdit) {
        delete this.group.updatedAt;
        this.loadingSpinner.show();
        this.group.automats = this.getAutomatsIDArray();
        this.automatGroupService.updateAutomatGroup(this.group, this.group._id).subscribe(res => {
            if (res.success) {
              this.groupData.result[this.controlVariables.index] = res.automatGroup;
              this.CloseAutomatGroupModal.nativeElement.click();
              this.getGroups();
            }

            this.loadingSpinner.hide();
          },
          error => {
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Otomat grubu güncellenemedi!'
            });
          });

      } else {
        this.loadingSpinner.show();
        this.group.firm = this.currentUser.firm._id;
        this.group.automats = this.getAutomatsIDArray();
        this.automatGroupService.createAutomatGroup(this.group).subscribe(res => {
            if (res.success) {
              this.groupData.result.push(res.automatGroup);
              this.CloseAutomatGroupModal.nativeElement.click();
              this.getGroups();
            }

            this.loadingSpinner.hide();
          },
          error => {
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Otomat grubu oluşturulamadı!'
            });
          });

      }
    }
  }

  checkField() {
    return !(this.group.name === null || this.group.name === '' || this.group.name.trim() === '');
  }

  search(term: string, item: any) {
    return item.name.toLocaleLowerCase().trim().includes(term.toLocaleLowerCase().trim());
  }

  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    this.page += 1;

    this.getAllAutomats();

  }

}
export interface Group {
  _id?: any;
  name: string;
  automats: string[];
  updatedAt?: any;
  firm?: any;
  status?: any;
};
