<div class="modal-header">
  <h4 *ngIf="_selectedUser.name else newUserTemp" class="modal-title">{{_selectedUser.name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss({success:false})">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>

    <div class="form-row mb-3">

      <div class="col-3 mx-4">
        <input type="text" [(ngModel)]="_selectedUser.username" name="username"
               class="form-control custom-control text-enter"
               [ngClass]="{'fail-input': showErrors && !inputCheck(_selectedUser.username)}"
               placeholder="Kullanıcı Adı(e-mail)">
      </div>


      <div class="col-3" *ngIf="!_selectedUser._id">
        <div class="row input-group mx-0">
          <input [type]="hide ? 'password' : 'text'" class="form-control custom-control text-enter"
                 placeholder="Şifre"
                 [(ngModel)]="_selectedUser.password" name="password"
                 [ngClass]="{'fail-input': showErrors && !passwordCheck()}">
          <a class="input-group-append" (click)="hide = !hide">
                  <span class="input-group-text"><i class="far"
                                                    [ngClass]="hide ? 'fa-eye-slash':'fa-eye' "></i></span>
          </a>
        </div>
      </div>

      <div class="col-3" *ngIf="!_selectedUser._id">
        <div class="row input-group mx-0">
          <input [type]="hide2 ? 'password' : 'text'" class="form-control custom-control text-enter"
                 placeholder="Şifre (Tekrar)"
                 [(ngModel)]="password2" name="password2"
                 [ngClass]="{'fail-input': showErrors && !passwordCheck()}">
          <a class="input-group-append" (click)="hide2 = !hide2">
                  <span class="input-group-text"><i class="far"
                                                    [ngClass]="hide2 ? 'fa-eye-slash':'fa-eye'"></i></span>
          </a>
        </div>
      </div>


    </div>

    <div class="form-row">

      <div class="col-3 mx-4">
        <input type="text" [(ngModel)]="_selectedUser.name" name="name"
               class="form-control custom-control text-enter"
               [ngClass]="{'fail-input': showErrors && !inputCheck(_selectedUser.name)}"
               placeholder="Ad Soyad">
      </div>

      <div class="col-3 text-enter">
        <app-formfield-phone
          [(phoneNumber)]="_selectedUser.phoneNumber">
        </app-formfield-phone>
      </div>


    </div>

    <div class="form-row m-1 mt-5">

      <div class="col">
        <label class="myCheckbox mr-1">
          <small class="mr-2">Tüm Yetkiler Açık:</small>
          <input type="radio" style="display: none;"
                 [checked]="_selectedUser.telemetryPermission.length === allpermissions.length"
                 (change)="changePermArray(allpermissions,$event,true)"
                 [name]="'allpermsw'">
          <span class="mr-3" style="vertical-align: bottom"></span>

        </label>

        <label class="myCheckbox">
          <small class="mr-2">Kısıtlı Yetkiler(Tavsiye Edilir):</small>
          <input type="radio" style="display: none;"
                 [checked]="_selectedUser.telemetryPermission.length !== allpermissions.length"
                 (change)="changePermArray(allpermissions,$event,false)"
                 [name]="'allpermswsw'">
          <span class="mr-3" style="vertical-align: bottom"></span>

        </label>

      </div>


    </div>

    <hr/>

    <div *ngIf="_selectedUser.telemetryPermission" class="row m-1">


      <div *ngFor="let auth of allpermissions" class="col-4">

        <div class="row mb-2">


          <small class="text-muted col">
            {{auth === 'automats' ? 'Otomat:' : ''}}
            {{auth === 'add-automat' ? 'Otomat ekle:' : ''}}
            {{auth === 'edit-automat' ? 'Otomat düzenle:' : ''}}
            {{auth === 'delete-automat' ? 'Otomat sil:' : ''}}
            {{auth === 'automat-detail' ? 'Otomat Detayları:' : ''}}
            {{auth === 'automat-detail-products' ? 'Otomat Ürünleri:' : ''}}
            {{auth === 'automat-detail-sales' ? 'Otomattaki Satış Raporları:' : ''}}
            {{auth === 'automat-detail-proceeds' ? 'Otomattaki Tahsilat:' : ''}}
            {{auth === 'automat-detail-personels' ? 'Otomat Personelleri:' : ''}}
            {{auth === 'automat-detail-errors' ? 'Otomat Arızaları:' : ''}}
            {{auth === 'automat-groups' ? 'Otomat Grupları:' : ''}}
            {{auth === 'automat-groups-edit' ? 'Otomat Grupları Güncelleme:' : ''}}
            {{auth === 'sales' ? 'Satışlar:' : ''}}
            {{auth === 'sales-report' ? 'Satış Raporları:' : ''}}
            {{auth === 'transaction-statement' ? 'İşlem Özeti:' : ''}}
            {{auth === 'proceeds' ? 'Tahsilatlar:' : ''}}
            {{auth === 'basket-report' ? 'Sepet Raporu:' : ''}}
            {{auth === 'products' ? 'Ürünler:' : ''}}
            {{auth === 'products-edit' ? 'Ürün Güncelleme:' : ''}}
            {{auth === 'personels' ? 'Personeller:' : ''}}
            {{auth === 'personels-edit' ? 'Personel Güncelleme:' : ''}}
            {{auth === 'users' ? 'Kullanıcılar:' : ''}}
            {{auth === 'users-edit' ? 'Kullanıcı Güncelleme:' : ''}}
            {{auth === 'service-care' ? 'Servis/Bakım:' : ''}}
            {{auth === 'settings-firminfo' ? 'Ayarlar - Firma Bilgileri:' : ''}}
            {{auth === 'settings-payment' ? 'Ayarlar - Ödeme Ayarları:' : ''}}
            {{auth === 'settings-payment-method' ? 'Ayarlar - Ödeme Yöntemleri:' : ''}}
            {{auth === 'settings-payment-mobile' ? 'Ayarlar - Mobil Ödeme Yöntemleri:' : ''}}
            {{auth === 'settings-payment-templates' ? 'Ayarlar - Ödeme Şablonları:' : ''}}
            {{auth === 'settings-payment-systems' ? 'Ayarlar - Ödeme Sistemleri:' : ''}}
            {{auth === 'settings-payment-orders' ? 'Ayarlar - Ödeme İstekleri:' : ''}}
            {{auth === 'card-packets' ? 'Kart Paketleri:' : ''}}
            {{auth === 'card-packets-edit' ? 'Kart Paketleri Güncelleme:' : ''}}
            {{auth === 'offline-cards' ? 'Kartları Görüntüleme:' : ''}}
            {{auth === 'offline-cards-edit' ? 'Kartları Güncelleme:' : ''}}
          </small>

          <label class="myCheckbox col-3 text-right">
            <input #yescheck type="radio" style="display: none;"
                   [checked]="_selectedUser.telemetryPermission.includes(auth)"
                   (change)="changePermArray(auth,$event,true)"
                   [name]="auth+'sw'">
            <span class="mr-1" style="vertical-align: bottom"></span>
            <small [ngStyle]="{color: yescheck.checked ? '#22b217': '#707070'}">Açık</small>
          </label>

          <label class="myCheckbox2 col-4">
            <input #nocheck type="radio" style="display: none;"
                   [checked]="!_selectedUser.telemetryPermission.includes(auth)"
                   (change)="changePermArray(auth,$event,false)"
                   [name]="auth+'sw'">
            <span class="mr-1" style="vertical-align: bottom"></span>
            <small [ngStyle]="{color: nocheck.checked ? '#ff5c64': '#707070'}">Kapalı</small>
          </label>


        </div>


      </div>
    </div>


  </form>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="_selectedUser._id" class="btn btn-danger" (click)="deleteUser()">Kullanıcıyı sil
  </button>
  <div class="col"></div>
  <button type="button" class="btn btn-success " style="width: 150px" (click)="saveUser()">Kaydet</button>
</div>

<ng-template #newUserTemp>
  <h4 class="modal-title">Yeni Kullanıcı</h4>
</ng-template>
