<div *ngIf="_user" class="wrap">
  <div class="container-fluid header">
    <div class="row justify-content-end firmcont">
      <img *ngIf="firmLogo" class="col-auto onlyweb" [src]="firmLogo | image: FirmImagePath" height="100%">
    </div>

    <div (click)="changeImage()" class="container p-0 userpiccon">
      <img class="userimage" [src]="(_user.photo | image: ProfileImagePath) || 'assets/img/user_image.png'"
           width="100%">
      <div class="middle">
        <i class="fas fa-pencil-alt imageicon"></i>
      </div>
    </div>

  </div>
  <div *ngIf="ft" class="container">
    <app-dynamic-form [questions]="ft" (submit)="formSubmit($event)"></app-dynamic-form>
  </div>

  <div class="container my-5">
    <div class="row justify-content-end">
      <button class="btn btn-primary form-submit mr-5" (click)="update()">{{'USER_INFO.UPDATE' | translate}}</button>
    </div>
  </div>


</div>


<ng-template #imageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'USER_INFO.UPLOAD_USER_PICTURE' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <div class="file-input-wrapper">
            <button class="btn btn-primary modal-buton mt-4 mb-2 pull-left btn-file" (click)="fileInput.click()"
                    style="width: 50%">Gözat
            </button>
            <input #fileInput style="display: none" id="edit-crop-input" type="file"
                   (change)="fileChangeEvent($event)"/>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                [resizeToWidth]="180"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (loadImageFailed)="imageLoadFailed()"
                style="max-height: 180px; width: 180px"
                [style.display]="cropperReady ? null : 'none'">
              </image-cropper>

              <span *ngIf="croppedImage" class="align-self-center" style="font-size: x-large"> &rarr; </span>

              <div *ngIf="croppedImage">
                <img [src]="croppedImage" style="max-width: 100%;max-height: 180px;border: solid 1px #b7b7b7;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="updateProfileImage()">{{'USER_INFO.SAVE' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">{{'USER_INFO.CANCEL' | translate}}</button>
  </div>
</ng-template>
