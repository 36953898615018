import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../../_guards/auth.guard';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {AccountInfoComponent} from './account-info/account-info.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {FirmInfoComponent} from './firm-info/firm-info.component';
import {SaveCreditCardComponent} from './save-credit-card/save-credit-card.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user-info',
    pathMatch: 'full'
  },
  {
    path: 'user-info',
    component: AccountInfoComponent,
    canActivate: [AuthGuard],
    data: {
      settings: true,
    },

  },
  {
    path: 'firm-info',
    component: FirmInfoComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      settings: true,
      permissions: {
        only: 'settings-firminfo',
        redirectTo: '/dashboard'
      }
    }

  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      settings: true,
    },
  },
  {
    path: 'storage-card',
    component: SaveCreditCardComponent,
    canActivate: [AuthGuard],
    data: {
      settings: true,
    },

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccountSettingsRoutingModule {
}

