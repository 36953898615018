<div id="wrapper">
  <div id="sidenav-wrapper">
    <a class="sidenav-toggler" data-toggle="collapse" data-target="#sidenav" aria-expanded="true"
       aria-controls="sidenav" id="sidenavToggler" (click)="setVisibilitySideNav()"><i class="fas fa-bars"></i> Ayarlar </a>

    <div class="sidenav show" role="tablist" id="sidenavId">
      <label class="sidenav-header">{{'SETTINGS.SETTING' | translate}}</label>
      <ul class="nav nav-pills flex-column" role="tablist">
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".accountSettings" aria-expanded="false"
             aria-controls="accountSettings1 accountSettings2 accountSettings3 accountSettings4" aria-selected="false">{{'SETTINGS.ACCOUNT' | translate}}</a>
        </li>
        <li class="nav-item accountSettings collapse show" id="accountSettings1">
          <a class="nav-link" [ngClass]="links.account.info ? 'active' : ''" routerLink="settings/account/user-info"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.ACCOUNT_INFO' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-firminfo']" class="nav-item accountSettings collapse show" id="accountSettings2">
          <a class="nav-link" [ngClass]="links.account.firm ? 'active' : ''" routerLink="settings/account/firm-info"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.FIRM_INFO' | translate}}</a>
        </li>
        <li class="nav-item accountSettings collapse show" id="accountSettings3">
          <a class="nav-link" data-toggle="modal" data-target="#editPassword">{{'SETTINGS.CHANGE_PASSWORD' | translate}}</a>
        </li>
        <li class="nav-item accountSettings collapse show" id="accountSettings4">
          <a class="nav-link" [ngClass]="links.account.card ? 'active' : ''" routerLink="settings/account/storage-card"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.STORAGE_CARD' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-payment']" class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".paymentSettings" aria-expanded="true"
             aria-controls="paymentSettings1 paymentSettings2 paymentSettings3 paymentSettings4 paymentSettings5" aria-selected="false">{{'SETTINGS.PAY' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-payment']" class="nav-item paymentSettings collapse show" id="paymentSettings1">
          <a class="nav-link" [ngClass]="links.payment.method ? 'active' : ''" routerLink="settings/payment/method"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.PAYMENT_METHODS' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-payment-templates']" class="nav-item paymentSettings collapse show" id="paymentSettings3">
          <a class="nav-link" [ngClass]="links.payment.templates ? 'active' : ''" routerLink="settings/payment/templates"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.PAYMENT_TEMPLATES' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-payment-systems']" class="nav-item paymentSettings collapse show" id="paymentSettings4">
          <a class="nav-link" [ngClass]="links.payment.systems ? 'active' : ''" routerLink="settings/payment/systems"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.VENDING_PAYMENT_METHOD' | translate}}</a>
        </li>
        <li *ngxPermissionsOnly="['settings-payment-orders']" class="nav-item paymentSettings collapse show" id="paymentSettings5">
          <a class="nav-link" [ngClass]="links.payment.orders ? 'active' : ''" routerLink="settings/payment/orders"
             (click)="closeSideNavIfSmallScreen()">{{'SETTINGS.PAYMENT_ORDERS' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target=".apps" aria-expanded="false"
             aria-controls="apps1" aria-selected="false">{{'APPS.APPS' | translate}}</a>
        </li>
        <!-- <li class="nav-item apps collapse show" id="apps1">
          <a class="nav-link" [ngClass]="links.apps.payter ? 'active' : ''" routerLink="settings/apps/payter"
             (click)="closeSideNavIfSmallScreen()">PAYTER</a>
        </li> -->
      </ul>
    </div>
  </div>

<div id="content-wrapper">
  <router-outlet></router-outlet>
</div>

</div>

<div class="modal fade" id="editPassword" tabindex="-1" aria-labelledby="editPasswordLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editPasswordLabel">{{'SETTINGS.CHANGE_PASSWORD' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-row">
            <div class="form-group col">
                <label>{{'SETTINGS.OLD_PASSWORD' | translate}}</label>
                <input type="password" formControlName="oldpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.oldpassword.errors }" />
                <div *ngIf="submitted && f.oldpassword.errors" class="invalid-feedback">
                    <div *ngIf="f.oldpassword.errors.required">{{'SETTINGS.PASSWORD_REQUIRED' | translate}}</div>
                </div>
            </div>
            <div class="form-group col">
                <label>{{'SETTINGS.NEW_PASSWORD' | translate}}</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{'SETTINGS.PASSWORD_REQUIRED' | translate}}</div>
                    <div *ngIf="f.password.errors.minlength">{{'SETTINGS.PASSWORD_LENGTH' | translate}}</div>
                    <div *ngIf="f.password.errors.pattern">{{'SETTINGS.PASSWORD_INFORMATION' | translate}}</div>
                </div>
            </div>
            <div class="form-group col">
                <label>{{'SETTINGS.CONFIRM_PASSWORD' | translate}}</label>
                <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">{{'SETTINGS.PASSWORD_REQUIRED' | translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">{{'SETTINGS.PASSWORD_CHECK' | translate}}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" type="reset" (click)="onReset()">{{'SETTINGS.CLOSE' | translate}}</button>
          <button type="submit" class="btn btn-primary">{{'SETTINGS.SAVE_CHANGES' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
