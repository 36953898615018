import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FirmUser} from '../../../_models/firmUser';
import {FirmUserService} from '../../../_services/firm-user.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {FormBuilder} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {SwalHelper} from '../../../_helpers/swal.helper';
import {ImageService} from '../../../_services/image.service';
import {environment} from '../../../../environments/environment';
import {FormTemplate} from '../../../_models/formTemplate';
import {DynamicFormComponent} from '../../../_widgets/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfoComponent implements OnInit {

  ProfileImagePath = environment.ProfileImagePath;
  FirmImagePath = environment.FirmImagePath;
  swalOptions = new SwalHelper();
  @ViewChild('imageModal') imageModal: ElementRef;
  // Resim ekleme değişkenleri
  cropInput: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;

  // END : Resim ekleme değişkenleri

  @ViewChild(DynamicFormComponent) private dyForm: DynamicFormComponent;
  edit = false;
  _user: FirmUser;
  ft: Array<FormTemplate<string>>;
  firmLogo: string;

  constructor(private firmuserService: FirmUserService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private imageService: ImageService) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.firmLogo = this.auth.user$.getValue().firm.photo || null;
    const userid = this.auth.user$.getValue()._id;
    this.firmuserService.getUser(userid).subscribe(res => {
      console.log('getuser', res);
      this._user = res;
      this.getFormTemplate(this._user);
      this.updateLocal();
    }, error => {

    });
  }

  getFormTemplate(data: FirmUser) {
    if (data) {
      this.ft = new FirmUser(data).getFormTemplate();
    }
  }

  formSubmit(event) {
    console.log(event);

    this.firmuserService.updateFirmUser(event, this._user._id).subscribe((res: any) => {
      if (res.success) {
        this.modalService.dismissAll();
        this.load();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Kullanıcı bilgileri güncellendi'
        });
      }
    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kullanıcı bilgileri güncellenirken hata oluştu'
      });
    });
  }

  update() {
    this.dyForm.onSubmit();
  }

  updateLocal() {
    const cUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this._user) {
      for (const prop in this._user) {
        cUser.user[prop] = this._user[prop];
      }
    }
    this.auth.user$.next(cUser.user);
    localStorage.setItem('currentUser', JSON.stringify(cUser));
  }

  switchEdit() {
    this.edit = !this.edit;
  }


  updateProfileImage() {

    if (this.croppedImage && this.croppedImage !== '') {
      let fileName = this.cropInput[0].name;
      fileName = fileName.split('.');
      const formData = new FormData();
      const ImageFile = this.convertToFile(this.croppedImage,
        fileName[0] + '_' + this._user._id + '.' + fileName[1]);
      formData.append('upfile', ImageFile);

      //servise formdata gönder

      this.imageService.addUserImage(formData, this._user._id).subscribe((res) => {
        console.log(res);
        this.modalService.dismissAll();
        this.load();
      });
    }
  }

  // Image crop functions
  changeImage() {
    const modalRef = this.modalService.open(this.imageModal, {centered: true});

  }

  fileChangeEvent(event: any): void {
    this.cropInput = event.target.files;
    this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageLoadFailed() {
    Swal.fire({
      ...this.swalOptions.error,
      text: 'Lütfen png yada jpeg resim seçiniz'
    });
  }

  convertToFile(base64Str: string, fileName: string) {

    const binary = atob(base64Str.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new File([new Uint8Array(array)], fileName, {type: 'image/jpeg', lastModified: Date.now()});
  }

  // END : Image crop functions

}
