import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

import {NgxSpinnerModule} from 'ngx-spinner';
import {PipesModule} from '../_pipes/pipes.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {GoogleMapsModule} from '@angular/google-maps';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    GoogleMapsModule,
    NgxSpinnerModule,
    PipesModule,
    NgxPermissionsModule.forChild(),
    SharedModule
  ],
  exports: [
  ],
  declarations: [DashboardComponent]
})
export class DashboardModule { }
