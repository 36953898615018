export class SwalHelper {
  constructor() {
  }

  default: any = {
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };
  question: any = {
    icon: 'question',
    confirmButtonText: 'Evet',
    cancelButtonText: 'Vazgeç',
    showCancelButton: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };
  delete: any = {
    title: 'Bu işlem geri alınamaz!',
    icon: 'warning',
    confirmButtonText: 'Onayla',
    cancelButtonText: 'Vazgeç',
    showCancelButton: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-danger swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };
  success: any = {
    title: 'Başarılı!',
    icon: 'success',
    confirmButtonText: 'Tamam',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-success swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };
  error: any = {
    title: 'Hata!',
    icon: 'error',
    confirmButtonText: 'Tamam',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };
  info: any = {
    title: 'Uyarı!',
    icon: 'info',
    confirmButtonText: 'Tamam',
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-primary swal-buttons mx-1',
      cancelButton: 'btn btn-outline-primary swal-buttons mx-1'
    }
  };


}
