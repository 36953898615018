import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';


@Injectable({
  providedIn: 'root'
})
export class ParamPaymentService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  checkAndStorageCreditCard(cardInfo: any): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    return this.http.post(paymentApiBaseUrl + 'payment/param/checkAndStorageCard',
        cardInfo,
        {headers: this.headers}).pipe(
        catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


  getStaoregeCard(firmId: string): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    return this.http.get(paymentApiBaseUrl + 'payment/param/firmStorageCard/' + firmId,
        {headers: this.headers}).pipe(
        catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


}
