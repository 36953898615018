import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../_services/product.service';
import {BasketReportFilter, SaleReportFilter} from '../_models/saleReportFilter';
import {AutomatService} from '../_services/automat.service';
import {FirmService} from '../_services/firm.service';
import {AutomatGroupService} from '../_services/automat-group.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-basket-report',
  templateUrl: './basket-report.component.html',
  styleUrls: ['./basket-report.component.css']
})
export class BasketReportComponent implements OnInit {

  @ViewChild('dropdownFilter', {static: false}) dropdownToggle: ElementRef;
  basketProductsData = {products: [], amount: 0};
  ProductImagePath: string;
  @ViewChild('showBasketProductsModal', {static: false}) showBasketProductsModal: ElementRef;

  swalOptions = new SwalHelper();

  filter: BasketReportFilter = new BasketReportFilter();
  currentUser: any = {};
  automatDistricts: any[];
  automatGroups: any[];
  automatNameIDs: any[];
  groupText: string;
  automatText: string;
  paymentTypeText: string;
  resultTypeText: string;
  today = new Date();
  selectedTimeTab = 'date';
  rows: Array<any> = [];
  page = 1;
  pages = 1;
  params: any = {};

  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  // END : Ngx-spinner değişkenleri (Loading)

  constructor(private productService: ProductService,
              private automatService: AutomatService,
              private automatGroupService: AutomatGroupService,
              private firmService: FirmService,
              private loadingSpinner: NgxSpinnerService,
              private exportsHelper: ExportsHelperService) {
    this.ProductImagePath = environment.ProductImagePath;
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.loadingSpinner.show();
    this.changeTimeTab(this.selectedTimeTab);

    this.automatService.getFirmAutomatDistricts().subscribe(response => {
      this.automatDistricts = response.result;
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomat bölgeleri sunucudan getirilirken hata oluştu!'
      });
    });

    this.automatGroupService.getFirmAutomatGroups().subscribe(response => {
      this.automatGroups = response.result;
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomat grup listesi sunucudan getirilirken hata oluştu!'
      });
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 500) {
      $('#topButton').fadeIn();
    } else {
      $('#topButton').fadeOut();
    }
  }

  topFunction() {
    $('html, body').animate({scrollTop: 0}, 500);
  }

  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    $('#page-loader').fadeIn();
    this.page += 1;
    this.productService.getBasketsReport(this.page, this.params).subscribe(res => {
      $('#page-loader').fadeOut();
      for (const item of res.result) {
        this.rows.push(item);
      }
      this.pages = res.pages;
    }, error => {
      $('#page-loader').fadeOut();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Raporun devamı yüklenemedi!'
      });
    });
  }

  getPaymentTypeText(type): string {
    switch (type) {
      case 1:
        return 'Kredi Kart';
      case 2:
        return 'Nakit';
      case 3:
        return 'Offline Kart';
      case 4:
        return 'İstanbul Kart';
      case 5:
        return 'QR';
      case 6:
        return 'MetropolCard QR';
      default:
        return '-';
    }
  }

  changeTimeTab(text) {
    this.selectedTimeTab = text;
    this.applyFilter();
  }

  getTextOfPaymentType(event) {
    this.paymentTypeText = event.target.options[event.target.options.selectedIndex].text;
  }

  getTextOfResultType(event) {
    this.resultTypeText = event.target.options[event.target.options.selectedIndex].text;
  }

  getTextOfGroup(event) {
    this.groupText = event.target.options[event.target.options.selectedIndex].text;
  }

  getTextOfAutomat(event) {
    this.automatText = event.target.options[event.target.options.selectedIndex].text;
  }

  getAutomatNameID() {
    this.getTextOfGroup(event);
    this.loadingSpinner.show();
    if (this.filter.group !== '') {
      this.firmService.getFirmAutomatGroup(this.filter.group).subscribe(res => {
        this.automatNameIDs = res.result;
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Grubun otomatları getirilirken hata oluştu!'
        });
      });
    } else {
      this.automatNameIDs.length = 0;
      this.loadingSpinner.hide();

    }
  }

  getBasketHistoryProducts(id) {
    this.loadingSpinner.show();
    this.basketProductsData = {products: [], amount: 0};
    this.productService.getBasketHistoryProducts(id).subscribe(res => {
      this.basketProductsData = res[0];
      this.showBasketProductsModal.nativeElement.click();
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bu sepetin ürünleri getirilirken hata oluştu!'
      });
    });
  }

  downloadExcel() {

    if (this.rows.length === 0) {
      Swal.fire({
        ...this.swalOptions.info,
        text: 'Tablo içerisinde veri yok!'
      });
      return;
    }
    this.exportsHelper.basketReportDownloadExcel(
      this.rows,
      this.getFilterDate(this.selectedTimeTab)[0],
      this.getFilterDate(this.selectedTimeTab)[1]
    );

  }


  applyFilter() {
    this.params = {};
    if (this.filter.productPrice !== undefined) {
      this.params.productPrice = Number(this.filter.productPrice);
    }
    if (this.filter.amount !== undefined) {
      this.params.amount = Number(this.filter.amount);
    }
    if (this.filter.resultType.toString() !== '-1') {
      this.params.resultType = Number(this.filter.resultType);
    }
    if (this.filter.paymentType.toString() !== '-1') {
      this.params.paymentType = Number(this.filter.paymentType);
    }
    if (this.filter.district !== '') {
      this.params.district = this.filter.district;
    }
    if (this.filter.group !== '') {
      this.params.group = this.filter.group;
    }
    if (this.filter.automat !== '') {
      this.params.automat = this.filter.automat;
    }
    if (this.filter.productName !== '') {
      this.params.productName = this.filter.productName;
    }
    this.loadingSpinner.show();
    this.params.kind = this.selectedTimeTab;
    if (this.dropdownToggle) {
      this.dropdownToggle.nativeElement.click();
    }
    this.productService.getBasketsReport(this.page, this.params).subscribe(res => {
      this.rows = res.result;
      this.pages = res.pages;
      if (this.rows.length === 0) {
        Swal.fire({
          ...this.swalOptions.info,
          text: 'Bu kriterlerde gösterilecek veri yok!'
        });
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Filtre verileri sunucudan getirilirken hata oluştu!'
      });
    });
  }

  clearFilter() {
    this.filter = new BasketReportFilter();
    this.params = {};
    this.changeTimeTab(this.selectedTimeTab);
  }

  getFilterDate(kind) {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    if (kind === 'week') {

      const day = today.getDay(),
        diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      startDate = new Date(today.setDate(diff));
      startDate.setHours(0, 0, 0, 0);
      const startMonday = new Date(startDate);
      endDate = new Date(startMonday.setTime(startMonday.getTime() + 6 * 86400000));
      endDate.setHours(23, 59, 59, 999);

    } else if (kind === 'month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      endDate.setHours(23, 59, 59, 999);
    }
    return [moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY')];
  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
}
