import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as XLSX from 'xlsx';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CashlessService} from '../../_services/cashless.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {SwalHelper} from '../../_helpers/swal.helper';
import {OfflineUser} from '../../_models/offlineUser';
import {OfflineUserGroup} from '../../_models/offlineUserGroup';
import { ExportsHelperService } from '../../_helpers/exports.helper';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-user-to-card-packet',
  templateUrl: './add-user-to-card-packet.component.html',
  styleUrls: ['./add-user-to-card-packet.component.css']
})
export class AddUserToCardPacketComponent implements OnInit {

  swalOptions = new SwalHelper();

  fileName;
  isInProggress = false;
  offlineUsersArray = [];
  controlVariables: { isInEdit: boolean, index: number, offlineUserGroupId: string };
  groupData: Array<{ totalUser: number, offlineUserGroup: OfflineUserGroup }>;
  offlineUser = new OfflineUser();

  @Input()
  set groups(data: Array<{ totalUser: number, offlineUserGroup: OfflineUserGroup }>) {
    this.groupData = data;
  }

  @Input()
  set conVariables(data: { isInEdit: boolean, index: number, offlineUserGroupId: string }) {
    this.controlVariables = data;
    this.setOfflineUserGroupId(data.offlineUserGroupId);
  }
  @Output() submit = new EventEmitter<{success: boolean, code: number, data: any}>();

  constructor(
    public activeModal: NgbActiveModal,
    private cashlessService: CashlessService,
    private loadingSpinner: NgxSpinnerService,
    private exportsHelper: ExportsHelperService
  ) {
  }

  ngOnInit(): void {
  }

  onFileChange(evt: any) {
    const file = evt.target.files[0];
    this.fileName = file.name;
    this.offlineUsersArray = undefined;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      throw new Error('Birden fazla dosya kullanılamaz!');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.offlineUsersArray = XLSX.utils.sheet_to_json(ws, {header: 1});
      if (Array.isArray(this.offlineUsersArray)) {
        for (let _i = 0; _i < this.offlineUsersArray.length; _i++) {
          const data = this.offlineUsersArray[_i][1];
          const data2 = this.offlineUsersArray[_i][2];
          if (typeof data === 'string' || data instanceof String || typeof data2 === 'string' || data2 instanceof String) {
            this.offlineUsersArray[_i][1] = data ? data.toLowerCase() : null;
            this.offlineUsersArray[_i][2] = data2 ? data2.toLowerCase() : null;
          } else {
            this.offlineUsersArray = undefined;
            break;
          }
        }
      } else {
        this.offlineUsersArray = undefined;
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

    /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.onFileChange($event);
  }

  downloadSampleExcel() {
    this.loadingSpinner.show();
    this.exportsHelper.sampleDownloadExcel();
    this.loadingSpinner.hide();
  }

  closeModal(form: NgForm) {
    form.resetForm();
    this.activeModal.close();
  }

  sendToDatabase() {
    this.loadingSpinner.show();
    if (this.offlineUsersArray) {
      let offlineGroupAutomatId = [];
      if (this.isInProggress) {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Zaten devam eden bir işlem var!'
        });
        return;
      }
      this.groupData.forEach((data, i) => {
        if (this.controlVariables.offlineUserGroupId === data.offlineUserGroup._id) {
          offlineGroupAutomatId = data.offlineUserGroup.automatGroup;
        }
      });
      this.isInProggress = true;
      this.cashlessService.addOfflineUsers(this.offlineUsersArray,
        this.controlVariables.offlineUserGroupId, offlineGroupAutomatId).subscribe(res => {
          if (res.success) {
            //closemodal
            this.submit.emit({success: true, code: 1, data: null});

            Swal.fire({
              ...this.swalOptions.success,
              text: 'Kişiler başarıyla kayıt edildi!'
            });
            this.isInProggress = false;
            this.loadingSpinner.hide();
          } else {
            if (res.code == 504) {
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Bazı kartlar daha önce kayıt edilmiştir. Dosyaını kontrol ediniz ve tekrar yükleyiniz!'
              });
            } else {
                Swal.fire({
                  ...this.swalOptions.error,
                  text: 'Hata!'
                });
            }
          }
      }, error => {
        this.loadingSpinner.hide();

        this.isInProggress = false;
        this.loadingSpinner.hide();
        if (error.message.code === 11000 && error.status === 500) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
              error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'
          });
        } else if (error.status === 400) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz Excel dosyasının ' + error.index + '. satırı yapıya uygun değil.'
          });
        } else if (error.status === 501) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz Excel dosyasının ' + error.index + '. satırında kart numaraları (HEX ve DEC) uyuşmuyor!.'
          });
        } else if (error.status === 502) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart numarası (HEX) hatalı!: satır ' + error.index
          });
        } else if (error.status === 503) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart numarası (DEC) hatalı!: satır ' + error.index
          });
        } else {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kişiler veritabanına eklenirken hata oluştu!'
          });
        }
      });
    } else {
      this.loadingSpinner.hide();
      this.isInProggress = false;
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kart numaralarının, 16 yada 10 tabanlı olarak girilmesi gerekmektedir'
      });
    }
  }


  isFieldsOkay(): boolean {
    let isOkay = true;
    if (this.offlineUser.name === '') {
      isOkay = false;
    }
    if (this.offlineUser.sicilNo === '') {
      isOkay = false;
    }
    if (this.offlineUser.gorev === '') {
      isOkay = false;
    }
    if (this.offlineUser.birim === '') {
      isOkay = false;
    }
    if (this.offlineUser.cardId === '') {
      isOkay = false;
    }
    if (this.offlineUser.firmBalance === null) {
      isOkay = false;
    }
    if (isOkay) {
      return this.hexDecControls(this.offlineUser.cardId, this.offlineUser.cardIdDec);
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen boş alan bırakmayınız!'
      });
      return false;
    }
  }

  hexDecControls(hex: string, dec: string): boolean {
    if (!hex.match(/^([a-fA-F0-9]){1,8}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '16 tabanlı uid en fazla 8 haneli olabilir, ve 0-9 a-f değerleri alabilir'
      });
      return false;
    }
    if (!dec.match(/^([0-9]){1,10}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '10 tabanlı uid en fazla 10 haneli olabilir ve harf içeremez'
      });
      return false;
    }

    return true;
  }

  createOfflineUser() {
    if (this.isFieldsOkay()) {
      this.loadingSpinner.show();
      this.groupData.forEach(data => {
        if (this.controlVariables.offlineUserGroupId === data.offlineUserGroup._id) {
          this.offlineUser.automatGroup = data.offlineUserGroup.automatGroup;
        }
      });
      this.offlineUser.cardId = this.offlineUser.cardId.toLowerCase();
      this.cashlessService.createOfflineUser(this.offlineUser).subscribe(res => {

        this.submit.emit({success: true, code: 2, data: null});

        Swal.fire({
          ...this.swalOptions.success,
          text: 'Kişi başarıyla kayıt edildi!'
        });
        this.loadingSpinner.hide();

      }, error => {

        if (error.message.code === 11000) {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
              error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'
          });
        } else {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kişi veritabanına kaydedilirken hata oluştu!'
          });
        }
      });
    } else {
      this.loadingSpinner.hide();
    }
  }

  InputIdChange(event, isHex) {
    if (isHex) {
      const id = parseInt(event, 16).toString().toLowerCase();
      this.offlineUser.cardIdDec = id === 'nan' ? '' : id;
    } else {
      const id = parseInt(event).toString(16).toLowerCase();
      this.offlineUser.cardId = id === 'nan' ? '' : id;
    }
  }

  setOfflineUserGroupId(id) {
    this.offlineUser.offlineUserGroups = [id];

    //openModal add-user-to-card
  }


  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
}
