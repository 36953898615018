
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EndOfAutomatSaleHistoryService {

  private headers =  new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token});

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {}

  getAutomatEndOfAutomatSaleHistory(automat: string, page): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'getAutomatEndOfAutomatSaleHistory/' + automat + '/' + page,
      { headers: this.headers } ).pipe(
      map((response: Response) => {
        // login successful if there's a jwt token in the response
        if (response) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        }
      }));
  }
  getFirmFilterEndOfAutomatSaleHistory(filter, page): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'getFirmFilterEndOfAutomatSaleHistory/' + page,
      JSON.stringify(filter),
      { headers: this.headers } ).pipe(
      map((response: Response) => {
        // login successful if there's a jwt token in the response
        if (response) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        }
      }));
  }
}
