<div class="row element">
  <div class="col-md-1 col-2 p-0 rowheight">
    <ng-template [ngIf]="_automat.image !== null && _automat?.image !== ''" [ngIfElse]="checkModelImage">
      <img class="center" src="{{_automat?.image | image: AutomatImagePath}}"
           height="100%">
    </ng-template>
    <ng-template #checkModelImage>
      <img *ngIf="_automat?.automatModel?.image !== null && _automat?.automatModel?.image !== ''; else noImage"
           class="center"
           src="{{_automat?.automatModel?.image | image: AutomatImagePath}}"
           height="100%">
      <ng-template #noImage>
        <img class="center" src="../../../assets/img/vending-machine.png" height="100%">
      </ng-template>
    </ng-template>
  </div>

  <div class="col-md-3 col-5 rowheight">
    <div class="container overflow-hidden" style="max-height: 80%;">
      <div class="row ml-1 mt-3 mb-1 overflow-hidden text-nowrap">
              <span class="automatname"
                    [ngClass]="{'slide-text': _automat.name?.length > 25}">
                {{_automat.name}}
              </span>
      </div>
      <div class="row overflow-hidden ml-1">
        <div class="automatdesc"
             [ngClass]="{'scroll': _automat.description && _automat.description.length > 50}">
          {{_automat.description ? _automat.description : 'Açıklama Yok'}}
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3 col-5 rowheight d-flex flex-column">
    <div class="row mb-1 mt-auto automatdesc">
      {{_automat.locationText}}
    </div>
    <div *ngIf="(_automat.lastInformed != undefined && _automat.lastInformed!= null)"
         class="row align-items-center mb-auto">
      <img src="{{getAutomatStatus(_automat.lastInformed[0]) ? onlineAutomat : offlineAutomat}}" height="15px">
      <div [class]="status ? 'automatdesc mx-1 active' : 'automatdesc mx-1 passive'">
        {{status ? 'Aktif' : 'Pasif:'}}
      </div>
      <div class="automatdesc">{{statusDesc}}</div>
    </div>
    <div *ngIf="(_automat.lastInformed != undefined && _automat.lastInformed == null)"
         class="row align-items-center mb-auto"
         data-toggle="tooltip" data-placement="bottom" title="Son aktif olduğu tarih bilgisi alınamadı.">
      <img src="../../../assets/img/uyari.svg" height="15px">
    </div>
    <div *ngIf="(_automat.lastInformed == undefined)" class="row align-items-center mb-auto"
         data-toggle="tooltip" data-placement="bottom" title="Aktiflik bilgisi tanımlı değil.">
      <img src="../../../assets/img/uyari.svg" height="15px">
    </div>
  </div>

  <div class="col-md-2 col-6 rowheight d-flex flex-column">
    <div class="row ml-1 my-auto automatname">
      <!-- {{_stats.stock}} Ürün / {{_stats.price}} ₺ -->
    </div>
  </div>

  <div class="col-md-3 col-6 rowheight d-flex align-items-center">
    <div class="row ml-auto">
      <button class="btn btn-primary listbutton m-1" (click)="goToAutomatDetail(_automat._id)">Detaylar</button>
      <button class="btn" (click)="close()">
        <img src="../../../assets/img/iptal.svg" width="28px">
      </button>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="container p-0">

    <div class="row table-header px-1">
      <div class="col-2">İşlem Türü</div>
      <div class="col-2">İşlem Tarihi</div>
      <!--<div class="col-2">İşlem</div>-->
      <div class="col">Açıklama</div>
      <div class="col-2"></div>

    </div>

    <div
      style="height: 500px;width: 100%; overflow-y: scroll"
      class="mb-3 scrollRef"
      infiniteScroll
      [infiniteScrollDistance]="8"
      [infiniteScrollThrottle]="20"
      [infiniteScrollContainer]="'.scrollRef'"
      [fromRoot]="true"
      (scrolled)="onScroll()">
      <div *ngFor="let data of rows" class="row px-1 table-body-row">
        <div class="col-2 d-flex">
          <div class="my-auto mx-2"
               [ngClass]="{'greenBold': data.automatIssue[0].name === 'Dolum','redBold': data.automatIssue[0].name === 'Arıza'}">
            {{data.automatIssue[0].name}}
          </div>
        </div>
        <div class="col-2 d-flex">
          <div class="my-auto"> {{data.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
        </div>
        <!--<div class="col-2 d-flex">
          <div class="my-auto" [ngClass]="{'greenBold': data.automatIssue[0].name === 'Dolum','redBold': data.automatIssue[0].name === 'Arıza'}">
            {{data.automatIssue[0].name}}
          </div>
        </div>-->
        <div class="col d-flex">
          <div class="my-auto">{{data.automatIssue[0].text}}</div>
        </div>
        <div class="col-2 d-flex">
          <button class="btn btn-outline-primary rowbutton ml-auto">İncele</button>
        </div>
      </div>
    </div>


  </div>

  <div class="container mb-2" id="page-loader" style="display: none">
    <div class="row justify-content-center">
      <img src="../../../assets/img/page-loader.svg" width="80">
    </div>
  </div>

</div>
