import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'commaDotSwapper'
})
export class CommaDotSwapperPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== undefined && value !== null) {
      return value.toString().replace(/,/g, 'a').replace(/[.]/g, ',').replace(/a/g, '.');
    } else {
      return '';
    }
  }

}
