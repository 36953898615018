import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {Observable, throwError as observableThrowError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionStatementService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
    ) { }


    getPaymentProductInfo(paymentId, paymentSystemsFirmCode): Observable<any> {
      return this.http.post(environment.apiBaseUrl + 'getPaymentProductInfo/',
      JSON.stringify({paymentId: paymentId, paymentSystemsFirmCode: paymentSystemsFirmCode}), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }
    getPaymentParameters(paymentId, paymentSystemsFirmCode): Observable<any> {
      return this.http.post(environment.apiBaseUrl + 'getPaymentParameters/',
      JSON.stringify({paymentId: paymentId, paymentSystemsFirmCode: paymentSystemsFirmCode}), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }
    getPaymentAutomatInfo(paymentId, paymentSystemsFirmCode): Observable<any> {
      return this.http.post(environment.apiBaseUrl + 'getPaymentAutomatInfo/',
      JSON.stringify({paymentId: paymentId, paymentSystemsFirmCode: paymentSystemsFirmCode}), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }
    getPaymentTransactionInfo(paymentId, paymentSystemsFirmCode): Observable<any> {
      return this.http.post(environment.apiBaseUrl + 'getPaymentTransactionInfo/',
      JSON.stringify({paymentId: paymentId, paymentSystemsFirmCode: paymentSystemsFirmCode}), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }
    getRefundPayment(paymentId, paymentSystemsFirmCode): Observable<any> {
      return this.http.post(environment.apiBaseUrl + 'getRefundPayment/',
      JSON.stringify({paymentId: paymentId, paymentSystemsFirmCode: paymentSystemsFirmCode}), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

}
