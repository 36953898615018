import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Product} from '../../_models/product';
import {ProductContent} from '../../_models/productContent';
import {AutomatService} from '../../_services/automat.service';
import {PowderProduct} from '../../_models/powderproduct';
import {environment} from '../../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProductService} from '../../_services/product.service';
import {HttpClient} from '@angular/common/http';
import {ProductBrand} from '../../_models/productBrand';
import {ProductCategory} from '../../_models/productCategory';
import {SwalHelper} from '../../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {EvendProduct} from '../../_models/evendProduct';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { BrandService } from '../../_services/brand.service';
import { CategoryService } from '../../_services/category.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
  @ViewChild('brandSelect') brandSelect: NgSelectComponent;
  @ViewChild('categorySelect') categorySelect: NgSelectComponent;

  ProfileImagePath = environment.ProfileImagePath;
  ProductImagePath = environment.ProductImagePath;

  swalOptions = new SwalHelper();
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  product: Product;
  _brands: Array<ProductBrand>;
  _brand: ProductBrand = null;
  _categories: Array<ProductCategory>;
  _category: ProductCategory = null;
  evendProducts: Array<EvendProduct>;

  page = 1;
  pages = 1;
  total = 0;
  defaultValue = null;
  loading = false;
  filter: Filter = {
    brandId: '',
    categoryId: '',
    name: ''
  };

  @Input()
  set selectedProduct(data: Product) {
    this.product = data || new Product();
    if (this.product.isHot) {
      this.formContentInit();
      this.product.content.forEach((con: ProductContent) => {
        this.productContents.forEach((Ccon: ProductContent, index) => {
          this.productContents[index] = con.powderProductID === Ccon.powderProductID ? con : Ccon;
        });
      });
    } else {
      this.formContentInit();
    }
  }



  @Output() changeProduct = new EventEmitter<{ event: string, data: Product }>();


  powderProducts: Array<PowderProduct> = new Array<PowderProduct>();
  productContents: Array<ProductContent> = new Array<ProductContent>();


  constructor(private http: HttpClient,
              private brandService: BrandService,
              private categoryService: CategoryService,
              private automatService: AutomatService,
              private loadingSpinner: NgxSpinnerService,
              private productService: ProductService,
              public activeModal: NgbActiveModal
  ) {

  }

  ngOnInit() {

    this.brandService.getBrands(this.page).subscribe(response => {
      this._brands = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }, error => {
      console.log(error);
      if (error.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error
        });
        console.log('Error:', error);
      }
    });

    this.categoryService.getCategories(this.page).subscribe(response => {
      this._categories = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }, error => {
      console.log(error);
      if (error.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error
        });
        console.log('Error:', error);
      }
    });

    this.automatService.getPowderProductTypes().subscribe(res => {
      this.powderProducts = res.powderProducts;
      this.getEvendProducts();
      this.formContentInit();

    }, error => {
      console.log(error);
    });

  }

  formContentInit() {
    this.powderProducts.forEach((pType, index) => {
      this.productContents[index] = new ProductContent();
      this.productContents[index].name = pType.name;
      this.productContents[index].productType = pType.productType;
      this.productContents[index].powderProductID = pType._id;
      this.productContents[index].value = null;
    });
  }


  // formda seçtirebilmek için alınan liste
  getEvendProducts(isItSelect?: boolean, select?: string) {
    if (select === 'brand') {
      if (this._brand) {
        this.filter.brandId = this._brand._id;
      } else {
        this.filter.brandId = '';
      }
      this._category = null;
      this.filter.categoryId = '';
      this.filter.name = '';
    } else if(select === 'category') {
      if (this._category) {
        this.filter.categoryId = this._category._id;
      } else {
        this.filter.categoryId = '';
      }
      this._brand = null;
      this.filter.brandId = '';
      this.filter.name = '';
    } else if(this.filter.name !== '') {
      this._brand = null;
      this._category = null;
      this.filter.brandId = '';
      this.filter.categoryId = '';
    }

    this.productService.getEvendProducts(this.page, this.filter).subscribe(res => {
      if (res.success) {
        if (this.evendProducts && this.evendProducts.length && !isItSelect) {
          this.evendProducts = this.evendProducts.concat(res.result.docs);
        } else {
          this.evendProducts = res.result.docs;
        }
        this.page = res.result.page;
        this.pages = res.result.pages;
        this.total = res.result.total;
      }
    });

  }


  deleteProduct(_id) {
    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Ürünü silmek istediğinize emin misiniz?'
    }).then((result) => {
      if (result.value) {
        this.loadingSpinner.show();
        this.productService.deleteProduct(_id).subscribe(res => {
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Ürün başarıyla silindi!'
          });

          this.changeProduct.emit({event: 'DELETE', data: null});

          this.loadingSpinner.hide();
          this.activeModal.close('product deleted');
        }, error => {
          this.loadingSpinner.hide();
          if (error.retCode === 1) {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Ürünü ürün havuzundan silebilmek için önce otomat(lar)ın içerisinden çıkarmanız gerekmektedir!'
            });
          } else if (error.retCode === 2) {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Ürünü ürün havuzundan silebilmek için önce şablon(lar)ın içerisinden çıkarmanız gerekmektedir!'
            });
          } else {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Ürün silinirken hata oluştu!'
            });
          }
          this.activeModal.dismiss('An error happened');
        });
      }
    });


  }

  //firm product new
  addProduct(): void {
    if (!this.checkIsFieldsOkay()) {
      return;
    }
    this.loadingSpinner.show();
    this.product.content = [];
    this.productContents.forEach(data => {
      if (data.value !== null && data.value !== undefined && data.value !== 0) {

        this.product.content.push(data);
      }
    });
    this.activeModal.close();
    this.productService.createProduct(this.product).subscribe(response => {
      this.loadingSpinner.hide();
      if (response.success === false) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu ürün zaten var!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Ürün başarıyla oluşturuldu!'
        });
        // add complete event without img
        this.changeProduct.emit({event: 'ADD', data: this.product});
      }

    }, error => {
      console.log(error);
      this.loadingSpinner.hide();
      if (error.code === 503) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'External Id 13 haneden uzun olmamalıdır!'
        });
      }
      else if (error.code === 504) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'External Id alanında karakter kullanılamaz, Sadece Rakam!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Ürün oluşturulurken hata oluştu!'
        });
      }
    });
  }

  //firm product update
  updateProduct() {
    if (!this.checkIsFieldsOkay()) {
      return;
    }
    this.loadingSpinner.show();
    this.product.content = [];
    this.productContents.forEach(data => {
      if (data.value !== null && data.value !== undefined && data.value != 0) {
        this.product.content.push(data);
      }
    });

    this.productService.updateProduct(this.product, this.product._id).subscribe(response => {
      this.changeProduct.emit({event: 'UPDATE', data: this.product});
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Ürün başarıyla güncellendi!'
      });

    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Ürün güncellenirken hata oluştu!'
      });
    });


  }

  // get products based on select input
  onSelectUpdate(isItSelect, select) {
    this.product.evendProduct = null;
    this.page = 1;
    this.getEvendProducts(isItSelect, select);
  }

  onClear() {
    if (!this.brandSelect.hasValue) {
      this._brand = null;
    }
    if (!this.categorySelect.hasValue) {
      this._category = null;
    }
  }

  search(event: any) {
    this.filter.name = event.searchTerm;
    this.page = 1;
    this.getEvendProducts(true, 'search');
  }

  cancel() {
    this.changeProduct.emit({event: 'CANCEL', data: null});
  }

  checkIsFieldsOkay(): boolean {
    let isOkay = true;

    if (this.product.price === 0 || this.product.price === null || !this.product.price) {
      isOkay = false;
    }
    if (!this.product._id) {
      if (this.product.evendProduct === null || !this.product.evendProduct) {
        isOkay = false;
      }
    }

    if (!isOkay) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen tüm alanları doldurunuz!'
      });
      return false;
    } else {
      return true;
    }
  }


  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    this.page += 1;

    this.getEvendProducts();

  }

  onScrollBrand() {
    if (this.page + 1 > this.pages) { return; }
      $('#page-loader').fadeIn();
      this.page += 1;
      this.brandService.getBrands(this.page)
          .subscribe(response => {
              $('#page-loader').fadeOut();
              for (const item of response.docs) {
                  this._brands.push(item);
                  this._brands = [...this._brands];
              }
              this.pages = response.pages;
          }, error => {
              $('#page-loader').fadeOut();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Ürünlerin devamı yüklenemedi!'
              });
          });
  }

  onScrollCategory() {
    if (this.page + 1 > this.pages) { return; }
      $('#page-loader').fadeIn();
      this.page += 1;
      this.categoryService.getCategories(this.page)
          .subscribe(response => {
              $('#page-loader').fadeOut();
              for (const item of response.docs) {
                  this._categories.push(item);
                  this._categories = [...this._categories];
              }
              //this.pages = response.pages;
              this.pages = 1;
          }, error => {
              $('#page-loader').fadeOut();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Ürünlerin devamı yüklenemedi!'
              });
          });
  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }

}

export interface Filter {
  brandId: string;
  categoryId: string;
  name: string;
}

