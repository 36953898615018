import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }


  getPaymentSystemsFirms(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'getPaymentSystemsFirms', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmPaymentMethods(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'getFirmPaymentMethods', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  createAutomatPaymentSystem(name: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'createAutomatPaymentSystem',
        JSON.stringify({name: name}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  updateAutomatPaymentSystem(name: string, id: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'updateAutomatPaymentSystem/' + id,
        JSON.stringify({name: name}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  deleteAutomatPaymentSystem(id: string): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'deleteAutomatPaymentSystem/' + id, {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getAutomatPaymentSystemsForAutomatFormPopup(): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'automat-payment-systems-from-create-automat-form', {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getAutomatPaymentSystems(): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'getAutomatPaymentSystems', {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getAutomatPaymentSystem(id: string): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'getAutomatPaymentSystem/' + id, {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  saveOrderedAutomatPaymentSystem(automatPaymentSystem): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'saveOrderedAutomatPaymentSystem',
        JSON.stringify(automatPaymentSystem), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  changeAutomatPaymentSystemOnAutomat(automatList, automatPaymentSystem): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'changeAutomatPaymentSystemOnAutomat',
        JSON.stringify({automats: automatList, automatPaymentSystems: automatPaymentSystem}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  deleteAutomatsOnAutomatPaymentSystem(object, automats): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'deleteAutomatsOnAutomatPaymentSystem',
        JSON.stringify({data: object, automats: automats}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getAutomatsForPayment(group, automatPaymentSystemId): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'getAutomatsForPayment',
        JSON.stringify({group: group, automatPaymentSystemId: automatPaymentSystemId}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }


}

