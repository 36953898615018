export class PaginationTableOptions {
  itemList: any[];
  limit: number;
  page: number;
  pages: number;
  total: number;
  headers: any [];
  limits: number[];
  buttons!: any[];
  emptyListMessage: string;
}
