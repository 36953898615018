import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {AutomatGroupService} from '../_services/automat-group.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {MarkerAutomat} from '../_models/markerAutomat';

@Component({
  selector: 'app-group-automats',
  templateUrl: './group-automats.component.html',
  styleUrls: ['./group-automats.component.css']
})
export class GroupAutomatsComponent implements OnInit {

  swalOptions = new SwalHelper();

  automats = Array<MarkerAutomat>();
  automatStats = Array<{ price: number, stock: number }>();
  AutomatImagePath = environment.AutomatImagePath;
  editAutomat: string;
  groupId: string;

  constructor(private automatGroupService: AutomatGroupService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService) {
  }

  ngOnInit() {

    this.loadingSpinner.show();
    this.automatGroupService.currentGroupId.subscribe(id => {
      if (id === '-1') {
        this.navigateTo('/automat-groups');
      } else {
        this.groupId = id;
        this.getGroupAutomats();
      }
      this.loadingSpinner.hide();

    });

  }

  getGroupAutomats() {
    this.loadingSpinner.show();
    this.automatGroupService.getGroupAutomats(this.groupId).subscribe(res => {
      if (res.success) {
        if (res.automats.length === 0) {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.info,
            text: 'Bu grup altında listenecek otomat verisi yok!'
          }).then(() => {
            this.navigateTo('/automat-groups');
          });
        }
        this.automats = res.automats;
        this.getAutomatStats();
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomatlar sunucudan alınamadı!'
        });
      }
    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomatlar sunucudan alınamadı!'
      });
    });
  }

  getAutomatStats() {
    this.automats.forEach((automat, index) => {
      let price = 0;
      let stock = 0;
      if (automat) {
        for (const product of automat.automatproduct) {
          price += product.price * product.stock;
          stock += product.stock;
        }
        this.automatStats[index] = {
          price: price,
          stock: stock
        };
      }

    });
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

}
