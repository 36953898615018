import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [AuthGuard],
    data: {
      settings: true,
    },
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment-settings/payment-settings.module').then(m => m.PaymentSettingsModule),
    canActivate: [AuthGuard],
    data: {
      settings: true
    },
  },
  // {
  //   path: 'apps',
  //   loadChildren: () => import('./app-settings/app-settings.module').then(m => m.AppSettingsModule),
  //   canActivate: [AuthGuard],
  //   data: {
  //     settings: true
  //   },
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SettingsRoutingModule {
}

