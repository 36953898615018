import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewCardPacketComponent} from './new-card-packet/new-card-packet.component';
import {AddUserToCardPacketComponent} from './add-user-to-card-packet/add-user-to-card-packet.component';
import {LoadBalanceCardPacketComponent} from './load-balance-card-packet/load-balance-card-packet.component';
import {DeductBalanceCardPacketComponent} from './deduct-balance-card-packet/deduct-balance-card-packet.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiSwitchModule} from 'ngx-ui-switch';
import {CardPacketsRoutingModule} from './card-packets-routing.module';
import {CardPacketsComponent} from './card-packets.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {PipesModule} from '../_pipes/pipes.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared/shared.module';


const dec_exp = [
  NewCardPacketComponent,
  AddUserToCardPacketComponent,
  LoadBalanceCardPacketComponent,
  DeductBalanceCardPacketComponent,
  CardPacketsComponent
];

@NgModule({
  declarations: dec_exp,
  exports: dec_exp,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbModule,
    UiSwitchModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forChild(),
    CardPacketsRoutingModule,
    SharedModule
  ]
})
export class CardPacketsModule {
}
