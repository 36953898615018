<div class="container mb-4">
  <div class="row">
    <div class="col-md-12 col-xl-12 col-lg-12 content-header-page">
      <label>Ödeme Emirleri (Pasif)</label>
    </div>
  </div>
  <div class="row header-bottom-line font-weight-bold">
    <div class="col-md-2 col-xl-2 col-lg-2">
      Dönem {{text}}
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      TELEMETRY Bakiyesi
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      Iyzico Bakiyesi
    </div>
    <div class="col-md-4 col-xl-4 col-lg-4 text-right font-weight-normal">
                  <span class="time-title noselect ml-0"
                        [ngClass]="currentTimeZone == 0 ? 'active-timezone' : ''"
                        (click)="currentTimeZone = 0">
                    Tümü
                  </span>
      <span class="time-title noselect"
            [ngClass]="currentTimeZone == 1 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 1">
                    Yıllık
                  </span>
      <span class="time-title noselect"
            [ngClass]="currentTimeZone == 2 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 2">
                    Aylık
                  </span>
      <span class="time-title noselect mr-0"
            [ngClass]="currentTimeZone == 3 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 3">
                    Haftalık
                  </span>
    </div>
  </div>

  <div class="row item">
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center">
      <p class="m-0">01.05.2018</p>
      <p class="m-0">01.08.2018</p>
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center text-center">
      <span>Aktif İşlem</span>
    </div>
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center text-center">
      <img src="../../../../assets/img/islemde.svg" width="42" height="42">
      <p class="m-0">İŞLEMDE</p>
    </div>
  </div>

  <div class="row item">
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center">
      <p class="m-0">01.05.2018</p>
      <p class="m-0">01.08.2018</p>
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-md-3 col-xl-3 col-lg-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center text-center">
      <span style="display: none">Aktif İşlem</span>
    </div>
    <div class="col-md-2 col-xl-2 col-lg-2 align-self-center text-center">
      <img src="../../../../assets/img/bigcheck.svg" width="42" height="42">
      <p class="m-0" style="color: #2fb43f">TAMAMLANDI</p>
    </div>
  </div>
</div>
