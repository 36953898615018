
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import * as moment from '../_helpers/sales.helper';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }


  salesSummaryHourly(automats: Array<string>): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-today-sales-summary',
        JSON.stringify(automats), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

  salesSummaryDaily(automats: Array<string>, start, end, interval: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-daily-sales-summary',
        JSON.stringify({automats: automats, date: {startDate: start, endDate: end}, interval: interval}), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

  salesHourly(automats: Array<string>): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-today-sales-by-time',
        JSON.stringify(automats), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

  salesDaily(automats: Array<string>, start, end, interval: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-daily-sales-by-time',
        JSON.stringify({automats: automats, date: {startDate: start, endDate: end}, interval: interval}), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

  paymentTypesHourly(automats: Array<string>): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-today-payments-by-time',
        JSON.stringify(automats), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

  paymentTypesDaily(automats: Array<string>, start, end, interval: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-daily-payments-by-time',
        JSON.stringify({automats: automats, date: {startDate: start, endDate: end}, interval: interval}), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }


  salesProducts(automats: Array<string>, start, end): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'get-sales-products',
        JSON.stringify({firmAutomats: automats, startDate: start, endDate: end}), {headers: this.headers}).pipe(
      map((response: Response) => {
        if (response) {
          return response;
        }
      }));
  }

}
