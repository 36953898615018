import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterModule} from '@angular/router';
import {OrderPaymentComponent} from './order-payment/order-payment.component';
import {PaymentSettingsRoutingModule} from './payment-settings.routing';
import {DndModule} from 'ngx-drag-drop';
import {PipesModule} from '../../_pipes/pipes.module';
import {SystemPaymentComponent} from './system-payment/system-payment.component';
import {TemplatePaymentComponent} from './template-payment/template-payment.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MethodPaymentModule} from './method-payment/method-payment.module';

const dec_exp = [
  TemplatePaymentComponent,
  SystemPaymentComponent,
  OrderPaymentComponent,


];

const imp = [
  PaymentSettingsRoutingModule,
  MethodPaymentModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    NgxSpinnerModule,
    PipesModule,
    DndModule,
    RouterModule,
    NgxPermissionsModule.forChild(),

    imp
  ],
  declarations: [dec_exp],
  exports: [dec_exp],
})
export class PaymentSettingsModule {
}
