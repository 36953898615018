import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './_guards/auth.guard';
import {ServiceCareComponent} from './service-care/service-care.component';
import {PersonelsComponent} from './personels/personels.component';
import {ProductsComponent} from './products/products.component';
import {SystemMessagesComponent} from './system-messages/system-messages.component';
import {SalesComponent} from './sales/sales.component';
import {SaleReportComponent} from './sale-report/sale-report.component';
import {BasketReportComponent} from './basket-report/basket-report.component';
import {AutomatGroupsComponent} from './automat-groups/automat-groups.component';
import {GroupAutomatsComponent} from './group-automats/group-automats.component';
import {CardPacketsComponent} from './card-packets/card-packets.component';
import {OfflineCardsComponent} from './offline-cards/offline-cards.component';
import {PaymentProcessComponent} from './payment-process/payment-process.component';
import {ProceedsComponent} from './proceeds/proceeds.component';
import {OfflineCardGroupPeriodComponent} from './offline-card-group-period/offline-card-group-period.component';
import {NotificationsComponent} from './notifications/notifications.component';
import { TransactionStatementComponent } from './transaction-statement/transaction-statement.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NgModule} from '@angular/core';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {QrCodesComponent} from './qr-codes/qr-codes/qr-codes.component';
import {QrCodesAutomatGroupsComponent} from './qr-codes/qr-codes-automat-groups/qr-codes-automat-groups.component';
import {QrCodesSubUsersComponent} from './qr-codes/qr-codes-sub-users/qr-codes-sub-users.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'automats',
    loadChildren: () => import('./automats/automats.module').then(m => m.AutomatsModule)
  },
  {
    path: 'automat-groups',
    component: AutomatGroupsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'automat-groups',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'group-automats',
    component: GroupAutomatsComponent,
    canActivate: [AuthGuard],
  },
    {
        path: 'qr-codes-sub-users',
        component: QrCodesSubUsersComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'automat-groups',
                redirectTo: '/auth/login'
            }
        }
    },
  {
    path: 'qr-codes-automat-groups',
    component: QrCodesAutomatGroupsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'automat-groups',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'qr-codes-automat-groups/:id',
    component: QrCodesComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'automat-groups',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'qr-codes',
    component: QrCodesComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'automats',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'service-care',
    component: ServiceCareComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'service-care',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'personels',
    component: PersonelsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'personels',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'products',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'system-messages',
    component: SystemMessagesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'sales',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'sales-report',
    component: SaleReportComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'sales-report',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path:'transaction-statement',
    component: TransactionStatementComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'transaction-statement',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'basket-report',
    component: BasketReportComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'basket-report',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'card-packets',
    loadChildren: () => import('./card-packets/card-packets.module').then(m => m.CardPacketsModule),
  },
  {
    path: 'offline-cards',
    component: OfflineCardsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'offline-cards',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'offline-card-group-period',
    component: OfflineCardGroupPeriodComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'offline-cards',
        redirectTo: '/auth/login'
      }
    }
  },
  {
    path: 'payment-process',
    component: PaymentProcessComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proceeds',
    component: ProceedsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'proceeds',
        redirectTo: '/auth/login'
      }
    }
  },
  /*{
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },*/
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'auth',
    data: {
      title: 'Authentication',
      showHeader: false
    },
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
    {
        path: 'qr',
        data: {
            title: 'Qr',
            showHeader: false
        },
        loadChildren: () => import('./sub-firm-qr/sub-firm-qr.module').then(m => m.SubFirmQrModule)
    },
  {
    path: 'automat-detail',
    loadChildren: () => import('./automat-detail/automat-detail.module').then(m => m.AutomatDetailModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    data: {
      settings: true
    },
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})


export class AppRoutingModule {
}
