<div class="container my-5 fade-in-animation" *ngIf="subFirmUsers else dataLoading">
    <div *ngxPermissionsOnly="['personels-edit']" class="row justify-content-end mb-4">
        <button class="btn btn-success newbtn" (click)="openNewModal()">
            <i class="fa fa-plus mr-3"></i> Yeni Kullanıcı
        </button>
    </div>
    <div
        *ngFor="let subFirmUser of subFirmUsers; let i = index"
        class="row personel-satiri my-2">
        <div class="col-sm col-md-1 align-self-center">
            <img class="rounded-circle" src="../../assets/img/user_image.png" width="50">
        </div>
        <div class="col-sm-2 align-self-center">
            <strong>{{subFirmUser?.username}}</strong>
        </div>
        <div class="col-sm-2 align-self-center">
            <strong>{{subFirmUser?.name}}</strong>
        </div>
        <div class="col-sm-2 align-self-center">
            <span class="font-weight-light">{{subFirmUser?.automatGroupName}}</span>
        </div>
        <div *ngxPermissionsOnly="['personels-edit']" class="col-sm-2 text-right align-self-center">
            <a (click)="setSubFirmUser(subFirmUser)">
                <img src="../../assets/img/duzenle.svg" width="25">
            </a>
        </div>
        <div *ngxPermissionsOnly="['personels-edit']" class="col-sm-1 text-center align-self-center">
            <a (click)="deleteSubFirmUser(subFirmUser._id, i)">
                <i class="fas fa-trash" style="font-size: 20px; color: #ed7060"></i>
            </a>
        </div>
    </div>

    <!-- Boş liste uyarı -->
    <div *ngIf="subFirmUsers.length == 0" class="row justify-content-center animated flipInX">
        <h1 class="h3 mt-5" style="color: gray">{{'PERSONNEL.NO_STAFF_CREATED_YET' | translate}}</h1>
    </div>
    <!-- Boş liste uyarı : SON -->
</div>

<ng-template #editModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">
             <span  *ngIf="!isEdit">
                    Yeni Kullanıcı
               </span>
            <span  *ngIf="isEdit">
                    Kullanıcı Düzenle
               </span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <h2 class="row justify-content-center">
                <span  *ngIf="!isEdit">
                    Yeni Kullanıcı
               </span>
                <span  *ngIf="isEdit">
                    Kullanıcı Düzenle
               </span>
            </h2>
            <hr class="mb-3 w-25">
            <div class="row mt-5">
                <div class="col-6  offset-3">
                    <input class="form-control no-border metin-girisi text-center" [(ngModel)]="subFirmUser.name" #name="ngModel"
                           type="text" name="name" placeholder="Alt Firm Ad">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-6 offset-3">
                    <input class="form-control no-border metin-girisi text-center" [(ngModel)]="subFirmUser.username" #name="ngModel"
                           type="text" name="username" placeholder="Firma Kodu">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6  offset-3" *ngIf="!isEdit">
                    <input class="form-control no-border metin-girisi text-center" id="subPass" [(ngModel)]="subFirmUser.password" #password="ngModel"
                           type="password" name="password" placeholder="Şifre">
                </div>
                <div class="col-1" *ngIf="!isEdit">
                    <button type="button" class="btn btn-sm" (click)="showPass()"><i class="fa fa-eye"></i></button>
                </div>
                <div class="col-1" *ngIf="!isEdit">
                    <button type="button" class="btn btn-sm" (click)="randomPassword()"><i class="fa fa-random"></i></button>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-6 offset-3 p-0">
                    <h3 class="ml-4 text-center">Otomat Grubu</h3>
                    <div class="col-12">
                        <div class="row">
                            <div class="w-100">
                                <ng-select
                                    [items]="automatGroupData"
                                    appendTo="body"
                                    [(ngModel)]="selectedGroup"
                                    [multiple]="false"
                                    [virtualScroll]="true"
                                    placeholder="QR Otomat Grubu Seçiniz"
                                    [loading]="loadingAutomatGroup"
                                    (change)="selectQrAutomat($event)"
                                    bindLabel="name" bindValue="" name="qrAutoamtGroup">
                                    <ng-template ng-label-tmp let-item="item">
                                        <span class="align-self-center">{{item.name}}</span>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        {{item.name}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="isEdit ? updateSubFirmUser() : addFirmUser()">{{'PERSONNEL.SAVE' | translate}}</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">{{'PERSONNEL.CLOSE' | translate}}</button>
    </div>
</ng-template>


<ng-template #dataLoading>
    <div class="col border container-height d-flex flex-column" style="height: 100vh">
        <div class="m-auto">
            <div class="loader fadeIn" style="font-size: 60%"></div>
        </div>
    </div>
</ng-template>


<!-- Yükleme spinner -->
<ngx-spinner
    [bdColor]=loadingSpinnerBdColor
    [size]=loadingSpinnerSize
    [color]=loadingSpinnerColor
    [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
