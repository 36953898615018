<div class="container my-5 fade-in-animation" *ngIf="staffs else dataLoading">
  <div *ngxPermissionsOnly="['personels-edit']" class="row justify-content-end mb-4">
    <button class="btn btn-success newbtn" (click)="openNewModal()">
      <i class="fa fa-plus mr-3"></i> {{'PERSONNEL.NEW_KULLANICI' | translate}}
    </button>
  </div>
  <div
    infiniteScroll
    [infiniteScrollDistance]="pages"
    [infiniteScrollThrottle]="20"
    (scrolled)="onScroll()">
    <div
      *ngFor="let staff of staffs; let i = index"
      class="row personel-satiri my-2">
      <div class="col-sm col-md-1 align-self-center">
        <img *ngIf="staff?.photo === ''" class="rounded-circle" src="../../assets/img/user_image.png" width="50">
        <img *ngIf="staff?.photo !== ''" class="rounded-circle" src="{{staff?.photo | image: StaffImagePath }}"
             width="50">
      </div>
      <div class="col-sm-2 align-self-center">
        <strong>{{staff?.name}}</strong>
      </div>
      <div class="col-sm-2 align-self-center">
        <span class="font-weight-light">{{staff?.staffKind?.name}}</span>
      </div>
      <div class="col-sm-2 align-self-center">
        <strong>{{staff?.phone}}</strong>
      </div>
      <div class="col-sm-2 align-self-center">
        <span class="font-weight-light">{{staff?.email}}</span>
      </div>
      <div *ngxPermissionsOnly="['personels-edit']" class="col-sm-2 text-right align-self-center">
        <a (click)="setStaff(staff)">
          <img src="../../assets/img/duzenle.svg" width="25">
        </a>
      </div>
      <div *ngxPermissionsOnly="['personels-edit']" class="col-sm-1 text-center align-self-center">
        <a (click)="deleteStaff(staff._id, i)">
          <i class="fas fa-trash" style="font-size: 20px; color: #ed7060"></i>
        </a>
      </div>
    </div>
  </div>

  <!-- Boş liste uyarı -->
  <div *ngIf="staffs.length == 0" class="row justify-content-center animated flipInX">
    <h1 class="h3 mt-5" style="color: gray">{{'PERSONNEL.NO_STAFF_CREATED_YET' | translate}}</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'PERSONNEL.PROFILE_UPDATE' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row modal-baslik justify-content-center">
        {{'PERSONNEL.EDIT_STAFF' | translate}}
      </div>
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <p class="m-0 mt-2"><strong>Kullanıcı Resmi Yükle</strong></p>
          <div class="file-input-wrapper">
            <button class="btn btn-primary modal-buton mt-4 mb-2 pull-left btn-file" style="width: 50%">{{'PERSONNEL.BROWSE' | translate}}</button>
            <input id="edit-crop-input" type="file" accept="image/*" (change)="fileChangeEvent($event)"/>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                [resizeToWidth]="180"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (loadImageFailed)="imageLoadFailed()"
                style="max-height: 180px; width: 180px"
                [style.display]="cropperReady ? null : 'none'">
              </image-cropper>

              <span *ngIf="croppedImage" class="align-self-center" style="font-size: x-large"> &rarr; </span>

              <div *ngIf="croppedImage">
                <img [src]="croppedImage" style="max-width: 100%;max-height: 180px;border: solid 1px #b7b7b7;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-6 offset-3">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="staff.username" #name="ngModel"
                 type="text" name="username" placeholder="Kullanıcı Adı">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="staff.name" #name="ngModel"
                 type="text" name="name" placeholder="Ad / Soyad">
        </div>
        <div class="col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="staff.phone" #phone="ngModel"
                 type="text" name="phone" placeholder="Telefon Numarası">
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="staff.email" #email="ngModel"
                 type="text" name="email" placeholder="Mail Adresi">
        </div>
        <div class="col" *ngIf="!isEdit">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="staff.password" #phone="ngModel"
                 type="password" name="password" placeholder="Şifre">
        </div>
        <div class="col">
          <select [(ngModel)]="staff.staffKind._id" class="custom-select select-product select-custom-icon">
            <option value="-1">{{'PERSONNEL.SELECT_A_SERVICE_DUTY' | translate}}</option>
            <option
              *ngFor="let kind of staffKinds; let i = index"
              value="{{ kind._id }}">{{kind.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="isEdit ? updateStaff() : addStaff()">{{'PERSONNEL.SAVE' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">{{'PERSONNEL.CLOSE' | translate}}</button>
  </div>
</ng-template>


<ng-template #dataLoading>
  <div class="col border container-height d-flex flex-column" style="height: 100vh">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%"></div>
    </div>
  </div>
</ng-template>


<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
