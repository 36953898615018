import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DatePickerI18nService implements NgbDatepickerI18n {

  monthNames = moment.monthsShort();
  weekdays = moment.weekdaysShort(true);

  constructor() {
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.weekdays[(date.day % 7)];
  }

  getDayNumerals(date: NgbDateStruct): string {
    return date.day.toString();
  }

  getMonthFullName(month: number, year?: number): string {
    return this.monthNames[month - 1];
  }

  getMonthShortName(month: number, year?: number): string {
    return this.monthNames[month - 1];

  }

  getWeekNumerals(weekNumber: number): string {
    return weekNumber.toString();
  }

  getWeekdayShortName(weekday: number): string {
    return this.weekdays[weekday - 1];
  }

  getYearNumerals(year: number): string {
    return year.toString();
  }
}
