import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AutomatService } from '../_services/automat.service';
import { Router } from '@angular/router';
import { MarkerAutomat } from '../_models/markerAutomat';
import { MonthName } from '../_enums/monthName';
import { PopularProductsService } from '../_services/popular-products.service';
import { environment } from '../../environments/environment';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { ChartHelper } from '../_helpers/chart.helper';
import * as moment from 'moment';
import { GaugeChart, XYChart } from '@amcharts/amcharts4/charts';
import { ThemeingHelper } from '../_helpers/themeing.helper';
import Swal from 'sweetalert2';
import { SwalHelper } from '../_helpers/swal.helper';
import { MapService } from '../_services/map.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  monthIndex: number;
  yearIndex: number;
  dateMonthName: typeof MonthName = MonthName;
  swalOptions = new SwalHelper();
  saleChart: XYChart;
  // public mounthlySale: Array<DailyData> = [];
  mounthlySale: Array<any> = [];
  totalSale = 0;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  stockChart: GaugeChart;
  automatCounts: number = 0;
  automatPassiveCount = 0;
  automatActiveCount = 0;
  dailySale: { count: number, amount: number } = { count: null, amount: null };
  automats: MarkerAutomat[];
  popularProducts = [];
  popularProductsArray = [];
  totalPopularProduct = 0;

  socketBasket: {
    automatName: string,
    productName: string,
    totalAmount: number,
    image: string,
    paymentKind: string
  } = { automatName: 'Anlık Hareket', productName: '', totalAmount: 0, image: '', paymentKind: 'Mobile' };

  // MAP
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild('gmapContainer', { static: false }) gmap: google.maps.Map;

  zoom = 8;
  center: any = { lat: 39.334943, lng: 35.177772 };
  selectedMarker: MarkerAutomat;
  selectedMarkerIndex: number;
  mapStyles:any = environment.MapStyle;

  AutomatImagePath: string;
  ProductImagePath = environment.ProductImagePath;

  lastSelectedMarker: MapMarker;

  constructor(private automatService: AutomatService,
    private PopularProductsService: PopularProductsService,
    private router: Router,
    private chartHelper: ChartHelper,
    private themeingHelper: ThemeingHelper,
    private mapService: MapService) {
    // this.findMe();
    this.monthIndex = new Date().getMonth() + 1;
    this.yearIndex = new Date().getFullYear();

    const currentTime = moment().hour();
    if(currentTime>19 || currentTime<7){
      this.mapStyles =  environment.MapStyleNight;
    }

    this.AutomatImagePath = environment.AutomatImagePath;
  }

  ngOnInit() {
      if (this.currentUser && this.currentUser.user.subFirmUser) {
          this.router.navigate(['/qr']);
          return;
      }

    this.automatService.getFirmAutomatsforDashboard().subscribe(Response => {
      this.automats = Response.automats;
      const [bounds, markers] = this.mapService.getBounds(this.automats);
      this.center = bounds.getCenter();
      this.gmap.fitBounds(bounds);
    });

    this.getCountActiveAndPassiveAutomat();

    this.automatService.getSalesDaily().subscribe(Response => {
      this.dailySale = Response;
    });

    this.PopularProductsService.getPopularProductFirm().subscribe(Response => {
      if (Response.data) {
        this.popularProductsArray = Response.data;
        this.popularProductsArray.forEach(popularProduct => {
          this.totalPopularProduct += popularProduct.sales;
        });
      }
    });

    this.automatService.getSalesMonthly(this.monthIndex.toString(), this.yearIndex.toString())
      .subscribe(Response => {
        this.mounthlySale = Response.data;
        this.setMonthlyChartData();
      });

    this.automatService.getStockRate()
      .subscribe(Response => {
        let value = Response.rate;
        if (!value) {
          value = 0;
        } else if (value > 100) {
          value = 100;
        }
        this.stockChart = this.chartHelper.newStockGaugeChart('stok-orani-chart', value);
      });
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {

  }

  getPopularProductPercent(count) {
    const max = this.totalPopularProduct;
    return ((100 * count) / max).toFixed();
  }

  setMonthlyChartData() {

    // moment eklediğinde objenin kendisi değişiyor
    const firstofMonth =
      moment().month(this.monthIndex - 1).year(this.yearIndex).startOf('month').subtract(1, 'day');
    const Monthsize = moment().month(this.monthIndex - 1).year(this.yearIndex).endOf('month').get('date');
    const array: any = [];
    this.totalSale = 0;


    for (let _i = 0; _i < Monthsize; _i++) {
      const data = {
        'date': firstofMonth.add(1, 'day').toDate(),
        'count': 0,
      };
      array.push(data);
    }

    if (this.mounthlySale !== undefined) {
      this.mounthlySale.forEach(temp => {
        array[new Date(temp.date).getDate() - 1].count = temp.count,
          this.totalSale += temp.count;
      });
    }

    this.saleChart = this.chartHelper.newDashSingleColumnChart('satis-orani-chart', array, 'Adet', 'count');


  }

  findMe() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      }, function (e) {
        alert(e.message);
      }, {
        enableHighAccuracy: true
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  getCountActiveAndPassiveAutomat() {
    const currentDate = new Date().getTime();
    const timeOffset = new Date().getTimezoneOffset();
    this.automatService.getFirmAutomatLastInformated().subscribe(Response => {
      if (Response.success) {
        this.automatActiveCount = Response.activeAutomatCount;
        this.automatPassiveCount = Response.passiveAutomatCount;
        this.automatCounts = Response.totalAutomatCount;
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bir hata oluştu!'
        });
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
    });
  }

  getAutomatProductsTotalPrice(index: number): number {
    let total = 0;
    const automat = this.automats[index];
    for (const product of automat.automatproduct) {
      total += product.price * product.stock;
    }
    return total;
  }

  getAutomatProductsCount(index: number): number {
    let total = 0;
    const automat = this.automats[index];
    for (const product of automat.automatproduct) {
      total += product.stock;
    }
    return total;
  }

  updateDailysales(count, amount) {
    this.dailySale.count += 1;
    this.dailySale.amount += amount;
  }

  changeMonthlySale(isNext: boolean) {
    if (isNext) {
      this.monthIndex++;
      if (this.monthIndex === 13) {
        this.monthIndex = 1;
        this.yearIndex++;
      }
    } else {
      this.monthIndex--;
      if (this.monthIndex === 0) {
        this.monthIndex = 12;
        this.yearIndex--;
      }
    }
    this.saleChart.dispose();
    this.saleChart = null;
    this.automatService.getSalesMonthly(this.monthIndex.toString(), this.yearIndex.toString())
      .subscribe(Response => {
        this.mounthlySale = Response.data;
        this.setMonthlyChartData();
      });
  }

  changeAutomatId(automatId: string) {
    this.automatService.changeAutomatId(automatId);
    this.router.navigate(['/automat-detail', automatId]);
  }

  getMarkerImage(status: number): string {
    switch (status) {
      case 1:
        return '../../assets/img/aktifMarker.png';
      case 2:
        return '../../assets/img/guncelleMarker.png';
      case 8:
      case 0:
        return '../../assets/img/arizaliMarker.png';
    }
  }


  openInfoWindow(marker: MapMarker, a: MarkerAutomat, index: number) {
    this.lastSelectedMarker = marker;
    this.selectedMarker = a;
    this.selectedMarkerIndex = index;
    this.infoWindow.open(marker);
  }


  closeAllInfo() {
    this.infoWindow.close()
  }

  public barColor(i: any) {
    return this.themeingHelper.barColor(i);
  }

}
