import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../_services/authentication.service';
import {environment} from '../../../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {SwalHelper} from '../../../_helpers/swal.helper';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  swalOptions = new SwalHelper();

  passwordData: any = {};
  tokenKey: string;

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  //END : Ngx-spinner değişkenleri (Loading)

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService) {

    this.loadingSpinner.show();


    this.resetData();
  }

  ngOnInit() {
  }

  resetData() {
    this.passwordData.password = '';
    this.passwordData.newPassword = '';
    this.passwordData.newConfirmPassword = '';
  }

  redirect(link: string) {
    this.router.navigateByUrl(link);
  }

  changePassword(): void {
    this.loadingSpinner.show();
    if (this.passwordData.newPassword === '' || this.passwordData.newConfirmPassword === '' ||
      this.passwordData.password === '') {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Yeni şifre alanlarını boş bırakmayınız!'
      });
      return;
    }
    if (this.passwordData.newPassword !== this.passwordData.newConfirmPassword) {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Yeni şifreler eşleşmiyor!'
      });
      return;
    }
    this.authenticationService.changePassword(this.passwordData.password, this.passwordData.newPassword)
      .subscribe(res => {
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Şifre başarılı bir şekilde güncellendi!'
        });
        this.resetData();
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Şifre güncellenemedi!'
        });
      });
  }

}
