import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AutomatService} from '../../../_services/automat.service';
import {AutomatPaymentSystem} from '../../../_models/automatPaymentSystem';
import {PaymentService} from '../../../_services/payment.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AutomatGroupService} from '../../../_services/automat-group.service';
import {FirmService} from '../../../_services/firm.service';
import {Firm} from '../../../_models/firm';
import {SwalHelper} from '../../../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-system-payment',
  templateUrl: './system-payment.component.html',
  styleUrls: ['./system-payment.component.css']
})
export class SystemPaymentComponent implements OnInit {


  isReady = false
  public selectedGroup = '-1';
  public automatList = [];
  public checkedAutomatList = [];
  public automatGroups = [];


  public paymentTemplates = [];
  public paymentsStageOne = true;
  public paymentsStageTwo = false;
  public paymentsStageThree = false;
  public stageTwoButton = true;
  public stageThreeButton = true;


  public selectedTemplateName = '';
  public selectedTemplateId = '-1';
  public selectedTemplateIdTable = '-1';
  public isTemplate = false;

  public paymentSystemsFirms = [];
  public automatPaymentSystemId = '';
  public automatPaymentSystems: Array<AutomatPaymentSystem>;


  public selectedTemplate: AutomatPaymentSystem = new AutomatPaymentSystem();


  public selectedGroupTable = '-1';
  public selectedTemplateOnAutomat: [any];
  public selectedAutomatPaymentSystemId = '';


  public checkedTemplateAutomatList = [];
  public checkedAutomatIds = [];

  public availablePaymentSystemsFirms = [];


  public automats: [any];
  public firmInfo: Firm;
  firmid: string;
  @ViewChild('closeAutomatPaymentTemplateModal', {static: false}) private closeAutomatPaymentTemplateModal: ElementRef;
  swalOptions = new SwalHelper();

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor;
  public loadingSpinnerSize;
  public loadingSpinnerColor;
  public loadingSpinnerType;
  public loadingSpinnerText;

  //END : Ngx-spinner değişkenleri (Loading)

  constructor(private automatService: AutomatService,
              private paymentService: PaymentService,
              private loadingSnipper: NgxSpinnerService,
              private automatGroupService: AutomatGroupService,
              private firmService: FirmService,
              private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.isReady = true;
    this.automatGroupService.getAutomatGroupsForFilter().subscribe(response => {
      this.automatGroups = response;
    });
    this.paymentService.getPaymentSystemsFirms().subscribe(response => {
      this.setPaymentSystemsFirmsStatus(response);
    });
    this.paymentService.getAutomatPaymentSystems().subscribe(response => {
      this.paymentTemplates = response.data;
      this.automatPaymentSystems = response.data;
    });

    this.firmid = this.auth.user$.getValue().firm._id;
    this.firmService.getFirmInfo(this.firmid).subscribe(res => {
      if (res.success) {
        this.availablePaymentSystemsFirms = res.data.paymentSystemsFirmsInfo;
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    });
  }

  setPaymentSystemsFirmsStatus(response) {
    this.paymentSystemsFirms = [];
    this.firmService.getFirmInfo(this.firmid).subscribe(res => {
      if (res.success) {
        this.firmInfo = res.data;
        response.data.forEach((data, index) => {
          const object = data;
          res.data.paymentSystemsFirms.forEach((data2, index2) => {
            if (data2.toString() === data._id.toString()) {
              object.status = true;
            }
          });
          if (object.status !== true) {
            object.status = false;
          }
          this.paymentSystemsFirms.push(object);
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    });
  }


  onChangeGroup(group) {
    this.isReady = false;
    let groupArr = [];
    groupArr.push(group);
    this.automatService.getAutomatListFromAutomatGroupId(groupArr).subscribe(response => {
      this.automatList = response;
      this.isReady = true;
      this.automatList.forEach(data => {
        this.checkedAutomatList.push(data._id);
      });
    });
  }

  changeCheckedAutomatList(isSelectAll) {
    if (isSelectAll) {
      this.checkedAutomatList = [];
      this.automatList.forEach((data, index) => {
        this.checkedAutomatList.push(data._id);
        //$('.automatCheckBox')[index].checked = true;
      });
    } else {
      //$('.automatCheckBox').prop('checked', false);
      this.paymentsStageTwo = false;
      this.paymentsStageThree = false;
      this.stageTwoButton = true;
      this.stageThreeButton = true;
      this.checkedAutomatList = [];
      this.selectedTemplateId = '-1';
      this.isTemplate = false;

    }
  }

  onChangeAutomatChecked(event, automat) {
    if (event.target.checked) {
      this.checkedAutomatList.push(automat._id);
    } else {
      this.checkedAutomatList.forEach((data, index) => {
        if (automat._id.toString() === data.toString()) {
          this.checkedAutomatList.splice(index, 1);
        }
      });
    }
    if (this.checkedAutomatList.length <= 0) {
      this.paymentsStageTwo = false;
      this.paymentsStageThree = false;
      this.stageTwoButton = true;
      this.stageThreeButton = true;
      this.selectedTemplateId = '-1';
      this.isTemplate = false;
    }
  }


  onChangeTemplateChecked(event, automat) {
    const object = {_id: '', automats: []};
    let control = false;
    if (event.target.checked) {
      if (this.checkedTemplateAutomatList.length > 0) {
        this.checkedTemplateAutomatList.forEach((data, index) => {
          if (data._id.toString() === automat.automatPaymentSystemId.toString()) {
            control = true;
            data.automats.push(automat._id);
          }
        });
        if (!control) {
          object._id = automat.automatPaymentSystemId;
          object.automats.push(automat._id);
          this.checkedTemplateAutomatList.push(object);
        }
      } else {
        object._id = automat.automatPaymentSystemId;
        object.automats.push(automat._id);
        this.checkedTemplateAutomatList.push(object);
      }
      this.checkedAutomatIds.push(automat._id);
    } else {
      this.checkedTemplateAutomatList.forEach((data, index) => {
        if (data._id.toString() === automat.automatPaymentSystemId.toString()) {
          data.automats.forEach((data2, index2) => {
            if (data2.toString() === automat._id.toString()) {
              data.automats.splice(index2, 1);
              if (data.automats.length <= 0) {
                this.checkedTemplateAutomatList.splice(index, 1);
              }
            }
          });
        }
      });
      this.checkedAutomatIds.forEach((data, index) => {
        if (data.toString() === automat._id.toString()) {
          this.checkedAutomatIds.splice(index, 1);
        }
      });
    }
  }

  changeCheckedTemplateList(isSelectAll) {
    let control = false;
    if (isSelectAll) {
      this.checkedTemplateAutomatList = [];
      this.checkedAutomatIds = [];
      this.automats.forEach((automat, indexAutomat) => {
        const object = {_id: '', automats: []};
        control = false;
        if (automat.automatPaymentSystemId !== null) {
          if (this.checkedTemplateAutomatList.length > 0) {
            this.checkedTemplateAutomatList.forEach((data, index) => {
              if (data._id.toString() === automat.automatPaymentSystemId.toString()) {
                control = true;
                data.automats.push(automat._id);
              }
            });
            if (!control) {
              object._id = automat.automatPaymentSystemId;
              object.automats.push(automat._id);
              this.checkedTemplateAutomatList.push(object);
            }
          } else {
            object._id = automat.automatPaymentSystemId;
            object.automats.push(automat._id);
            this.checkedTemplateAutomatList.push(object);
          }
          this.checkedAutomatIds.push(automat._id);
        }
      });
      //$('.templateCheckBox').prop('checked', true);
    } else {
      this.checkedTemplateAutomatList = [];
      this.checkedAutomatIds = [];
      //$('.templateCheckBox').prop('checked', false);
    }

  }

  stageJump(stage) {
    if (stage === 2) {
      this.paymentsStageTwo = true;
      this.stageTwoButton = false;
    }
    if (stage === 3) {
      this.paymentsStageThree = true;
      this.stageThreeButton = false;
    }
    if (stage === 4) {
      this.paymentsStageTwo = false;
      this.paymentsStageThree = false;
      this.stageTwoButton = true;
      this.stageThreeButton = true;
      this.automatList = [];
      this.checkedAutomatList = [];
      this.selectedTemplateId = '-1';
      this.selectedGroup = '-1';
      this.isTemplate = false;
    }
    if (stage === 5) {
      this.paymentService.changeAutomatPaymentSystemOnAutomat(this.checkedAutomatList, this.selectedTemplate).subscribe(res => {
        this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
        this.paymentsStageTwo = false;
        this.paymentsStageThree = false;
        this.stageTwoButton = true;
        this.stageThreeButton = true;
        this.automatList = [];
        this.checkedAutomatList = [];
        this.selectedTemplateId = '-1';
        this.selectedGroup = '-1';
        this.isTemplate = false;
        //$('#collapseTwo').collapse('show');
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      }, error => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      });
    }
  }


  getFirmAutomats(group, automatPaymentSystemId) {
    this.loadingSnipper.show();
    this.paymentService.getAutomatsForPayment(group, automatPaymentSystemId).subscribe(Response => {
      this.automats = Response.automats;
      this.loadingSnipper.hide();
    }, error => {
      this.loadingSnipper.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomatlar sunucudan alınamadı!'
      });
    });
  }


  onChangeTemplate(template) {
    this.automatPaymentSystems.forEach((data, index) => {
      if (data._id.toString() === template.toString()) {
        this.selectedTemplate = data;
        this.isTemplate = true;
      }
    });
  }

  onChangeTemplateTable(template) {
    this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
    this.checkedAutomatIds = [];
    this.checkedTemplateAutomatList = [];
  }

  reloadPaymentTemplate() {
    this.paymentService.getAutomatPaymentSystems().subscribe(response => {
      this.paymentTemplates = response.data;
      this.automatPaymentSystems = response.data;
      this.selectedTemplateId = '-1';
      this.paymentsStageThree = false;
      this.stageThreeButton = true;
      this.isTemplate = false;
    });
  }

  selectAutomatPaymentTemplate(automat) {
    this.selectedAutomatPaymentSystemId = automat.automatPaymentSystemId;
    this.selectedTemplateOnAutomat = automat.automatPaymentSystems;
    this.selectedTemplateName = automat.automatPaymentSystemName;
  }


  onChangeGroupTable(group) {
    this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
    this.checkedAutomatIds = [];
    this.checkedTemplateAutomatList = [];
  }


  deleteAutomatsOnAutomatPaymentSystem() {
    Swal.fire({
      ...this.swalOptions.question,
      text: 'Bu işlemin geri dönüşü yok. İşlemi onaylıyor musunuz?'
    }).then((result) => {
      this.paymentService.deleteAutomatsOnAutomatPaymentSystem(this.checkedTemplateAutomatList, this.checkedAutomatIds).subscribe(res => {
        this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
        this.checkedTemplateAutomatList = [];
        this.closeAutomatPaymentTemplateModal.nativeElement.click();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      }, error => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      });
    });


  }

}
