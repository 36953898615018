import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AutomatService} from '../_services/automat.service';
import {EditAutomat} from '../_models/editAutomat';
import {AutomatPaymentSystem} from '../_models/automatPaymentSystem';
import {AutomatModelService} from '../_services/automat-model.service';
import {LocationService} from '../_services/location.service';
import {environment} from '../../environments/environment';
import {AutomatHelperService} from '../_helpers/automat.helper';
import {Automat} from '../_models/automat';
import {Cashless} from '../_models/cashless';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {NgbActiveModal, NgbNav} from '@ng-bootstrap/ng-bootstrap';
import { GeocodingHelperService } from '../_helpers/geocoding.helper';
import { AutomatBrandService } from '../_services/automat-brand.service';

@Component({
  selector: 'app-automat',
  templateUrl: './automat.component.html',
  styleUrls: ['./automat.component.css']
})
export class AutomatComponent implements OnInit {

  AutomatImagePath = environment.AutomatImagePath;
  @ViewChild('nav', {static: false}) nav: NgbNav;

  swalOptions = new SwalHelper();

  controlVariables = {isInEdit: false};
  formDataLoaded = false;

  // MAP
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  zoom = 6;
  center = {lat: 39.334943, lng: 35.177772};
  mapStyles: any = {
    ...environment.MapStyle,
    streetViewControl: false,
  };

  // END : Google maps variables
  mapAutomat: any = {};
  // Service data variables
  public groups: Array<any>;
  public types: Array<any> = ['DEFAULT', 'SNACK', 'DRINK', 'COFFEE', 'GAME', 'KIOSK', 'SERVICE'];
  public models = [];
  public devices = [];
  public vPoses = [];
  public beacons = [];
  public sims = [];
  public cities = [];
  public districts = [];
  public neighborhoods = [];
  public staffs = [];
  public brands = [];
  public paymentTemplates: Array<AutomatPaymentSystem>;
  cashlessConfig = new Cashless();
  // END : Service data variables
  selectedLocations: { cityKey: number, districtKey: number } = {cityKey: null, districtKey: null};

  routeSub;
  automatId: string;

  automat: EditAutomat = new EditAutomat();
  savedAutomat: Automat;
  showErrors = false;

  page = 1;
  pages = 1;
  loading = false;

  loaded = false;
  active; //tab
  @Input()
  set selectedAutomat(data: string) {
    this.automatId = data;
  }

  @Output() submit = new EventEmitter<{ success: boolean, message: string, data: any }>();

  constructor(
    private route: ActivatedRoute,
    private automatHelper: AutomatHelperService,
    private automatService: AutomatService,
    private automatModelService: AutomatModelService,
    private locationService: LocationService,
    public activeModal: NgbActiveModal,
    private geocodingHelperService: GeocodingHelperService,
    private automatBrandService: AutomatBrandService,
  ) {
  }

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
      this.automatId = this.automatId ? this.automatId : params['id'];
      this.Load();
    });
  }

  Load() {
    if (this.loaded) {
      return;
    }
    this.loaded = true;
    if (this.automatId && this.automatId !== ' ') {
      this.controlVariables.isInEdit = true;
      this.loadAutomatData(this.automatId);
    } else {
      this.controlVariables.isInEdit = false;
      this.getSelectData(this.automat);
    }
  }

  handleSave() {
    if (!this.formChecks()) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Alanları kontrol ediniz.'
      }).then(() => {
        this.nav.select(1);
      }, () => {
        this.nav.select(1);
      });
      return;
    }
      this.automat.isStockControl = this.automat.isStockControl.toString() === 'false' ? false : true;
    if (!this.controlVariables.isInEdit) {
      this.automatService.addAutomat(this.automat, this.cashlessConfig).subscribe(res => {
        if (res.success) {
          this.savedAutomat = res.automat;
          this.submit.emit({success: true, message: 'Eklendi', data: null});
          this.nav.select(4);
        }
      }, err => {

        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomat eklenemedi.'
        });
      });
    } else {
      this.automatService.updateAutomat(this.automat, this.cashlessConfig, this.automatId).subscribe(res => {
        if (res.success) {
          this.savedAutomat = res.automat;
          this.submit.emit({success: true, message: 'Düzenlendi', data: null});
          this.nav.select(4);
        }
      }, err => {

        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomat güncellenemedi.'
        });
      });
    }
  }


  loadAutomatData(automatId) {
    this.automatService.getEditAutomatDetail(automatId).subscribe(res => {
        if (res.success) {
          this.automat = res.automat;
          if (this.automat.maxProductPrice === 25) {
            this.automat.maxProductPrice = null;
          }
          this.cashlessConfig = res.cashlessConfig;
          this.getSelectData(this.automat);
        }
      },
      error => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomat bilgisine ulaşılamadı.'
        });
      });

  }

  initializeMap() {
    this.mapAutomat.lat = this.automat.location.coordinates[0] ? this.automat.location.coordinates[0] : this.center.lat;
    this.mapAutomat.lng = this.automat.location.coordinates[1] ? this.automat.location.coordinates[1] : this.center.lng;
    this.mapAutomat.isOpen = true;
  }

  getSelectData(automat) {
    //0-brand 1-group 2-operator 3-city 4-sim 5-device 6-beacon 7-vpos 8-paymentSys
    this.automatHelper.loadFormData(automat?._id).subscribe((res: Array<any>) => {

      //Bazı arraylerin serviste filtrelenmesi lazım
      if (res[0].brands && res[0].brands.length) {
        this.brands = res[0].brands;
        // this.pages = res[0].pages;
      }
      if (res[1].result && res[1].result.length) {
        this.groups = res[1].result;
      }
      if (res[2].result && res[2].result.length) {
        res[2].result.forEach(data => {
          this.staffs.push(data);
        });
      }
      if (res[3].length) {
        this.cities = res[3];
      }
      if (res[4].result && res[4].result.length) {
        res[4].result.forEach(data => {
          this.sims.push(data);
        });
      }
      if (res[5].result && res[5].result.length) {
        res[5].result.forEach(data => {
          this.devices.push(data);
        });
      }
      if (res[6].result && res[6].result.length) {
        res[6].result.forEach(data => {
          this.beacons.push(data);
        });
      }
      if (res[7].result && res[7].result.length) {
        res[7].result.forEach(data => {
          this.vPoses.push(data);
        });
      }
      if (res[8].result) {
        this.paymentTemplates = res[8].result;
      }

      if (this.controlVariables.isInEdit) {
        this.selectAutomatData();
      }

      this.initializeMap();
      this.formDataLoaded = true;
    });
  }

  selectAutomatData() {
    this.getAutomatModels(this.automat.automatModel.automatBrand._id);
    this.getDistricts();
  }

  getAutomatModels(brand) {
    this.models = null;
    if (brand) {
      this.automatModelService.getAutomatModelsByBrand(brand).subscribe(res => {
        this.models = res.data;
      });
    }
  }

  getDistricts() {
    this.districts = null;
    if (!this.automat.province) {
      return;
    }
    const selected = this.search(this.automat.province, 'cityTitle', this.cities);
    this.selectedLocations.cityKey = selected ? selected.cityKey : null;
    if (this.selectedLocations.cityKey) {
      this.locationService.getDistrictsByCity(this.selectedLocations.cityKey).subscribe(res => {
        this.districts = res;
        if (this.automat.district !== null) {
          this.getNeighborhoods(true);
        }
      });
    }

  }

  getNeighborhoods(dynamic) {
    this.neighborhoods = null;
    if (!this.automat.district) {
      return;
    }
    const selected = this.search(this.automat.district, 'districtTitle', this.districts);
    this.selectedLocations.districtKey = selected ? selected.districtKey : null;
    if (this.selectedLocations.districtKey) {
      this.locationService.getNeighborhoodsByDistrict(this.selectedLocations.districtKey).subscribe(res => {
        this.neighborhoods = res;
      });
    } else {
      this.automat.district = null;
    }

    if (!dynamic) {
      this.automat.neighborhood = null;
    }

  }

  selectAddress(){
    this.geocodingHelperService.getLatLngFromAdress(this.automat.province, this.automat.district, this.automat.neighborhood).subscribe(
      latlng => {
        this.mapAutomat.lat = latlng.results[0].geometry.location.lat;
        this.mapAutomat.lng = latlng.results[0].geometry.location.lng;
        this.automat.location.coordinates = [this.mapAutomat.lat, this.mapAutomat.lng];
      });
  }

  markerDragEnd($event: any) {
    this.mapAutomat.lat = $event.latLng.lat();
    this.mapAutomat.lng = $event.latLng.lng();
    this.automat.location.coordinates = [this.mapAutomat.lat, this.mapAutomat.lng];
  }

  close() {
    this.activeModal.close({success: true, message: 'Kapat', data: null});
  }

  search(nameKey, prop, myArray) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][prop].toLowerCase() === nameKey.toLowerCase()) {
        return myArray[i];
      }
    }
  }

  formChecks(): boolean {
    this.showErrors = false;
    const a = this.automat;
    const uptDevice = this.isUPTDevice(this.automat.device);
    const conf = this.cashlessConfig;
    if (!this.selectCheck(a.automatModel._id)) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.vPos) && !uptDevice) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.beacon) && !uptDevice) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.sim) && !uptDevice) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.device)) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.district)) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.province)) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.neighborhood)) {
      this.showErrors = true;
    }
    if (!this.selectCheck(a.automatPaymentSystemId)) {
      this.showErrors = true;
    }
    if (!this.inputCheck(a.name)) {
      this.showErrors = true;
    }
    if (!this.inputCheck(a.serial)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(a.maxProductPrice)) {
      this.showErrors = true;
    }
    if (!this.mapCheck()) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.countryCode)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.decimalPlaces)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.featureLevel)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.maxRespTime)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.miscOptions)) {
      this.showErrors = true;
    }
    if (!this.numberCheck(conf.scaleFactor)) {
      this.showErrors = true;
    }
    if (this.showErrors) {
      return false;
    }

    return true;
  }

  selectCheck(val: string): boolean {
    return val !== null && val !== '-1';
  }

  inputCheck(val: string): boolean {
    if (val) {
      return val !== null && val.trim() !== '';
    }
  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }

  numberCheck(val: number): boolean {
    return val !== null && val >= 0;
  }

  mapCheck(): boolean {
    return this.automat.location.coordinates.length > 0;
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

  log(a) {
  }

  isUPTDevice(deviceId) {
    if (deviceId) {
      const uptDevice = this.devices.find(x => x._id === deviceId);
      return uptDevice ? uptDevice.deviceType === 2 : false;
    }
    return false;
  }

  // onScrollBrand() {
  //   if (this.page + 1 > this.pages) { return; }
  //     $('#page-loader').fadeIn();
  //     this.page += 1;
  //     this.automatBrandService.getAllBrands(this.page)
  //         .subscribe(response => {
  //             $('#page-loader').fadeOut();
  //             for (const item of response.docs) {
  //                 this.brands.push(item);
  //                 this.brands = [...this.brands];
  //             }
  //             this.pages = response.pages;
  //         }, error => {
  //             $('#page-loader').fadeOut();
  //             Swal.fire({
  //               ...this.swalOptions.error,
  //               text: 'Ürünlerin devamı yüklenemedi!'
  //             });
  //         });
  // }
}
