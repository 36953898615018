import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';


// Import RxJs required methods

@Injectable({
  providedIn: 'root'
})
export class VposService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getFirmVPosses(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-vPoses', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmVPossesForAutomatFormPopup(automatId?:string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + `firm-pos-from-create-automat-form/${automatId}`, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }
}
