import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {PipesModule} from '../_pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {NgxPermissionsModule} from 'ngx-permissions';
import {UsersComponent} from './users.component';
import {UserEditComponent} from './user-edit/user-edit.component';
import {UsersRoutingModule} from './users.routing';
import {UserCardComponent} from './user-card/user-card.component';
import {FormfieldPhoneModule} from '../_widgets/formfield-phone/formfield-phone.module';
import { SharedModule } from '../_shared/shared.module';

const dec_exp = [
  UsersComponent,
  UserEditComponent
];



@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    NgxSpinnerModule,
    PipesModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    UsersRoutingModule,
    SharedModule,
    FormfieldPhoneModule
  ],
  declarations: [dec_exp, UserCardComponent],
  exports: [dec_exp],
})
export class UsersModule {
}

