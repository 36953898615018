import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '../../../_services/payment.service';
import {AutomatPaymentSystem} from '../../../_models/automatPaymentSystem';
import {NgxSpinnerService} from 'ngx-spinner';
import {PaymentsFirms} from '../../../_models/paymentsFirms';
import {FirmService} from '../../../_services/firm.service';
import {DndDropEvent} from 'ngx-drag-drop';
import {SwalHelper} from '../../../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {AuthenticationService} from '../../../_services/authentication.service';

@Component({
  selector: 'app-template-payment',
  templateUrl: './template-payment.component.html',
  styleUrls: ['./template-payment.component.css']
})
export class TemplatePaymentComponent implements OnInit {


  @ViewChild('closeTemplateModal', {static: false}) private closeTemplateModal: ElementRef;


  public automatPaymentSystemName = '';
  public automats: [any];

  public paymentTemplates: Array<AutomatPaymentSystem>;
  public orderedPaymentTemplate: AutomatPaymentSystem = new AutomatPaymentSystem();

  public selectedGroupTable = '-1';
  public selectedTemplateIdTable = '-1';
  public automatPaymentSystemId = '';

  public tempAvailablePaymentSystemsFirms = [];
  public selectedIndex = null;
  /* public templateStructure: Array<PaymentsFirms> = [];*/
  public mouseOnTemplate = null;
  public templateModalRouter = '';
  public automatPaymentSystems: Array<AutomatPaymentSystem>;

  public whereIsDraggableData = 'None';
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: PaymentsFirms,
    effectAllowed: 'all',
    disable: false,
    handle: false
  };

  swalOptions = new SwalHelper();
  firmid: string;
  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor;
  public loadingSpinnerSize;
  public loadingSpinnerColor;
  public loadingSpinnerType;
  public loadingSpinnerText;

  //END : Ngx-spinner değişkenleri (Loading)

  constructor(private paymentService: PaymentService,
              private loadingSnipper: NgxSpinnerService,
              private firmService: FirmService,
              private auth: AuthenticationService) {
  }

  ngOnInit() {
    this.firmid = this.auth.user$.getValue().firm._id;
    this.paymentService.getAutomatPaymentSystems().subscribe(response => {
      this.paymentTemplates = response.data;
      this.automatPaymentSystems = response.data;
    });
    this.firmService.getFirmInfo(this.firmid).subscribe(res => {
      if (res.success) {
        this.tempAvailablePaymentSystemsFirms = res.data.paymentSystemsFirmsInfo;
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    });
  }


  createAutomatPaymentSystem() {
    this.paymentService.createAutomatPaymentSystem(this.automatPaymentSystemName).subscribe(res => {
      this.paymentService.getAutomatPaymentSystems().subscribe(response => {
        this.paymentTemplates = response.data;
        this.closeTemplateModal.nativeElement.click();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      });
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'İşlem Başarısız!'
      });
    });
  }

  updateAutomatPaymentSystem() {
    this.paymentService.updateAutomatPaymentSystem(this.automatPaymentSystemName, this.automatPaymentSystemId).subscribe(res => {
      this.paymentService.getAutomatPaymentSystems().subscribe(response => {
        this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
        this.paymentTemplates = response.data;
        this.closeTemplateModal.nativeElement.click();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'İşlem Başarılı.'
        });
      });
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'İşlem Başarısız!'
      });
    });
  }


  saveOrderedTemplate() {
    Swal.fire({
      ...this.swalOptions.question,
      text: 'Yaptığınız değişiklik, şablonun kullanıldığı tüm otomatları etkileyecek. Bu işlemi onaylıyor musunuz?',
      title: 'Uyarı'
    }).then((result) => {
      if (result.value) {
        if (this.orderedPaymentTemplate.paymentSystems) {
          this.orderedPaymentTemplate.paymentSystems.forEach((data, index) => {
            data.paymentSystemsFirmPriority = index;
          });
        }
        this.paymentService.saveOrderedAutomatPaymentSystem(this.orderedPaymentTemplate).subscribe(res => {
          this.paymentService.getAutomatPaymentSystems().subscribe(response => {
            this.getFirmAutomats(this.selectedGroupTable, this.selectedTemplateIdTable);
            this.paymentTemplates = response.data;
            this.automatPaymentSystems = response.data;
            this.cancelSelect();
            Swal.fire({
              ...this.swalOptions.success,
              text: 'İşlem Başarılı.'
            });
          });
        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'İşlem Başarısız!'
          });
        });

        this.cancelSelect();
      }


    });

  }


  getFirmAutomats(group, automatPaymentSystemId) {
    this.loadingSnipper.show();
    this.paymentService.getAutomatsForPayment(group, automatPaymentSystemId).subscribe(Response => {
      this.automats = Response.automats;
      this.loadingSnipper.hide();
    }, error => {
      this.loadingSnipper.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomatlar sunucudan alınamadı!'
      });
    });
  }


  onDragStart(event: DragEvent, where) {
    this.whereIsDraggableData = where;
  }

  onDragEnd(event: DragEvent) {

  }

  onDraggableCopied(event: DragEvent) {

  }

  onDraggableLinked(event: DragEvent) {

  }

  onDraggableMoved(event: DragEvent) {

  }

  onDragCanceled(event: DragEvent) {
  }

  onDragover(event: DragEvent) {

  }

  onDrop(event: DndDropEvent) {
    if (this.whereIsDraggableData === 'paymentFirms' && this.tempAvailablePaymentSystemsFirms) {
      this.tempAvailablePaymentSystemsFirms.forEach((data, index) => {
        if (data.paymentSystemsFirmId.toString() === event.data.paymentSystemsFirmId.toString()) {
          this.tempAvailablePaymentSystemsFirms.splice(index, 1);
        }
      });
      this.orderedPaymentTemplate.paymentSystems.splice(event.index, 0, event.data);
    }
    if (this.whereIsDraggableData === 'sorting' && this.orderedPaymentTemplate.paymentSystems) {
      this.orderedPaymentTemplate.paymentSystems.forEach((data, index) => {
        if (data.paymentSystemsFirmId.toString() === event.data.paymentSystemsFirmId.toString()) {
          this.orderedPaymentTemplate.paymentSystems.splice(index, 1);
        }
      });
      this.orderedPaymentTemplate.paymentSystems.splice(event.index, 0, event.data);
    }
  }


  selectTemplate(y, template) {
    this.selectedIndex = y;
    this.loadingSnipper.show();
    this.orderedPaymentTemplate = new AutomatPaymentSystem();
    this.tempAvailablePaymentSystemsFirms = [];
    this.firmService.getFirmInfo(this.firmid).subscribe(resInfo => {
      this.loadingSnipper.hide();
      if (resInfo.success) {
        this.tempAvailablePaymentSystemsFirms = resInfo.data.paymentSystemsFirmsInfo;
        this.paymentService.getAutomatPaymentSystem(template._id).subscribe(res => {
          this.orderedPaymentTemplate = res;
          if ( this.orderedPaymentTemplate.paymentSystems && this.tempAvailablePaymentSystemsFirms) {
            this.orderedPaymentTemplate.paymentSystems.forEach((data, index) => {
              this.tempAvailablePaymentSystemsFirms.forEach((data2, index2) => {
                if (data.paymentSystemsFirmId.toString() === data2.paymentSystemsFirmId.toString()) {
                  this.tempAvailablePaymentSystemsFirms.splice(index2, 1);
                }
              });
            });
          }
        }, error => {
          this.loadingSnipper.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'İşlem Başarısız!'
          });
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem Başarısız!'
        });
      }
    }, error => {
      this.loadingSnipper.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'İşlem Başarısız!'
      });
    });
  }

  deleteTemplate(template) {
    Swal.fire({
      ...this.swalOptions.question,
      text: 'Bu işlemin geri dönüşü yok. İşlemi onaylıyor musunuz?'
    }).then((result) => {
      if (result.value) {
        this.paymentService.deleteAutomatPaymentSystem(template._id).subscribe(res => {
          this.paymentService.getAutomatPaymentSystems().subscribe(response => {
            this.paymentTemplates = response.data;
            Swal.fire({
              ...this.swalOptions.success,
              text: 'İşlem Başarılı.'
            });
          });
        }, error => {
          if (error.error.code === '0001') {
            Swal.fire({
              ...this.swalOptions.error,
              text: error.error.message + ' Lütfen şablonu tanımlı olan otomatlardan çıkarınız.'
            });
          } else {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'İşlem Başarısız!'
            });
          }
        });
      }

    });

  }


  deleteElementInTemplate(template) {
    if (this.orderedPaymentTemplate.paymentSystems) {
      this.orderedPaymentTemplate.paymentSystems.forEach((data, index) => {
        if (data.paymentSystemsFirmId.toString() === template.paymentSystemsFirmId.toString()) {
          this.orderedPaymentTemplate.paymentSystems.splice(index, 1);
        }
      });
    }
    if (this.tempAvailablePaymentSystemsFirms) {
      this.tempAvailablePaymentSystemsFirms.push(template);
    }
  }


  startMarquee(y) {
    this.mouseOnTemplate = y;
  }

  stopMarquee() {
    this.mouseOnTemplate = null;
  }

  cancelSelect() {
    this.selectedIndex = null;
  }


  routeTemplateModal(where, template) {
    this.templateModalRouter = where;
    if (where === 'create') {
      this.automatPaymentSystemName = '';
    }
    if (where === 'update') {
      this.automatPaymentSystemName = template.name;
      this.automatPaymentSystemId = template._id;
    }
  }


}
