<a #collapser style="display: none" data-toggle="collapse" [href]="'#collapse'+index">
</a>
<div class="card" (click)="collapser.click()">
  <div class="card-body container">
    <div class="row d-flex">
      <div class="col-md-5">
        <div class="row ">
          <a *ngxPermissionsOnly="['users-edit']" class="col-auto pr-0" (click)="editUser(user)">
            <img class="mt-1" width="auto" height="20" style="vertical-align:baseline"
                 src="../../../assets/img/duzenle.svg"
                 alt="düzenle">
          </a>
          <div class="col pl-0 ml-3 overflow-hidden text-nowrap">
            <h4 class="card-title" [ngClass]="{'slide-text': user.name.length > 10}">
              {{user.name}}
            </h4>
          </div>
        </div>

        <p class="card-subtitle text-muted">
          <small>{{checkAllPermission(user.telemetryPermission) ? 'Tüm yetkiler açık' : 'Kısmi yetkili'}}</small>
          <img src="../../../assets/img/tick.svg" width="20px" class="ml-2">
        </p>
      </div>
      <div class="col text-right d-flex flex-column align-content-around">
        <div class="card-header-info font-weight-bold w-100 my-auto">
          {{user.phoneNumber}}
        </div>
        <div class="card-header-info w-100 my-auto overflow-hidden text-nowrap">
          <p class="m-0" [ngClass]="{'slide-text': user.username.length > 10}">{{user.username}}</p>
        </div>
      </div>
      <div class="col-auto">
        <img class="m-auto" [src]="user.photo && user.photo !== '' ?
         (user.photo | image: ProfileImagePath) : '../../../assets/img/user_image.png'"
             width="40px" style="border-radius: 50%">
      </div>


    </div>


    <hr/>
    <div class="row justify-content-start collapse" [id]="'collapse'+index">
      <div *ngFor="let auth of allpermissions" class="col-md-6">
        <div class="row mb-2">

          <small class="col m-0 text-muted">
            {{auth === 'automats' ? 'Otomat:' : ''}}
            {{auth === 'add-automat' ? 'Otomat ekle:' : ''}}
            {{auth === 'edit-automat' ? 'Otomat düzenle:' : ''}}
            {{auth === 'delete-automat' ? 'Otomat sil:' : ''}}
            {{auth === 'automat-detail' ? 'Otomat Detayları:' : ''}}
            {{auth === 'automat-detail-products' ? 'Otomat Ürünleri:' : ''}}
            {{auth === 'automat-detail-sales' ? 'Otomattaki Satış Raporları:' : ''}}
            {{auth === 'automat-detail-proceeds' ? 'Otomattaki Tahsilat:' : ''}}
            {{auth === 'automat-detail-personels' ? 'Otomat Personelleri:' : ''}}
            {{auth === 'automat-detail-errors' ? 'Otomat Arızaları:' : ''}}
            {{auth === 'automat-groups' ? 'Otomat Grupları:' : ''}}
            {{auth === 'automat-groups-edit' ? 'Otomat Grupları Güncelleme:' : ''}}
            {{auth === 'sales' ? 'Satışlar:' : ''}}
            {{auth === 'sales-report' ? 'Satış Raporları:' : ''}}
            {{auth === 'transaction-statement' ? 'İşlem Özeti:' : ''}}
            {{auth === 'proceeds' ? 'Tahsilatlar:' : ''}}
            {{auth === 'basket-report' ? 'Sepet Raporu:' : ''}}
            {{auth === 'products' ? 'Ürünler:' : ''}}
            {{auth === 'products-edit' ? 'Ürün Güncelleme:' : ''}}
            {{auth === 'personels' ? 'Personeller:' : ''}}
            {{auth === 'personels-edit' ? 'Personel Güncelleme:' : ''}}
            {{auth === 'users' ? 'Kullanıcılar:' : ''}}
            {{auth === 'users-edit' ? 'Kullanıcı Güncelleme:' : ''}}
            {{auth === 'service-care' ? 'Servis/Bakım:' : ''}}
            {{auth === 'settings-firminfo' ? 'Ayarlar - Firma Bilgileri:' : ''}}
            {{auth === 'settings-payment' ? 'Ayarlar - Ödeme Ayarları:' : ''}}
            {{auth === 'settings-payment-method' ? 'Ayarlar - Ödeme Yöntemleri:' : ''}}
            {{auth === 'settings-payment-mobile' ? 'Ayarlar - Mobil Ödeme Yöntemleri:' : ''}}
            {{auth === 'settings-payment-templates' ? 'Ayarlar - Ödeme Şablonları:' : ''}}
            {{auth === 'settings-payment-systems' ? 'Ayarlar - Ödeme Sistemleri:' : ''}}
            {{auth === 'settings-payment-orders' ? 'Ayarlar - Ödeme İstekleri:' : ''}}
            {{auth === 'card-packets' ? 'Kart Paketleri:' : ''}}
            {{auth === 'card-packets-edit' ? 'Kart Paketleri Güncelleme:' : ''}}
            {{auth === 'offline-cards' ? 'Kartları Görüntüleme:' : ''}}
            {{auth === 'offline-cards-edit' ? 'Kartları Güncelleme:' : ''}}
          </small>

          <div class="col-4">
            <img
              [src]="checkPermission(auth,user.telemetryPermission) ? '../../assets/img/tick.svg' : '../../assets/img/cancelCustom.svg'"
              width="15px">
            <small
              [ngStyle]="{'color': checkPermission(auth,user.telemetryPermission) ? '#22b217' : '#ff0507' }">{{checkPermission(auth, user.telemetryPermission) ? 'Açık' : 'Kapalı'}}</small>
          </div>

        </div>
      </div>

    </div>
  </div>

</div>
