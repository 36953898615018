import {User} from './user';
import {Automat} from './automat';
import {FormTemplate} from './formTemplate';

export class Firm {

  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }

  _id: string;
  name: string;
  users: User[];
  automats: Automat[];
  paymentSystemsFirmsInfo: [any];
  mobilePaymentSystemsFirmsInfo;
  updatedAt: string;
  createdAt: string;
  photo: string;

  legalName: string;
  mail: string;
  mobile: string;
  address: string;
  taxAdministration: string;
  taxNo: string;


  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'name',
        label: 'Firma Adı',
        value: this.name,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'legalName',
        label: 'Yasal Ünvan',
        value: this.legalName,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'mail',
        label: 'Email',
        value: this.mail,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'mobile',
        label: 'Telefon',
        value: this.mobile,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'address',
        label: 'Adres',
        value: this.address,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'taxAdministration',
        label: 'Vergi Dairesi',
        value: this.taxAdministration,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'taxNo',
        label: 'Vergi No',
        value: this.taxNo,
        required: true
      })

    ];

    return template;
  }

}
