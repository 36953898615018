import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {BrandService} from '../_services/brand.service';
import {CategoryService} from '../_services/category.service';
import {ProductCategory} from '../_models/productCategory';
import {ProductBrand} from '../_models/productBrand';
import {ProductService} from '../_services/product.service';
import {Product} from '../_models/product';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {ProductEditComponent} from './product-edit/product-edit.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit{
  @ViewChild('brandsSelect', {static: false}) brandsSelect: ElementRef;
  loading = false;
  ProfileImagePath: string;
  ProductImagePath: string;
  product: Product; // Object for Bootstrap modals
  editProductName = ''; // Edit product modal title
  deleteIndexForProduct = -1; // Current product array index

  // Selected filter variables
  selectedCategory: ProductCategory = null;
  selectedBrand: ProductBrand = null;
  selectedSearchText = '';
  // END : Selected filter variables

  // Datas coming from service for select tags
  public categories: ProductCategory[] = [];
  public brands: ProductBrand[] = [];
  public products = Array<Product>();
  isNetworkActive: boolean;
  // END : Datas coming from service for select tags

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)

  //Ngx-loading değişkenleri (Searching)
  public searchingSpinner = false;
  //END : Ngx-loading değişkenleri (Searching)

  page = 1;
  pages = 1;
  total = 0;


  swalOptions = new SwalHelper();


  constructor(
              private brandService: BrandService,
              private categoryService: CategoryService,
              private productService: ProductService,
              private loadingSpinner: NgxSpinnerService,
              private modalService: NgbModal
  ) {
    this.ProfileImagePath = environment.ProfileImagePath;
    this.ProductImagePath = environment.ProductImagePath;
  }

  ngOnInit() {

    // evend-product filtreleme için seçilebilecek brand-categ
    this.brandService.getBrands(this.page).subscribe(response => {
      this.brands = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }, error => {
      console.log(error);
      if (error.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error
        });
        console.log('Error:', error);
      }
    });

    this.categoryService.getCategories(this.page).subscribe(response => {
      this.categories = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }, error => {
      console.log(error);
      if (error.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: error.error
        });
        console.log('Error:', error);
      }
    });

    this.getAllProducts();
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 500) {
      $('#topButton').fadeIn();
    } else {
      $('#topButton').fadeOut();
    }
  }

  topFunction() {
    $('html, body').animate({scrollTop: 0}, 500);
  }

  //firmproduct listesi
  getAllProducts() {
    this.loadingSpinner.show();
    this.isNetworkActive = true;
    this.page = 1;
    this.productService.searchProducts(this.page, '', '', '')
      .subscribe(res => {
        if (res.success) {
          this.isNetworkActive = false;
          this.products = res.result.docs;
          this.pages = res.result.pages;
          this.total = res.result.total;
        }
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Ürün listesi sunucudan getirilirken hata oluştu!'
        });
      });
  }


  setProduct(product, index) {
    this.product = Object.assign({}, product);
    this.deleteIndexForProduct = index;
    if (product == null) {
      this.product = null;
    }

    //modal
    const modalRef = this.modalService.open(ProductEditComponent, {centered: true});
    modalRef.componentInstance.selectedProduct = this.product;
    modalRef.componentInstance.changeProduct.subscribe((res) => {
      this.editHandler(res);
    });
  }

  //modal handler
  editHandler(res) {
    switch (res.event) {
      case 'ADD':
        this.getAllProducts();
        break;
      case 'UPDATE':
        this.updateProductsArray(res.data);
        break;
      case 'DELETE':
        this.products.splice(this.deleteIndexForProduct, 1);
        break;
    }

    this.product = null;
    this.deleteIndexForProduct = -1;
  }


  updateProductsArray(product) {
    this.products.forEach((data, index) => {
      if (data._id === product._id) {
        this.products[index] = product;
      }
    });
  }

  //firmproduct listesi - search filter
  searchProduct(value, select?: string) {
    if (value != null) {
      value = value.trim();
    }
    if (value !== '') {
      this.selectedSearchText = value;
      this.page = 1;
      if (select === 'brand') {
        this.selectedCategory = new ProductCategory();
      } if(select === 'category') {
        this.selectedBrand = new ProductBrand();
      } if(this.selectedCategory === null) {
        this.selectedCategory = new ProductCategory();
      } if(this.selectedBrand === null) {
        this.selectedBrand = new ProductBrand();
      }
      this.productService.searchProducts(this.page, this.selectedCategory._id, this.selectedBrand._id, this.selectedSearchText)
        .subscribe(res => {
          if (this.selectedCategory?._id === '') {
            this.selectedCategory = null;
          } if(this.selectedBrand?._id === '') {
            this.selectedBrand = null;
          }
          this.products = res.result.docs;
          this.pages = res.pages;
          this.searchingSpinner = false;
        }, error => {
          this.searchingSpinner = false;
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Arama sonuçları getirilirken hata oluştu!'
          });
        });
    } else if (value === '') {
      this.selectedSearchText = '';
      this.getAllProducts();
    }
  }

  //firmproduct listesi - infinitescroll pagination
  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    $('#page-loader').fadeIn();
    this.page += 1;
    this.selectedCategory = this.selectedCategory ? this.selectedCategory : new ProductCategory();
    this.selectedBrand = this.selectedBrand ? this.selectedBrand : new ProductBrand();
    this.productService.searchProducts(this.page, this.selectedCategory._id, this.selectedBrand._id, this.selectedSearchText)
      .subscribe(res => {
      this.selectedCategory = this.selectedCategory ? this.selectedCategory : new ProductCategory();
      this.selectedBrand = this.selectedBrand ? this.selectedBrand : new ProductBrand();
        if (this.selectedCategory._id === '') {
          this.selectedCategory = null;
        } if(this.selectedBrand._id === '') {
          this.selectedBrand = null;
        }
        $('#page-loader').fadeOut();
        for (const item of res.result.docs) {
          this.products.push(item);
        }
        this.pages = res.result.pages;

      }, error => {
        $('#page-loader').fadeOut();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Ürünlerin devamı yüklenemedi!'
        });
      });
  }

  onScrollBrand() {
    if (this.page + 1 > this.pages) { return; }
      $('#page-loader').fadeIn();
      this.page += 1;
      this.brandService.getBrands(this.page)
          .subscribe(response => {
              $('#page-loader').fadeOut();
              for (const item of response.docs) {
                  this.brands.push(item);
                  this.brands = [...this.brands];
              }
              this.pages = response.pages;
          }, error => {
              $('#page-loader').fadeOut();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Ürünlerin devamı yüklenemedi!'
              });
          });
  }

  onScrollCategory() {
    if (this.page + 1 > this.pages) { return; }
      $('#page-loader').fadeIn();
      this.page += 1;
      this.categoryService.getCategories(this.page)
          .subscribe(response => {
              $('#page-loader').fadeOut();
              for (const item of response.docs) {
                  this.categories.push(item);
                  this.categories = [...this.categories];
              }
              //this.pages = response.pages;
              this.pages = 1;
          }, error => {
              $('#page-loader').fadeOut();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Ürünlerin devamı yüklenemedi!'
              });
          });
  }
  trackFunction(index, item) {
    return `${item.id}-${index}`;
  }

}
