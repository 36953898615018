<div class="modal-header">
  <h4 class="modal-title">Kart Paketindeki Kullanıcıların Bakiye İşlemleri</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="row mb-5 justify-content-center align-items-center">
      <div class="col-md-3 text-right ">
        <strong>Düşürülecek Bakiye:</strong>
      </div>
      <div class="input-group col-md-4">
        <input [(ngModel)]="singleGroupBalance" (input)="controlSingleUpdateGroupBalance()" type="number"
          (keydown)="validatePrice($event)" min="0" class="form-control custom-input" id="singleGroupBalanceInput"
          placeholder="0.00">
        <div class="input-group-append">
          <span class="input-group-text no-border metin-girisi">₺</span>
        </div>

      </div>
    </div>
    <div class="row mb-2">
      <div *ngIf="singleGroupBalanceApproval" class="col text-center">
        <strong class="text-danger">Dikkat!</strong>
        <div>
          <label>Tüm kullanıcılardan tek seferde <strong class="text-success">{{singleGroupBalance}} ₺</strong>
            düşürülecek onaylıyor musunuz?</label>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div *ngIf="!singleGroupBalanceApproval" class="col-md-6">
        <button class="btn btn-primary" (click)="resetAllBalance()"> Sıfırla
        </button>
      </div>
      <div *ngIf="!singleGroupBalanceApproval" class="col-md-6 text-right">
        <button class="btn btn-primary" disabled> Onayla</button>
      </div>
      <div *ngIf="singleGroupBalanceApproval" class="col text-center">
        <button class="btn btn-primary" (click)="updateSingleGroupBalance()"> Onayla</button>
      </div>
    </div>
    <hr>

  </div>
</div>