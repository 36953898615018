import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {SwalHelper} from '../../_helpers/swal.helper';
import {CashlessService} from '../../_services/cashless.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {OfflineUser} from '../../_models/offlineUser';
import * as XLSX from 'xlsx';
import {OfflineUserGroup} from '../../_models/offlineUserGroup';
import { ExportsHelperService } from '../../_helpers/exports.helper';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-card-packet',
  templateUrl: './new-card-packet.component.html',
  styleUrls: ['./new-card-packet.component.css']
})
export class NewCardPacketComponent implements OnInit {
  swalOptions = new SwalHelper();
  offlineUsersArray = [];

  isExcel: boolean;
  fileName;
  controlVariables: { isInEdit: boolean, index: number, offlineUserGroupId: string };
  group: OfflineUserGroup;
  offlineUser2 = new OfflineUser();

  @Input()
  set selectedGroup(data: OfflineUserGroup) {
    this.group = data;
  }

  @Input()
  set conVariables(data: { isInEdit: boolean, index: number, offlineUserGroupId: string }) {
    this.controlVariables = data;
  }

  @Output() submit = new EventEmitter<{ success: boolean, code: number, data: any }>();


  constructor(
    public activeModal: NgbActiveModal,
    private cashlessService: CashlessService,
    private loadingSpinner: NgxSpinnerService,
    private exportsHelper: ExportsHelperService
  ) {
  }

  ngOnInit(): void {
  }

  checkedBalanceTransfer(balanceTransfer) {
    this.group.balanceTransfer = balanceTransfer;
  }



  handleGroup(is_balance_transfer, whichOne: string) {
    if (this.group.name !== null && this.group.name !== '') {
      if (whichOne === 'excel') {
        if (this.offlineUsersArray.length > 0) {
          this.isExcel = true;
          this.group.balanceTransfer = is_balance_transfer;
          this.loadingSpinner.show();
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.group.firm = currentUser.user.firm._id;
          this.cashlessService.createOfflineUserGroup(this.group, this.offlineUsersArray, this.isExcel).subscribe(res => {
            this.submit.emit({success: true, code: 1, data: null});
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Kart Paketi oluşturuldu.'
            });

          }, error => {

            if (error.message && error.message.code === 11000) {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
                  error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'
              });
            } else if (error.code === 400) {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Eklemek istediğiniz Excel dosyasının ' + error.index + '. satırı yapıya uygun değil.'
              });
            } else {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Kart paketi oluşturulurken hata oluştu!'
              });
            }
          });
        } else {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kullanıcı Eklemeden Kart Paketi Oluşturulamaz. ' +
              'Lütfen örnekte hazırlanmış formatta bir excel dosyası seçiniz veya Tek Kullanıcı sekmesinden kullanıcı oluşturunuz.'
          });
        }
      } else if (whichOne === 'single') {
        if (this.isCardFieldsOkay()) {
          this.isExcel = false;
          this.offlineUsersArray[0] = this.offlineUser2;
          this.group.balanceTransfer = is_balance_transfer;
          this.loadingSpinner.show();
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.group.firm = currentUser.user.firm._id;
          this.cashlessService.createOfflineUserGroup(this.group, this.offlineUsersArray, this.isExcel).subscribe(res => {
            this.submit.emit({success: true, code: 2, data: null});
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Kart Paketi oluşturuldu.'
            });
          }, error => {
            if (error.message && error.message.code === 11000) {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
                  error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'
              });
            } else {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'Kart paketi oluşturulurken hata oluştu!'
              });
            }
          });
        }

      }
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kart Adını Giriniz.'
      });
    }
    if (whichOne === 'update') {
      delete this.group.updatedAt;
      delete this.group.firm;

      this.cashlessService.updateOfflineUserGroup(this.group, this.group._id).subscribe(res => {

        this.submit.emit({success: true, code: 3, data: null});
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart paketi güncellenirken hata oluştu!'
        });
      });
    }

  }

  isCardFieldsOkay(): boolean {
    let isOkay = true;
    if (this.offlineUser2.name === '') {
      isOkay = false;
    }
    if (this.offlineUser2.sicilNo === '') {
      isOkay = false;
    }
    if (this.offlineUser2.gorev === '') {
      isOkay = false;
    }
    if (this.offlineUser2.birim === '') {
      isOkay = false;
    }
    if (this.offlineUser2.cardId === '') {
      isOkay = false;
    }
    if (this.offlineUser2.firmBalance === null) {
      isOkay = false;
    }
    if (isOkay) {
      return this.hexDecControls(this.offlineUser2.cardId, this.offlineUser2.cardIdDec);
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen boş alan bırakmayınız!'
      });
      return false;
    }
  }


  InputIdChange2(event, isHex) {
    if (isHex) {
      const id = parseInt(event, 16).toString().toLowerCase();
      this.offlineUser2.cardIdDec = id === 'nan' ? '' : id;
    } else {
      const id = parseInt(event).toString(16).toLowerCase();
      this.offlineUser2.cardId = id === 'nan' ? '' : id;
    }
  }

  hexDecControls(hex: string, dec: string): boolean {
    if (!hex.match(/^([a-fA-F0-9]){1,8}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '16 tabanlı uid en fazla 8 haneli olabilir, ve 0-9 a-f değerleri alabilir'
      });
      return false;
    }
    if (!dec.match(/^([0-9]){1,10}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '10 tabanlı uid en fazla 10 haneli olabilir ve harf içeremez'
      });
      return false;
    }

    return true;
  }

  onFileChange(evt: any) {
    const file = evt.target ? evt.target.files[0]: evt[0];
    this.fileName = file.name;
    this.offlineUsersArray = undefined;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target) {
      if (target.files.length !== 1) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Birden fazla dosya kullanılamaz!'
        });
        throw new Error('Birden fazla dosya kullanılamaz!');
      }
    } else {
      if (evt.length > 1) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Birden fazla dosya kullanılamaz!'
        });
        throw new Error('Birden fazla dosya kullanılamaz!');
      }
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.offlineUsersArray = XLSX.utils.sheet_to_json(ws, {header: 1});
      if (Array.isArray(this.offlineUsersArray)) {
        for (let _i = 0; _i < this.offlineUsersArray.length; _i++) {
          const data = this.offlineUsersArray[_i][1];
          const data2 = this.offlineUsersArray[_i][2];
          if (typeof data === 'string' || data instanceof String || typeof data2 === 'string' || data2 instanceof String) {
            this.offlineUsersArray[_i][1] = data ? data.toLowerCase() : null;
            this.offlineUsersArray[_i][2] = data2 ? data2.toLowerCase() : null;
          } else {
            Swal.fire({
              ...this.swalOptions.error,
              text: `Dosya içerisinde boş kayıt gözükmekte, Kayıt No : ${_i + 1}`
            });
            this.offlineUsersArray = undefined;
            break;
          }
        }
      } else {
        this.offlineUsersArray = undefined;
      }
    };
    if (target) {
      reader.readAsBinaryString(target.files[0]);
    } else {
      reader.readAsBinaryString(evt[0]);
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.onFileChange($event);
  }


  downloadSampleExcel() {
    this.loadingSpinner.show();
    this.exportsHelper.sampleDownloadExcel();
    this.loadingSpinner.hide();
  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }

  closeModal(form: NgForm) {
    form.resetForm();
    this.activeModal.close();
  }
}
