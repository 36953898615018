import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError as _throw} from 'rxjs';
import {AuthenticationService} from '../_services/authentication.service';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService, private router: Router) {
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403 || err.status === 513) {
      //navigate /delete cookies or whatever
      localStorage.removeItem('currentUser');
      this.auth.user$.next(null);
      this.router.navigateByUrl(`/auth/login`);
      // if you've caught / handled the error, you don't want to rethrow it
      // unless you also want downstream consumers to have to handle it as well.
      // console.log('autherror', err.error);
      return of(err.message); // or EMPTY may be appropriate here
    } else {
      // console.log(err.error);
    }

    return _throw(err);
    /*return of(err);*/

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('new-password/')) {
      // bearer update yapma
    } else if (request.url.includes('geocode/')) {
      // Do not add token
    } else if (request.url.includes('-image')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.token}`
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.auth.token}`,
          'Set-Cookie': 'SameSite=Strict'
        }
      });
    }


    return next.handle(request).pipe(catchError(err => this.handleError(err)));
  }
}
