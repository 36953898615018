import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductsComponent} from './products.component';
import {FormsModule} from '@angular/forms';

import {NgxSpinnerModule} from 'ngx-spinner';
import {PipesModule} from '../_pipes/pipes.module';
import { ProductEditComponent } from './product-edit/product-edit.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgSelectModule} from '@ng-select/ng-select';
import { SharedModule } from '../_shared/shared.module';

const dec = [
  ProductsComponent,
  ProductEditComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    PipesModule,
    NgxPermissionsModule.forChild(),

    InfiniteScrollModule,
    ImageCropperModule,
    NgSelectModule
  ],
  declarations: [dec],
  exports:[dec]
})
export class ProductsModule {
}

