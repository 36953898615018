<div class="row mb-4 fade-in-animation">

  <div *ngFor="let a of automatsFiltered; let i = index" class="col-sm-6 col-md-4 col-lg-3 mt-2 mb-1">
    <div class="container automat-card">
      <div class="row justify-content-center automat-card-header" (mouseenter)="startMarquee(a._id, 'title')" (mouseleave)="stopMarquee('title')">
        <marquee *ngIf="mouseOnTitle && a._id == mouseOnAutomatId" class="wrap-text-one-line" behavior="scroll">
          <strong>{{a.name}}</strong>
        </marquee>
        <strong *ngIf="mouseOnTitle && a._id != mouseOnAutomatId"
          class="wrap-text-one-line-ellipsis">{{a.name}}</strong>
        <strong *ngIf="!mouseOnTitle" class="wrap-text-one-line-ellipsis">{{a.name}}</strong>
      </div>
      <div class="row mt-2">
        <div class="col-auto d-flex" [ngStyle]="{'color': a.isRecentlyInformed ? '#84E296' : '#9B9B9B'}">
          <a class="mr-2 my-auto" *ngIf="a.lastInformed" placement="bottom"
            [ngbTooltip]="getAutomatStatusText(a.lastInformed)">
            <i class="fas" [ngClass]="a.isRecentlyInformed ? 'fa-check-circle blink-infinite' : 'fa-times-circle'" [ngStyle]="{
                'color': a.isRecentlyInformed ? '#84E296' : '#9B9B9B'
               }"></i>
          </a>
          <a class="mr-2 my-auto" *ngIf="!a.lastInformed" placement="bottom"
            ngbTooltip="Son aktif olduğu tarih bilgisi alınamadı.">
            <i class="fas fa-times-circle"></i>
          </a>
          <span class="my-auto font-weight-bold" style="font-size: 12px">
            {{a.isRecentlyInformed ? 'AKTİF' : 'PASİF'}}
          </span>
        </div>

        <div class="col text-right" style="color: #9B9B9B">
          <!--<i class="fas fa-bell"></i>0-->

        </div>
      </div>
      <ng-template [ngIf]="a.image !== null && a?.image !== ''" [ngIfElse]="checkModelImage">
        <div style="text-align: center; height: 166px" class="col align-self-center">
          <img class="mt-3 img-fit" [src]="a?.image | image: AutomatImagePath" default="../../assets/img/gorsel_yok.svg"
            height="150px">
        </div>
      </ng-template>
      <ng-template #checkModelImage>
        <div style="text-align: center; height: 166px" class="col align-self-center"
          *ngIf="a?.automatModel?.image !== null && a?.automatModel?.image !== ''; else noImage">
          <img class="mt-3 img-fit" [src]="a?.automatModel?.image | image: AutomatImagePath"
            default="../../assets/img/gorsel_yok.svg" height="150px">
        </div>
        <ng-template #noImage>
          <div style="text-align: center;">
            <img class="mt-3" src="../../../assets/img/vending-machine.png" style="width: auto; height: 150px">
          </div>
        </ng-template>
      </ng-template>


      <div class="row justify-content-center" (mouseenter)="startMarquee(a._id, 'description')"
        (mouseleave)="stopMarquee('description')">
        <marquee *ngIf="mouseOnDescription && mouseOnAutomatId == a._id"
          class="wrap-text-three-line automat-card-title my-3" behaivor="scroll" direction="up" scrollamount="4">
          <p class="automat-card-title my-3">{{ a?.description ? a?.description : 'Açıklama Yok' }}</p>
        </marquee>
        <p *ngIf="mouseOnDescription && mouseOnAutomatId != a._id"
          class="automat-card-title my-3 wrap-text-three-line-ellipsis">
          {{ a?.description ? a?.description : 'Açıklama Yok' }}</p>
        <p *ngIf="!mouseOnDescription" class="automat-card-title my-3 wrap-text-three-line-ellipsis">
          {{ a?.description ? a?.description : 'Açıklama Yok' }}</p>
      </div>
      <!-- <div *ngIf="!a?.automatModel?.isHot" class="row justify-content-center">
        <p *ngIf="automatStats[i].stock !==0" class="automat-card-detail">{{automatStats[i].stock}} Ürün
          / {{ automatStats[i].price | number | commaDotSwapper  }} ₺</p>
        <p *ngIf="automatStats[i].stock ===0" class="automat-card-detail">Stok Yok</p>
      </div> -->
      <div *ngIf="a?.automatModel?.isHot" class="row justify-content-center">
        <p class="automat-card-detail">Sıcak Otomat</p>
      </div>
      <div class="row justify-content-center">
        <a *ngxPermissionsOnly="['automat-detail']" (click)="goToAutomatDetail(a?._id)" class="btn automat-card-button">Detaylar</a>
      </div>
      <div class="row justify-content-center py-4">
        <i [ngClass]="a?.isCard ? 'active-icon' : 'disable-icon'" class="far fa-credit-card mx-2"></i>
        <i [ngClass]="a?.isTelemetry ? 'active-icon' : 'disable-icon'" class="fa fa-wifi mx-2"></i>
        <i [ngClass]="a?.isMobil ? 'active-icon' : 'disable-icon'" class="fa fa-mobile mx-2"></i>
        <i [ngClass]="a?.isCoin ? 'active-icon' : 'disable-icon'" class="fa fa-money-bill-alt mx-2"></i>
      </div>
    </div>
  </div>


</div>