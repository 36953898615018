
<div class="container">
  <div class="row">
    <div class="col-md-6 offset-md-3 text-center">

      <h3 class="mt-5 text-capitalize font-weight-light">hesap şifresini değiştir</h3>
      <input class="form-control no-border password-text mt-4" [(ngModel)]="passwordData.password" #productName="ngModel" type="password" name="password" placeholder="Eski Şifre">
      <input class="form-control no-border password-text mt-4" [(ngModel)]="passwordData.newPassword" #productName="ngModel" type="password" name="newPassword" placeholder="Yeni Şifre">
      <input class="form-control no-border password-text mt-4" [(ngModel)]="passwordData.newConfirmPassword" #productName="ngModel" type="password" name="newConfirmPassword" placeholder="Yeni Şifreyi Doğrulayın">

    </div>
  </div>
  <div class="row">
    <div class="col-md-6 offset-md-3 text-center mt-3">

      <button class="btn btn-secondary mt-4 mr-2" style="width: 200px" type="button" (click)="resetData()">Temizle</button>
      <button class="btn btn-secondary mt-4" style="width: 200px" type="button" (click)="changePassword()">Güncelle</button>

    </div>
  </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
