import {AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import * as IntlTelInput from 'intl-tel-input';

declare var $: any;

@Component({
  selector: 'app-formfield-phone',
  templateUrl: './formfield-phone.component.html',
  styleUrls: ['./formfield-phone.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormfieldPhoneComponent implements AfterViewInit {

  errorMap = ['Geçerli Numara', 'Hatalı ülke kodu', 'Çok kısa', 'Çok uzun', 'Geçersiz Numara'];
  _errorMsg: string;
  element: IntlTelInput.Plugin;

  _pNumber: string;

  @Input()
  set phoneNumber(data: string) {
    this._pNumber = data;
    if (this.element) {
      this.element.setNumber(data);
    }
  }

  @Output() phoneNumberChange = new EventEmitter();
  @Output() errorMsg = new EventEmitter();


  constructor() {
  }

  ngAfterViewInit() {
    const phone = document.querySelector('#phoneInput');
    this.element = IntlTelInput(phone,
      {
        preferredCountries: ['tr'],
        utilsScript: 'node_modules/intl-tel-input/build/js/utils.js',
      });
    this.element.promise.then(() => {
      if (this._pNumber) {
        this.element.setNumber(this._pNumber);
      }

      phone.addEventListener('keyup', formatIntlTelInput);
      phone.addEventListener('change', formatIntlTelInput);

      function formatIntlTelInput() {
        if (typeof this.element !== 'undefined') { // utils are lazy loaded, so must check
          const currentText = this.element.getNumber(intlTelInputUtils.numberFormat.E164);
          if (typeof currentText === 'string') { // sometimes the currentText is an object :)
            this.element.setNumber(currentText); // will autoformat because of formatOnDisplay=true
          }
        }
      }
    });


  }

  inputChange() {
    if (this.element) {
      if (this.element.isValidNumber()) {
        this.phoneNumberChange.emit(this.element.getNumber());
        this._errorMsg = null;
      } else {
        const errorCode = this.element.getValidationError();
        this._errorMsg = this.errorMap[errorCode];
        this.errorMsg.emit(this._errorMsg);
      }
    }

  }

  public isValid(): boolean {
    return this.element.isValidNumber();
  }

}
