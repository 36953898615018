import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {QrLoginComponent} from './qr-login/qr-login.component';
import {SubFirmQrRouting} from './sub-firm-qr.routing';
import {SubFirmGenerateQrComponent} from './sub-firm-generate-qr/sub-firm-generate-qr.component';
import {PipesModule} from '../_pipes/pipes.module';
import {DatePickerModule} from '../_widgets/date-picker/date-picker.module';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSpinnerModule,
        SubFirmQrRouting,
        PipesModule,
        DatePickerModule,
        NgbDatepickerModule
    ],
    declarations: [
        QrLoginComponent,
        SubFirmGenerateQrComponent,
    ]
})
export class SubFirmQrModule {
}
