import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../_services/product.service';
import {SaleReportFilter} from '../_models/saleReportFilter';
import {AutomatService} from '../_services/automat.service';
import {FirmService} from '../_services/firm.service';
import {AutomatGroupService} from '../_services/automat-group.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {ExportsHelperService} from '../_helpers/exports.helper';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {SwalHelper} from '../_helpers/swal.helper';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../_models/product';

declare var $: any;

@Component({
  selector: 'app-sale-report',
  templateUrl: './sale-report.component.html',
  styleUrls: ['./sale-report.component.css']
})
export class SaleReportComponent implements OnInit, AfterViewInit {

  @ViewChild('dropdownFilter', {static: false}) dropdownToggle: ElementRef;
  @ViewChild('detailsButton', {static: false}) detailsButton: ElementRef;
  @ViewChild('receiptButton', {static: false}) receiptButton: ElementRef;

  swalOptions = new SwalHelper();

  @ViewChild('filterMenu', {static: false}) private filterMenu: ElementRef;

  newAutomatFilter = false;
  filter: SaleReportFilter = new SaleReportFilter();
  currentUser: any = {};
  automatDistricts: any[];
  automatGroups: any[];
  automatNameIDs: any[];
  groupText: string;
  automatText: string;
  paymentTypeText: string;
  resultTypeText: string;
  rows: Array<any> = [];
  page = 1;
  pages = 1;
  params: any = {};
  incomeParams: IncomeParams = new IncomeParams();
  selectedRow: any;
  modalLoading = false;
  loading = false;

  cash: Cash = new Cash();
  creditCard: CreditCard = new CreditCard();
  PrepaidCard: PrepaidCard = new PrepaidCard();
  qrPayment: any =  {};
  metropolCardQrPayment: any = {};
  total: Total = new Total();
  isDownloadReady = true;
  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  // END : Ngx-spinner değişkenleri (Loading)

  today = {
    start: moment().startOf('day').toISOString(true),
    end: moment().endOf('day').toISOString(true)
  };
  thisWeek = {
    start: moment().startOf('week').toISOString(true),
    end: moment().endOf('week').toISOString(true)
  };
  thisMonth = {
    start: moment().startOf('month').toISOString(true),
    end: moment().endOf('month').toISOString(true)
  };
  lastMonth = {
    start: moment().subtract(1, 'month').startOf('month').toISOString(true),
    end: moment().subtract(1, 'month').endOf('month').toISOString(true)
  };
  last60Days = {
    start: moment().subtract(60, 'days').toISOString(true),
    end: moment().toISOString(true)
  };
  selectedTimeTab = this.today;
  selectedDay: NgbDateStruct;

    receiptData: any = {};
  constructor(private productService: ProductService,
              private automatService: AutomatService,
              private automatGroupService: AutomatGroupService,
              private firmService: FirmService,
              private loadingSpinner: NgxSpinnerService,
              private exportHelper: ExportsHelperService,
              private calendar: NgbCalendar) {

    this.selectedDay = calendar.getToday();
    this.incomeParams.automats = [];
    this.incomeParams.date = moment({
      day: this.selectedDay.day,
      month: this.selectedDay.month - 1,
      year: this.selectedDay.year,
      hour: 0,
      minute: 0,
      second: 0
    }).toISOString(true);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.loadingSpinner.show();
    this.changeTimeTab();

    this.automatService.getFirmAutomatDistricts().subscribe(response => {
      this.automatDistricts = response.result;
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomat bölgeleri sunucudan getirilirken hata oluştu!'
      });

    });
    this.automatGroupService.getFirmAutomatGroups().subscribe(response => {
      this.automatGroups = response.result;
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomat grup listesi sunucudan getirilirken hata oluştu!'
      });
    });
  }

  ngAfterViewInit() {
    $(this.filterMenu.nativeElement).on('click', function (e) {
      e.stopPropagation();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 500) {
      $('#topButton').fadeIn();
    } else {
      $('#topButton').fadeOut();
    }
  }

  topFunction() {
    $('html, body').animate({scrollTop: 0}, 500);
  }

  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    $('#page-loader').fadeIn();
    this.page += 1;
    this.productService.getProductsReport(this.page, this.params).subscribe(res => {
      $('#page-loader').fadeOut();
      for (const item of res.result) {
        this.rows.push(item);
      }
      this.pages = res.pages;

    }, error => {
      $('#page-loader').fadeOut();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Raporun devamı yüklenemedi!'
      });
    });
  }


  changeTimeTab() {
    this.selectedTimeTab.start = moment({
      day: this.selectedDay.day,
      month: this.selectedDay.month - 1,
      year: this.selectedDay.year,
      hour: 0,
      minute: 0,
      second: 0
    }).toISOString(true);
    this.selectedTimeTab.end = moment({
      day: this.selectedDay.day,
      month: this.selectedDay.month - 1,
      year: this.selectedDay.year,
      hour: 23,
      minute: 59,
      second: 59
    }).toISOString(true);
    this.applyFilter();
  }

  getAutomatNameID(event) {
    this.loadingSpinner.show();
    if (this.filter.group !== '') {
      this.firmService.getFirmAutomatGroup(this.filter.group).subscribe(res => {
        this.automatNameIDs = res.result;
        this.filter.automat = res.result;
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Grubun otomatları getirilirken hata oluştu!'
        });
      });
    } else {
      this.automatNameIDs = [];
      this.loadingSpinner.hide();

    }
  }

  downloadExcel() {
    this.loadingSpinner.show();
    this.getDataNoPagination().subscribe(res => {
      this.loadingSpinner.hide();
      if (res.success) {
        if (res.result.length === 0) {
          Swal.fire({
            ...this.swalOptions.info,
            text: 'Tablo içerisinde veri yok!'
          });
          return;
        }
        this.exportHelper.salesReportDownloadExcel(
          res.result,
          this.selectedTimeTab.start,
          this.selectedTimeTab.end
        );
      }
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Rapor yüklenemedi!'
      });
    });

  }

  getPaymentTypeText(type): string {
    switch (type) {
      case 1:
        return 'Kredi Kart';
      case 2:
        return 'Nakit';
      case 3:
        return 'Offline Kart';
      case 4:
        return 'İstanbul Kart';
      case 5:
        return 'QR';
      case 6:
        return 'MetropolCard QR';
      default:
        return '-';
    }
  }


  applyFilter() {
    this.loading = true;
    this.params = {};
    if (this.filter.productPrice !== undefined && this.filter.productPrice !== 0) {
      this.params.productPrice = Number(this.filter.productPrice);
    }
    if (this.filter.amount !== undefined && this.filter.amount !== 0) {
      this.params.amount = Number(this.filter.amount);
    }
    if (this.filter.resultType.toString() !== '-1') {
      this.params.resultType = Number(this.filter.resultType);
    }
    if (this.filter.paymentType.toString() !== '-1') {
      this.params.paymentType = Number(this.filter.paymentType);
    }
    if (this.filter.district !== '') {
      this.params.district = this.filter.district;
    }
    if (this.filter.group !== '') {
      this.params.group = this.filter.group;
    }
    if (this.filter.automat !== []) {
      const idArr = [];
      const idArr2 = [];
      this.filter.automat.forEach(el => {
        idArr.push(el._id);
        idArr2.push({_id:el._id});
      });
      this.params.automat = idArr;
      this.incomeParams.automats = idArr2;
    } else {
      this.params.automat = [];
      this.incomeParams.automats = [];
    }
    if (this.filter.productName !== '') {
      this.params.productName = this.filter.productName;
    }
    this.params.startDate = this.selectedTimeTab.start;
    this.params.endDate = this.selectedTimeTab.end;

    this.page = 1;
    if (this.dropdownToggle) {
      this.dropdownToggle.nativeElement.click();
    }
    this.productService.getProductsReport(this.page, this.params).subscribe(res => {
      this.loading = false;
      this.rows = res.result;
      this.pages = res.pages;
      if (this.rows.length === 0) {
        Swal.fire({
          ...this.swalOptions.info,
          text: 'Bu kriterlerde gösterilecek veri yok!'
        });
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Filtre verileri sunucudan getirilirken hata oluştu!'
      });
    });
    this.incomeParams.date = moment({
      day: this.selectedDay.day,
      month: this.selectedDay.month - 1,
      year: this.selectedDay.year,
      hour: 0,
      minute: 0,
      second: 0
    }).toISOString(true);
    this.productService.getProductsReportIncome(this.incomeParams).subscribe(res => {
      if (res.success) {
        this.cash.amount = res.result[0]?.volumeOfCashTransaction || 0;
        this.cash.count = res.result[0]?.countOfCashTransaction || 0;
        this.creditCard.amount = res.result[0]?.volumeOfOnlineCardTransaction || 0;
        this.creditCard.count = res.result[0]?.countOfOnlineCardTransaction;
        this.PrepaidCard.amount = res.result[0]?.volumeOfOfflineCardTransaction || 0;
        this.PrepaidCard.count = res.result[0]?.countOfOfflineCardTransaction || 0;
        this.total.amount = res.result[0]?.volumeOfTotalTransaction || 0;
        this.total.count = res.result[0]?.countOfTotalTransaction || 0;
        this.qrPayment.amount = res.result[0]?.volumeOfQrTransaction || 0;
        this.qrPayment.count = res.result[0]?.countOfQrTransaction || 0;
        this.metropolCardQrPayment.amount = res.result[0]?.volumeOfMetropolCardQrTransaction || 0;
        this.metropolCardQrPayment.count = res.result[0]?.countOfMetropolCardQrTransaction || 0;
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Filtre verileri sunucudan getirilirken hata oluştu!'
      });
    });

  }

  clearFilter() {
    this.filter = new SaleReportFilter();
    this.params = {};
    this.changeTimeTab();
  }


  showDetails(data) {
    this.selectedRow = data;
    if (data._id.telemetryTransaction && typeof data._id.telemetryTransaction === 'string') {
      this.getReportTransactions(data);
    }

    this.detailsButton.nativeElement.click();


  }

  getReportTransactions(data) {
    this.modalLoading = true;
    this.productService.getTelemetryTransaction(data._id.telemetryTransaction).subscribe(res => {
      if (res.success) {
        data._id.telemetryTransaction = res.telemetryTransaction;
      }
      this.modalLoading = false;
    }, err => {
      this.modalLoading = false;
    });
  }

  getDataNoPagination(): Observable<any> {
    return this.productService.getAllProductsReport(this.params);
  }

  searchAutomat(term: string, item: any) {
    return item.name.toLocaleLowerCase().trim().includes(term.toLocaleLowerCase().trim());
  }


  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }

    showReceipt(payment, voidReceipt) {
        this.modalLoading = true;
        this.detailsButton.nativeElement.click();
        this.productService.getReportReceiptByPaymentForResult(payment._id, voidReceipt).subscribe(
            res => {
                this.modalLoading = false;
                if (res.success) {
                    if (voidReceipt) {
                        this.receiptData = res.result.voidReceipt;
                    } else {
                        this.receiptData = res.result.successReceipt;
                    }
                }
                this.receiptButton.nativeElement.click();
            },
            error => {
                this.modalLoading = false;
            }
        );
    }

    backToDetail() {
        this.receiptButton.nativeElement.click();
        this.detailsButton.nativeElement.click();
    }
}
export class IncomeParams {
  date: string;
  automats: Automat[];
}
export interface Automat {
  _id: string;
}

export class Cash {
  amount: number;
  count: number
};
export class CreditCard {
  amount: number;
  count: number
};
export class PrepaidCard {
  amount: number;
  count: number
};
export class Total {
  amount: number;
  count: number
};
