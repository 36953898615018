import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public auth: AuthenticationService) {
  }

  canActivate() {

    if (this.auth.user$.getValue()) {
      return true;
    } else {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/auth/login']);
      return false;
    }

  }
}
