import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutomatGroupService {

  // ----------------- Group Automats için gereken _id değeri ----------------
  private messageSource = new BehaviorSubject<string>('-1');
  currentGroupId = this.messageSource.asObservable();


  // ----------------- Group Automats için gereken _id değeri : END ----------

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  changeGroupId(id: string) {
    this.messageSource.next(id);
  }

  getFirmAutomatGroups(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat-groups', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmAutomatGroupsForAutomatFormPopup(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat-groups-from-create-automat-form', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatGroupsForFilter(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-automat-groups', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getGroupAutomats(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'group-automats/' + groupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatGroup(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'automat-group/' + groupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deleteAutomatGroup(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.delete(environment.apiBaseUrl + 'automat-group/' + groupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  createAutomatGroup(automatGroup: any): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'automat-group',
      JSON.stringify(automatGroup),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateAutomatGroup(automatGroup: any, groupId): Observable<any> {
    /*eslint-disable */
    return this.http.put(environment.apiBaseUrl + 'automat-group/' + groupId,
      JSON.stringify(automatGroup),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  addOfflineUserGroupToAutomatGroup(offlineUserGroupId, automatGroupId): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'add-offline-user-group-to-automat-group',
      JSON.stringify({offlineUserGroupId: offlineUserGroupId, automatGroupId: automatGroupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  removeOfflineUserGroupToAutomatGroup(offlineUserGroupId, automatGroupId): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'remove-offline-user-group-to-automat-group',
      JSON.stringify({offlineUserGroupId: offlineUserGroupId, automatGroupId: automatGroupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


}
