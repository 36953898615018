export class FormTemplate<T> {
  controlType: string; //input-password-dropdown-
  type: string; //text-number-password-obj
  order: number;
  label: string;
  key: string;
  value: T;
  required: boolean;
  options?: Array<{key: string, value: string}>;

  constructor(options: {
    value?: T;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    type?: string;
    options?: {key: string, value: string}[];
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
  }
}
