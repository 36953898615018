import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProductService} from '../_services/product.service';
import {AutomatService} from '../_services/automat.service';
import {FirmService} from '../_services/firm.service';
import {AutomatGroupService} from '../_services/automat-group.service';
import {EndOfAutomatSaleHistoryService} from '../_services/end-of-automat-sale-history.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {EndOfAutomatSaleHistory} from '../_models/endOfAutomatSaleHistory';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ProceedsFilter} from '../_models/proceedsFilter';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {ChartHelper} from '../_helpers/chart.helper';
import {ThemeingHelper} from '../_helpers/themeing.helper';

declare var $: any;

@Component({
  selector: 'app-proceeds',
  templateUrl: './proceeds.component.html',
  styleUrls: ['./proceeds.component.css']
})
export class ProceedsComponent implements OnInit, OnDestroy {

  @ViewChild('dropdownFilter', {static: false}) dropdownToggle: ElementRef;

  swalOptions = new SwalHelper();

  locale = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: moment.localeData().firstDayOfWeek()
  };
  selected: { startDate: Moment, endDate: Moment };
  StaffImgPath: string;
  ProfileImagePath: string;

  private pieChart;
  colorIndex = 0;

  endOfAutomatSaleHistory: Array<EndOfAutomatSaleHistory>;
  selectedEOASH: EndOfAutomatSaleHistory;

  filter: ProceedsFilter = new ProceedsFilter();
  automatGroups: [any];
  automatNameIDs: [any];

  pages = 1;
  page = 1;
  rows: Array<EndOfAutomatSaleHistory> = [];

  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  // END : Ngx-spinner değişkenleri (Loading)


  constructor(private productService: ProductService,
              private automatService: AutomatService,
              private automatGroupService: AutomatGroupService,
              private endOfAutomatSaleHistoryService: EndOfAutomatSaleHistoryService,
              private firmService: FirmService,
              private loadingSpinner: NgxSpinnerService,
              private exportsHelper: ExportsHelperService,
              private chartHelper: ChartHelper,
              private themeingHelper: ThemeingHelper) {
    this.StaffImgPath = environment.StaffImagePath;
    this.ProfileImagePath = environment.ProfileImagePath;
  }

  ngOnInit() {
    this.endOfAutomatSaleHistoryService.getFirmFilterEndOfAutomatSaleHistory(this.filter, this.page).subscribe(res => {
      this.endOfAutomatSaleHistory = res.dataEOASH.docs;
      this.rows = this.endOfAutomatSaleHistory;
      this.pages = res.dataEOASH.pages;
    });
    this.automatGroupService.getFirmAutomatGroups().subscribe(response => {
      this.automatGroups = response.result;
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomat grup listesi sunucudan getirilirken hata oluştu!'
      });
    });
  }

  ngOnDestroy() {
  }

  getAutomatNameID() {
    this.loadingSpinner.show();
    if (this.filter.group !== '') {
      this.firmService.getFirmAutomatGroup(this.filter.group).subscribe(res => {
        this.automatNameIDs = res.result;
        this.loadingSpinner.hide();
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Grubun otomatları getirilirken hata oluştu!'
        });

      });
    }
  }

  getDateForProceedsFilter(): any {
    if (this.selected !== undefined && this.selected.startDate !== null && this.selected.endDate !== null) {
      this.filter.startDate = this.selected.startDate.toDate();
      this.filter.endDate = this.selected.endDate.toDate();
      if (this.selected.startDate.isSame(this.selected.endDate)) {
        this.filter.endDate = moment(this.selected.endDate.toDate()).set({hour: 23, minute: 59, second: 59, millisecond: 999}).toDate();
      }
    }
    return this.selected;
  }

  applyFilter() {
    this.page = 1;
    this.loadingSpinner.show();
    if (this.dropdownToggle) {
      this.dropdownToggle.nativeElement.click();
    }
    this.endOfAutomatSaleHistoryService.getFirmFilterEndOfAutomatSaleHistory(this.filter, this.page).subscribe(res => {
      this.endOfAutomatSaleHistory = res.dataEOASH.docs;
      this.rows = this.endOfAutomatSaleHistory;
      this.pages = res.dataEOASH.pages;
      this.loadingSpinner.hide();
    });
  }

  resetFilter() {
    this.filter = new ProceedsFilter();
    this.applyFilter();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 500) {
      $('#topButton').fadeIn();
    } else {
      $('#topButton').fadeOut();
    }
  }

  onScroll() {

    if (this.page + 1 > this.pages) {
      return;
    }
    $('#page-loader').fadeIn();
    this.page += 1;

    this.endOfAutomatSaleHistoryService.getFirmFilterEndOfAutomatSaleHistory(this.filter, this.page).subscribe(res => {
      $('#page-loader').fadeOut();
      for (const item of res.dataEOASH.docs) {
        this.rows.push(item);
      }
      this.pages = res.dataEOASH.pages;

    }, error => {
      $('#page-loader').fadeOut();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Raporun devamı yüklenemedi!'
      });
    });
  }

  topFunction() {
    $('html, body').animate({scrollTop: 0}, 500);
  }

  downloadExcel() {
    if (this.rows.length === 0) {
      Swal.fire({
        ...this.swalOptions.info,
        text: 'Tablo içerisinde veri yok!'
      });
      return;
    }

    this.exportsHelper.proceedsDownloadExcel(this.rows, this.filter.startDate, this.filter.endDate);
  }

  public textColor(i: number) {
   return this.themeingHelper.textColor(i);
  }

  getEndOfAutomatSaleHistory(eoashId) {
    this.endOfAutomatSaleHistory.forEach((eoash, index) => {
      if (eoash._id.toString() === eoashId.toString()) {
        this.selectedEOASH = eoash;
      }
    });
    let array = [];
    let paymentObject = {};
    if (this.selectedEOASH.payments.length > 0) {
      this.selectedEOASH.payments.forEach((payment, index) => {
        paymentObject = {'title': payment.paymentName, 'value': payment.totalPrice};
        array.push(paymentObject);
      });
    } else {
      array = [
        {'title': 'Kredi Kartı', 'value': 0},
        {'title': 'Nakit', 'value': 0},
      ];
    }

    this.loadChart(array);
  }

  loadChart(array) {
    this.pieChart = this.chartHelper.newProductSalesPieChart('payDist', array);

  }

}
