import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
  }

  get(page, status): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + '/' + status + '/' + page, { headers: this.headers }).pipe(
        // ...errors if any
        catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getSunrise(center = { lat: 41.01224, lng:28.976018}) {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.responseText)
          } else {
            reject(xhr.responseText)
          }
        }
      }
      xhr.open("GET", `https://api.sunrise-sunset.org/json?lat=${center.lat}&lng=${center.lng}&formatted=0`, true)
      xhr.send()
    })
  }

  // getSunrise(center = { lat: 39.334943, lng: 35.177772 }):any {
  //   /*eslint-disable */
  //   return this.http.get(`https://api.sunrise-sunset.org/json?lat=${center.lat}&lng=${center.lng}`, { headers: this.headers })
  //   /*eslint-enable */
  // }


  getBounds = (authp: any[]) => {
    const locations = authp.map(x => { return { lat: x.location.coordinates[0], lng: x.location.coordinates[1] } });
    let markers: google.maps.Marker[] = [];
    const bounds = new google.maps.LatLngBounds();

    if (locations.length == 0) {
      const northeast = new google.maps.LatLng(35.334943, 33.177772);
      const southwest = new google.maps.LatLng(41.834943, 35.077772);
      bounds.extend(northeast);
      bounds.extend(southwest);

      // console.log([bounds, markers]);
      return [bounds, markers] as const;
    }
    let northeastLat;
    let northeastLong;
    let southwestLat;
    let southwestLong;
    locations.forEach(function (location) {

      const marker = new google.maps.Marker();
      marker.setPosition(location)
      markers.push(marker);

      if (!northeastLat) {
        northeastLat = southwestLat = location.lat;
        southwestLong = northeastLong = location.lng;
        return;
      }
      if (location.lat > northeastLat) northeastLat = location.lat;
      else if (location.lat < southwestLat) southwestLat = location.lat;
      if (location.lng < northeastLong) northeastLong = location.lng;
      else if (location.lng > southwestLong) southwestLong = location.lng;
    });
    if (locations.length < 2) {
      northeastLat -= 0.5;
      southwestLat += 0.5;
    }
    const northeast = new google.maps.LatLng(northeastLat + 0.5, northeastLong);
    const southwest = new google.maps.LatLng(southwestLat, southwestLong);
    bounds.extend(northeast);
    bounds.extend(southwest);

    // console.log([bounds, markers]);
    return [bounds, markers] as const;

  };

}
