import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MarkerAutomat } from '../../_models/markerAutomat';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AutomatService } from '../../_services/automat.service';

declare var $: any;

@Component({
    selector: 'app-notifications-automat',
    templateUrl: './notifications-automat.component.html',
    styleUrls: ['./notifications-automat.component.css']
})
export class NotificationsAutomatComponent implements OnInit {

    AutomatImagePath = environment.AutomatImagePath;

    //Otomat aktiflik durumu değişkenleri
    onlineAutomat = '../../assets/img/aktif-otomat.svg';
    offlineAutomat = '../../assets/img/otomat-arizali.svg';
    status: boolean;
    statusDesc: string;
    whenOnline: Number;
    oneMinute = 60000;
    oneHour = 3600000;
    oneDay = 86400000;
    //END : Otomat aktiflik durumu değişkenleri

    page = 1;
    pages = 1;
    rows: Array<{
        automatIssue: Array<any>,
        closedFirmUser: string,
        closedStaff: string,
        createdAt: Date,
    }> = [];
    animatedtext: false;


    _automat: MarkerAutomat | any;
    _stats: { price: number, stock: number };

    @Input()
    set automat(data: MarkerAutomat) {
        this._automat = data;
        this.getAutomatInfo();
    }

    @Input()
    set stats(data: { price: number, stock: number }) {
        this._stats = data;
    }

    @Output() submit = new EventEmitter<{ success: boolean, message: string, data: any }>();

    /* @Output() routeEvent = new EventEmitter<{ url: string, params: any }>();*/


    constructor(
        private router: Router,
        private automatService: AutomatService
    ) {
    }

    ngOnInit() {
    }

    close() {
        this.submit.emit({ success: false, message: '', data: null });
    }

    getAutomatInfo() {
        this.automatService.getAutomatNotifications(this._automat.automat._id, 1).subscribe(res => {
            if (res.success) {
                this.rows = res.issueCompleted.docs;
                this.pages = res.issueCompleted.pages;
            } else {
                this.submit.emit({ success: false, message: 'Hareket Verisi Alınamadı', data: null });
            }
        }, err => {
            this.submit.emit({ success: false, message: 'Hareket Verisi Alınamadı', data: null });
        });
    }

    getAutomatStatus(automatStatus: string): boolean {

        const current = new Date();
        const currentDate = new Date().getTime();
        const timeOffset = new Date().getTimezoneOffset();
        const automatDate = new Date(automatStatus.slice(0, 19)).getTime();
        const difference = currentDate - automatDate + (timeOffset * 60000);
        if (difference <= environment.WaitingTimeLimit) {
            this.status = true;
            this.statusDesc = '';
        } else {
            this.status = false;
            if (difference <= this.oneHour) {
                this.whenOnline = Math.floor(difference / this.oneMinute);
                this.statusDesc = 'En son ' + this.whenOnline + ' dakika önce aktifti';
            } else if (difference > this.oneHour && difference <= this.oneDay) {
                this.whenOnline = Math.floor(difference / this.oneHour);
                this.statusDesc = 'En son ' + this.whenOnline + ' saat önce aktifti.';

            } else if (difference > this.oneDay) {
                this.whenOnline = Math.floor(difference / this.oneDay);
                this.statusDesc = 'En son ' + this.whenOnline + ' gün önce aktifti.';
            }
        }
        return this.status;
    }

    onScroll() {
        if (this.page + 1 > this.pages) {
            return;
        }
        $('#page-loader').fadeIn();
        this.page += 1;
        this.automatService.getAutomatNotifications(this._automat._id, this.page).subscribe(res => {
            $('#page-loader').fadeOut();

            if (res.success) {
                res.issueCompleted.docs.forEach(el => {
                    this.rows.push(el);
                });
                this.pages = res.issueCompleted.pages;
            }

        }, error => {
            $('#page-loader').fadeOut();
            this.submit.emit({ success: false, message: 'Hareket Verisi Alınamadı', data: null });
        });
    }

    slideText(enter) {
        this.animatedtext = enter;
    }

    goToAutomatDetail(automatId: string) {
        this.submit.emit({ success: true, message: '/automat-detail', data: automatId });
    }
}
