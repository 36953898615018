import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgxSpinnerModule} from 'ngx-spinner';
import {PipesModule} from '../../_pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {NgxPermissionsModule} from 'ngx-permissions';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {AccountInfoComponent} from './account-info/account-info.component';
import {AccountSettingsRoutingModule} from './account-settings.routing';
import {FirmInfoComponent} from './firm-info/firm-info.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DynamicFormModule} from '../../_widgets/dynamic-form/dynamic-form.module';
import { SharedModule } from '../../_shared/shared.module';
import { SaveCreditCardComponent } from './save-credit-card/save-credit-card.component';

const dec_exp = [
  ChangePasswordComponent,
  AccountInfoComponent,
  FirmInfoComponent,
  SaveCreditCardComponent
];

const imp = [
  AccountSettingsRoutingModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ImageCropperModule,
        NgxSpinnerModule,
        PipesModule,
        RouterModule,
        NgxPermissionsModule.forChild(),
        SharedModule,
        imp,
        DynamicFormModule,

    ],
  declarations: [dec_exp, SaveCreditCardComponent],
  exports: [dec_exp],
})
export class AccountSettingsModule {
}
