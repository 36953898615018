import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {AutomatGroupService} from './automat-group.service';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  // ----------------- Group Automats için gereken _id değeri ----------------
  private messageSource = new BehaviorSubject<string>('-1');
  currentGroupId = this.messageSource.asObservable();


  // ----------------- Group Automats için gereken _id değeri : END ----------

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token,
    'Access-Control-Allow-Origin': '*'
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService
  ) {
  }

  changeQrGroupId(id: string) {
    this.messageSource.next(id);
  }

  searchQrList(filter: any): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    this.removeNullField(filter);
    return this.http.post(paymentApiBaseUrl + 'payment/qr/getFirmQrCodeList',
      filter, {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  generateQrKodList(qrCode: any): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    return this.http.post(paymentApiBaseUrl + 'payment/qr/generateQrCodes',
      qrCode, {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  generateQrKodListForSub(qrCode: any): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    qrCode.isSubFirmUser = true;
    return this.http.post(paymentApiBaseUrl + 'payment/qr/generateQrCodes',
      qrCode, {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


  getAutomatsByGroup(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'qr-group-automats/' + groupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmQrAutomatGroups(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'qr-automat-groups', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  getFirmQrAutomatGroupsForSubFirm(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
        + 'qr-automat-groups-sub-firm', {headers: this.headers}).pipe(
        // ...errors if any
        catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  createQrAutomatGroup(automatGroup: any): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'qr-automat-group',
      JSON.stringify(automatGroup),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateQrAutomatGroup(automatGroup: any, groupId): Observable<any> {
    /*eslint-disable */
    return this.http.put(environment.apiBaseUrl + 'qr-automat-group/' + groupId,
      JSON.stringify(automatGroup),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  deleteAutomatGroup(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.delete(environment.apiBaseUrl + 'qr-automat-group/' + groupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deleteQrCode(qrId: string): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    /*eslint-disable */
    return this.http.delete(paymentApiBaseUrl + 'payment/qr/delete-qr-code/' + qrId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deleteAllQrCodes(): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    /*eslint-disable */
    return this.http.delete(paymentApiBaseUrl + 'payment/qr/delete-all-qr-codes',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateQrPrint(qrIdList): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    /*eslint-disable */
    return this.http.put(paymentApiBaseUrl + 'payment/qr/update-qr-print',
      qrIdList, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateQr(qrIdList: any[], qrCode: any): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    /*eslint-disable */
    return this.http.post(paymentApiBaseUrl + 'payment/qr/update-qr-code/',
      {qrIdList, qrCode}, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  removeNullField(filter) {
    const fields = Object.keys(filter.filter);
    for (const f of fields) {
      if ((filter.filter[f] === null) || (Array.isArray(filter.filter[f]) && filter.filter[f].length === 0)) {
        delete filter.filter[f];
      }
    }
  }
}
