
<div class="container my-5">
  <div *ngxPermissionsOnly="['automat-groups-edit']" class="row justify-content-end mb-4">
    <button class="btn btn-success newbtn animated fadeIn" (click)="clearGroup()" data-toggle="modal" data-backdrop="true" data-target="#automatGroup">
      <span class="fa fa-plus mr-3"></span> {{'VENDING_GROUPS.CREATE_NEW_GROUPS' | translate}}
    </button>
  </div>
  <!-- Başlık -->
  <div *ngIf="groupData.result.length != 0" class="row animated fadeIn">
    <div class="col-sm-4">
      <span class="font-weight-light ml-3">{{'VENDING_GROUPS.GROUP_NAME' | translate}}</span>
    </div>
    <div class="col-sm p-0">
      <span class="font-weight-light">{{'VENDING_GROUPS.VENDING_COUNT' | translate}}</span>
    </div>
  </div>
  <!-- Grup Satırı -->
  <div
    *ngFor="let group of groupData.result; let i = index"
    class="row row-group my-2 animated fadeIn">
    <!-- İlk sütun -->
    <div class="col-sm-4 align-self-center">
      <strong>{{ group?.name }}</strong>
    </div>
    <!-- İkinci sütun -->
    <div class="col-sm-3 align-self-center">
      <div class="row align-items-center">
        <span class="row-automat-count">{{ group.automatList?.length || 0 }}</span>
        <!--<a *ngxPermissionsOnly="['automats']" class="show-automats-button ml-2"
           (click)="goToGroupAutomats(group._id)">
          {{'VENDING_GROUPS.SHOW_VENDINGS' | translate}}
        </a>-->
      </div>

    </div>

    <!-- Dördüncü sütun -->
    <div *ngxPermissionsOnly="['automat-groups-edit']" class="col-sm text-right align-self-center">
      <a (click)="deleteGroup(group._id,i)">
        <i class="fas fa-trash mr-2" style="font-size: 20px; color: #E53935"></i>
      </a>
      <a (click)="setGroup(group, i)" data-toggle="modal" data-backdrop="true" data-target="#automatGroup">
        <i class="fas fa-edit" style="font-size: 20px; color: #757575"></i>
      </a>
    </div>
  </div>
  <!-- Grup Satırı : SON -->
  <!-- Boş liste uyarı -->
  <div *ngIf="groupData.result.length == 0 && !isNetworkActive" class="row justify-content-center animated flipInX">
    <h1 class="h3 mt-5" style="color: gray">{{'VENDING_GROUPS.NO_AUTOMAT_GROUP_HAVE_BEEN_CREATED_YET' | translate}}</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>

<!-- Otomat Grubu Oluştur -->
<div class="modal fade" id="automatGroup" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <form>
          <div class="container">
            <div class="row modal-baslik justify-content-center">
              {{ (controlVariables.isInEdit ? 'VENDING_GROUPS.EDIT_VENDING_GROUP' : 'VENDING_GROUPS.CREATE_VENDING_GROUP') | translate}}
            </div>

            <div class="row mt-5 mb-4">
              <div class="col-md-6 offset-3">
                <input class="form-control no-border metin-girisi text-center" [(ngModel)]="group.name" #name="ngModel" type="text" name="name" placeholder="{{'VENDING_GROUPS.GROUP_NAME' | translate}}">
              </div>
            </div>

            <div class="row mt-5 mb-4">
              <div class="col-md-6 offset-3">
                <ng-select class="automatselect"
                           [items]="automatList"
                           [multiple]="true"
                           [searchable]="true"
                           [searchFn]="search"
                           [closeOnSelect]="false"
                           [hideSelected]="false"
                           bindLabel="name"
                           name="automats"
                           bindValue=""
                           placeholder="{{'VENDING_GROUPS.SELECT_VENDING' | translate}}"
                           [loading]="loading"
                           (scrollToEnd)="onScroll()"
                           [virtualScroll]="true"
                           [(ngModel)]="automats"
                           (ngModelChange)="onSelectUpdate()" (close)="onSelectClose()">
                  <ng-template ng-header-tmp>
                    <button (click)="selectAllAutomat()" class="btn btn-sm btn-primary">{{'VENDING_GROUPS.SELECT_ALL' | translate}}</button>
                    <button (click)="unselectAllAutomat()" class="btn btn-sm btn-secondary ml-2">{{'VENDING_GROUPS.REMOVE_SELECTIONS' | translate}}</button>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label align-self-center"> {{item.name}}</span>
                    </div>
                    <div class="automat-labelplus" *ngIf="items.length > 1">
                      <span>+ {{items.length - 1}}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mr-auto">
                <button class="btn btn-danger modal-buton mt-5 mb-5" (click)="automats = []" data-dismiss="modal" #CloseAutomatGroupModal >{{'VENDING_GROUPS.CANCEL' | translate}}</button>
              </div>
              <!-- ----------- -->
              <div class="col-md-3 ml-auto">
                <button class="btn btn-success modal-buton mt-5 mb-5" (click)="handleGroup()">{{ (controlVariables.isInEdit ? 'VENDING_GROUPS.UPDATE' : 'VENDING_GROUPS.SAVE') | translate}}</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- Kart Paketi Ekle -->
<div class="modal fade" id="addofflineUserGroup" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <form>
          <div class="container">
            <div class="row modal-baslik justify-content-center">
              {{'VENDING_GROUPS.ADD_CARD_PACKAGE' | translate}}
            </div>

            <div class="row mt-5 mb-4">
              <div class="col-md-6 offset-3">
                <ng-select
                  [items]="offlineUserGroups"
                  [multiple]="true"
                  [searchable]="true"
                  [searchFn]="search"
                  [closeOnSelect]="false"
                  [clearable]="false"
                  [hideSelected]="false"
                  bindLabel="name"
                  name="cart"
                  bindValue="_id"
                  placeholder="{{'VENDING_GROUPS.SELECT_CARD' | translate}}"
                  [virtualScroll]="true"
                  [(ngModel)]="offlineUserGroupIds"
                  (change)="onSelectCartUpdate()" (close)="onSelectCartClose()">
                  <ng-template ng-header-tmp>
                    <button (click)="selectAllCarts()" class="btn btn-sm btn-primary">{{'VENDING_GROUPS.SELECT_ALL' | translate}}</button>
                    <button (click)="unselectAllCarts()" class="btn btn-sm btn-secondary ml-2">{{'VENDING_GROUPS.REMOVE_SELECTIONS' | translate}}</button>
                  </ng-template>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label align-self-center"> {{item.name}}</span>
                    </div>
                    <div class="automat-labelplus" *ngIf="items.length > 1">
                      <span>+ {{items.length - 1}}</span>
                    </div>
                  </ng-template>
                </ng-select>
                <select *ngIf="offlineUserGroups && offlineUserGroups === []" name="brand" class="custom-select select-product-modal select-custom-icon" [(ngModel)]="selectedOfflineUserGroupData.offlineUserGroupId">
                  <option value="" disabled>{{'VENDING_GROUPS.SELECT_CARD_PACKAGE' | translate}}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 mr-auto">
                <button class="btn btn-primary modal-buton mt-5 mb-5" data-dismiss="modal" #CloseOfflineGroupModal>{{'VENDING_GROUPS.CANCEL' | translate}}</button>
              </div>
              <!-- ----------- -->
              <div class="col-md-3 ml-auto" >
                <button [disabled]="offlineUserGroupIds.length < 1" class="btn btn-primary modal-buton mt-5 mb-5" (click)="addQrAutomatGroup()">{{'VENDING_GROUPS.ADD' | translate}}</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
