import {throwError as observableThrowError, Observable, BehaviorSubject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';


import {AuthenticationService} from './authentication.service';
import {AddAutomat} from '../_models/addAutomat';
import {EditAutomat} from '../_models/editAutomat';
import {AutomatOperator} from '../_models/automatOperator';
import {Cashless} from '../_models/cashless';

@Injectable({
  providedIn: 'root'
})
export class AutomatService {

  private automatWatchSource = new BehaviorSubject<string>('');
  automatId = this.automatWatchSource.asObservable();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  changeAutomatId(automatId: string) {
    this.automatWatchSource.next(automatId);
  }

  getFirmAutomatDistricts(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'automat-districts',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  fullAutomatProductStocks(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'update-automat-stock-full', JSON.stringify({ automatId: automatId }), {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatListFromAutomatGroupId(groupId: string[]): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'get-automats-id-name',
      JSON.stringify({groupId: groupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getCountAutomats(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'count-automats',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getCountAutomatsStatus(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'count-automats-status',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmAutomats(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat-v2', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmAutomatsold(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmAutomatsforDashboard(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat-dashboard', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAllFirmAutomats(page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-firm-all-automats/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAllFirmAutomatsForQr(page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-firm-all-automats-qr/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmAutomatLastInformated(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat-lastinformed', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  getFirmAutomatsStatus(status: number, page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat/' + status + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getSalesDaily(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'sales-daily', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getStockRate(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'stock-rate-by-automat-spirals', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getSalesMonthly(month: string, year: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'sales-monthly/' + month + '/' + year, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatSalesMonthly(id: string, month: string, year: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'sales-monthly-automat/' + id + '/' + month + '/' + year, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getCashCoinBox(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getDeviceConfig(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'get-device-config/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getAutomatConfig(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'get-automat-config/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getPayvendConfig(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'get-payvend-config/'+ automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updatePayvendConfig(automatConfig): Observable<any> {
    return this.http.post(environment.apiBaseUrl
      + 'update-payvend-config', automatConfig, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getAutomatInfo(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl
      + 'get-automat-info/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getEditAutomatDetail(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-edit-automat/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatProducts(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat-products-all/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAllAutomatProducts(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'all-automat-products/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatStock(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-snack-automat-stock/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getHotAutomatStock(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-hot-automat-stock/' + automatId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  searchAutomats(page: string, name: string, type: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'search-automats/' + page + '/' + name + '/' + type, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getSalesAutomat(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'sales-automat/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueMonthlyAutomat(id: string, month: string, year: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-monthly-automat/' + id + '/' + month + '/' + year, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueLatestAutomat(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-latest-automat/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueCountAutomat(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-issue-count/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  setAutomatActive(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'automat-status-active/' + id,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueAutomat(id: string, page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-automat/' + id + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueAutomatKind(id: string, page: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-automat-kind/' + id + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatNotifications(id: string, page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'get-automat-issue/' + id + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  addAutomat(automat: EditAutomat, config: Cashless): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'create-automat/',
      JSON.stringify({automat: automat, cashlessConfig: config}),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  updateAutomat(automat: EditAutomat, config: Cashless, id: string): Observable<any> {
    return this.http.put(environment.apiBaseUrl + 'automat/' + id,
      JSON.stringify({automat: automat, cashlessConfig: config}),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  deleteAutomat(_id): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + 'automat/' + _id,
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  getAutomatSpirals(automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'automat-spirals/',
      JSON.stringify({automat: automatId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  addCashlessConfig(data): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'add-cashless-config',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  changePriceSystem(value: boolean, automatId: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'change-automat-price-system/' + automatId,
      JSON.stringify({getPriceFromAutomat: value}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateCashlessConfig(data): Observable<any> {
    return this.http.put(environment.apiBaseUrl + 'update-cashless-config/',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getCashlessConfig(automat): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-cashless-config/' + automat,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  getPaymentTypeCounts(automat): Observable<any> {
    const paymentApiBaseUrl = environment.production ? environment.apiBaseUrl : environment.localPaymentUrl;

    /*eslint-disable */
    return this.http.get(paymentApiBaseUrl + 'payment-type-counts/' + automat,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  updateAutomatSpirals(spirals: Array<any>, _id: any): Observable<any> {
    return this.http.put(environment.apiBaseUrl
      + 'automat-spirals/', JSON.stringify({_id: _id, spirals: spirals}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getPowderProduct(automat): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-powder-product-tubes/' + automat,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  getHowManyProduct(automat): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-how-many-product/' + automat,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  getPowderProductTypes(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-all-powder-products',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
  }

  updatePowderProduct(data): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'update-powder-product-tubes',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  uyumSoftExport(data, automatId): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'uyumSoftExport/' + automatId,
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }

  updateAutomatProceeds(automat, user, isProceeds): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'updateAutomatProceeds/' + automat + '/' + user + '/' + isProceeds,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error || 'Server error')));
    /*eslint-enable */
  }
}
