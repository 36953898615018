import {Issue} from './issue';
import {Staff} from './staff';

export class IssueProcess {
  note: string;
  type: number;
  issue: Issue;
  staff: Staff;
  status: number;
}
