import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {FirmService} from '../_services/firm.service';
import {AutomatService} from '../_services/automat.service';
import {AutomatGroupService} from '../_services/automat-group.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {SalesHelper} from '../_helpers/sales.helper';
import {SalesService} from '../_services/sales.service';
import {Sales, SalesChartItem} from '../_models/sales';
import {SaleProduct} from '../_models/saleProduct';
import {SalePaymentType} from '../_models/salePaymentType';
import {ChartHelper} from '../_helpers/chart.helper';
import {PieChart, XYChart} from '@amcharts/amcharts4/charts';
import Swal from 'sweetalert2';
import {SwalHelper} from '../_helpers/swal.helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ThemeingHelper} from '../_helpers/themeing.helper';
import {DatePickerComponent} from '../_widgets/date-picker/date-picker.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit, OnDestroy {
  ProductImagePath = environment.ProductImagePath;
  swalOptions = new SwalHelper();

  @ViewChild('dPicker', {static: true}) datepicker: DatePickerComponent;
  currentTap: number = 1;
  thisWeek = moment().startOf('week');
  thisMonth = moment().startOf('month');
  customTime = [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month')
  ];


  hours = [
    {txt: '00:00', val: 0},
    {txt: '01:00', val: 1},
    {txt: '02:00', val: 2},
    {txt: '03:00', val: 3},
    {txt: '04:00', val: 4},
    {txt: '05:00', val: 5},
    {txt: '06:00', val: 6},
    {txt: '07:00', val: 7},
    {txt: '08:00', val: 8},
    {txt: '09:00', val: 9},
    {txt: '10:00', val: 10},
    {txt: '11:00', val: 11},
    {txt: '12:00', val: 12},
    {txt: '13:00', val: 13},
    {txt: '14:00', val: 14},
    {txt: '15:00', val: 15},
    {txt: '16:00', val: 16},
    {txt: '17:00', val: 17},
    {txt: '18:00', val: 18},
    {txt: '19:00', val: 19},
    {txt: '20:00', val: 20},
    {txt: '21:00', val: 21},
    {txt: '22:00', val: 22},
    {txt: '23:00', val: 23},

  ];
  weekdays = moment.weekdays(true);

  currentUser: any;
  isActive: number;
  isTodaySummaryReady: boolean = false;
  isThisWeekSummaryReady: boolean = false;
  isThisMonthSummaryReady: boolean = false;
  cancelSummaryTodaySubscription: Subscription;
  cancelSummaryWeekSubscription: Subscription;
  cancelSummaryMonthSubscription: Subscription;
  cancelGetSalesSubscription: Subscription;
  hoveredProductSale: number;
  sale: { group: any[], automat: any[] } = {group: [], automat: []};

  allAutomatsSelected = '#d4d7dd';
  selectGroup = [];
  textAll = '#ffffff';

  newAutomatFilter = false;

  automatGroups = [];
  automatList = [];
  selectedGroup = [];
  selectedAutomat: any;

  selectedProduct: SaleProduct = null;

  /*yukarıdaki zaman aralıkları*/
  dailysum = {amount: 0, count: 0};
  monthlysum = {amount: 0, count: 0};
  weeklysum = {amount: 0, count: 0};
  customsum = {amount: 0, count: 0};

  /*Toplam satış grafiği*/
  sales: Array<Sales>;
  salesChart: XYChart;
  isSalesChart: boolean = false;
  salesChartArray: Array<SalesChartItem>;

  /*ödeme dağılımı*/
  paymentTypes: Sales;
  paymentChart: PieChart;
  paymentChartArray: Array<SalePaymentType>;
  noPayment = true;

  /*Ürün satış Dağılımı*/
  saleProducts: Array<SaleProduct>;
  productSort = 1;

  /*Nakit para dağılımı*/
  saleDist: Array<any>;
  distChart;
  distTotal = 0;
  distPercent = 0;

  /*Tahsilat durumu*/
  saleProceeds: Array<any>;

  emptyPaymentChart: Array<{ paymentMethodName: string, volumeOfTotalTransaction: number }> = [{
    paymentMethodName: 'Nakit',
    volumeOfTotalTransaction: 1
  }, {
    paymentMethodName: 'Kredi Kartı',
    volumeOfTotalTransaction: 1
  }, {
    paymentMethodName: 'Ön Ödemeli Kart',
    volumeOfTotalTransaction: 1
  }, {
    paymentMethodName: 'QR Ödeme',
    volumeOfTotalTransaction: 1
  },
  {
    paymentMethodName: 'MetropolCard QR',
    volumeOfTotalTransaction: 1
  }];

  /*Ürün modal*/
  @ViewChild('urunModal') private urunModal: NgbModal;
  @ViewChild('automatGroupsElement') automatGroupsElement: NgSelectComponent;
  @ViewChild('automatListElement') automatListElement: NgSelectComponent;
  modalChart: PieChart;
  modalData: Array<any>;
  selectedProductDetailAutomat = [];
  selectedProductDetailPayment = [];

  currentSaleReportTab = 1; // 1-gün 2-hafta 3-ay 4-son60gün 5-geçenAy
  widgetDates: Array<number> = new Array<number>(5);
  selectedDateRange: Array<{ start: Moment, end: Moment }> = new Array<{ start: Moment, end: Moment }>(5);

  //3rd Party Software
  public thirdPartySoftwareData = {'startDate': new Date(), 'endDate': new Date(), 'invoice': new Date()};

  constructor(private firmService: FirmService,
              private automatService: AutomatService,
              private automatGroupService: AutomatGroupService,
              private router: Router,
              private exportsHelper: ExportsHelperService,
              private salesHelper: SalesHelper,
              private salesService: SalesService,
              private chartHelper: ChartHelper,
              private modalService: NgbModal,
              private themeingHelper: ThemeingHelper) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngOnInit() {
    // @ts-ignore
    $('body').tooltip({selector: '[data-toggle=tooltip]'});
    this.Init();
  }

  ngOnDestroy() {
  }


  Init() {
    this.sale.group = [];
    this.sale.automat = [];
    this.automatGroupService.getAutomatGroupsForFilter().subscribe(response => {
      this.automatGroups = response;
      this.sale.group = [];
      this.sale.automat = [];
      this.automatService.getAutomatListFromAutomatGroupId([this.automatGroups[0]._id]).subscribe(response => {
        this.automatList = response;
        // tab 1 seç
        this.sale.automat = [];
        this.emptySales();
        this.emptyPaymentTypes();
        this.isSalesChart = true;
        this.isTodaySummaryReady = true;
        this.isThisWeekSummaryReady = true;
        this.isThisMonthSummaryReady = true;
      });
    });
  }


  /*ÖZET*/
  getSummaryToday() {
    this.isTodaySummaryReady = false;
    this.cancelSummaryTodaySubscription = this.salesService.salesSummaryHourly(this.getAutomatIDArray()).subscribe(res => {
      this.isTodaySummaryReady = true;
    }, err => {

    });
  }
  cancelSummaryToday() {
    if (this.cancelSummaryTodaySubscription) {
      this.cancelSummaryTodaySubscription.unsubscribe();
      this.cancelGetSalesSubscription.unsubscribe();
      this.isTodaySummaryReady = true;
    }
  }

  getSummaryWeek() {
    this.isThisWeekSummaryReady = false;
    this.cancelSummaryWeekSubscription = this.salesService.salesSummaryDaily(this.getAutomatIDArray(),
      this.thisWeek.toISOString(true), moment().toISOString(true), 'week').subscribe(res => {
      this.isThisWeekSummaryReady = true;
      if (res.success && res.code === 204) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bugüne ait satış bilgisi bulunmamaktadır'
        });
      }
    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
    });
  }

  cancelSummaryWeek() {
    if (this.cancelSummaryWeekSubscription) {
      this.cancelSummaryWeekSubscription.unsubscribe();
      this.cancelGetSalesSubscription.unsubscribe();
      this.isThisWeekSummaryReady = true;
    }
  }

  getSummaryMonth() {
    this.isThisMonthSummaryReady = false;
    this.cancelSummaryMonthSubscription = this.salesService.salesSummaryDaily(this.getAutomatIDArray(),
      this.thisMonth.toISOString(true), moment().toISOString(true), 'month').subscribe(res => {
      this.isThisMonthSummaryReady = true;
      if (res.success && res.code === 204) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bugüne ait satış bilgisi bulunmamaktadır'
        });
      }
    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
    });
  }

  cancelSummaryMonth() {
    if (this.cancelSummaryMonthSubscription) {
      this.cancelSummaryMonthSubscription.unsubscribe();
      this.cancelGetSalesSubscription.unsubscribe();
      this.isThisMonthSummaryReady = true;
    }
  }

  getSummaryCustom() {
    if (this.customTime.length !== 2) {
      return;
    }
    this.salesService.salesSummaryDaily(this.getAutomatIDArray(),
      this.customTime[0].toISOString(true), this.customTime[1].toISOString(true), 'custom').subscribe(res => {

      if (res.success && res.code === 204) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bugüne ait satış bilgisi bulunmamaktadır'
        });
      }
    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
    });
  }

  /*
    getSummaryLastMonth() {
      this.salesService.salesSummaryDaily(this.getAutomatIDArray(), this.lastMonth[0], this.lastMonth[1], 'lastMonth').subscribe(res => {
        if (res.success && res.data) {
          this.lastmonthsum.amount = res.data.volumeOfTotalTransaction;
          this.lastmonthsum.count = res.data.countOfTotalTransaction;
        } else {
          this.lastmonthsum.amount = 0;
          this.lastmonthsum.count = 0;
        }
        if (res.success && res.code === 204) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Bugüne ait satış bilgisi bulunmamaktadır'
          });
        }
      }, err => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bir hata oluştu!'
        });
      });
    }
  */

  /*ÖZET-END*/

  getAutomatIDArray(): Array<string> {
    const ret = [];
    this.sale.automat.forEach(automat => {
      if(automat)
        ret.push(automat._id);
    });
    return ret;
  }

  getAlldata() {
    this.getSales();
    this.getPaymentTypes();
    this.getProductSales();
    /* this.getSaleDist();*/
    /*this.getSaleProdeeds();*/
  }

  /*SATIŞLAR*/
  getSales() {
    this.sales = null;
    this.isSalesChart = false;
    this.salesChartArray = null;
    this.changeDateRange(0, this.widgetDates[0]);

    this.cancelGetSalesSubscription = this.salesHelper.getSales(this.getAutomatIDArray(), this.widgetDates[0], this.selectedDateRange[0]).subscribe(res => {
      this.isSalesChart = true;
      if (res.success && res.data) {
        this.sales = res.data;
        if (this.sales && this.sales.length) {
          this.setupSales(this.widgetDates[0]);
          return;
        } else {
          this.emptySales();
        }
      } else {
        this.emptySales();
      }
      if (res.success && res.code === 204) {
        this.emptySales();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bugüne ait satış bilgisi bulunmamaktadır'
        });
      }

    }, err => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bir hata oluştu!'
      });
    });
  }

    getSalesInit(tab) {
        this.sales = null;
        this.isSalesChart = false;
        this.salesChartArray = null;
        let switchData = this.getchangeDateRange(0, tab);

        this.cancelGetSalesSubscription = this.salesHelper.getSales(this.getAutomatIDArray(), tab, switchData).subscribe(res => {
            this.isSalesChart = true;
            if (res.success && res.data) {
                this.sales = res.data;
                if (this.sales && this.sales.length) {
                    this.setupSales(tab, true);
                    return;
                } else {
                    this.emptySales();
                }
            } else {
                this.emptySales();
            }
            if (res.success && res.code === 204) {
                this.emptySales();
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Bugüne ait satış bilgisi bulunmamaktadır'
                });
            }

        }, err => {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Bir hata oluştu!'
            });
        });
    }

  setupSales(switchData, notShow= false) {
    //burada değerlerin saate göre sıralı olduğu kabul edilir
    let chartArray: Array<SalesChartItem>;
    let start = 0;
    let chartlength;
    let names: Array<any>;

    switch (switchData) {
      case 1:
        chartlength = 24;
        chartArray = new Array<SalesChartItem>(chartlength);
        names = this.hours;

        for (let i = 0; i < chartArray.length; i++) {
          chartArray[i] = new SalesChartItem(names[i].txt, new Sales(moment().set('hour', names[i].val).set('minute', 0)));
        }
          this.dailysum = {amount: 0, count: 0};

        this.sales.forEach((sale) => {
            start = 0;
            for (let i = start; i < chartlength; i++) {
            if (sale.hourOfDay === i) {
              chartArray[i].setSale(sale);
              start = i + 1;
                this.dailysum.amount += sale.volumeOfTotalTransaction;
                this.dailysum.count += sale.countOfTotalTransaction;
              break;
            }
          }
        });

        break;
      case 2:
        chartlength = 7;
        chartArray = new Array<SalesChartItem>(chartlength);
        names = this.weekdays;
        for (let i = 0; i < chartArray.length; i++) {
          chartArray[i] = new SalesChartItem(names[i], new Sales(moment().weekday(i)));

        }
          this.weeklysum = {amount: 0, count: 0};
          this.sales.forEach((sale) => {
              start = 0;
              for (let i = start; i < chartlength; i++) {
                  if (moment(sale.date).get('day') === moment().weekday(i).get('day')) {
                      chartArray[i].setSale(sale);
                      start = i + 1;
                      this.weeklysum.amount += sale.volumeOfTotalTransaction;
                      this.weeklysum.count += sale.countOfTotalTransaction;
                      break;
                  }
              }
          });

        break;
        case 3:
        chartlength = moment().diff(this.thisMonth, 'day') + 1;
        chartArray = new Array<SalesChartItem>(chartlength);

        this.monthlysum = {amount: 0, count: 0};
        for (let i = 0; i < chartArray.length; i++) {
          const day = moment().startOf('month').add(i, 'day');
          chartArray[i] = new SalesChartItem(day.format('D MMM'), new Sales(day));
        }

        this.sales.forEach((sale) => {
          for (let i = start; i < chartArray.length; i++) {
            if (moment(sale.date).get('date') === i + 1) {
              chartArray[i].setSale(sale);
              start = i + 1;
              this.monthlysum.amount += sale.volumeOfTotalTransaction;
              this.monthlysum.count += sale.countOfTotalTransaction;
              break;
            }
          }
        });

        break;
      case 4:
        if (this.customTime.length !== 2) {
          break;
        }
        chartlength = this.customTime[1].diff(this.customTime[0], 'day');
        chartArray = new Array<SalesChartItem>(chartlength + 1);

        const day = moment(this.customTime[0].toISOString(true)).subtract(1, 'day');
        for (let i = 0; i < chartArray.length; i++) {
          day.add(1, 'day');
          chartArray[i] = new SalesChartItem(day.format('D MMM'), new Sales(moment(day)));
        }

        this.customsum = {amount: 0, count: 0};
        this.sales.forEach((sale) => {
          for (let i = start; i < chartArray.length; i++) {
            if (moment(sale.date).format('DD-MM-YYYY') === chartArray[i].date.format('DD-MM-YYYY')) {
              chartArray[i].setSale(sale);
              start = i + 1;
              this.customsum.amount += sale.volumeOfTotalTransaction;
              this.customsum.count += sale.countOfTotalTransaction;
              break;
            }
          }
        });

        break;
    }
    if (!notShow) {
      this.salesChartArray = chartArray;
      if (this.salesChart) {
        this.chartHelper.newData(this.salesChart, chartArray);
      } else {
        this.salesChart = this.chartHelper.newSalesStackedChart('satis-rapor-chart', this.salesChartArray);
      }
    }

  }

  emptySales() {
    this.salesChartArray = new Array<SalesChartItem>(5);
    for (let i = 0; i < 5; i++) {
      this.salesChartArray[i] = new SalesChartItem(i.toString(), new Sales(moment()));
    }
    if (this.salesChart) {
      this.salesChart.data = this.salesChartArray;
      this.salesChart.appear();
    } else {
      this.salesChart = this.chartHelper.newSalesStackedChart('satis-rapor-chart', this.salesChartArray);
    }
  }

  /*SATIŞLAR-END*/

  /*ÖDEME DAĞILIMI*/
  getPaymentTypes() {
    this.paymentTypes = null;
    this.paymentChartArray = null;
    this.paymentChart = null;
    this.changeDateRange(1, this.widgetDates[1]);
    this.salesHelper.getPaymentTypes(this.getAutomatIDArray(), this.widgetDates[1], this.selectedDateRange[1]).subscribe(res => {
      if (res.success && res.data) {
        this.paymentTypes = res.data;
        if (this.paymentTypes) {
          this.setupPaymentTypes();
          return;
        }
      }
      this.emptyPaymentTypes();

    }, err => {

    });
  }

  setupPaymentTypes() {
    this.paymentChartArray = [
      new SalePaymentType(
        'Nakit',
        this.paymentTypes.countOfCashTransaction || 0,
        this.paymentTypes.volumeOfCashTransaction || 0
      ),
      new SalePaymentType(
        'Kredi Kartı',
        this.paymentTypes.countOfOnlineCardTransaction || 0,
        this.paymentTypes.volumeOfOnlineCardTransaction || 0
      ),
      new SalePaymentType(
        'Ön Ödemeli Kart',
        this.paymentTypes.countOfOfflineCardTransaction || 0,
        this.paymentTypes.volumeOfOfflineCardTransaction || 0
      ),
      new SalePaymentType(
        'QR Ödeme',
        this.paymentTypes.countOfQrTransaction || 0,
        this.paymentTypes.volumeOfQrTransaction || 0
      ),
      new SalePaymentType(
        'MetropolCard QR',
        this.paymentTypes.countOfMetropolCardQrTransaction || 0,
        this.paymentTypes.volumeOfMetropolCardQrTransaction || 0
      )
    ];

    this.noPayment = true;
    for (let i = 0; i < this.paymentChartArray.length; i++) {
      if (this.paymentChartArray[i].countOfTotalTransaction !== 0) {
        this.noPayment = false;
        break;
      }
    }
    if (this.noPayment) {
      return this.emptyPaymentTypes();
    }


    if (this.paymentChart) {
      this.chartHelper.newData(this.paymentChart, this.paymentChartArray);
    } else {
      this.paymentChart = this.chartHelper.newPaymentTypePieChart('payDist', this.paymentChartArray);
    }
  }

  emptyPaymentTypes() {
    if (this.paymentChart) {
      this.chartHelper.newData(this.paymentChart, this.emptyPaymentChart);
    } else {
      this.paymentChart = this.chartHelper.newPaymentTypePieChart('payDist', this.emptyPaymentChart);
    }
  }

  /*ÖDEME DAĞILIMI-END*/

  /*ÜRÜN SATIŞ DAĞILIMI*/
  getProductSales() {
    this.saleProducts = null;
    this.changeDateRange(2, this.widgetDates[2]);
    this.salesHelper.getProductSales(this.getAutomatIDArray(), this.widgetDates[2], this.selectedDateRange[2]).subscribe(res => {
      if (res.success && res.data) {
        this.saleProducts = res.data;
        this.sortProducts();
      }
    }, err => {

    });
  }

  sortProducts() {
    if (this.productSort > 0) {
      switch (this.productSort) {
        case 1:
          this.saleProducts.sort((a, b) => {
            return a.countPercent > b.countPercent ? -1 : 1;
          });
          break;
        case 2:
          this.saleProducts.sort((a, b) => {
            return a.volumePercent > b.volumePercent ? -1 : 1;
          });
          break;
      }
    }
  }

  /*ÜRÜN SATIŞ DAĞILIMI-END*/

  /*NAKİT PARA DAĞILIMI*/
  /* getSaleDist() {
     this.saleDist = null;
     this.changeDateRange(3, this.widgetDates[3]);
     this.salesHelper.getSaleDist(this.getAutomatIDArray(), this.widgetDates[3]).subscribe(res => {
       this.saleDist = res;
       if (this.saleDist && this.saleDist.length > 0) {
         this.distChart = this.setupSaleDist();
       } else {
         this.distChart = this.emptySaleDist();
       }

     });
   }

   setupSaleDist(): AmChart {
     this.distTotal = 2000;
     this.distPercent = 60;
     return this.AmCharts.makeChart('distChart', {
       'type': 'gauge',
       'faceAlpha': 0,
       'creditsPosition': 'bottom-left',
       'arrows': [
         {
           'alpha': 1,
           'innerRadius': 10,
           'nailRadius': 10,
           'nailAlpha': 0,
           'nailBorderAlpha': 1,
           'nailBorderThickness': 7,
           'startWidth': 15,
           'radius': '50%',
           'value': this.distPercent,  //değişken gelecek
           'color': '#7ba3cd'
         }
       ],
       'axes': [
         {
           'topText': '',
           'gridInside': false,
           'labelsEnabled': false,
           'axisAlpha': 0,
           'endValue': 100,
           'valueInterval': 10,
           'startAngle': -90,
           'endAngle': 90,
           'minorTickLength': 0,
           'tickLength': 10,
           'tickColor': '#f0f2f8',
           'bands': [
             {
               'color': '#f0f2f8',
               'startValue': 0,
               'endValue': 100,
               'innerRadius': 60
             },
             {
               'color': '#2bd281',
               'startValue': 0,
               'endValue': this.distPercent, //değişken gelecek
               'innerRadius': 60
             }
           ]
         }
       ]
     }, 100);
   }

   emptySaleDist(): AmChart {
     this.distTotal = 0;
     this.distPercent = 0;
     return this.AmCharts.makeChart('distChart', {
       'type': 'gauge',
       'faceAlpha': 0,
       'arrows': [
         {
           'alpha': 1,
           'innerRadius': 10,
           'nailRadius': 10,
           'nailAlpha': 0,
           'nailBorderAlpha': 1,
           'nailBorderThickness': 7,
           'startWidth': 15,
           'radius': '50%',
           'value': 0,
           'color': '#7ba3cd'
         }
       ],
       'axes': [
         {
           'topText': '',
           'gridInside': false,
           'labelsEnabled': false,
           'axisAlpha': 0,
           'endValue': 100,
           'valueInterval': 10,
           'startAngle': -90,
           'endAngle': 90,
           'minorTickLength': 0,
           'tickLength': 10,
           'tickColor': '#f0f2f8',
           'bands': [
             {
               'color': '#f0f2f8',
               'startValue': 0,
               'endValue': 100,
               'innerRadius': 60
             }
           ]
         }
       ]
     }, 100);
   }*/
  /*NAKİT PARA DAĞILIMI-END*/

  /* getSaleProdeeds() {
     this.saleProceeds = null;
     this.changeDateRange(4, this.widgetDates[4]);
     this.salesHelper.getSaleProdeeds(this.widgetDates[4]).then(value => {
       this.saleProceeds = value;
     });
   }*/

  changeAllDateRanges(tab) {
    this.changeDateRange(0, tab);
    this.changeDateRange(1, tab);
    this.changeDateRange(2, tab);
    this.changeDateRange(3, tab);
    this.changeDateRange(4, tab);
  }

  changeDateRange(row, tab) {

    switch (tab) {
      case 1:
        this.selectedDateRange[row] = {
          start: moment().startOf('day'),
          end: moment().endOf('day')
        };
        break;
      case 2:
        this.selectedDateRange[row] = {
          start: moment().startOf('week'),
          end: moment().endOf('week')
        };
        break;
      case 3:
        this.selectedDateRange[row] = {
          start: moment().startOf('month'),
          end: moment().endOf('month')
        };
        break;
      case 4:
        if (this.customTime.length !== 2) {
          break;
        }
        this.selectedDateRange[row] = {
          start: this.customTime[0],
          end: this.customTime[1]
        };
        break;
    }

  }
  getchangeDateRange(row, tab) {

    switch (tab) {
      case 1:
        return {
          start: moment().startOf('day'),
          end: moment().endOf('day')
        };
      case 2:
        return {
          start: moment().startOf('week'),
          end: moment().endOf('week')
        };
      case 3:
        return {
          start: moment().startOf('month'),
          end: moment().endOf('month')
        };
      case 4:
        if (this.customTime.length !== 2) {
          break;
        }
        return  {
          start: this.customTime[0],
          end: this.customTime[1]
        };
    }
  }

  changeWidgetDates(tab: number) {
    this.widgetDates.fill(tab);
    this.getAlldata();
  }

  raporTabClick(tab: number) {
    this.currentTap = tab;
    if (tab === 4) {
      this.datepicker.open();
    } else {
      if(tab === 3) {
        //this.getSummaryMonth();
        this.cancelSummaryToday();
        this.cancelSummaryWeek();
      } else if(tab === 2) {
        //this.getSummaryWeek();
        this.cancelSummaryToday();
        this.cancelSummaryMonth();
      } else {
        //this.getSummaryToday();
        this.cancelSummaryWeek();
        this.cancelSummaryMonth();

      }
      this.currentSaleReportTab = tab;
      this.changeWidgetDates(tab);
      this.changeAllDateRanges(tab);
    }
  }

  customDateSelected(event) {
    this.customTime = event;
    this.currentSaleReportTab = 4;
    this.getSummaryCustom();
    this.changeWidgetDates(4);
    this.changeAllDateRanges(4);
    this.raporTabClick(4);
  }

  onSelectAutomatClose() {
    this.onSelectAutomatUpdate(this.sale.automat);

    this.raporTabClick(this.currentTap);
    if (this.currentTap === 1) {
      //this.getSummaryWeek();
      //this.getSummaryMonth();
    } else if(this.currentTap === 2) {
      //this.getSummaryToday();
      //this.getSummaryMonth();
    } else if (this.currentTap === 3) {
      //this.getSummaryToday();
      //this.getSummaryWeek();
    } else if (this.currentTap === 4) {
      //this.getSummaryToday();
      //this.getSummaryWeek();
      //this.getSummaryMonth();
      //this.getSummaryCustom();
    }

  }

  onSelectGroupClose() {
    if (!this.newAutomatFilter) {
      return;
    }
    this.newAutomatFilter = false;
    this.onChangeGroup(this.sale.group);
  }

  onSelectGroupUpdate() {
    this.newAutomatFilter = true;
    this.onChangeGroup(this.sale.group);
  }

  onSelectAutomatUpdate(automat) {
    if (automat === undefined) {
      automat = [];
      if (this.selectGroup === []) {
        this.allAutomatsSelected = '#d4d7dd';
        this.textAll = '#ffffff';
      }
    } else {
      this.allAutomatsSelected = '#ffffff';
      this.textAll = '#7b7b7b';
    }

    this.selectedAutomat = automat;
    this.getAllDataForTab();
  }

  onChangeGroup(group) {
    this.selectGroup = group;
    if (this.selectGroup.length === 0){
      this.sale.automat = [];
      this.automatList = [];
    } else {
      if (group === []) {
        this.allAutomatsSelected = '#d4d7dd';
        this.textAll = '#ffffff';
      } else {
        this.allAutomatsSelected = '#ffffff';
        this.textAll = '#7b7b7b';
      }
      this.automatService.getAutomatListFromAutomatGroupId(group).subscribe(response => {
        this.automatList = response;
        this.sale.automat = response;
        if (this.sale.automat.length === 0) {
          Swal.fire({
            ...this.swalOptions.default,
            text: 'Seçili Otomat yok ! Lütfen seçim yapınız !'
          });
        }
        this.sale.automat = [];
      });
    }
  }

  onChangeGroupPromise(group) {
    return new Promise((resolve, reject) => {
          this.selectGroup = group;
          if (this.selectGroup.length === 0){
            this.sale.automat = [];
            this.automatList = [];
          } else {
            if (group === []) {
              this.allAutomatsSelected = '#d4d7dd';
              this.textAll = '#ffffff';
            } else {
              this.allAutomatsSelected = '#ffffff';
              this.textAll = '#7b7b7b';
            }
            this.automatService.getAutomatListFromAutomatGroupId(group).subscribe(response => {
              this.automatList = response;
              this.sale.automat = [];
              resolve();
            });
          }
      });
    }

  selectAllAutomat() {
    this.sale.automat = this.automatList.map(x => x);
    this.onSelectAutomatUpdate(this.sale.automat);
  }

  selectFirstAutomat() {
    this.sale.automat = [];
    this.sale.automat.push(this.automatList[0]);
  }

  unselectAllAutomat() {
    this.sale.automat = [];
    this.onSelectAutomatUpdate(this.sale.automat);
  }

  selectAllGroup() {
    this.sale.group = this.automatGroups.map(x => x._id);
    this.onChangeGroup(this.sale.group);
  }

  selectFirstGroup() {
    this.sale.group = [];
    this.sale.group.push(this.automatGroups[0]._id);
  }

  unselectAllGroup() {
    this.sale.group = [];
    this.sale.automat = [];
    this.onChangeGroup(this.sale.group);
  }

  redirect(link: string) {
    this.router.navigateByUrl(link);
  }

  sendThirdPartySoftwareReport(startDate: Date, endDate: Date, invoice: Date, automatId: string) {
    if (startDate.toString() !== '' && endDate.toString() !== '' && invoice.toString() !== '' && automatId !== '-1') {
      this.thirdPartySoftwareData.startDate = startDate;
      this.thirdPartySoftwareData.endDate = endDate;
      this.thirdPartySoftwareData.invoice = invoice;
      this.automatService.uyumSoftExport(this.thirdPartySoftwareData, automatId).subscribe(() => {

      }, error => {

      });
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Tüm alanları doldurduğunuzdan emin olun.'
      });
    }
  }

  getProductSaleDetail(product) {
    const modalRef = this.modalService.open(this.urunModal, {size: 'xl'});

    this.selectedProductDetailAutomat = [];
    this.selectedProductDetailPayment = [];
    this.modalData = [];
    this.selectedProduct = product;
    this.modalChart = null;
    this.firmService.getAutomatsOnSaleProduct(this.selectedProduct.productId,
      this.selectedDateRange[2].start, this.selectedDateRange[2].end, this.getAutomatIDArray()).subscribe(resAutomat => {
      this.selectedProductDetailAutomat = resAutomat.dataPayment;
    });
    this.firmService.getPaymentsOnSaleProduct(this.selectedProduct.productId,
      this.selectedDateRange[2].start, this.selectedDateRange[2].end).subscribe(resPayment => {
      this.selectedProductDetailPayment = resPayment.dataPaymentType;
      let countOfCreditCard = 0;
      let countOfCash = 0;
      this.selectedProductDetailPayment.forEach((payment, index) => {
        if (payment._id.paymentType === 1) {
          payment._id.paymentTypeName = 'Kredi Kartı';
          countOfCreditCard++;
        } else if (payment._id.paymentType === 2) {
          payment._id.paymentTypeName = 'Nakit';
          countOfCash++;
        } else if (payment._id.paymentType === 3) {
          payment._id.paymentTypeName = 'Offline Kart';
        } else {
          payment._id.paymentTypeName = '-';
        }
        if (index === this.selectedProductDetailPayment.length - 1) {
          if (countOfCreditCard === 0) {
            const tempObject = {
              'price': 0,
              'count': 0,
              '_id': {
                'paymentType': 1,
                'paymentTypeName': 'Kredi Kartı'
              }
            };
            this.selectedProductDetailPayment.push(tempObject);
          }

          if (countOfCash === 0) {
            const tempObject = {
              'price': 0,
              'count': 0,
              '_id': {
                'paymentType': 2,
                'paymentTypeName': 'Nakit'
              }
            };
            this.selectedProductDetailPayment.push(tempObject);
          }
        }
        const value = this.numberToAbbreviateNumber(payment.price);
        const paymentObject = {'title': payment._id.paymentTypeName, 'value': value};
        this.modalData.push(paymentObject);
      });

      this.modalChart = this.chartHelper.newProductSalesPieChart('payDist2', this.modalData);
    });
  }

  numberToAbbreviateNumber(number) {
    return (number / 1.0).toFixed(2);
  }

  /*EXCELL*/
  downloadExcell(row) {
    let filteredAutomats;
    if (this.sale.automat.length) {
      filteredAutomats = this.sale.automat;
    } else {
      filteredAutomats = this.automatList;
    }

    switch (row) {
      case 0:
        this.downloadXLSXSales(filteredAutomats);
        break;
      case 1:
        this.downloadXLSXPaymentTypes(filteredAutomats);
        break;
      case 2:
        this.downloadXLSXProductTable(filteredAutomats);
        break;
      case 3:
        this.downloadXLSXSaleDist(filteredAutomats);
        break;
      case 5:
        this.downloadXLSXProductDetail();
        break;
    }
  }

  downloadXLSXSales(filteredAutomats) {
    this.exportsHelper.salesExcell(
      this.salesChartArray,
      /* this.sales,*/
      this.selectedDateRange[0].start.format('DD.MM.YYYY'),
      this.selectedDateRange[0].end.format('DD.MM.YYYY'),
      filteredAutomats
    );

  }

  downloadXLSXPaymentTypes(filteredAutomats) {
    this.exportsHelper.paymentTypesExcell(
      this.paymentChartArray,
      this.selectedDateRange[1].start.format('DD.MM.YYYY'),
      this.selectedDateRange[1].end.format('DD.MM.YYYY'),
      filteredAutomats
    );

  }

  downloadXLSXProductTable(filteredAutomats) {
    this.exportsHelper.saleProductsExcell(
      this.saleProducts,
      this.selectedDateRange[2].start.format('DD.MM.YYYY'),
      this.selectedDateRange[2].end.format('DD.MM.YYYY'),
      filteredAutomats
    );

  }

  downloadXLSXProductDetail() {
    this.exportsHelper.saleProductsDetailExcell(
      this.selectedProduct.productName,
      this.selectedProductDetailAutomat,
      this.selectedDateRange[2].start.format('DD.MM.YYYY'),
      this.selectedDateRange[2].end.format('DD.MM.YYYY'),
    );

  }

  downloadXLSXSaleDist(filteredAutomats) {
    this.exportsHelper.saleDistExcel(
      this.saleDist,
      this.selectedDateRange[3].start.format('DD.MM.YYYY'),
      this.selectedDateRange[3].end.format('DD.MM.YYYY'),
      filteredAutomats
    );

  }

  /*EXCELL-END*/

  public barColor(i: any) {
    return this.themeingHelper.barColor(i);
  }

  public textColor(i: number) {
    return this.themeingHelper.textColor(i);
  }

  public getAllAutomats() {
    this.sale.group = this.automatGroups.map(x => x._id);
    this.onChangeGroupPromise(this.sale.group).then(res => {
      this.automatGroupsElement.focus();
      this.automatListElement.focus();
      this.selectAllAutomat();
      this.onSelectAutomatClose();
    }).catch(err => console.error(err));
    this.allAutomatsSelected = '#d4d7dd';
    this.textAll = '#ffffff';
    //this.sale.group = [];
  }

  searchAutomat(term: string, item: any) {
    return item.name.toLocaleLowerCase().trim().includes(term.toLocaleLowerCase().trim());
  }

    getAllDataForTab() {
        this.getSalesInit(2);
        this.getSalesInit(3);
        this.getSalesInit(1);
    }
}

