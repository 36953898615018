import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';
import { CashlessService } from '../../_services/cashless.service';
import Swal from 'sweetalert2';
import { requestParam, TransactionsDataSource } from '../transactions.datasource';
import * as moment from 'moment';
import {Moment} from 'moment';
import * as mathjs from 'mathjs';
import {OfflineUserService} from '../../_services/offline-user.service';
import { OfflineUserTransactions } from '../../_models/offlineUserTransactions';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwalHelper } from '../../_helpers/swal.helper';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';


export enum TAB {
  'KART_SAHIPLIGI' = 0,
  'HARCAMA_GECMISI' = 1,
  'GECMIS_KULLANICILAR' = 2,
  'BAKIYE_IADE_YUKLEME' = 3,
}

@Component({
  selector: 'app-card-modal',
  templateUrl: './card-modal.component.html',
  styleUrls: ['./card-modal.component.css']
})
export class CardModalComponent implements OnInit {
  @Input() selectedOfflineUserIndex;
  @Input() selectedOfflineUser;
  selectedDate: { startDate: Moment, endDate: Moment };
  customSelectedStartDate: any;
  customSelectedEndDate: any;
  swalOptions = new SwalHelper();
  displayedColumns: string[] = ['Kart Sahibi', 'İşlem Tarihi', 'Otomat', 'Spiral No', 'Ürün', 'Ürün Resmi', 'İşlem Türü', 'Bakiye', 'İşlem Tutarı' ,'Güncel Bakiye'];
  selectedTab: TAB = TAB.KART_SAHIPLIGI;
  selectedOfflineUserTransactions: TransactionsDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedOfflineUserHistory = [];
  options = [10];
  paginatorLength = 100;
  offlineUsers = [];
  balance: number;
  ProductImagePath: string;
  isStateInProcess = false;
  excelEmailAddress = '';
  regexp;
  emailValid = false;
  param: requestParam = {
    startDate:'',
    endDate: '',
    filter: '',
    sortDirection: 'asc',
    offlineUserId: '',
    pageIndex: 0,
    pageSize: 3
  };
  constructor(
    private cashlessService: CashlessService,
    private offlineUserService: OfflineUserService,
    private loadingSpinner: NgxSpinnerService,
    private router: Router,
    private modalService: NgbModal,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.ProductImagePath = environment.ProductImagePath;
    this.selectedTab = TAB.KART_SAHIPLIGI;
    this.selectedOfflineUserTransactions = new TransactionsDataSource(this.cashlessService);
  }

  loadLessonsPage() {
    this._changeDetectorRef.markForCheck();
    const param: requestParam = {
      startDate: this.customSelectedStartDate,
      endDate: this.customSelectedEndDate,
      filter: '',
      sortDirection: this.sort.direction,
      offlineUserId: this.selectedOfflineUser._id,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
      this.selectedOfflineUserTransactions.loadTransactions(param);
  }

  showTab(index) {
    if (this.selectedTab === index) {
      return;
    }
    this.loadingSpinner.show();
    this.selectedTab = index;
    switch (index) {
      case TAB.KART_SAHIPLIGI:
        this.loadingSpinner.hide();
        this.getOfflineUser();
        break;
      // ----------------------------------------
      case TAB.HARCAMA_GECMISI:
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadLessonsPage())
        )
        .subscribe();
        this.setCustomDate();
        this.param.offlineUserId = this.selectedOfflineUser._id;
        this.param.startDate = this.customSelectedStartDate;
        this.param.endDate = this.customSelectedEndDate;
        this.cashlessService.getOfflineUserTransactions2(this.param)
          .subscribe(res => {
              this.paginatorLength = res.pages;
              /* this.sortTransactions();*/
              this.paginator.nextPage();
              this.paginator.previousPage();
              this.getOfflineUser();
              $('.test').scrollTop(0);
              this.loadingSpinner.hide();
            },
            error => {
              this.loadingSpinner.hide();
              Swal.fire({
                ...this.swalOptions.error,
                text: 'İşlem geçmişi alınamadı!'
              });
            });
        break;
      // ----------------------------------------
      case TAB.GECMIS_KULLANICILAR:
        this.cashlessService.getOldOfflineUserList(this.selectedOfflineUser._id).subscribe(res => {
            this.selectedOfflineUserHistory = res;
            this.loadingSpinner.hide();
          },
          error => {
            this.loadingSpinner.hide();
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Geçmiş kullanıcılar alınamadı!'
            });
          });
        break;
      // ----------------------------------------
      case TAB.BAKIYE_IADE_YUKLEME:
        this.loadingSpinner.hide();
        break;
    }
  }

  setCustomDate() {
    if (this.selectedDate === undefined || this.selectedDate.startDate === null) {
      this.customSelectedStartDate = null;
      this.customSelectedEndDate = null;
      return;
    }
    this.customSelectedStartDate = this.selectedDate.startDate.toISOString(false);
    this.customSelectedEndDate = this.selectedDate.endDate.toISOString(false);
    if (this.selectedDate.startDate.isSame(this.selectedDate.endDate)) {
      this.customSelectedEndDate = moment(this.customSelectedEndDate)
        .set({hour: 23, minute: 59, second: 59, millisecond: 999})
        .toISOString();
    }
    this.customSelectedStartDate = moment(this.customSelectedStartDate).toDate();
    this.customSelectedEndDate = moment(this.customSelectedEndDate).toDate();
  }

  getOfflineUser() {
    this.loadingSpinner.show();
    this.offlineUserService.getOfflineUser(this.selectedOfflineUser._id).subscribe(res => {
      if (res === null) {
        Swal.fire({
          ...this.swalOptions.info,
          text: 'Kart kullanıcı bilgisi getirilemedi!'
        }).then(() => {
          this.navigateTo('/card-packets');
        });
      } else {
        this.selectedOfflineUser = res;
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kart kullanıcı bilgisi sunucudan getirilemedi!'
      });
    });
  }

  stateChanged(isActive) {
    this.loadingSpinner.show();
    const val = isActive ? 1 : 0;
    this.isStateInProcess = true;
    this.cashlessService.updateOfflineUserState(this.selectedOfflineUser._id, val).subscribe(res => {
        this.offlineUsers[this.selectedOfflineUserIndex] = res;
        this.selectedOfflineUser = res;
        this.isStateInProcess = false;
        this.loadingSpinner.hide();
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart durumu değiştirilemedi!'
        });
        this.isStateInProcess = false;
      });
  }


  updateOfflineUser(name, cardId, cardIdDec, gorev, sicilNo, birim, explanation1, explanation2, explanation3, newUserCheck) {
    const userData = {
      name: name,
      cardId: cardId,
      cardIdDec: cardIdDec,
      gorev: gorev,
      sicilNo: sicilNo,
      birim: birim,
      explanation1: explanation1,
      explanation2: explanation2,
      explanation3: explanation3,
      oldOfflineUsers: this.selectedOfflineUser.oldOfflineUsers
    };
    this.loadingSpinner.show();
    this.cashlessService.updateOfflineUser(userData, this.selectedOfflineUser._id, newUserCheck).subscribe(res => {
        if (res.success) {
          this.offlineUsers[this.selectedOfflineUserIndex] = res.offlineUser;
          this.selectedOfflineUser = res.offlineUser;
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Kart bilgileri başarıyla güncellendi!'
          });
        }
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart bilgileri güncellenemedi!'
        });
      });
  }

  updateOfflineUserBalance(processType, balance) {
    this.loadingSpinner.show();
    // tslint:disable-next-line:triple-equals
    if (processType === '-1' || balance == null || balance === 0 || balance === '') {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Gerekli iki alanı da doldurunuz!'
      });
      return;
    }
    balance = mathjs.round(balance, 2);
    if (balance <= 0 && processType !== '11') {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Sıfır veya sıfırdan küçük bir değer girmeyiniz!'
      });
      return;
    }
    if (balance >= 0 && processType === '11') {
      balance *= -1;
    }
    const object = Object.assign({}, this.selectedOfflineUser);
    object.firmBalance += mathjs.round(balance, 2);
    this.cashlessService.updateOfflineUserBalance(object, parseInt(processType, 10), mathjs.round(balance, 2)).subscribe(res => {
        this.loadingSpinner.hide();
        this.offlineUsers[this.selectedOfflineUserIndex] = res;
        this.selectedOfflineUser = res;
        this.balance = null;
        this.setCustomDate();
        this.param.offlineUserId = this.selectedOfflineUser._id;
        this.param.startDate = this.customSelectedStartDate;
        this.param.endDate = this.customSelectedEndDate;
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem yapılamadı!'
        });
      });
  }

  resetOfflineUserBalance() {
    this.cashlessService.resetOfflineUserBalance(this.selectedOfflineUser._id).subscribe(res => {
        this.selectedOfflineUser = res;
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Sıfırlama işlemi başarıyla yapıldı!'
        });
        this.loadingSpinner.hide();
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem yapılamadı!'
        });
      });
  }

  getOfflineUserTransactionDateFilter() {
    if (this.selectedDate === undefined) {
      return 0;
    }
    this.setCustomDate();
    this.param.offlineUserId = this.selectedOfflineUser._id;
    this.param.startDate = this.customSelectedStartDate;
    this.param.endDate = this.customSelectedEndDate;
  }

  isEmail(email: string): boolean {
    this.regexp = new RegExp(/^(\s?[^\s;]+@[^\s;]+\.[^\s;]+\s?;)*(\s?[^\s;]+@[^\s;]+\.[^\s;]+)$/);

    this.emailValid = this.regexp.test(email);
    return this.emailValid;
  }

  clearExcelEmailAddress() {
    this.excelEmailAddress = '';
    this.emailValid = true;
    //const modalRef = this.modalService.open(this.mailModal, {centered: true, size: 'xl', scrollable: true});

  }

  downloadXLSX() {
    this.selectedOfflineUserTransactions.transactions$.subscribe(transactions=> {
      if (transactions.length === 0) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Tablo içerisinde veri yok!'
        });
        return;
      }
      this.loadingSpinner.show();
      this.offlineUserService.getOfflineUserTransactionsXls(this.param.offlineUserId, this.selectedOfflineUser.cardId).subscribe(res => {
        console.log(res);
        if(this.offlineUserService.downloadXlsx(res.downloadXLS)) {
          setTimeout(() => {
            this.offlineUserService.deleteOfflineUserTransactionXls(res.downloadXLS.split('.').slice(0, -1).join('.')).subscribe(res => console.log(res));
          }, 1000);
        }
      });
      this.loadingSpinner.hide();
    });
  }

  getTranactionDate(transaction) {
    return (transaction.saleDate === null || transaction.saleDate === undefined) ? transaction.createdAt : transaction.saleDate;
  }


  isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) {
      return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') {
      return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
      if (this.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return true;
  }

  round(value, decimals) {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }

  sendEmailOfflineUserTranscation() {
    if (!this.isEmail(this.excelEmailAddress)) {
      return;
    }

    this.loadingSpinner.show();
    const tableData = this.getXLSXData();
    this.cashlessService.sendOfflineUserTransactionEmail(tableData, this.excelEmailAddress,
      this.selectedOfflineUser.cardId).subscribe(res => {
      if (res.success) {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Email başarılı bir şekilde gönderildi.'
        });
        this.modalService.dismissAll();
      }
    }, error => {

      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Email gönderilemedi!'
      });
    });

  }

  getTransactionProcessTypeText(type) {
    switch (type) {
      case 0:
        return 'İade';
      case 1:
        return 'Yükleme';
      case 2:
        return 'Harcama';
      case 3:
        return 'Sıfırlama';
      case 4:
        return 'Toplu Yükleme';
      case 5:
        return 'Toplu Sıfırlama';
      case 6:
        return 'Periyodik Yükleme';
      case 7:
        return 'Periyodik Sıfırlama';
      case 10:
        return 'Devreden';
      case 11:
        return 'Düşüm';
      case 12:
        return 'Manuel Yükleme';
      default:
        return '-';

    }
  }
  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
  closeCustom() {
    console.log('close active modal');
    this.modalService.dismissAll();  // <-- calling .close() method
  }
  SelectedIdChange(event: string, isHex) {
    if (isHex) {
      const id = parseInt(event, 16).toString().toLowerCase();
      this.selectedOfflineUser.cardIdDec = id === 'nan' ? '' : id;
    } else {
      const id = parseInt(event).toString(16).toLowerCase();
      this.selectedOfflineUser.cardId = id === 'nan' ? '' : id;
    }
  }

  getXLSXData() {
    // this.setCustomDate();
    const tableData = [];
    /* this.selectedOfflineUserTransactions.forEach(data => {
      const date = new Date(this.getTranactionDate(data));
      // noinspection JSNonASCIINames
      const temp = {
        'Kart Sahibi': data.name,
        'İşlem Tarihi': date.toLocaleDateString(),
        'Otomat': this.isEmpty(data.automat) || data.automat === undefined ? '' : data.automat.name,
        'Spiral Numarası': data.spiralNumber,
        'Ürün Adı': this.isEmpty(data.automatProduct) || data.automatProduct === undefined ||
        this.isEmpty(data.automatProduct.product) ? '' : data.automatProduct.product.name,
        'İşlem Türü': this.getTransactionProcessTypeText(data.processType),
        'Miktar': this.getTransactionAmountText(data.processType, data.total),
        'Güncel Bakiye': this.round(data.firmBalance, 2),
        'Para Birimi': 'Türk Lirası'
      };
      tableData.push(temp);
    }); */
    return tableData;

  }

  getTransactionAmountText(type, amount) {
    switch (type) {
      case 0:
      case 1:
      case 4:
      case 6:
      case 10:
      case 11:
      case 12:
        return this.round(amount, 2); // + ' ₺';
      case 2:
        return this.round(amount, 2); // + ' ₺';
      case 3:
      case 5:
      case 7:
        return 'Sıfırlama';
      default:
        return '-';

    }
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

}
