<div [formGroup]="form" class="row">
  <label [attr.for]="question.key" class="col-sm-4 col-form-label">{{question.label}}</label>

  <div [ngSwitch]="question.controlType" class="col-sm-8 input-group">
    <!--input-->
    <input *ngSwitchCase="'input'" class="form-control custom-control text-enter my-auto" [ngClass]="{'fail-input': isInvalid}"
           [formControlName]="question.key" [id]="question.key" [type]="question.type">

    <!--password-->
    <input *ngSwitchCase="'password'" class="form-control custom-control text-enter my-auto"
           [ngClass]="{'fail-input': isInvalid}"
           [formControlName]="question.key" [id]="question.key" [type]="question.type">
    <a *ngSwitchCase="'password'" class="input-group-append" (click)="switch(question)">
      <span class="input-group-text">
        <i class="far" [ngClass]="question.type === 'text' ? 'fa-eye-slash':'fa-eye' "></i>
      </span>
    </a>

    <!--IBAN-->
    <input *ngSwitchCase="'iban'" ibanFormatter class="form-control custom-control text-enter my-auto"
           [ngClass]="{'fail-input': isInvalid}"
           [formControlName]="question.key" [id]="question.key" [type]="question.type">

    <!--dropdown-->

    <select [id]="question.key" *ngSwitchCase="'dropdown'" class="form-control custom-control my-auto" [ngClass]="{'fail-input': isInvalid}"
            [formControlName]="question.key">
      <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
    </select>
    <!---->
  </div>

</div>
