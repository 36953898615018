export class ProductContent {
  productType: number;
  name: string;
  powderProductID: string;
  value: number;

  constructor() {
    this.name = null;
    this.productType = null;
    this.value = null;
    this.powderProductID = null;
  }

}
