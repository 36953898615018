import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {allPerms} from '../../_services/user-permissions.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.css']
})
export class UserCardComponent implements OnInit {
  ProfileImagePath = environment.ProfileImagePath;
  user;
  index;

  allpermissions = allPerms;

  @Input()
  set firmuser(data: { user: any, index: number }) {
    this.user = data.user || {
      name: null, username: null, telemetryPermission: []
    };
    this.index = data.index || 0;
  }

  @Output() changeUser = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  checkPermission(perm: string, userPermArr: string[]): boolean {
    return userPermArr.findIndex((data) => {
      return data === perm;
    }) > -1;
  }

  editUser(user) {
    this.changeUser.emit('edit');
  }

  checkAllPermission(userPermArr: string[]): boolean {
    return this.allpermissions.length === userPermArr.length;
  }
}
