<div class="container-fluid">
    <div class="row" id="header-menu">

        <div class="col-sm-6 col-md-6 col-lg-3 form-inline px-0 ml-auto">

            <div class="container-fluid my-2">
                <div class="row justify-content-end header-icon d-flex">

                    <div *ngIf="currentUser" class="col-auto my-auto text-center dropdown">
                        <img
                             src="../../assets/img/user_image.png" style="cursor: pointer" width="38" height="auto"
                             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                        <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" (click)="logout()">Çıkış Yap</a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>



<div class="container fade-in-animation pt-5 mt-5" *ngIf="evendProducts">

    <input type="text" class="form-control" placeholder="Ürün Arama" #inputstringsearch
           (keyup)="searchInput(inputstringsearch)">
</div>

<div class="container" *ngIf="evendProducts">
    <div
            class="row personel-satiri my-2">
        <div class="col-sm col-md-1 align-self-center">
            <img src="../../../assets/img/gorsel_yok.svg" alt=""
                 height="50" width="50"
                 default="../../../assets/img/gorsel_yok.svg">
        </div>
        <div class="col-sm-2 align-self-center">
            <strong>Fiyat Qr</strong>
        </div>

        <div class="col-sm-2 align-self-center">
            <input type="number" placeholder="Qr Adet" class="form-control" [(ngModel)]="priceQrcode.count">
        </div>

        <div class="col-sm-2 align-self-center">
            <input type="number" placeholder="Qr Fiyat" class="form-control" [(ngModel)]="priceQrcode.price">
        </div>
        <div class="col-4 mx-auto align-self-center">
            <div class="input-group">
                <input
                        class="form-control d-none"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="priceQrcode.model"
                        ngbDatepicker
                        #d2="ngbDatepicker"
                        placement="bottom-right"

                />
                <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button"><i class="fa fa-calendar-alt"></i></button>
                <div class="col pt-2" *ngIf="priceQrcode.model">
                    SKT : {{ priceQrcode.model?.day?.toString()}} / {{ priceQrcode.model?.month?.toString()}} / {{ priceQrcode.model?.year?.toString()}}
                </div>
            </div>
        </div>
        <div class="col-sm-2 align-self-center ml-auto">
            <button class="btn btn-success btn-sm" (click)="generateQrListPrice()"> Qr Oluştur</button>
        </div>
    </div>
</div>

<div class="container my-5 fade-in-animation" *ngIf="evendProducts else dataLoading" (scrollend)="onScroll()"
     style="height:calc( 100vh - 200px); overflow: auto">

    <div *ngFor="let evendProduct of evendProducts; let i = index"
         class="row personel-satiri my-2">
        <div class="col-sm col-md-1 align-self-center">
            <img *ngIf="evendProduct && evendProduct?.image" [src]="evendProduct.image | image: ProductImagePath" alt=""
                 height="50" width="50"
                 default="../../../assets/img/gorsel_yok.svg">
        </div>
        <div class="col-sm-2 align-self-center">
            <strong>{{evendProduct?.name}}</strong>
        </div>
        <div class="col-sm-2 align-self-center">
            Stok :  <strong>{{evendProduct?.stok}}</strong>
        </div>

        <div class="col-sm-2 align-self-center">
            <input type="number" placeholder="Qr Adet" class="form-control" [(ngModel)]="evendProduct.qrCodeCount">
        </div>

        <div class="col-4 mx-auto align-self-center">
            <div class="input-group">
                <input
                        class="form-control d-none"
                        placeholder="yyyy-mm-dd"
                        name="dp"
                        [(ngModel)]="evendProduct.model"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        placement="bottom-right"

                />
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i class="fa fa-calendar-alt"></i></button>
                <div class="col pt-2" *ngIf="evendProduct.model">
                    SKT : {{ evendProduct.model?.day?.toString()}} / {{ evendProduct.model?.month?.toString()}} / {{ evendProduct.model?.year?.toString()}}
                </div>
            </div>
        </div>

        <div class="col-sm-2 align-self-center ml-auto">
            <button class="btn btn-success btn-sm" (click)="generateQrList(evendProduct)"> Qr Oluştur</button>
        </div>
    </div>

    <!-- Boş liste uyarı -->
    <div *ngIf="evendProducts.length == 0" class="row justify-content-center animated flipInX">
        <h1 class="h3 mt-5" style="color: gray"> ÜRÜN BULUNAMDI</h1>
    </div>
    <!-- Boş liste uyarı : SON -->
</div>

<ng-template #dataLoading>
    <div class="col border container-height d-flex flex-column" style="height: 100vh">
        <div class="m-auto">
            <div class="loader fadeIn" style="font-size: 60%"></div>
        </div>
    </div>
</ng-template>


<!-- Yükleme spinner -->
<ngx-spinner
        [bdColor]=loadingSpinnerBdColor
        [size]=loadingSpinnerSize
        [color]=loadingSpinnerColor
        [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
