import {Injectable} from '@angular/core';
import {AutomatService} from '../_services/automat.service';
import {AutomatGroupService} from '../_services/automat-group.service';
import {AutomatBrandService} from '../_services/automat-brand.service';
import {AutomatModelService} from '../_services/automat-model.service';
import {PaymentService} from '../_services/payment.service';
import {DeviceService} from '../_services/device.service';
import {BeaconService} from '../_services/beacon.service';
import {SimService} from '../_services/sim.service';
import {ModemService} from '../_services/modem.service';
import {VposService} from '../_services/vpos.service';
import {LocationService} from '../_services/location.service';
import {StaffService} from '../_services/staff.service';
import {Observable, forkJoin, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutomatHelperService {

  constructor(
    private automatService: AutomatService,
    private automatGroupService: AutomatGroupService,
    private automatBrandService: AutomatBrandService,
    private automatModelService: AutomatModelService,
    private paymentService: PaymentService,
    private deviceService: DeviceService,
    private beaconService: BeaconService,
    private simService: SimService,
    private modemService: ModemService,
    private vposService: VposService,
    private locationService: LocationService,
    private staffService: StaffService,
  ) {

  }


  loadFormData(automatId?): Observable<any> {
    automatId= automatId ? automatId: "";
    const call0 = this.automatBrandService.getAllBrandsNoPage()
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call1 = this.automatGroupService.getFirmAutomatGroupsForAutomatFormPopup()
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call2 = this.staffService.getFirmOperatorForAutomatFormPopup()
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call3 = this.locationService.getAllCities()
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call4 = this.simService.getFirmSimsForAutomatFormPopup(automatId)
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call5 = this.deviceService.getFirmDevicesForAutomatFormPopup(automatId)
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call6 = this.beaconService.getFirmBeaconsForAutomatFormPopup(automatId)
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call7 = this.vposService.getFirmVPossesForAutomatFormPopup(automatId)
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );
    const call8 = this.paymentService.getAutomatPaymentSystemsForAutomatFormPopup()
      .pipe(
        catchError(val => of(`error getting: ${val}`))
      );


    return forkJoin([call0, call1, call2, call3, call4, call5, call6, call7, call8]);
  }


}
