import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppSettingsRoutingModule } from './app-settings-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipesModule } from '../../_pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '../../_shared/shared.module';
import { payterComponent } from './payter/payter.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { UiSwitchModule } from 'ngx-ui-switch';

const dec_exp = [
];

@NgModule({
  imports: [
    CommonModule,
    AppSettingsRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PipesModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    SharedModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    UiSwitchModule.forRoot({
      size: 'medium',
      color: 'rgb(0, 189, 99)',
      switchColor: '#FFFFFF',
      defaultBgColor: '#9b9b9b',
    })
  ],
  declarations: [dec_exp, payterComponent],
  exports: [dec_exp],
})
export class AppSettingsModule { }
