import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {QrLoginComponent} from './qr-login/qr-login.component';
import {AuthGuard} from '../_guards/auth.guard';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {SubFirmGenerateQrComponent} from './sub-firm-generate-qr/sub-firm-generate-qr.component';

const routes: Routes = [
    {
        path: '',
        component: SubFirmGenerateQrComponent,
        canActivate: [AuthGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: 'generate-qr',
                redirectTo: 'qr/login'
            }
        }
    },
    {
        path: 'login',
        component: QrLoginComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class SubFirmQrRouting {
}
