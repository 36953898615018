<div class="modal-header">
  <h4 class="modal-title">Kart Paketindeki Kullanıcıların Bakiye İşlemleri</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <ul ngbNav #nav="ngbNav" class="nav-pills kart-paketi-nav">
      <li ngbNavItem>
        <a ngbNavLink>Tek Seferde</a>
        <ng-template ngbNavContent>
          <div class="container">
            <div class="row mb-5 justify-content-center align-items-center">
              <div class="col-md-3 text-right ">
                <strong>Yüklenecek Bakiye:</strong>
              </div>
              <div class="input-group col-md-4">
                <input [(ngModel)]="singleGroupBalance" (input)="controlSingleUpdateGroupBalance()" type="number"
                        (keydown)="validatePrice($event)" min="0"
                       class="form-control custom-input" id="singleGroupBalanceInput" placeholder="0.00">
                <div class="input-group-append">
                  <span class="input-group-text no-border metin-girisi">₺</span>
                </div>

              </div>
            </div>
            <div class="row mb-2">
              <div *ngIf="singleGroupBalanceApproval" class="col text-center">
                <strong class="text-danger">Dikkat!</strong>
                <div>
                  <label>Tüm kullanıcılara tek seferde <strong class="text-success">{{singleGroupBalance}} ₺</strong>
                    yüklenecek onaylıyor musunuz?</label>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div *ngIf="!singleGroupBalanceApproval" class="col-md-6 text-right">
                <button class="btn btn-primary" disabled> Onayla</button>
              </div>
              <div *ngIf="singleGroupBalanceApproval" class="col text-center">
                <button class="btn btn-primary" (click)="updateSingleGroupBalance()"> Onayla</button>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Periyodik Plan</a>
        <ng-template ngbNavContent>
          <div class="container">
            <!-- Periyot -->
            <div *ngIf="group?.isPeriod">
              <!-- Periyot Bilgileri -->
              <div *ngIf="!periodIsUpdate">
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Grup Adı:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{group?.name}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Yükleneceği Tarih:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{editNextStartDate(group?.nextStartDate)}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Periyot:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{getPeriodInfoText(group?.monthlyPeriod)}}</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Mail Adresi:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{splitMailAddresses(group?.mailAddress)}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Yüklenecek Bakiye:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{group?.periodBalance}}₺</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Periyot Günü:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label>Ayın {{group?.constantDay}}. Günü</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Bakiye Devret:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label *ngIf="group?.balanceTransfer"> Açık</label>
                        <label *ngIf="!group?.balanceTransfer"> Kapalı</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label><strong>Mail Gönder:</strong></label>
                      </div>
                      <div class="col-md-6">
                        <label *ngIf="group?.sendMail"> Açık</label>
                        <label *ngIf="!group?.sendMail"> Kapalı</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Periyot Bilgileri: SON -->
              <!-- Periyot Güncelleme -->
              <div *ngIf="periodIsUpdate">
                <div class="row justify-content-center">
                  <div class="col">
                    <div class="row mb-2">
                      <div class="col-md-6 text-right">
                        <label><strong>Yükleneceği Tarih:</strong></label>
                      </div>
                      <div class="col-md-3">
                        <input id="datePickerUpdate" [value]="group.nextStartDate" class="form-control" type="date"
                               (change)="getPeriod(datePickerUpdate.value, 'startdate')" #datePickerUpdate>
                      </div>
                      <div *ngIf="!controlPeriodArea('startDate')" class="col-md-1">
                        <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                             title="Lütfen ileri bir tarih seçin.">
                      </div>
                      <div *ngIf="controlPeriodArea('startDate')" class="col-md-1">
                        <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px"
                             data-toggle="tooltip" title="Onaylandı">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6 text-right">
                        <label><strong>Periyot:</strong></label>
                      </div>
                      <div class="col-md-3">
                        <select id="monthPeriodUpdate" name="everyWeekOrMonthOrYear"
                                [value]="group.monthlyPeriod"
                                (change)="getPeriod(monthPeriodUpdate.value, 'month')"
                                class="custom-select select-modal select-custom-icon h-100" #monthPeriodUpdate>
                          <option value="-1" selected disabled>Kaç ayda bir?</option>
                          <option value="1">Ayda 1 Kez</option>
                          <option value="2">2 Ayda 1 Kez</option>
                          <option value="3">3 Ayda 1 Kez</option>
                          <option value="4">4 Ayda 1 Kez</option>
                          <option value="5">5 Ayda 1 Kez</option>
                          <option value="6">6 Ayda 1 Kez</option>
                        </select>
                      </div>
                      <div *ngIf="!controlPeriodArea('monthlyPeriod')" class="col-md-1">
                        <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                             title="Boş Bırakılamaz">
                      </div>
                      <div *ngIf="controlPeriodArea('monthlyPeriod')" class="col-md-1">
                        <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px"
                             data-toggle="tooltip" title="Onaylandı">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6 text-right">
                        <label><strong>Yüklenecek Bakiye:</strong></label>
                      </div>
                      <div class="input-group col-md-3">
                        <input [(ngModel)]="group.periodBalance" type="number" class="form-control" min="0"
                               #periodUpdateBalance id="periodUpdateBalance" (keydown)="validatePrice($event)" placeholder="0.00">
                        <div class="input-group-append">
                          <span class="input-group-text metin-girisi">₺</span>
                        </div>

                      </div>
                      <div *ngIf="!controlPeriodArea('periodBalanceUpdate')" class="col-md-1">
                        <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                             title="Boş Bırakılamaz">
                      </div>
                      <div *ngIf="controlPeriodArea('periodBalanceUpdate')" class="col-md-1">
                        <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px"
                             data-toggle="tooltip" title="Onaylandı">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6 text-right">
                        <ui-switch #balanceTransferUpdate size="small"
                                   [checked]="!!group.balanceTransfer"
                                   (change)="checkedBalanceTransfer(balanceTransferUpdate.checked)"></ui-switch>
                        <span class="ml-1 align-top"
                              (click)="balanceTransferUpdate.checked = !balanceTransferUpdate.checked"
                              data-toggle="tooltip" data-placement="top"
                              title="Bu alanı işaretlerseniz ay sonunda kalan bakiyeler hesaplardan silinmeyecektir.">
                            Bakiye Devret
                          </span>
                      </div>
                      <div class="col-md-3">
                        <ui-switch #sendMailUpdate size="small"
                                   [checked]="!!group.sendMail"
                                   (change)="checkedSendMail(sendMailUpdate.checked)"></ui-switch>
                        <span class="ml-1 align-top" (click)="sendMailUpdate.checked = !sendMailUpdate.checked"
                              data-toggle="tooltip" data-placement="top"
                              title="Bu alanı işaretlerseniz ay sonunda bakiye sıfırlanırsa size son durum bilgisi mail olarak gönderilir">
                          Mail Gönder
                        </span>
                      </div>
                    </div>
                    <div *ngIf="sendMailUpdate.checked" class="row">
                      <div class="col-md-5 text-right">
                        <label><strong>Mail Adresi:</strong></label>
                      </div>
                      <div class="col-md-4">
                          <textarea [(ngModel)]="group.mailAddress" type="email" #name="ngModel"
                                    class="metin form-control" placeholder="Mail Adresi Giriniz."></textarea>
                      </div>
                    </div>
                    <div *ngIf="sendMailUpdate.checked" class="row mt-2">
                      <div class="col text-center text-danger">
                        <label><strong>*Birden fazla mail adresi girmek için noktalı virgül (;) ile
                          ayırın. </strong></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Periyot Güncelleme: END -->
              <div class="row mt-4 justify-content-end">
                <button *ngIf="!periodIsUpdate" class="btn btn-outline-primary mr-4"
                        (click)="openPeriodUpdateButton()">
                  Güncellemeyi Aç
                </button>
                <button *ngIf="periodIsUpdate && !controlPeriodArea('areaControl')" class="btn btn-primary mr-4"
                        disabled>
                  Güncelle
                </button>
                <button *ngIf="periodIsUpdate && controlPeriodArea('areaControl')" class="btn btn-primary mr-4"
                        (click)="updatePeriod()">
                  Güncelle
                </button>
                <button *ngIf="!periodIsUpdate" class="btn btn-danger"
                        (click)="deletePeriod(group?._id)">
                  Planı Sil
                </button>
                <button *ngIf="periodIsUpdate" class="btn btn-danger" (click)="cancelPeriodUpdate()">
                  İptal
                </button>
              </div>
            </div>
            <!-- Periyot Oluştur -->
            <div *ngIf="!group?.isPeriod">
              <div class="row mt-3 mb-3 justify-content-center">
                <div class="col-md-3 text-right">
                  <strong>Başlangıç Tarihi:</strong>
                </div>
                <div class="col-md-3">
                  <input id="datePicker" class="form-control" type="date"
                         (change)="getPeriod(datePicker.value, 'startdate')" #datePicker>
                </div>
                <div *ngIf="!controlPeriodArea('startDate')" class="col-md-1">
                  <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Lütfen ileri bir tarih seçin.">
                </div>
                <div *ngIf="controlPeriodArea('startDate')" class="col-md-1">
                  <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Onaylandı">
                </div>
              </div>
              <div class="row mb-3 justify-content-center">
                <div class="col-md-3 text-right">
                  <strong>Periyot Seçimi:</strong>
                </div>
                <div class="col-md-3">
                  <select id="monthPeriod" name="everyWeekOrMonthOrYear"
                          (change)="getPeriod(monthPeriod.value, 'month')"
                          class="custom-select select-modal select-custom-icon h-100" #monthPeriod>
                    <option value="-1" selected disabled>Kaç ayda bir?</option>
                    <option value="1">Ayda 1 Kez</option>
                    <option value="2">2 Ayda 1 Kez</option>
                    <option value="3">3 Ayda 1 Kez</option>
                    <option value="4">4 Ayda 1 Kez</option>
                    <option value="5">5 Ayda 1 Kez</option>
                    <option value="6">6 Ayda 1 Kez</option>
                  </select>
                </div>
                <div *ngIf="!controlPeriodArea('monthlyPeriod')" class="col-md-1">
                  <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Boş Bırakılamaz">
                </div>
                <div *ngIf="controlPeriodArea('monthlyPeriod')" class="col-md-1">
                  <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Onaylandı">
                </div>
              </div>
              <div class="row mb-2 justify-content-center">
                <div class="col-md-3 text-right">
                  <strong>Yüklenecek Bakiye:</strong>
                </div>
                <div class="input-group col-md-3">
                  <input [ngModel]="groupBalance" type="number" class="form-control" #groupBalanceInput
                         id="groupBalanceInput" placeholder="0.00" (keydown)="validatePrice($event)" min="0">
                  <div class="input-group-append">
                    <span class="input-group-text metin-girisi">₺</span>
                  </div>

                </div>
                <div *ngIf="!controlPeriodArea('periodBalance')" class="col-md-1">
                  <img src="../../assets/img/uyari.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Boş Bırakılamaz">
                </div>
                <div *ngIf="controlPeriodArea('periodBalance')" class="col-md-1">
                  <img src="../../assets/img/aktif-otomat.svg" style="width: auto; height: 20px" data-toggle="tooltip"
                       title="Onaylandı">
                </div>
              </div>
              <div class="row mb-2 justify-content-center">
                <div class="col-md-6 text-right">
                  <ui-switch #balanceTransfer size="small"
                             [checked]="!!group.balanceTransfer"
                             (change)="checkedBalanceTransfer(balanceTransfer.checked)"></ui-switch>
                  <span class="ml-1 align-top" (click)="balanceTransfer.checked = !balanceTransfer.checked"
                        data-toggle="tooltip" data-placement="top"
                        title="Bu alanı işaretlerseniz ay sonunda kalan bakiyeler hesaplardan silinmeyecektir.">
                      Bakiye Devret
                    </span>
                </div>
                <div class="col-md-6">
                  <ui-switch #sendMail size="small"
                             (change)="checkedSendMail(sendMail.checked)"></ui-switch>
                  <span class="ml-1 align-top" (click)="sendMail.checked = !sendMail.checked"
                        data-toggle="tooltip" data-placement="top"
                        title="Bu alanı işaretlerseniz ay sonunda bakiye sıfırlanırsa size son durum bilgisi mail olarak gönderilir">
                      Mail Gönder
                    </span>
                </div>
              </div>
              <div *ngIf="sendMail.checked" class="row mb-2 justify-content-center">
                <div class="col-md-2 text-right">
                  <label><strong>Mail Adresi:</strong></label>
                </div>
                <div class="col-md-4">
                    <textarea [(ngModel)]="firmUserMailAddress" type="email" #name="ngModel" class="metin form-control"
                              placeholder="Mail adresi giriniz."></textarea>
                </div>
              </div>
              <div *ngIf="sendMail.checked" class="row mt-2">
                <div class="col text-center text-danger">
                  <label><strong>*Birden fazla mail adresi girmek için noktalı virgül (;) ile ayırın.
                    Herhangi bir boşluk veya yazım yanlışı olmamasına dikkat edin.
                    Okunabilirliği arttırmak için noktalı virgül (;)'den sonra 'Enter' tuşuna
                    basabilirsiniz. </strong></label>
                </div>
              </div>
              <div *ngIf="controlPeriodArea('areaControl')" class="row mb-2">
                <div class="col text-center">
                  <strong>Dikkat!</strong>
                </div>
              </div>
              <div class="row mb-2">
                <div *ngIf="controlPeriodArea('areaControl')" class="col text-center">
                  <label>{{editNextStartDate(datePicker.value)}} tarihinden itibaren {{monthlyPeriodInfoText}},
                    ayın {{constantDay}}. günü kart paketindeki tüm kullanıcılara {{groupBalanceInput.value}}₺
                    yüklenecek. Bu planı oluşturmak ister misiniz?</label>
                </div>
              </div>
              <div *ngIf="!controlPeriodArea('areaControl')" class="row text-center">
                <div class="col">
                  <button class="btn btn-primary" disabled> Oluştur</button>
                </div>
              </div>
              <div *ngIf="controlPeriodArea('areaControl')" class="row text-center">
                <div class="col">
                  <button class="btn btn-primary"
                          (click)="updateGroupBalance()">
                    Oluştur
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <hr>
    <div [ngbNavOutlet]="nav"></div>

  </div>
</div>
