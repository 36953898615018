export class ProductCategory {
  constructor() {
    this._id = '';
  }

  createdAt: string;
  name: string;
  status: number;
  updatedAt: string;
  _id: string;
}
