import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../../_guards/auth.guard';
import {MethodPaymentComponent} from './method-payment/method-payment.component';
import {OrderPaymentComponent} from './order-payment/order-payment.component';
import {SystemPaymentComponent} from './system-payment/system-payment.component';
import {TemplatePaymentComponent} from './template-payment/template-payment.component';
import {NgxPermissionsGuard} from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'method',
    pathMatch: 'full'
  },
  {
    path: 'method',
    component: MethodPaymentComponent,
    canActivate: [AuthGuard],
    data: {
      settings: true,
      permissions: {
        only: 'settings-payment-method',
        redirectTo: '/dashboard'
      }
    },

  },
  {
    path: 'orders',
    component: OrderPaymentComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      settings: true,
      permissions: {
        only: 'settings-payment-orders',
        redirectTo: '/dashboard'
      }
    },
  },
  {
    path: 'systems',
    component: SystemPaymentComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      settings: true,
      permissions: {
        only: 'settings-payment-systems',
        redirectTo: '/dashboard'
      }
    },
  },
  {
    path: 'templates',
    component: TemplatePaymentComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      settings: true,
      permissions: {
        only: 'settings-payment-templates',
        redirectTo: '/dashboard'
      }
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PaymentSettingsRoutingModule {
}

