<div class="container-fluid">
  <div class="row">
    <div #mapWrap class="map" id="wrapper">
      <button class="btn btn-sm btn-outline-light mapSwitch"
              (click)="mapWrap.classList.contains('close') ?  mapWrap.classList.remove('close') : mapWrap.classList.add('close')">
        x
      </button>
      <google-map #gmapContainer 
                  height="100%"
                  width="100%"
                  [center]="center"
                  [zoom]="zoom"
                  [options]="mapStyles"
      >
        <map-marker #marker
                    *ngFor="let a of automats; let i = index"
                    [position]="{lat:a.location.coordinates[0],lng:a.location.coordinates[1]}"
                    [options]="{draggable:false, icon:getMarkerImage(a.status)}"
                    (mapClick)="openInfoWindow(marker,a,i)">

        </map-marker>
        <map-info-window class="map-window">
          <ng-template  [ngIf]="selectedMarker">
            <div class="container map-window">
              <div class="row">
                <ng-template [ngIf]="selectedMarker.image !== null && selectedMarker?.image !== ''"
                             [ngIfElse]="checkModelImage">
                  <div class="col map-image">
                    <img alt="Otomat resmi"
                         src="{{selectedMarker?.image | image: AutomatImagePath}}"
                         style="height:150px"/>
                  </div>
                </ng-template>
                <ng-template #checkModelImage>
                  <div class="col map-image"
                       *ngIf="selectedMarker?.automatModel?.image !== null && selectedMarker?.automatModel?.image !== ''; else noImage">
                    <img alt="Otomat resmi"
                         src="{{selectedMarker?.automatModel?.image | image: AutomatImagePath}}"
                         style="height:150px"/>
                  </div>
                  <ng-template #noImage>
                    <div class="col map-image">
                      <img alt="Otomat resmi" src="../../assets/img/vending-machine.png" style="height:150px"/>
                    </div>
                  </ng-template>
                </ng-template>
                <div class="col-6 d-flex">
                  <div class="container my-auto">
                    <div class="row">
                      <strong>{{selectedMarker?.name}}</strong>
                    </div>

                    <div class="row">
                      <p>{{selectedMarker?.description}}</p>
                    </div>

                    <div *ngIf="!selectedMarker?.isHot" class="row">
                        <span style="color: #26b999;font-size: 15px;font-weight: 700;">
                          {{automatStats[selectedMarkerIndex].stock}}
                          Ürün / {{ automatStats[selectedMarkerIndex].price | number | commaDotSwapper: '1.0-2'}} ₺
                        </span>
                    </div>
                    <div *ngIf="selectedMarker?.isHot" class="row">
                        <span style="color: #26b999;font-size: 15px;font-weight: 700;">
                          Sıcak Otomat
                        </span>
                    </div>

                    <div class="row my-2">
                      <a
                        (click)="goToAutomatDetail(selectedMarker?._id)"
                        class="btn btn-sm mx-auto marker-bubble-button">Detaylar</a>
                    </div>

                    <div class="d-flex">
                      <div class="row mt-2 justify-content-end">
                        <i [ngClass]="selectedMarker?.isCard ? 'active-icon' : 'disable-icon'"
                           class="far fa-credit-card mx-1"></i>
                        <i [ngClass]="selectedMarker?.isTelemetry ? 'active-icon' : 'disable-icon'"
                           class="fa fa-wifi mx-1"></i>
                        <i [ngClass]="selectedMarker?.isMobil ? 'active-icon' : 'disable-icon'"
                           class="fa fa-mobile mx-1"></i>
                        <i [ngClass]="selectedMarker?.isCoin ? 'active-icon' : 'disable-icon'"
                           class="fa fa-money-bill-alt mx-1"></i>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </map-info-window>

      </google-map>
    </div>
  </div>
</div>

<div class="container" [ngClass]="automats.length != 0 ? 'mt-3': 'mt-5'">
  <div class="row">

    <div class="col-6 col-lg-3 my-1">
      <select [(ngModel)]="selectedFilter" class="custom-select component-background h-100" id="filtre-sec">
        <option value="0">{{'VENDINGS.SELECT_FILTER' | translate}}</option>
        <option value="1">{{'VENDINGS.LOCATION' | translate}}</option>
        <option value="2">{{'VENDINGS.TYPE' | translate}}</option>
        <option value="3">{{'VENDINGS.NAME' | translate}}</option>
      </select>
    </div>

    <div class="col-6 col-lg-5 my-1">
      <div class="container h-100">
        <div class="row component-background h-100">
          <img class="my-auto mx-3" src="../../assets/img/aramaicon.svg" alt="arama" height="16">
          <input type="text" id="search" class="col" name="search"
                 (input)="searchAutomat($event)"
                 [placeholder]="(automats.length == 0 ? 'VENDINGS.YOU_MUST_ADD_VENDING_TO_MAKE_A_SEARCH' : 'VENDINGS.FIND_VENDING') | translate"
                 [disabled]="searchDisabled">
        </div>
      </div>
    </div>

    <div class="col my-1">
      <div class="row">
        <div class="col-4 col-md">

          <div class="row justify-content-center h-100">
            <button class="btn btnwidth" (click)="changeListStyle(1)">
              <div class="menuicon reversed" [ngClass]="{'selected': listStyle === 1}"></div>
            </button>
            <button class="btn btnwidth" (click)="changeListStyle(2)">
              <div class="menuicon" [ngClass]="{'selected': listStyle === 2}"></div>
            </button>
          </div>

        </div>
        <div class="col-8">
          <button *ngxPermissionsOnly="['add-automat']" class="btn btn-success add-automat"
                  (click)="newAutomatModal()">
            <span class="fa fa-plus mr-3"></span> {{'VENDINGS.ADD_NEW_VENDING' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Boş liste uyarı -->
  <div *ngIf="!automatsFlag" class="row justify-content-center">
    <div class="col chartdiv d-flex flex-column">
      <div class="m-auto">
        <div class="loader fadeIn" style="font-size: 60%">{{'VENDINGS.LOADING' | translate}}...</div>
      </div>
    </div>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>

<div class="container my-1">

   <!-- Boş liste uyarı -->
  <div *ngIf="automats.length == 0 && automatsFlag" class="row justify-content-center">
    <h1 class="h3 mt-5" style="color: gray">{{'VENDINGS.NO_VENDINGS_HAVE_BEE_NUILT_YET' | translate}}</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
  <app-card-view-automats *ngIf="listStyle === 1 && automats.length > 0"
                          [automatList]="automats"
                          [automatListFiltered]="automatsFiltered"
                          [automatListStats]="automatStats"></app-card-view-automats>

  <app-list-view-automats *ngIf="listStyle === 2 && automats.length > 0"
                          [automatList]="automats"
                          [automatListFiltered]="automatsFiltered"
                          [automatListStats]="automatStats"
                          (submit)="handleListView($event)"></app-list-view-automats>

</div>

<ng-template #InfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{'VENDINGS.VENDING_MOVEMENTS' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-notifications-automat [automat]="selected.automat"
                               [stats]="selected.stats"
                               (submit)="handleInfoModal($event)"
    ></app-notifications-automat>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">{{'VENDINGS.CLOSE' | translate}}</button>
  </div>
</ng-template>

<!-- <ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType
>
</ngx-spinner> -->
