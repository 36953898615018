import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../environments/environment';
import {Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {SwalHelper} from '../_helpers/swal.helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {

  swalOptions = new SwalHelper();
  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor;
  public loadingSpinnerSize;
  public loadingSpinnerColor;
  public loadingSpinnerType;
  public loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)
  urlsub: Subscription;
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  public links = {
    account: {
      info: false,
      firm: false,
      card: false
    },
    payment: {
      method: false,
      mobile: false,
      templates: false,
      systems: false,
      orders: false,
    },
    apps: {
      payter: false
    }
  };
  public innerWidth: any;
  passwordForm: FormGroup;
  submitted = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private loadingSnipper: NgxSpinnerService) {
    this.loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
    this.loadingSpinnerSize = environment.loadingSpinnerSize;
    this.loadingSpinnerColor = environment.loadingSpinnerColor;
    this.loadingSpinnerType = environment.loadingSpinnerType;
    this.loadingSpinnerText = environment.loadingSpinnerText;

    this.links = {
      account: {
        info: router.url.includes('user-info'),
        firm: router.url.includes('firm-info'),
        card: router.url.includes('storage-card')
      },
      payment: {
        method: router.url.includes('method'),
        mobile: router.url.includes('mobile'),
        templates: router.url.includes('templates'),
        systems: router.url.includes('systems'),
        orders: router.url.includes('orders'),
      },
      apps: {
        payter: router.url.includes('payter'),
      }
    };

    this.urlsub =
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.links = {
            account: {
              info: event.url.includes('user-info'),
              firm: event.url.includes('firm-info'),
              card: router.url.includes('storage-card')
            },
            payment: {
              method: event.url.includes('method'),
              mobile: event.url.includes('mobile'),
              templates: event.url.includes('templates'),
              systems: event.url.includes('systems'),
              orders: event.url.includes('orders'),
            },
            apps: {
              payter: router.url.includes('payter'),
            }
          };
        }
      });
  }

  ngOnInit() {
    this.closeSideNavIfSmallScreen();

      if (this.currentUser && this.currentUser.user.subFirmUser) {
          this.router.navigate(['/qr']);
          return;
      }

    this.passwordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]],
      password: ['', [
        Validators.required,
        Validators.pattern(('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})')),
        Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
  }

  ngOnDestroy() {
    this.urlsub.unsubscribe();
  }


  closeSideNavIfSmallScreen() {
    if (this.innerWidth < 768 && $('#sidenavId').is(':visible')) {
      $('#sidenavId').collapse('hide');
    }
  }

  setVisibilitySideNav() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768 && !$('#sidenavId').is(':visible')) {
      $('#sidenavId').collapse('show');
    }
    if (this.innerWidth < 768 && $('#sidenavId').is(':visible')) {
      $('#sidenavId').collapse('hide');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 768 && !$('#sidenavId').is(':visible')) {
      $('#sidenavId').collapse('show');
    }
  }

// convenience getter for easy access to form fields
  get f() { return this.passwordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
        return;
    }
    this.authenticationService.changePasswordUsingOld(this.passwordForm.value.oldpassword,this.passwordForm.value.password).subscribe(res => {
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Şifre başarıyla değiştirildi!'
      });
      this.loadingSnipper.hide();
      $('#editPassword').modal('hide');
    }, error => {
      this.loadingSnipper.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Şifre değiştirilemedi!'
      });
    })
  }

  onReset() {
    this.submitted = false;
    this.passwordForm.reset();
  }

  sendMail() {
    this.loadingSnipper.show();
    this.authenticationService.sendChangePasswordToken().subscribe(res => {
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Şifrenizi e-posta adresinize gönderilen bağlantıdan değiştirebilirsiniz!'
      });
      this.loadingSnipper.hide();
    }, error => {
      this.loadingSnipper.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Şifre değiştirme e-postası gönderilemedi!'
      });
    });
  }
}


export enum TimeZone {
  ALL,
  YEAR,
  MONTH,
  WEEK
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
