<div class="container-fluid top-bg pt-4 pb-3">
  <div class="row">
    <div class="col-md-2 col-lg-2 col-xl-2 align-self-center">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" #dropdownFilter type="button" id="dropdownFilter"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Filtre Uygula
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownFilter">
          <form>
            <div class="container p-4">
              <div class="row">
                <div class="col">
                  <select name="district" class="custom-select select-product-modal select-custom-icon">
                    <option value="">Bölge</option>
                  </select>
                  <input class="form-control no-border text-enter mt-4" type="text" name="productName"
                         placeholder="Ürün Adı / ID">
                  <input class="form-control no-border text-enter mt-4" type="number" min="0" name="productPrice"
                          oninput="validity.valid||(value='');" placeholder="Ürün Fiyatı">
                  <select name="paymentType" class="custom-select select-product-modal select-custom-icon mt-4">
                    <option value="-1">Ödeme Yöntemi</option>
                    <option value="1">Kredi Kartı</option>
                    <option value="2">Nakit</option>
                  </select>
                </div>
                <!-- Divider -->
                <div class="col">
                  <select name="group" class="custom-select select-product-modal select-custom-icon">
                    <option value="">Otomat Grubu</option>
                  </select>
                  <select name="nameID" class="custom-select select-product-modal select-custom-icon mt-4">
                    <option value="">Otomat Adı / ID</option>
                  </select>
                  <input class="form-control no-border text-enter mt-4" type="number" min="0" name="amount"
                          oninput="validity.valid||(value='');" placeholder="Toplam Fiyat">
                  <select name="resultType" class="custom-select select-product-modal select-custom-icon mt-4">
                    <option value="-1">Sonuç Türü</option>
                    <option value="1">Tamamlandı</option>
                    <option value="2">Devam Ediyor</option>
                    <option value="3">İptal</option>
                  </select>
                  <button class="btn btn-success mt-5" style="width: 100%">FİLTRE UYGULA</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-7 col-lg-7 col-xl-7 text-center">
      <span class="title">{{ groupData.name + ' Kart Grubu' }}</span>
    </div>

    <div class="col-md-2 col-lg-2 col-xl-2">
      <div class="container">
        <div class="row justify-content-end text-center">
          <span class="refresh-button" (click)="getOfflineUsers()">
            <i class="fas fa-sync-alt"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xl-1">
      <div class="container">
        <div class="row justify-content-end text-center">
          <button (click)="downloadExcel()" [hidden]="!isDownloadReady" class="btn btn-download btn-sm btn-block" type="button"><i
            class="fa fa-download"></i> XLS indir
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table" id="main-table">
    <thead>
    <tr>
      <th *ngxPermissionsOnly="['offline-cards-edit']" scope="col" class="table-header">
        <span *ngIf="makeActiveDeleteButton" class="delete-button" (click)="deleteOfflineUsers()">
            <i class="fas fa-trash" style="width: 20px"></i>
          </span>
        <span *ngIf="!makeActiveDeleteButton" class="new-offline-user-button"
              (click)="openAddCardModal()">
            <i class="fas fa-user-plus" style="width: 20px;"></i>
          </span>
      </th>
      <th scope="col" class="table-header">Ad Soyad</th>
      <th scope="col" class="table-header">Sicil No</th>
      <th scope="col" class="table-header">Görev</th>
      <th scope="col" class="table-header">Birim</th>
      <th scope="col" class="table-header">Kart UID Hex</th>
      <th scope="col" class="table-header">Kart UID Dec</th>
      <th scope="col" class="table-header">Oluşturma Zamanı</th>
      <th scope="col" class="table-header">
        Firma Bakiyesi
        <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1 mt-1 info" placement="top" container="body"
                        ngbTooltip="Firmanın Yüklediği Bakiye.">
                info
        </mat-icon>
      </th>
      <th scope="col" class="table-header">
        Kullanıcı Bakiyesi
        <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1 mt-2 info" placement="top" container="body"
                        ngbTooltip="Kullanıcının Yüklediği Bakiye.">
                info
        </mat-icon>
      </th>
      <th scope="col" class="table-header">Durum</th>
      <th *ngxPermissionsOnly="['offline-cards-edit']" scope="col" class="table-header">Kart Hareketleri</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let card of offlineUsers; let i = index"
        [ngClass]="signCheckedUser(card._id) == true ? 'checked-offline-user':'' ">
      <th *ngxPermissionsOnly="['offline-cards-edit']" scope="row">
        <input id="offlineUsersCheckbox" type="checkbox" aria-label="" (change)="checkedUser($event, card._id)">
      </th>
      <td style="text-align: left">{{ card.name }}</td>
      <td>{{card.sicilNo }}</td>
      <td>{{card.gorev }}</td>
      <td>{{card.birim }}</td>
      <td>{{ card.cardId }}</td>
      <td>{{ card.cardIdDec }}</td>
      <td>{{card.createdAt | date: 'dd.MM.yyyy HH:mm'}}</td>
      <td>{{ card.firmBalance | number | commaDotSwapper }} ₺</td>
      <td class="font-weight-bold">{{ card.balance | number | commaDotSwapper }} ₺</td>
      <td>
        <span [ngClass]="card.status == 0 ? 'state-passive' : 'state-active'">
          {{ card.status == 1 ? 'Aktif' : 'Pasif' }}
        </span>
      </td>
      <td *ngxPermissionsOnly="['offline-cards-edit']">
        <button class="btn-detail" style="cursor: pointer"
                (click)="showCardModal(card, i)">İncele
        </button>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<ng-template #addCardModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Kart Paketine Kullanıcı Ekle</h4>
    <button class="btn btn-success newbtn"
        data-toggle="modal" data-backdrop="true" data-target="#videoModal">
        <i class="fas fa-video mr-4"></i> Bu işlem Nasıl yapılır ?
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <!-- Tab başlıkları -->
      <div class="row d-flex">
        <ul class="nav nav-pills modal-tab-bg-offlinecards mx-auto" id="kullanici-ekle-modal" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="toplu-tab" data-toggle="pill" href="#toplu" role="tab"
               aria-controls="urun-ekle" aria-selected="true">
               Toplu Kart Paketi
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="tek-tab" data-toggle="pill" href="#tek" role="tab"
               aria-controls="kampanya-olustur" aria-selected="false">
              Tek
            </a>
          </li>
        </ul>
      </div>
      <!-- Tab başlıkları : Son -->

      <!-- Tab içeriği -->
      <div class="tab-content" id="urun-degistir-modal-tablar">
        <!-- Çoklu kullanıcı oluştur -->
        <div class="tab-pane fade show active" id="toplu" role="tabpanel" aria-labelledby="toplu-tab">

          <div class="container mt-3">
            <div class="row mb-4 justify-content-center">
              <form class="w-100" #myForm="ngForm">
                <div class="d-flex my-5">
                  <div class="container inputField" (click)="uploader.click()" appDragDrop (fileDropped)="onFileDropped($event)">
                    <div class="row inputFieldRow">
                      <input #pickXMLFile type="file"
                                                (change)="onFileChange($event)"
                                                #uploader
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                hidden/>
                      <div class="col">
                        <img src="../../../assets/img/file.svg" alt="excel">
                      </div>
                      <div class="col mt-auto">
                        <h1 class="descriptipn">Excel Dosyası seçin</h1>
                        <p class="descriptipnAlert">{{fileName ? fileName:'Seçilmiş Dosya yok'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <button class="btn btn-outline-dark mx-1 px-4 text-center" (click)="downloadSampleExcel()">
                      <i class="fas fa-download mr-4"></i> Örnek XLS Dosyası
                    </button>
                  </div>
                  <div class="mr-1">
                    <button #clear (click)="closeModal(myForm)" class="btn btn-outline-dark px-3 text-center">İptal</button>
                  </div>
                  <div class="ml-1">
                    <button class="btn btn-dark mx-1 px-4 text-right" (click)="sendToDatabase()">
                      Kaydet
                    </button>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
        <!-- Tek kullanıcı oluştur -->
        <div class="tab-pane fade" id="tek" role="tabpanel" aria-labelledby="tek-tab">

          <div class="container mt-5">

            <div class="row mt-5 mb-4">
              <div class="col-md-8 offset-2">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.name" #name="ngModel" type="text" name="name"
                       placeholder="Kullanıcı Adı">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.sicilNo" #name="ngModel" type="text" name="sicilNo"
                       placeholder="Sicil Numarası">
              </div>
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.cardId" #name="ngModel" type="text" name="cardId"
                       placeholder="Kart UID (16 tabanlı)" (ngModelChange)="InputIdChange($event,true)">
              </div>
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.cardIdDec" #name="ngModel" type="text" name="cardIdDec"
                       placeholder="Kart UID (10 tabanlı)" (ngModelChange)="InputIdChange($event,false)">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.gorev" #name="ngModel" type="text" name="gorev"
                       placeholder="Görev">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.birim" #name="ngModel" type="text" name="birim"
                       placeholder="Birim">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.firmBalance" #name="ngModel" type="number" name="firmBalance" min="0"
                       (keydown)="validatePrice($event)"
                       placeholder="Bakiye">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation1" #name="ngModel" type="text" name="explanation1"
                       placeholder="Açıklama 1">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation2" #name="ngModel" type="text" name="explanation2"
                       placeholder="Açıklama 2">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       [(ngModel)]="offlineUser.explanation3" #name="ngModel" type="text" name="explanation3"
                       placeholder="Açıklama 3">
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="createOfflineUser()">Kaydet</button>
            <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Kapat</button>
          </div>

        </div>
      </div>
      <!-- Tab içeriği : Son -->
    </div>
  </div>

</ng-template>

<ng-template #mailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">EXCEL'i Mail Olarak Gönder</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-3">
      <div class="col">
        <input class="form-control no-border metin-girisi text-center" [(ngModel)]="excelEmailAddress"
               #email="ngModel" type="email" name="excelEmailAddress" placeholder="Email Adres">
        <span *ngIf="!emailValid" style="color: red;"> * Email adresi geçersiz! </span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <p class="text-danger" style="font-size:11px">* Birden fazla mail adresi girmek için noktalı virgül (;)
          ile ayırın.
          <br>
          Örn: telemetry1@telemetry.com.tr;telemetry2@telemetry.com.tr
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary modal-buton mb-2" (click)="sendEmailOfflineUserTranscation()">Email Gönder
    </button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Kapat</button>
  </div>
</ng-template>


<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType
>
</ngx-spinner>
<!-- Yükleme spinner : SON -->

<div id="videoModal" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
        <iframe width="900" height="415"
                src="https://www.youtube.com/embed/Awtpq4A8ZdI?autoplay=0&loop=0&controls=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
    </div>
  </div>
</div>
