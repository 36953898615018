import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AutomatService } from '../_services/automat.service';
import { MarkerAutomat } from '../_models/markerAutomat';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalHelper } from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import { AutomatComponent } from '../automat/automat.component';
import { MapService } from '../_services/map.service';
import * as moment from 'moment';


@Component({
  selector: 'app-automats',
  templateUrl: './automats.component.html',
  styleUrls: ['./automats.component.css']
})
export class AutomatsComponent implements OnInit, OnDestroy {

  swalOptions = new SwalHelper();

  @ViewChild('InfoModal') private infoModal: NgbModal;
  @ViewChild('EditAutomatModal') private editAutomatModal: NgbModal;

  listStyle = 1;
  repeater: any;
  AutomatImagePath: string;
  selectedFilter = '0';
  searchDisabled: boolean;
  /*loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  loadingSpinnerSize = environment.loadingSpinnerSize;
  loadingSpinnerColor = environment.loadingSpinnerColor;
  loadingSpinnerType = environment.loadingSpinnerType;
  loadingSpinnerText = environment.loadingSpinnerText;*/
  automatsFlag: boolean = false;
  automats = Array<MarkerAutomat>();
  newAutomats = Array<MarkerAutomat>();

  automatStats = Array<{ price: number, stock: number }>();
  automatsFiltered = Array<MarkerAutomat>();
  selected: { automat: MarkerAutomat, stats: { price: number, stock: number } }
    = { automat: null, stats: null };
  editAutomat: string;

  // MAP
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild('gmapContainer', { static: false }) gmap: GoogleMap;

  zoom = 8;
  center: any = { lat: 39.334943, lng: 35.177772 };
  selectedMarker: MarkerAutomat;
  selectedMarkerIndex: number;
  mapStyles:any = environment.MapStyle;

  // END : Google maps variables

  constructor(private automatService: AutomatService,
    private router: Router,
    private modalService: NgbModal,
    private mapService: MapService,
    //private spinner: NgxSpinnerService
  ) {
    const currentTime = moment().hour();
    if(currentTime>19 || currentTime<7){
      this.mapStyles =  environment.MapStyleNight;
    }
    this.AutomatImagePath = environment.AutomatImagePath;
  }

  ngOnInit() {
    this.repeater = setInterval(() => this.checkAutomatStatusAndUpdate(), 60000);
    this.getFirmAutomats();
  }

  ngOnDestroy() {
    clearInterval(this.repeater);
  }

  changeListStyle(i) {
    this.listStyle = i;
  }

  handleListView(event: { success: boolean, message: string, data: any }) {
    if (event.data) {
      this.selected.automat = event.data.automat;
      this.selected.stats = this.automatStats[event.data.index];
      this.openModal(this.infoModal);
      return;
    }

    if (event.success) {
      Swal.fire({
        ...this.swalOptions.success,
        text: event.message
      });
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: event.message
      });
    }
  }

  handleInfoModal(event: { success: boolean, message: string, data: any }) {
    this.modalService.dismissAll();
    if (event.success) {
      this.router.navigate([event.message, event.data]);
    }
  }

  getFirmAutomats() {
    //this.spinner.show();
    this.automatService.getFirmAutomats().subscribe(res => {
      //this.spinner.hide();
      this.automatsFlag = true;
      if (res.success) {
        this.automats = res.automats;
        this.getAutomatStats();
        this.automatsFiltered = this.automats;
        this.searchDisabled = this.automats.length === 0;
        const [bounds, center] = this.mapService.getBounds(this.automats);
        this.center = bounds.getCenter();
        this.gmap.fitBounds(bounds);
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Otomatlar sunucudan alınamadı!'
        });
      }
    }, error => {
      //this.spinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Otomatlar sunucudan alınamadı!'
      });
      this.searchDisabled = false;
    });
  }

  getMarkerImage(status: number): string {
    switch (status) {
      case 1:
        return '../../assets/img/aktifMarker.png';
      case 2:
        return '../../assets/img/guncelleMarker.png';
      case 8:
      case 0:
        return '../../assets/img/arizaliMarker.png';
    }
  }


  getAutomatStats() {
    // this.automats.forEach((automat, index) => {
    //   let price = 0;
    //   let stock = 0;
    //   if (automat) {
    //     // for (const product of automat.automatproduct) {
    //     //   price += product.price * product.stock;
    //     //   stock += product.stock;
    //     // }
    //     // this.automatStats[index] = {
    //     //   price: price,
    //     //   stock: stock
    //     // };
    //   }

    // });
  }

  goToAutomatDetail(automatId: string) {
    this.automatService.changeAutomatId(automatId);
    this.router.navigate(['/automat-detail', automatId]);
  }

  newAutomatModal() {
    this.editAutomat = ' ';
    this.openAutomatModal();
  }

  searchAutomat(event) {

    const filter = event.target.value.toLowerCase().trim();

    this.automatsFiltered = this.automats.filter((automat) => {
      if (this.selectedFilter === '0' || this.selectedFilter === '3') { //ad
        return automat.name.toLocaleLowerCase().trim().search(filter) > -1;
      } else if (this.selectedFilter === '1') { //lokasyon
        return automat.locationText.toLocaleLowerCase().trim().search(filter) > -1;
      } else if (this.selectedFilter === '2') { //tür
        return automat.automatModel.name.toLocaleLowerCase().trim().search(filter) > -1;
      }
    });
  }

  openInfoWindow(marker: MapMarker, a: MarkerAutomat, index: number) {
    this.selectedMarker = a;
    this.selectedMarkerIndex = index;
    this.infoWindow.open(marker);
  }

  openModal(content) {
    this.modalService.open(content).result.then((res) => {
    }, (reason) => {
    });
  }

  openAutomatModal() {
    const modalRef = this.modalService.open(AutomatComponent, { centered: true, scrollable: true });
    modalRef.componentInstance.selectedAutomat = this.editAutomat;
    modalRef.componentInstance.submit.subscribe((res) => {
      if (res.success) {
        this.getFirmAutomats();
      }
    });
  }

  checkAutomatStatusAndUpdate() {
    this.automatService.getFirmAutomats().subscribe(res => {
      if (res.success) {
        this.automats = this.newAutomats = res.automats;
        for (let automat of this.newAutomats) {
          const indFil = this.automatsFiltered.findIndex(item => item._id == automat._id)
          if (indFil > -1) {
            this.automatsFiltered[indFil].status = automat.status;
            this.automatsFiltered[indFil].lastInformed = automat.lastInformed;
            this.automatsFiltered[indFil].isRecentlyInformed = automat.isRecentlyInformed;
            this.automatsFiltered[indFil].isCard = automat.isCard;
            this.automatsFiltered[indFil].isCoin = automat.isCoin;
            this.automatsFiltered[indFil].isHot = automat.isHot;
            this.automatsFiltered[indFil].isMobil = automat.isMobil;
            this.automatsFiltered[indFil].isOpen = automat.isOpen;
            this.automatsFiltered[indFil].isPreSalesCard = automat.isPreSalesCard;
            this.automatsFiltered[indFil].isMetropolCardQR = automat.isMetropolCardQR;
            this.automatsFiltered[indFil].isTelemetry = automat.isTelemetry;
            this.automatsFiltered[indFil].description = automat.description;
          }
        }
      }
    });
  }


}
