// USA
export const locale = {
  lang: 'en',
  data: {
    HEADER: {
      DASHBOARD: '',
      VENDINGS: '',
      VENDING_DROPDOWN: {
        VENDINGS: '',
        VENDING_GROUPS: '',
        NEW_VENDING: '',
        QR_CODES: 'QR CODES'
      },
      CARDS: '',
      CARDS_DROPDOWN: {
        CARD_PACKAGES: ''
      },
      SALES: '',
      SALES_DROPDOWN: {
        SALES: '',
        COLLECTING: '',
        PRODUCT_REPORT: '',
        BASKET_REPORT: '',
        TRANSACTION_SUMMARY: ''
      },
      PRODUCTS:'',
      PERSONNEL:'',
      USERS:'',
      SERVICE_MAINTENANCE:''
    },
    DASHBOARD: {
      DAILY_SALES:'',
      PRODUCT: '',
      TOTAL_VENDING: '',
      PIECE: '',
      ACTIVE: '',
      PASSIVE: '',
      PRODUCT_STOCK_RATE: '',
      PRODUCT_SALES_THIS_MONTH: '',
      POPULAR_PRODUCTS_OF_THIS_MONTH: '',
      NO_PRODUCT: '',
      LOADING: '',
    },
    VENDINGS: {
      SELECT_FILTER: '',
      LOCATION: '',
      TYPE: '',
      NAME: '',
      YOU_MUST_ADD_VENDING_TO_MAKE_A_SEARCH: '',
      FIND_VENDING: '',
      ADD_NEW_VENDING: '',
      LOADING: '',
      NO_VENDINGS_HAVE_BEE_NUILT_YET: '',
      VENDING_MOVEMENTS: '',
      CLOSE: 'KApat'
    },
    VENDING_GROUPS: {
      CREATE_NEW_GROUPS: '',
      GROUP_NAME:'',
      VENDING_COUNT:'',
      SHOW_VENDINGS:'',
      ADD_CARD_PACKAGE:'',
      NO_AUTOMAT_GROUP_HAVE_BEEN_CREATED_YET:'',
      EDIT_VENDING_GROUP:'',
      CREATE_VENDING_GROUP:'',
      SELECT_VENDING:'',
      SELECT_ALL:'',
      REMOVE_SELECTIONS:'',
      UPDATE: '',
      SAVE: '',
      CANCEL: 'İptal'
    },
    NEW_VENDING: {
      ADD_NEW_VENDING:'',
      EDIT_VENDING:'',
      VENDING_NAME:'',
      VENDING_BRAND:'',
      VENDING_MODEL:'',
      SERIAL_NO:'',
      BALANCE_TO_BE_DOWNLOADED_TO_THE_VENDING:'',
      VENDING_GROUP:'',
      OPERATOR:'',
      PAYMENT_TEMPLATE:'',
      PROVINCE:'',
      DISTRICT:'',
      NEIGHBORHOODS:'',
      DRAG_TCHANGE_POSITION:'',
      SELECTI_DEVICE:'',
      SELECT_READER:'',
      SELECT_BEACON:'',
      SELECT_SIM:'',
      CASH:'',
      CREDICARD:'',
      PRE_PAID_CARD:'',
      QR:'Qr Payment',
      MOBILE:'',
      DESCRIPTION:'',
      FEATURE_LEVEL:'',
      COUNTRY_CODE:'',
      MAX_RESP_TIME:'',
      MISC_OPTION:'',
      ADVANCE_PAYMENT:'',
      GROUP:'',
      PAYMENT_METHOD:'',
      REGISTRATION_SUCCESSFUL:'',
      NEXT:'',
      PREV:'',
      UPDATE:'',
      SAVE:'',
      CLOSE:'Kapat',
        AUTOMAT_TYPE:'Automat Type',
        IS_STOCK_CONTROL:'Stock Control',

    },
    CARD_PACKAGES: {
      CREATE_REPORT: '',
      CREATE_CARD_PACKAGE: '',
      PACKAGE_NAME: '',
      CREATED_AT: '',
      NUMBER_OF_CARDS: '',
      CARD_STATUS: '',
      TRANSFER_OF_BALANCE: '',
      PASSIVE: '',
      ACTIVE: '',
      OPEN: '',
      CLOSE: '',
      BALANCE_AND_EXPENDITURES: '',
      LOAD_BALANCE: '',
      ADD_USER: '',
      LOADING_REPORT: '',
      NO_CARD_PACKAGE: '',
    },
    SALES: {
      ALL_VENDINGS: '',
      SELECT_GROUP: '',
      SELECT_ALL: '',
      REMOVE_SELECTIONS: '',
      SELECT_VENDING: '',
      GENEL_TURNOVER: '',
      SEND_REPORT: '',
      DETAILED_SALES_REPORT: '',
      TODAY: '',
      PRODUCT: '',
      THIS_WEEK: '',
      THIS_MONTH: '',
      SPECIAL_CAPS: '',
      SALES: '',
      DAY: '',
      WEEK: '',
      MONTH: '',
      SPECIAL: '',
      PAYMENT_DISTRIBUTION: '',
      EXPORT: '',
      NAME: '',
      PRODUCT_SALES_DISTRIBUTION: '',
      SORTING_TYPE: '',
      NUMBER_OF_SALES: '',
      TOTAL_PRICE: '',
      REMOVED_PRODUCT: '',
      GIRO: '',
      PRODUCT_DETAILED_SALES_REPORT: '',
      PRODUCT_PAYMENT_DISTRIBUTION: '',
      PRODUCT_SALES_BY_VENDING: '',
      VENDING_NAME: '',
      DELETED_VENDING: '',
      CLOSE: '',
      LOADING: '',
      NO_DATA:'',
      QUANTITY: '',
      PIECE: '',
    },
    COLLECTION: {
      APPLY_FILTER: '',
      VENDING_GRUOP: '',
      VENDING_NAME_ID: '',
      PAYMENT_METHOD: '',
      CREDIT_CART: '',
      CASH: '',
      PRE_PAID_CARD: '',
      APPLY_FILTER_CAPS: '',
      RESET_FILTER: '',
      COMPANY_GENERAL_COLLECTION_TABLE: '',
      EXCEL: '',
      VENDING: '',
      GROUP: '',
      GIRO: '',
      ADVANCE_PAYMENT: '',
      STAFF: '',
      START: '',
      COLLECTION: '',
      DETAIL: '',
      DELETED_VENDING: '',
      DELETED_GROUP: '',
      REVIEW: '',
      COLLECTION_DETAIL: '',
      DATE: '',
      METALLIC: '',
      PAPER: '',
      TOTAL_GIRO: '',
      PERSONNEL_MAKING_COLLECTION: '',
    },
    PRODUCT_REPORT: {
      REPORT_INTERVAL: '',
      APPLY_FILTER: '',
      REGION: '',
      PAYMENT_METHOD: '',
      CREDIT_CARD: '',
      CASH: '',
      PRE_PAID_CARD: '',
      VENDING_GROUP: '',
      VENDINGS: '',
      TOTAL_PRICE: '',
      RESULT_TYPE: '',
      COMPLETED: '',
      CONTINUE: '',
      CANCEL: '',
      RESET_FILTER: '',
      APPLY_FILTER_CAPS: '',
      CASH_SALE: '',
      PRODUCT_CAPS: '',
      TOTAL_GIRO: '',
      EXCEL_DOWNLOAD: '',
      PRODUCT_NAME: '',
      SPIRAL_NUMBER: '',
      NUMBER_OF_SALES: '',
      TIME: '',
      VENDING: '',
      SALES_METHOD: '',
      DISCOUNT: '',
      SALES_PRICE: '',
      RESULT: '',
      PRODUCT: '',
      TRANSACTION_DETAILS: '',
      TRANSACTION_DATE: '',
      SALET_METHOD: '',
      SENSOR: '',
      SITUATION_UNKNOWN: '',
      OPEN: '',
      CLOSE: '',
      SEND_WALLET_INFORMATION: '',
      VEND_REQUEST: '',
      CREATE_BASKET: '',
      VEND_PAY: '',
      ACCEPT_PAYMENT: '',
      SENSOR_RESPONSE: '',
      END_OF_SESSION: '',
      CANCEL_PAYMENT: '',
      CANCEL_PAYMENT_END_SESSION: '',
    },
    SALES_REPORT: {
      APPLY_FILTER: '',
      REGION: '',
      PRODUCT_NAME: '',
      PAYMENT_METHOD: '',
      CREDIT_CARD: '',
      CASH: '',
      PRE_PAID_CARD: '',
      QR:'Qr Ödeme',
      VENDING_GROUP: '',
      VENDING_NAME_ID: '',
      TOTAL_PRICE: '',
      RESULT_TYPE: '',
      COMPLETED: '',
      CONTINUE: '',
      CANCEL: '',
      RESET_FILTER: '',
      APPLY_FILTER_CAPS: '',
      EXCEL: '',
      DAY: '',
      WEEK: '',
      MONTH: '',
      PRODUCT_PRICE: '',
      GROUP: '',
      VENDING: '',
      RESULT: '',
      USERNAME: '',
      BASKET_ID: '',
      TIME: '',
      SALES_METHOD: '',
      SALES_PRICE: '',
      PRODUCTS: '',
      CUSTOMER: '',
      SHOW_PRODUCTS: '',
      PRODUCTS_IN_BASKET: '',
      DISCOUNT_PRICE: '',
      PRICE: '',
    },
    PRODUCTS: {
      CREATE_PRODUCT: '',
      ALL_PRODUCTS: '',
      CATEGORIES: '',
      BRANDS: '',
      FIND_PRODUCT: '',
      NO_PRODUCT: '',
    },
    PERSONNEL: {
      NEW_KULLANICI: '',
      NO_STAFF_CREATED_YET: '',
      PROFILE_UPDATE: '',
      EDIT_STAFF: '',
      BROWSE: '',
      SELECT_A_SERVICE_DUTY: '',
      SAVE: '',
      CLOSE: '',
    },
    USERS: {
      CREATE_NEW_USERS: 'Yeni Kullanıcı Oluştur'
    },
    SERVICE_MAINTENANCE: {
      ACTIVE_VENDINGS: '',
      DETAIL: '',
      DEFECTIVE_VENDINGS: '',
      UPDATE_REQUIRED: '',
      SERVICE_CONTINUES: '',
      BACK_TO_CALENDAR: '',
      ASK_US: '',
      TOTAL: '',
      HOW_MANY_PROBLEMS: '',
      VENDING_ACTIVE_SALES: '',
      VENDING_DETAIL: '',
      DIRECT_STAFF: '',
      ROUTING_DONE: '',
      VENDINGS_REQUIRED_TO_UPDATE: '',
      VENDINGS_IN_MAINTENANCE: '',
      FAULT_TYPE: '',
      DESCRIPTION: '',
      SPIRAL_NUMBER: '',
      PRODUCT_NAME: '',
      REMAINING_PIECES: '',
      CREATION_TIME: '',
      LAST_UPDATE: '',
      NO: '',
      RELATED_PERSONNEL: '',
      CLOSE: '',
      DIRECT_PERSONNEL_TO_THE_VENDING: '',
      SELECT_PERSONNEL: '',
      CHOOSE_SERVICE_TYPE: '',
      FAULT: '',
      FILLING: '',
      SMS: '',
      WHATSAPP: '',
      E_MAIL: '',
      SEND_TO_STAFF: '',
      CANCEL: '',
    },
    SETTINGS: {
      SETTING: '',
      ACCOUNT: '',
      ACCOUNT_INFO: '',
      FIRM_INFO: '',
      CHANGE_PASSWORD: '',
      PAY: '',
      PAYMENT_METHODS: '',
      PAYMENT_TEMPLATES: '',
      VENDING_PAYMENT_METHOD: '',
      PAYMENT_ORDERS: '',
      OLD_PASSWORD: '',
      PASSWORD_REQUIRED: '',
      PASSWORD_INFORMATION: '',
      PASSWORD_LENGTH: '',
      PASSWORD_CHECK: '',
      CLOSE: '',
      SAVE_CHANGES: '',
    },
    USER_INFO: {
      UPDATE: '',
      UPLOAD_USER_PICTURE: '',
      SAVE: '',
      CANCEL: '',
    },
    SYSTEM_MESSAGES: {
      CREATE_REQUEST: '',
      NO_REQUESTS: '',
      CREATE_NEW_REQUEST: '',
      PLEASE_SELECT_SUBJECT: '',
      SEND: '',
      CANCEL: '',
      HOW_CAN_WE_HELP_YOU: '',
    }
  }
};
