import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormTemplate} from '../_models/formTemplate';


@Injectable({
  providedIn: 'root'
})

export class FormbuilderHelper {

  constructor(
    private fb: FormBuilder
  ) {
  }


  toFormGroup(obj: Array<FormTemplate<any>>): FormGroup {

    const group = {};
    obj.forEach(q => {
      switch (q.type) {
        case 'text':
          group[q.key] = q.required ? new FormControl(q.value || '', Validators.required)
            : new FormControl(q.value || '');
          break;
        case 'number':
          group[q.key] = q.required ? new FormControl(q.value || 0, Validators.required)
            : new FormControl(q.value || 0);
          break;
        default:
          group[q.key] = q.required ? new FormControl(q.value || '', Validators.required)
            : new FormControl(q.value || '');
      }
    });
    return new FormGroup(group);
  }
}
