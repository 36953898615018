
<div class="container mt-5 animated fadeIn">
  <div *ngxPermissionsOnly="['products-edit']" class="text-right mb-4">
    <button class="btn btn-success newbtn"
            (click)="setProduct(null, -1)"
            data-toggle="modal" data-backdrop="true" data-target="#urunDuzenle">
      <span class="fa fa-plus mr-3"></span>  {{'PRODUCTS.CREATE_PRODUCT' | translate}}
    </button>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg">
      <div class="gri-yuvarlak-cerceve align-items-center justify-content-center mb-2">
        <span class="font-weight-bold">{{'PRODUCTS.ALL_PRODUCTS' | translate}} {{total}}</span>
      </div>
    </div>
    <div class="col-md-6 col-lg">
      <ng-select class="custom"
            [items]="categories"
            appendTo="body"
            [(ngModel)]="selectedCategory"
            [virtualScroll]="true"
            placeholder="{{'PRODUCTS.CATEGORIES' | translate}}"
            [loading]="loading"
            (scrollToEnd)="onScrollCategory()"
            (change)="searchProduct('', 'category')"
            (keyup)="searchProduct('', 'category')"
            bindLabel="name" bindValue="" name="evendProduct">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
      </ng-select>
    </div>
    <div class="col-md-6 col-lg">
      <ng-select class="custom"
            [items]="brands"
            appendTo="body"
            [(ngModel)]="selectedBrand"
            [virtualScroll]="true"
            placeholder="{{'PRODUCTS.BRANDS' | translate}}"
            [loading]="loading"
            (scrollToEnd)="onScrollBrand()"
            (change)="searchProduct('', 'brand')"
            (keyup)="searchProduct('', 'brand')"
            bindLabel="name" bindValue="" name="evendProduct">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
      </ng-select>
    </div>
    <div class="col-md-6 col-lg">
      <div class="container">
        <div class="row arama-bar mb-2">
          <input type="text" id="arama" name="search" (input)="searchProduct($event.target.value)"
                 placeholder="{{'PRODUCTS.FIND_PRODUCT' | translate}}">
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-1">

</div>

<div class="container my-4" id="products">
  <div
    infiniteScroll
    [infiniteScrollDistance]="pages"
    [infiniteScrollThrottle]="20"
    (scrolled)="onScroll()"
    class="row">
    <!-- ÜRÜN : START -->
    <div
      *ngFor="let product of products; let i = index; trackBy: trackFunction"
      class="col-sm-4 col-md-3 col-lg-2 animated flipInY fast">
      <div class="container mb-4 py-3 border-urunler">
        <a *ngxPermissionsOnly="['products-edit']" (click)="setProduct(product, i)">
          <img class="duzenle-ikon" src="../../assets/img/duzenle.svg" width="25">
        </a>
        <div class="row justify-content-center">
          <img *ngIf="product.image === ''" class="my-4 product-image" src="../../assets/img/gorsel_yok.svg"
               height="72">
          <img *ngIf="product.image !== ''" class="my-4 product-image" [src]="product.image | image: ProductImagePath"
               default="../../assets/img/gorsel_yok.svg" height="72">
        </div>
        <div class="row overflow-hidden text-nowrap" [ngClass]="{'justify-content-center':product?.name?.length <= 25}">
          <p class="urun-baslik mb-1" [ngClass]="{'slide-text': product?.name?.length > 25}">{{product?.name}}</p>
        </div>
        <div class="row justify-content-center">
          <p class="urun-fiyat my-1">{{product.price | number | commaDotSwapper}} ₺</p>
        </div>

      </div>
    </div>
    <!-- ÜRÜN : END -->
  </div>

  <!-- Boş liste uyarı -->
  <div *ngIf="(!products || products?.length == 0) && !isNetworkActive"
       class="row justify-content-center animated flipInX">
    <h1 class="h3 mt-5" style="color: gray">{{'PRODUCTS.NO_PRODUCT' | translate}}</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>

<div class="container mb-2" id="page-loader" style="display: none">
  <div class="row justify-content-center">
    <img src="../../assets/img/page-loader.svg" width="80">
  </div>
</div>

<a (click)="topFunction()" id="topButton" title="En yukarı çık">
  <img src="../../assets/img/up-arrow.svg" width="36">
</a>


<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
