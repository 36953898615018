import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {PipesModule} from '../../../_pipes/pipes.module';
import {DndModule} from 'ngx-drag-drop';
import {MethodPaymentComponent} from './method-payment.component';
import {EditMethodPaymentComponent} from './edit-method-payment/edit-method-payment.component';
import {DynamicFormModule} from '../../../_widgets/dynamic-form/dynamic-form.module';

const dec_exp = [
  MethodPaymentComponent,
  EditMethodPaymentComponent,

];

@NgModule({
  declarations: [dec_exp],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NgxSpinnerModule,
    PipesModule,
    DndModule,

    DynamicFormModule
  ],
  exports: [dec_exp]
})
export class MethodPaymentModule {
}
