import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as mathjs from 'mathjs';
import {SwalHelper} from '../../_helpers/swal.helper';
import {CashlessService} from '../../_services/cashless.service';
import * as moment from 'moment';
import {OfflineUserGroup} from '../../_models/offlineUserGroup';

@Component({
  selector: 'app-load-balance-card-packet',
  templateUrl: './load-balance-card-packet.component.html',
  styleUrls: ['./load-balance-card-packet.component.css']
})
export class LoadBalanceCardPacketComponent implements OnInit {

  @ViewChild('datePicker', {static: false}) datePicker: ElementRef;
  @ViewChild('groupBalanceInput', {static: false}) groupBalanceInput: ElementRef;
  @ViewChild('monthPeriod', {static: false}) monthPeriodSelect: ElementRef;

  swalOptions = new SwalHelper();

  monthPeriod = '-1';
  periodStartDate = '-1';
  monthlyPeriodInfoText = '';
  singleGroupBalance = null;
  singleGroupBalanceApproval = false;
  group: OfflineUserGroup;
  groupBalance = null;
  periodIsUpdate = false;
  sendMail = false;
  constantDay = '';
  isGroupBalance = false;
  isSingleGroupBalance = false;
  isMonthPeriod = false;
  isPeriodStartDate = false;

  firmUserMailAddress = '';

  controlVariables: { isInEdit: boolean, index: number, offlineUserGroupId: string };

  @Input()
  set selectedGroup(data: OfflineUserGroup) {
    this.group = data;
  }

  @Input()
  set conVariables(data: { isInEdit: boolean, index: number, offlineUserGroupId: string }) {
    this.controlVariables = data;
  }

  @Output() submit = new EventEmitter<{ success: boolean, code: number, data: any }>();

  constructor(
    public activeModal: NgbActiveModal,
    private cashlessService: CashlessService,
  ) {
  }


  ngOnInit(): void {
  }


  controlSingleUpdateGroupBalance() {
    this.singleGroupBalanceApproval = mathjs.round(this.singleGroupBalance, 2) > 0;
    // this.singleGroupBalance = event.target.value;
  }

  updateSingleGroupBalance() {
    if (mathjs.round(this.singleGroupBalance, 2) <= 0) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Sıfır veya sıfırdan küçük bir değer girmeyiniz!'
      });
      return;
    }
    this.cashlessService.addOfflineUserGroupBalance(this.group._id, this.singleGroupBalance).subscribe(res => {

      this.submit.emit({success: true, code: 1, data: null});
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Kart paketindeki tüm kullanıcılara ' + this.singleGroupBalance + '₺ yüklendi.'
      });

    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bakiye yüklenirken hata oluştu.'
      });

    });
  }

  controlPeriodArea(whichOne: string): boolean {
    if (whichOne === 'startDate') {
      let currentDate;
      if (this.periodStartDate !== '-1') {
        currentDate = moment().format('YYYY-MM-DD');
        const partOfDay = this.periodStartDate.split('-');
        const partOfCurrentDay = currentDate.split('-');
        this.constantDay = partOfDay[2];
        if (partOfCurrentDay[0] === partOfDay[0] && partOfCurrentDay[1] === partOfDay [1]) {
          if (parseInt(partOfCurrentDay[2], 10) < parseInt(partOfDay[2], 10)) {
            this.isPeriodStartDate = true;
            return true;
          } else if (parseInt(partOfCurrentDay[0], 10) >= parseInt(partOfDay[0], 10)) {
            this.isPeriodStartDate = false;
            return false;
          }
        } else if (partOfCurrentDay[0] === partOfDay[0] && parseInt(partOfCurrentDay[1], 10) < parseInt(partOfDay[1], 10)) {
          this.isPeriodStartDate = true;
          return true;
        } else if (parseInt(partOfCurrentDay[0], 10) < parseInt(partOfDay[0], 10)) {
          this.isPeriodStartDate = true;
          return true;
        } else {
          this.isPeriodStartDate = false;
          return false;
        }
      }
    } else if (whichOne === 'monthlyPeriod') {
      if (this.monthPeriod === '-1') {
        this.isMonthPeriod = false;
        return false;
      } else {
        this.isMonthPeriod = true;
        return true;
      }
    } else if (whichOne === 'periodBalance' && this.groupBalanceInput !== undefined) {
      if (parseInt(this.groupBalanceInput.nativeElement.value, 10) <= 0 || this.groupBalanceInput.nativeElement.value === '') {
        this.isGroupBalance = false;
        return false;
      } else {
        this.isGroupBalance = true;
        return true;
      }

    } else if (whichOne === 'areaControl') {
      return this.isPeriodStartDate && this.isMonthPeriod && this.isGroupBalance;
    } else if (whichOne === 'periodBalanceUpdate') {
      if (this.group.periodBalance <= 0 || this.group.periodBalance === null) {
        this.isGroupBalance = false;
        return false;
      } else {
        this.isGroupBalance = true;
        return true;
      }
    }
  }


  editNextStartDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  getPeriod(value: string, whichOne: string) {
    if (whichOne === 'month') {
      this.monthPeriod = value;
      this.monthlyPeriodInfoText = this.getPeriodInfoText(parseInt(value));
    } else if (whichOne === 'startdate') {
      this.periodStartDate = value;
    }
  }

  getPeriodInfoText(period): string {
    switch (period) {
      case 1: {
        return 'Her Ay';
      }
      case 2: {
        return '2 Ayda 1 Kez';
      }
      case 3: {
        return '3 Ayda 1 Kez';
      }
      case 4: {
        return '4 Ayda 1 Kez';
      }
      case 5: {
        return '5 Ayda 1 Kez';
      }
      case 6: {
        return '6 Ayda 1 Kez';
      }
    }
  }

  openPeriodUpdateButton() {
    this.periodIsUpdate = true;
    this.periodStartDate = this.group.nextStartDate.toString();
    this.monthPeriod = this.group.monthlyPeriod.toString();
  }

  updatePeriod() {
    const partOfNextStartDate = this.periodStartDate.split('-');
    this.group.nextStartDate = this.periodStartDate;
    this.group.monthlyPeriod = this.monthPeriod;
    this.group.constantDay = partOfNextStartDate[2];
    if (this.group.sendMail && (this.group.mailAddress === '' || this.group.mailAddress === null)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Mail adresi boş bırakılamaz.'
      });
    } else {
      this.cashlessService.updatePeriodOfflineGroup(this.controlVariables.offlineUserGroupId, this.group).subscribe(res => {

        this.submit.emit({success: true, code: 3, data: null});
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Periyot Güncellendi'
        });

      }, error => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Periyot güncellenirken hata oluştu.'
        });
      });
    }
  }


  deletePeriod(offlineUserGroupId: string) {
    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Kart Paketine tanımlı Periyodik Yükleme Planı\'nı silmek üzeresiniz. Onaylıyor musunuz?'
    }).then((result) => {
      if (result.value) {
        this.cashlessService.deletePeriodOfflineGroup(offlineUserGroupId).subscribe(res => {
          this.submit.emit({success: true, code: 4, data: null});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Periyot Planı Silindi.'
          });

        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Periyot Planı Silinirken Hata Oluştu.'
          });

        });
      }
    });

  }


  cancelPeriodUpdate() {
    //modalclose
    this.periodIsUpdate = false;
  }

  splitMailAddresses(mailAddress): string {
    const mailAddresses = mailAddress.toString().split(';');
    let resMailAddresses = '';

    mailAddresses.forEach((data, index) => {
      if (index === mailAddresses.length - 1) {
        resMailAddresses += data;
      } else {
        resMailAddresses += data + ', ';
      }
    });
    return resMailAddresses;
  }

  checkedBalanceTransfer(balanceTransfer) {
    this.group.balanceTransfer = balanceTransfer;
  }

  checkedSendMail(sendMail) {
    this.sendMail = sendMail;
    this.group.sendMail = sendMail;
  }

  updateGroupBalance() {
    if (this.sendMail && this.firmUserMailAddress === '') {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'E-Mail adresi girmeden periyot oluşturamazsınız.'
      });
    } else if (!this.sendMail) {
      this.groupBalance = this.groupBalanceInput.nativeElement.value;
      this.cashlessService.createPeriodOfflineGroup(this.group._id, this.periodStartDate, true,
        this.monthPeriod, this.constantDay, this.groupBalance, this.sendMail, this.firmUserMailAddress, this.group.balanceTransfer)
        .subscribe(res => {
          this.submit.emit({success: true, code: 5, data: null});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Periyodik Yükleme Planı başarılı bir şekilde oluştu.'
          });
          this.groupBalanceInput.nativeElement.value = 0;
          this.groupBalance = null;
          this.periodStartDate = '-1';
          this.monthPeriod = '-1';
          this.datePicker.nativeElement.value = '';
          this.monthPeriodSelect.nativeElement.value = '-1';
          this.groupBalance = this.groupBalanceInput.nativeElement.value;

        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Periyodik Yükleme Planı oluştururken bir hata meydana geldi.'

          });
        });
    } else if (this.sendMail && this.firmUserMailAddress !== '') {
      this.groupBalance = this.groupBalanceInput.nativeElement.value;
      this.cashlessService.createPeriodOfflineGroup(this.group._id, this.periodStartDate, true,
        this.monthPeriod, this.constantDay, this.groupBalance, this.sendMail, this.firmUserMailAddress, this.group.balanceTransfer)
        .subscribe(res => {

          this.submit.emit({success: true, code: 6, data: null});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Periyodik Yükleme Planı başarılı bir şekilde oluştu.'
          });
          this.groupBalanceInput.nativeElement.value = 0;
          this.groupBalance = null;
          this.periodStartDate = '-1';
          this.monthPeriod = '-1';
          this.datePicker.nativeElement.value = '';
          this.monthPeriodSelect.nativeElement.value = '-1';
          this.groupBalance = this.groupBalanceInput.nativeElement.value;
        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Periyodik Yükleme Planı oluştururken bir hata meydana geldi.'
          });

        });
    }

  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
}
