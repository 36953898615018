<input
       ngbDatepicker
       #datepicker="ngbDatepicker"
       [autoClose]="'outside'"
       (dateSelect)="onDateSelection($event)"
       [displayMonths]="1"
       [dayTemplate]="t"
       outsideDays="hidden"
       [startDate]="fromDate"
       [maxDate]=""
       [footerTemplate]="footerTemplate"
       class="custom"
>

<ng-template #t let-date let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <hr class="my-0">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <ngb-timepicker [(ngModel)]="fromTime" [minuteStep]="15"></ngb-timepicker>
    </div>
    <div class="row justify-content-center">
      <ngb-timepicker [(ngModel)]="toTime" [minuteStep]="15"></ngb-timepicker>
    </div>
  </div>
  <div class="row justify-content-center">
    <button class="btn btn-primary" [disabled]="this.toDate ? !(this.fromDate && this.toDate && this.toDate.after(this.fromDate)) : !(this.fromDate)"
            (click)="send()">Uygula
    </button>
  </div>
</ng-template>
