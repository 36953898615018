export class SaleReportFilter {
  constructor() {}
  district = '';
  group = '';
  automat = [];
  paymentType = -1;
  resultType = -1;
  amount: number;
  productName = '';
  productPrice: number;
}

export class BasketReportFilter {
  constructor() {}
  district = '';
  group = '';
  automat = '';
  paymentType = -1;
  resultType = -1;
  amount: number;
  productName = '';
  productPrice: number;
}
