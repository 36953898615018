// USA
export const locale = {
  lang: 'tr',
  data: {
    HEADER: {
      DASHBOARD: 'Dashboard',
      VENDINGS: 'Otomatlar',
      VENDING_DROPDOWN: {
        VENDINGS: 'Otomatlar',
        VENDING_GROUPS: 'Otomat Grupları',
        NEW_VENDING: 'Yeni Otomat'
      },
      QRCODES: 'QR Kod',
      QRCODES_DROPDOWN: {
        QRCODES_GROUPS: 'QR Kod Otomat Grupları',
        QRCODES: 'QR Kod Listesi',
        QRCODES_SUB_USER: 'QR Kod Alt Kullanıcı',
      },
      CARDS: 'Kartlar',
      CARDS_DROPDOWN: {
        CARD_PACKAGES: 'Kart Paketleri'
      },
      SALES: 'Satışlar',
      SALES_DROPDOWN: {
        SALES: 'Satışlar',
        COLLECTING: 'Tahsilat',
        PRODUCT_REPORT: 'Ürün Raporu',
        BASKET_REPORT: 'Sepet Raporu',
        TRANSACTION_SUMMARY: 'İşlem Özeti'
      },
      PRODUCTS:'Ürünler',
      PERSONNEL:'Personeller',
      USERS:'Kullanıcılar',
      SERVICE_MAINTENANCE:'Servis/Bakım'
    },
    DASHBOARD: {
      DAILY_SALES:'GÜNLÜK SATIŞLAR',
      PRODUCT: 'Ürün',
      TOTAL_VENDING: 'Toplam Otomat',
      PIECE: 'Adet',
      ACTIVE: 'AKTİF',
      PASSIVE: 'PASİF',
      PRODUCT_STOCK_RATE: 'ÜRÜN STOK ORANI',
      PRODUCT_SALES_THIS_MONTH: 'BU AYIN ÜRÜN SATIŞ ADETLERİ',
      POPULAR_PRODUCTS_OF_THIS_MONTH: 'BU AYIN POPÜLER ÜRÜNLERİ',
      NO_PRODUCT: 'Ürün yok',
      LOADING: 'Yükleniyor',
    },
    VENDINGS: {
      SELECT_FILTER: 'FİLTRE UYGULA',
      LOCATION: 'Lokasyon',
      TYPE: 'Tür',
      NAME: 'Ad',
      YOU_MUST_ADD_VENDING_TO_MAKE_A_SEARCH: 'Arama yapabilmek için otomat eklemelisiniz !',
      FIND_VENDING: 'Otomat arayınız',
      ADD_NEW_VENDING: 'Yeni otomat ekle',
      LOADING: 'Yükleniyor',
      NO_VENDINGS_HAVE_BEE_NUILT_YET: 'Henüz oluşturulmuş otomat yok !',
      VENDING_MOVEMENTS: 'Otomat hareketleri',
      CLOSE: 'KApat'
    },
    VENDING_GROUPS: {
      CREATE_NEW_GROUPS: 'Yeni Grup Oluştur',
      GROUP_NAME:'Grup Adı',
      VENDING_COUNT:'Otomat Sayısı',
      SHOW_VENDINGS:'Otomatları Göster',
      ADD_CARD_PACKAGE:'Kart Paketi Ekle',
      NO_AUTOMAT_GROUP_HAVE_BEEN_CREATED_YET:'Henüz oluşturulmuş otomat grubu yok !',
      EDIT_VENDING_GROUP:'Otomat Grubunu Düzenle',
      CREATE_VENDING_GROUP:'Otomat Grubu Oluştur',
      SELECT_VENDING:'Otomat Seçiniz',
      SELECT_ALL:'Hepsini Seç',
      REMOVE_SELECTIONS:'Seçimleri Kaldır',
      UPDATE: 'Güncelle',
      SAVE: 'Kaydet',
      CANCEL: 'İptal',
      SELECT_CARD: 'kart Seçiniz',
      SELECT_CARD_PACKAGE: 'Kart Paketi Seçiniz',
      ADD: 'Ekle'
    },
    NEW_VENDING: {
      ADD_NEW_VENDING:'Yeni Otomat Ekle',
      EDIT_VENDING:'Otomat Düzenle',
      VENDING_NAME:'Otomat Adı',
      VENDING_BRAND:'Otomat Markası',
      VENDING_MODEL:'Otomat Modeli',
      SERIAL_NO:'Seri No',
      EXTERNAL_ID: 'External Id',
      BALANCE_TO_BE_DOWNLOADED_TO_THE_VENDING:'Otomata Yüklenecek Bakiye',
      VENDING_GROUP:'Otomat Grubu',
      AUTOMAT_TYPE:'Otomat Türü',
      OPERATOR:'Operatör',
      PAYMENT_TEMPLATE:'Ödeme Şablonu',
      PROVINCE:'İl',
      DISTRICT:'İlçe',
      NEIGHBORHOODS:'Semt',
      DRAG_TCHANGE_POSITION:'Konumu değiştirmek için sürükleyin',
      DEVICE: 'Cihaz',
      SELECTI_DEVICE:'Cihaz Seçiniz',
      SELECT_READER:'Okuyucu Seçiniz',
      SELECT_BEACON:'Beacon Seçiniz',
      SELECT_SIM:'Sim Kart Seçiniz',
      CASH:'Nakit',
      CREDICARD:'Kredi Kartı',
      QR:'Qr Ödeme',
      PRE_PAID_CARD:'Ön Ödemeli Kart',
      METROPOLCARD_QR:'MetropolCard QR',
      MOBILE:'Mobil',
      DESCRIPTION:'Açıklama',
      FEATURE_LEVEL:'Özellik Seviyesi',
      COUNTRY_CODE:'Ülke Kodu',
      SCALE_FACTOR: 'Scale Factor',
      DECIMAL_PLACES: 'Decimal Places',
      MAX_RESP_TIME:'Maksimum Cevap Süresi',
      MISC_OPTION:'Opsiyonlar',
      ADVANCE_PAYMENT:'Avans',
      GROUP:'Grup',
      PAYMENT_METHOD:'Ödeme Yöntemi',
      REGISTRATION_SUCCESSFUL:'KAYIT BAŞARILI',
      NEXT:'İleri',
      PREV:'Geri',
      UPDATE:'Güncelle',
      SAVE:'Kaydet',
      CLOSE:'Kapat',
        IS_STOCK_CONTROL:'Stok Kontrol',
    },
    CARD_PACKAGES: {
      CREATE_REPORT: 'Rapor Oluştur',
      CREATE_CARD_PACKAGE: 'Kart Paketi Oluştur',
      PACKAGE_NAME: 'Paket Adı',
      CREATED_AT: 'Oluşturma Tarihi',
      NUMBER_OF_CARDS: 'Kart Sayısı',
      CARD_STATUS: 'Kart Durumu',
      TRANSFER_OF_BALANCE: 'Bakiye Devretme',
      PASSIVE: 'Pasif',
      ACTIVE: 'Aktif',
      OPEN: 'Açık',
      CLOSE: 'Kapalı',
      BALANCE_AND_EXPENDITURES: 'Bakiye ve Harcamalar',
      LOAD_BALANCE: 'Bakiye Yükle',
      RESET_BALANCE: 'Bakiye Sıfırla',
      DEDUCT_BALANCE: 'Bakiye Düşür',
      ADD_USER: 'Kullanıcı Ekle',
      LOADING_REPORT: 'Yükleme Raporu',
      NO_CARD_PACKAGE: 'Henüz oluşturulmuş kart paketi yok !',
    },
    SALES: {
      ALL_VENDINGS: 'TÜM OTOMATLAR',
      SELECT_GROUP: 'Grup Seçiniz',
      SELECT_ALL: 'Hepsini Seç',
      REMOVE_SELECTIONS: 'Seçimleri Kaldır',
      SELECT_VENDING: 'Otomat Seçiniz',
      GENEL_TURNOVER: 'GENEL CİRO',
      SEND_REPORT: 'Rapor Gönder',
      DETAILED_SALES_REPORT: 'Detaylı Satış Raporu',
      TODAY: 'BUGÜN',
      PRODUCT: 'ÜRÜN',
      THIS_WEEK: 'BU HAFTA',
      THIS_MONTH: 'BU AY',
      SPECIAL_CAPS: 'ÖZEL',
      SALES: 'SATIŞLAR',
      DAY: 'Gün',
      WEEK: 'Hafta',
      MONTH: 'Ay',
      SPECIAL: 'Özel',
      PAYMENT_DISTRIBUTION: 'ÖDEME DAĞILIMI',
      EXPORT: 'EXPORT',
      NAME: 'Ad',
      PRODUCT_SALES_DISTRIBUTION: 'ÜRÜN SATIŞ DAĞILIMI',
      SORTING_TYPE: 'Sıralama Şekli',
      NUMBER_OF_SALES: 'Satış Adedi',
      TOTAL_PRICE: 'Toplam Fiyat',
      REMOVED_PRODUCT: 'Silinmiş Ürün',
      GIRO: 'Ciro',
      PRODUCT_DETAILED_SALES_REPORT: 'Ürünün Detaylı Satış Raporu',
      PRODUCT_PAYMENT_DISTRIBUTION: 'Ürünün Ödeme Dağılımı',
      PRODUCT_SALES_BY_VENDING: 'Otomata Göre Ürün Satışları',
      VENDING_NAME: 'Otomat Adı',
      DELETED_VENDING: 'Silinmiş Otomat',
      CLOSE: 'Kapat',
      LOADING: 'Yükleniyor',
      NO_DATA:'Veri Yok!',
      QUANTITY: 'Miktar',
      PIECE: 'Adet',
    },
    COLLECTION: {
      APPLY_FILTER: 'Filtre Uygula',
      VENDING_GRUOP: 'Otomat Grubu',
      VENDING_NAME_ID: 'Otomat Adı ve ID',
      PAYMENT_METHOD: 'Ödeme Yöntemi',
      CREDIT_CART: 'Kredi Kartı',
      CASH: 'Nakit',
      PRE_PAID_CARD: 'Ön Ödemeli Kart',
      QR:'Qr Ödeme',
      APPLY_FILTER_CAPS: 'FİLTRE UYGULA',
      RESET_FILTER: 'FİLTRE SIFIRLA',
      COMPANY_GENERAL_COLLECTION_TABLE: 'Firma Genel Tahsilat Tablosu',
      EXCEL: 'EXCEL',
      VENDING: 'Otomat',
      GROUP: 'Grup',
      GIRO: 'Ciro',
      ADVANCE_PAYMENT: 'Avans',
      STAFF: 'Personel',
      START: 'Başlangıç',
      COLLECTION: 'Tahsilat',
      DETAIL: 'Detay',
      DELETED_VENDING: 'Silinmiş Otomat',
      DELETED_GROUP: 'Silinmiş Grup',
      REVIEW: 'İncele',
      COLLECTION_DETAIL: 'Tahsilat Detayı',
      DATE: 'Tarih',
      METALLIC: 'Madeni',
      PAPER: 'Kağıt',
      TOTAL_GIRO: 'Toplam Ciro',
      PERSONNEL_MAKING_COLLECTION: 'Tahsilat Yapan Personel',
    },
    PRODUCT_REPORT: {
      REPORT_INTERVAL: 'Rapor Aralığı',
      APPLY_FILTER: 'Filtre Uygula',
      REGION: 'Bölge',
      PAYMENT_METHOD: 'Ödeme Yöntemi',
      CREDIT_CARD: 'Kredi Kartı',
      CASH: 'Nakit',
      PRE_PAID_CARD: 'Ön Ödemeli Kart',
      QR:'Qr Ödeme',
      METROPOLCARD: 'MetropolCard',
      METROPOLCARD_QR: 'MetropolCard QR',
      VENDING_GROUP: 'Otomat Grubu',
      VENDINGS: 'Otomatlar',
      TOTAL_PRICE: 'Toplam Fiyat',
      RESULT_TYPE: 'Sonuç Türü',
      COMPLETED: 'Tamamlandı',
      CONTINUE: 'Devam Ediyor',
      CANCEL: 'İptal',
      RESET_FILTER: 'FİLTREYİ TEMİZLE',
      APPLY_FILTER_CAPS: 'FİLTRE UYGULA',
      CASH_SALE: 'Nakit Satış',
      PRODUCT_CAPS: 'ÜRÜN',
      TOTAL_GIRO: 'Toplam Ciro',
      EXCEL_DOWNLOAD: 'Excel İndir',
      PRODUCT_NAME: 'Ürün Adı',
      SPIRAL_NUMBER: 'Spiral Numarası',
      NUMBER_OF_SALES: 'Satış Adet',
      TIME: 'Zaman',
      VENDING: 'Otomat',
      SALES_METHOD: 'Ödeme Yöntemi',
      DISCOUNT: 'İndirimli',
      SALES_PRICE: 'Satış Fiyatı',
      RESULT: 'Sonuç',
      PRODUCT: 'Ürün',
      TRANSACTION_DETAILS: 'İşlem Detayları',
      TRANSACTION_DATE: 'İşlem Tarihi',
      SALET_METHOD: 'Ödeme Yöntemi',
      SENSOR: 'Sensör',
      SITUATION_UNKNOWN: 'Durumu Bilinmiyor',
      OPEN: 'Açık',
      CLOSE: 'Kapalı',
      SEND_WALLET_INFORMATION: 'Cüzdan Bilgisini Gönder',
      VEND_REQUEST: 'Satış İsteği',
      CREATE_BASKET: 'Sepet Oluştur',
      VEND_PAY: 'Ödeme Yap',
      ACCEPT_PAYMENT: 'Ödeme Kabul',
      SENSOR_RESPONSE: 'Sensör Cevabı',
      END_OF_SESSION: 'İşlem Sonu',
      CANCEL_PAYMENT: 'Ödeme İptali',
      CANCEL_PAYMENT_END_SESSION: 'İşlem Sonu Ödeme İptali',
    },
    SALES_REPORT: {
      APPLY_FILTER: 'Filtre Uygula',
      REGION: 'Bölge',
      PRODUCT_NAME: 'Ürün Adı',
      PAYMENT_METHOD: 'Ödeme Yöntemi',
      CREDIT_CARD: 'Kredi Kartı',
      CASH: 'Nakit',
      PRE_PAID_CARD: 'Ön Ödemeli Kart',
      QR:'Qr Ödeme',
      VENDING_GROUP: 'Otomat Grubu',
      VENDING_NAME_ID: 'Otomat Adı ve ID',
      TOTAL_PRICE: 'Toplam Fiyat',
      RESULT_TYPE: 'Sonuç Türü',
      COMPLETED: 'Tamamlandı',
      CONTINUE: 'Devam Ediyor',
      CANCEL: 'İptal',
      RESET_FILTER: 'FİLTREYİ TEMİZLE',
      APPLY_FILTER_CAPS: 'FİLTRE UYGULA',
      EXCEL: 'EXCEL',
      DAY: 'Gün',
      WEEK: 'Hafta',
      MONTH: 'Ay',
      PRODUCT_PRICE: 'Ürün Fiyatı',
      GROUP: 'Grup',
      VENDING: 'Otomat',
      RESULT: 'Sonuç',
      USERNAME: 'Kullanıcı Adı',
      BASKET_ID: 'Sepet ID',
      TIME: 'Zaman',
      SALES_METHOD: 'Ödeme Yöntemi',
      SALES_PRICE: 'Satış Fiyatı',
      PRODUCTS: 'Ürünler',
      CUSTOMER: 'Müşteri',
      SHOW_PRODUCTS: 'Ürünleri Göster',
      PRODUCTS_IN_BASKET: 'Sepetteki Ürünler',
      DISCOUNT_PRICE: 'İndirimli Fiyat',
      PRICE: 'Normal Fiyat',
    },
    PRODUCTS: {
      CREATE_PRODUCT: 'Ürün Oluştur',
      ALL_PRODUCTS: 'TÜM ÜRÜNLER',
      CATEGORIES: 'Kategoriler',
      BRANDS: 'Markalar',
      FIND_PRODUCT: 'Ürün Arayın',
      NO_PRODUCT: 'Ürün Yok!',
    },
    PERSONNEL: {
      NEW_KULLANICI: 'Yeni Personel',
      NO_STAFF_CREATED_YET: 'Henüz oluşturulmuş personel yok!',
      PROFILE_UPDATE: 'Profil Güncelle',
      EDIT_STAFF: 'Personel Düzenle',
      BROWSE: 'Gözat',
      SELECT_A_SERVICE_DUTY: 'Servis Görevi Seçiniz',
      SAVE: 'Kaydet',
      CLOSE: 'İptal',
    },
    USERS: {
      CREATE_NEW_USERS: 'Yeni Kullanıcı Oluştur'
    },
    SERVICE_MAINTENANCE: {
      ACTIVE_VENDINGS: 'AKTİF OTOMATLAR',
      DETAIL: 'Detay',
      DEFECTIVE_VENDINGS: 'ARIZALI OTOMATLAR',
      UPDATE_REQUIRED: 'GÜNCELLEME GEREKLİ',
      SERVICE_CONTINUES: 'SERVİSİ DEVAM EDİYOR',
      BACK_TO_CALENDAR: 'Takvime Geri Dön',
      ASK_US: 'Bize Sorun',
      TOTAL: 'Toplam',
      HOW_MANY_PROBLEMS: 'tane sorun var',
      VENDING_ACTIVE_SALES: 'OTOMAT AKTİF SATIŞTA',
      VENDING_DETAIL: 'Otomat Detayları',
      DIRECT_STAFF: 'Personel Yönlendir',
      ROUTING_DONE: 'Yönlendirme Yapıldı',
      VENDINGS_REQUIRED_TO_UPDATE: 'GÜNCELLEME GEREKLİ OTOMATLAR',
      VENDINGS_IN_MAINTENANCE: 'SERVİSTE OLAN OTOMATLAR',
      FAULT_TYPE: 'Sorun Türü',
      DESCRIPTION: 'Açıklama',
      SPIRAL_NUMBER: 'Spiral No',
      PRODUCT_NAME: 'Ürün Adı',
      REMAINING_PIECES: 'Kalan Adet',
      CREATION_TIME: 'Oluşturma Zamanı',
      LAST_UPDATE: 'Son Güncelleme',
      NO: 'Yok',
      RELATED_PERSONNEL: 'İlgili Personeller',
      CLOSE: 'Kapat',
      DIRECT_PERSONNEL_TO_THE_VENDING: 'Otomata Personel Yönlendir',
      SELECT_PERSONNEL: 'Personel Seçiniz',
      CHOOSE_SERVICE_TYPE: 'Servis Tipini Seçiniz',
      FAULT: 'Arıza',
      FILLING: 'Dolum',
      SMS: 'SMS',
      WHATSAPP: 'Whatsapp',
      E_MAIL: 'E-Posta',
      SEND_TO_STAFF: 'Personele Gönder',
      CANCEL: 'İptal',
    },
    SETTINGS: {
      SETTING: 'Ayarlar',
      ACCOUNT: 'Hesap',
      ACCOUNT_INFO: 'Hesap Bilgileri',
      STORAGE_CARD: 'Kart Kayıt',
      FIRM_INFO: 'Firma Bilgileri',
      CHANGE_PASSWORD: 'Şifre Değiştir',
      PAY: 'Ödeme',
      PAYMENT_METHODS: 'Ödeme Yöntemleri',
      PAYMENT_TEMPLATES: 'Ödeme Şablonları',
      VENDING_PAYMENT_METHOD: 'Otomat Ödeme Yöntemi',
      PAYMENT_ORDERS: 'Ödeme Emirleri',
      OLD_PASSWORD: 'Eski Şifre',
      NEW_PASSWORD: 'Yeni Şifre',
      CONFIRM_PASSWORD: 'Yeni Şifre Tekrar',
      PASSWORD_REQUIRED: 'Şifre Girilmesi Zorunludur',
      PASSWORD_LENGTH: 'Şifre uzunluğu en az 8 karakterden oluşmalıdır!',
      PASSWORD_INFORMATION: 'Şifreniz en az bir küçük, bir büyük harf ve rakam içermelidir',
      PASSWORD_CHECK: 'Şifreler aynı olmalıdır',
      CLOSE: 'Kapat',
      SAVE_CHANGES: 'Değişiklikleri Kaydet',
    },
    USER_INFO: {
      UPDATE: 'Güncelle',
      UPLOAD_USER_PICTURE: 'Kullanıcı Resmi Yükle',
      SAVE: 'Kaydet',
      CANCEL: 'İptal',
    },
    SYSTEM_MESSAGES: {
      CREATE_REQUEST: 'İSTEK OLUŞTUR',
      NO_REQUESTS: 'İstek Talebi Yok',
      CREATE_NEW_REQUEST: 'Yeni İstek Oluştur',
      PLEASE_SELECT_SUBJECT: 'Lütfen Konu Seçiniz',
      SEND: 'Gönder',
      CANCEL: 'İptal',
      HOW_CAN_WE_HELP_YOU: 'Size nasıl yardımcı olabiliriz.',
    },
    APPS: {
      APPS: 'Uygulamalar',
      API_SETTINGS: 'API Ayarları',
      API_KEY_CONNECTION: 'API KEY BAĞLANTISI',
      PASSIVE: 'PASİF',
      ACTIVE: "AKTİF",
      PAYTER_DOMAIN: 'PAYTER Domain, Kullanıcı adı ve Şifre bilgilerinizi girerek entegrasyonu tamamlayabilirsiniz',
      SAVE: 'Kaydet',
      DOMAIN: 'Domain',
      USER_NAME: 'Kullanıcı Adı',
      PASSWORD: 'Şifre'
    }
  }
};
