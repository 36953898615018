<div class="app flex-row align-items-center">
    <div class="container p-4 mt-5">
        <div class="row justify-content-center">

            <div class="col-6 mt-4">
                <form name="form" novalidate>
                    <div class="input-group mb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col">
                                    <img src="../../../assets/img/evend_logo.svg" alt="" style="width:100%">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="input-group  mb-3">
                        <div class="input-group-prepend display-block">
                            <span class="input-group-text justify-content-center"><img src="../../../assets/img/login-user-icon.svg" alt=""></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Kullanıcı Kodu" id="bayiCode" name="bayiCode" [(ngModel)]="user.bayiCode" >
                    </div>
                    <div class="input-group  mb-4">
                        <div class="input-group-prepend display-block">
                            <span class="input-group-text justify-content-center"><img src="../../../assets/img/login-pass-icon.svg" alt=""></span>
                        </div>
                        <input type="password" class="form-control" placeholder="Şifre" name="password" id="password" [(ngModel)]="user.password" >
                    </div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <button class="btn btn-primary enter-btn m-3" (click)="login()">GİRİŞ YAP</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
    [bdColor]=loadingSpinnerBdColor
    [size]=loadingSpinnerSize
    [color]=loadingSpinnerColor
    [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
