import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getSales(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'sales-firm/', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueFirm(page: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-firm/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueFirmKindType(type: number, page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-firm-kind-type/' + type + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueFirmAutomatStatus(status: number, page: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-firm-automat-status/' + status + '/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  salesFirmFilter(automats: Array<{ _id: string }>, groupID: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'sales-firm-filter',
        JSON.stringify({automatID: automats, groupID: groupID}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  customSalesFirmFilter(automats: Array<{ _id: string }>, automatGroupId: string, startDate, endDate): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'customSalesFirmFilter',
        JSON.stringify({
          automatID: automats, groupID: automatGroupId,
          startDate: startDate, endDate: endDate
        }), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getPaymentsOnSaleProduct(productId, startDate, endDate): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'getPaymentsOnSaleProduct/' + productId,
        JSON.stringify({startDate: startDate, endDate: endDate}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getAutomatsOnSaleProduct(productId, startDate, endDate, automats): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'getAutomatsOnSaleProduct/' + productId,
        JSON.stringify({startDate: startDate, endDate: endDate, automats: automats}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  getFirmAutomatGroup(group_id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-automat-group/' + group_id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmInfo(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm/getFirmInfo/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateFirmInfo(id: string, data: any): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'firm/updateFirmInfo/' + id, data, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }


  getFirmPaymentSystemsInfo(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm/getPaymentMethods/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  changeFirmPaymentSystemState(id: string, state: boolean, code: number): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'firm/updatePaymentMethods/' + id,
        JSON.stringify({targetCode: code, data: {status: state}}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  updateFirmPaymentSystemsFirms(id: string, data: any, code: number): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'firm/updatePaymentMethods/' + id,
        JSON.stringify({targetCode: code, data: data}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

  createPaymentSytemFirm(id: string, data: any, subType: string): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'firm/createPaymentMethods/' + id,
        JSON.stringify({subMerchantType: subType, data: data}), {headers: this.headers}).pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response;
          }
        }));
  }

}
