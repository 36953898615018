<div class="modal-header">
  <h4 class="modal-title">{{ controlVariables.isInEdit ? 'Kart Paketini Düzenle' : 'Kart Paketi Oluştur' }}</h4>
  <button class="btn btn-success newbtn"
          data-toggle="modal" data-backdrop="true" data-target="#videoModal">
          <i class="fas fa-video mr-4"></i> Bu işlem Nasıl yapılır ?
  </button>
</div>
<div class="modal-body pt-4">
  <form>
    <div class="container">
          <ul ngbNav #nav="ngbNav" class="nav nav-pills kart-paketi-nav modal-tab-bg mb-3">
            <li ngbNavItem>
              <a ngbNavLink>Toplu Kart Paketi</a>
              <ng-template ngbNavContent>
                <div class="container mt-5">
                  <div class="row mb-4 justify-content-center">
                    <form class="w-100" #myForm="ngForm">
                      <div class="flex col-md-12 input-group">
                        <input class="form-control no-border metin-girisi" [(ngModel)]="group.name"
                                #name="ngModel" aria-describedby="basic-addon2"
                                name="name" type="text" placeholder="Kart Paketi Adı">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">Örn : Satış personeli Kartları</span>
                        </div>
                      </div>
                      <div class="d-flex my-5">
                        <div class="container inputField" (click)="uploader.click()" appDragDrop (fileDropped)="onFileDropped($event)">
                          <div class="row inputFieldRow">
                            <input #pickXMLFile type="file"
                                                      (change)="onFileChange($event)"
                                                      #uploader
                                                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                      hidden/>
                            <div class="col">
                              <img src="../../../assets/img/file.svg" alt="excel">
                            </div>
                            <div class="col mt-auto">
                              <h1 class="descriptipn">Excel Dosyası seçin</h1>
                              <p class="descriptipnAlert">{{fileName ? fileName:'Seçilmiş Dosya yok'}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mb-3">
                        <div class="mr-1">
                          <ui-switch #balanceTransfer2 size="small"
                          [checked]="controlVariables.isInEdit ? group.balanceTransfer : true "
                          (change)="checkedBalanceTransfer(balanceTransfer2.checked)"></ui-switch>
                          <span class="ml-1 align-top" (click)="balanceTransfer2.checked = !balanceTransfer2.checked"
                            data-toggle="tooltip" data-placement="top"
                            title="Bu alanı işaretlerseniz ay sonunda kalan bakiyeler hesaplardan silinmeyecektir!">
                                Bakiye devretme
                          </span>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <button class="btn btn-outline-dark mx-1 px-4 text-center" (click)="downloadSampleExcel()">
                            <i class="fas fa-download mr-4"></i> Örnek XLS Dosyası
                          </button>
                        </div>
                        <div class="mr-1">
                          <button #clear (click)="closeModal(myForm)" class="btn btn-outline-dark px-3 text-center">İptal</button>
                        </div>
                        <div class="ml-1" *ngIf="!controlVariables.isInEdit">
                          <button class="btn btn-dark mx-1 px-4 text-right" (click)="handleGroup(balanceTransfer2?.checked, 'excel')">
                              Kart Paketi Oluştur
                          </button>
                        </div>
                        <div class="ml-1" *ngIf="controlVariables.isInEdit">
                          <button class="btn btn-dark mx-1 px-4 text-right" (click)="handleGroup(balanceTransfer2?.checked, 'update')">
                              Kart Paketi Oluştur
                          </button>
                        </div>

                      </div>
                    </form>

                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Tek Kişi Kartı</a>
              <ng-template ngbNavContent>
                <form #mySingleForm="ngForm">
                  <div class="container mt-5">

                    <div class="row mt-5 mb-4">
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="group.name" #name="ngModel" type="text" name="groupName"
                                placeholder="Kart Paketi Adı">
                      </div>
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.name" #name="ngModel" type="text" name="name"
                                placeholder="Kullanıcı Adı">
                      </div>
                    </div>
                    <div class="row mt-3 mb-4">
                      <div class="col-md-4">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.sicilNo" #name="ngModel" type="text" name="sicilNo"
                                placeholder="Sicil Numarası">
                      </div>
                      <div class="col-md-4">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.cardId" #name="ngModel" type="text" name="cardId"
                                placeholder="Kart UID" (ngModelChange)="InputIdChange2($event,true)">
                      </div>
                      <div class="col-md-4">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.cardIdDec" #name="ngModel" type="text" name="cardIdDec"
                                placeholder="Kart UID" (ngModelChange)="InputIdChange2($event,false)">
                      </div>
                    </div>
                    <div class="row mt-3 mb-4">
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.gorev" #name="ngModel" type="text" name="gorev"
                                placeholder="Görev">
                      </div>
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.birim" #name="ngModel" type="text" name="birim"
                                placeholder="Birim">
                      </div>
                    </div>
                    <div class="row mt-3 mb-4">
                      <div class="input-group col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.firmBalance" #name="ngModel" type="number"
                                name="firmBalance" min="0"
                                (keydown)="validatePrice($event)"
                                placeholder="Bakiye">
                        <div class="input-group-append">
                          <span class="input-group-text no-border metin-girisi">₺</span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.explanation1" #name="ngModel" type="text"
                                name="explanation1"
                                placeholder="Açıklama 1">
                      </div>
                    </div>
                    <div class="row mt-3 mb-4">
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.explanation2" #name="ngModel" type="text"
                                name="explanation2"
                                placeholder="Açıklama 2">
                      </div>
                      <div class="col-md-6">
                        <input class="form-control no-border metin-girisi text-center"
                                [(ngModel)]="offlineUser2.explanation3" #name="ngModel" type="text"
                                name="explanation3"
                                placeholder="Açıklama 3">
                      </div>
                    </div>
                    <div class="row mt-2 mb-4 mx-4">
                      <div class="col-6">
                        <ui-switch #balanceTransfer3 size="small"
                        [checked]="controlVariables.isInEdit ? group.balanceTransfer : true "
                        (change)="checkedBalanceTransfer(balanceTransfer3.checked)"></ui-switch>
                        <span class="ml-1 align-top" (click)="balanceTransfer3.checked = !balanceTransfer3.checked"
                          data-toggle="tooltip" data-placement="top"
                          title="Bu alanı işaretlerseniz ay sonunda kalan bakiyeler hesaplardan silinmeyecektir!">
                              Bakiye devretme
                        </span>
                      </div>
                      <div class="col-6 text-right" *ngIf="!controlVariables.isInEdit">
                        <button type="submit" [disabled]="mySingleForm.invalid" class="btn btn-primary" (click)="handleGroup(balanceTransfer3?.checked, 'single')">
                          Kart Paketi Oluştur
                        </button>
                      </div>
                      <div class="col-6 text-right" *ngIf="controlVariables.isInEdit">
                        <button type="submit" [disabled]="mySingleForm.invalid" class="btn btn-primary" (click)="handleGroup(balanceTransfer3?.checked, 'update')">
                          Kart Paketi Oluştur
                        </button>
                      </div>
                    </div>

                  </div>
                </form>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
    </div>
  </form>
</div>

<div id="videoModal" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
        <iframe width="900" height="415"
                src="https://www.youtube.com/embed/Awtpq4A8ZdI?autoplay=0&loop=0&controls=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>

        </iframe>
    </div>
  </div>
</div>
