
<div class="container my-5">
  <div class="row animated fade-in-animation">

    <div class="col">
      <div class="container yuvarlak-kenar kart-aktif" (click)="showArea('active')">
        <div class="row">
          <span class="kart-baslik">{{'SERVICE_MAINTENANCE.ACTIVE_VENDINGS' | translate}}</span>
        </div>
        <div class="row mt-3">

          <div class="col pl-1">
            <img src="../../assets/img/beyaz-aktif.svg">
          </div>
          <div class="col text-center">
            <span class="kart-sayi" >{{automatActiveCounts}}</span>
          </div>
          <div class="col text-right align-self-end">
            <span class="kart-detay">{{'SERVICE_MAINTENANCE.DETAIL' | translate}}</span>
          </div>

        </div>
      </div>
    </div>

    <div class="col">
      <div class="container yuvarlak-kenar kart-ariza" (click)="showArea('error')">
        <div class="row">
          <span class="kart-baslik">{{'SERVICE_MAINTENANCE.DEFECTIVE_VENDINGS' | translate}}</span>
        </div>
        <div class="row mt-3">

          <div class="col pl-1">
            <img src="../../assets/img/beyaz-arizali.svg">
          </div>
          <div class="col text-center">
            <span class="kart-sayi">{{automatFaultCounts}}</span>
          </div>
          <div class="col text-right align-self-end">
            <span class="kart-detay">{{'SERVICE_MAINTENANCE.DETAIL' | translate}}</span>
          </div>

        </div>
      </div>
    </div>

    <div class="col">
      <div class="container yuvarlak-kenar kart-guncelleme" (click)="showArea('update')">
        <div class="row">
          <span class="kart-baslik">{{'SERVICE_MAINTENANCE.UPDATE_REQUIRED' | translate}}</span>
        </div>
        <div class="row mt-3">

          <div class="col pl-1">
            <img src="../../assets/img/beyaz-guncelleme.svg">
          </div>
          <div class="col text-center">
            <span class="kart-sayi">{{automatNeedUpdateCounts}}</span>
          </div>
          <div class="col text-right align-self-end">
            <span class="kart-detay">detay</span>
          </div>

        </div>
      </div>
    </div>

    <div class="col">
      <div class="container yuvarlak-kenar kart-serviste" (click)="showArea('service')">
        <div class="row">
          <span class="kart-baslik">{{'SERVICE_MAINTENANCE.SERVICE_CONTINUES' | translate}}</span>
        </div>
        <div class="row mt-3">

          <div class="col pl-1">
            <img src="../../assets/img/beyaz-serviste.svg">
          </div>
          <div class="col text-center">
            <span class="kart-sayi">{{automatOnRepairCounts}}</span>
          </div>
          <div class="col text-right align-self-end">
            <span class="kart-detay">detay</span>
          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="row mt-2 animated lightSpeedIn justify-content-end" *ngIf="returnCalendarButton">
    <button (click)="showArea('calendar')"
    class="btn btn-takvime-don">
      <img src="../../assets/img/sola-ok.svg" width="26">
      {{'SERVICE_MAINTENANCE.BACK_TO_CALENDAR' | translate}}
    </button>
  </div>

  <div class="row mt-2 animated fade-in-animation" *ngIf="calendarArea">

    <div class="col-md-8">
      <mwl-calendar-month-view
        [weekStartsOn]="1"
        [activeDayIsOpen]="activeDayIsOpen"
        [viewDate]="viewDate"
        [refresh]="refresh"
        [locale]="'tr'"
        (dayClicked)="dayClicked($event.day)"
        [events]="events">
      </mwl-calendar-month-view>
    </div>

    <div class="col-md mt-4">
      <div class="container my-2 otomat-notify-baslik">

        <div class="row round-background justify-content-center p-2 mb-2">
          <div class="col-3">
            <div mwlCalendarPreviousView (click)="previousMonth()"
               [view]="view"
               [(viewDate)]="viewDate"
               (viewDateChange)="activeDayIsOpen = false">
              <img src="../../assets/img/sola-ok.svg"></div>
          </div>
          <div class="col p-0 text-center align-self-center">
            <span class="ariza-ay-text">{{ viewDate.getDate() }} {{ viewDate | calendarDate:(view + 'ViewTitle'):'tr'}}</span>
          </div>
          <div class="col-3">
            <div mwlCalendarNextView (click)="nextMonth()"
               [view]="view"
               [(viewDate)]="viewDate"
               (viewDateChange)="activeDayIsOpen = false">
              <img src="../../assets/img/saga-ok.svg"></div>
          </div>
        </div>
        <div class="pr-3" style="overflow-y: scroll;height: 450px !important;">
          <div  *ngFor="let issue of issues" class="ariza-round-background ariza-notify-error mb-2 pl-3 pr-3">
            <div class="row ariza-notify-error-bg p-2">
              <img class="align-self-center" src="../../assets/img/uyari.svg" width="29" height="29">
              <div class="col-md align-self-center">
                {{issue.data.automat.name}}
              </div>
              <div class="col-md align-self-center text-right p-0">
                {{issue.data.updatedAt | date: 'MM.dd.yyyy HH:mm'}}
              </div>
            </div>
            <div class="row ariza-notify-error-bg pl-2 text-danger">
              <div class="col-md align-self-center">
                {{issue.count}} Sorun
              </div>
              <div *ngIf="issue.date !== '-'" class="col-md align-self-center text-right p-2">
                {{issue.date | date: 'MM.dd.yyyy HH:mm'}}
              </div>
              <div *ngIf="issue.date === '-'" class="col-md align-self-center text-right p-2">
                {{issue.date}}
              </div>
            </div>
            <div class="row ariza-notify-error-bg p-2">
              <div class="col-md align-self-center">
                {{'SERVICE_MAINTENANCE.TOTAL' | translate}} {{issue.data.automatIssue.length}} {{'SERVICE_MAINTENANCE.HOW_MANY_PROBLEMS' | translate}}.
              </div>
              <div class="col-md align-self-center text-right p-0">
                <button
                  (click)="getIssue(issue.data._id)"
                  class="btn btn-detail-sale-report-error">Detay</button>
                <button style="display:none;" data-toggle="modal" data-backdrop="false" data-target="#arizaDetay" #openIssueDetail></button>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="row ariza-round-background ariza-notify-warn p-3 mb-2">

          <img class="align-self-center" src="../../assets/img/uyari.svg" width="29" height="29">
          <div class="col-md-6 ml-3 align-self-center">
            PARA HAZNESİNDE ARIZA
          </div>
          <div class="col-md align-self-center text-right p-0">
            <img class="mx-2" src="../../assets/img/aktif-otomat.svg" width="24" height="24">
          </div>
          <div class="col-md align-self-center text-right p-0">
            11:45
          </div>

        </div>

        <div class="row ariza-round-background ariza-notify-error p-3 mb-2">

          <img class="align-self-center" src="../../assets/img/uyari.svg" width="29" height="29">
          <div class="col-md-6 ml-3 align-self-center">
            PARA HAZNESİNDE ARIZA
          </div>
          <div class="col-md align-self-center text-right p-0">
            <img class="mx-2" src="../../assets/img/aktif-otomat.svg" width="24" height="24">
          </div>
          <div class="col-md align-self-center text-right p-0">
            11:45
          </div>

        </div>-->

      </div>
    </div>

  </div>

  <div class="row animated fadeIn" *ngIf="activeAutomatsArea">
    <div class="container">
      <div class="row">
        <span class="aktif-otomatlar-title">AKTİF OTOMATLAR</span>
      </div>
      <hr style="margin: 10px -15px">
      <div
        *ngFor="let activeAutomat of activeAutomats"
        class="row bilgi-satiri my-2">
        <div class="col-1 align-self-center">
          <img src="../../assets/img/aktif-otomat.svg" width="25">
        </div>
        <div class="col-4 align-self-center">
          <span class="bilgi-satiri-baslik">{{'SERVICE_MAINTENANCE.VENDING_ACTIVE_SALES' | translate}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="bilgi-satiri-text">{{activeAutomat.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="bilgi-satiri-text">{{activeAutomat.locationText}}</span>
        </div>
        <div class="col">
          <button class="btn otomat-detay-button" (click)="changeAutomatId(activeAutomat?._id)">
            {{'SERVICE_MAINTENANCE.VENDING_DETAIL' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>

  <div class="row animated fadeIn" *ngIf="errorAutomatsArea">
    <div class="container">
      <div class="row">
        <span class="arizali-otomatlar-title">ARIZALI OTOMATLAR</span>
      </div>
      <hr style="margin: 10px -15px">
      <div
        *ngFor="let faultAutomat of faultAutomats"
        class="row bilgi-satiri my-2">
        <div class="col-1 align-self-center">
          <img src="../../assets/img/otomat-arizali.svg" width="25">
        </div>
        <div class="col-4 align-self-center">
          <span class="bilgi-satiri-baslik">{{faultAutomat?.automatModel?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="bilgi-satiri-text">{{faultAutomat.automat?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="personel-isim" *ngIf="faultAutomat?.staffs.length  > 0 ">{{faultAutomat?.staffs[0].name}}</span>
          <span class="bilgi-satiri-text" *ngIf="faultAutomat?.staffs.length  === 0 ">{{faultAutomat.automat?.locationText}}</span>
        </div>
        <div class="col">
          <button *ngIf="faultAutomat?.staffs.length  === 0 " class="btn personel-yonlendir-hata" data-toggle="modal" (click)="setIssue(faultAutomat)"  data-backdrop="false" data-target="#yonlendirmeOlustur">
            {{'SERVICE_MAINTENANCE.DIRECT_STAFF' | translate}}
          </button>
          <button *ngIf="faultAutomat?.staffs.length > 0 " class="btn yonlendirme-yapildi">
            {{'SERVICE_MAINTENANCE.ROUTING_DONE' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
  <div class="row animated fadeIn" *ngIf="updateAutomatsArea">
    <div class="container">
      <div class="row">
        <span class="güncelleme-otomatlar-title">{{'SERVICE_MAINTENANCE.VENDINGS_REQUIRED_TO_UPDATE' | translate}}</span>
      </div>
      <hr style="margin: 10px -15px">
      <div
        *ngFor="let needUpdateAutomat of needUpdateAutomats"
        class="row bilgi-satiri my-2">
        <div class="col-1 align-self-center">
          <img src="../../assets/img/otomat-arizali.svg" width="25">
        </div>
        <div class="col-4 align-self-center">
          <span class="bilgi-satiri-baslik">{{needUpdateAutomat?.kind?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="bilgi-satiri-text">{{needUpdateAutomat.automat?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="personel-isim" *ngIf="needUpdateAutomat?.staffs.length  > 0 ">{{needUpdateAutomat?.staffs[0].name}}</span>
          <span class="bilgi-satiri-text" *ngIf="needUpdateAutomat?.staffs.length  === 0 ">{{needUpdateAutomat.automat?.locationText}}</span>
        </div>
        <div class="col">
          <button *ngIf="needUpdateAutomat?.staffs.length  === 0 " class="btn personel-yonlendir-hata" data-toggle="modal" (click)="setIssue(needUpdateAutomat)"  data-backdrop="false" data-target="#yonlendirmeOlustur">
            Personel Yönlendir
          </button>
          <button *ngIf="needUpdateAutomat?.staffs.length > 0 " class="btn yonlendirme-yapildi">
            Yönlendirme Yapıldı
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row animated fadeIn" *ngIf="serviceAutomatsArea">
    <div class="container">
      <div class="row">
        <span class="serviste-otomatlar-title">{{'SERVICE_MAINTENANCE.VENDINGS_IN_MAINTENANCE' | translate}}</span>
      </div>
      <hr style="margin: 10px -15px">
      <div
        *ngFor="let onRepairAutomat of onRepairAutomats"
        class="row bilgi-satiri my-2">
        <div class="col-1 align-self-center">
          <img src="../../assets/img/otomat-arizali.svg" width="25">
        </div>
        <div class="col-4 align-self-center">
          <span class="bilgi-satiri-baslik">{{onRepairAutomat?.kind?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="bilgi-satiri-text">{{onRepairAutomat.automat?.name}}</span>
        </div>
        <div class="col-2 align-self-center">
          <span class="personel-isim" *ngIf="onRepairAutomat?.staffs.length  > 0 ">{{onRepairAutomat?.staffs[0].name}}</span>
          <span class="bilgi-satiri-text" *ngIf="onRepairAutomat?.staffs.length  === 0 ">{{onRepairAutomat.automat?.locationText}}</span>
        </div>
        <div class="col">
          <button *ngIf="onRepairAutomat?.staffs.length  === 0 " class="btn personel-yonlendir-hata" data-toggle="modal" (click)="setIssue(onRepairAutomat)"  data-backdrop="false" data-target="#yonlendirmeOlustur">
            Personel Yönlendir
          </button>
          <button *ngIf="onRepairAutomat?.staffs.length > 0 " class="btn yonlendirme-yapildi">
            Yönlendirme Yapıldı
          </button>
        </div>
      </div>

    </div>
  </div>

</div>
<!-- Yeni istek oluştur -->
<div class="modal fade" id="arizaDetay" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="container">
          <div class="row justify-content-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row modal-baslik justify-content-center">
            {{issue?.automat?.name}}
          </div>

          <div class="row justify-content-center mb-3">
            <img src="../../assets/img/uyari.svg" height="70">
          </div>
          <div class="row modal-uyari-baslik justify-content-center mb-3">
            {{automatIssue?.kind?.name}}
          </div>
          <div class="row modal-uyari-aciklama font-weight-light justify-content-center">
            {{issue?.automat?._id}} / {{issue?.automat?.locationText}}<br>
            {{automatIssue?.updatedAt | date: 'MM.dd.yyyy HH:mm'}}
          </div>
          <div class="row mt-3 mb-1" style="font-size: 18px; font-weight: bold">
            <div class="col text-center">
              Sorunlar
            </div>
          </div>
          <div class="table-responsive">
            <table class="table" id="main-table">
              <thead>
              <tr>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.FAULT_TYPE' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.DESCRIPTION' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.SPIRAL_NUMBER' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.PRODUCT_NAME' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.REMAINING_PIECES' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.CREATION_TIME' | translate}}</th>
                <th scope="col" class="table-header text-center">{{'SERVICE_MAINTENANCE.LAST_UPDATE' | translate}}</th>
              </tr>
              </thead>

              <tbody>
              <tr *ngFor="let issue of issue?.automatIssue; let i = index" >
                <td class="text-center">{{issue.name}}</td>
                <td class="text-center">{{issue.kind.name}}</td>
                <td *ngIf="issue.automatProduct !== null" class="text-center">{{issue.automatProduct.spiralNumber}}</td>
                <td *ngIf="issue.automatProduct === null" class="text-center">{{'SERVICE_MAINTENANCE.NO' | translate}}</td>
                <td class="text-center">{{issue.productName}}</td>
                <td class="text-center">{{issue.remainingCount}}</td>
                <td class="text-center">{{issue.createdAt | date: 'dd.MM.yyyy HH:mm'}}</td>
                <td class="text-center">{{issue.updatedAt | date: 'dd.MM.yyyy HH:mm'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr/>
          <div class="row mt-2" style="font-size: 18px; font-weight: bold">
            <div class="col text-center">
              {{'SERVICE_MAINTENANCE.RELATED_PERSONNEL' | translate}}
            </div>
          </div>
          <div class="row">
            <div
              *ngIf="issue?.staffs?.length !== 0"
              class="col-md-10 offset-md-1">
              <div
                *ngFor="let staff of issue?.staffs"
                class="yuvarlak-cerceve">
                <div class="container">
                  <div class="row">
                    <div class="col-sm col-md-2 align-self-center">
                      <img *ngIf="staff?.photo === ''" class="rounded-circle" src="../../assets/img/user_image.png" height="50">
                      <img *ngIf="staff?.photo !== ''" class="rounded-circle" src="{{staff?.photo | image: StaffImagePath }}" height="50">
                    </div>
                    <div class="col-sm font-weight-bold align-self-center">
                      {{staff?.name}}
                    </div>
                    <div class="col-sm font-weight-light align-self-center">
                      {{staff?.role?.name}}
                    </div>
                    <div class="col-sm font-weight-bold align-self-center">
                      {{staff?.phone}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="issue?.staffs?.length === 0"
              class="col-md-4 offset-md-4 mt-4 text-center">
              <button class="btn btn-primary modal-buton" data-dismiss="modal" data-toggle="modal" data-backdrop="false" data-target="#yonlendirmeOlustur">Personel Yönlendir</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 offset-md-1 text-right">
              <button class="btn btn-primary modal-buton" data-dismiss="modal">{{'SERVICE_MAINTENANCE.CLOSE' | translate}}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Yeni yönlendirme oluştur -->
<div class="modal fade" id="yonlendirmeOlustur" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="container">
          <div class="row modal-baslik justify-content-center">
            {{'SERVICE_MAINTENANCE.DIRECT_PERSONNEL_TO_THE_VENDING' | translate}}
          </div>

          <div class="row">
            <div class="col"></div>
            <div class="col-8 oval-kutu text-center">
              <p class="m-0" >{{issue?.automat?.name}} {{issue?.kind?.name}}</p>
              <p class="m-0" >{{issue?.automat?._id}} / {{issue?.automat?.locationText}} / {{issue?.createdAt | date: 'MM.dd.yyyy HH:mm'}}</p>
            </div>
            <div class="col"></div>
          </div>
          <div class="row mt-5">
            <div class="col-md-6 offset-md-3">
              <div class="col">
                <select [(ngModel)]="staff._id" class="custom-select select-product select-custom-icon h-100">
                  <option value="-1">{{'SERVICE_MAINTENANCE.SELECT_PERSONNEL' | translate}}</option>
                  <option
                    *ngFor="let stf of staffs;"
                    value="{{ stf._id }}">{{stf.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" style="margin: 50px 15px 5px 15px">
            <div class="col-6">
              <select  [(ngModel)]="issueType"  class="custom-select select-product select-custom-icon mb-2">
                <option value="-1">{{'SERVICE_MAINTENANCE.CHOOSE_SERVICE_TYPE' | translate}}</option>
                <option value="1">{{'SERVICE_MAINTENANCE.FAULT' | translate}}</option>
                <option value="2">{{'SERVICE_MAINTENANCE.FILLING' | translate}}</option>
              </select>
            </div>
            <div class="col-2 px-0 text-center align-self-center">
              <label>
                <input type="checkbox" name="sms">
                {{'SERVICE_MAINTENANCE.SMS' | translate}}
              </label>
            </div>
            <div class="col-2 px-0 text-center align-self-center">
              <label>
                <input type="checkbox" name="whatsapp">
                {{'SERVICE_MAINTENANCE.WHATSAPP' | translate}}
              </label>
            </div>
            <div class="col-2 px-0 text-center align-self-center">
              <label>
                <input type="checkbox" name="email">
                {{'SERVICE_MAINTENANCE.E_MAIL' | translate}}
              </label>
            </div>
          </div>
          <div class="container">
            <textarea class="metin" [(ngModel)]="note" placeholder="Lütfen Personele notunuzu yazınız"></textarea>
          </div>
          <div class="container text-right">
            <button class="btn btn-primary modal-buton" (click)="staffDirect()">{{'SERVICE_MAINTENANCE.SEND_TO_STAFF' | translate}}</button>
            <button class="btn btn-primary modal-buton mb-5" data-dismiss="modal" #closeRedirectPersonal>{{'SERVICE_MAINTENANCE.CANCEL' | translate}}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
