import {PaymentSystemsIyzicoMobil} from './paymentSystemsIyzicoMobil';
import {FormTemplate} from './formTemplate';

export class PaymentSystemsIyzicoMobilPrivateCompany implements PaymentSystemsIyzicoMobil {

  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }
  _id: string;
  status: boolean;
  paymentSystemsFirmId = '';
  paymentSystemsFirmName = '';
  paymentSystemsFirmCode = 4;

  paymentSystemId = '';
  name = '';
  gsmNumber = '';


  legalCompanyTitle: '';
  taxOffice: '';
  email: '';
  iban: '';
  identityNumber: '';
  address: '';

  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'name',
        label: 'İsim',
        value: this.name,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'legalCompanyTitle',
        label: 'Yasal Ünvan',
        value: this.legalCompanyTitle,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'test',
        key: 'gsmNumber',
        label: 'Telefon',
        value: this.gsmNumber,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'taxOffice',
        label: 'Vergi Dairesi',
        value: this.taxOffice,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'email',
        label: 'Email',
        value: this.email,
        required: true
      }),
      new FormTemplate({
        controlType: 'iban',
        type: 'text',
        key: 'iban',
        label: 'IBAN',
        value: this.iban,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'identityNumber',
        label: 'T.C. No',
        value: this.identityNumber,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'address',
        label: 'Adres',
        value: this.address,
        required: true
      }),
    ];

    return template;
  }

}
