import {Injectable} from '@angular/core';
import * as Excel from 'exceljs';
import {Workbook, Worksheet} from 'exceljs';
import * as moment from 'moment';
import * as fs from 'file-saver';
import {AuthenticationService} from '../_services/authentication.service';
import {SalesChartItem} from '../_models/sales';
import {SaleProduct} from '../_models/saleProduct';
import {SalePaymentType} from '../_models/salePaymentType';

@Injectable({
  providedIn: 'root'
})
export class ExportsHelperService {
  username;
  private LocaleOptions = {hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};

  constructor(private authService: AuthenticationService) {
    authService.user$.subscribe(val => {
      if(val)
        this.username = val.name;
    });
  }

  /*SALES*/
  salesExcell(rows: Array<SalesChartItem>, startDate, endDate, filter: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Satış Raporu');

    this.AddSheetHeader(worksheet, 'Şatış Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const temp = [];
      temp.push(data.time);

      temp.push(data.countOfCashTransaction || 0);
      temp.push(data.volumeOfCashTransaction || 0);
      temp.push(data.countOfOnlineCardTransaction || 0);
      temp.push(data.volumeOfOnlineCardTransaction || 0);
      temp.push(data.countOfOfflineCardTransaction || 0);
      temp.push(data.volumeOfOfflineCardTransaction || 0);
      temp.push(data.countOfQrTransaction || 0);
      temp.push(data.volumeOfQrTransaction || 0);
      temp.push(data.countOfMetropolCardQrTransaction || 0);
      temp.push(data.volumeOfMetropolCardQrTransaction || 0);
      temp.push(data.countOfTotalTransaction || 0);
      temp.push(data.volumeOfTotalTransaction || 0);
      temp.push('Türk Lirası');

      dataTable.push(temp);
    });

    const headers = [
      {name: 'Zaman', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Nakit Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Nakit Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Kredi Kartı Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Kredi Kartı Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Ön Ödemeli Kart Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Ön Ödemeli Kart Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'QR Ödemeli Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'QR Ödemeli Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'MetropolCard QR Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'MetropolCard QR Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Toplam Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Toplam Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Para Birimi', filterButton: false, totalsRowLabel: 'Türk Lirası'}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÜrünSatışRaporu', true);

    //2.sayfa
    this.addAutomatsWorksheet(workbook, filter, startDate, endDate);


    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'SR');
  }

  paymentTypesExcell(rows: Array<SalePaymentType>, startDate, endDate, filter: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Ödeme Türleri');

    this.AddSheetHeader(worksheet, 'Otomat Ödeme Türü Dağılımı Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const temp = [];
      temp.push(data.paymentMethodName);
      temp.push(data.volumeOfTotalTransaction);
      temp.push(data.countOfTotalTransaction);
      dataTable.push(temp);
    });

    const headers = [
      {name: 'Ödeme Türü', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Satış Adedi', filterButton: false, totalsRowFunction: 'sum'}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÖdemeRaporu', true);

    //2.sayfa
    this.addAutomatsWorksheet(workbook, filter, startDate, endDate);

    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'OOTDR');
  }

  saleProductsExcell(rows: Array<SaleProduct>, startDate, endDate, filter: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Satış Raporu');

    this.AddSheetHeader(worksheet, 'Şatış Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const temp = [];
      temp.push(data.productName);
      temp.push(data.totalCount);
      temp.push(data.totalVolume);
      temp.push(data.countPercent);
      temp.push(data.volumePercent);
      temp.push('Türk Lirası');


      dataTable.push(temp);
    });

    const headers = [
      {name: 'Ürün Adı', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Satış Oranı(Adet)', filterButton: false},
      {name: 'Satış Oranı(Fiyat)', filterButton: false},
      {name: 'Para Birimi', filterButton: false, totalsRowLabel: 'Türk Lirası'}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÜrünSatışRaporu', true);


    //2.sayfa
    this.addAutomatsWorksheet(workbook, filter, startDate, endDate);

    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'SR');
  }

  saleProductsDetailExcell(productName, rows: Array<any>, startDate, endDate) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Ürün Raporu');

    this.AddSheetHeader(worksheet, 'Ürün Bazlı Satış Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const temp = [];
      temp.push(productName);
      temp.push(data._id.automat[0].name);
      temp.push(data.amount);
      temp.push(data.count);
      dataTable.push(temp);
    });

    const headers = [
      {name: 'Ürün Adı', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Otomat Adı', filterButton: true},
      {name: 'Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Satış Adedi', filterButton: false, totalsRowFunction: 'sum'}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÜrünRaporu', true);

    worksheet.state = 'visible';

    this.ExportFileExcel(workbook, 'UBSR');

  }

  saleDistExcel(rows: Array<any>, startDate, endDate, filter: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Satış Raporu');

    this.AddSheetHeader(worksheet, 'Şatış Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const temp = [];
      /* temp.push(data.name);
       temp.push(data.count || 0);
       temp.push(data.value || 0);*/

      temp.push(moment(data.date).format('DD.MM.YYYY HH:mm'));
      temp.push(data.countOfTotalTransaction || 0);
      temp.push(data.volumeOfTotalTransaction || 0);

      temp.push('Türk Lirası');


      dataTable.push(temp);
    });

    const headers = [
      {name: 'Zaman', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Satış Adedi', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Ciro', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Para Birimi', filterButton: false, totalsRowLabel: 'Türk Lirası'}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÜrünSatışRaporu', true);

    //2.sayfa
    this.addAutomatsWorksheet(workbook, filter, startDate, endDate);


    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'SR');
  }

  /*SALES-End*/


  salesReportDownloadExcel(rows: Array<any>, startDate, endDate) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Ürün Raporu');

    this.AddSheetHeader(worksheet, 'Ürün Bazlı Ayrıntılı Satış Raporu', startDate, endDate, this.username);

    //data
    const dataTable = [];
    if (rows.length !== 0) {
      rows.forEach(data => {
        const temp = [];
        temp.push(data._id.product.product.length > 0 ? data._id.product.product[0].name : '');
        temp.push(data._id.product.spiralNumber === undefined ? '' : data._id.product.spiralNumber);
        temp.push(moment(data._id.d).format('DD.MM.YYYY HH:mm'));
        temp.push(data._id.automat[0].name);
        temp.push(this.getPaymentTypeText(data._id.payment[0].paymentType));
        temp.push(data._id.product.isDiscountApplied ? 'Evet' : 'Hayır');
        temp.push(data._id.product.count);
        temp.push(data._id.product.price);
        temp.push(data.amount);
        temp.push('Türk Lirası');
        temp.push(this.getResultText(data._id.payment[0].isDelivered, data._id.payment[0].isCompleted, data._id.payment[0].isRefund));
        dataTable.push(temp);
      });
    }
    if (dataTable.length === 0) {
      dataTable.push(['', '', '', '', '', '', '', '', '', '', '']);
    }
    const headers = [
      {name: 'Ürün Adı', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Spiral Numarası', filterButton: true},
      {name: 'Tarih', filterButton: false},
      {name: 'Otomat', filterButton: true},
      {name: 'Satış Yönetimi', filterButton: true},
      {name: 'İndirimli', filterButton: true},
      {name: 'Satış Adet', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Tutar', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Toplam Tutar', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Para Birimi', filterButton: false, totalsRowLabel: 'Türk Lirası'},
      {name: 'Sonuç', filterButton: false}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'ÜrünRaporu', true);

    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'UBASR');
  }

  basketReportDownloadExcel(rows: Array<any>, startDate, endDate) {

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sepet Raporu');

    this.AddSheetHeader(worksheet, 'Sepet Bazlı Satış Raporu', startDate, endDate, this.username);

    //data
    const dataTable = [];
    if (rows.length !== 0) {
      rows.forEach(data => {
        const date = new Date(data.date);
        const temp = [];
        temp.push('Müşteri');
        temp.push(data._id.payment[0].basketHistory);
        temp.push(date.toLocaleString(undefined, this.LocaleOptions));
        temp.push(data._id.automat[0].name);
        temp.push(this.getPaymentTypeText(data._id.payment[0].paymentType));
        temp.push(data.amount);
        temp.push('Türk Lirası');
        temp.push(this.getResultText(data._id.payment[0].isDelivered, data._id.payment[0].isCompleted, data._id.payment[0].isRefund));
        dataTable.push(temp);
      });
    }
    if (dataTable.length === 0) {
      dataTable.push(['', '', '', '', '', '', '', '']);
    }
    const headers = [
      {name: 'Kullanıcı Adı', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Sepet ID', filterButton: false},
      {name: 'Tarih', filterButton: true},
      {name: 'Otomat', filterButton: true},
      {name: 'Satış Yönetimi', filterButton: true},
      {name: 'Toplam Tutar', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Para Birimi', filterButton: false},
      {name: 'Sonuç', filterButton: false}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'SepetRaporu', true);

    worksheet.state = 'visible';

    this.ExportFileExcel(workbook, 'SBSR');

  }

  proceedsDownloadExcel(rows: Array<any>, startDate, endDate) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Tahsilat Raporu');

    this.AddSheetHeader(worksheet, 'Tahsilat Raporu', startDate, endDate, this.username);

    const dataTable = [];
    rows.forEach(data => {
      const startDate = moment(data.createdAt.toString()).format('DD.MM.YYYY HH:mm');
      const endDate = moment(data.endedAt.toString()).format('DD.MM.YYYY HH:mm');
      const temp = [];

      let personel = 'Yok';
      let creditCard = 0;
      let cash = 0;
      let offlineCard = 0;

      if (data.closedPersonel === null && data.closedTelemetryUser !== null) {
        personel = data.closedTelemetryUser.name;
      } else if (data.closedPersonel !== null && data.closedTelemetryUser === null) {
        personel = data.closedPersonel.name;
      }
      data.payments.forEach((payment, index) => {
        if (payment.paymentType === 1) {
          creditCard = payment.totalPrice;
        } else if (payment.paymentType === 2) {
          cash = payment.totalPrice;
        } else if (payment.paymentType === 3) {
          offlineCard = payment.totalPrice;
        }
      });

      temp.push(data.automat ? data.automat.name : 'Silinmiş Otomat');
      temp.push(data.automatGroup ? data.automatGroup.name : 'Silinmiş Group');
      temp.push(data.headStart);
      temp.push(data.filledCoins);
      temp.push(data.filledBanknote);
      temp.push(creditCard);
      temp.push(cash);
      temp.push(offlineCard);
      temp.push(data.totalPrice);
      temp.push(personel);
      temp.push(startDate);
      temp.push(endDate);

      dataTable.push(temp);
    });
    const headers = [
      {name: 'Otomat Adı', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Grup Adı', filterButton: true},
      {name: 'Avans', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Avans (Metal)', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Avans (Kağıt)', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Kredi Kartı Ciro', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Nakit Ciro', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Offline Kart Ciro', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Toplam Ciro', filterButton: true, totalsRowFunction: 'sum'},
      {name: 'Personel', filterButton: true},
      {name: 'Başlangıç Tarihi', filterButton: false},
      {name: 'Tahsilat Tarihi', filterButton: true}
    ];

    this.AddSheetTable(worksheet, dataTable, headers, 'TahsilatRaporu', true);

    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'TR');

  }

  cardPacketsDownloadExcel(rows: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Kart Raporu');

    this.AddSheetHeader(worksheet, 'Kart Kullanıcıları Raporu', '-', '-', this.username);

    const dataTable = [];
    rows.forEach((data) => {
      if (data.offlineUserGroups.length) {
        const temp = [];
        temp.push(data.name);
        temp.push(data.cardId);
        temp.push(data.sicilNo);
        temp.push(data.gorev);
        temp.push(data.birim);
        temp.push(data.firmBalance);
        temp.push(data.explanation1);
        temp.push(data.explanation2);
        temp.push(data.explanation3);
        temp.push(data.offlineUserGroups[0].name);

        dataTable.push(temp);
      }
    });
    const headers = [
      {name: 'Ad Soyad', filterButton: true, totalsRowLabel: 'Toplam:'},
      {name: 'Kart Bilgisi', filterButton: false},
      {name: 'Sicil Numarası', filterButton: false},
      {name: 'Görev', filterButton: true},
      {name: 'Birim', filterButton: true},
      {name: 'Bakiye', filterButton: false, totalsRowFunction: 'sum'},
      {name: 'Açıklama 1', filterButton: true},
      {name: 'Açıklama 2', filterButton: true},
      {name: 'Açıklama 3', filterButton: true},
      {name: 'Grup', filterButton: false}
    ];


    this.AddSheetTable(worksheet, dataTable, headers, 'KartRaporu', true);

    worksheet.state = 'visible';
    this.ExportFileExcel(workbook, 'KKR');
  }

  sampleDownloadExcel(){
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");

    worksheet.columns = [
      {header: 'John Doe (İsim Soyisim)', key: 'key1', width: 35},
      {header: '12AB3244 (Kart No)', key: 'key2', width: 35},
      {header: '313209412 (Kart No)', key: 'key3', width: 35,},
      {header: '1233 (Sicil No)', key: 'key4', width: 35,},
      {header: 'Mühendis (Görev)', key: 'key5', width: 35,},
      {header: 'Ar-Ge (Birim)', key: 'key6', width: 35,},
      {header: '100 (Bakiye)', key: 'key7', width: 35,},
      {header: 'Yorum Yok (Açıklama 1)', key: 'key8', width: 35,},
      {header: 'Yorum Yok (Açıklama 2)', key: 'key9', width: 35,},
      {header: 'Yorum Yok (Açıklama 3)', key: 'key10', width: 35,},
    ];

  // save under export.xlsx
  this.ExportFileExcel(workbook, 'ornekdosya');
  }

  offlineCardsDownloadExcel(rows: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Kart İşlem Raporu');

    this.AddSheetHeader(worksheet, 'Kart İşlem Geçmişi Raporu', '-', '-', this.username);

    const dataTable = [];
    rows.forEach(data => {
      const date = new Date(this.getTransactionDate(data));
      // noinspection JSNonASCIINames
      const temp = [];
      temp.push(data.name);
      temp.push(date.toLocaleDateString(undefined, this.LocaleOptions));
      temp.push(this.isEmpty(data.automat) || data.automat === undefined ? '' : data.automat.name);
      temp.push(data.spiralNumber);
      temp.push(this.isEmpty(data.automatProduct) || data.automatProduct === undefined ||
      this.isEmpty(data.automatProduct.product) ? '' : data.automatProduct.product.name);
      temp.push(this.getTransactionProcessTypeText(data.processType));
      temp.push(this.getTransactionAmountText(data.processType, data.total));
      temp.push(this.round(data.firmBalance + data.balance, 2));
      temp.push('Türk Lirası');

      dataTable.push(temp);
    });

    const headers = [
      {name: 'Kart Sahibi', filterButton: false},
      {name: 'İşlem Tarihi', filterButton: true},
      {name: 'Otomat', filterButton: true},
      {name: 'Spiral Numarası', filterButton: true},
      {name: 'Ürün Adı', filterButton: true},
      {name: 'İşlem Türü', filterButton: true},
      {name: 'Fiyat', filterButton: false},
      {name: 'Bakiye', filterButton: true},
      {name: 'Para Birimi', filterButton: false},
    ];
    this.AddSheetTable(worksheet, dataTable, headers, 'KartRaporu', false);

    worksheet.state = 'visible';

    this.ExportFileExcel(workbook, 'KIGR');

  }

  offlineCardGroupDownloadExcel(rows: Array<any>) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Kart Grubu Raporu');

    this.AddSheetHeader(worksheet, 'Kart Grubu Raporu', '-', '-', this.username);

    const dataTable = [];
    rows.forEach(data => {
      const date = new Date(this.getTransactionDate(data));
      // noinspection JSNonASCIINames
      const temp = [];
      temp.push(data.name);
      temp.push(data.sicilNo);
      temp.push(data.gorev);
      temp.push(data.birim);
      temp.push(data.cardId);
      temp.push(data.cardIdDec);
      temp.push(data.createdAt);
      temp.push(data.firmBalance);
      temp.push(data.balance);
      if (data.status === 1) {
        temp.push('AKTİF');
      } else {
        temp.push('PASİF');
      }

      dataTable.push(temp);
    });

    const headers = [
      {name: 'Ad Soyad', filterButton: false},
      {name: 'Sicil No', filterButton: false},
      {name: 'Görev', filterButton: false},
      {name: 'Birim', filterButton: false},
      {name: 'Kart UID Hex', filterButton: false},
      {name: 'Kart UID Dec', filterButton: false},
      {name: 'Oluşturma Zamanı', filterButton: false},
      {name: 'Firma Bakiyesi', filterButton: false},
      {name: 'Bakiye', filterButton: false},
      {name: 'Durum', filterButton: false},
    ];
    this.AddSheetTable(worksheet, dataTable, headers, 'KartGrubuRaporu', false);

    worksheet.state = 'visible';

    this.ExportFileExcel(workbook, 'KartGrubu');

  }

  offlineCardPeriodDownloadExcel(rowsFirst: Array<any>, headers, startDate, endDate, rowsSecond: Array<any>) {
    const workbook = new Excel.Workbook();
    const firstWorksheet = workbook.addWorksheet('Periyot');
    this.AddSheetHeader(firstWorksheet, 'Offline User Grubu Periyot Raporu', startDate, endDate, this.username);

    const tableData = [];
    for (let i = 0; i < rowsFirst.length; i++) {
      const temp = [];
      if (headers.find(x => x.id === 1).selected) {
        temp.push(rowsFirst[i].name);
      }
      if (headers.find(x => x.id === 2).selected) {
        temp.push(rowsFirst[i].cardId);
      }
      if (headers.find(x => x.id === 14).selected) {
        temp.push(rowsFirst[i].cardIdDec);
      }
      if (headers.find(x => x.id === 3).selected) {
        temp.push(rowsFirst[i].status === 1 ? 'Aktif' : 'Pasif');
      }
      if (headers.find(x => x.id === 4).selected) {
        temp.push(rowsFirst[i].sicilNo);
      }
      if (headers.find(x => x.id === 5).selected) {
        temp.push(rowsFirst[i].gorev);
      }
      if (headers.find(x => x.id === 6).selected) {
        temp.push(rowsFirst[i].birim);
      }
      if (headers.find(x => x.id === 7).selected) {
        temp.push(rowsFirst[i].explanation1);
      }
      if (headers.find(x => x.id === 8).selected) {
        temp.push(rowsFirst[i].explanation2);
      }
      if (headers.find(x => x.id === 9).selected) {
        temp.push(rowsFirst[i].explanation3);
      }
      if (headers.find(x => x.id === 10).selected) {
        temp.push(parseFloat(this.calOfflineUserTransactionData(rowsFirst[i].transaction_data, 6)));
      }
      if (headers.find(x => x.id === 11).selected) {
        temp.push(parseFloat(this.calOfflineUserTransactionData(rowsFirst[i].transaction_data, 10)));
      }
      if (headers.find(x => x.id === 12).selected) {
        temp.push(parseFloat(this.calOfflineUserTransactionData(rowsFirst[i].transaction_data, 2)));
      }
      if (headers.find(x => x.id === 13).selected) {
        temp.push(parseFloat(rowsFirst[i].firmBalance));
      }

      tableData.push(temp);

    }
    const header = headers.filter(x => x.selected).map(function (x: any) {
      const ret: any = {
        name: x.name,
        filterButton: true,
      };
      if (x.id === 1 || x.id === 2 || x.id === 4 || x.id === 11 || x.id === 12 || x.id === 13 || x.id === 14) {
        ret.filterButton = false;
      }
      if (x.id === 10 || x.id === 11 || x.id === 12 || x.id === 13) {
        ret.totalsRowFunction = 'sum';
      }
      return ret;
    });

    this.AddSheetTable(firstWorksheet, tableData, header, 'Periyot', true);


    //2.sayfa
    const secondWorksheet = workbook.addWorksheet('İşlem Geçmişi');
    this.AddSheetHeader(secondWorksheet, 'Offline User Grubu İşlem Geçmişi', startDate, endDate, this.username);

    const transactionTable = [];
    if (rowsSecond.length !== 0) {
      rowsSecond.forEach(tData => {
        if (tData.processType === 1 || tData.processType === 2 ||
          tData.processType === 6 || tData.processType === 10 || tData.processType === 12
        ) {

          const date = new Date(this.getTransactionDate(tData));
          const temp = [];
          temp.push(tData.name);
          temp.push(tData.cardNumber);
          temp.push(date.toLocaleDateString(undefined, this.LocaleOptions));
          temp.push((this.isEmpty(tData.automat) || !tData.automat) ? ' ' : tData.automat.name);
          temp.push(!tData.spiralNumber ? ' ' : tData.spiralNumber);
          temp.push((this.isEmpty(tData.automatProduct) || !tData.automatProduct ||
            this.isEmpty(tData.automatProduct.product)) ? ' ' : tData.automatProduct.product.name);
          temp.push(this.getTransactionProcessTypeText(tData.processType));
          temp.push(tData.total);
          temp.push(tData.firmBalance);
          temp.push(tData.balance);
          temp.push('Türk Lirası');

          transactionTable.push(temp);
        }
      });
    }
    if (transactionTable.length === 0) {
      transactionTable.push(['', '', '', '', '', '', '', '', '', '']);
    }
    const columns = [
      {name: 'Ad Soyad', filterButton: true},
      {name: 'Kart Numarası', filterButton: true},
      {name: 'İşlem Tarihi', filterButton: true},
      {name: 'Otomat', filterButton: true},
      {name: 'Spiral No', filterButton: false},
      {name: 'Ürün', filterButton: true},
      {name: 'İşlem Türü', filterButton: true},
      {name: 'Miktar', filterButton: false},
      {name: 'Firma Bakiyesi', filterButton: false},
      {name: 'Kullanıcı Bakiyesi', filterButton: false},
      {name: 'Para Birimi', filterButton: false}
    ];

    this.AddSheetTable(secondWorksheet, transactionTable, columns, 'İşlemGeçmişi', false);

    this.ExportFileExcel(workbook, 'OUGPR');
  }


  private getPaymentTypeText(type): string {
    switch (type) {
      case 1:
        return 'Kredi Kart';
      case 2:
        return 'Nakit';
      case 3:
        return 'Offline Kart';
      case 4:
        return 'İstanbul Kart';
      case 5:
        return 'QR';
      case 6:
        return 'MetropolCard QR';
      default:
        return '-';
    }
  }

  private getResultText(isDelivered: boolean, isCompleted: boolean, isRefund: boolean): string {
    if (isDelivered) {
      return 'Tamamlandı';
    }
    if (isCompleted) {
      return 'Devam Ediyor';
    }
    if (isRefund) {
      return 'İptal';
    }
  }

  private getTransactionAmountText(type, amount) {
    switch (type) {
      case 0:
      case 1:
      case 4:
      case 6:
      case 10:
      case 11:
      case 12:
        return this.round(amount, 2); // + ' ₺';
      case 2:
        return this.round(amount, 2); // + ' ₺';
      case 3:
      case 5:
      case 7:
        return 'Sıfırlama';
      default:
        return '-';

    }
  }

  private getTransactionProcessTypeText(type) {
    switch (type) {
      case 0:
        return 'İade';
      case 1:
        return 'Yükleme';
      case 2:
        return 'Harcama';
      case 3:
        return 'Sıfırlama';
      case 4:
        return 'Toplu Yükleme';
      case 5:
        return 'Toplu Sıfırlama';
      case 6:
        return 'Periyodik Yükleme';
      case 7:
        return 'Periyodik Sıfırlama';
      case 10:
        return 'Devreden';
      case 11:
        return 'Düşüm';
      case 12:
        return 'Manuel Yükleme';
      default:
        return '-';

    }
  }

  private getTransactionDate(transaction) {
    return (transaction.saleDate === null || transaction.saleDate === undefined) ? transaction.createdAt : transaction.saleDate;
  }

  private round(value, decimals) {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals).valueOf();
  }

  isEmpty(obj): boolean {

    // null and undefined are "empty"
    if (obj == null) {
      return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') {
      return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
      if (this.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return true;
  }

  private calOfflineUserTransactionData(transaction_data, processType: number) {
    if (transaction_data.length > 0) {
      return transaction_data.filter(x => x.processType === processType)
        .map(x => x.total)
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    } else {
      return 0;
    }
  }


  private AddSheetHeader(worksheet: Worksheet, type, startDate, endDate, username) {

    const titleRow = worksheet.addRow(['TELEMETRY']);
    titleRow.font = {name: 'Sony Sketch EF', size: 20, underline: 'none', bold: true, color: {argb: 'FFFF0000'}};
    titleRow.alignment = {horizontal: 'center', vertical: 'middle'};

    worksheet.addRow([]);
    const typeROW = worksheet.addRow(['Rapor Türü:', type]);

    const sDateROW = worksheet.addRow(['Başlagıç Tarihi:', startDate]);
    sDateROW.getCell(3).value = 'Rapor Tarihi:';
    sDateROW.getCell(4).value = moment(Date.now()).format('DD-MM-YYYY HH:mm');

    const eDateROW = worksheet.addRow(['Bitiş Tarihi:', endDate]);
    eDateROW.getCell(3).value = 'Yetkili:';
    eDateROW.getCell(4).value = username;

    //styles
    typeROW.getCell(1).font = {bold: true};
    sDateROW.getCell(1).font = {bold: true};
    sDateROW.getCell(3).font = {bold: true};
    eDateROW.getCell(1).font = {bold: true};
    eDateROW.getCell(3).font = {bold: true};


  }

  private AddSheetTable(worksheet: Worksheet, rows, columns, name, bTotalsRow: boolean) {
    worksheet.addTable({
      name: name,
      ref: 'A6',
      headerRow: true,
      totalsRow: bTotalsRow,
      style: {
        theme: 'TableStyleMedium17',
        showRowStripes: true,
      },
      columns: columns,
      rows: rows,
    });
    this.AdjustCellSizes(worksheet);
  }

  private addAutomatsWorksheet(workbook: Excel.Workbook, filter: Array<any>, startDate, endDate) {

    const secondWorksheet = workbook.addWorksheet('Otomatlar');
    this.AddSheetHeader(secondWorksheet, 'Raporda Bulunan Otomatlar', startDate, endDate, this.username);

    const dataTable2 = [];
    filter.forEach(automat => {
      const temp = [];
      temp.push(automat.name);
      temp.push(automat.group ? automat.group.name : '-');
      dataTable2.push(temp);
    });


    const headers2 = [
      {name: 'Otomat', filterButton: true},
      {name: 'Grup', filterButton: true}
    ];


    this.AddSheetTable(secondWorksheet, dataTable2, headers2, 'Otomatlar', false);

  }


  private AdjustCellSizes(worksheet: Worksheet) {
    for (let i = 0; i < worksheet.columns.length; i++) {
      let dataMax = 0;
      const cell = worksheet.columns[i];
      for (let j = 6; j < cell.values.length; j++) {
        if (cell.values[j] !== undefined) {
          const cellLength = cell.values[j].toString().length;
          if (cellLength > dataMax) {
            dataMax = cellLength;
          }
        }
      }
      cell.width = dataMax < 12 ? 12 : dataMax;
    }
    worksheet.mergeCells('A1:' + worksheet.getColumn(worksheet.actualColumnCount).letter + '1');
  }

  private FileNameExtension(): string {
    return moment(Date.now()).format('YYYY-MM-DD_HH-mm');
  }

  private ExportFileExcel(workbook: Workbook, filename: string) {
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, 'TELEMETRY_' + filename + '_Rapor_' + this.FileNameExtension() + '.xlsx');
    });
  }

}
