<div class="container-fluid top-bg pt-4 pb-3">
  <div class="row animated fadeInDown">
    <div class="col-md-2 col-lg-2 col-xl-2 align-self-center dateChoose">
      <span class="row no-gutters datelabel">{{'PRODUCT_REPORT.REPORT_INTERVAL' | translate}}</span>
      <div class="row no-gutters">
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="selectedDay"
              (ngModelChange)="changeTimeTab()" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" #dropdownFilter type="button" id="dropdownFilter"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{'PRODUCT_REPORT.APPLY_FILTER' | translate}}
          </button>
          <div style="position: absolute; top:40px;">
            <div #filterMenu class="dropdown-menu" aria-labelledby="dropdownFilter">
              <form>
                <div class="container p-4">
                  <div class="row">
                    <div class="col">
                      <select [(ngModel)]="filter.district" name="district"
                        class="custom-select select-product-modal select-custom-icon">
                        <option value="">{{'PRODUCT_REPORT.REGION' | translate}}</option>
                        <option *ngFor="let automatDistrict of automatDistricts;" value="{{ automatDistrict?._id }}">
                          {{automatDistrict?._id}}</option>
                      </select>
                      <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.productName"
                        #productName="ngModel" type="text" name="productName" placeholder="Ürün Adı / ID">
                      <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.productPrice"
                        (keydown)="validatePrice($event)" #productName="ngModel" type="number" min="0"
                        name="productPrice" placeholder="Ürün Fiyatı">
                      <select [(ngModel)]="filter.paymentType" name="paymentType"
                        class="custom-select select-product-modal select-custom-icon mt-4">
                        <option value="-1">{{'PRODUCT_REPORT.PAYMENT_METHOD' | translate}}</option>
                        <option value="1">{{'PRODUCT_REPORT.CREDIT_CARD' | translate}}</option>
                        <option value="2">{{'PRODUCT_REPORT.CASH' | translate}}</option>
                        <option value="3">{{'PRODUCT_REPORT.PRE_PAID_CARD' | translate}}</option>
                        <option value="6">{{'PRODUCT_REPORT.METROPOLCARD_QR' | translate}}</option>                        
                      </select>
                    </div>
                    <!-- Divider -->
                    <div class="col">
                      <select [(ngModel)]="filter.group" (change)="getAutomatNameID($event)" name="group"
                        class="custom-select select-product-modal select-custom-icon">
                        <option value="">{{'PRODUCT_REPORT.VENDING_GROUP' | translate}}</option>
                        <option *ngFor="let automatGroup of automatGroups;" value="{{ automatGroup._id }}">
                          {{automatGroup.name}}</option>
                      </select>
                      <ng-select id="select4" name="automat" [items]="automatNameIDs" [multiple]="true"
                        [searchable]="true" [searchFn]="searchAutomat" [closeOnSelect]="false" [hideSelected]="true"
                        bindLabel="name" bindValue="" placeholder="{{'PRODUCT_REPORT.VENDINGS' | translate}}"
                        [virtualScroll]="true" [(ngModel)]="filter.automat">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                          <div class="ng-value" *ngFor="let item of items | slice:0:1">
                            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                            <span class="ng-value-label"> {{item.name}}</span>
                          </div>
                          <div class="ng-value" *ngIf="items.length > 1">
                            <span class="ng-value-label">+ {{items.length - 1}}</span>
                          </div>
                        </ng-template>
                      </ng-select>
                      <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.amount"
                        #amount="ngModel" type="number" min="0" name="amount"
                        placeholder="{{'PRODUCT_REPORT.TOTAL_PRICE' | translate}}">
                      <select [(ngModel)]="filter.resultType" name="resultType"
                        class="custom-select select-product-modal select-custom-icon mt-4">
                        <option value="-1">{{'PRODUCT_REPORT.RESULT_TYPE' | translate}}</option>
                        <option value="1">{{'PRODUCT_REPORT.COMPLETED' | translate}}</option>
                        <option value="2">{{'PRODUCT_REPORT.CONTINUE' | translate}}</option>
                        <option value="3">{{'PRODUCT_REPORT.CANCEL' | translate}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-danger mt-5" (click)="clearFilter()"
                        style="width: 100%">{{'PRODUCT_REPORT.RESET_FILTER' | translate}}
                      </button>
                    </div>
                    <div class="col">
                      <button class="btn btn-success mt-5" (click)="applyFilter()"
                        style="width: 100%">{{'PRODUCT_REPORT.APPLY_FILTER_CAPS' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-9 col-lg-9 col-xl-9 text-center px-0">
      <div class="container-fluid pr-0 pl-4">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-2 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-blue">
                    <img src="../../assets/img/money.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.CASH_SALE' | translate}}</div>
                  <div class="text-amount text-blue">{{ cash?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{cash?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-purple">
                    <img src="../../assets/img/credit-card2.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.CREDIT_CARD' | translate}}</div>
                  <div class="text-amount text-purple">{{ creditCard?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{creditCard?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-red">
                    <img src="../../assets/img/nfc.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.PRE_PAID_CARD' | translate}}</div>
                  <div class="text-amount text-red">{{ PrepaidCard?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{PrepaidCard?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-2 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-cyan">
                    <img src="../../assets/img/qr-code.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.QR' | translate}}</div>
                  <div class="text-amount text-cyan">{{ qrPayment?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{qrPayment?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-2 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-orange">
                    <img src="../../assets/img/restaurant-card.svg" class="restaurant-card mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.METROPOLCARD' | translate}}</div>
                  <div class="text-amount text-orange">{{ metropolCardQrPayment?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{metropolCardQrPayment?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-1 col-lg-1 col-md-1 col-0 px-0 my-xs-1 d-none d-sm-block">
            <div class="Rectangle-835 mt-4"></div>
            <div class="Rectangle-835 mt-4"></div>
          </div>
          <div class="col-xl-1 col-lg-1 col-md-1 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-green">
                    <img src="../../assets/img/Calculator_1_.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left ml-3 my-auto">
                  <div class="text-s-15 text-gray mb-1 mt-1">{{'PRODUCT_REPORT.TOTAL_GIRO' | translate}}</div>
                  <div class="text-amount text-green">{{ total?.amount || 0 | number | commaDotSwapper}}
                    ₺
                  </div>
                  <div class="text-s-15 text-gray mb-1 mt-1">
                    {{total?.count || 0}} {{'PRODUCT_REPORT.PRODUCT_CAPS' | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xl-1">
      <div class="container">
        <div class="row justify-content-end text-center">
          <button (click)="downloadExcel()" [hidden]="!isDownloadReady" class="btn btn-download btn-sm btn-block"
            type="button"><i class="fa fa-download"></i> {{'PRODUCT_REPORT.EXCEL_DOWNLOAD' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive" *ngIf="!loading else loadingRef2">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.PRODUCT_NAME' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.SPIRAL_NUMBER' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.NUMBER_OF_SALES' | translate}}</th>
        <!--<th scope="col" class="table-header">Sepet ID</th>
      <th scope="col" class="table-header">Ürün ID</th>-->
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.TIME' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.VENDING' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.SALES_METHOD' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.DISCOUNT' | translate}}</th>
        <!--<th scope="col" class="table-header">Tutar</th>-->
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.SALES_PRICE' | translate}}</th>
        <th scope="col" class="table-header">{{'PRODUCT_REPORT.RESULT' | translate}}</th>
      </tr>
    </thead>

    <!-- infiniteScrollThrottle milisaniye cinsinden bir değer -->
    <tbody infiniteScroll [infiniteScrollDistance]="pages" [infiniteScrollThrottle]="20" (scrolled)="onScroll()"
      id="table-datas">
      <tr *ngFor="let data of rows" (click)="showDetails(data)">
        <td style="text-align: left">{{data?._id?.product?.product[0]?.name}}</td>
        <td>{{data?._id?.product?.spiralNumber}}</td>
        <td>{{data?._id?.product?.count}}</td>
        <!--<td>{{data?._id?.payment[0]?.basket}}</td>
      <td>{{data?._id?.product?.product[0]?._id}}</td>-->
        <td>{{data?._id?.d | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
        <td>{{data?._id?.automat[0]?.name}}</td>
        <td>{{getPaymentTypeText(data?._id?.payment[0]?.paymentType)}}</td>
        <td>{{data?._id?.product?.isDiscountApplied ? 'Evet' : 'Hayır'}}</td>
        <td class="font-weight-bold">{{data?._id?.product?.price || 0 | number | commaDotSwapper}} ₺</td>
        <td>
          <button *ngIf="data?._id?.payment[0]?.isDelivered" class="btn-okay"><i
              class="fas fa-check mr-1"></i>{{'PRODUCT_REPORT.COMPLETED' | translate}}
          </button>

          <button
            *ngIf="data?._id?.payment[0]?.isCompleted && !data?._id?.payment[0]?.isDelivered && !data?._id?.payment[0]?.isRefund"
            class="btn-process">{{'PRODUCT_REPORT.PRODUCT' | translate}}
          </button>

          <button
            *ngIf="data?._id?.payment[0]?.isCompleted && !data?._id?.payment[0]?.isDelivered && data?._id?.payment[0]?.isRefund"
            class="btn-cancel">{{'PRODUCT_REPORT.CANCEL' | translate}}
          </button>

        </td>
      </tr>
    </tbody>

  </table>
</div>

<div class="container mb-2" id="page-loader" style="display: none">
  <div class="row justify-content-center">
    <img src="../../assets/img/page-loader.svg" width="80">
  </div>
</div>

<a (click)="topFunction()" id="topButton" title="En yukarı çık">
  <img src="../../assets/img/up-arrow.svg" width="36">
</a>

<!--Details Modal START-->
<button style="display: none;" #detailsButton data-toggle="modal" data-backdrop="true" data-target="#details"></button>
<div *ngIf="selectedRow" class="modal fade" id="details" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div *ngIf="!modalLoading else loadingRef" class="container p-2">


          <div class="row justify-content-center">
            <h5>{{'PRODUCT_REPORT.TRANSACTION_DETAILS' | translate}}</h5>
          </div>


          <div class="row">
            <div class="text-muted col-md-2">{{'PRODUCT_REPORT.TRANSACTION_DATE' | translate}}:</div>
            <pre class="m-0">  </pre>
            {{selectedRow._id?.d | date: 'dd.MM.yyyy HH:mm:ss'}}
          </div>
          <div class="row">
            <div class="text-muted col-md-2">{{'PRODUCT_REPORT.RESULT' | translate}}:</div>
            <pre class="m-0">  </pre>
            <div
              *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
              <img width="20px"
                [src]=" (selectedRow._id.telemetryTransaction[0]?.transaction?.completedTransaction == true ||
                            selectedRow._id.telemetryTransaction[0]?.transaction?.cancelTransaction == true) ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg'">

            </div>
          </div>


          <hr />

          <div class="row">
            <div class="col-md-6 container"> <!--sol-->
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.PRODUCT_NAME' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.product?.product[0]?.name}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.SPIRAL_NUMBER' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.product?.spiralNumber}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.NUMBER_OF_SALES' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.product?.count}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.VENDING' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.automat[0]?.name}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.SALES_METHOD' | translate}}
                </div>
                <div class="col-md-8">
                  {{getPaymentTypeText(selectedRow._id?.payment[0]?.paymentType)}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted ">
                  {{'PRODUCT_REPORT.DISCOUNT' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.product?.isDiscountApplied ? 'Evet' : 'Hayır'}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.SALES_PRICE' | translate}}
                </div>
                <div class="col-md-8">
                  {{selectedRow._id?.product?.price}}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 text-muted">
                  {{'PRODUCT_REPORT.SENSOR' | translate}}
                </div>
                <div class="col-md-8"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction.transaction else noDataTemp">
                  {{(selectedRow._id.telemetryTransaction?.transaction?.isSensorControlState ?
                  'PRODUCT_REPORT.SITUATION_UNKNOWN' :
                  selectedRow._id.telemetryTransaction?.transaction?.isSensorControlState === 1 ? 'PRODUCT_REPORT.OPEN'
                  :
                  selectedRow._id.telemetryTransaction?.transaction?.isSensorControlState === 2 ? 'PRODUCT_REPORT.CLOSE'
                  : 'PRODUCT_REPORT.SITUATION_UNKNOWN') | translate
                  }}
                </div>
              </div>
            </div>
            <div class="col-md-6 container"> <!--sağ-->
              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.SEND_WALLET_INFORMATION' | translate}}
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.sendBalance ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  Vend İstek
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.getVendRequest ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.CREATE_BASKET' | translate}}
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.createdBasket ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.VEND_PAY' | translate}}
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.makeVendPay ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.ACCEPT_PAYMENT' | translate}}
                </div>
                <div class="col-md-3"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.sendApproved ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
                <div class="col-md-3">
                  <button class="btn btn-sm" *ngIf="selectedRow._id?.payment[0]?.paymentType == 1"
                    (click)="showReceipt(selectedRow._id?.payment[0], false)"><img width="20px"
                      src="../../assets/img/report.svg"></button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.SENSOR_RESPONSE' | translate}}
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.getSensorResponse ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  Alış-veriş Sonu
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.completedTransaction ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-6 text-muted">
                  {{'PRODUCT_REPORT.CANCEL_PAYMENT' | translate}}
                </div>
                <div class="col-md-3"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.cancelVendPay ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
                <div class="col-md-3">
                  <button class="btn btn-sm" (click)="showReceipt(selectedRow._id?.payment[0], true)"
                    *ngIf="selectedRow._id?.payment[0]?.isRefund && selectedRow._id?.payment[0]?.paymentType == 1"><img
                      width="20px" src="../../assets/img/report.svg"></button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-muted">
                  Alışveriş Sonu ({{'PRODUCT_REPORT.CANCEL_PAYMENT' | translate}})
                </div>
                <div class="col-md-6"
                  *ngIf="selectedRow._id.telemetryTransaction && selectedRow._id.telemetryTransaction[0]?.transaction else noDataTemp">
                  <img width="20px"
                    [src]=" selectedRow._id.telemetryTransaction[0]?.transaction?.cancelTransaction ? '../../assets/img/bigcheck.svg' : '../../assets/img/cancelBlack.svg' ">
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!--Details Modal END-->

<!-- Receipt Show -->
<button style="display: none;" #receiptButton data-toggle="modal" data-backdrop="true"
  data-target="#receiptData"></button>

<div class="modal fade" id="receiptData" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div *ngIf="!modalLoading else loadingRef" class="container p-2 position-relative">
          <div><button class="btn btn-sm" style="position: absolute; left: 0px;top: -5px"
              (click)="backToDetail()">Geri</button></div>
          <div class="row justify-content-center">
            <h5>{{receiptData.voidReceipt ? 'İade Makbuzu' : 'Ödeme Makbuzu'}}</h5>
          </div>


          <div class="row">
            <div class="text-muted col-md-2">POS </div>
            <pre class="m-0">  </pre>
            {{receiptData.AcquirerName}}
          </div>
          <div class="row">
            <div class="text-muted col-md-2">Sonuç</div>
            <pre class="m-0"> </pre>
            {{receiptData.RespDesc}}
          </div>
          <hr />

          <div class="row mt-3">
            <div class="col-md-6 container"> <!--sol-->
              <div class="text-center">
                <b> {{receiptData.bankName}}</b>
              </div>
              <div class="text-center">
                <b>{{receiptData.voidReceipt ? 'İADE' : 'SATIŞ'}}</b>
              </div>
              <div class="text-center">
                <b>{{receiptData.CardScheme}}</b>
              </div>
              <div class="mt-2">
                {{receiptData.bankAddress}}
              </div>
              <div class="mt-2">
                {{receiptData.address}}
              </div>
              <div>
                <p><span style="width: 20px">MID : </span>{{receiptData.MID}}</p>
                <div class="row">
                  <div class="col-md-6">
                    <p><span style="width: 20px">TID : </span>{{receiptData.TID}}</p>
                  </div>
                  <div class="col-md-6">
                    <p><span style="width: 20px">BATCH NO : </span>{{receiptData.BatchNum}}</p>
                  </div>
                </div>
              </div>
              <div>
                <h3><b>{{receiptData.CardNum}}</b></h3>
              </div>
              <div>
                <p><span style="width: 20px">DATE/TIME : </span>{{receiptData.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}
                </p>
              </div>
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <p><span style="width: 20px">AUTH : </span>{{receiptData.AuthCode}}</p>
                  </div>
                  <div class="col-md-6">
                    <p><span style="width: 20px">TRACE : </span>{{receiptData.CertNum}}</p>
                  </div>
                </div>
              </div>
              <div>
                <p><span style="width: 40px">TRANSACTION NUM: </span>{{receiptData.transactionNum}}</p>
              </div>
              <div>
                <p><span style="width: 20px">RRN: </span>{{receiptData.ReferCode}}</p>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <h2><b style="width: 20px">AMOUNT: </b></h2>
                </div>
                <div class="col-md-6 text-right">
                  <h2><b>{{(receiptData.TransAmount/100) | currency: ' '}}</b></h2>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Yükleme spinner -->
<ngx-spinner [bdColor]=loadingSpinnerBdColor [size]=loadingSpinnerSize [color]=loadingSpinnerColor
  [type]=loadingSpinnerType>
</ngx-spinner>
<!-- Yükleme spinner : SON -->

<ng-template #noDataTemp>
  -
</ng-template>

<ng-template #loadingRef>
  <div class="d-flex w-100 h-100">
    <div class="loader" style="font-size: 50%"></div>
  </div>
</ng-template>
<ng-template #loadingRef2>
  <div class="container" style="height: 80vh">
    <div class=" d-flex w-100 h-100">
      <div class="loader" style="font-size: 50%"></div>
    </div>
  </div>
</ng-template>