import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AutomatsRoutingModule} from './automats-routing.module';
import {AutomatsComponent} from './automats.component';

import {PipesModule} from '../_pipes/pipes.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxLoadingModule} from 'ngx-loading';
import {FormsModule} from '@angular/forms';
import {CardViewAutomatsComponent} from './card-view-automats/card-view-automats.component';
import {ListViewAutomatsComponent} from './list-view-automats/list-view-automats.component';
import {NotificationsAutomatModule} from './notifications-automat/notification-automat.module';
import {AutomatModule} from '../automat/automat.module';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared/shared.module';

const dec_exp = [
  AutomatsComponent,
  CardViewAutomatsComponent,
  ListViewAutomatsComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    PipesModule,
    NgxSpinnerModule,
    NgxLoadingModule,
    NgbModule,
    InfiniteScrollModule,
    NgxPermissionsModule.forChild(),
    AutomatsRoutingModule,
    GoogleMapsModule,
    NotificationsAutomatModule,
    AutomatModule,
    SharedModule
  ],
  declarations: [dec_exp],
  exports: [dec_exp]
})
export class AutomatsModule {
}
