import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AuthenticationService} from '../../../_services/authentication.service';
import {FirmService} from '../../../_services/firm.service';
import {Firm} from '../../../_models/firm';
import Swal from 'sweetalert2';
import {environment} from '../../../../environments/environment';
import {SwalHelper} from '../../../_helpers/swal.helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageService} from '../../../_services/image.service';
import {FormTemplate} from '../../../_models/formTemplate';
import {DynamicFormComponent} from '../../../_widgets/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-firm-info',
  templateUrl: './firm-info.component.html',
  styleUrls: ['./firm-info.component.css']
})
export class FirmInfoComponent implements OnInit {

  FirmImagePath = environment.FirmImagePath;
  swalOptions = new SwalHelper();
  @ViewChild('imageModal') imageModal: ElementRef;
  // Resim ekleme değişkenleri
  cropInput: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;

  // END : Resim ekleme değişkenleri

  @ViewChild(DynamicFormComponent) private dyForm: DynamicFormComponent;
  edit = false;
  _firm: Firm;
  firmid: string;
  ft: Array<FormTemplate<string>>;

  constructor(private firmService: FirmService,
              private auth: AuthenticationService,
              private fb: FormBuilder,
              private modalService: NgbModal,
              private imageService: ImageService) {
  }

  ngOnInit() {
    this.firmid = this.auth.user$.getValue().firm._id;
    this.load();
  }


  load() {
    this.firmService.getFirmInfo(this.firmid).subscribe(res => {
      if (res.success) {
        console.log('getfirm', res.data);
        this._firm = res.data;
        this.getFormTemplate(this._firm);
        this.updateLocal();
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Firma bilgileri alınamadı!'
        });
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Firma bilgileri alınamadı'
      });
    });
  }


  getFormTemplate(data: Firm) {
    if (data) {
      console.log('ft-data', data);
      this.ft = new Firm(data).getFormTemplate();
      console.log('ft', this.ft);

    }
  }


  formSubmit(event) {
    console.log(event);

    this.firmService.updateFirmInfo(this._firm._id, event)
      .subscribe(res => {
        if (res.success) {
          this.modalService.dismissAll();
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Firma bilgileri güncellendi'
          });
        }
      }, err => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Firma bilgileri güncellenirken hata oluştu'
        });
      });
  }

  update() {
    this.dyForm.onSubmit();
  }

  switchEdit() {
    this.edit = !this.edit;
  }

  updateLocal() {
    const cUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this._firm) {
      for (const prop in this._firm) {
        cUser.user.firm[prop] = this._firm[prop];
      }
    }
    this.auth.user$.next(cUser.user);
    localStorage.setItem('currentUser', JSON.stringify(cUser));
  }

  updateFirmImage() {

    if (this.croppedImage && this.croppedImage !== '') {
      let fileName = this.cropInput[0].name;
      fileName = fileName.split('.');
      const formData = new FormData();
      const ImageFile = this.convertToFile(this.croppedImage,
        fileName[0] + '_' + this._firm._id + '.' + fileName[1]);
      formData.append('upfile', ImageFile);

      //servise formdata gönder

      this.imageService.addFirmImage(formData, this._firm._id).subscribe((res) => {
        console.log(res);
        this.modalService.dismissAll();
        this.load();
      });
    }
  }

  // Image crop functions
  changeImage() {
    const modalRef = this.modalService.open(this.imageModal, {centered: true});

  }

  fileChangeEvent(event: any): void {
    this.cropInput = event.target.files;
    this.imageChangedEvent = event;
  }

  imageCropped(image: string) {
    this.croppedImage = image;
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  imageLoadFailed() {
    Swal.fire({
      ...this.swalOptions.error,
      text: 'Lütfen png yada jpeg resim seçiniz'
    });
  }

  convertToFile(base64Str: string, fileName: string) {

    const binary = atob(base64Str.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new File([new Uint8Array(array)], fileName, {type: 'image/jpeg', lastModified: Date.now()});
  }

  // END : Image crop functions


}
