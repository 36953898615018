import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormTemplate} from '../../_models/formTemplate';
import {FormbuilderHelper} from '../../_helpers/formbuilder.helper';
import Swal from 'sweetalert2';
import {SwalHelper} from '../../_helpers/swal.helper';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {


  @Input()
  set questions(qs: FormTemplate<string>[]) {
    this._questions = qs;
    this.form = this.fbh.toFormGroup(qs);
  }

  @Output() submit = new EventEmitter<any>();
  @Output() formValue = new EventEmitter<any>();

  swalOptions = new SwalHelper();
  form: FormGroup;
  _questions: FormTemplate<string>[] = [];

  constructor(private fbh: FormbuilderHelper) {
  }

  ngOnInit() {
    this.formValue.emit(this.form.getRawValue());
  }

  onSubmit() {
    if (this.form.valid) {
      this.submit.emit(this.form.getRawValue());
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Tüm alanları doldurunuz'
      });
    }
  }

}
