import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import 'jquery';
import {AuthenticationService} from './_services/authentication.service';
import { TranslationService } from './i18n/translation.service';
import { locale as enLang } from './i18n/vocabs/en';
import { locale as chLang } from './i18n/vocabs/ch';
import { locale as esLang } from './i18n/vocabs/es';
import { locale as jpLang } from './i18n/vocabs/jp';
import { locale as deLang } from './i18n/vocabs/de';
import { locale as frLang } from './i18n/vocabs/fr';
import { locale as trLang } from './i18n/vocabs/tr';
declare let gtag: Function;

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  showHeader = false;
  settings = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private config: NgbModalConfig,
              private authService: AuthenticationService,
              private translationService: TranslationService,
              private modal: NgbModal) {
                router.events.subscribe(event => {
                  if(event instanceof NavigationEnd){
                      gtag('config', 'UA-104442403-2',{'page_path': event.urlAfterRedirects});
                   }
                }
             )
    config.size = 'lg';
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang,
      trLang
    );
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {

        return;
      }
      this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
      this.settings = this.activatedRoute.firstChild.snapshot.data.settings === true;
      window.scrollTo(0, 0);
      this.modal.dismissAll();
    });

  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
}

