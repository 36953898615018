import {StaffKind} from './staffKind';
import {Firm} from './firm';

export class Staff {
  constructor() {}
  _id: string;
  name: string;
  username: string;
  password: string;
  phone: string;
  photo: string;
  email: string;
  status: number;
  staffKind: StaffKind;
  firm: Firm;
  updatedAt: string;
  createdAt: string;
}
