<div class="custom-table-div {{_paginationOption.total <= 1 ? 'custom-table-overflow-hidden' : '' }}">
  <table class="table custom-table">
    <thead  *ngIf="_paginationOption.headers.length>0" >
    <tr>
      <th style="width: 60px">
        <input type="checkbox" id="check_all" [ngModel]="isAllSelected()" (click)="selectAlItem($event)">
      </th>
      <th style="width: 60px">Sıra</th>
      <th style="width: {{ item.width}}px" *ngFor="let item of _paginationOption.headers">{{item.name}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of _paginationOption.itemList; let i = index;">
      <td style="width: 60px" id="index_{{i}}" (click)="selectItem(item)">
        <input type="checkbox" id="check_{{item._id}}" [ngModel]="item.isSelected">
      </td>
      <td style="width: 60px" id="index_{{i}}" (click)="selectItem(item)">
        {{
          i +
          (((_paginationOption.page - 1) * 10)+1)
        }}
      </td>
      <td style="width: {{ itemHeader.width}}px" *ngFor="let itemHeader of _paginationOption.headers">
        <p *ngIf="itemHeader.propertyName && !itemHeader.type">
          {{ getValue(item, itemHeader.propertyName)}}
        </p>
        <p *ngIf="itemHeader.propertyName && itemHeader.type=='boolean'"
           [ngClass]="{'text-danger': itemHeader.color && !getValue(item, itemHeader.propertyName)}">
          {{ getValue(item, itemHeader.propertyName) ? itemHeader.trueVal : itemHeader.falseVal}}
        </p>
        <p *ngIf="itemHeader.propertyName && itemHeader.type === 'price'">
          {{ getValue(item, itemHeader.propertyName) | number | commaDotSwapper}}
        </p>
        <p *ngIf="itemHeader.propertyName && itemHeader.type === 'date'">
          {{ convertDate(getValue(item, itemHeader.propertyName), itemHeader.format)}}
        </p>
        <p *ngIf="itemHeader.propertyName && itemHeader.type === 'status'"
           [ngClass]="{'text-danger': getValue(item, itemHeader.propertyName) == 8 || getValue(item, itemHeader.propertyName) == 9 || getValue(item, itemHeader.propertyName) == 10}">
          {{getStatus(getValue(item, itemHeader.propertyName))}}
        </p>

        <div class="row overflow-hidden text-nowrap"
             *ngIf="itemHeader.propertyName && itemHeader.type === 'slide-text'">
          <p [ngClass]="{'slide-text': getValue(item, itemHeader.propertyName)?.length > 25}">
            {{ getValue(item, itemHeader.propertyName) }}
          </p>
        </div>

        <div class="row overflow-hidden text-nowrap" *ngIf="itemHeader.type === 'action'">
          <button class="btn btn-sm" *ngFor="let button of _paginationOption.buttons;"
                  (click)="buttonAction(button.action , item)">
            <i *ngIf="button.icon" class="fa {{button.icon}} mr-2"></i>
            <span *ngIf="!button.onlyIcon">{{button.name}} </span>
          </button>
        </div>

        <div *ngIf="itemHeader.propertyName && itemHeader.type == 'img'" class="img-size">
          <img *ngIf="getValue(item, itemHeader.propertyName)" [src]="getValue(item, itemHeader.propertyName) | image: (itemHeader.base || '') ">
          <img *ngIf="itemHeader.emptyShow && !getValue(item, itemHeader.propertyName)" src="../../assets/img/gorsel_yok.svg">
        </div>
      </td>
    </tr>
    <tr *ngFor="let item of generateEmptyList();let i = index;">
      <td></td>
      <td *ngFor="let itemHeader of _paginationOption.headers">
        <div style="visibility: hidden">
          <p *ngIf="itemHeader.propertyName && !itemHeader.type">
            {{ getValue(item, itemHeader.propertyName)}}
          </p>
          <p *ngIf="itemHeader.propertyName && itemHeader.type === 'price'">
            {{ getValue(item, itemHeader.propertyName) | number | commaDotSwapper}}
          </p>
          <p *ngIf="itemHeader.propertyName && itemHeader.type === 'date'">
            {{ getValue(item, itemHeader.propertyName) | date}}
          </p>
          <p *ngIf="itemHeader.propertyName && itemHeader.type === 'status'"
             [ngClass]="{'text-danger': getValue(item, itemHeader.propertyName) == 8 || getValue(item, itemHeader.propertyName) == 9 || getValue(item, itemHeader.propertyName) == 10}">
            {{getStatus(getValue(item, itemHeader.propertyName))}}
          </p>

          <div class="row overflow-hidden text-nowrap"
               *ngIf="itemHeader.propertyName && itemHeader.type === 'slide-text'">
            <p [ngClass]="{'slide-text': getValue(item, itemHeader.propertyName)?.length > 25}">
              {{ getValue(item, itemHeader.propertyName) }}
            </p>
          </div>

          <div class="row overflow-hidden text-nowrap" *ngIf="itemHeader.type === 'action'">
            <button class="btn btn-sm" *ngFor="let button of _paginationOption.buttons;"
                    (click)="buttonAction(button.action , item)">
              <i *ngIf="button.icon" class="fa {{button.icon}} mr-2"></i>
              <span *ngIf="!button.onlyIcon">{{button.name}} </span>
            </button>
          </div>

          <div *ngIf="itemHeader.propertyName && itemHeader.type == 'img'" class="img-size">
            <img *ngIf="getValue(item, itemHeader.propertyName)" [src]="getValue(item, itemHeader.propertyName)">
            <img *ngIf="!getValue(item, itemHeader.propertyName)" src="../../assets/img/gorsel_yok.svg">
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div *ngIf="(!_paginationOption.itemList || _paginationOption.itemList?.length == 0)"
       class="row justify-content-center animated flipInX emptyText">
    <h1 class="h3 mt-5" style="color: gray">{{_paginationOption.emptyListMessage}}</h1>
  </div>
</div>
<div class="row mt-2">
  <div class="col-10">
    <ngb-pagination
      [(page)]="_paginationOption.page"
      [maxSize]="10"
      [rotate]="true"
      [boundaryLinks]="true"
      [pageSize]="_paginationOption.limit"
      [collectionSize]="_paginationOption.total"
      (pageChange)="changeValue($event)"
    >

    </ngb-pagination>
  </div>
  <div class="col-2">
    <select class="form-select form-control" style="width: '80px'" aria-label="Default select example" ngModel
            [ngModel]="_paginationOption.limit"
            (ngModelChange)="changeLimitValue($event)">
      <option [value]="this._paginationOption.total" disabled>Limit</option>
      <option [value]="limit" *ngFor="let limit of _paginationOption.limits">{{limit}}</option>

    </select>
  </div>
</div>
