import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getIssues(): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'issue/', null, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssue(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'issue/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueKinds(): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'issueKind/', null, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueKind(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'issueKind/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueProcesses(): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'issueProcess/', null, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueProcess(id: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'issueProcess/' + id, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssueMonthlyFirm(day: string, month: string, year: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'issue-monthly-firm/' + day + '/' + month + '/' + year, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getIssuesFirmMonthlyByAutomat(month: string, year: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'getIssuesFirmMonthlyByAutomat/' + month + '/' + year, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }
}
