import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicFormComponent} from './dynamic-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DynamicFormQuestionComponent} from './dynamic-form-question/dynamic-form-question.component';
import {IbanDirective} from '../../_directives/iban.directive';


@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    IbanDirective
  ],
  exports: [
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    IbanDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class DynamicFormModule {
}
