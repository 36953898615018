import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_guards/auth.guard';
import { payterComponent } from "./payter/payter.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'payter',
    pathMatch: 'full'
  },
  {
    path: 'payter',
    component: payterComponent,
    canActivate: [AuthGuard],
    data: {
      settings: true,
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppSettingsRoutingModule { }
