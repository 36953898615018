import {FormTemplate} from './formTemplate';
import {PaymentSystems} from './paymentSystems';

export class PaymentSystemsZiraat implements PaymentSystems {
  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }
  _id: string;
  status;
  paymentSystemsFirmId = '';
  paymentSystemsFirmName = '';
  paymentSystemsFirmCode = 2;

  paymentSystemsFirmClientID = '';
  paymentSystemsFirmUserName = '';
  paymentSystemsFirmPassword = '';


  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'paymentSystemsFirmClientID',
        label: 'Client id',
        value: this.paymentSystemsFirmClientID,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'paymentSystemsFirmUserName',
        label: 'Kullanıcı Adı',
        value: this.paymentSystemsFirmUserName,
        required: true
      }),
      new FormTemplate({
        controlType: 'password',
        type: 'password',
        key: 'paymentSystemsFirmPassword',
        label: 'Şifre',
        value: this.paymentSystemsFirmPassword,
        required: true
      })
    ];

    return template;
  }

}
