<div class="container mb-4">
  <div class="row mt-3">
    <div *ngFor="let paymentSystemsFirm of paymentSystemsFirms" class="col-md-6 col-xl-4 col-lg-4
            justify-content-center align-content-center">
      <div class="border payment-firms-border text-center" (click)="selectPaymentSystemsFirm(paymentSystemsFirm)">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="payment-systems-header">
              <label class="m-2">{{paymentSystemsFirm.name}}</label>
            </div>
          </div>
        </div>
        <div class="payments-image-border d-flex">
          <img *ngIf="paymentSystemsFirm.photo && paymentSystemsFirm.photo.length > 0 else paymentSystemName"
               class="m-auto" [src]="paymentSystemsFirm.photo | image:PaymentTypeImage" width="70%" alt="pSysPhoto">
          <ng-template #paymentSystemName>
            <h4 style="color: #818181; margin-top: 55px;">{{paymentSystemsFirm.name}} Ödeme Yöntemi</h4>
          </ng-template>
        </div>
        <label *ngIf="paymentSystemsFirm.status"><img src="../../../../assets/img/aktif-otomat.svg"/> Aktif </label>
        <label *ngIf="!paymentSystemsFirm.status"><i class="fas fa-times" style="color: #E53935"></i> Pasif </label>
      </div>
    </div>
  </div>
</div>

