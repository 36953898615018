import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FirmUser} from '../../_models/firmUser';
import {FirmUserService} from '../../_services/firm-user.service';
import {allPerms} from '../../_services/user-permissions.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SwalHelper} from '../../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {FormfieldPhoneComponent} from '../../_widgets/formfield-phone/formfield-phone.component';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  @ViewChild(FormfieldPhoneComponent)
  private phoneInput: FormfieldPhoneComponent;

  _selectedUser: FirmUser;
  allpermissions = allPerms;

  password2: string;
  hide = true;
  hide2 = true;
  errors: string[] = [];
  showErrors = false;

  @Input()
  set selectedUser(data: any) {
    this._selectedUser = data || {
      name: null, username: null, telemetryPermission: []
    };
  }

  @Output() changeUser = new EventEmitter<{ event: string, data: any }>();
  swalOptions = new SwalHelper();

  constructor(
    private firmuserService: FirmUserService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }

  changePermArray(perm: any, event, add: boolean) {
    if (perm instanceof Array) {
      if (add && event.target.checked) {
        this._selectedUser.telemetryPermission = JSON.parse(JSON.stringify(perm));
      } else {
        this._selectedUser.telemetryPermission = [];
      }

    } else {
      if (add && event.target.checked) {
        //ekle
        this._selectedUser.telemetryPermission.push(perm);
      } else {
        //çıkar
        const index = this._selectedUser.telemetryPermission.findIndex((data) => {
          return data === perm;
        });
        this._selectedUser.telemetryPermission.splice(index, 1);
      }
    }
  }

  saveUser() {
    if (!this.validateInputs()) {
      this.password2 = null;
      Swal.fire({
        ...this.swalOptions.error,
        title: 'Alanları kontrol ediniz',
        text: this.errors
      });
      return;
    }
    if (this._selectedUser._id) {
      //update
      const req = {
        name: this._selectedUser.name,
        username: this._selectedUser.username,
        authRole: this._selectedUser.authRole,
        telemetryPermission: this._selectedUser.telemetryPermission,
        phoneNumber: this._selectedUser.phoneNumber
      };
      this.firmuserService.updateFirmUser(req, this._selectedUser._id).subscribe((res: any) => {
        if (res.success) {
          this.changeUser.emit({event: 'update', data: res.firmUser});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Güncelleme başarılı!'
          });
        }

      }, error => {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Güncelleme başarısız!'
        });
      });
    } else {
      //new
      const req = {
        name: this._selectedUser.name,
        username: this._selectedUser.username,
        password: this._selectedUser.password,
        telemetryPermission: this._selectedUser.telemetryPermission,
        phoneNumber: this._selectedUser.phoneNumber
      };
      this.firmuserService.addFirmUser(req).subscribe((res) => {
        if (res.success) {
          this.changeUser.emit({event: 'new', data: res.firmUser});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Kayıt başarılı!'
          });
        }

      }, (error) => {
        let text;
        if (error.error.status === 500) {
          text = 'Bu kullanıcı adı kullanılıyor!';
        } else {
          text = 'Kayıt başarısız!';
        }
        Swal.fire({
          ...this.swalOptions.error,
          text: text
        });
      });
    }
  }

  deleteUser() {
    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Bu kullanıcıyı silmek istediğinizden emin misiniz?'
    }).then((result) => {
      if (result.value) {
        this.firmuserService.deleteFirmUser(this._selectedUser._id).subscribe((res) => {
          if (res.success) {
            this.changeUser.emit({event: 'delete', data: this._selectedUser});
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Kayıt silindi!'
            });
          }
        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kayıt silinemedi!'
          });
        });
      }
    });


  }


  validateInputs(): boolean {
    if (!this.phoneInput.isValid() ||
      !this.inputCheck(this._selectedUser.username) ||
      !this.inputCheck(this._selectedUser.name)
    ) {
      this.showErrors = true;
      return false;
    }

    if (!this._selectedUser._id) {
      return this.passwordCheck();
    }

    return true;
  }

  inputCheck(val: string): boolean {
    return !(val === null || val.trim() === '');
  }

  passwordCheck() {
    this.errors = [];
    if (this.password2 !== this._selectedUser.password) {
      this.errors.push(' Şifreler aynı olmalıdır ');
    }
    if (this._selectedUser.password === undefined || this._selectedUser.password === null || this._selectedUser.password === '') {
      this.errors.push(' Şifre girilmesi zorunludur ');
    }
    if (this._selectedUser.password && this._selectedUser.password.length < 8) {
      this.errors.push(' Uzunluğu en az 8 karakterden oluşmalıdır ');
    }
    return this.password2 === this._selectedUser.password &&
      this._selectedUser.password !== null &&
      this._selectedUser.password !== undefined &&
      this._selectedUser.password !== '' &&
      this._selectedUser.password.length > 7;
  }
}
