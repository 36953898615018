import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';

import {FirmService} from '../_services/firm.service';
import {AutomatService} from '../_services/automat.service';
import {IssueService} from '../_services/issue.service';
import {IssueKind} from '../_models/issueKind';
import {Router} from '@angular/router';
import {StaffService} from '../_services/staff.service';
import {IssueProcess} from '../_models/issueProcess';
import {IssueProcessService} from '../_services/issue-process.service';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {isSameDay, isSameMonth} from 'date-fns';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';

const colors: any = {
  fault: {
    primary: '#ad2121',
    secondary: '#FFFFFF'
  },
  update: {
    primary: '#ff7482',
    secondary: '#FFFFFF'
  },
  passive: {
    primary: '#b8b8b8',
    secondary: '#FFFFFF'
  }
};

@Component({
  selector: 'app-service-case',
  templateUrl: './service-care.component.html',
  styleUrls: ['./service-care.component.css']
})
export class ServiceCareComponent implements OnInit {

  swalOptions = new SwalHelper();

  @ViewChild('closeRedirectPersonal', {static: false}) private closeRedirectPersonal: ElementRef;
  @ViewChild('openIssueDetail', {static: false}) private openIssueDetail: ElementRef;

  public StaffImagePath: string;
  public monthIndex: number;
  public yearIndex: number;
  public dayIndex: number;
  issuesOfDay: Array<any> = [];
  public issue: any = {};
  public automatIssue: any = {};
  public issues = [];
  public issuesObject = [];
  public staffs: any = [];
  public staff: any = {};
  public issueType = -1;
  public note: string;
  public returnCalendarButton = false;
  public calendarArea = true;
  public activeAutomatsArea = false;
  public errorAutomatsArea = false;
  public updateAutomatsArea = false;
  public serviceAutomatsArea = false;
  public automatCounts: any = {};
  public automatActiveCounts = 0;
  public automatNeedUpdateCounts = 0;
  public automatOnRepairCounts = 0;
  public automatFaultCounts = 0;
  public activeAutomats: any = [];
  public activeAutomatPage = 1;
  public faultAutomats: any = [];
  public faultAutomatPage = 1;
  public needUpdateAutomats: any = [];
  public needUpdateAutomatPage = 1;
  public onRepairAutomats: any = [];
  public onRepairAutomatPage = 1;
  public firmIssues = {};

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)


  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  currentArea: string;
  viewDate: Date = new Date();
  events = [];
  activeDayIsOpen = false;
  refresh: Subject<any> = new Subject();
  page = 1;
  issuesCount;


  constructor(private router: Router,
              private firmService: FirmService,
              private automatService: AutomatService,
              private issueService: IssueService,
              private staffService: StaffService,
              private issueProcessService: IssueProcessService,
              private loadingSpinner: NgxSpinnerService) {
    this.monthIndex = new Date().getMonth() + 1;
    this.yearIndex = new Date().getFullYear();
    this.dayIndex = new Date().getDate();
    this.StaffImagePath = environment.StaffImagePath;
  }

  ngOnInit() {
    //this.loadingSpinner.show();
    this.issueService.getIssueMonthlyFirm(this.dayIndex.toString(), this.monthIndex.toString(), this.yearIndex.toString())
      .subscribe(res => {
        let array = [];
        this.events = [];
        array = res.event;
        array.forEach(info => {
          this.addEvent(info.updatedAt, info.kind);
        });
        this.issueService.getIssuesFirmMonthlyByAutomat(this.monthIndex.toString(), this.yearIndex.toString()).subscribe(response => {
          let issuesOfDayObject = {};
          let indexOf = -1;
          this.issuesOfDay = [];
          response.data.forEach((automat, index) => {
            this.issuesCount = 0;
            issuesOfDayObject = {};
            array.forEach((info, infoIndex) => {
              if (automat._id.issueId.toString() === info._id.issueId) {
                if (isSameDay(new Date(), info._id.issueUpdatedAt)) {
                  if (this.issuesOfDay.indexOf(issuesOfDayObject) === -1) {
                    this.issuesCount++;
                    issuesOfDayObject = {automat: automat._id, count: this.issuesCount};
                    this.issuesOfDay.push(issuesOfDayObject);
                  } else {
                    indexOf = this.issuesOfDay.indexOf(issuesOfDayObject);
                    this.issuesCount++;
                    issuesOfDayObject = {automat: automat._id, count: this.issuesCount};
                    this.issuesOfDay[indexOf].count = this.issuesCount;
                  }
                }
              }
            });
          });
        });
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Arıza listesi sunucudan getirilirken hata oluştu!'
        });
      });

    this.automatService.getCountAutomatsStatus().subscribe(Response => {
      this.automatCounts = Response.result;
      this.automatCounts.forEach(data => {
        switch (data._id) {
          case 1:
            this.automatActiveCounts = data.count;
            break;
          case 2:
            this.automatNeedUpdateCounts = data.count;
            break;
          case 3:
            this.automatOnRepairCounts = data.count;
            break;
          case 8:
            this.automatFaultCounts = data.count;
            break;
        }
      });
    });


    this.clearStaff();

    this.staffService.getFirmStaffs().subscribe(res => {
      if (res.success) {
        /*for (const item of res.firmStaffs) {
          this.staffs.push(item);
        }*/
        this.staffs = res.firmStaffs;
      }

      this.loadingSpinner.hide();
    });
  }


  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0);
      this.viewDate = date;
      this.dayIndex = date.getDate();
      this.monthIndex = date.getMonth() + 1;
      this.yearIndex = date.getFullYear();
      //this.issuesOfDay = events;
      this.issuesOfDay = [];
      const indexOf = -1;
      const issuesOfDayObject = {};

      this.loadingSpinner.show();
      this.issueService.getIssueMonthlyFirm(this.dayIndex.toString(), this.monthIndex.toString(), this.yearIndex.toString())
        .subscribe(res => {
          let array = [];
          if (events.length > 0) {
            this.issues = res.issue;
          } else {
            this.issues = [];
          }
          array = res.event;
          this.events = [];
          this.issuesOfDay = [];
          array.forEach(info => {
            this.addEvent(info.updatedAt, info.kind);
          });
          this.loadingSpinner.hide();
        });

    }

  }

  previousMonth() {
    this.monthIndex--;
    if (this.monthIndex === 0) {
      this.yearIndex--;
      this.monthIndex = 12;
    }
    this.getIssues();

  }

  nextMonth() {
    this.monthIndex++;
    if (this.monthIndex === 13) {
      this.yearIndex++;
      this.monthIndex = 1;
    }
    this.getIssues();
  }

  getIssues() {
    this.issueService.getIssueMonthlyFirm(this.dayIndex.toString(), this.monthIndex.toString(), this.yearIndex.toString())
      .subscribe(res => {
        let array = [];
        array = res.event;
        this.events = [];
        this.issues = [];
        this.issuesOfDay = [];
        array.forEach(info => {
          this.addEvent(info.updatedAt, info.kind);
        });
      }, error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Aylık arıza listesi sunucudan getirilirken hata oluştu!'
        });
      });
  }


  addEvent(date: Date, kind: IssueKind): void {
    let color;
    switch (kind.type) {
      case 2:
        color = colors.update;
        break;
      case 3:
      case 7:
      case 9:
        color = colors.passive;
        break;
      case 8:
        color = colors.fault;
        break;
    }
    const data = {
      title: kind.name,
      start: moment(date).startOf('day').toDate(),
      end: moment(date).endOf('day').toDate(),
      color: color,
      draggable: false,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      date: kind.updatedAt, // Benim eklediklerim
      type: kind.type,
      status: kind.status,
    };
    this.events.push(data);
    this.refresh.next();
  }

  showArea(area: string) {
    this.currentArea = area;
    this.loadingSpinner.show();
    switch (area) {
      case 'calendar':
        this.calendarArea = true;
        this.activeAutomatsArea = false;
        this.errorAutomatsArea = false;
        this.updateAutomatsArea = false;
        this.serviceAutomatsArea = false;
        this.returnCalendarButton = false;
        this.loadingSpinner.hide();
        break;
      case 'active':
        this.automatService.getFirmAutomatsStatus(1, this.activeAutomatPage).subscribe(Response => {
          this.activeAutomats = Response.docs;
          if (this.activeAutomats.length === 0) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Aktif otomatlar listesi boş!'
            });
          }
          this.loadingSpinner.hide();
        }, error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Aktif otomatlar listesi sunucudan getirilirken hata oluştu!'
          });
        });
        this.calendarArea = false;
        this.activeAutomatsArea = true;
        this.errorAutomatsArea = false;
        this.updateAutomatsArea = false;
        this.serviceAutomatsArea = false;
        this.returnCalendarButton = true;
        break;
      case 'error':
        this.firmService.getIssueFirmAutomatStatus(8, this.faultAutomatPage).subscribe(Response => {
          this.faultAutomats = Response.docs;
          if (this.faultAutomats.length === 0) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Arızalı otomatlar listesi boş!'
            });
          }
          this.loadingSpinner.hide();
        }, error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Arızalı otomatlar listesi sunucudan getirilirken hata oluştu!'
          });
        });
        this.calendarArea = false;
        this.activeAutomatsArea = false;
        this.errorAutomatsArea = true;
        this.updateAutomatsArea = false;
        this.serviceAutomatsArea = false;
        this.returnCalendarButton = true;
        break;
      case 'update':
        this.firmService.getIssueFirmAutomatStatus(2, this.needUpdateAutomatPage).subscribe(Response => {
          this.needUpdateAutomats = Response.docs;
          if (this.needUpdateAutomats.length === 0) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Güncelleme gerektiren otomatlar listesi boş!'
            });
          }
          this.loadingSpinner.hide();
        }, error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Güncelleme gerektiren otomatlar listesi sunucudan getirilirken hata oluştu!'
          });
        });
        this.calendarArea = false;
        this.activeAutomatsArea = false;
        this.errorAutomatsArea = false;
        this.updateAutomatsArea = true;
        this.serviceAutomatsArea = false;
        this.returnCalendarButton = true;
        break;
      case 'service':
        this.firmService.getIssueFirmAutomatStatus(3, this.onRepairAutomatPage).subscribe(Response => {
          this.onRepairAutomats = Response.docs;
          if (this.onRepairAutomats.length === 0) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Servisi devam eden otomatlar listesi boş!'
            });
          }
          this.loadingSpinner.hide();
        }, error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Servisi devam eden otomatlar listesi sunucudan getirilirken hata oluştu!'
          });
        });
        this.calendarArea = false;
        this.activeAutomatsArea = false;
        this.errorAutomatsArea = false;
        this.updateAutomatsArea = false;
        this.serviceAutomatsArea = true;
        this.returnCalendarButton = true;
        break;
    }
  }

  getIssue(id) {
    this.loadingSpinner.show();
    this.issueService.getIssue(id).subscribe(res => {
      this.issue = res;
      this.openIssueDetail.nativeElement.click();
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Arıza bilgisi sunucudan getirilirken hata oluştu!'
      });
    });
  }

  changeAutomatId(automatId: string) {
    this.automatService.changeAutomatId(automatId);
    this.router.navigate(['/automat-detail', automatId]);
  }

  setIssue(issue) {
    this.issue = Object.assign({}, issue);
  }

  clearStaff() {
    this.staff._id = '-1';
    this.issue = {};
    this.note = '';
    this.issueType = -1;
  }

  staffDirect() {
    const issueProcess = new IssueProcess();
    issueProcess.note = this.note;
    issueProcess.issue = this.issue._id;
    issueProcess.staff = this.staff;
    issueProcess.type = this.issueType;
    this.loadingSpinner.show();
    this.issueProcessService.addIssueProcess(issueProcess).subscribe(Response => {
      if (!this.calendarArea) {
        this.showArea(this.currentArea);
      }
      this.closeRedirectPersonal.nativeElement.click();
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Personel yönlendirilirken hata oluştu!'
      });
    });

  }


}
