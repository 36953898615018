import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDirective } from '../_directives/image.directive';
import { DragDropDirective } from '../_directives/drag-drop.directive';
import { TranslationModule } from '../i18n/translation.module';


@NgModule({
  declarations: [ImageDirective,DragDropDirective],
  imports: [
    CommonModule,
    TranslationModule
  ],
  exports:[ImageDirective,DragDropDirective,TranslationModule]
})
export class SharedModule { }
