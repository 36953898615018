import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DatePickerComponent} from './date-picker.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';

const dec_exp = [
  DatePickerComponent
];

@NgModule({
  declarations: dec_exp,
  exports: dec_exp,
    imports: [
        CommonModule,
        FormsModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        NgbTimepickerModule
    ]
})
export class DatePickerModule { }
