import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as mathjs from 'mathjs';
import {SwalHelper} from '../../_helpers/swal.helper';
import {CashlessService} from '../../_services/cashless.service';
import * as moment from 'moment';
import {OfflineUserGroup} from '../../_models/offlineUserGroup';

@Component({
  selector: 'app-deduct-balance-card-packet',
  templateUrl: './deduct-balance-card-packet.component.html',
  styleUrls: ['./deduct-balance-card-packet.component.css']
})
export class DeductBalanceCardPacketComponent implements OnInit {

  @ViewChild('datePicker', {static: false}) datePicker: ElementRef;
  @ViewChild('groupBalanceInput', {static: false}) groupBalanceInput: ElementRef;
  @ViewChild('monthPeriod', {static: false}) monthPeriodSelect: ElementRef;

  swalOptions = new SwalHelper();

  monthPeriod = '-1';
  periodStartDate = '-1';
  monthlyPeriodInfoText = '';
  singleGroupBalance = null;
  singleGroupBalanceApproval = false;
  group: OfflineUserGroup;
  groupBalance = null;
  periodIsUpdate = false;
  sendMail = false;
  constantDay = '';
  isGroupBalance = false;
  isSingleGroupBalance = false;
  isMonthPeriod = false;
  isPeriodStartDate = false;

  firmUserMailAddress = '';

  controlVariables: { isInEdit: boolean, index: number, offlineUserGroupId: string };

  @Input()
  set selectedGroup(data: OfflineUserGroup) {
    this.group = data;
  }

  @Input()
  set conVariables(data: { isInEdit: boolean, index: number, offlineUserGroupId: string }) {
    this.controlVariables = data;
  }

  @Output() submit = new EventEmitter<{ success: boolean, code: number, data: any }>();

  constructor(
    public activeModal: NgbActiveModal,
    private cashlessService: CashlessService,
  ) {
  }


  ngOnInit(): void {
  }


  controlSingleUpdateGroupBalance() {
    this.singleGroupBalanceApproval = mathjs.round(this.singleGroupBalance, 2) > 0;
    // this.singleGroupBalance = event.target.value;
  }

  updateSingleGroupBalance() {
    if (mathjs.round(this.singleGroupBalance, 2) <= 0) {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Sıfır veya sıfırdan küçük bir değer girmeyiniz!'
      });
      return;
    }
    this.cashlessService.deductOfflineUserGroupBalance(this.group._id, this.singleGroupBalance).subscribe(res => {

      this.submit.emit({success: true, code: 1, data: null});
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Kart paketindeki tüm kullanıcıardan ' + this.singleGroupBalance + '₺ düşürüldü.'
      });

    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Bakiye yüklenirken hata oluştu.'
      });

    });
  }


  resetAllBalance() {
    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Kart Paketindeki tüm bakiyeleri sıfırlamak istiyor musunuz? Tüm kullanıcıların bakiyeleri sıfırlanacaktır.'
    }).then((result) => {
      if (result.value) {
        this.cashlessService.resetAllBalanceOfGroup(this.controlVariables.offlineUserGroupId).subscribe(res => {

          this.submit.emit({success: true, code: 2, data: null});
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Kart Paketindeki tüm kullanıcıların bakiyeleri sıfırlandı.'
          });

        }, error => {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Bakiye sıfırlama sırasında bir hata oluştu.'
          });

        });
      }
    });


  }

  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
}
