import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient) { }

  payterAuthenticate(data) {
    console.log(data);
    return this.http.post(environment.apiBaseUrl + 'payter/authenticate',
      JSON.stringify({username: data.username, password: data.password, domain: data.domain}),
      {headers: this.headers}).pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      }));
  }
}
