import {User} from './user';
import {Firm} from './firm';
import {TicketSubject} from './ticketSubject';
import {TicketMessage} from './ticketMessage';

export class Ticket {
  constructor() {}
  note: string;
  _id: string;
  final: string;
  user: User;
  firm: Firm;
  updatedAt: Date;
  createdAt: Date;
  subject: TicketSubject;
  messages: Array<TicketMessage>;
  status: number;
}
