import {ProductCategory} from './productCategory';
import {ProductBrand} from './productBrand';
import {ProductContent} from './productContent';
import {EvendProduct} from './evendProduct';

export class Product {

  _id: string;
  external_id?: string;
  image: string;
  name: string;
  status: number;
  price: number;
  unitPrice?: number;
  isHot: boolean;
  evendProduct: EvendProduct;
  content: Array<ProductContent>;
  brand: ProductBrand;
  categories: Array<ProductCategory>;
  tags: Array<any>;
  updatedAt: string;
  createdAt: string;

  constructor() {
    this.image = '';
    this.content = new Array<ProductContent>();
    this.brand = new ProductBrand();
    this.categories = [];
    this.categories.push(new ProductCategory());
    this.tags = [];
  }
}
