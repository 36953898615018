<div class="modal-header">
  <h4 class="modal-title">Ödeme Yöntemini Düzenle</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss({success:false})">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="options.marketplace" class="container">
    <div class="row">

      <div class="col">
        <select name="modem" class="custom-select select-product-modal select-custom-icon"
                [(ngModel)]="options.selectedPaymentType" (ngModelChange)="selectPaymentType($event)">
          <option [value]="null" disabled>Üyelik Türü Seçiniz</option>
          <option value="PERSONAL">Bireysel Satıcı</option>
          <option value="PRIVATE_COMPANY">Şahıs Şirketi</option>
          <option value="LIMITED_OR_JOINT_STOCK_COMPANY">Anonim/Ltd Şirketi</option>
        </select>
      </div>

    </div>
  </div>
  <div *ngIf="ft else loadingRef" class="container mt-4">
    <app-dynamic-form [questions]="ft" (submit)="update($event)" (formValue)="getValue($event)"></app-dynamic-form>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close({success:false})">İptal</button>
  <div class="col"></div>
  <button *ngIf="psData && psData.status" class="btn btn-primary" (click)="changeState(false)">Pasif Yap</button>
  <button [disabled]="formValue.paymentSystemsFirmMerchant === '' || formValue.paymentSystemsFirmMerchantApi === '' || formValue.paymentSystemsFirmMerchantPassword === ''"
          *ngIf="psData && !psData.status" class="btn btn-success" (click)="changeState(true)">Aktif Et</button>
  <button class="btn btn-primary" *ngIf="ft[0].controlType !== ''" (click)="submitForm()">Uygula</button>
</div>



<ng-template #loadingRef>
  <div class="d-flex w-100 h-100">
    <div class="loader" style="font-size: 50%"></div>
  </div>
</ng-template>
