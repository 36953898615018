<div class="container-fluid">
  <div class="row" id="header-menu">
    <nav class="navbar navbar-expand-lg navbar-light col-sm-6 col-md-8 col-lg-9">

      <button class="navbar-toggler ml-3" type="button" data-toggle="collapse" data-target="#ustMenu"
              aria-controls="ustMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand mx-auto" routerLink="/dashboard"><img width="auto" height="40" class="m-1" src="../../assets/img/logo-dashboard.png" alt=""></a>
      <div class="collapse navbar-collapse text-center" id="ustMenu">
        <ul class="navbar-nav font-weight-bold ml-auto" style="margin-right: 15%;">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link noselect" routerLink="/dashboard">{{'HEADER.DASHBOARD' | translate}}</a>
          </li>
          <li *ngIf="visible.automats" class="nav-item" routerLinkActive="active">
            <a class="nav-link dropdown-toggle noselect" id="automats" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false">{{'HEADER.VENDINGS' | translate}}</a>
            <div class="dropdown">
              <div class="dropdown-menu" aria-labelledby="automats">
                <a *ngxPermissionsOnly="['automats']" class="dropdown-item" routerLink="/automats" type="button">{{'HEADER.VENDING_DROPDOWN.VENDINGS' | translate}}</a>
                <a *ngxPermissionsOnly="['automat-groups']" class="dropdown-item" routerLink="/automat-groups"
                   type="button">{{'HEADER.VENDING_DROPDOWN.VENDING_GROUPS' | translate}}</a>
                <a *ngxPermissionsOnly="['add-automat']" class="dropdown-item" (click)="openAutomatModal()"
                   type="button">{{'HEADER.VENDING_DROPDOWN.NEW_VENDING' | translate}}</a>
              </div>
            </div>
          </li>
          <li *ngIf="visible.qrCode" class="nav-item" routerLinkActive="active">
            <a class="nav-link dropdown-toggle noselect" id="qrCodes" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false">{{'HEADER.QRCODES' | translate}}</a>
            <div class="dropdown">
              <div class="dropdown-menu" aria-labelledby="qrcodes">
                <a *ngxPermissionsOnly="['automats']" class="dropdown-item" routerLink="/qr-codes"
                   type="button">{{'HEADER.QRCODES_DROPDOWN.QRCODES' | translate}}</a>
                <a *ngxPermissionsOnly="['automat-groups']" class="dropdown-item" routerLink="/qr-codes-automat-groups"
                   type="button">{{'HEADER.QRCODES_DROPDOWN.QRCODES_GROUPS' | translate}}</a>
                  <a *ngxPermissionsOnly="['automat-groups']" class="dropdown-item" routerLink="/qr-codes-sub-users"
                     type="button">{{'HEADER.QRCODES_DROPDOWN.QRCODES_SUB_USER' | translate}}</a>
              </div>
            </div>
          </li>
          <li *ngIf="visible.cards" class="nav-item" routerLinkActive="active">
            <a class="nav-link dropdown-toggle noselect" id="cards" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false">{{'HEADER.CARDS' | translate}}</a>
            <div class="dropdown">
              <div class="dropdown-menu" aria-labelledby="cards">
                <a *ngxPermissionsOnly="['card-packets']" class="dropdown-item" routerLink="/card-packets"
                   type="button">{{'HEADER.CARDS_DROPDOWN.CARD_PACKAGES' | translate}}</a>
              </div>
            </div>
          </li>
          <li *ngIf="visible.sales" class="nav-item" routerLinkActive="active">
            <a class="nav-link dropdown-toggle noselect" id="sales" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false">{{'HEADER.SALES' | translate}}</a>
            <div class="dropdown">
              <div class="dropdown-menu" aria-labelledby="sales">
                <a *ngxPermissionsOnly="['sales']" class="dropdown-item" routerLink="/sales" type="button">{{'HEADER.SALES_DROPDOWN.SALES' | translate}}</a>
                <a *ngxPermissionsOnly="['proceeds']" class="dropdown-item" routerLink="/proceeds" type="button">{{'HEADER.SALES_DROPDOWN.COLLECTING' | translate}}</a>
                <a *ngxPermissionsOnly="['sales-report']" class="dropdown-item" routerLink="/sales-report"
                   type="button">{{'HEADER.SALES_DROPDOWN.PRODUCT_REPORT' | translate}}</a>
                <a *ngxPermissionsOnly="['basket-report']" class="dropdown-item" routerLink="/basket-report"
                   type="button">{{'HEADER.SALES_DROPDOWN.BASKET_REPORT' | translate}}</a>
                <a *ngxPermissionsOnly="['transaction-statement']" class="dropdown-item" routerLink="/transaction-statement"
                type="button">{{'HEADER.SALES_DROPDOWN.TRANSACTION_SUMMARY' | translate}}</a>
              </div>
            </div>
          </li>
          <li *ngxPermissionsOnly="['products']" class="nav-item" routerLinkActive="active">
            <a class="nav-link noselect" routerLink="/products">{{'HEADER.PRODUCTS' | translate}}</a>
          </li>
          <li *ngxPermissionsOnly="['personels']" class="nav-item" routerLinkActive="active">
            <a class="nav-link noselect" routerLink="/personels">{{'HEADER.PERSONNEL' | translate}}</a>
          </li>
          <li *ngxPermissionsOnly="['users']" class="nav-item" routerLinkActive="active">
            <a class="nav-link noselect" routerLink="/users">{{'HEADER.USERS' | translate}}</a>
          </li>
          <li *ngxPermissionsOnly="['service-care']" class="nav-item" routerLinkActive="active">
            <a class="nav-link noselect" routerLink="/service-care">{{'HEADER.SERVICE_MAINTENANCE' | translate}}</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="col-sm-6 col-md-6 col-lg-3 form-inline px-0">

      <div class="container-fluid my-2">
        <div class="row justify-content-end header-icon d-flex">
          <!--<div class="col-1 my-auto p-2 text-center">
            <a  title="Bildirimler"
                routerLink="/notifications">
              <i class="fas fa-bell" style="font-size:13px; color:#7b7b7b"></i>
            </a>
          </div>-->
          <div class="col-auto my-auto p-2 text-center">
            <a title="Sistem Mesajları"
               routerLink="/system-messages" style="font-size:13px; color: #7b7b7b">
              <i class="far fa-comments">
              </i>
            </a>
          </div>
          <div class="col-auto my-auto p-2 text-center">
            <a
              title="Ayarlar"
              routerLink="/settings/" style="font-size:13px; color: #7b7b7b">
              <i class="fas fa-cog"></i>
            </a>
          </div>
          <div class="col-1 my-auto p-2 text-center">
            <div class="header-menu-divider"></div>
          </div>
          <div class="col-auto my-auto">
            <app-language-selector></app-language-selector>
          </div>
          <div *ngIf="currentUser" id="header-username" class="col-auto my-auto p-2 text-center">
            <a placement="bottom" container="body" [ngbTooltip]="tipContent">
              {{currentUser.name}}
            </a>
            <ng-template #tipContent>{{currentUser.firm.name}} <br> {{currentUser.username}}</ng-template>
          </div>

          <div *ngIf="currentUser" class="col-auto my-auto text-center dropdown">
            <img *ngIf="currentUser.photo !== ''" [src]="currentUser.photo | image: ProfileImagePath"
                 default="../../assets/img/user_image.png"
                 alt="" class="rounded-circle" width="38" height="auto" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false">
            <img *ngIf="currentUser.photo === '' || currentUser.photo === undefined"
                 src="../../assets/img/user_image.png" style="cursor: pointer" width="38" height="auto"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" routerLink="/settings">Profil</a>
              <a class="dropdown-item" (click)="logout()">Çıkış Yap</a>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>

