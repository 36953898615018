import {Firm} from './firm';
import {FormTemplate} from './formTemplate';

export class FirmUser {

  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }

  _id: string;
  name: string;
  username: string;
  password?: string;
  phoneNumber: string;
  firm: Firm;
  photo: string;
  authRole: string;
  telemetryPermission: string[];


  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'name',
        label: 'Ad Soyad',
        value: this.name,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'username',
        label: 'Email',
        value: this.username,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'phoneNumber',
        label: 'Telefon',
        value: this.phoneNumber,
        required: true
      })
    ];

    return template;
  }
}
