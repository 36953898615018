import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImagePipe} from './image.pipe';
import { CommaDotSwapperPipe } from './comma-dot-swapper.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ImagePipe,
    CommaDotSwapperPipe,
  ],
  exports: [
    ImagePipe,
    CommaDotSwapperPipe
  ]
})
export class PipesModule {
}
