<div class="container mt-4">
  <form [formGroup]="inquireForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-12 mt-auto mb-auto">
        <ng-select #automatGroupsElement
        appearance="fill"
                  class="paymentSystemsSelect"
                  [items]="paymentSystemsFirms"
                  [multiple]="false"
                  [searchable]="false"
                  [searchFn]="searchPaymentSystems"
                  [closeOnSelect]="true"
                  [clearable]="false"
                  [hideSelected]="false"
                  bindLabel="name"
                  placeholder="Ödeme Sistemi Seçiniz"
                  [virtualScroll]="true"
                  formControlName="paymentSystemFirm"
                  (change)="onSelectPaymentSystemUpdate()" (close)="onSelectPaymentSystemClose()">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label align-self-center"> {{item.name}}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-md-6 col-lg-6 col-12 mt-auto mb-auto">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>İşlem Numarası giriniz</mat-label>
          <input matInput placeholder="İşlem Numarası giriniz" formControlName="paymentId" [ngClass]="{ 'is-invalid': submitted && f.paymentId.errors }">
        </mat-form-field>
      </div>
      <div class="col-md-5 col-lg-5 col-5 offset-md-7 offset-lg-7 mt-auto mb-auto">
        <button type="submit" class="h-100 p-3 rounded" mat-flat-button color="dark">Sorgula</button>
        <!-- <img *ngIf="f.paymentSystemFirm.value.photo" [src]="f.paymentSystemFirm.value.photo | image:paymentImagePath" alt="paymentSystemLogo" class="img-thumbnail"> -->
      </div>
    </div>
  </form>
  <mat-divider class="my-3"></mat-divider>
  <div *ngIf="submitted else loadingRef">
    <div class="row">
      <div class="col-md-11 col-lg-11 col-11 box ml-0 pl-3">
        <h2 class="font-weight-bold">
          İşlem Özeti
        </h2>
      </div>
      <div class="col-md-11 col-lg-11 col-11 border box ml-4">
        <div class="row ml-0 justify-content-between h-100">
          <div class="col-16 col-md-16 col-lg-16 m-auto">
            <span class="dark mx-1">İşlem durumu </span>
            <span class="red mx-1" *ngIf="paymentParameters?.canceled">İade Edildi</span>
            <span class="green mx-1" *ngIf="!paymentParameters?.canceled && paymentParameters?.isApproved">Ödendi</span>
          </div>
          <div class="col-8 col-md-8 col-lg-8 m-auto">
            <button type="button" class="rounded p-2" mat-flat-button color="warn" (click)="openDialog()" *ngIf="!paymentParameters?.canceled && paymentParameters?.isApproved">
              iptal/iade et
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-11 col-md-11 col-lg-11 border box">
        <div class="row">
          <div class="col-5 col-md-5 col-lg-5 m-auto p-0">
              <img *ngIf="paymentProductInfo.image !== null && paymentProductInfo.image !== undefined && paymentProductInfo.image !== ''" default="../../assets/img/gorsel_yok.svg"
               [src]="paymentProductInfo.image | image:productImagePath" alt="paymentAutomatInfoPhoto" class="img-thumbnail p-0">
              <img *ngIf="!paymentProductInfo.image" src="../../assets/img/gorsel_yok.svg" alt="paymentAutomatInfoPhoto" class="img-thumbnail p-0">
          </div>
          <div class="col-19 col-md-19 col-lg-19">
            <div class="row">
              <div class="col-10 col-md-10 col-lg-10 my-2">
                <span>Ürün Adı</span>
              </div>
              <div class="col-14 col-md-14 col-lg-14 my-2">
                <span class="font-weight-bold">{{paymentProductInfo?.productName}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-10 col-md-10 col-lg-10 my-2">
                <span>Adet</span>
              </div>
              <div class="col-14 col-md-14 col-lg-14 my-2">
                <span class="font-weight-bold">1</span>
              </div>
            </div>
            <div class="row">
              <div class="col-10 col-md-10 col-lg-10 my-2">
                <span>Fiyat</span>
              </div>
              <div class="col-14 col-md-14 col-lg-14 my-2">
                <span class="font-weight-bold">{{paymentProductInfo?.price}} ₺</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-11 col-md-11 col-lg-11 border box">
        <div class="row h-100 m-auto">
          <div class="col-5 col-md-5 col-lg-5 m-auto p-0">
              <img *ngIf="paymentAutomatInfo.image !== null && paymentAutomatInfo.image !== undefined && paymentAutomatInfo.image !== ''" default="../../assets/img/gorsel_yok.svg"
              [src]="paymentAutomatInfo.image | image:automatImagePath" alt="paymentAutomatInfoPhoto" class="img-thumbnail p-0">
              <img *ngIf="!paymentAutomatInfo.image" src="../../assets/img/gorsel_yok.svg" alt="paymentAutomatInfoPhoto" class="img-thumbnail p-0">
          </div>
          <div class="col-19 col-md-19 col-lg-19 m-auto">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 my-2">
                <span>Otomat Türü</span>
              </div>
              <div class="col-12 col-md-12 col-lg-12 my-2">
                <span class="font-weight-bold">{{paymentAutomatInfo?.automatModel}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 my-2">
                <span>Otomat Adı</span>
              </div>
              <div class="col-12 col-md-12 col-lg-12 my-2">
                <span class="font-weight-bold">{{paymentAutomatInfo?.automatName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-11 col-md-11 col-lg-11 border box" *ngIf="paymentProductInfo">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <p>Satın Alınan Ürün Bilgileri</p>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Adı</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentProductInfo?.productName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Fiyatı</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentProductInfo?.price}} ₺</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Spirali</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentProductInfo?.spiralNumber}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>İşlem Saati</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentProductInfo?.createdAt | date:'medium'}}</span>
          </div>
        </div>
      </div>
      <div class="col-11 col-md-11 col-lg-11 border box" *ngIf="paymentParameters">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <p>Satış işleminin parametreler</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <span>Otomata Bakiye Yüklendi</span>
          </div>
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <div *ngIf="paymentParameters?.paymentCompleted" class="d-flex align-items-center success">
              <mat-icon aria-hidden="false" aria-label="Example check icon" class="mr-1">check</mat-icon>
              <span class="ml-1">Onaylandı</span>
            </div>
            <div *ngIf="!paymentParameters?.paymentCompleted" class="d-flex align-items-center fails">
              <mat-icon aria-hidden="false" aria-label="Example remove_circle_outline icon" class="mr-1">remove_circle_outline</mat-icon>
              <span class="ml-1">Onaylanmadı</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <span>Seçim Yapıldı</span>
          </div>
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <div *ngIf="paymentParameters?.isDelivered" class="d-flex align-items-center success">
              <mat-icon aria-hidden="false" aria-label="Example check icon" class="mr-1">check</mat-icon>
              <span class="ml-1">Onaylandı</span>
            </div>
            <div *ngIf="!paymentParameters?.isDelivered" class="d-flex align-items-center fails">
              <mat-icon aria-hidden="false" aria-label="Example remove_circle_outline icon" class="mr-1">remove_circle_outline</mat-icon>
              <span class="ml-1">Onaylanmadı</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <span>Para Çekildi</span>
          </div>
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <div *ngIf="paymentParameters?.isApproved" class="d-flex align-items-center success">
              <mat-icon aria-hidden="false" aria-label="Example check icon" class="mr-1">check</mat-icon>
              <span class="ml-1">Onaylandı</span>
            </div>
            <div *ngIf="!paymentParameters?.isApproved" class="d-flex align-items-center fails">
              <mat-icon aria-hidden="false" aria-label="Example remove_circle_outline icon" class="mr-1">remove_circle_outline</mat-icon>
              <span class="ml-1">Onaylanmadı</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <span>Sensör Onay Verdi</span>
          </div>
          <div class="col-12 col-md-12 col-lg-12 my-2">
            <div *ngIf="paymentParameters?.sensorState" class="d-flex align-items-center success">
              <mat-icon aria-hidden="false" aria-label="Example check icon" class="mr-1">check</mat-icon>
              <span class="ml-1">Onaylandı</span>
            </div>
            <div *ngIf="!paymentParameters?.sensorState" class="d-flex align-items-center fails">
              <mat-icon aria-hidden="false" aria-label="Example remove_circle_outline icon" class="mr-1">remove_circle_outline</mat-icon>
              <span class="ml-1">Onaylanmadı</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-11 col-md-11 col-lg-11 border box" *ngIf="paymentAutomatInfo">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <p>Otomata Ait Bilgiler</p>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Otomat Adı</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentAutomatInfo?.automatName}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Lokasyonu</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentAutomatInfo?.location}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>Grubu </span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentAutomatInfo?.automatGroup}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-8 col-md-8 col-lg-8 my-2">
            <span>ID NO</span>
          </div>
          <div class="col-16 col-md-16 col-lg-16 my-2">
            <span>{{paymentAutomatInfo?.idNo}}</span>
          </div>
        </div>
      </div>
      <div class="col-11 col-md-11 col-lg-11 border box paymentInfo">
        <div *ngIf="paymentTransactionInfoIyzico && f.paymentSystemFirm.value.code === 0">
          <!-- Iyzico -->
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <p>Ödeme sistemi</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Status</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.status}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Yapılan isteğin sonucunu bildirir. İşlem başarılı ise success, hatalı ise failure döner.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Sistem Zamanı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.systemTime | date:'medium'}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Dönen sonucun o anki unix timestamp değeridir.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Fiyat</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.price}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödeme sepet tutarı. Kırılım tutarlar toplamı sepet tutarına eşit olmalı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Ödenen Fiyat</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.paidPrice}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="İndirim vade farkı vs. hesaplanmış POS’tan geçen, tahsil edilen, nihai tutar.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Taksit</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.installment}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemenin taksit bilgisi, tek çekim için 1 döner. Geçerli değerler: 1, 2, 3, 6, 9.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Ödeme Numanarı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.paymentId}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemeye ait id, üye işyeri tarafından mutlaka saklanmalıdır. Ödemenin iptali ve iyzico ile iletişimde kullanılır.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İyzico Komisyon Oranı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.iyziCommissionRateAmount}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemeye ait iyzico işlem komisyon tutarı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İyzico Komisyon Ücreti</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.iyziCommissionFee}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemeye ait iyzico işlem ücreti.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Tipi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.cardType}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Eğer ödeme yapılan kart yerel bir kart ise, kartın ait olduğu tipi. Geçerli değerler: CREDIT_CARD, DEBIT_CARD, PREPAID_CARD.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Kuruluşu</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.cardAssociation}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Eğer ödeme yapılan kart yerel bir kart ise, kartın ait olduğu kuruluş. Geçerli değerler: VISA, MASTER_CARD, AMERICAN_EXPRESS, TROY">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Ailesi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.cardFamily}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Eğer ödeme yapılan kart yerel bir kart ise, kartın ait olduğu aile. Geçerli değerler: Bonus, Axess, World, Maximum, Paraf, CardFinans, Advantage">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Bin Number</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.binNumber}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödeme yapılan kartın ilk 6 hanesi.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Son 4 Hane</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.lastFourDigits}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Son 4 hanesi.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Basket Id</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.basketId}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Üye işyeri tarafından gönderilen sepet id’si.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Para Birimi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.currency}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemenin alındığı para birimi.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Ödeme Durumu</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoIyzico?.paymentStatus}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödeme Durumu.">
                info
              </mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="paymentTransactionInfoParatika && f.paymentSystemFirm.value.code === 1">
          <!-- Paratika -->
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <p>Ödeme sistemi</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Dönen Mesaj</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.responseMsg}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        [ngbTooltip]="responseMsgDescription">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İşlem Adedi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionCount}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Yapılan işlem sayısı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Tutar</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].amount}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="İşlem tutarı bilgisidir. Önemli Not: Üye iş yerlerinin Paratika’ya ilettikleri tutar KDV dahil tutardır. Paratika ek olarak herhangi bir vergi hesaplaması yapmaz.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Para Birimi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].currency}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="ISO Alpha para birimi kodu. Örneğin: TRY, EUR, USD ...">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Ödeme Sistemi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].paymentSystem}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödeme sisteminin (banka ödeme geçidinin /vPOS hesabının) adı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Bin Değeri</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].bin}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="BIN, Bank Identification Number.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kartın Son 4 Hanesi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].panLast4}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Kart numarasının son 4 hanesi.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoParatika?.transactionList">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Sahibi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoParatika?.transactionList[0].cardOwnerMasked}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Kart üzerindeki isim. Maskelenmiş Format.">
                info
              </mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="paymentTransactionInfoZiraat && f.paymentSystemFirm.value.code === 2">
          <!-- Ziraat -->
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <p>Ödeme sistemi</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Yanıt</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Response}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ziraat Sanal POS üzerinden dönen yanıt.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Sipariş Numarası</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.OrderId}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemeye özel oluşturulmuş, ödeme numarası">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İşlem Tutarı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{+paymentTransactionInfoZiraat?.CC5Response?.Extra[0].CAPTURE_AMT / 100 | number}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ön otorizasyon kapama tutarı">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Numarası</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Extra[0].PAN}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödemenin alındığı Kart Numarası">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Otorizasyon Numarası</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Extra[0].AUTH_CODE}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Banko Onay Kodu">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İşlem Numarası</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Extra[0].TRANS_ID}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="İşlem numarası">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İşlem Tipi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Extra[0].CHARGE_TYPE_CD}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="S: Auth/PreAuth/PostAuth C: Refund">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoZiraat?.CC5Response?.Extra">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>İşlem Durumu</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoZiraat?.CC5Response?.Extra[0].TRANS_STAT}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="top" container="body"
                        [ngbTooltip]="TRANSSTATDescription">
                info
              </mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="paymentTransactionInfoPaynet && f.paymentSystemFirm.value.code === 3">
          <!-- Paynet -->
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <p>Ödeme sistemi</p>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Firma Adı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].card_brand_name}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Ödeme sisteminin (banka ödeme geçidinin /vPOS hesabının) adı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Sahibi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].card_holder}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Kart üzerindeki isim.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Kart Tipi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].card_type}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        [ngbTooltip]="cardTypeDescription">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Banka Adı</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].bank_name}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Banka Adı">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Banka Id</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].bank_id}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Bankanın Paynet sistemindeki kod">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Tutar</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].amount}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Kredi kartından çekilen tutar.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Net Tutar</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].netAmount}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Hizmet bedeli hariç tutar.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Komisyon</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].comission}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Hizmet bedeli tutarı.">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Para Birimi</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].currency}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="Para birimi">
                info
              </mat-icon>
            </div>
          </div>
          <div class="row" *ngIf="paymentTransactionInfoPaynet?.Data">
            <div class="col-12 col-md-12 col-lg-12 my-2">
              <span>Mesaj</span>
            </div>
            <div class="col-10 col-md-10 col-lg-10 my-2">
              <span>{{paymentTransactionInfoPaynet?.Data[0].message}}</span>
            </div>
            <div class="col-2 col-md-2 col-lg-2 my-2">
              <mat-icon aria-hidden="false" aria-label="Example info icon" class="mr-1" placement="left" container="body"
                        ngbTooltip="mesaj">
                info
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<ng-template #loadingRef>
  <div class="d-flex w-100 h-100 justify-content-center">
    <p class="text-center">Lütfen bir ödeme yöntemi seçin</p>
  </div>
</ng-template>
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<ng-template #responseMsgDescription>
  <ul>
    <li>00 =>   Onaylı	İşlem başarılı bir şekilde gerçekleşmiştir</li>
    <li>98 =>   Genel Hata</li>
    <li>99 =>   Reddedildi</li>
  </ul>
</ng-template>
<ng-template #cardTypeDescription>
  <ul class="px-3 text-left">
    <li>bc => Ticari Kart</li>
    <li>cc => Bireysel Kart</li>
    <li>dc => Debit Kart</li>
  </ul>
</ng-template>
<ng-template #TRANSSTATDescription>
  <ul class="px-3 text-left">
    <li>D => Başarısız işlem</li>
    <li>A => Otorizasyon, gün sonu kapanmadan</li>
    <li>C => Ön otorizasyon kapama, gün sonu kapanmadan</li>
    <li>PN => Bekleyen İşlem</li>
    <li>CNCL => İptal Edilmiş İşlem</li>
    <li>ERR => Hata Almış İşlem</li>
    <li>S => Satış</li>
    <li>R => Teknik İptal gerekiyor</li>
    <li>V => İptal</li>
  </ul>
</ng-template>
