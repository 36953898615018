import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import { requestParam } from '../offline-cards/transactions.datasource';

@Injectable({
  providedIn: 'root'
})
export class CashlessService {

  private idWatchSource = new BehaviorSubject<object>({id: '5b460be2d0ae0e073d0e330f', name: 'Test'});
  Id = this.idWatchSource.asObservable();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  changeId(Id: object) {
    this.idWatchSource.next(Id);
  }

  addOfflineUsers(users, offlineUserGroupId, offlineGroupAutomatId): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'addOfflineUsers/' + offlineUserGroupId,
      JSON.stringify({offlineUserArray: users, automatGroup: offlineGroupAutomatId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getOfflineUserGroupList(automatGroupId): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'getOfflineUserGroupList',
      JSON.stringify({automatGroupId: automatGroupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getOfflineUserGroups(firm): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'getOfflineUserGroups',
      JSON.stringify({firm: firm}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createOfflineUserGroup(OfflineUserGroup, offlineUsersArray, isExcel: boolean): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'offline-user-group',
      JSON.stringify({offlineUserGroup: OfflineUserGroup, offlineUser: offlineUsersArray, isExcel: isExcel}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createOfflineUser(OfflineUser): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'offlineUser',
      JSON.stringify(OfflineUser),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getOfflineUserList(offlineUserGorupId): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'getOfflineUserList/' + offlineUserGorupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateOfflineUserGroup(OfflineUserGroup: any, groupId): Observable<any> {
    /*eslint-disable */
    return this.http.put(environment.apiBaseUrl + 'offline-user-group/' + groupId,
      JSON.stringify(OfflineUserGroup),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deleteOfflineUserGroup(groupId): Observable<any> {
    /*eslint-disable */
    return this.http.delete(environment.apiBaseUrl
      + 'offline-user-group/' + groupId, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateOfflineUserBalance(offlineUser: any, processType: number, total: number): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'updateOfflineUserBalance/' + offlineUser._id,
      JSON.stringify({offlineUser: offlineUser, total: total, processType: processType}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  addOfflineUserGroupBalance(offlineUserGroupId: string, balance: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'offline-payment/add-offline-user-group-balance/' + offlineUserGroupId,
      JSON.stringify({balance: balance}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deductOfflineUserGroupBalance(offlineUserGroupId: string, balance: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'offline-payment/deduct-offline-user-group-balance/' + offlineUserGroupId,
      JSON.stringify({balance: balance}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  resetOfflineUserBalance(offlineUserId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'offline-user-reset-balance/' + offlineUserId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  resetAllBalanceOfGroup(offlineUserGroupId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'offline-payment/reset-offline-user-group-balance/' + offlineUserGroupId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getOfflineUserTransactions(page: number, offlineUserId: string, startDate, endDate): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'offline-user-transactions/' + page + '/' + offlineUserId,
      JSON.stringify({startDate: startDate, endDate: endDate}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getOfflineUserTransactions2(param: requestParam): Observable<any> {
    /*eslint-disable */
    return this.http.post(`${environment.apiBaseUrl}offline-user-transactions/`,
      JSON.stringify(param)).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  sendOfflineUserTransactionEmail(jsonData: any, toEmail: string, cardId: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'offline-user-transactions-email', JSON.stringify({jsonData: jsonData, toEmail: toEmail, cardId: cardId}),
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateOfflineUserState(offlineUserId: string, status: number): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'update-offline-user-state/' + offlineUserId,
      JSON.stringify({status: status}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updateOfflineUser(offlineUser: any, offlineUserId: string, processKey: boolean): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl + 'update-offline-user/' + offlineUserId,
      JSON.stringify({offlineUser: offlineUser, processKey: processKey}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getOldOfflineUserList(offlineUserId: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'oldOfflineUsers/' + offlineUserId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deleteOfflineUsers(offlineUserId: string[], groupId): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'deleteOfflineUsers',
      JSON.stringify({offlineUsersId: offlineUserId, groupId: groupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  createPeriodOfflineGroup(offlineGroupId: string, nextStartDate: string, isPeriod: boolean,
                           monthlyPeriod: string, constantDay: string,
                           periodBalance: string, sendMail: boolean, mail: string,
                           balanceTransfer: boolean): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'createPeriodInOfflineGroup',
      JSON.stringify({
        offlineGroupId: offlineGroupId, nextStartDate: nextStartDate, isPeriod: isPeriod, monthlyPeriod: monthlyPeriod,
        constantDay: constantDay, periodBalance: periodBalance, sendMail: sendMail, mail: mail, balanceTransfer: balanceTransfer
      }),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  deletePeriodOfflineGroup(offlineGroupId: string): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'deletePeriodInOfflineUserGroup',
      JSON.stringify({offlineGroupId: offlineGroupId}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  updatePeriodOfflineGroup(offlineGroupId: string, group): Observable<any> {
    /*eslint-disable */
    return this.http.post(environment.apiBaseUrl
      + 'updatePeriodInOfflineUserGroup',
      JSON.stringify({offlineGroupId: offlineGroupId, group: group}),
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAllOfflineUser(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'getAllOfflineUser', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

}
