import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TicketService} from '../_services/ticket.service';
import {Ticket} from '../_models/ticket';
import {TicketSubject} from '../_models/ticketSubject';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {SwalHelper} from '../_helpers/swal.helper';

@Component({
  selector: 'app-system-messages',
  templateUrl: './system-messages.component.html',
  styleUrls: ['./system-messages.component.css']
})
export class SystemMessagesComponent implements OnInit {

  swalOptions = new SwalHelper();

  @ViewChild('closeAddTicket', {static: false}) closeAddTicket: ElementRef;

  ProfileImagePath: string;
  ticket: Ticket;
  subjects: Array<any> = [];
  tickets: Array<Ticket> = [];
  page = 1;
  pages = 1;

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;

  //END : Ngx-spinner değişkenleri (Loading)

  constructor(private ticketService: TicketService,
              private loadingSpinner: NgxSpinnerService) {
    this.clearTicket();
    this.ProfileImagePath = environment.ProfileImagePath;
  }

  ngOnInit() {
    this.loadingSpinner.show();
    this.ticketService.getTicketSubjects().subscribe(res => {
      this.subjects = res.result;
      this.loadingSpinner.hide();
    });

    this.getAllTickets();
  }

  getAllTickets() {
    this.loadingSpinner.show();
    this.ticketService.getTickets(this.page).subscribe(res => {
      this.tickets = res.result.docs;
      this.pages = res.result.pages;
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Mesajlar sunucudan yüklenemedi!'
      });
    });
  }

  clearTicket() {
    this.ticket = new Ticket();
    this.ticket.subject = new TicketSubject();
    this.ticket.subject._id = '';
    this.ticket.messages = [];
  }

  addTicket() {
    if (!this.checkIsFieldsOkay()) {
      return;
    }
    this.loadingSpinner.show();
    this.ticketService.addTicket(this.ticket).subscribe(res => {
      this.clearTicket();
      this.page = 1;
      this.getAllTickets();
      this.closeAddTicket.nativeElement.click();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Mesaj başarıyla oluşturuldu!'
      });
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Mesaj oluşturulamadı!'
      });
    });
  }

  deleteTicket(id, index) {
    this.loadingSpinner.show();
    this.ticketService.deleteTicket(id).subscribe(res => {
      this.tickets.splice(index, 1);
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Mesaj başarıyla silindi!'
      });
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Mesaj silinemedi!'
      });
    });
  }

  deletePrompt(id, index) {
    Swal.fire({
      ...this.swalOptions.delete,
      text: 'Mesajı silmek istediğinize emin misiniz?'
    }).then((result) => {
      if (result.value) {
        this.deleteTicket(id, index);
      }

    });

  }

  onScroll() {
    this.page += 1;
    this.loadingSpinner.show();
    this.ticketService.getTickets(this.page).subscribe(res => {
      for (const item of res.result.docs) {
        this.tickets.push(item);
      }
      this.pages = res.result.pages;
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Mesajların devamı yüklenemedi!'
      });
    });
  }

  checkIsFieldsOkay(): boolean {
    this.loadingSpinner.show();
    let isOkay = true;
    if (this.ticket.subject._id === '') {
      isOkay = false;
      this.loadingSpinner.hide();
    }
    if (this.ticket.note === '' || this.ticket.note === undefined) {
      isOkay = false;
      this.loadingSpinner.hide();
    }
    if (!isOkay) {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Lütfen tüm alanları doldurunuz!'
      });
      return false;
    } else {
      return true;
    }
  }

}
