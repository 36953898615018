export class SalePaymentType {
  paymentMethodName: string;
  countOfTotalTransaction: number;
  volumeOfTotalTransaction: number;
  totalProductCount: number;

  constructor(name, count, value) {
    this.paymentMethodName = name;
    this.countOfTotalTransaction = count;
    this.volumeOfTotalTransaction = value;
  }
}
