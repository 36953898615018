<div class="modal-header">
  <h4 class="modal-title">{{ product._id ? 'Ürün Düzenle' : 'Ürün Ekle'}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="container">
      <div class="row mt-3">
        <div class="col">
          <ng-select
            *ngIf="!product._id; else editProductBrand"
            class="custom"
            #brandSelect
            [items]="_brands"
            [(ngModel)]="_brand"
            [virtualScroll]="true"
            placeholder="Markalar"
            [loading]="loading"
            (scrollToEnd)="onScrollBrand()"
            (change)="onSelectUpdate(true, 'brand')"
            (keyup)="onSelectUpdate(true, 'brand')"
            (clear)="onClear()"
            name="brand">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>
          <ng-template #editProductBrand>
            <input class="form-control no-border metin-girisi text-center" disabled [ngModel]="product.brand?.name" #name="ngModel"
               type="text" name="productBrand">
          </ng-template>
        </div>
        <div class="col">
          <ng-select
            *ngIf="!product._id; else editProductCategory"
            class="custom"
            #categorySelect
            [items]="_categories"
            [(ngModel)]="_category"
            [virtualScroll]="true"
            placeholder="Kategoriler"
            [loading]="loading"
            (scrollToEnd)="onScrollCategory()"
            (change)="onSelectUpdate(true, 'category')"
            (keyup)="onSelectUpdate(true, 'category')"
            (clear)="onClear()"
            name="categories">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>
          <ng-template #editProductCategory>
            <input class="form-control no-border metin-girisi text-center" disabled [ngModel]="product.categories[0]?.name" #name="ngModel"
               type="text" name="productCategory">
          </ng-template>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col" id="evendSel"  *ngIf="!product._id">

          <ng-select
            [items]="evendProducts"
            [(ngModel)]="product.evendProduct"
            [virtualScroll]="true"
            placeholder="Ürün seçiniz"
            [loading]="loading"
            (scrollToEnd)="onScroll()"
            #inputstring
            (keyup)="search(inputstring)"
            bindLabel="name" bindValue="" name="evendProduct">
            <ng-template ng-label-tmp let-item="item">
              <img *ngIf="item && item.image" [src]="item.image | image: ProductImagePath" height="50" width="50" default="../../../assets/img/gorsel_yok.svg">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <img *ngIf="item && item.image" [src]="item.image | image: ProductImagePath" height="50" width="50" default="../../../assets/img/gorsel_yok.svg">
              {{item.name}}
            </ng-template>
          </ng-select>

        </div>
        <div class="col" *ngIf="product._id">
          <input class="form-control no-border metin-girisi text-center" disabled [ngModel]="product.name" #name="ngModel"
               type="text" name="productName">
        </div>
        <div class="col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="product.external_id" #name="ngModel"
               type="text" name="external_id" placeholder="External Id">
               <!-- <i class="fa fa-info-circle mt-3" data-toggle="tooltip" data-placement="right" title="Opsiyonal"></i> -->
        </div>

      </div>

      <div class="row mt-3">
        <div class="input-group col">
          <input class="form-control no-border metin-girisi" [(ngModel)]="product.price"
          (keydown)="validatePrice($event)" #price="ngModel"
                 type="number" min="0" name="price" placeholder="Price 0.00">
          <div class="input-group-append">
            <span class="input-group-text no-border metin-girisi">₺</span>
          </div>
        </div>
        <div class="input-group col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="product.unitPrice"
          (keydown)="validatePrice($event)" #name="ngModel"
               type="number" min="0" name="unitPrice" placeholder="Unit Price 0.00">
          <div class="input-group-append">
            <span class="input-group-text no-border metin-girisi">₺</span>
          </div>
               <!-- <i class="fa fa-info-circle mt-3" data-toggle="tooltip" data-placement="right" title="Opsiyonal"></i> -->
        </div>

      </div>

      <div class="row mt-3">
        <div class="col">
          <input [(ngModel)]="product.isHot" #isHot="ngModel" type="checkbox" name="isHot"> Bu ürün bir sıcak içecektir.
        </div>
      </div>

      <div *ngIf="product.isHot">
        <div class="row mt-3 mx-1">
          <div class="col-md-10 offset-1 py-3" style="border-radius: 10px;background-color: #ededed;">
            <div class="row m-0 text-center">

              <div *ngFor="let pType of powderProducts; let ind=index" class="col-3">
                <span>{{pType.name}}</span>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text input-icon">
                      <img src="../../assets/img/editicon.svg">
                    </div>
                  </div>
                  <input *ngIf="productContents[ind]" class="form-control input-add-hot-drink"
                         [(ngModel)]="productContents[ind].value" [name]="pType.name"
                         style="background: #efefef;" type="number" placeholder="0">
                  <div class="input-group-prepend">
                    <!--productType === 9 su.-->
                    <div class="input-group-text input-icon">{{pType.productType === 9 ? 'ML' : 'GR'}}</div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div class="row mt-4 justify-content-center" *ngIf="product._id">
        <div class="col-4"></div>
        <div class="col-4">
          <div class="container py-4">
            <div class="row justify-content-center">
              <img class="product-pic" *ngIf="product.image === '' || !product.image" src="../../../assets/img/gorsel_yok.svg">
              <img class="product-pic" *ngIf="product.image !== ''" src="{{product.image | image: ProductImagePath}}" default="../../../assets/img/gorsel_yok.svg" alt="GÃ¶rsel yok">
            </div>
          </div>
        </div>
        <div class="col-4"></div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button *ngIf="product._id" class="btn btn-danger" (click)="deleteProduct(product._id)">Ürünü Sil</button>
  <div class="col"></div>
  <button *ngIf="!product._id" class="btn btn-primary" (click)="addProduct()">Kaydet</button>
  <button *ngIf="product._id" class="btn btn-primary" (click)="updateProduct()">Güncelle</button>
  <button class="btn btn-outline-primary" (click)="activeModal.dismiss()">İptal</button>
</div>


