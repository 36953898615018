import {Beacon} from './beacon';
import {Point} from './point';
import {AutomatModel} from './automatModel';

export class MarkerAutomat {
  constructor() {}
  _id: string;
  name: string;
  district: string;
  description: string;
  beacon: Beacon;
  status: number;
  automatModel: AutomatModel;
  location: Point;
  isCard: boolean;
  isCoin: boolean;
  isMobil: boolean;
  isPreSalesCard: boolean;
  isMetropolCardQR: boolean;
  isTelemetry: boolean;
  image: string;
  qrCode: string;
  locationText: string;
  modalName: string;
  neighborhood: string;
  operator: string;
  tag: string;
  serial: string;
  province: string;
  automatproduct: Array<any>;
  isOpen = false;
  lastInformed: Date;
  isRecentlyInformed: boolean;
  isHot: boolean;
}
