import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AutomatModelService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getAutomatModelsByBrand(automatBrand: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat-models-by-brand/' + automatBrand, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getAutomatModel(automatModel: string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'automat-model/' + automatModel, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

}
