import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';
import {EvendProductSearchFilter} from '../_models/evendProduct';
import { Product } from '../_models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getAllProducts(isHot): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'products-all/' + isHot).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


  getBasketHistoryProducts(basketHistoryId): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'getBasketHistoryProducts',
      JSON.stringify({basketHistoryId: basketHistoryId})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  searchProducts(page: number, category: string, brand: string, name: string): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'search-products/' + page,
      JSON.stringify({categoryId: category, brandId: brand, name: name})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getEvendProducts(page: number, params: EvendProductSearchFilter): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'get-evend-products/' + page,
      JSON.stringify(params))
      .pipe(
        catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createProduct(product: Product): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'product',
      JSON.stringify({content: product.content,
        evendProduct: product.evendProduct._id,
        external_id: product.external_id,
        price: product.price,
        unitPrice: product.unitPrice})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateProduct(product: any, id: string): Observable<any> {
    return this.http.put(environment.apiBaseUrl + 'product/' + id,
      JSON.stringify(product)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getProductsReport(page, params: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'search-products-report/' + page,
      JSON.stringify(params)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getProductsReportIncome(params: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'get-product-report-income',
    JSON.stringify(params)).pipe(
    catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getAllProductsReport(params: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'search-products-report-for-output/',
      JSON.stringify(params)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getTelemetryTransaction(id): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'get-telemetry-transaction/',
      JSON.stringify({id: id})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getBasketsReport(page, params: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'search-products-report-basket/' + page,
      JSON.stringify(params)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  deleteProduct(_id: string): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + 'product/' + _id).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createAutomatProduct(automatProduct: any, spiralNumber: number, spiralCount: number): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'automat-product/' + spiralNumber + '/' + spiralCount,
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createAutomatHotProduct(automatProduct: any, spiralIndex: number): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'create-automat-hot-product/' + spiralIndex,
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


  createAutomatProductMulti(automatProduct: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'automat-product-multi/',
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createAutomatHotProductMulti(automatProduct: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'create-automat-hot-product-multi/',
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateAllProducts(data: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'update-automat-product-by-automat-product/',
      JSON.stringify(data)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  deleteAutomatProduct(automatProduct: any, spiralNumber: number, automat: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'automat-product-delete/' + spiralNumber,
      JSON.stringify({automatProductId: automatProduct._id, automat: automat})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateAutomatProduct(automatProduct: any, count: number, issueId: string): Observable<any> {
    return this.http.put(environment.apiBaseUrl + 'automat-product/' + issueId,
      JSON.stringify({automatProduct: automatProduct, count: count})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateAutomatHotProduct(automatProduct: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'update-automat-hot-product/',
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateAutomatProductBySpiral(automatProduct: any, id: string, automatId: string,
                               issueId: string, firmUser: string): Observable<any> {
    return this.http.patch(environment.apiBaseUrl + 'update-automat-product/' +
      id + '/' + issueId,
      JSON.stringify(automatProduct)).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  updateAutomatSpiralCount(automatId: string, spiralNumber: number, count: number): Observable<any> {
    return this.http.put(environment.apiBaseUrl + 'update-automat-spiral-count/',
      JSON.stringify({automatId: automatId, spiralNumber: spiralNumber, count: count})).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  createAutomatProductDiscount(params: any, id): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'automat-product-campaign',
      JSON.stringify({
        startDate: params.startDay, endDate: params.endDay,
        discountPrice: params.discountPrice, id: id
      })).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


    getReportReceiptByPaymentForResult(paymentId: string, voidReceipt: boolean): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}payment/param/pos-receipt-by-payment/${paymentId}`, {voidReceipt}).pipe(
            catchError((error: any) => observableThrowError(error.error || 'Server error')));
    }

    getAllPaymentReceiptByPayment(paymentId: string): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}payment/param/pos-receipt-by-payment/${paymentId}`).pipe(
            catchError((error: any) => observableThrowError(error.error || 'Server error')));
    }


}
