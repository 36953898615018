import {Injectable} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';


@Injectable({
  providedIn: 'root'
})
export class ThemeingHelper {

  private colors = [
    ['#FF83A7', '#FF83A740'],
    ['#D483FF', '#D483FF40'],
    ['#8388FF', '#8388FF40'],
    ['#54A6DE', '#54A6DE40'],
    ['#83D4FF', '#83D4FF40'],
    ['#56D3D9', '#56D3D940'],
    ['#2BD281', '#2BD28140'],
    ['#28A844', '#28A84440'],
    ['#FE8889', '#FE888940'],
    ['#FF0507', '#FF050740'],
    ['#D4D7DD', '#D4D7DD40'],
    ['#9B9B9B', '#9B9B9B40']
  ];

  private chartColors = [
    am4core.color('#FF83A7'), // nakit
    am4core.color('#D483FF'), // kredi kartı
    am4core.color('#8388FF'), // offline
    am4core.color('#54A6DE'), // mobil
    am4core.color('#83D4FF'), //
    am4core.color('#56D3D9'), //
    am4core.color('#2BD281'), //
    am4core.color('#28A844'), //
    am4core.color('#FE8889'), //
    am4core.color('#FF0507'), //
  ];

  public barColor(i: number): string {
    switch (i < 10) {
      case true:
        return this.colors[i][0];
      default:
        return '#D4D7DD';
    }
  }

  public textColor(i: number): Array<string> {
    switch (i < 10) {
      case true:
        return this.colors[i];
      default:
        return ['#D4D7DD', '#D4D7DD40'];

    }
  }

  public getAllChartColors() {
    return this.chartColors;
  }

}
