import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: string, path?: any): string {
    return value  !== '' && value !== undefined && value !== null ? path + value : '';
  }

}
