import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgbCalendar, NgbDate, NgbInputDatepicker, NgbInputDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {Moment} from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent {

  @ViewChild('datepicker', {static: false}) datepicker: NgbInputDatepicker;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  fromTime = {hour: 0, minute: 0};
  toTime = {hour: 23, minute: 59};

  _InputDate: Moment[];

  @Input()
  set InputDate(data: Moment[]) {
    if (data && data.length === 2) {
      this._InputDate = data;
      this.toNgb(this._InputDate[0], this.fromDate);
      this.toNgb(this._InputDate[1], this.toDate);
    }
  }

  @Output() newDate = new EventEmitter<Moment[]>();

  constructor(private calendar: NgbCalendar, private ngbconfig: NgbInputDatepickerConfig) {
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', 1);
    this.toDate = calendar.getToday();

  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;

    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }


  toNgb(MomentSrc: Moment, ngbTarget: NgbDate) {
    ngbTarget.year = MomentSrc.get('year');
    ngbTarget.month = MomentSrc.get('month') + 1;
    ngbTarget.day = MomentSrc.get('date');
  }

  toMoment(ngbSrc: NgbDate, MomentTarget: Moment, hour: number, min: number, sec: number) {
    MomentTarget.set('year', ngbSrc.year);
    MomentTarget.set('month', ngbSrc.month - 1);
    MomentTarget.set('date', ngbSrc.day);
    MomentTarget.set('hour', hour);
    MomentTarget.set('minute', min);
    MomentTarget.set('second', sec);
  }

  open() {
    this.datepicker.open();
  }

  send() {
    if (this.fromDate) {
      if (this.toDate && this.toDate.after(this.fromDate)) {
        this.toMoment(this.toDate, this._InputDate[1], this.toTime.hour, this.toTime.minute, 59);
      } else {
        this.toMoment(this.fromDate, this._InputDate[1], this.toTime.hour, this.toTime.minute, 59);
      }
      this.toMoment(this.fromDate, this._InputDate[0], this.fromTime.hour, this.fromTime.minute, 0);
      this.newDate.emit(this._InputDate);
      this.datepicker.close();
    }
  }

}
