import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../_guards/auth.guard';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {CardPacketsComponent} from './card-packets.component';

const routes: Routes = [
  {
    path: '',
    component: CardPacketsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'card-packets',
        redirectTo: '/dashboard'
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardPacketsRoutingModule {
}
