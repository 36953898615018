import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {AuthGuard} from '../_guards/auth.guard';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {AutomatsComponent} from './automats.component';

const routes: Routes = [
  {
    path: '',
    component: AutomatsComponent,
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'automats',
        redirectTo: '/dashboard'
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutomatsRoutingModule {
}
