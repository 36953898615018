import {BehaviorSubject, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgxPermissionsService} from 'ngx-permissions';
import {environment} from '../../environments/environment';


export const allPerms = [
  'automats',
  'add-automat',
  'edit-automat',
  'delete-automat',
  'automat-detail',
  'automat-detail-products',
  'automat-detail-sales',
  'automat-detail-proceeds',
  'automat-detail-personels',
  'automat-detail-errors',
  'automat-groups',
  'automat-groups-edit',
  'sales',
  'sales-report',
  'transaction-statement',
  'proceeds',
  'basket-report',
  'products',
  'products-edit',
  'personels',
  'personels-edit',
  'users',
  'users-edit',
  'service-care',
  'card-packets',
  'card-packets-edit',
  'offline-cards',
  'offline-cards-edit',
  'settings-firminfo',
  'settings-payment',
  'settings-payment-method',
  'settings-payment-mobile',
  'settings-payment-templates',
  'settings-payment-systems',
  'settings-payment-orders'
];

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  // deneme... Normalde 1 kere servise gidilip alınmalı
  private userperms = new BehaviorSubject([]);

  constructor(private http: HttpClient,
              private permissionService: NgxPermissionsService) {
  }


  userPermissions(perms?): Promise<any> {

    if (perms) {
      this.userperms.next(perms);
      this.permissionService.loadPermissions(this.userperms.getValue());
      return;
    }

    const localuser = JSON.parse(localStorage.getItem('currentUser'));
    if (localuser && !localuser.user.subFirmUser) {
      return this.http.get(environment.apiBaseUrl + 'get-telemetry-user-info/' + localuser.user._id)
        .toPromise()
        .then((res: any) => {
          this.userperms.next(res.telemetryPermission);
          this.permissionService.loadPermissions(this.userperms.getValue());
        })
        // ...errors if any
        .catch((error: any) => observableThrowError(error.error || 'Server error'));
    } else if (localuser && localuser.user.subFirmUser) {
          return this.http.get(environment.apiBaseUrl + 'get-telemetry-sub-user-info/' + localuser.user._id)
              .toPromise()
              .then((res: any) => {
                  this.userperms.next(res.telemetryPermission);
                  this.permissionService.loadPermissions(this.userperms.getValue());
              })
              // ...errors if any
              .catch((error: any) => observableThrowError(error.error || 'Server error'));
      }
    return null;

  }

}

