import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CashlessService} from '../_services/cashless.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import {OfflineUserGroupService} from '../_services/offline-user-group.service';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewCardPacketComponent} from './new-card-packet/new-card-packet.component';
import {LoadBalanceCardPacketComponent} from './load-balance-card-packet/load-balance-card-packet.component';
import {DeductBalanceCardPacketComponent} from './deduct-balance-card-packet/deduct-balance-card-packet.component';
import {OfflineUserGroup} from '../_models/offlineUserGroup';
import {AddUserToCardPacketComponent} from './add-user-to-card-packet/add-user-to-card-packet.component';

@Component({
  selector: 'app-card-packets',
  templateUrl: './card-packets.component.html',
  styleUrls: ['./card-packets.component.css']
})
export class CardPacketsComponent implements OnInit {

  swalOptions = new SwalHelper();

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)

  groupData: Array<{ totalUser: number, offlineUserGroup: OfflineUserGroup }> = [];
  controlVariables = {isInEdit: false, index: -1, offlineUserGroupId: null};
  group: OfflineUserGroup = new OfflineUserGroup();

  constructor(private cashlessService: CashlessService,
              private offlineUserGroupService: OfflineUserGroupService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService,
              private cdRef: ChangeDetectorRef,
              private exportsHelper: ExportsHelperService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.getGroups();
  }

  getGroups() {
    this.loadingSpinner.show();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.cashlessService.getOfflineUserGroups(currentUser.user.firm._id).subscribe(res => {
        this.groupData = res;
        this.loadingSpinner.hide();
      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart paketleri sunucudan getirilemedi!'
        });
      });
  }

  clearGroup() {
    this.controlVariables.isInEdit = false;
    this.controlVariables.offlineUserGroupId = null;
    this.controlVariables.index = null;
    this.group = new OfflineUserGroup();
    //new card packet modal
    this.openNewCardPacketModal();
  }

  setGroupForNewPacket(group, index, id) {
    this.group = Object.assign({}, group);
    this.controlVariables.index = index;
    this.controlVariables.isInEdit = true;
    this.controlVariables.offlineUserGroupId = id;
    //open new packet modal
    this.openNewCardPacketModal();
  }

  openNewCardPacketModal() {
    const modalRef = this.modalService.open(NewCardPacketComponent, {centered: true});
    modalRef.componentInstance.selectedGroup = this.group;
    modalRef.componentInstance.conVariables = this.controlVariables;
    modalRef.componentInstance.submit.subscribe((res: { success: boolean, code: number, data: any }) => {
      if (res.success) {
        this.getGroups();
        this.modalService.dismissAll();
      }
    });
  }

  setGroup(group, index, id) {
    this.group = Object.assign({}, group);
    this.controlVariables.index = index;
    this.controlVariables.isInEdit = true;
    this.controlVariables.offlineUserGroupId = id;
    //openModal load-balance-card-packet
    const modalRef = this.modalService.open(LoadBalanceCardPacketComponent, {centered: true});
    modalRef.componentInstance.selectedGroup = this.group;
    modalRef.componentInstance.conVariables = this.controlVariables;
    modalRef.componentInstance.submit.subscribe((res) => {
      if (res.success) {
        this.getGroups();
        this.modalService.dismissAll();
      }
    });
  }

  deductGroupBalance(group, index, id) {
    this.group = Object.assign({}, group);
    this.controlVariables.index = index;
    this.controlVariables.isInEdit = true;
    this.controlVariables.offlineUserGroupId = id;
    //openModal load-balance-card-packet
    const modalRef = this.modalService.open(DeductBalanceCardPacketComponent, {centered: true});
    modalRef.componentInstance.selectedGroup = this.group;
    modalRef.componentInstance.conVariables = this.controlVariables;
    modalRef.componentInstance.submit.subscribe((res) => {
      if (res.success) {
        this.getGroups();
        this.modalService.dismissAll();
      }
    });
  }

  addOfflineUser(offlineUserGroupId) {
    this.controlVariables.offlineUserGroupId = offlineUserGroupId;
    //open add user modal
    const modalRef = this.modalService.open(AddUserToCardPacketComponent, {centered: true});
    modalRef.componentInstance.groups = this.groupData;
    modalRef.componentInstance.conVariables = this.controlVariables;
    modalRef.componentInstance.submit.subscribe((res) => {
      if (res.success) {
        this.getGroups();
        this.modalService.dismissAll();
      }
    });
  }


  goToGroupUsers(group) {
    this.cashlessService.changeId({id: group._id, name: group.name});
    this.router.navigate(['/offline-cards']);
  }

  goToOfflineCardGroupPeriod(group) {
    localStorage.setItem('offlineUserGroupId', group._id);
    // this.offlineUserGroupService.changeId({ id: group._id});
    this.router.navigate(['/offline-card-group-period']);
  }

  deleteGroup(groupId, index) {
    this.controlVariables.index = index;
    Swal.fire({
      ...this.swalOptions.delete,
      title: 'Kart paketini silmek istediğinize emin misiniz?',
      text: 'Paket altındaki kartlar da silinecektir'
    }).then((result) => {
      if (result.value) {
        this.loadingSpinner.show();
        this.cashlessService.deleteOfflineUserGroup(groupId).subscribe(res => {
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Kart grubu silindi'
          });
          this.groupData.splice(this.controlVariables.index, 1);
          this.loadingSpinner.hide();
        });
      }

    });

  }

  createUserReport() {
    this.loadingSpinner.show();
    this.cashlessService.getAllOfflineUser().subscribe(res => {
      if (res) {
        this.exportsHelper.cardPacketsDownloadExcel(res.dataAllOfflineUser);
        this.loadingSpinner.hide();
      } else {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kullanıcı Raporu Oluştururken Hata Meydana Geldi!'
        });
      }

    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kullanıcı Raporu Oluştururken Hata Meydana Geldi!'
      });
    });
  }

}
