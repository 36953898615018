import {Moment} from 'moment';

export class Sales {
  date: Moment;
  hourOfDay: number;
  volumeOfTotalTransaction: number;
  countOfTotalTransaction: number;
  totalProductCount: number;
  countOfOnlineCardTransaction: number;
  volumeOfOnlineCardTransaction: number;
  countOfOfflineCardTransaction: number;
  volumeOfOfflineCardTransaction: number;
  countOfCashTransaction: number;
  volumeOfCashTransaction: number;
  countOfQrTransaction: number;
  volumeOfQrTransaction: number;
  countOfMetropolCardQrTransaction: number;
  volumeOfMetropolCardQrTransaction: number;

  constructor(date: Moment) {
    this.date = date;
    this.volumeOfTotalTransaction = null;
    this.countOfTotalTransaction = null;
    this.totalProductCount = null;
    this.countOfOnlineCardTransaction = null;
    this.volumeOfOnlineCardTransaction = null;
    this.countOfOfflineCardTransaction = null;
    this.volumeOfOfflineCardTransaction = null;
    this.countOfCashTransaction = null;
    this.volumeOfCashTransaction = null;
    this.countOfQrTransaction = null;
    this.volumeOfQrTransaction = null;
    this.countOfMetropolCardQrTransaction = null;
    this.volumeOfMetropolCardQrTransaction = null;
  }
}

export class SalesChartItem {
  time: string;
  hourOfDay: number;
  date: Moment;
  volumeOfTotalTransaction: number;
  countOfTotalTransaction: number;
  totalProductCount: number;
  countOfOnlineCardTransaction: number;
  volumeOfOnlineCardTransaction: number;
  countOfOfflineCardTransaction: number;
  volumeOfOfflineCardTransaction: number;
  countOfCashTransaction: number;
  volumeOfCashTransaction: number;
  countOfQrTransaction: number;
  volumeOfQrTransaction: number;
  countOfMetropolCardQrTransaction: number;
  volumeOfMetropolCardQrTransaction: number;

  constructor(time: string,
              item: Sales) {
    this.time = time;
    this.setSale(item);
  }

  setSale(item: Sales) {
    this.date = item.date;
    this.hourOfDay = item.hourOfDay;
    this.volumeOfTotalTransaction = item.volumeOfTotalTransaction;
    this.countOfTotalTransaction = item.countOfTotalTransaction;
    this.totalProductCount = item.totalProductCount;
    this.countOfOnlineCardTransaction = item.countOfOnlineCardTransaction;
    this.volumeOfOnlineCardTransaction = item.volumeOfOnlineCardTransaction;
    this.countOfOfflineCardTransaction = item.countOfOfflineCardTransaction;
    this.volumeOfOfflineCardTransaction = item.volumeOfOfflineCardTransaction;
    this.countOfCashTransaction = item.countOfCashTransaction;
    this.volumeOfCashTransaction = item.volumeOfCashTransaction;
    this.countOfQrTransaction = item.countOfQrTransaction;
    this.volumeOfQrTransaction = item.volumeOfQrTransaction;
    this.countOfMetropolCardQrTransaction = item.countOfMetropolCardQrTransaction;
    this.volumeOfMetropolCardQrTransaction = item.volumeOfMetropolCardQrTransaction;
  }
}
