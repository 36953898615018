<div class="container mt-4">
  <div class="row">
    <div class="col-md-6 col-lg-4 allAutomatsBtn">
      <div class="gri-yuvarlak-cerceve align-items-center justify-content-center mb-2 h-100" (click)="getAllAutomats()"
           [ngStyle]="{'background-color': allAutomatsSelected}">
        <span style="font-weight: bold" [ngStyle]="{'color': textAll}">{{'SALES.ALL_VENDINGS' | translate}}</span>
      </div>
    </div>
    <div class="col-md-6 col-lg-4">
      <ng-select #automatGroupsElement
                 class="automatselect"
                 [items]="automatGroups"
                 [multiple]="true"
                 [searchable]="true"
                 [searchFn]="searchAutomat"
                 [closeOnSelect]="false"
                 [clearable]="false"
                 [hideSelected]="false"
                 bindLabel="name"
                 bindValue="_id"
                 placeholder="{{'SALES.SELECT_GROUP' | translate}}"
                 [virtualScroll]="true"
                 [(ngModel)]="sale.group"
                 (change)="onSelectGroupUpdate()" (close)="onSelectGroupClose()">
        <ng-template ng-header-tmp>
          <button (click)="selectAllGroup()" class="btn btn-sm btn-primary">{{'SALES.SELECT_ALL' | translate}}</button>
          <button (click)="unselectAllGroup()" class="btn btn-sm btn-secondary ml-2">{{'SALES.REMOVE_SELECTIONS' | translate}}</button>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice:0:1">
            <span class="ng-value-label align-self-center"> {{item.name}}</span>
          </div>
          <div class="automat-labelplus" *ngIf="items.length > 1">
            <span>+ {{items.length - 1}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-md-6 col-lg-4">
      <ng-select #automatListElement
                 class="automatselect"
                 [items]="automatList"
                 [multiple]="true"
                 [searchable]="true"
                 [searchFn]="searchAutomat"
                 [closeOnSelect]="false"
                 [hideSelected]="false"
                 bindLabel="name"
                 bindValue=""
                 placeholder="{{'SALES.SELECT_VENDING' | translate}}"
                 [virtualScroll]="true"
                 [(ngModel)]="sale.automat"
                 (change)="onSelectAutomatUpdate(sale.automat)" (close)="onSelectAutomatClose()">
        <ng-template ng-header-tmp>
          <button (click)="selectAllAutomat()" class="btn btn-sm btn-primary">{{'SALES.SELECT_ALL' | translate}}</button>
          <button (click)="unselectAllAutomat()" class="btn btn-sm btn-secondary ml-2">{{'SALES.REMOVE_SELECTIONS' | translate}}</button>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice:0:1">
            <span class="ng-value-label align-self-center"> {{item.name}}</span>
          </div>
          <div class="automat-labelplus" *ngIf="items.length > 1">
            <span>+ {{items.length - 1}}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>


  </div>
</div>

<!-- SATIŞ RAPORLARI : START -->
<div class="container my-4 mt-5  w-100">


  <div class="row">
    <div class="col-sm-12 col-md-6">
      <span class="subtitle">{{'SALES.GENEL_TURNOVER' | translate}}</span>
    </div>
    <div *ngIf="currentUser.user.firm._id === '5caf19d0da0da4580adb510e'" class="col-md-3">
      <div>
        <button class="btn btn-detail-sale-report" data-toggle="modal" data-backdrop="true"
                data-target="#thirdPartySoftware">{{'SALES.SEND_REPORT' | translate}}
        </button>
      </div>
    </div>
    <div *ngIf="currentUser.user.firm._id !== '5caf19d0da0da4580adb510e'" class="col-md-3">
    </div>
    <div class="col-sm-12 col-md-3">
      <button *ngxPermissionsOnly="['sales-report']" (click)="redirect('sales-report')"
              class="btn btn-detail-sale float-right">{{'SALES.DETAILED_SALES_REPORT' | translate}}
      </button>
    </div>
  </div>

  <hr class="mt-2 mb-0">
  <div class="row rapor-detay-baslik">

    <div class="col-md py-3">
      <a (click)="raporTabClick(1)">
        <div class="row">
          <div class="col-md-9">
            {{'SALES.TODAY' | translate}} <br>
            <div *ngIf="isTodaySummaryReady">
              <span class="header-weighted"
                    [ngClass]="currentSaleReportTab === 1 ? 'rapor-active-detay' : 'rapor-blue-detay'">
                {{dailysum?.amount || 0 | number | commaDotSwapper}}
              </span>
              <span class="header-unit">₺</span>
            </div>
            <div>
              <ng-template *ngIf="isTodaySummaryReady === false" [ngTemplateOutlet]="dataLoadingborderlesssmall" class="chartdiv"></ng-template>
            </div>
            <span>{{dailysum?.count || 0}} {{'SALES.PRODUCT' | translate}}</span>
          </div>
          <div class="col-md px-0 align-self-center">
            <!--<span class="badge badge-danger p-2">-5%</span>-->
          </div>
        </div>
      </a>
    </div>

    <div class="col-md py-3">
      <a (click)="raporTabClick(2)">
        <div class="row">
          <div class="col-md-9">
            {{'SALES.THIS_WEEK' | translate}} <br>
            <div *ngIf="isThisWeekSummaryReady">
              <span class="header-weighted"
                    [ngClass]="currentSaleReportTab === 2 ? 'rapor-active-detay' : 'rapor-blue-detay'">
                {{weeklysum?.amount || 0 | number | commaDotSwapper }}
              </span>
              <span class="header-unit">₺</span>
            </div>
            <div>
              <ng-template *ngIf="isThisWeekSummaryReady === false" [ngTemplateOutlet]="dataLoadingborderlesssmall" class="chartdiv"></ng-template>
            </div>
            <span>{{weeklysum?.count || 0}} {{'SALES.PRODUCT' | translate}}</span>
          </div>
          <div class="col-md px-0 align-self-center">
            <!--<span class="badge badge-danger p-2">-5%</span>-->
          </div>
        </div>
      </a>
    </div>

    <div class="col-md py-3">
      <a (click)="raporTabClick(3)">
        <div class="row">
          <div class="col-md-9">
            {{'SALES.THIS_MONTH' | translate}} <br>
            <div *ngIf="isThisMonthSummaryReady">
              <span class="header-weighted"
                    [ngClass]="currentSaleReportTab === 3 ? 'rapor-active-detay' : 'rapor-blue-detay'">
                {{monthlysum?.amount || 0 | number | commaDotSwapper }}
              </span>
              <span class="header-unit">₺</span>
            </div>
            <div>
              <ng-template *ngIf="isThisMonthSummaryReady === false" [ngTemplateOutlet]="dataLoadingborderlesssmall" class="chartdiv"></ng-template>
            </div>
            <span>{{monthlysum?.count || 0}} {{'SALES.PRODUCT' | translate}}</span>

          </div>
          <div class="col-md px-0 align-self-center">
            <!--<span class="badge badge-success p-2">+12%</span>-->
          </div>
        </div>
      </a>
    </div>

    <div class="col-md py-3">
      <a (click)="raporTabClick(4)">
        <div class="row">
          <div class="col-md-9">
            {{'SALES.SPECIAL_CAPS' | translate}} <br>

            <span class="header-weighted"
                  [ngClass]="currentSaleReportTab === 4 ? 'rapor-active-detay' : 'rapor-blue-detay'">
              {{customsum?.amount || 0 | number | commaDotSwapper }}
            </span>
            <span class="header-unit">₺</span>
            <br>
            <span>{{customsum?.count || 0}} {{'SALES.PRODUCT' | translate}}</span>

          </div>
          <div class="col-md px-0 align-self-center">
            <!--<span class="badge badge-success p-2">+12%</span>-->
          </div>
        </div>
      </a>
      <app-date-picker #dPicker [InputDate]="customTime"
                       (newDate)="customDateSelected($event)"></app-date-picker>
    </div>

  </div>
  <hr class="m-0">
</div>

<div class="container animated fadeIn mt-4">
  <div class="row d-flex mb-1">
    <p class="col-sm-auto col-12 rowbaslık my-auto mr-auto">SATIŞLAR</p>
    <button [disabled]="!(salesChartArray && salesChartArray.length)"
            class="btn btn-outline-primary col-auto export-button"
            (click)="downloadExcell(0)">
      <i class="fa fa-file-excel mr-1"></i>{{'SALES.EXPORT' | translate}}
    </button>
    <select [(ngModel)]="widgetDates[0]" (ngModelChange)="getSales()" class="col-auto ml-1 w-auto custom-select">
      <option [ngValue]="1">{{'SALES.DAY' | translate}}</option>
      <option [ngValue]="2">{{'SALES.WEEK' | translate}}</option>
      <option [ngValue]="3">{{'SALES.MONTH' | translate}}</option>
      <option [ngValue]="4">{{'SALES.SPECIAL' | translate}}</option>
    </select>
  </div>
  <div class="row">
    <div class="col-12 chartdiv" [ngClass]="{'chartcollapsed': isSalesChart === false}" id="satis-rapor-chart"></div>
    <ng-template *ngIf="isSalesChart === false" [ngTemplateOutlet]="dataLoadingSales" class="chartdiv"></ng-template>
  </div>
</div>


<div class="container animated fadeIn mt-4">
  <div class="row d-flex mb-1">
    <p class="col-sm-auto col-12 rowbaslık my-auto mr-auto">ÖDEME DAĞILIMI</p>
    <button [disabled]="noPayment || !(paymentChartArray && paymentChartArray.length)"
            class="btn btn-outline-primary col-auto export-button"
            (click)="downloadExcell(1)">
      <i class="fa fa-file-excel mr-1"></i>{{'SALES.EXPORT' | translate}}
    </button>
    <select [(ngModel)]="widgetDates[1]" (ngModelChange)="getPaymentTypes()"
            class="col-auto ml-1 w-auto custom-select">
      <option [ngValue]="1">{{'SALES.DAY' | translate}}</option>
      <option [ngValue]="2">{{'SALES.WEEK' | translate}}</option>
      <option [ngValue]="3">{{'SALES.MONTH' | translate}}</option>
      <option [ngValue]="4">{{'SALES.SPECIAL' | translate}}</option>
    </select>
  </div>
  <div class="row">
    <div *ngIf="paymentTypes === null" [ngTemplateOutlet]="dataLoading"></div>
    <div class="col-md-12 col-12 col-lg-7 col-xl-6 border container-height">
      <div *ngIf="paymentTypes else emptyListRef" class="container">
        <div *ngFor="let paymentType of paymentChartArray; let i = index" class="row border-bottom pb-2 mb-2">
          <div class="col-md-1">
            <a class="number-radius mr-1"
               [ngStyle]="{'color': textColor(i)[0],'background-color': textColor(i)[1] }">
              {{i + 1}}
            </a>
          </div>
          <div class="col overflow-hidden text-nowrap">
            <div [ngClass]="{'slide-text': paymentType.paymentMethodName.length > 14 }">
              {{paymentType.paymentMethodName}}
            </div>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">Ciro</a><a
            class="price-type"> {{paymentType.volumeOfTotalTransaction | number | commaDotSwapper}}
            ₺</a>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">Ürün</a><a class="quantity-type"> {{paymentType.countOfTotalTransaction}} Ad.</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-12 col-lg-5 col-xl-6 border container-height justify-content-center">
      <div class="row p-0 m-0">
        <div class="col-8 chartdivPayment" [ngClass]="{'chartcollapsed': paymentChart === null}" id="payDist"></div>
        <div class="col-4 p-0 d-flex">
          <div class="my-auto" id="chartlegend"></div>
        </div>
        <ng-template *ngIf="paymentChart === null" [ngTemplateOutlet]="dataLoadingborderless"></ng-template>
      </div>
    </div>
  </div>
</div>


<div class="container animated fadeIn mt-4">
  <div class="row d-flex mb-1">
    <p class="col-sm-auto col-12 rowbaslık my-auto mr-auto">{{'SALES.PRODUCT_SALES_DISTRIBUTION' | translate}}</p>
    <button [disabled]="!(saleProducts && saleProducts.length)"
            class="btn btn-outline-primary col-auto export-button"
            (click)="downloadExcell(2)">
      <i class="fa fa-file-excel mr-1"></i>{{'SALES.EXPORT' | translate}}
    </button>
    <select [(ngModel)]="productSort" (ngModelChange)="sortProducts()"
            class="col-auto ml-1 w-auto custom-select">
      <option [ngValue]="-1" disabled>{{'SALES.SORTING_TYPE' | translate}}</option>
      <option [ngValue]="1">{{'SALES.NUMBER_OF_SALES' | translate}}</option>
      <option [ngValue]="2">{{'SALES.TOTAL_PRICE' | translate}}</option>
    </select>
    <select [(ngModel)]="widgetDates[2]" (ngModelChange)="getProductSales()"
            class="col-auto ml-1 w-auto custom-select">
      <option [ngValue]="1">{{'SALES.DAY' | translate}}</option>
      <option [ngValue]="2">{{'SALES.WEEK' | translate}}</option>
      <option [ngValue]="3">{{'SALES.MONTH' | translate}}</option>
      <option [ngValue]="4">{{'SALES.SPECIAL' | translate}}</option>
    </select>
  </div>
  <div class="row">
    <div *ngIf="saleProducts === null" [ngTemplateOutlet]="dataLoading"></div>
    <div class="col-md border container-height">
      <div *ngIf="saleProducts && saleProducts.length else emptyListRef" class="container">
        <div *ngFor="let product of saleProducts; let i = index" class="row border-bottom pb-2 mb-2">
          <div class="col-md-1">
            <img *ngIf="product.productImage && product.productImage !== '' else bullet"
                 [src]="product.productImage | image: ProductImagePath"
                 height="25px">
            <ng-template #bullet>
              <a class="number-radius mr-1"
                 [ngStyle]="{'color': textColor(i)[0],'background-color': textColor(i)[1] }"> {{i + 1}} </a>
            </ng-template>
          </div>
          <div *ngIf="product.productName" class="col-md-3 overflow-hidden text-nowrap">
            <div data-toggle="modal" data-backdrop="true" data-target="#urunSatisDetay"
                 (click)="getProductSaleDetail(product)"
                 (mouseenter)="hoveredProductSale = i"
                 (mouseleave)="hoveredProductSale = null"
                 [ngClass]="{'slide-text': product.productName.length > 10,
                 'hovercolor': hoveredProductSale === i}">{{product.productName}}
            </div>
          </div>
          <div *ngIf="!product.productName" class="col-md-3">
            <a class="number-radius mr-1"
               [ngStyle]="{'color': textColor(i)[0],'background-color': textColor(i)[1] }"> {{i + 1}} </a>
            <a>{{'SALES.REMOVED_PRODUCT' | translate}}</a>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">{{'SALES.GIRO' | translate}}</a><a class="price-type"> {{product.totalVolume | number | commaDotSwapper}}
            ₺</a>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">{{'SALES.PRODUCT' | translate}}</a><a class="quantity-type"> {{product.totalCount}}</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="saleProducts" class="col-md border container-height">
      <div *ngIf="saleProducts.length else emptyProductsRef" class="container">
        <div *ngFor="let product of saleProducts; let i = index" class="row d-flex">
          <div class="col-md overflow-hidden text-nowrap">
            <div class="graph"
                 (mouseenter)="hoveredProductSale = i"
                 (mouseleave)="hoveredProductSale = null"
                 [ngClass]="{'slide-text': product.productName.length > 10,
                 'hovercolor': hoveredProductSale === i}">{{product.productName}}</div>
          </div>
          <div class="col-md-7">
            <div class="progress progressborder text-center">
              <div class="progress-bar progress-bar-striped progress-bar-animated progressborder"
                   [ngStyle]="{'width': (
                 productSort === 1 ? product.countPercent :
                 productSort === 2 ? product.volumePercent : product.countPercent) + '%', 'background-color':barColor(i)}">
              </div>
              <span class="percent">% {{productSort === 1 ? product.countPercent :
                productSort === 2 ? product.volumePercent : product.countPercent}}</span>
            </div>

          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<div class="container animated fadeIn mt-4">
  <div class="row d-flex mb-1">
    <p class="col-auto rowbaslık my-auto">NAKİT PARA DAĞILIMI (Para Haznesi)</p>
    <button class="btn btn-outline-primary fa fa-file-excel ml-auto col-auto icon-button"
            (click)="downloadExcell(3)"></button>
    <select [(ngModel)]="widgetDates[3]" (ngModelChange)="getSaleDist()" class="col-auto ml-1 w-auto custom-select">
      <option [ngValue]="1">Gün</option>
      <option [ngValue]="2">Hafta</option>
      <option [ngValue]="3">Ay</option>
      <option [ngValue]="4">Son 30 Gün</option>
    </select>
    &lt;!&ndash; <div class="col-md-2 text-right mb-2">
       <button class="btn btn-outline-success" (click)="downloadXLSXPaymentType()">Excel</button>
     </div>&ndash;&gt;
  </div>
  <div class="row">
    <div *ngIf="!saleDist" [ngTemplateOutlet]="dataLoading"></div>
    <div *ngIf="saleDist" class="col-md border container-height">
      <div *ngIf="saleDist.length else emptyListRef" class="container">
        <div *ngFor="let number of [0,1,2,3]; let i=index" class="row border-bottom pb-2 mb-2">

          <div class="col-md-4">
            <a class="number-radius"
               [ngStyle]="{'color': textColor(i+1)[0],'background-color': textColor(i+1)[1] }"> 1</a> 5 TL
          </div>
          <div class="col-md-4">
            <a class="subtext-type">Miktar</a><a class="price-type"> 10 ₺</a>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">Adet</a><a class="quantity-type"> 1</a>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="saleDist" class="col-md border container-height">
      <div class="container text-center">
        <div class="row">
          <div class="col-md-6">
            <a class="money-head">KASADAKİ TOPLAM PARA</a><br><a class="money-blue">{{distTotal}}₺</a>
          </div>
          <div class="col-md-6">
            <a class="money-head">DOLULUK ORANI</a><br><a class="money-green">%{{distPercent}}</a>
          </div>
        </div>
        <div class="row">
          <div class="chartdivCash" id="distChart"></div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<!--<div class="container animated fadeInUp">
  <div class="row d-flex">
    <p class="col-auto">TAHSİLAT DURUMU</p>
    <select [(ngModel)]="widgetDates[4]" (ngModelChange)="getSaleProdeeds()"
            class="col-auto ml-auto w-auto custom-select">
      <option value="1">Gün</option>
      <option value="2">Hafta</option>
      <option value="3">Ay</option>
      <option value="4">Özel</option>
    </select>
  </div>
  <div *ngIf="saleProceeds else dataLoading" class="row">
    <div *ngIf="saleProceeds.length>0; else noDataDistrubition" class="col-md border container-height">
      <div class="container">
        <div *ngFor="let number of [0,1,2,3]" class="row border-bottom pb-2 mb-2">

          <div class="col-md-4">
            <a class="number-radius"> 1</a> Garanti Pos
          </div>
          <div class="col-md-4">
            <a class="subtext-type">Banka</a><a class="price-type"> 42112 TL</a>
          </div>
          <div class="col-md-4">
            <a class="subtext-type">EFT</a><a class="quantity-type"> 343</a>
          </div>

        </div>
      </div>

    </div>
    <div *ngIf="saleProceeds.length>0; else noDataDistrubition" class="col-md border container-height">
      <div class="container text-center">
        <div class="row">
          <div class="col-md-6">
            <img src="./assets/img/totalwait.svg" class="mb-2"><br>
            <a class="money-head">TOPLAM BEKLEYEN</a><br><a class="money-blue"> 2650 TL </a>
          </div>
          <div class="col-md-6">
            <img src="./assets/img/totalcomplete.svg" class="mb-2"><br>
            <a class="money-head">TOPLAM TAMAMLANAN</a><br><a class="money-green"> 5600 TL</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>-->
<!-- SATIŞ RAPORLARI : END -->

<!-- 3. PARTİ YAZILIM -->
<!--<div class="modal fade" id="thirdPartySoftware" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <div class="row justify-content-center modal-baslik">
            3. Parti Yazılıma Rapor Gönder
          </div>
          <hr/>
          <div class="row justify-content-center">
            <div class="col-md-3 text-right">
              <label>Tarih Aralığı: </label>
            </div>
            <div class="col-md-4">
              <label>Başlangıç: </label>
              <input type="date" id="thirdPartyDatePickerStart" class="form-control" #thirdPartyDatePickerStart>
            </div>
            <div class="col-md-4">
              <label>Bitiş: </label>
              <input type="date" id="thirdPartyDatePickerEnd" class="form-control" #thirdPartDatePickerEnd>
            </div>
          </div>
          <div class="row justify-content-center mt-2">
            <div class="col-md-3 text-right">
              <label>Fatura Tarihi:</label>
            </div>
            <div class="col-md-4">
              <input type="date" id="thirdPartyDatePickerInvoice" class="form-control" #thirdPartyDatePickerInvoice>
            </div>
            <div class="col-md-4">

            </div>
          </div>
          <div class="row justify-content-center mt-2">
            <div class="col-md-3 text-right">
              <label>Otomat Seçiniz:</label>
            </div>
            <div class="col-md-4">
              <select class="select custom-select" id="thirdPartySelectAutomat" #thirdPartySelectAutomat>
                <option value="-1" disabled>Otomat Seçiniz</option>
                <option *ngFor="let automat of automatList" [value]="automat._id">
                  {{automat.name}}
                </option>
              </select>
            </div>
            <div class="col-md-4">

            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <button class="btn btn-outline-primary"
                    (click)="sendThirdPartySoftwareReport(thirdPartyDatePickerStart.value, thirdPartDatePickerEnd.value, thirdPartyDatePickerInvoice.value, thirdPartySelectAutomat.value)">
              Rapor Gönder
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!-- 3.PARTİ YAZILIM: END -->

<!-- ÜRÜN SATIŞ DAĞILIMI DETAY -->
<ng-template #urunModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{this.selectedProduct.productName}} {{'SALES.PRODUCT_DETAILED_SALES_REPORT' | translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="container mt-5">
        <p *ngIf="this.selectedProduct">{{this.selectedProduct.productName}}{{'SALES.PRODUCT_PAYMENT_DISTRIBUTION' | translate}}</p>
        <div class="row">
          <div *ngIf="selectedProductDetailPayment && selectedProductDetailPayment.length else dataLoading"
               class="col-md border container-height">
            <div class="container">
              <div *ngFor="let paymentType of selectedProductDetailPayment; let i = index"
                   class="row border-bottom pb-2 mb-2">

                <div class="col-md-4">
                  <a class="number-radius"> {{i + 1}} </a>{{paymentType._id.paymentTypeName}}
                </div>
                <div class="col-md-4">
                  <a class="subtext-type">Ciro</a><a
                  class="price-type"> {{paymentType.price | number | commaDotSwapper}} ₺</a>
                </div>
                <div class="col-md-4">
                  <a class="subtext-type">Ürün</a><a class="quantity-type"> {{paymentType.count}} Ad.</a>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md border container-height justify-content-center">
            <div class="modalpie" [ngClass]="{'chartcollapsed': modalChart === null}" id="payDist2"></div>
            <ng-template *ngIf="modalChart === null" [ngTemplateOutlet]="dataLoadingborderless" class="modalpie"></ng-template>
          </div>

        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6">
          <strong>{{'SALES.PRODUCT_SALES_BY_VENDING' | translate}}</strong>
        </div>
        <div class="col-md-6 text-right">
          <button [disabled]="!(selectedProductDetailAutomat && selectedProductDetailAutomat.length)"
                  class="btn btn-outline-primary fa fa-file-excel col-auto export-button"
                  (click)="downloadExcell(5)"></button>
        </div>
      </div>
      <div>
        <div *ngIf="selectedProductDetailAutomat && selectedProductDetailAutomat.length else dataLoading"
             class="table-responsive" style="height: 300px;">
          <table class="table">
            <thead>
            <tr>
              <th scope="col" class="table-header text-left">{{'SALES.VENDING_NAME' | translate}}</th>
              <th scope="col" class="table-header text-center">{{'SALES.GIRO' | translate}}</th>
              <th scope="col" class="table-header text-right">{{'SALES.NUMBER_OF_SALES' | translate}}</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let automat of selectedProductDetailAutomat let i = index">
              <td *ngIf="automat._id.automat[0] !== undefined"
                  class="text-left">{{automat._id.automat[0].name}}</td>
              <td *ngIf="automat._id.automat[0] === undefined" class="text-left">{{'SALES.DELETED_VENDING' | translate}}</td>
              <td class="text-center">{{automat.amount | number | commaDotSwapper}} ₺</td>
              <td class="text-right">{{automat.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">{{'SALES.CLOSE' | translate}}</button>
  </div>
</ng-template>
<!-- ÜRÜN SATIŞ DAĞILIMI DETAY: END -->


<ng-template #dataLoadingSales>
  <div class="col border chartdiv d-flex flex-column">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%">{{'SALES.LOADING' | translate}}...</div>
    </div>
  </div>
</ng-template>
<ng-template #dataLoading>
  <div class="col border container-height d-flex flex-column">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%">{{'SALES.LOADING' | translate}}...</div>
    </div>
  </div>
</ng-template>
<ng-template #dataLoadingborderless>
  <div class="col chartdivPayment d-flex flex-column">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%">{{'SALES.LOADING' | translate}}...</div>
    </div>
  </div>
</ng-template>
<ng-template #dataLoadingborderlesssmall>
  <div class="col d-flex flex-column">
    <div class="mx-0 my-2 w-25">
      <div class="loader fadeIn" style="font-size: 20%">{{'SALES.LOADING' | translate}}...</div>
    </div>
  </div>
</ng-template>

<ng-template #emptyProductsRef>
  <div class="row d-flex">
    <div class="col-md overflow-hidden text-nowrap">
      <div class="graph">{{'SALES.NO_DATA' | translate}}</div>
    </div>
    <div class="col-md-7">
      <div class="progress progressborder text-center">
        <div class="progress-bar progress-bar-striped progress-bar-animated progressborder"
             [ngStyle]="{'width':0, 'background-color':barColor(0)}">
        </div>
        <span class="percent">% {{0}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyListRef>
  <div class="row border-bottom pb-2 mb-2">
    <div class="col-md-4">
      <a class="number-radius mr-2"
         [ngStyle]="{'color': textColor(0)[0],'background-color': textColor(0)[1] }"> 1 </a>{{'SALES.NO_DATA' | translate}}
    </div>
    <div class="col-md-4">
      <a class="subtext-type">{{'SALES.QUANTITY' | translate}}</a><a class="price-type"> 0 ₺</a>
    </div>
    <div class="col-md-4">
      <a class="subtext-type">{{'SALES.PIECE' | translate}}</a><a class="quantity-type"> 0</a>
    </div>
  </div>
</ng-template>
