import {FormTemplate} from './formTemplate';
import {PaymentSystems} from './paymentSystems';

export class PaymentSystemsPaynet implements PaymentSystems {
  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }

  _id: string;
  status;
  paymentSystemsFirmId = '';
  paymentSystemsFirmName = '';
  paymentSystemsFirmCode = 3;

  paymentSystemsFirmSecretKey: '';


  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'password',
        type: 'password',
        key: 'paymentSystemsFirmSecretKey',
        label: 'Secret Key',
        value: this.paymentSystemsFirmSecretKey,
        required: true
      })
    ];

    return template;
  }

}
