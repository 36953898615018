


import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, BehaviorSubject, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import { CashlessService } from "../_services/cashless.service";
import { OfflineUserTransactions } from "../_models/offlineUserTransactions";


export class TransactionsDataSource implements DataSource<OfflineUserTransactions> {

    private transactionsSubject = new BehaviorSubject<OfflineUserTransactions[]>([]);

    public transactions$ = this.transactionsSubject.asObservable();

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private cashlessService: CashlessService) {

    }

    loadTransactions(param: requestParam) {
        this.loadingSubject.next(true);
        this.cashlessService.getOfflineUserTransactions2(param).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(transactions => this.transactionsSubject.next(transactions.docs));

    }

    connect(collectionViewer: CollectionViewer): Observable<OfflineUserTransactions[]> {
        console.log("Connecting data source");
        return this.transactionsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.transactionsSubject.complete();
        this.loadingSubject.complete();
    }

}

export interface requestParam {
  filter:string,
  sortDirection:string,
  pageIndex:number,
  pageSize:number,
  startDate: string,
  endDate: string,
  offlineUserId: string
}
