
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {IssueProcess} from '../_models/issueProcess';

@Injectable({
  providedIn: 'root'
})
export class IssueProcessService {
  private headers =  new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token});

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  addIssueProcess(issueProcess: IssueProcess): Observable<any>  {
    return this.http
      .post(environment.apiBaseUrl + 'issueProcess', JSON.stringify(issueProcess), { headers: this.headers}).pipe(
        );
  }

}
