import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgxSpinnerModule} from 'ngx-spinner';
import {SettingsComponent} from './settings.component';
import {PipesModule} from '../_pipes/pipes.module';
import {DndModule} from 'ngx-drag-drop';
import {SettingsRoutingModule} from './settings.routing';
import {RouterModule} from '@angular/router';
import {PaymentSettingsModule} from './payment-settings/payment-settings.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AccountSettingsModule} from './account-settings/account-settings.module';
import { SharedModule } from '../_shared/shared.module';
import { AppSettingsModule } from './app-settings/app-settings.module';

const dec_exp = [
  SettingsComponent,
];

const imp_exp = [];

const imp = [
  SettingsRoutingModule,
  PaymentSettingsModule,
  AccountSettingsModule,
  AppSettingsModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PipesModule,
    DndModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    SharedModule,
    imp_exp,
    imp
  ],
  declarations: [dec_exp],
  exports: [dec_exp],
})
export class SettingsModule {
}

