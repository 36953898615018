import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormTemplate} from '../../../../_models/formTemplate';
import {PaymentSystems} from '../../../../_models/paymentSystems';
import {DynamicFormComponent} from '../../../../_widgets/dynamic-form/dynamic-form.component';
import {PaymentSystemsIyzicoMobilPrivateCompany} from '../../../../_models/paymentSystemsIyzicoMobil-privateCompany';
import {PaymentSystemsIyzicoMobilPersonal} from '../../../../_models/paymentSystemsIyzicoMobil-personal';
import {PaymentSystemsIyzicoMobilStockCompany} from '../../../../_models/paymentSystemsIyzicoMobil-stockCompany';
import {MarketplaceService} from '../../../../_services/marketplace.service';
import {AuthenticationService} from '../../../../_services/authentication.service';


export enum Type {
  PERSONAL = 'PERSONAL',
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
  LIMITED_OR_JOINT_STOCK_COMPANY = 'LIMITED_OR_JOINT_STOCK_COMPANY'
}

@Component({
  selector: 'app-edit-method-payment',
  templateUrl: './edit-method-payment.component.html',
  styleUrls: ['./edit-method-payment.component.css']
})
export class EditMethodPaymentComponent implements OnInit {
  readonly Type = Type;
  ft: Array<FormTemplate<string>>;
  psData: PaymentSystems;
  psInfo: any;
  firmid: string;
  loading = false;
  formValue: FormValue;
  @ViewChild(DynamicFormComponent) private dyForm: DynamicFormComponent;

  @Input() options: {
    selectedPaymentType: string,
    marketplace: boolean,
    operation: string
  } = {
    selectedPaymentType: null,
    marketplace: false,
    operation: 'update'
  };

  @Input()
  set paymentObj(data: PaymentSystems) {
    if (data) {
      if (this.options.marketplace) {
        if (data instanceof PaymentSystemsIyzicoMobilPersonal) {
          this.getIyzicoMobilInfo().then(
            () => {
            },
            (err) => {
              console.log(err);
            }
          );
        }

      } else {
        this.psData = data;
        this.getFormTemplate(data);
        this.loading = false;
      }

    }
  }

  @Output() submitState = new EventEmitter<boolean>();
  @Output() submitUpdate = new EventEmitter<{ data: any, subType: string, operation: string }>();

  constructor(
    public activeModal: NgbActiveModal,
    private marketService: MarketplaceService,
    private auth: AuthenticationService
  ) {
    this.firmid = this.auth.user$.getValue().firm._id;
  }

  ngOnInit(): void {
  }

  getFormTemplate(data: PaymentSystems) {
    this.ft = null;
    if (data) {
      this.ft = data.getFormTemplate();
    }
  }

  changeState(newState: boolean) {
    this.submitState.emit(newState);
  }

  submitForm() {
    this.dyForm.onSubmit();
  }

  update(event) {
    console.log('emit', {
      data: event,
      subType: this.options.selectedPaymentType,
      operation: this.options.operation
    });
    this.submitUpdate.emit(
      {
        data: event,
        subType: this.options.selectedPaymentType,
        operation: this.options.operation
      });
  }

  getValue(value: FormValue) {
    this.formValue = value;
  }

  /*MOBIL*/
  async getIyzicoMobilInfo() {
    try {
      this.loading = true;
      const res = await this.marketService.getInfoIyzico(this.firmid);
      this.psInfo = res;
      console.log('iyzico-info', res);
      if (res && res.status === 'success') {
        this.options.operation = 'update'; //kayıt edit
        this.options.selectedPaymentType = res.subMerchantType;

      } else if (res.status === 'failure' && res.errorCode === '2001') {
        // yeni kayıt
        this.options.operation = 'create';
        this.options.selectedPaymentType = Type.PERSONAL;
      }

      this.selectPaymentType(this.options.selectedPaymentType);
    } catch (e) {
      console.log(e);
    }

    this.loading = false;
  }


  selectPaymentType(event) {
    switch (event) {
      case Type.PERSONAL:
        this.psData = new PaymentSystemsIyzicoMobilPersonal(this.psInfo);
        break;
      case Type.PRIVATE_COMPANY:
        this.psData = new PaymentSystemsIyzicoMobilPrivateCompany(this.psInfo);
        break;
      case Type.LIMITED_OR_JOINT_STOCK_COMPANY:
        this.psData = new PaymentSystemsIyzicoMobilStockCompany(this.psInfo);
        break;
    }

    this.getFormTemplate(this.psData);
  }

  /*MOBIL-END*/


}

export interface FormValue {
  paymentSystemsFirmMerchant: string;
  paymentSystemsFirmMerchantApi: string;
  paymentSystemsFirmMerchantPassword: string;
}
