import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Directive({
  selector: '[ibanFormatter]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanDirective),
      multi: true
    }
  ]
})

export class IbanDirective implements ControlValueAccessor {
  propagateChange;

  constructor(private renderer: Renderer2, private element: ElementRef) {
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    const canonical = this.removeSplitter(value);

    const formatted = this.format(canonical);
    this.render(formatted);

    if (this.propagateChange) {
      this.propagateChange(canonical);
    }
  }

  writeValue(value: any): void {
    const canonical = this.removeSplitter(value);
    const formatted = this.format(canonical);
    this.render(formatted);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  private removeSplitter(value): string {
    if (!value) {
      return null;
    }
    return value.replace(/^[a-z]*/gi, 'TR').split('-').join('');
  }

  private format(value): string {
    if (!value) {
      return null;
    }

    return value.replace(/(.{4})/g, '$1-');

  }

  private render(value) {
    const element = this.element.nativeElement;
    this.renderer.setProperty(element, 'value', value);
  }
}
