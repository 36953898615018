import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutomatComponent} from './automat.component';

import {FormsModule} from '@angular/forms';
import {PipesModule} from '../_pipes/pipes.module';
import {UiSwitchModule} from 'ngx-ui-switch';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgSelectModule} from '@ng-select/ng-select';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
  imports: [
    CommonModule,

    FormsModule,
    NgSelectModule,
    NgbModule,
    GoogleMapsModule,
    PipesModule,
    UiSwitchModule,
    NgxPermissionsModule.forChild(),
    SharedModule
  ],
  exports: [
    AutomatComponent
  ],
  declarations: [AutomatComponent]
})
export class AutomatModule {
}
