import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';
import {Staff} from '../_models/staff';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getFirmStaffs(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'staff-firm', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getFirmOperator(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-firm-staffs', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getFirmOperatorForAutomatFormPopup(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-firm-staffs-from-create-automat-form', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }


  getAvailableAutomatStaffs(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-automat-staff/' + automatId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getCurrentAutomatStaffs(automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-automat-staff-by-automat/' + automatId,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getStaffs(page): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'staffs/' + page, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getStaffById(id): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'staff/' + id,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  addStaff(staff: Staff): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'staff',
      JSON.stringify(staff),
      {headers: this.headers}).pipe(
    );
  }

  addStaffToAutomat(staffId: string, automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'add-automat-staff/' + staffId + '/' + automatId,
      {headers: this.headers}).pipe(
    );
  }

  deleteStaffFromAutomat(staffId: string, automatId: string): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'delete-automat-staff/' + staffId + '/' + automatId,
      {headers: this.headers}).pipe(
    );
  }

  updateStaff(staff: Staff, id) {
    return this.http
      .put(environment.apiBaseUrl + 'staff/' + id, JSON.stringify(staff), {headers: this.headers}).pipe(
      );
  }

  deleteStaff(id): Observable<any> {
    /*eslint-disable */
    return this.http.delete(environment.apiBaseUrl
      + 'staff/' + id, {headers: this.headers}).pipe(
    );
    /*eslint-enable */
  }

    /** Sub Firm Users **/

    getSubFirmUserList(): Observable<any> {
        return this.http.get(environment.apiBaseUrl + 'sub-firm-user-list', {headers: this.headers}).pipe(
            // ...errors if any
            catchError((error: any) => observableThrowError(error.error || 'Server error')));
    }

    deleteSubFirmUser(id): Observable<any> {
        return this.http.delete(environment.apiBaseUrl
            + 'sub-firm-user/' + id, {headers: this.headers}).pipe(
        );
    }

    updateSubFirmUser(staff: Staff, id) {
        return this.http
            .put(environment.apiBaseUrl + 'sub-firm-user/' + id, JSON.stringify(staff), {headers: this.headers}).pipe(
            );
    }

    addSubFirmUser(user: any): Observable<any> {
        return this.http.post(environment.apiBaseUrl + 'sub-firm-user',
            JSON.stringify(user),
            {headers: this.headers}).pipe(
        );
    }

    /*** End Sub Firm Users ***/

}
