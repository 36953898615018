<div class="modal-header">
  <h4 class="modal-title">{{(controlVariables.isInEdit ? 'NEW_VENDING.EDIT_VENDING' : 'NEW_VENDING.ADD_NEW_VENDING') | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row my-2 ">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav nav-pills mx-auto">
      <li [ngbNavItem]="1" [destroyOnHide]="false" [disabled]="true">
        <a ngbNavLink class="nav-link circle p-0 d-flex"><span class="m-auto">1</span></a>
        <ng-template ngbNavContent let-active>
          <div *ngIf="formDataLoaded else loadingRef" class="container">
            <div class="row">
              <div class="col mx-5">
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.VENDING_NAME' | translate}}
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !inputCheck(automat.name)}">
                    <input class="form-control custom-input" [(ngModel)]="automat.name" type="text"
                           name="name" placeholder="{{'NEW_VENDING.VENDING_NAME' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.VENDING_BRAND' | translate}}
                  </div>
                  <div class="col bottom-line">
                    <!-- <select name="automatBrand" class="custom-select custom-input"
                            [(ngModel)]="automat.automatModel.automatBrand._id"
                            (ngModelChange)="getAutomatModels($event)">
                      <option [ngValue]="null" selected disabled>Otomat Markası</option>
                      <option
                        *ngFor="let brand of brands;"
                        [value]="brand._id">{{brand.name}}</option>
                    </select> -->
                      <ng-select class="custom"
                        [items]="brands"
                        appendTo="body"
                        [(ngModel)]="automat.automatModel.automatBrand._id"
                        [virtualScroll]="true"
                        placeholder="{{'NEW_VENDING.VENDING_BRAND' | translate}}"
                        [loading]="loading"
                        (ngModelChange)="getAutomatModels($event)"
                        bindLabel="name" bindValue="_id" name="evendProduct">
                        <ng-template ng-label-tmp let-item="item">
                          <span class="align-self-center">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          {{item.name}}
                        </ng-template>
                      </ng-select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.VENDING_MODEL' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input':showErrors && !selectCheck(automat.automatModel?._id)}">
                    <ng-select class="custom"
                        [items]="models"
                        appendTo="body"
                        [(ngModel)]="automat.automatModel._id"
                        [virtualScroll]="true"
                        placeholder="{{'NEW_VENDING.VENDING_MODEL' | translate}}"
                        [loading]="loading"
                        [disabled]="!automat.automatModel.automatBrand._id || !models || !models?.length"
                        bindLabel="name" bindValue="_id" name="automatModel">
                        <ng-template ng-label-tmp let-item="item">
                          <span class="align-self-center">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          {{item.name}}
                        </ng-template>
                      </ng-select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.SERIAL_NO' | translate}}
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input':showErrors &&!inputCheck(automat.serial)}">
                    <input class="form-control custom-input" [(ngModel)]="automat.serial"
                           type="text" name="serial" placeholder="{{'NEW_VENDING.SERIAL_NO' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.EXTERNAL_ID' | translate}}
                  </div>
                  <div class="col bottom-line">
                    <input class="form-control custom-input" [(ngModel)]="automat.external_id"
                           type="text" name="external_id" placeholder="External ID">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.BALANCE_TO_BE_DOWNLOADED_TO_THE_VENDING' | translate}}
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input':showErrors &&!numberCheck(automat.maxProductPrice)}">
                    <input class="form-control custom-input" [(ngModel)]="automat.maxProductPrice"
                            (keydown)="validatePrice($event)"
                           type="number" min="0" name="maxProductPrice" placeholder="{{'NEW_VENDING.BALANCE_TO_BE_DOWNLOADED_TO_THE_VENDING' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.VENDING_GROUP' | translate}}
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.group)}">
                    <select name="group" class="custom-select custom-input" [(ngModel)]="automat.group">
                      <option [ngValue]="null" selected disabled>{{'NEW_VENDING.VENDING_GROUP' | translate}}</option>
                      <option
                        *ngFor="let group of groups;"
                        [value]="group._id">{{group.name}}</option>
                    </select>
                  </div>
                </div>
                  <div class="row mt-3">
                      <div class="col-form-label col-3 bottom-line">
                          {{'NEW_VENDING.AUTOMAT_TYPE' | translate}}
                      </div>
                      <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.type)}">
                          <select name="group" class="custom-select custom-input" [(ngModel)]="automat.type">
                              <option [ngValue]="null" selected disabled>{{'NEW_VENDING.AUTOMAT_TYPE' | translate}}</option>
                              <option
                                  *ngFor="let type of types;"
                                  [value]="type">{{type}}</option>
                          </select>
                      </div>
                  </div>
                  <div class="row mt-3">
                      <div class="col-form-label col-3 bottom-line">
                          {{'NEW_VENDING.IS_STOCK_CONTROL' | translate}}
                      </div>
                      <div class="col bottom-line">
                          <select name="group" class="custom-select custom-input" [(ngModel)]="automat.isStockControl">
                              <option [ngValue]="null" selected disabled>{{'NEW_VENDING.IS_STOCK_CONTROL' | translate}}</option>
                              <option  [value]="true">KONTROL AÇIK</option>
                              <option  [value]="false">KONTROL KAPALI</option>
                          </select>
                      </div>
                  </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.OPERATOR' | translate}}
                  </div>
                  <div class="col bottom-line">
                    <select name="operator" class="custom-select custom-input"
                            [(ngModel)]="automat.operator">
                      <option [ngValue]="null" selected disabled>{{'NEW_VENDING.OPERATOR' | translate}}</option>
                      <option
                        *ngFor="let staff of staffs;"
                        [value]="staff._id">{{staff.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.PAYMENT_TEMPLATE' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !selectCheck(automat.automatPaymentSystemId)}">
                    <select name="operator" class="custom-select custom-input"
                            [(ngModel)]="automat.automatPaymentSystemId">
                      <option [ngValue]="null" selected disabled>{{'NEW_VENDING.PAYMENT_TEMPLATE' | translate}}</option>
                      <option
                        *ngFor="let payment of paymentTemplates;"
                        [value]="payment._id">{{payment.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.province)}">
                    <select name="city"
                            [(ngModel)]="automat.province"
                            (ngModelChange)="getDistricts()"
                            class="custom-select custom-input">
                      <option [ngValue]="null" selected disabled>{{'NEW_VENDING.PROVINCE' | translate}}</option>
                      <option
                        *ngFor="let city of cities;"
                        [value]="city.cityTitle">{{city.cityTitle}}</option>
                    </select>
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.district)}">
                    <select name="district"
                            [(ngModel)]="automat.district"
                            [disabled]="!automat.province || !districts || !districts.length"
                            (ngModelChange)="getNeighborhoods(false)"
                            class="custom-select custom-input">
                      <option [ngValue]="null" selected>{{'NEW_VENDING.DISTRICT' | translate}}</option>
                      <option
                        *ngFor="let district of districts;"
                        [value]="district.districtTitle">{{district.districtTitle}}</option>
                    </select>
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !selectCheck(automat.neighborhood)}">
                    <select name="neighborhood"
                            [(ngModel)]="automat.neighborhood"
                            [disabled]="!automat.district || !neighborhoods || !neighborhoods.length"
                            (ngModelChange)="selectAddress()"
                            class="custom-select custom-input">
                      <option [ngValue]="null" selected>{{'NEW_VENDING.NEIGHBORHOODS' | translate}}</option>
                      <option
                        *ngFor="let neighborhood of neighborhoods;"
                        [value]="neighborhood.neighborhoodTitle">{{neighborhood.neighborhoodTitle}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="formDataLoaded" class="container-fluid mt-5 mx-2">
            <div class="row" [ngClass]="{'fail-input': showErrors && !mapCheck()}">

              <google-map height="100%"
                          width="100%"
                          [center]="center"
                          [zoom]="zoom"
                          [options]="mapStyles"
              >
                <map-marker #marker
                            [position]="{lat:mapAutomat.lat,lng:mapAutomat.lng}"
                            [options]="{draggable:true, icon:'../../assets/img/aktifMarker.png'}"
                            (mapDragend)="markerDragEnd($event)"
                            (mapClick)="openInfoWindow(marker)">

                </map-marker>
                <map-info-window class="map-window">
                  <div class="container map-window">
                    <div class="row">
                      {{'NEW_VENDING.DRAG_TCHANGE_POSITION' | translate}}
                    </div>
                  </div>
                </map-info-window>

              </google-map>

            </div>
          </div>
        </ng-template>
      </li>
      <div class="line"></div>
      <li [ngbNavItem]="2" [destroyOnHide]="false" [disabled]="true">
        <a ngbNavLink class="nav-link circle p-0 d-flex"><span class="m-auto">2</span></a>
        <ng-template ngbNavContent>
          <div class="container-fluid">
            <div class="row">
              <div class="col mx-5">
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    {{'NEW_VENDING.DEVICE' | translate}}
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.device)}">
                    <select name="device" class="custom-select custom-input"
                            [(ngModel)]="automat.device">
                      <option [ngValue]="null">{{'NEW_VENDING.SELECTI_DEVICE' | translate}}</option>
                      <option
                        *ngFor="let device of devices;"
                        [value]="device._id">{{device.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    Pos
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.vPos)}">
                    <select name="vPos" class="custom-select custom-input"
                            [(ngModel)]="automat.vPos" [disabled]="isUPTDevice(automat.device)">
                      <option [ngValue]="null">{{'NEW_VENDING.SELECT_READER' | translate}}</option>
                      <option
                        *ngFor="let vPos of vPoses;"
                        [value]="vPos._id">{{vPos.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    Beacon
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.beacon)}" >
                    <select name="beacon" class="custom-select custom-input"
                            [(ngModel)]="automat.beacon" [disabled]="isUPTDevice(automat.device)">
                      <option [ngValue]="null">{{'NEW_VENDING.SELECT_BEACON' | translate}}</option>
                      <option
                        *ngFor="let beacon of beacons;"
                        [value]="beacon._id">{{beacon.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-3 bottom-line">
                    Sim
                  </div>
                  <div class="col bottom-line" [ngClass]="{'fail-input': showErrors && !selectCheck(automat.sim)}">
                    <select name="sim" class="custom-select custom-input"
                            [(ngModel)]="automat.sim" [disabled]="isUPTDevice(automat.device)">
                      <option [ngValue]="null">{{'NEW_VENDING.SELECT_SIM' | translate}}</option>
                      <option
                        *ngFor="let sim of sims;"
                        [value]="sim._id">{{sim.serial}}</option>
                    </select>
                  </div>
                </div>


                <div class="row">
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="nakit" [(ngModel)]="automat.isCoin" (click)="log(automat)">
                      {{'NEW_VENDING.CASH' | translate}}
                    </label>
                  </div>
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="kkartı" [(ngModel)]="automat.isCard">
                      {{'NEW_VENDING.CREDICARD' | translate}}
                    </label>
                  </div>
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="offline" [(ngModel)]="automat.isPreSalesCard">
                      {{'NEW_VENDING.PRE_PAID_CARD' | translate}}
                    </label>
                  </div>
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="mobil" [(ngModel)]="automat.isMetropolCardQR">
                      {{'NEW_VENDING.METROPOLCARD_QR' | translate}}
                    </label>
                  </div>
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="mobil" [(ngModel)]="automat.isMobil">
                      {{'NEW_VENDING.MOBILE' | translate}}
                    </label>
                  </div>
                  <div class="col-6 col-sm d-flex bottom-line mt-3">
                    <label class="mx-auto">
                      <input type="checkbox" name="mobil" [(ngModel)]="automat.isQrPayment">
                      {{'NEW_VENDING.QR' | translate}}
                    </label>
                  </div>

                </div>

                <div class="row mt-3">
              <textarea class="text-area w-100"
                        [(ngModel)]="automat.description" name="description"
                        placeholder="{{'NEW_VENDING.DESCRIPTION' | translate}}"></textarea>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <div class="line"></div>
      <li [ngbNavItem]="3" [destroyOnHide]="false" [disabled]="true">
        <a ngbNavLink class="nav-link circle p-0 d-flex"><span class="m-auto">3</span></a>
        <ng-template ngbNavContent>
          <div class="container-fluid">
            <div class="row">
              <div class="col mx-5">
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.FEATURE_LEVEL' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.featureLevel)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.featureLevel"
                           #featureLevel="ngModel"
                           type="number" name="featureLevel" placeholder="{{'NEW_VENDING.FEATURE_LEVEL' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.COUNTRY_CODE' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.countryCode)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.countryCode"
                           #countryCode="ngModel"
                           type="number" name="countryCode" placeholder="{{'NEW_VENDING.COUNTRY_CODE' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.SCALE_FACTOR' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.scaleFactor)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.scaleFactor"
                           #scaleFactor="ngModel"
                           type="number" name="scaleFactor" placeholder="{{'NEW_VENDING.SCALE_FACTOR' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.DECIMAL_PLACES' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.decimalPlaces)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.decimalPlaces"
                           #decimalPlaces="ngModel"
                           type="number" name="decimalPlaces" placeholder="{{'NEW_VENDING.DECIMAL_PLACES' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.MAX_RESP_TIME' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.maxRespTime)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.maxRespTime"
                           #maxRespTime="ngModel"
                           type="number" name="maxRespTime" placeholder="{{'NEW_VENDING.MAX_RESP_TIME' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.MISC_OPTION' | translate}}
                  </div>
                  <div class="col bottom-line"
                       [ngClass]="{'fail-input': showErrors && !numberCheck(cashlessConfig.miscOptions)}">
                    <input class="form-control custom-input" [(ngModel)]="cashlessConfig.miscOptions"
                           #miscOptions="ngModel"
                           type="number" name="miscOptions" placeholder="{{'NEW_VENDING.MISC_OPTION' | translate}}">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-form-label col-4 bottom-line">
                    {{'NEW_VENDING.ADVANCE_PAYMENT' | translate}}
                  </div>
                  <div class="col bottom-line">
                    <input class="form-control custom-input" [(ngModel)]="automat.headStart" #headStart="ngModel"
                           type="number" name="headStart" placeholder="{{'NEW_VENDING.ADVANCE_PAYMENT' | translate}}">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <div class="line"></div>
      <li [ngbNavItem]="4" [destroyOnHide]="false" [disabled]="true">
        <a ngbNavLink class="nav-link circle p-0 d-flex"><span class="fa fa-check m-auto"></span></a>
        <ng-template ngbNavContent>
          <div *ngIf="savedAutomat else loadingRef" class="container-fluid ">
            <div class="row justify-content-center p-4 saved-border">
              <div class="col-auto d-flex">
                <img class="my-auto" [src]="savedAutomat.image | image: AutomatImagePath" alt="Otomat resmi"
                     style="width: auto; height: 200px">
              </div>
              <div class="col-6">
                <div class="container">
                  <div class="row">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.VENDING_NAME' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.name}}</p></div>
                  </div>
                  <div class="row">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.VENDING_BRAND' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.automatModel.name}}</p></div>
                  </div>
                  <div class="row">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.SERIAL_NO' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.serial}}</p></div>
                  </div>
                  <div class="row" *ngIf="savedAutomat.external_id">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.EXTERNAL_ID' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.external_id}}</p></div>
                  </div>
                  <div class="row" *ngIf="savedAutomat.maxProductPrice">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.BALANCE_TO_BE_DOWNLOADED_TO_THE_VENDING' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.maxProductPrice}}</p></div>
                  </div>
                  <div class="row">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.GROUP' | translate}}</div>
                    <div class="col d-flex"><p class="my-auto">{{savedAutomat.group.name}}</p></div>
                  </div>
                    <div class="row">
                        <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.AUTOMAT_TYPE' | translate}}</div>
                        <div class="col d-flex"><p class="my-auto">{{savedAutomat.type}}</p></div>
                    </div>
                  <div class="row">
                    <div class="col-form-label col-5 saved-automat-label">{{'NEW_VENDING.PAYMENT_METHOD' | translate}}</div>
                    <div class="col d-flex">
                      <p class="my-auto">
                        <img *ngIf="savedAutomat.isCard" class="mr-2" alt="kart"
                             src="../../assets/img/grey-rapor-kart.svg" height="100%">
                        <img *ngIf="savedAutomat.isMobil" class="mr-2" alt="mobil"
                             src="../../assets/img/grey-rapor-mobilodeme.svg" height="100%">
                        <img *ngIf="savedAutomat.isCoin" class="mr-2" alt="nakit"
                             src="../../assets/img/grey-raporwallet.svg" height="100%">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="savedAutomat" class="row my-5 justify-content-center">
              <img src="../../assets/img/bigcheck.svg" width="30%" alt="başarılı">
            </div>
            <div *ngIf="savedAutomat" class="row my-5 justify-content-center">
              <p class="saved-text">{{'NEW_VENDING.REGISTRATION_SUCCESSFUL' | translate}}</p>
            </div>

          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer" [ngSwitch]="active">
  <ng-template [ngSwitchCase]="1">
    <button class="btn btn-primary" [disabled]="!formDataLoaded" (click)="nav.select(2)">{{'NEW_VENDING.NEXT' | translate}}</button>
  </ng-template>
  <ng-template [ngSwitchCase]="2">
    <button class="btn btn-primary" (click)="nav.select(1)">{{'NEW_VENDING.PREV' | translate}}</button>
    <button class="btn btn-primary" (click)="nav.select(3)">{{'NEW_VENDING.NEXT' | translate}}</button>
  </ng-template>
  <ng-template [ngSwitchCase]="3">
    <button class="btn btn-primary" (click)="nav.select(2)">{{'NEW_VENDING.PREV' | translate}}</button>
    <button class="btn btn-success"
            (click)="handleSave()">{{(controlVariables.isInEdit ? 'NEW_VENDING.UPDATE' : 'NEW_VENDING.SAVE') | translate}}</button>
  </ng-template>
  <ng-template [ngSwitchCase]="4">
    <button class="btn btn-outline-dark" (click)="close()">{{'NEW_VENDING.CLOSE' | translate}}</button>
  </ng-template>
</div>


<ng-template #loadingRef>
  <div class="container d-flex w-100" style="height: 400px">
    <div class="loader" style="font-size: 60%"></div>
  </div>
</ng-template>
