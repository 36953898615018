import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgSelectComponent} from '@ng-select/ng-select';
import {environment} from '../../../environments/environment';
import {SwalHelper} from '../../_helpers/swal.helper';
import {Product} from '../../_models/product';
import {ProductBrand} from '../../_models/productBrand';
import {ProductCategory} from '../../_models/productCategory';
import {EvendProduct} from '../../_models/evendProduct';
import {ProductContent} from '../../_models/productContent';
import {PowderProduct} from '../../_models/powderproduct';
import {HttpClient} from '@angular/common/http';
import {BrandService} from '../../_services/brand.service';
import {CategoryService} from '../../_services/category.service';
import {AutomatService} from '../../_services/automat.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProductService} from '../../_services/product.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {QrCodeService} from '../../_services/qr-code.service';
import * as moment from 'moment/moment';
import {DatePickerComponent} from '../../_widgets/date-picker/date-picker.component';

@Component({
    selector: 'app-generate-qr',
    templateUrl: './generate-qr.component.html',
    styleUrls: ['./generate-qr.component.css']
})
export class GenerateQrComponent implements OnInit {
    @ViewChild('brandSelect') brandSelect: NgSelectComponent;
    @ViewChild('categorySelect') categorySelect: NgSelectComponent;
    @ViewChild('dPickerS', {static: true}) datepicker: DatePickerComponent;

    ProfileImagePath = environment.ProfileImagePath;
    ProductImagePath = environment.ProductImagePath;

    swalOptions = new SwalHelper();
    public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
    public loadingSpinnerSize = environment.loadingSpinnerSize;
    public loadingSpinnerColor = environment.loadingSpinnerColor;
    public loadingSpinnerType = environment.loadingSpinnerType;
    public loadingSpinnerText = environment.loadingSpinnerText;

    product: Product;
    _brands: Array<ProductBrand>;
    _brand: ProductBrand = null;
    _categories: Array<ProductCategory>;
    _groupData: Array<any>;
    _category: ProductCategory = null;
    evendProducts: Array<EvendProduct> = [];
    _evendProduct;

    page = 1;
    pages = 1;
    total = 0;
    defaultValue = null;
    loading = false;
    filter: Filter = {
        brandId: '',
        categoryId: '',
        name: '',
        qrEvendProducts: true
    };
    qrTypes: any[] = [
        {
            name: 'Ürün',
            type: 'PRODUCT'
        }, {
            name: 'Fiyat',
            type: 'PRICE'
        },
    ];

    selectedQrType = {
        name: 'Fiyat',
        type: 'PRICE'
    };

    showProductType = false;
    qrCode: any = {};
    selectedGroup;
    showExpiredDate = '';

    customTime = [
        moment().subtract(1, 'month').endOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ];

    updateQr: boolean;

    @Input()
    set selectedQr(data: Product) {
        if (data) {
            this.updateQr = true;
            this.qrCode = data;
            this.selectedQrType = this.qrTypes.find(item => item.type === this.qrCode.type);
        }
        this.formContentInit();
    }

    @Output() changeProduct = new EventEmitter<{ event: string, data: any }>();


    powderProducts: Array<PowderProduct> = new Array<PowderProduct>();
    productContents: Array<ProductContent> = new Array<ProductContent>();


    constructor(private http: HttpClient,
                private brandService: BrandService,
                private categoryService: CategoryService,
                private automatService: AutomatService,
                private loadingSpinner: NgxSpinnerService,
                private productService: ProductService,
                public activeModal: NgbActiveModal,
                private qrCodeService: QrCodeService
    ) {
    }


    ngOnInit() {
        this.getFirmQrAutomatGroup();
    }

    getProductQrInıt() {

        this.brandService.getBrands(this.page).subscribe(response => {
            this._brands = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }, error => {
            console.log(error);
            if (error.status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            } else {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: error.error
                });
                console.log('Error:', error);
            }
        });

        this.categoryService.getCategories(this.page).subscribe(response => {
            this._categories = response.docs.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        }, error => {
            console.log(error);
            if (error.status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            } else {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: error.error
                });
                console.log('Error:', error);
            }
        });

        this.automatService.getPowderProductTypes().subscribe(res => {
            this.powderProducts = res.powderProducts;
            this.getEvendProducts();
            this.formContentInit();

        }, error => {
            console.log(error);
        });
    }

    getFirmQrAutomatGroup() {
        this.qrCodeService.getFirmQrAutomatGroups().subscribe(response => {
            this._groupData = response.result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            if (this.updateQr) {
                this.selectedGroup = this._groupData.find(item => item._id === this.qrCode.qrAutomatGroups);
            }
            console.log(this._groupData);
        }, error => {
            console.log(error);
            if (error.status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            } else {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: error.error
                });
                console.log('Error:', error);
            }
        });
    }


    convertNgDatePickerDate(date) {
        console.log(date);
        return moment(`${date.year}-${date.month}-${date.day}`).toISOString(false);
    }

    generateQr() {
        this.qrCode['type'] = this.selectedQrType.type;
        this.qrCode['qrAutomatGroups'] = this.selectedGroup?._id;
        this.qrCode['expiredDate'] = this.convertNgDatePickerDate(this.showExpiredDate);

        if (this.validateQr(this.qrCode)) {
            this.qrCodeService.generateQrKodList(this.qrCode).subscribe(
                res => {
                    Swal.fire({
                        ...this.swalOptions.success,
                        text: 'Qr Listesi Oluşturuldu'
                    });
                    this.changeProduct.emit();
                }, error => {
                    console.log(error);
                    if (error.status === 403) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                    } else {
                        Swal.fire({
                            ...this.swalOptions.error,
                            text: error.error
                        });
                        console.log('Error:', error);
                    }
                }
            );
        }
    }

    validateQr(qrCode) {
        if (!qrCode.type) {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Qr Türü Seçiniz'
            });
            return false;
        }
        if (!qrCode.qrAutomatGroups) {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Qr Otomat Grup Seçiniz'
            });
            return false;
        }
        if (!qrCode.count) {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Qr Adedi Giriniz'
            });
            return false;
        }
        if (qrCode.type === 'PRODUCT') {
            if (!qrCode.evendProduct) {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Ürün Seçiniz'
                });
                return false;
            }
        }
        if (qrCode.type === 'PRICE') {
            if (!qrCode.price) {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Qr Fiyatı Seçiniz'
                });
                return false;
            }
        }
        return true;
    }

    selectType() {
        this.showProductType = this.selectedQrType.type === 'PRODUCT';
        if (this.selectedQrType.type === 'PRODUCT') {
            this.getProductQrInıt();
        }
    }


    customDateSelected(event) {
        console.log(event);
        this.showExpiredDate = moment(event[1]).format('LLLL');
        this.qrCode['expiredDate'] = moment(event[1]);
    }

    openDatePicker() {
        this.datepicker.open();
    }

    formContentInit() {
        this.powderProducts.forEach((pType, index) => {
            this.productContents[index] = new ProductContent();
            this.productContents[index].name = pType.name;
            this.productContents[index].productType = pType.productType;
            this.productContents[index].powderProductID = pType._id;
            this.productContents[index].value = null;
        });
    }


    // formda seçtirebilmek için alınan liste
    getEvendProducts(isItSelect?: boolean, select?: string) {
        if (select === 'brand') {
            if (this._brand) {
                this.filter.brandId = this._brand._id;
            } else {
                this.filter.brandId = '';
            }
            this._category = null;
            this.filter.categoryId = '';
            this.filter.name = '';
        } else if (select === 'category') {
            if (this._category) {
                this.filter.categoryId = this._category._id;
            } else {
                this.filter.categoryId = '';
            }
            this._brand = null;
            this.filter.brandId = '';
            this.filter.name = '';
        } else if (this.filter.name !== '') {
            this._brand = null;
            this._category = null;
            this.filter.brandId = '';
            this.filter.categoryId = '';
        }

        this.filter.qrEvendProducts = true;
        this.productService.getEvendProducts(this.page, this.filter).subscribe(res => {
            if (res.success) {
                if (this.evendProducts && this.evendProducts.length && !isItSelect) {
                    this.evendProducts = this.evendProducts.concat(res.result.docs);
                } else {
                    this.evendProducts = res.result.docs;
                }
                this.page = res.result.page;
                this.pages = res.result.pages;
                this.total = res.result.total;
            }
        });

    }


    // get products based on select input
    onSelectUpdate(isItSelect, select) {
        this.qrCode.evendProduct = null;
        this.page = 1;
        this.getEvendProducts(isItSelect, select);
    }

    onClear() {
        if (!this.brandSelect.hasValue) {
            this._brand = null;
        }
        if (!this.categorySelect.hasValue) {
            this._category = null;
        }
    }

    search(event: any) {
        this.filter.name = event.searchTerm;
        this.page = 1;
        this.getEvendProducts(true, 'search');
    }

    cancel() {
        this.changeProduct.emit({event: 'CANCEL', data: null});
    }

    onScroll() {
        if (this.page + 1 > this.pages) {
            return;
        }
        this.page += 1;

        this.getEvendProducts();

    }

    onScrollBrand() {
        if (this.page + 1 > this.pages) {
            return;
        }
        $('#page-loader').fadeIn();
        this.page += 1;
        this.brandService.getBrands(this.page)
            .subscribe(response => {
                $('#page-loader').fadeOut();
                for (const item of response.docs) {
                    this._brands.push(item);
                    this._brands = [...this._brands];
                }
                this.pages = response.pages;
            }, error => {
                $('#page-loader').fadeOut();
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Ürünlerin devamı yüklenemedi!'
                });
            });
    }

    onScrollCategory() {
        if (this.page + 1 > this.pages) {
            return;
        }
        $('#page-loader').fadeIn();
        this.page += 1;
        this.categoryService.getCategories(this.page)
            .subscribe(response => {
                $('#page-loader').fadeOut();
                for (const item of response.docs) {
                    this._categories.push(item);
                    this._categories = [...this._categories];
                }
                //this.pages = response.pages;
                this.pages = 1;
            }, error => {
                $('#page-loader').fadeOut();
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Ürünlerin devamı yüklenemedi!'
                });
            });
    }
}

export interface Filter {
    brandId: string;
    categoryId: string;
    name: string;
    qrEvendProducts?: boolean;
    qrEvendProductsForSubFirm?: boolean;
}
