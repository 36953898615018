// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr: false,

  /*apiBaseUrl: 'http://dev.api-v1.e-vend.com.tr/v1/',
  SOCKET_URL: 'http://dev.api-v1.e-vend.com.tr/',
  ProfileImagePath: 'http://dev.api-v1.e-vend.com.tr/img/profiles/',
  StaffImagePath: 'http://dev.api-v1.e-vend.com.tr/img/staffs/',
  AutomatImagePath: 'http://dev.api-v1.e-vend.com.tr/img/automats/',
  ProductImagePath: 'http://dev.api-v1.e-vend.com.tr/img/products/',
  PaymentImagePath: 'http://dev.api-v1.e-vend.com.tr/img/payments/',*/

  // New API
  // apiBaseUrl: 'https://api-v1.e-vend.com.tr/v1/',
  // SOCKET_URL: 'https://api-v1.e-vend.com.tr/',
  // ProfileImagePath: 'https://api-v1.e-vend.com.tr/img/profiles/',
  // StaffImagePath: 'https://api-v1.e-vend.com.tr/img/staffs/',
  // AutomatImagePath: 'https://api-v1.e-vend.com.tr/img/automats/',
  // ProductImagePath: 'https://api-v1.e-vend.com.tr/img/products/',
  // PaymentImagePath: 'https://api-v1.e-vend.com.tr/img/payments/',
  // FirmImagePath: 'https://api-v1.e-vend.com.tr/img/firm/',

  apiBaseUrl: 'https://test.api-v1.e-vend.com.tr/v1/',
  localPaymentUrl: 'https://test.api-v1.e-vend.com.tr/v1/',
  SOCKET_URL: 'https://test.api-v1.e-vend.com.tr/',
  xlsxPath: 'https://test.api-v1.e-vend.com.tr/',
  ProfileImagePath: 'https://test.api-v1.e-vend.com.tr/img/profiles/',
  StaffImagePath: 'https://test.api-v1.e-vend.com.tr/img/staffs/',
  AutomatImagePath: 'https://test.api-v1.e-vend.com.tr/img/automats/',
  ProductImagePath: 'https://test.api-v1.e-vend.com.tr/img/products/',
  PaymentImagePath: 'https://test.api-v1.e-vend.com.tr/img/payments/',
  FirmImagePath: 'https://test.api-v1.e-vend.com.tr/img/firm/',

  /* apiBaseUrl: 'http://192.168.1.41:8080/v1/',
  SOCKET_URL: 'http://192.168.1.41:3001/',
  ProfileImagePath: 'http://192.168.1.41:8080/img/profiles/',
  StaffImagePath: 'http://192.168.1.41:8080/img/staffs/',
  AutomatImagePath: 'http://192.168.1.41:8080/img/automats/',
  ProductImagePath: 'http://192.168.1.41:8080/img/products/',
  PaymentImagePath: 'http://192.168.1.41:8080/img/payments/',
  FirmImagePath: 'http://192.168.1.41:8080/img/firm/', */

  // apiBaseUrl: 'http://192.168.1.49:7575/v1/',
  // xlsxPath: 'http://192.168.1.49:7575/',
  // SOCKET_URL: 'http://192.168.1.49:3002/',
  // ProfileImagePath: 'http://192.168.1.49:7575/img/profiles/',
  // StaffImagePath: 'http://192.168.1.49:7575/img/staffs/',
  // AutomatImagePath: 'http://192.168.1.49:7575/img/automats/',
  // ProductImagePath: 'http://192.168.1.49:7575/img/products/',
  // PaymentImagePath: 'http://192.168.1.49:7575/img/payments/',
  // FirmImagePath: 'http://192.168.1.49:7575/img/firm/',

  WaitingTimeLimit: 240000,
  MapStyle: {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  },

  MapStyleNight: {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ]
  },
  loadingSpinnerType: 'ball-spin',
  loadingSpinnerSize: 'medium',
  loadingSpinnerColor: '#a80019',
  loadingSpinnerText: 'Yükleniyor...',
  loadingSpinnerBdColor: 'rgba(51,51,51, 0.1)'
};

