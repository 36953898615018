import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';


export enum TimeZone {
  ALL,
  YEAR,
  MONTH,
  WEEK
}


@Component({
  selector: 'app-payment-process',
  templateUrl: './payment-process.component.html',
  styleUrls: ['./payment-process.component.css']
})
export class PaymentProcessComponent implements OnInit {
  readonly TimeZone: TimeZone;

  currentTimeZone: TimeZone = TimeZone.ALL;
  text: any;

  //Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  //END : Ngx-spinner değişkenleri (Loading)

  constructor(private loadingSpinner: NgxSpinnerService) { }

  ngOnInit() {
    this.loadingSpinner.hide();
  }

}
