  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Kart Kimliği</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeCustom()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">

      <div class="container">
        <div class="row justify-content-end">
              <span class="mr-2 align-self-center"
                    [ngClass]="selectedOfflineUser?.status == 0 ? 'state-passive' : 'state-active'">
                {{ selectedOfflineUser?.status == 1 ? 'Aktif' : 'Pasif' }}
              </span>

          <ui-switch [checked]="selectedOfflineUser?.status == 1"
                     (valueChange)="stateChanged($event)"
                     [disabled]="isStateInProcess"
                     defaultBgColor="red"></ui-switch>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <span class="h5">{{ selectedOfflineUser?.name }}</span><br>
          <span class="font-weight-bold">{{ selectedOfflineUser?.cardId }}
            - {{ selectedOfflineUser?.cardIdDec }}</span>
        </div>
        <div class="col-2 text-right">
          Firma Bakiyesi<br>
          <span class="h4">
                {{ selectedOfflineUser?.firmBalance | number | commaDotSwapper }} ₺
              </span>
        </div>
        <div class="col-2 text-right">
          Kullanıcı Bakiyesi<br>
          <span class="h4">
                {{ selectedOfflineUser?.balance | number | commaDotSwapper }} ₺
              </span>
        </div>
      </div>
      <div class="row my-2" style="border: 1px solid dimgray;"></div>

      <!-- Tab başlıkları -->
      <div class="row" style="justify-content: center">
        <ul class="nav nav-pills modal-tab-bg mb-3 justify-content-center" id="kart-kimligi-modal" role="tablist">
          <li class="col-12 col-md nav-item">
            <a class="nav-link mx-auto" [ngClass]="selectedTab == 0 ? 'active' : '' " (click)="showTab(0)"
               id="kart-sahipligi-tab" data-toggle="pill" href="#kart-sahipligi" role="tab"
               aria-controls="kart-sahipligi" aria-selected="true">
              Kart Sahipliği
            </a>
          </li>
          <li class="col-12 col-md nav-item">
            <a class="nav-link mx-auto" [ngClass]="selectedTab == 1 ? 'active' : '' " (click)="showTab(1)"
               id="harcama-gecmisi-tab" data-toggle="pill" href="#harcama-gecmisi" role="tab"
               aria-controls="harcama-gecmisi" aria-selected="false">
              İşlem Geçmişi
            </a>
          </li>
          <li class="col-12 col-md nav-item">
            <a class="nav-link mx-auto" [ngClass]="selectedTab == 2 ? 'active' : '' " (click)="showTab(2)"
               id="gecmis-kullanicilar-tab" data-toggle="pill" href="#gecmis-kullanicilar" role="tab"
               aria-controls="gecmis-kullanicilar" aria-selected="false">
              Geçmiş Kullanıcılar
            </a>
          </li>
          <li class="col-12 col-md nav-item">
            <a class="nav-link mx-auto" [ngClass]="selectedTab == 3 ? 'active' : '' " (click)="showTab(3)"
               id="bakiye-iade-yukleme-tab" data-toggle="pill" href="#bakiye-iade-yukleme" role="tab"
               aria-controls="bakiye-iade-yukleme" aria-selected="false">
              Bakiye İade/Yükleme
            </a>
          </li>
        </ul>
      </div>
      <!-- Tab başlıkları : Son -->

      <!-- Tab içeriği -->
      <div class="tab-content" id="kart-kimligi-modal-tablar">
        <!-- Kart Sahipliği -->
        <div class="tab-pane fade" [ngClass]="selectedTab == 0 ? 'show active' : '' " id="kart-sahipligi"
             role="tabpanel" aria-labelledby="kart-sahipligi-tab">

          <div class="container mt-2">

            <div class="row mt-3 mb-4">
              <div class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       #userName type="text" name="userName"
                       [value]="selectedOfflineUser?.name"
                       placeholder="Kullanıcı Adı">
              </div>
              <div *ngIf="selectedOfflineUser" class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       #cardId type="text" name="cardId"
                       [(ngModel)]="selectedOfflineUser.cardId"
                       placeholder="Kart UID (16 tabanlı)" (ngModelChange)="SelectedIdChange($event,true)">
              </div>
              <div *ngIf="selectedOfflineUser" class="col-md-4">
                <input class="form-control no-border metin-girisi text-center"
                       #cardIdDec type="text" name="cardIdDec"
                       [(ngModel)]="selectedOfflineUser.cardIdDec"
                       placeholder="Kart UID (10 tabanlı)" (ngModelChange)="SelectedIdChange($event,false)">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #gorev type="text" name="gorev"
                       [value]="selectedOfflineUser?.gorev"
                       placeholder="Görev">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #sicilNo type="text" name="sicilNo"
                       [value]="selectedOfflineUser?.sicilNo"
                       placeholder="Sicil Numarası">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #birim type="text" name="birim"
                       [value]="selectedOfflineUser?.birim"
                       placeholder="Birim">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #explanation1 type="text" name="explanation1"
                       [value]="selectedOfflineUser?.explanation1"
                       placeholder="Açıklama 1">
              </div>
            </div>
            <div class="row mt-3 mb-4">
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #explanation2 type="text" name="explanation2"
                       [value]="selectedOfflineUser?.explanation2"
                       placeholder="Açıklama 2">
              </div>
              <div class="col-md-6">
                <input class="form-control no-border metin-girisi text-center"
                       #explanation3 type="text" name="explanation3"
                       [value]="selectedOfflineUser?.explanation3"
                       placeholder="Açıklama 3">
              </div>
            </div>
            <div class="row mt-2 mb-5">
              <div class="col-md-6">

                <ui-switch #newUserCheck size="small"></ui-switch>

                <span class="ml-1 align-top" (click)="newUserCheck.checked = !newUserCheck.checked"
                      data-toggle="tooltip" data-placement="top"
                      title="Bu alanı işaretlerseniz eski kullanıcı geçmişe gönderilip yeni kullanıcı ataması yapılacaktır, bu işlem geri alınamaz!">
                      Yeni kullanıcı olarak tanımla
                    </span>
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-primary"
                        (click)="updateOfflineUser(userName.value, selectedOfflineUser.cardId,selectedOfflineUser.cardIdDec, gorev.value, sicilNo.value, birim.value,
                          explanation1.value, explanation2.value, explanation3.value, newUserCheck.checked)">Güncelle
                </button>
              </div>

            </div>

          </div>

        </div>
        <!-- Kart Sahipliği : Son-->
        <!-- Harcama Geçmişi -->
        <div class="tab-pane fade " [ngClass]="selectedTab == 1 ? 'show active' : '' " id="harcama-gecmisi"
             role="tabpanel" aria-labelledby="harcama-gecmisi-tab">
          <div class="container"
               *ngIf="!(selectedOfflineUserTransactions.loading$ | async) || this.selectedDate !== undefined ">
            <div class="row">
              <div class="col-8">
              </div>
              <div class="col align-self-end">

                <button class="btn btn-primary mr-1" style="float:right;" (click)="clearExcelEmailAddress()"
                >EXCEL mail gönder
                </button>
                <button class="btn btn-success mr-1" style="float:right;" (click)="downloadXLSX()">EXCEL indir
                </button>
                <div class="spinner-container" *ngIf="selectedOfflineUserTransactions.loading$ | async">
                    <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </div>
          <!-- Boş liste uyarı -->
          <div *ngIf="selectedOfflineUserTransactions.loading$ | async" class="row justify-content-center">
            <div class="loader fadeIn" style="font-size: 60%">{{'DASHBOARD.LOADING' | translate}}...</div>
          </div>
          <!-- Boş liste uyarı : SON -->
          <mat-table [dataSource]="selectedOfflineUserTransactions" class="mat-elevation-z1 mt-4" [ngClass]="{'d-none': selectedOfflineUserTransactions.loading$ | async}"
                matSort matSortActive="Kart Sahibi" matSortDirection="asc" matSortDisableClear>

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Kart Sahibi -->
            <ng-container matColumnDef="Kart Sahibi">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Kart Sahibi </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{transaction.name}} </mat-cell>
            </ng-container>

            <!-- İşlem Tarihi -->
            <ng-container matColumnDef="İşlem Tarihi">
              <mat-header-cell *matHeaderCellDef> İşlem Tarihi </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{getTranactionDate(transaction) | date: 'dd.MM.yyyy HH:mm:ss'}} </mat-cell>
            </ng-container>

            <!-- Otomat -->
            <ng-container matColumnDef="Otomat">
              <mat-header-cell *matHeaderCellDef> Otomat </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{transaction.automat?.name}} </mat-cell>
            </ng-container>

            <!-- Spiral No -->
            <ng-container matColumnDef="Spiral No">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Spiral No </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{transaction.spiralNumber}} </mat-cell>
            </ng-container>

            <!-- Ürün -->
            <ng-container matColumnDef="Ürün">
              <mat-header-cell *matHeaderCellDef> Ürün </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{isEmpty(transaction.automatProduct) || transaction.automatProduct?.product === null ? '' : transaction.automatProduct?.product?.name}} </mat-cell>
            </ng-container>

            <!-- Ürün Resmi -->
            <ng-container matColumnDef="Ürün Resmi">
              <mat-header-cell *matHeaderCellDef> Ürün Resmi </mat-header-cell>
              <mat-cell *matCellDef="let transaction">
                <img
                    *ngIf="isEmpty(transaction.automatProduct) || isEmpty(transaction.automatProduct?.product) || transaction.automatProduct?.product?.image === ''; else imageElse"
                    class="img-responsive" src="../../assets/img/gorsel_yok.svg" height="30">
                  <ng-template #imageElse>
                    <img class="img-responsive"
                         src="{{transaction.automatProduct.product.image | image: ProductImagePath }}" height="30">
                  </ng-template>
              </mat-cell>
            </ng-container>

            <!-- İşlem Türü -->
            <ng-container matColumnDef="İşlem Türü">
              <mat-header-cell *matHeaderCellDef> İşlem Türü </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{getTransactionProcessTypeText(transaction.processType)}} </mat-cell>
            </ng-container>

            <!-- Bakiye -->
            <ng-container matColumnDef="Bakiye">
              <mat-header-cell *matHeaderCellDef> Bakiye </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{transaction.previousTotalBalance}} </mat-cell>
            </ng-container>

            <!-- İşlem Tutarı -->
            <ng-container matColumnDef="İşlem Tutarı">
              <mat-header-cell *matHeaderCellDef> İşlem Tutarı </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ round(transaction.total, 2)  }} ₺ </mat-cell>
            </ng-container>

            <!-- Güncel Bakiye -->
            <ng-container matColumnDef="Güncel Bakiye">
              <mat-header-cell *matHeaderCellDef> Güncel Bakiye </mat-header-cell>
              <mat-cell *matCellDef="let transaction"> {{ round(transaction.firmBalance + transaction.balance, 2) }} ₺ </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
          <mat-paginator [length]="paginatorLength" [pageSize]="10"
                    [pageSizeOptions]="options"></mat-paginator>
        </div>
        <!-- Harcama Geçmişi : Son-->
        <!-- Geçmiş Kullanıcılar -->
        <div class="tab-pane fade" [ngClass]="selectedTab == 2 ? 'show active' : '' " id="gecmis-kullanicilar"
             role="tabpanel" aria-labelledby="gecmis-kullanicilar-tab">

          <div *ngIf="selectedOfflineUserHistory.length != 0" class="table-responsive mt-2 table-scrollbar">
            <table class="table table-borderless table-hover table-sm modal-table">
              <thead>
              <tr>
                <th scope="col">Kart Sahibi</th>
                <th scope="col" class="text-center">İşlem Tarihi</th>
                <th scope="col" class="text-center">Toplam Yüklenen Bakiye</th>
                <th scope="col" class="text-center">Toplam Harcanan Bakiye</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of selectedOfflineUserHistory; let i = index">
                <td>{{data.name}}</td>
                <td class="text-center">{{ data.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
                <td class="text-center">{{ data.totalFirmFund }}</td>
                <td class="text-center">{{ data.totalSpendFirmBalance }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- Boş liste uyarı -->
          <div *ngIf="selectedOfflineUserHistory.length == 0" class="row justify-content-center">
            <h1 class="h5 my-5" style="color: gray">Eski kart kullanıcısı yok!</h1>
          </div>
          <!-- Boş liste uyarı : SON -->

        </div>
        <!-- Geçmiş Kullanıcılar : Son-->
        <!-- Bakiye İade/Yükleme -->
        <div class="tab-pane fade" [ngClass]="selectedTab == 3 ? 'show active' : '' " id="bakiye-iade-yukleme"
             role="tabpanel" aria-labelledby="bakiye-iade-yukleme-tab">
          <div class="row justify-content-center mb-3">
            <div class="col-md-4">
              <select id="processType" name="processType"
                      class="custom-select select-modal select-custom-icon h-100" #processType>
                <option value="-1" selected disabled>İşlem Tipi Seçiniz</option>
                <option value="0">İade</option>
                <option value="1">Yükleme</option>
                <option value="11">Düşüm</option>
                <option value="2">Sıfırla</option>
              </select>
            </div>
            <div class="input-group col-md-4">
              <input class="form-control no-border metin-girisi text-center" (keydown)="validatePrice($event)"
                     id="balance" name="balance" [(ngModel)]="balance" type="number" min="0" placeholder="0.00">
              <div class="input-group-append">
                <span class="input-group-text no-border metin-girisi">₺</span>
              </div>

            </div>
          </div>

          <div *ngIf="processType.value != 2" class="row mt-4 mb-5 justify-content-center">
            <button class="btn btn-primary" (click)="updateOfflineUserBalance(processType.value, balance)">
              Gönder
            </button>
          </div>
          <div *ngIf="processType.value == 2" class="row mt-4 mb-5 justify-content-center">
            <button class="btn btn-primary" (click)="resetOfflineUserBalance()">Sıfırla</button>
          </div>
        </div>
        <!-- Bakiye İade/Yükleme : Son -->
      </div>
      <!-- Tab içeriği : Son -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closeCustom()">Kapat</button>
  </div>
