
export class AutomatPaymentSystem {
  constructor() {}
  _id: string;
  name: string;
  firm: string;
  automats: [string];
  paymentSystems: [any];

}
