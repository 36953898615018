import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getMobilePaymentSystemsFirms(): Observable<any> {

    return this.http.get(environment.apiBaseUrl
      + 'get-all-mobile-payment-system-firms').pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));

  }


  /*İYZİCO*/
  createPersonalIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-create-c2c',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  updatePersonalIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-update-c2c',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  createPrivateCompanyIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-create-personel-company',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  updatePrivateCompanyIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-update-personel-company',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  createLimitedOrJointStockCompanyIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-create-company',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  updateLimitedOrJointStockCompanyIyzico(data): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'iyzico-update-company',
      JSON.stringify(data),
      {headers: this.headers}).pipe(
      map((response: Response) => {
        return response;
      }));
  }

  getInfoIyzico(id: string): Promise<any> {
    return this.http.get(environment.apiBaseUrl
      + 'firm/iyzico-get-sub-merchant-info/' + id).toPromise();
  }

  /*İYZİCO END*/


}
