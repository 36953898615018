<div class="container-fluid top-bg pt-4 pb-3">
  <div class="row animated fadeInDown">
    <div class="col-md-2 col-lg-2 col-xl-2 align-self-center">
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" #dropdownFilter type="button" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'SALES_REPORT.APPLY_FILTER' | translate}}
        </button>
        <div style="position: absolute; top:40px;">
          <div class="dropdown-menu" aria-labelledby="dropdownFilter">
            <form>
              <div class="container p-4">
                <div class="row">
                  <div class="col">
                    <select [(ngModel)]="filter.district" name="district" class="custom-select select-product-modal select-custom-icon">
                      <option value="">{{'SALES_REPORT.REGION' | translate}}</option>
                      <option
                        *ngFor="let automatDistrict of automatDistricts;"
                        value="{{ automatDistrict?._id }}">{{automatDistrict?._id}}</option>
                    </select>
                    <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.productName" #productName="ngModel" type="text" name="productName" placeholder="{{'SALES_REPORT.PRODUCT_NAME' | translate}}">
                    <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.productPrice" #productName="ngModel"
                            (keydown)="validatePrice($event)"
                            type="number" min="0" name="productPrice" placeholder="Ürün Fiyatı">
                    <select [(ngModel)]="filter.paymentType" (change)="getTextOfPaymentType($event)" name="paymentType" class="custom-select select-product-modal select-custom-icon mt-4">
                      <option value="-1">{{'SALES_REPORT.PAYMENT_METHOD' | translate}}</option>
                      <option value="1">{{'SALES_REPORT.CREDIT_CARD' | translate}}</option>
                      <option value="2">{{'SALES_REPORT.CASH' | translate}}</option>
                      <option value="3">{{'SALES_REPORT.PRE_PAID_CARD' | translate}}</option>
                      <option value="5">{{'SALES_REPORT.QR' | translate}}</option>
                    </select>
                  </div>
                  <!-- Divider -->
                  <div class="col">
                    <select [(ngModel)]="filter.group" (change)="getAutomatNameID()" name="group" class="custom-select select-product-modal select-custom-icon">
                      <option value="">{{'SALES_REPORT.VENDING_GROUP' | translate}}</option>
                      <option
                        *ngFor="let automatGroup of automatGroups;"
                        value="{{ automatGroup._id }}">{{automatGroup.name}}</option>
                    </select>
                    <select [(ngModel)]="filter.automat" name="nameID" (change)="getTextOfAutomat($event)" class="custom-select select-product-modal select-custom-icon mt-4">
                      <option value="">{{'SALES_REPORT.VENDING_NAME_ID' | translate}}</option>
                      <option
                        *ngFor="let automatNameID of automatNameIDs;"
                        value="{{ automatNameID._id }}">{{automatNameID.name}}</option>
                    </select>
                    <input class="form-control no-border text-enter mt-4" [(ngModel)]="filter.amount" #amount="ngModel"
                            (keydown)="validatePrice($event)"
                            type="number" min="0" name="amount" placeholder="{{'SALES_REPORT.TOTAL_PRICE' | translate}}">
                    <select [(ngModel)]="filter.resultType" (change)="getTextOfResultType($event)" name="resultType" class="custom-select select-product-modal select-custom-icon mt-4">
                      <option value="-1">{{'SALES_REPORT.RESULT_TYPE' | translate}}</option>
                      <option value="1">{{'SALES_REPORT.COMPLETED' | translate}}</option>
                      <option value="2">{{'SALES_REPORT.CONTINUE' | translate}}</option>
                      <option value="3">{{'SALES_REPORT.CANCEL' | translate}}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <button class="btn btn-danger mt-5" (click)="clearFilter()" style="width: 100%">{{'SALES_REPORT.RESET_FILTER' | translate}}</button>
                  </div>
                  <div class="col">
                    <button class="btn btn-success mt-5" (click)="applyFilter()" style="width: 100%">{{'SALES_REPORT.APPLY_FILTER_CAPS' | translate}}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-lg-5 col-xl-6 text-center">
      <span class="title">{{today | date: 'EEEE, d MMMM y' : undefined : 'tr' }}</span>
      <!-- undefined olan timezone -->
    </div>


    <div class="col-md-6 col-lg-5 col-xl-4">
      <div class="container">
        <div class="row justify-content-end text-center">
          <button class="btn btn-success mr-1" (click)="downloadExcel()">{{'SALES_REPORT.EXCEL' | translate}}</button>
          <!--<div (click)="writeConsole('Sol Tık')" id="left-arrow"></div>
          <div (click)="writeConsole('Sağ Tık')" id="right-arrow"></div>
          <select [(ngModel)]="selectedDay" class="custom-select select-filter select-day-icon">
            <option
              *ngFor="let day of days; let i = index"
              value="{{ i }}">{{day}}</option>
          </select>-->
          <div class="border-line">
            <div class="container h-100">
              <div class="row h-100">
                <div
                  class="col sale-tab align-self-center"
                  (click)="changeTimeTab('date')"
                  [ngClass]="selectedTimeTab === 'date' ? 'active-tab':'' ">{{'SALES_REPORT.DAY' | translate}}</div>
                <div
                  class="col sale-tab align-self-center"
                  (click)="changeTimeTab('week')"
                  [ngClass]="selectedTimeTab === 'week' ? 'active-tab':'' ">{{'SALES_REPORT.WEEK' | translate}}</div>
                <div
                  class="col sale-tab align-self-center"
                  (click)="changeTimeTab('month')"
                  [ngClass]="selectedTimeTab === 'month' ? 'active-tab':'' ">{{'SALES_REPORT.MONTH' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row animated fadeInDown">
    <div class="col mt-1 d-flex">
      <div class="pl-2" *ngIf="filter.district !== ''">
        {{'SALES_REPORT.REGION' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{filter.district}}</a>
        <!-- <img src="/assets/img/cancelCustom.svg" width="15px"> -->
               </span>
      </div>
      <div class="pl-2" *ngIf="filter.productName !== ''">
        {{'SALES_REPORT.PRODUCT_NAME' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{filter.productName}}</a>
        <!--                <img src="/assets/img/cancelCustom.svg" width="15px">-->
            </span>
      </div>
      <div class="pl-2" *ngIf="filter.productPrice !== undefined && filter.productPrice !== 0 && filter.productPrice">
        {{'SALES_REPORT.PRODUCT_PRICE' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{filter.productPrice}}</a>
        <!--                <img src="/assets/img/cancelCustom.svg" width="15px">-->
            </span>
      </div>
      <div class="pl-2" *ngIf="filter.group !== ''">
        {{'SALES_REPORT.GROUP' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{groupText}}</a>
        <!--                  <img src="/assets/img/cancelCustom.svg" width="15px">-->
            </span>
      </div>
      <div class="pl-2" *ngIf="filter.automat !== ''">
        {{'SALES_REPORT.VENDING' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{automatText}}</a>
        <!--                      <img src="/assets/img/cancelCustom.svg" width="15px">-->
          </span>
      </div>
      <div class="pl-2" *ngIf="(filter.amount !== undefined && filter.amount !== 0) && filter.amount">
        {{'SALES_REPORT.TOTAL_PRICE' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{filter.amount}}</a>
        <!--                    <img src="/assets/img/cancelCustom.svg" width="15px">-->
          </span>
      </div>
      <div class="pl-2" *ngIf="filter.paymentType.toString() !== '-1'">
        {{'SALES_REPORT.PAYMENT_METHOD' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{paymentTypeText}}</a>
        <!--                <img src="/assets/img/cancelCustom.svg" width="15px">-->
            </span>
      </div>
      <div class="pl-2" *ngIf="filter.resultType.toString() !== '-1'">
        {{'SALES_REPORT.RESULT' | translate}} : <span class="p-1 border" style="border-radius: 10px"><a> {{resultTypeText}}</a>
        <!--                  <img src="/assets/img/cancelCustom.svg" width="15px">-->
          </span>
      </div>

    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table">
    <thead>
    <tr>
      <th scope="col" class="table-header">{{'SALES_REPORT.USERNAME' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.BASKET_ID' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.TIME' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.VENDING' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.SALES_METHOD' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.SALES_PRICE' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.PRODUCTS' | translate}}</th>
      <th scope="col" class="table-header">{{'SALES_REPORT.RESULT' | translate}}</th>
    </tr>
    </thead>

    <tbody
      infiniteScroll
      [infiniteScrollDistance]="pages"
      [infiniteScrollThrottle]="20"
      (scrolled)="onScroll()"
      id="table-datas">
    <tr *ngFor="let data of rows">
      <td style="text-align: left">
        {{'SALES_REPORT.CUSTOMER' | translate}}
      </td>
      <td>{{data?._id?.payment[0]?.basketHistory}}</td>
      <td>{{data?._id?.d | date: 'dd.MM.yyyy HH:mm:ss'}}</td>
      <td>{{data?._id?.automat[0]?.name}}</td>
      <td>{{getPaymentTypeText(data?._id?.payment[0]?.paymentType)}}</td>
      <td class="font-weight-bold">{{data?.amount | number | commaDotSwapper}} ₺</td>
      <td>
        <button
          (click)="getBasketHistoryProducts(data?._id?.payment[0]?.basketHistory)"
        class="btn-process" style="cursor: pointer;">{{'SALES_REPORT.SHOW_PRODUCTS' | translate}}</button>
      </td>
      <td>
        <button
          *ngIf="data?._id?.payment[0]?.isDelivered"
          class="btn-okay"><i class="fas fa-check mr-1"></i>{{'SALES_REPORT.COMPLETED' | translate}}</button>

        <button
          *ngIf="data?._id?.payment[0]?.isCompleted && !data?._id?.payment[0]?.isDelivered"
          class="btn-process">Devam Ediyor</button>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<div class="container mb-2" id="page-loader" style="display: none">
  <div class="row justify-content-center">
    <img src="../../assets/img/page-loader.svg" alt="yükleniyor" width="80">
  </div>
</div>

<a (click)="topFunction()" id="topButton" title="En yukarı çık">
  <img src="../../assets/img/up-arrow.svg" alt="en yukarı çık" width="36">
</a>

<!-- Basket History Products -->
<button style="display: none;" #showBasketProductsModal data-toggle="modal" data-backdrop="true" data-target="#basketProductsModal"></button>
<div class="modal fade" id="basketProductsModal" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="container">
          <div class="row justify-content-end">
            <button #closeBasketProductsModal type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row modal-baslik justify-content-center">
            {{'SALES_REPORT.PRODUCTS_IN_BASKET' | translate}}
          </div>
        </div>

        <div class="container">
          <div
            *ngFor="let automatProduct of basketProductsData.products"
            class="row">
            <div class="col-md">
              <div class="row m-0 align-items-center">
                <div *ngIf="automatProduct?.product" class="col-md-1 p-1">
                  <img *ngIf="automatProduct?.product?.image !== '' " src="{{ automatProduct?.product.image | image: ProductImagePath }}" height="62" alt="ürün resmi">
                  <img *ngIf="automatProduct?.product?.image === '' " src="../../assets/img/gorsel_yok.svg" height="62" alt="ürün resmi">
                </div>
                <div *ngIf="!automatProduct?.product" class="col-md-1 p-1">
                  <img  src="../../assets/img/gorsel_yok.svg" height="62" alt="ürün resmi">
                </div>
                <div class="col-md-2 font-weight-bold">
                  {{ automatProduct?.product?.name }}
                </div>
                <div class="col-md-2 font-weight-bold">
                  {{ automatProduct?.price + " ₺" }}
                </div>
                <div class="col-md-2">
                  {{ automatProduct?.count + " Adet" }}
                </div>
                <div class="col-md-2">
                  {{ (automatProduct?.isDiscountApplied ? 'SALES_REPORT.DISCOUNT_PRICE' : 'SALES_REPORT.PRICE') | translate}}
                </div>
                <div class="col-md-3 text-center">
                  {{ automatProduct?.price * automatProduct?.count }} ₺
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md">
              <div class="row m-0 align-items-center">
                <div class="col-md-9"></div>
                <div class="col-md-3" style="margin-top: -10px">
                  <div class="row m-0 font-weight-bold">
                    +
                  </div>
                  <div class="row m-0">
                    <div style="border: solid 1px #000000;overflow: hidden;width: 100%"></div>
                  </div>
                  <div class="row m-1 justify-content-center">
                    <span *ngIf="basketProductsData" class="font-weight-bold">{{ basketProductsData?.amount | number | commaDotSwapper }} ₺</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
