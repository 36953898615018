import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import localeTr from '@angular/common/locales/tr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ImageCropperModule} from 'ngx-image-cropper';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {HeaderComponent} from './header/header.component';
import {SimpleLayoutComponent} from './containers/simple-layout/simple-layout.component';
import {FullLayoutComponent} from './containers/full-layout/full-layout.component';

import {ServiceCareComponent} from './service-care/service-care.component';
import {PersonelsComponent} from './personels/personels.component';
import {SystemMessagesComponent} from './system-messages/system-messages.component';
import {SalesComponent} from './sales/sales.component';
import {SaleReportComponent} from './sale-report/sale-report.component';

import {PipesModule} from './_pipes/pipes.module';
import {BasketReportComponent} from './basket-report/basket-report.component';
import {AutomatGroupsComponent} from './automat-groups/automat-groups.component';
import {GroupAutomatsComponent} from './group-automats/group-automats.component';
import * as moment from 'moment';
import {OfflineCardsComponent} from './offline-cards/offline-cards.component';
import {PaymentProcessComponent} from './payment-process/payment-process.component';
import {DashboardModule} from './dashboard/dashboard.module';
import {NgxLoadingModule} from 'ngx-loading';
import {ProceedsComponent} from './proceeds/proceeds.component';
import {DndModule} from 'ngx-drag-drop';
import {OfflineCardGroupPeriodComponent} from './offline-card-group-period/offline-card-group-period.component';
import {TokenInterceptor} from './_guards/http.interceptor';
import {NotificationsComponent} from './notifications/notifications.component';
import {SettingsModule} from './settings/settings.module';
import {UserPermissionsService} from './_services/user-permissions.service';
import {UsersModule} from './users/users.module';
import {ProductsModule} from './products/products.module';
import {AutomatsModule} from './automats/automats.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPermissionsModule} from 'ngx-permissions';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgSelectModule} from '@ng-select/ng-select';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UiSwitchModule} from 'ngx-ui-switch';
import {registerLocaleData} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DatePickerModule} from './_widgets/date-picker/date-picker.module';
import {DatePickerI18nService} from './_providers/date-picker-i18n.service';

import {CardPacketsModule} from './card-packets/card-packets.module';
import { SharedModule } from './_shared/shared.module';
import { TransactionStatementComponent } from './transaction-statement/transaction-statement.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DynamicFormModule } from './_widgets/dynamic-form/dynamic-form.module';
import { TranslateModule } from "@ngx-translate/core";
import { LanguageSelectorComponent } from './header/language-selector/language-selector.component';
import { GoogleAnalyticsService } from './_services/google-analytics.service';
import { CardModalComponent } from './offline-cards/card-modal/card-modal.component';
import { QrCodesComponent } from './qr-codes/qr-codes/qr-codes.component';
import { QrCodesAutomatGroupsComponent } from './qr-codes/qr-codes-automat-groups/qr-codes-automat-groups.component';
import { GenerateQrComponent } from './qr-codes/generate-qr/generate-qr.component';
import {PaginationTableListModule} from './_widgets/pagination-table-list/pagination-table-list.module';
import {SubFirmQrModule} from './sub-firm-qr/sub-firm-qr.module';
import { QrCodesSubUsersComponent } from './qr-codes/qr-codes-sub-users/qr-codes-sub-users.component';

moment.locale('tr');

registerLocaleData(localeTr, 'tr');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LanguageSelectorComponent,
    SimpleLayoutComponent,
    FullLayoutComponent,
    ServiceCareComponent,
    PersonelsComponent,
    SystemMessagesComponent,
    SalesComponent,
    SaleReportComponent,
    BasketReportComponent,
    AutomatGroupsComponent,
    GroupAutomatsComponent,
    OfflineCardsComponent,
    PaymentProcessComponent,
    ProceedsComponent,
    OfflineCardGroupPeriodComponent,
    NotificationsComponent,
    TransactionStatementComponent,
    CardModalComponent,
    QrCodesComponent,
    QrCodesAutomatGroupsComponent,
    GenerateQrComponent,
    QrCodesSubUsersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ImageCropperModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    GoogleMapsModule,
    UiSwitchModule,
    PipesModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSpinnerModule,
    SharedModule,
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(0,0,0, 0.0)',
      primaryColour: '#a80019',
      secondaryColour: '#a80019',
      tertiaryColour: '#a80019'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxPermissionsModule.forRoot(),
    DndModule,
    NgbModule,
    FontAwesomeModule,

    DashboardModule,
    SettingsModule,
    UsersModule,
    ProductsModule,
    AutomatsModule,
    CardPacketsModule,
    DatePickerModule,
    NgSelectModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    DynamicFormModule,
    TranslateModule.forRoot({
      defaultLanguage: 'tr'
    }),
    PaginationTableListModule,
      SubFirmQrModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (permService: UserPermissionsService) =>
        function () {
          return permService.userPermissions();
        },
      deps: [UserPermissionsService],
      multi: true
    },
    {
      provide: NgbDatepickerI18n,
      useClass: DatePickerI18nService
    },
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}

