import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FirmUserService} from '../_services/firm-user.service';
import {FirmUser} from '../_models/firmUser';
import {UserEditComponent} from './user-edit/user-edit.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {

  users: FirmUser[];
  selectedUser: FirmUser;

  @ViewChild('openUserModal', {static: false}) userModal: ElementRef;
  @ViewChild('closeUserModal', {static: false}) closeUserModal: ElementRef;

  constructor(
    private firmUserService: FirmUserService,
    private modalService: NgbModal) {
  }

  ngOnInit() {

    this.firmUserService.getFirmUsers().subscribe(res => {
      this.users = res;

      this.users.forEach(user => {
        if (!user.telemetryPermission) {
          user.telemetryPermission = [];
        }
      });
    });
  }

  openEditModal() {
    const modalRef = this.modalService.open(UserEditComponent, {scrollable: true, size: 'xl'});
    modalRef.componentInstance.selectedUser = this.selectedUser;
    modalRef.componentInstance.changeUser.subscribe((res) => {
      this.updateList(res);
    });
  }


  newUser() {
    this.selectedUser = null;
    this.openEditModal();
  }

  editUser(user) {
    this.selectedUser = JSON.parse(JSON.stringify(user)); //cloning
    this.openEditModal();
  }

  updateList(event) {
    if (event.event === 'new') {
      this.users.push(event.data);
    } else {
      const index: number = this.findUserIndex(event.data._id);
      if (event.event === 'update') {
        this.users[index] = event.data;
      } else if (event.event === 'delete') {
        this.users.splice(index, 1);
      }
    }

    this.modalService.dismissAll();
  }

  findUserIndex(id: string): number {
    return this.users.findIndex((data) => {
      return data._id === id;
    });
  }

}
