import {Point} from './point';

export class EditAutomat {

  _id: string;
  name: string;
  serial: string;
  external_id?: string;
  maxProductPrice: number;
  operator: string;
  group: string;
  automatModel: {
    _id: string,
    automatBrand: {
      _id: string,
    };
  };
  province: string;
  district: string;
  neighborhood: string;
  description: string;
  device: string;
  vPos: string;
  beacon: string;
  sim: string;
  modem: string;
  location: Point = new Point();
  isCoin: boolean;
  isCard: boolean;
  isTelemetry: boolean;
  isPreSalesCard: boolean;
  isMetropolCardQR: boolean;
  isMobil: boolean;
  isQrPayment: boolean;
  isStock: boolean;
  isEndOfAutomatSale: boolean;
  headStart: number;
  automatPaymentSystemId: string;
  automatPaymentSystemName: string;
  type: string;
  isStockControl: boolean;

  constructor() {
    this.name = null;
    this.serial = null;
    this.description = null;
    this.automatModel = {
      automatBrand: {
        _id: null,
      },
      _id: null
    };
    this.operator = null;
    this.group = null;
    this.province = null;
    this.district = null;
    this.neighborhood = null;
    this.device = null;
    this.vPos = null;
    this.beacon = null;
    this.sim = null;
    this.modem = null;
    this.isCoin = false;
    this.isCard = false;
    this.isQrPayment = false;
    this.isPreSalesCard = false;
    this.isMetropolCardQR = false;
    this.isMobil = false;
    this.automatPaymentSystemId = null;
    this.type = null;
    this.isStockControl = null;
  }
}
