import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppSettingsService } from '../../../_services/app-settings.service';
import Swal from 'sweetalert2';
import { SwalHelper } from '../../../_helpers/swal.helper';

@Component({
  selector: 'app-payter',
  templateUrl: './payter.component.html',
  styleUrls: ['./payter.component.scss']
})
export class payterComponent implements OnInit {
  swalOptions = new SwalHelper();
  isChecked = true;
  apiSettingsForm = new FormGroup({
    domain: new FormControl(''),
    username: new FormControl(''),
    password: new FormControl(''),
  });
  constructor(
    private loadingSpinner: NgxSpinnerService,
    private appSettingsService: AppSettingsService
    ) { }

  ngOnInit(): void {
  }

  save() {
    this.appSettingsService.payterAuthenticate(this.apiSettingsForm.value).subscribe(res => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.success,
        text: 'Kullanıcı bilgileri güncellendi.'
      });
    },
    error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kart durumu değiştirilemedi!'
      });
    });
  }

  stateChanged(isActive) {
    this.isChecked = isActive;
    this.loadingSpinner.show();
    const val = isActive ? 1 : 0;
  }

}
