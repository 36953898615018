import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AutomatBrandService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getAllBrands(page: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'automat-brands/'+ page,
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

  getAllBrandsNoPage(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'get-automat-brands/',
      {headers: this.headers}).pipe(
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

}
