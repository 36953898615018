import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CashlessService} from '../_services/cashless.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import * as XLSX from 'xlsx';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {OfflineUser} from '../_models/offlineUser';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CardModalComponent } from "./card-modal/card-modal.component";

declare var $: any;

@Component({
  selector: 'app-offline-cards',
  templateUrl: './offline-cards.component.html',
  styleUrls: ['./offline-cards.component.css']
})
export class OfflineCardsComponent implements OnInit{
  swalOptions = new SwalHelper();

  @ViewChild('addCardModal', {static: false}) addCardModal: NgbModal;
  @ViewChild('mailModal', {static: false}) mailModal: NgbModal;

  ProductImagePath: string;
  groupData = {id: '', name: ''};
  offlineUserGroupData: any;
  offlineUsers = [];

  excelEmailAddress = '';
  regexp;
  emailValid = false;

  isDownloadReady = false;

  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  // END : Ngx-spinner değişkenleri (Loading)

  checkedOfflineUser: boolean;
  checkedOfflineUsers = [];
  makeActiveDeleteButton = false;

  fileName = 'Seçilmiş dosya yok.';
  offlineUser: OfflineUser;
  offlineUsersArray: any;
  hasOwnProperty = Object.prototype.hasOwnProperty;

  constructor(private cashlessService: CashlessService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService,
              private exportsHelper: ExportsHelperService,
              private modalService: NgbModal) {
    this.offlineUser = new OfflineUser();
  }

  ngOnInit() {
    this.ProductImagePath = environment.ProductImagePath;
    this.getOfflineUsers();
    this.setOfflineUserGroupId();
  }


  getGroups() {
    this.loadingSpinner.show();
    this.cashlessService.getOfflineUserGroupList(this.groupData.id).subscribe(res => {
        this.loadingSpinner.hide();
        if (res.success) {
          this.offlineUserGroupData = res.offlineUserGroup;
        } else {

          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart paketleri sunucudan getirilemedi!'
          });
        }

      },
      error => {
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Kart paketleri sunucudan getirilemedi!'
        });
      });
  }

  showCardModal(card, index) {
    const modalRef = this.modalService.open(CardModalComponent, {centered: true, size: 'xl', scrollable: true});
    modalRef.componentInstance.selectedOfflineUserIndex = index;
    modalRef.componentInstance.selectedOfflineUser = card;
  }

  getOfflineUsers() {
    this.loadingSpinner.show();
    this.offlineUsers = [];
    this.cashlessService.Id.subscribe(offlineUserGroup => {
      this.groupData = Object.assign(this.groupData, offlineUserGroup);
      this.getGroups();
      this.cashlessService.getOfflineUserList(this.groupData.id).subscribe(res => {
          if (res === null) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Bu grubun altında listelenecek kart yok!'
            }).then(() => {
              this.navigateTo('/card-packets');
            });
          } else {
            this.offlineUsers = res;
            this.isDownloadReady = true;
          }
          this.loadingSpinner.hide();
        },
        error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kartlar sunucudan getirilemedi!'
          });
        });
    });
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

  checkedUser(event, offlineUserId: string) {
    let index: number;
    if (event.target.checked) {
      this.checkedOfflineUser = true;
      this.makeActiveDeleteButton = true;
      this.checkedOfflineUsers.push(offlineUserId);
    } else {
      this.checkedOfflineUser = false;
      index = this.checkedOfflineUsers.indexOf(offlineUserId, 0);
      if (index > -1) {
        this.checkedOfflineUsers.splice(index, 1);
      }
      if (this.checkedOfflineUsers.length === 0) {
        this.makeActiveDeleteButton = false;
      }
    }
  }

  signCheckedUser(id: string) {
    if (this.checkedOfflineUsers.length > 0) {
      for (let i = 0; i < this.checkedOfflineUsers.length; i++) {
        if (this.checkedOfflineUsers[i] === id) {
          return true;
        }
      }
    }
  }

  deleteOfflineUsers() {
    Swal.fire({
      ...this.swalOptions.delete,
      title: 'Kart paketindeki seçtiğiniz kullanıcı kartlarını silinecek onaylıyor musunuz?',
      text: 'Onaylarsanız seçilen tüm kullanıcı kartları silenecektir.'
    }).then((result) => {
      if (result.value) {
        if (this.checkedOfflineUsers.length > 0) {
          this.cashlessService.deleteOfflineUsers(this.checkedOfflineUsers, this.groupData.id).subscribe(res => {
            Swal.fire({
              ...this.swalOptions.success,
              text: 'Seçilen kullanıcı kartları başarı ile silindi.'
            });
            this.checkedOfflineUsers.splice(0, this.checkedOfflineUsers.length);
            this.makeActiveDeleteButton = false;
            this.getOfflineUsers();
          }, error => {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Silme işlemi başarısız.'
            });
          });
        }
      }
    });
  }

  /* sortTransactions() {
    this.selectedOfflineUserTransactions.sort((a: any, b: any) => {
        const aDate = new Date((a.saleDate === null || a.saleDate === undefined) ? a.createdAt : a.saleDate);
        const bDate = new Date((b.saleDate === null || b.saleDate === undefined) ? b.createdAt : b.saleDate);
        return aDate.valueOf() - bDate.valueOf();
      }
    );
  } */


  onFileChange(evt: any) {
    const file = evt.target.files[0];
    this.fileName = file.name;
    this.offlineUsersArray = undefined;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      throw new Error('Birden fazla dosya kullanılamaz!');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.offlineUsersArray = XLSX.utils.sheet_to_json(ws, {header: 1});
      if (Array.isArray(this.offlineUsersArray)) {
        for (let _i = 0; _i < this.offlineUsersArray.length; _i++) {
          const data = this.offlineUsersArray[_i][1];
          const data2 = this.offlineUsersArray[_i][2];
          if (typeof data === 'string' || data instanceof String || typeof data2 === 'string' || data2 instanceof String) {
            this.offlineUsersArray[_i][1] = data ? data.toLowerCase() : null;
            this.offlineUsersArray[_i][2] = data2 ? data2.toLowerCase() : null;
          } else {
            this.offlineUsersArray = undefined;
            break;
          }
        }
      } else {
        this.offlineUsersArray = undefined;
      }
    };
    reader.readAsBinaryString(target.files[0]);
  }

    /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.onFileChange($event);
  }

  downloadSampleExcel() {
    this.loadingSpinner.show();
    this.exportsHelper.sampleDownloadExcel();
    this.loadingSpinner.hide();
  }

  closeModal(form: NgForm) {
    form.resetForm();
    this.modalService.dismissAll();
  }

  sendToDatabase() {
    this.loadingSpinner.show();
    if (this.offlineUsersArray) {
      let offlineGroupAutomatId = [];
      this.offlineUserGroupData.forEach((data, i) => {
        if (this.groupData.id === data._id) {
          offlineGroupAutomatId = data.automatGroup;
        }
      });
      this.cashlessService.addOfflineUsers(this.offlineUsersArray, this.groupData.id, offlineGroupAutomatId).subscribe(res => {
        if (res.success) {
          this.modalService.dismissAll();
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.success,
            text: 'Kişiler başarıyla kayıt edildi!'
          });
          this.getOfflineUsers();
        } else {
          if (res.code == 504) {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Bazı kartlar daha önce kayıt edilmiştir. Dosyaını kontrol ediniz ve tekrar yükleyiniz!'
            });
          } else {
            Swal.fire({
              ...this.swalOptions.error,
              text: 'Hata!'
            });
          }
        }
      }, error => {

        this.loadingSpinner.hide();
        if (error.message.code === 11000 && error.status === 500) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
              error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'

          });
        } else if (error.status === 400) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz Excel dosyasının ' + error.index + '. satırı yapıya uygun değil.'
          });
        } else if (error.status === 501) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz Excel dosyasının ' + error.index + '. satırında kart numaraları (HEX ve DEC) uyuşmuyor!.'
          });
        } else if (error.status === 502) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart numarası (HEX) hatalı!: satır ' + error.index
          });
        } else if (error.status === 503) {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kart numarası (DEC) hatalı!: satır ' + error.index
          });
        } else {
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kişiler veritabanına eklenirken hata oluştu!'
          });
        }
      });
    } else {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'İlk olarak geçerli bir dosya seçmelisiniz!'
      });
    }
  }

  setOfflineUserGroupId() {
    this.offlineUser = new OfflineUser();
    this.offlineUser.offlineUserGroups = [this.groupData.id];
  }

  isFieldsOkay(): boolean {
    let isOkay = true;
    if (this.offlineUser.name === '') {
      isOkay = false;
    }
    if (this.offlineUser.sicilNo === '') {
      isOkay = false;
    }
    if (this.offlineUser.gorev === '') {
      isOkay = false;
    }
    if (this.offlineUser.birim === '') {
      isOkay = false;
    }
    if (this.offlineUser.cardId === '') {
      isOkay = false;
    }
    if (this.offlineUser.firmBalance === null) {
      isOkay = false;
    }
    if (isOkay) {
      return this.hexDecControls(this.offlineUser.cardId, this.offlineUser.cardIdDec);
    } else {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Lütfen boş alan bırakmayınız!'
      });
      return false;
    }
  }

  createOfflineUser() {
    if (this.isFieldsOkay()) {
      this.loadingSpinner.show();
      this.offlineUserGroupData.forEach((data, i) => {
        if (this.groupData.id === data._id) {
          this.offlineUser.automatGroup = data.automatGroup;
        }
      });
      this.offlineUser.cardId = this.offlineUser.cardId.toLowerCase();
      this.cashlessService.createOfflineUser(this.offlineUser).subscribe(res => {
        this.modalService.dismissAll();
        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.success,
          text: 'Kişi başarıyla kayıt edildi!'
        });
        this.getOfflineUsers();
      }, error => {

        if (error.message.code === 11000) {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Eklemek istediğiniz ' + error.message.op.name + ' kullanıcısının ' +
              error.message.op.cardId + ' kart numarası başka bir kullanıcı tarafından kullanılıyor.'
          });
        } else {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'Kişi veritabanına kaydedilirken hata oluştu!'
          });
        }
      });
    }
  }





  getXLSXData() {
    // this.setCustomDate();
    const tableData = [];
    /* this.selectedOfflineUserTransactions.forEach(data => {
      const date = new Date(this.getTranactionDate(data));
      // noinspection JSNonASCIINames
      const temp = {
        'Kart Sahibi': data.name,
        'İşlem Tarihi': date.toLocaleDateString(),
        'Otomat': this.isEmpty(data.automat) || data.automat === undefined ? '' : data.automat.name,
        'Spiral Numarası': data.spiralNumber,
        'Ürün Adı': this.isEmpty(data.automatProduct) || data.automatProduct === undefined ||
        this.isEmpty(data.automatProduct.product) ? '' : data.automatProduct.product.name,
        'İşlem Türü': this.getTransactionProcessTypeText(data.processType),
        'Miktar': this.getTransactionAmountText(data.processType, data.total),
        'Güncel Bakiye': this.round(data.firmBalance, 2),
        'Para Birimi': 'Türk Lirası'
      };
      tableData.push(temp);
    }); */
    return tableData;

  }

  sendEmailOfflineUserTranscation(){

  }

  downloadExcel() {
    if (this.offlineUsers.length === 0) {
      Swal.fire({
        ...this.swalOptions.info,
        text: 'Tablo içerisnde veri yok!'
      });
      return;
    } else {
        this.exportsHelper.offlineCardGroupDownloadExcel(this.offlineUsers);
    }
  }




  clearExcelEmailAddress() {
    this.excelEmailAddress = '';
    this.emailValid = true;
    const modalRef = this.modalService.open(this.mailModal, {centered: true, size: 'xl', scrollable: true});

  }

  openAddCardModal() {
    const modalRef = this.modalService.open(this.addCardModal, {centered: true, size: 'xl', scrollable: true});

  }

  isEmail(email: string): boolean {
    this.regexp = new RegExp(/^(\s?[^\s;]+@[^\s;]+\.[^\s;]+\s?;)*(\s?[^\s;]+@[^\s;]+\.[^\s;]+)$/);

    this.emailValid = this.regexp.test(email);
    return this.emailValid;
  }



  InputIdChange(event: string, isHex) {
    if (isHex) {
      const id = parseInt(event, 16).toString().toLowerCase();
      this.offlineUser.cardIdDec = id === 'nan' ? '' : id;
    } else {
      const id = parseInt(event).toString(16).toLowerCase();
      this.offlineUser.cardId = id === 'nan' ? '' : id;
    }

  }


  hexDecControls(hex: string, dec: string): boolean {
    if (!hex.match(/^([a-fA-F0-9]){1,8}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '16 tabanlı uid en fazla 8 haneli olabilir, ve 0-9 a-f değerleri alabilir'
      });
      return false;
    }
    if (!dec.match(/^([0-9]){1,10}$/ig)) {
      Swal.fire({
        ...this.swalOptions.error,
        text: '10 tabanlı uid en fazla 10 haneli olabilir ve harf içeremez'
      });
      return false;
    }

    return true;
  }


  validatePrice(event: KeyboardEvent) {
    return event.code === 'Delete' || event.code === 'Backspace' || event.code === 'Tab' || event.code === 'NumpadDecimal' || event.code === 'Backslash' || event.code === 'Comma' || event.code === 'Slash' || event.code === 'Period' ? true : !isNaN(Number(event.key));
  }
}
