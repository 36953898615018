<div class="container-fluid top-bg pt-4 pb-3 top-container">
  <div class="row">
    <div class="col-md-1 col-lg-1 col-xl-1 align-self-center">
      <span class="text-gray" style="font-size:14px; font-weight: bold">Kart Grubu:</span> <br/>
      <span class="group-title text-gray">{{ offlineUserGroupData?.name}}</span> <br/>

      <span class="text-gray" style="font-size:14px; font-weight: bold">Period:</span> <br/>
      <span
        class="group-title text-gray">{{ (periodStartDate | date : 'yyyy-MM-dd') + ' - ' + (periodEndDate | date : 'yyyy-MM-dd') }}</span>
    </div>
    <div class="col-md-10 col-lg-10 col-xl-10 text-center">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-orange">
                    <img src="../../assets/img/money-refund.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left">
                  <div class="text-s-15 text-gray mb-1 mt-1">Toplam Devreden Bakiye</div>
                  <div
                    class="font-weight-bold text-amount text-orange">{{ (totalBalanceBroughtForward) | number | commaDotSwapper}}
                    ₺
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-blue">
                    <img src="../../assets/img/money.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left">
                  <div class="text-s-15 text-gray mb-1 mt-1">Toplam Yüklenen Miktar</div>
                  <div class="font-weight-bold text-amount text-blue">{{ totalLoadedAmount | number | commaDotSwapper}}
                    ₺
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-red">
                    <img src="../../assets/img/money-1.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left">
                  <div class="text-s-15 text-gray mb-1 mt-1">Toplam Harcanan Miktar</div>
                  <div class="font-weight-bold text-amount text-red">{{ totalAmountSpent | number | commaDotSwapper}}
                    ₺
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-6 px-0 my-xs-1">
            <div>
              <div class="row">
                <div class="col-md-3">
                  <div class="circle-green">
                    <img src="../../assets/img/money-2.svg" class="money mx-auto">
                  </div>
                </div>
                <div class="col-auto text-left">
                  <div class="text-s-15 text-gray mb-1 mt-1">Kalan Bakiye</div>
                  <div
                    class="font-weight-bold text-amount text-green">{{ (totalLoadedAmount - totalAmountSpent) | number | commaDotSwapper}}
                    ₺
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-1 col-xl-1">
      <div class="container">
        <div class="row justify-content-end text-center">
          <button (click)="downloadExcel()" [hidden]="!isDownloadReady" class="btn btn-download btn-sm btn-block" type="button"><i
            class="fa fa-download"></i> XLS indir
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!hasStartedPeriod">
    <div class="col">
      <span class="text-danger" style="font-size:12px">* Periyot henüz başlamadı!</span>
    </div>
  </div>
</div>
<div class="table-responsive">
  <table class="table" id="main-table">
    <thead>
    <tr>
      <th *ngFor="let tableHeader of tableHeaders" class="table-header">
        {{tableHeader.name}}
        <input (change)="updateColumn($event.target.value)" type="checkbox" value="{{tableHeader.id}}"
               name="{{tableHeader.id}}" [checked]="tableHeader.selected">
      </th>
      <th class="table-header text-center">Kart İşlem Geçmişi</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let offlineUser of offlineUsers; let i = index">
      <td style="text-align: left">{{ offlineUser.name }}</td>
      <td>{{ offlineUser.cardId }}</td>
      <td>{{ offlineUser.cardIdDec }}</td>
      <td
        [ngClass]="offlineUser.status == 0 ? 'state-passive' : 'state-active'">{{ offlineUser.status == 1 ? 'Aktif' : 'Pasif'}}</td>
      <td>{{ offlineUser.sicilNo }}</td>
      <td>{{ offlineUser.gorev }}</td>
      <td>{{ offlineUser.birim }}</td>
      <td>{{ offlineUser.explanation1 }}</td>
      <td>{{ offlineUser.explanation2 }}</td>
      <td>{{ offlineUser.explanation3 }}</td>
      <td>{{ calOfflineUserTransactionData(offlineUser.transaction_data, 6) | number | commaDotSwapper }} ₺</td>
      <td>{{ calOfflineUserTransactionData(offlineUser.transaction_data, 10) | number | commaDotSwapper }} ₺</td>
      <td>{{ calOfflineUserTransactionData(offlineUser.transaction_data, 2) | number | commaDotSwapper }} ₺</td>
      <td>{{ offlineUser.firmBalance | number | commaDotSwapper }} ₺</td>
      <td class="text-center">
        <button class="btn-detail" (click)="showUserTransactionModal(offlineUser, i)">İncele</button>
      </td>
    </tr>
    </tbody>

  </table>
</div>

<!-- Offline User Transaction -->
<button style="display: none;" #showOfflineUserTransaction data-toggle="modal" data-backdrop="true"
        data-target="#modalOfflineUserTransaction"></button>
<div class="modal fade" id="modalOfflineUserTransaction" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <div class="row justify-content-end">
            <button #closeOfflineUserTransaction type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row m-title justify-content-center">
            İşlem Geçmişi
          </div>
          <div class="container">
            <div class="row justify-content-end">
              <span class="mr-2 align-self-center"
                    [ngClass]="selectedOfflineUser?.status == 0 ? 'state-passive' : 'state-active'">
                {{ selectedOfflineUser?.status == 1 ? 'Aktif' : 'Pasif' }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <span class="h5">{{ selectedOfflineUser?.name }}</span><br>
              <span class="font-weight-bold">{{ selectedOfflineUser?.cardId }}
                - {{ selectedOfflineUser?.cardIdDec }}</span>
            </div>
            <div class="col text-right">
              Bakiye<br>
              <span class="h4">
                {{ selectedOfflineUser?.balance + selectedOfflineUser?.firmBalance | number | commaDotSwapper }} ₺
              </span>
            </div>
          </div>
          <div class="row my-2" style="border: 1px solid dimgray;"></div>
          <div class="tab-pane">
            <div class="container">
              <div class="row">
                <div class="col-8">

                  <form class="form-inline">
                    <div class="form-group hidden">
                      <div class="input-group">
                        <input name="datepicker"
                               class="form-control"
                               ngbDatepicker
                               #datepicker="ngbDatepicker"
                               [autoClose]="'outside'"
                               (dateSelect)="onDateSelection($event)"
                               [displayMonths]="2"
                               [dayTemplate]="t"
                               outsideDays="hidden"
                               [startDate]="fromDate!">
                        <ng-template #t let-date let-focused="focused">
                        <span class="custom-day"
                              [class.focused]="focused"
                              [class.range]="isRange(date)"
                              [class.faded]="isHovered(date) || isInside(date)"
                              (mouseenter)="hoveredDate = date"
                              (mouseleave)="hoveredDate = null">
                          {{ date.day }}
                        </span>
                        </ng-template>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input #dpFromDate
                               class="form-control" placeholder="yyyy-mm-dd"
                               name="dpFromDate"
                               [value]="formatter.format(fromDate)"
                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">Tarih
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group ml-2">
                      <div class="input-group">
                        <input #dpToDate
                               class="form-control" placeholder="yyyy-mm-dd"
                               name="dpToDate"
                               [value]="formatter.format(toDate)"
                               (input)="toDate = validateInput(toDate, dpToDate.value)">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()"
                                  type="button">Tarih
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
                <div class="col align-self-end">
                </div>
              </div>
            </div>
            <div *ngIf="selectedOfflineUserTransactions.length != 0" class="table-responsive mt-2 table-scrollbar">
              <div class="container">
                <div class="row" style="color:#aaa">
                  <div class="col">Kart Sahibi</div>
                  <div class="col-2">İşlem Tarihi</div>
                  <div class="col">Otomat</div>
                  <div class="col text-center">Spiral No</div>
                  <div class="col">Ürün</div>
                  <div class="col text-center">Ürün Resmi</div>
                  <div class="col">İşlem Türü</div>
                  <div class="col">Miktar</div>
                  <div class="col">Bakiye</div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedOfflineUserTransactions.length != 0"
                 class="table-responsive mt-2 table-scrollbar transaction-list"
                 style="height: 200px; overflow-y: scroll" infiniteScroll
                 [infiniteScrollDistance]="pages"
                 [infiniteScrollThrottle]="20"
                 [fromRoot]="true"
                 [infiniteScrollContainer]="'.transaction-list'"
                 (scrolled)="onScroll()">
              <div class="container" *ngFor="let transaction of selectedOfflineUserTransactions; let i = index">
                <div class="row d-body">
                  <div class="col">{{ transaction.name}}</div>
                  <div class="col-2">{{ getTransactionDate(transaction) | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                  <div class="col">{{ transaction.automat?.name }}</div>
                  <div class="col text-center">{{ transaction.spiralNumber }}</div>
                  <div
                    class="col">{{ isEmpty(transaction.automatProduct) || transaction.automatProduct?.product === null ? '' : transaction.automatProduct?.product?.name}}</div>
                  <div class="col text-center">
                    <img
                      *ngIf="isEmpty(transaction.automatProduct) || isEmpty(transaction.automatProduct?.product) || transaction.automatProduct?.product?.image === ''; else imageElse"
                      class="img-responsive" src="../../assets/img/gorsel_yok.svg" height="30">
                    <ng-template #imageElse>
                      <img class="img-responsive"
                           src="{{transaction.automatProduct.product.image | image: ProductImagePath }}" height="30">
                    </ng-template>
                  </div>
                  <div *ngIf="transaction.processType == 0" class="col">İade</div>
                  <div *ngIf="transaction.processType == 1" class="col">Manuel Yükleme</div>
                  <div *ngIf="transaction.processType == 2" class="col text-warning">Harcama</div>
                  <div *ngIf="transaction.processType == 3" class="col text-danger">Sıfırlama</div>
                  <div *ngIf="transaction.processType == 4" class="col">Toplu Yükleme</div>
                  <div *ngIf="transaction.processType == 5" class="col text-danger">Toplu Sıfırlama</div>
                  <div *ngIf="transaction.processType == 6" class="col">Periyodik Yükleme</div>
                  <div *ngIf="transaction.processType == 7" class="col">Periyodik Sıfırlama</div>
                  <div *ngIf="transaction.processType == 10" class="col text-warning">Devreden</div>
                  <div *ngIf="transaction.processType == 11" class="col text-danger">Düşüm</div>
                  <div *ngIf="transaction.processType == 12" class="col">Revalue</div>
                  <div *ngIf="transaction.processType == 0" class="col state-active">+{{ round(transaction.total, 2)  }}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 1" class="col state-active">+{{ round(transaction.total, 2)  }}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 2" class="col text-warning">-{{ round(transaction.total, 2)  }}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 3" class="col text-danger">Sıfırlama</div>
                  <div *ngIf="transaction.processType == 4" class="col state-active">+{{ round(transaction.total, 2)  }}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 5" class="col text-danger">Sıfırlama</div>
                  <div *ngIf="transaction.processType == 6" class="col state-active">+{{ round(transaction.total, 2) }}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 7" class="col text-danger">Sıfırlama</div>
                  <div *ngIf="transaction.processType == 10" class="col text-warning">+{{round(transaction.total, 2)}}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 11" class="col text-danger"> {{round(transaction.total, 2)}}
                    ₺
                  </div>
                  <div *ngIf="transaction.processType == 12" class="col state-active">+{{ round(transaction.total, 2) }}
                    ₺
                  </div>
                  <div class="col">{{ round(transaction.firmBalance, 2) }} ₺</div>
                </div>
              </div>

            </div>
            <!-- Boş liste uyarı -->
            <div *ngIf="selectedOfflineUserTransactions.length == 0" class="row justify-content-center">
              <h1 class="h5 my-5" style="color: gray">Kayıtlı kart hareketi yok!</h1>
            </div>
            <!-- Boş liste uyarı : SON -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType
>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
