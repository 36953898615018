import {Injectable} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import {RoundedRectangle} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {Chart, GaugeChart, PieChart, ValueAxisDataItem, XYChart} from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_tr_TR from '@amcharts/amcharts4/lang/tr_TR';
import {ThemeingHelper} from './themeing.helper';

@Injectable({
  providedIn: 'root'
})
export class ChartHelper {

  chartColors: Array<am4core.Color>;

  constructor(
    private themeingHelper: ThemeingHelper
  ) {
    am4core.useTheme(am4themes_animated);
    am4core.options.defaultLocale = am4lang_tr_TR;
    am4core.options.autoDispose = true;

    this.chartColors = themeingHelper.getAllChartColors();
  }

  newData(chart: any, data: Array<any>) {
    chart.data = data;
    if (chart instanceof XYChart || chart instanceof PieChart) {
      chart.appear();
      chart.series.each(function (series) {
        series.appear();
      });
    }

  }


  /*Detail Info*/
  newSingleColumnChart(element: HTMLElement | string, source: Array<any>,
                       serieName: string, serieDataField: string, numberFormat: string): XYChart {
    const chart = am4core.create(element, am4charts.XYChart);
    chart.data = source;
    chart.colors.list = [this.chartColors[6]];
    chart.dateFormatter.dateFormat = 'd MMMM';

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.dateFormats.setKey('day', 'd MMMM');
    dateAxis.periodChangeDateFormats.setKey('day', '[bold]d MMMM');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = numberFormat;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = serieName;
    series.dataFields.valueY = serieDataField;
    series.dataFields.dateX = 'date';
    series.columns.template.tooltipText = '[bold]{dateX}[/]\n[font-size:14px]{name}: {valueY} ';
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color('#FFFFFF');

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    return chart;
  }

  /*Detail Info END*/

  /*SALES*/
  newSalesStackedChart(element: HTMLElement | string, source: Array<any>): XYChart {
    const chart = am4core.create(element, am4charts.XYChart);
    chart.data = source;
    chart.colors.list = this.chartColors;
    chart.paddingTop = 100;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.dataFields.category = 'time';

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = '#,###₺';

    const series1 = this.createStackedColumnSeries('volumeOfCashTransaction', 'countOfCashTransaction', 'time', 'Nakit', chart);
    const series2 =
      this.createStackedColumnSeries('volumeOfOnlineCardTransaction', 'countOfOnlineCardTransaction', 'time', 'Kredi Kartı', chart);
    const series3 =
      this.createStackedColumnSeries('volumeOfOfflineCardTransaction', 'countOfOfflineCardTransaction', 'time', 'Ön Ödemeli Kart', chart);
    const series4 =
      this.createStackedColumnSeries('volumeOfQrTransaction', 'countOfQrTransaction', 'time', 'Qr Ödeme', chart);
    const series5 =
      this.createStackedColumnSeries('volumeOfMetropolCardQrTransaction', 'countOfMetropolCardQrTransaction', 'time', 'Metropol Card Qr', chart);

    const seriesT = this.totalXYSeries('volumeOfTotalTransaction', 'time', 'Toplam', chart);
    this.countTotalSeries('countOfTotalTransaction', 'time', 'Adet', chart);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;

    this.customSalesLegend(chart);

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(seriesT);
    chart.scrollbarX = scrollbarX;
    chart.scrollbarX.parent = chart.bottomAxesContainer;
    return chart;
  }

  newPaymentTypePieChart(element: HTMLElement | string, source: Array<any>): PieChart {
    const chart = am4core.create(element, am4charts.PieChart);
    chart.data = source;
    chart.colors.list = this.chartColors;
    chart.innerRadius = am4core.percent(45);
    chart.hiddenState.properties.innerRadius = am4core.percent(0);
    chart.hiddenState.properties.radius = am4core.percent(100);
    chart.tooltip.autoTextColor = false;
    chart.tooltip.label.fill = am4core.color('#FFFFFF');
    chart.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';

    const series = this.createPieSeries('volumeOfTotalTransaction', 'paymentMethodName', chart);

    /*-------------*/
    const legend = new am4charts.Legend();
    chart.legend = legend;
    legend.tooltip.disabled = true;

    chart.events.on('datavalidated', (event) => {
      const legendContainer = am4core.create('chartlegend', am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      legend.parent = legendContainer;
      this.legendRefresh(chart, series, legend);
    });

    chart.events.on('beforedisposed', () => {
      if (chart.legend) {
        chart.legend.dispose();
      }
    });


    /*-------------*/
    return chart;

  }

  newProductSalesPieChart(element: HTMLElement | string, source: Array<any>): PieChart {

    const chart = am4core.create(element, am4charts.PieChart);
    chart.data = source;
    chart.innerRadius = am4core.percent(40);
    chart.tooltipText = '[bold]{name}[/]\n[font-size:14px]{valueY}';
    this.createPieSeries('value', 'title', chart);

    chart.legend = new am4charts.Legend();
    chart.legend.tooltip.disabled = true;
    chart.legend.position = 'right';
    return chart;
  }

  /*SALES END*/

  /*Dashboard*/
  newStockGaugeChart(element: HTMLElement | string, value: number): GaugeChart {
    const chart = am4core.create(element, am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(60);
    chart.radius = am4core.percent(80);
    /*  chart.startAngle = -180;
      chart.endAngle = 0;*/

    const axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.labels.template.disabled = true;
    axis.renderer.grid.template.disabled = true;

    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 0.5;
    axis.renderer.ticks.template.strokeWidth = 2;
    axis.renderer.ticks.template.stroke = am4core.color('#D4D7DD');

    const range0 = this.newGaugeRange(0, 50, this.chartColors[6], axis);
    const range1 = this.newGaugeRange(50, 100, am4core.color('#D4D7DD'), axis);

    const label = chart.chartContainer.createChild(am4core.Label);
    label.fontSize = 20;
    label.isMeasured = false;
    label.x = am4core.percent(50);
    label.horizontalCenter = 'middle';
    label.y = 0;

    label.text = '50%';


    const hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis;
    hand.fill = am4core.color('#7BA3CD');
    hand.pin.fill = am4core.color('#FFFFFF');
    hand.stroke = am4core.color('#7BA3CD');
    hand.pin.stroke = am4core.color('#7BA3CD');

    hand.startWidth = 34;
    hand.radius = am4core.percent(50);
    hand.innerRadius = 4;
    hand.pin.radius = 12;
    hand.pin.strokeWidth = 12;
    hand.pin.strokeOpacity = 1;
    hand.value = 0;
    hand.pin.zIndex = 2;

    hand.events.on('propertychanged', function (ev) {
      range0.endValue = ev.target.value;
      range1.value = ev.target.value;
      label.text = axis.positionToValue(hand.currentPosition).toFixed(1) + '%';
      axis.invalidate();
    });

    chart.events.on('ready', function () {
      const animation = new am4core.Animation(hand, {
        property: 'value',
        to: value
      }, 2000, am4core.ease.cubicInOut).start();
    });


    return chart;
  }


  newDashSingleColumnChart(element: HTMLElement | string, source: Array<any>,
                           serieName: string, serieDataField: string): XYChart {
    const chart = am4core.create(element, am4charts.XYChart);
    chart.data = source;

    chart.align = 'left';
    chart.colors.list = [this.chartColors[6]];
    chart.dateFormatter.dateFormat = 'd MMMM';

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.dateFormats.setKey('day', 'd MMMM');
    dateAxis.periodChangeDateFormats.setKey('day', '[bold]d MMMM');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.visible = false;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = serieName;
    series.dataFields.valueY = serieDataField;
    series.dataFields.dateX = 'date';
    series.tooltipText = '[bold]{dateX}[/]\n[font-size:14px]{name}: {valueY} ';
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color('#FFFFFF');

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    return chart;
  }

  /*Dashboard END*/

  /*XY-Column*/
  private createStackedColumnSeries(valueField: string, countField: string, categoryField: string, name: string, chart) {

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.name = name;
    series.dataFields.valueY = valueField;
    series.dataFields.categoryX = categoryField;
    series.sequencedInterpolation = true;
    series.stacked = true;

    series.columns.template.width = am4core.percent(70);
    series.tooltip.pointerOrientation = 'up';
    series.tooltipText = '[bold]{name}[/]\n[font-size:14px]Ciro: {valueY}₺\nAdet: {' + countField + '}';
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color('#FFFFFF');
    series.legendSettings.labelText = '{name}';
    series.legendSettings.valueText = '{valueY.sum}₺';
    series.legendSettings.itemValueText = '{valueY}₺';
    return series;
  }

  private totalXYSeries(valueField, categoryField, name, chart) {
    const series = chart.series.push(new am4charts.XYSeries());
    series.name = name;
    series.fill = am4core.color(this.chartColors[6]);
    series.dataFields.valueY = valueField;
    series.dataFields.categoryX = categoryField;
    series.strokeOpacity = 0;
    series.legendSettings.labelText = '{name}';
    series.legendSettings.valueText = '{valueY.sum}₺';
    series.legendSettings.itemValueText = '{valueY}₺';
    return series;

  }

  private countTotalSeries(countField, categoryField, name, chart) {

    const series = chart.series.push(new am4charts.LineSeries());
    series.name = name;
    /*series.stroke = am4core.color('rgb(0,0,0)');
    series.fill = this.chartColors[6];*/
    series.dataFields.valueY = countField;
    series.dataFields.categoryX = categoryField;
    series.strokeOpacity = 0.5;

    series.tooltip.disabled = true;

    series.legendSettings.labelText = '{name}';
    series.legendSettings.valueText = '{valueY.sum} Adet';
    series.legendSettings.itemValueText = '{valueY} Adet';
    series.tensionX = 0.8;
    return series;

  }

  /*XY-Column END*/

  /*Pie*/
  private createPieSeries(valueField: string, categoryField: string, chart: PieChart) {
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = valueField;
    series.dataFields.category = categoryField;
    series.colors.list = this.chartColors;
    series.ticks.template.disabled = true;
    series.labels.template.disabled = true;
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color('#FFFFFF');
    series.visible = false;

    return series;
  }

  /*Pie END*/


  /*Gauge*/
  private newGaugeRange(startVal: number, endVal: number, color: am4core.Color, chartAxis: am4charts.ValueAxis): ValueAxisDataItem {
    const range = chartAxis.axisRanges.create();
    range.value = startVal;
    range.endValue = endVal;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = color;

    return range;
  }

  /*Gauge END*/

  /*Legend*/
  private customSalesLegend(chart: am4charts.Chart) {
    const legend = new am4charts.Legend();
    chart.legend = legend;
    legend.useDefaultMarker = true;
    const marker = <RoundedRectangle>legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);

    return legend;
  }

  private legendRefresh(chart, series, legend) {
    legend.htmlContainer.innerHTML = '';

    function toggleSlice(item) {
      const slice = series.dataItems.getIndex(item);
      slice.visible ? slice.hide() : slice.show();

    }

    function hoverSlice(item) {
      const slice = series.slices.getIndex(item);
      slice.isHover = true;
    }

    function blurSlice(item) {
      const slice = series.slices.getIndex(item);
      slice.isHover = false;
    }

    series.dataItems.each(function (row, i) {
      const color = chart.colors.getIndex(i);
      const percent = Math.round(row.values.value.percent * 10) / 10;
      const category = row.category;
      const newChild = document.createElement('DIV');
      newChild.id = 'legend-item-' + i;
      newChild.classList.value = 'legend-item row';
      newChild.style.cursor = 'pointer';

      newChild.innerHTML =
        '<div class="legend-marker my-auto" ' +
        'style="border: 6px solid ' + color + ';width: 30px;height: 30px;border-radius: 50%;"></div>' +
        ' <div class="col">' +
        ' <div class="row">' +
        ' <span class="col" style="font-size: 11px;color: #7B7B7B"' +
        '">' + category + '</span>' +
        ' <div class="legend-value col-12" style="font-size: 22px;font-weight:600;color: #7B7B7B"' +
        '">' + percent + '%</div>' +
        ' </div>' +
        '</div>';

      legend.htmlContainer.appendChild(newChild);
      newChild.addEventListener('click', () => {
        newChild.classList.toggle('disabled');
        toggleSlice(i);
      });
      newChild.addEventListener('mouseenter', () => hoverSlice(i));
      newChild.addEventListener('mouseleave', () => blurSlice(i));


    });

  }

  /*Legend END*/
}
