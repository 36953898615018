
<div class="container mt-5 animated fadeIn" style="overflow-x: auto">
  <div class="row header-bottom-line font-weight-bold">
    <div class="col-2">
      Dönem {{text}}
    </div>
    <div class="col-3">
      TELEMETRY Bakiyesi
    </div>
    <div class="col-3">
      Iyzico Bakiyesi
    </div>
    <div class="col-4 text-right font-weight-normal">
      <span class="time-title noselect ml-0"
            [ngClass]="currentTimeZone == 0 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 0">
        Tümü
      </span>
      <span class="time-title noselect"
            [ngClass]="currentTimeZone == 1 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 1">
        Yıllık
      </span>
      <span class="time-title noselect"
            [ngClass]="currentTimeZone == 2 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 2">
        Aylık
      </span>
      <span class="time-title noselect mr-0"
            [ngClass]="currentTimeZone == 3 ? 'active-timezone' : ''"
            (click)="currentTimeZone = 3">
        Haftalık
      </span>
    </div>
  </div>

  <div class="row item">
    <div class="col-2 align-self-center">
      <p class="m-0">01.05.2018</p>
      <p class="m-0">01.08.2018</p>
    </div>
    <div class="col-3">
      <p class="m-0"><strong>2550 ₺</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-3">
      <p class="m-0"><strong>2550 ₺</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-2 align-self-center text-center">
      <span>Aktif İşlem</span>
    </div>
    <div class="col-2 align-self-center text-center">
      <img src="../../assets/img/islemde.svg" width="42" height="42">
      <p class="m-0">İŞLEMDE</p>
    </div>
  </div>

  <div class="row item">
    <div class="col-2 align-self-center">
      <p class="m-0">01.05.2018</p>
      <p class="m-0">01.08.2018</p>
    </div>
    <div class="col-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-3">
      <p class="m-0"><strong>2550 TL</strong></p>
      <p class="m-0">İşlem Tarihi: 12.08.2018</p>
      <p class="m-0">Ödeme Tarihi: 15.08.2018</p>
    </div>
    <div class="col-2 align-self-center text-center">
      <span style="display: none">Aktif İşlem</span>
    </div>
    <div class="col-2 align-self-center text-center">
      <img src="../../assets/img/bigcheck.svg" width="42" height="42">
      <p class="m-0" style="color: #2fb43f">TAMAMLANDI</p>
    </div>
  </div>

</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->


