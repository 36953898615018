<div class="col-md-12 h-100">
  <div class="container" *ngIf="users && users.length else dataLoading">
    <div *ngxPermissionsOnly="['users-edit']" class="row justify-content-end">
      <button *ngxPermissionsOnly="['card-packets-edit']"
              class="btn btn-success newbtn mr-2 my-3" (click)="newUser()">
        <span class="fa fa-plus mr-2"></span> {{'USERS.CREATE_NEW_USERS' | translate}}
      </button>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12 p-0">
        <div *ngFor="let user of users, index as ind, even as iseven">
          <div *ngIf="iseven" class=" p-1 fade-in-animation">
            <app-user-card [firmuser]="{user:user,index:ind}" (changeUser)="editUser(user)"></app-user-card>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 p-0">
        <div *ngFor="let user of users, index as ind,odd as isodd">
          <div *ngIf="isodd" class=" p-1 fade-in-animation">
            <app-user-card [firmuser]="{user:user,index:ind}" (changeUser)="editUser(user)"></app-user-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #dataLoading>
  <div class="col border container-height d-flex flex-column" style="height: 100vh">
    <div class="m-auto">
      <div class="loader fadeIn" style="font-size: 60%"></div>
    </div>
  </div>
</ng-template>
