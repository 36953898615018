export class Cashless {
  _id: string;
  featureLevel: number;
  countryCode: number;
  scaleFactor: number;
  decimalPlaces: number;
  maxRespTime: number;
  miscOptions: number;
  automat: string;

  constructor() {
    this.featureLevel = 1;
    this.countryCode = 90;
    this.scaleFactor = 1;
    this.decimalPlaces = 2;
    this.maxRespTime = 59;
    this.miscOptions = 153;
  }
}
