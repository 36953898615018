<div class="container mt-5">
  <div class="row justify-content-end">
    <a>
      <div class="btn-istek-olustur justify-content-center" data-toggle="modal" data-backdrop="true" data-target="#istekOlustur">
        {{'SYSTEM_MESSAGES.CREATE_REQUEST' | translate}}
      </div>
    </a>
  </div>
</div>

<div class="container my-5">
  <div
    infiniteScroll
    [infiniteScrollDistance]="pages"
    [infiniteScrollThrottle]="20"
    (scrolled)="onScroll()">
    <hr>

    <div
      *ngFor="let ticket of tickets; let i = index"
      class="row">
      <div class="container">
        <div class="row">
          <div class="col-sm col-md-1 text-center align-self-center p-1">
            <img *ngIf="ticket.user.photo === ''" class="rounded-circle" src="../../assets/img/user_image.png" width="50">
            <img *ngIf="ticket.user.photo !== ''" class="rounded-circle" src="{{ticket.user.photo | image: ProfileImagePath}}" width="50">
          </div>
          <div class="col-sm align-self-center p-1">
            <strong>{{ticket.user.name}}</strong>
          </div>
          <div class="col-sm align-self-center p-1">
            <p class="baslik">{{ticket.subject.name}}</p>
            <p class="tarih">{{ticket.updatedAt | date: 'MM.dd.yyyy HH:mm'}}</p>
          </div>
          <div class="col-sm-4 align-self-center p-1">
          <span class="aciklama">
            {{ticket.note}}
          </span>
          </div>
          <div class="col-6 col-sm-1 text-right align-self-center">
            <a>
              <img class="rounded-circle" src="../../assets/img/destek-yanitla.svg" width="25">
            </a>
          </div>
          <div class="col-6 col-sm-1 align-self-center">
            <a>
              <img class="rounded-circle" src="../../assets/img/destek-sil.svg" (click)="deletePrompt(ticket._id, i)" width="25">
            </a>
          </div>
        </div>
        <hr>
      </div>
    </div>

  </div>
  <!-- Boş liste uyarı -->
  <div *ngIf="tickets.length == 0" class="row justify-content-center">
    <h1 class="h3 mt-5" style="color: gray">{{'SYSTEM_MESSAGES.NO_REQUESTS' | translate}}!</h1>
  </div>
  <!-- Boş liste uyarı : SON -->
</div>

<!-- Yeni istek oluştur -->
<div class="modal fade" id="istekOlustur" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="container">
          <div class="row modal-baslik justify-content-center">
            {{'SYSTEM_MESSAGES.CREATE_NEW_REQUEST' | translate}}
          </div>
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <select [(ngModel)]="ticket.subject._id" class="custom-select select-product select-custom-icon mb-2">
                <option value="">{{'SYSTEM_MESSAGES.PLEASE_SELECT_SUBJECT' | translate}}</option>
                <option
                  *ngFor="let subject of subjects; let i = index"
                  value="{{ subject._id }}">{{subject.name}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <textarea [(ngModel)]="ticket.note" #note="ngModel" name="note" class="metin" placeholder="{{'SYSTEM_MESSAGES.HOW_CAN_WE_HELP_YOU' | translate}}"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 offset-md-1 text-right">
              <button class="btn btn-primary modal-buton" (click)="addTicket()">{{'SYSTEM_MESSAGES.SEND' | translate}}</button>
              <button class="btn btn-primary modal-buton" data-dismiss="modal" #closeAddTicket>{{'SYSTEM_MESSAGES.CANCEL' | translate}}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- Yükleme spinner -->
<ngx-spinner
  [bdColor]=loadingSpinnerBdColor
  [size]=loadingSpinnerSize
  [color]=loadingSpinnerColor
  [type]=loadingSpinnerType

>
</ngx-spinner>
<!-- Yükleme spinner : SON -->
