import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmUserService {

  constructor(private http: HttpClient) {
  }


  getUser(firmUserId: string): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'get-telemetry-user/' + firmUserId);
  }

  getFirmUsers(): Observable<any> {
    return this.http
      .get(environment.apiBaseUrl + 'get-firm-telemetry-user');
  }

  addFirmUser(firmUser): Observable<any> {
    firmUser._id = undefined;
    return this.http
      .post(environment.apiBaseUrl + 'create-firm-user-from-telemetry', JSON.stringify(firmUser));
  }

  deleteFirmUser(id): Observable<any> {
    /*eslint-disable */
    return this.http
      .post(environment.apiBaseUrl + 'delete-firm-user-from-telemetry', {firmUserID: id});
    /*eslint-enable */
  }

  updateFirmUser(firmUser, id) {
    return this.http
      .post(environment.apiBaseUrl + 'update-firm-user-from-telemetry/' + id, {firmUser: firmUser});
  }

}
