import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private router: Router,
              private http: HttpClient) {
  }

  addStaffImage(formData, id): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'staff-image/' + id, formData).pipe(
      );
  }

  addAutomatImage(formData, id): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'automat-image/' + id, formData).pipe(
      );
  }

  addUserImage(formData, id): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'profile-image/' + id, formData).pipe(
      );
  }

  addFirmImage(formData, id): Observable<any> {
    return this.http
      .post(environment.apiBaseUrl + 'firm-image/' + id, formData).pipe(
      );
  }

  productImageUpload(formData, id): Observable<any> {
    return this.http.post(environment.apiBaseUrl + 'product-image/' + id, formData).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error)));
  }

}
