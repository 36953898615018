import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwalHelper } from '../_helpers/swal.helper';
import Swal from 'sweetalert2';
import { TransactionStatementService } from '../_services/transaction-statement.service';
import { AuthenticationService } from '../_services/authentication.service';
import { PaymentSystemsFirm } from '../_models/paymentSystemsTypes';
import { PaymentService } from '../_services/payment.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-transaction-statement',
  templateUrl: './transaction-statement.component.html',
  styleUrls: ['./transaction-statement.component.scss']
})
export class TransactionStatementComponent implements OnInit {
  swalOptions = new SwalHelper();
  paymentSystemsFirms: PaymentSystemsFirm[] = [];
  inquireForm: FormGroup;
  paymentProductInfo: PaymentProductInfo = new PaymentProductInfo();
  paymentParameters: PaymentParameters = new PaymentParameters();
  paymentAutomatInfo: PaymentAutomatInfo = new PaymentAutomatInfo();
  paymentTransactionInfoIyzico: PaymentTransactionInfoIyzico = new PaymentTransactionInfoIyzico();
  paymentTransactionInfoParatika: PaymentTransactionInfoParatika = new PaymentTransactionInfoParatika();
  paymentTransactionInfoZiraat: PaymentTransactionInfoZiraat = new PaymentTransactionInfoZiraat();
  paymentTransactionInfoPaynet: PaymentTransactionInfoPaynet = new PaymentTransactionInfoPaynet();
  paymentTransactionInfoVerifone: PaymentTransactionInfoVerifone = new PaymentTransactionInfoVerifone();
  firmid: string;
  formValue: FormValue;
  paymentImagePath = environment.PaymentImagePath;
  automatImagePath = environment.AutomatImagePath;
  productImagePath = environment.ProductImagePath;
  submitted = false;
  options = {
    selectedPaymentType: null,
    marketplace: false,
    operation: 'update'
  };
  loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  loadingSpinnerSize = environment.loadingSpinnerSize;
  loadingSpinnerColor = environment.loadingSpinnerColor;
  loadingSpinnerType = environment.loadingSpinnerType;
  loadingSpinnerText = environment.loadingSpinnerText;
  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private loadingSpinner: NgxSpinnerService,
    private transactionStatementService: TransactionStatementService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.inquireForm = this.formBuilder.group({
      paymentId: ['', [Validators.required]],
      paymentSystemFirm: [null, [Validators.required]],
  });
    this.firmid = this.auth.user$.getValue().firm._id;
    this.load();
  }

  onSelectPaymentSystemUpdate(){
    this.selectPaymentSystemsFirm(this.inquireForm.value.paymentSystemFirm);
  }
  onSelectPaymentSystemClose(){
    console.log('close');
  }

  // convenience getter for easy access to form fields
  get f() { return this.inquireForm.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.inquireForm.invalid) {
      return;
    }
    this.getPaymentParameters(this.inquireForm.value.paymentId);
    this.getPaymentProductInfo(this.inquireForm.value.paymentId);
    this.getPaymentAutomatInfo(this.inquireForm.value.paymentId);
    this.getPaymentTransactionInfo(this.inquireForm.value.paymentId);
  }

  onReset() {
    this.submitted = false;
    this.inquireForm.reset();
  }
  selectPaymentSystemsFirm(paymentSystemsFirm: PaymentSystemsFirm) {
        switch (paymentSystemsFirm.code) {
          case 0:
              this.paymentTransactionInfoParatika = null;
              this.paymentTransactionInfoZiraat = null;
              this.paymentTransactionInfoPaynet = null;
              this.options.marketplace = false;
              break;
          case 1:
              this.paymentTransactionInfoIyzico = null;
              this.paymentTransactionInfoZiraat = null;
              this.paymentTransactionInfoPaynet = null;
              this.options.marketplace = false;
              break;
          case 2:
              this.paymentTransactionInfoIyzico = null;
              this.paymentTransactionInfoParatika = null;
              this.paymentTransactionInfoPaynet = null;
              this.options.marketplace = false;
              break;
          case 3:
              this.paymentTransactionInfoIyzico = null;
              this.paymentTransactionInfoZiraat = null;
              this.paymentTransactionInfoParatika = null;
              this.options.marketplace = false;
              break;
          case 4:
            this.options.marketplace = true;
            break;
        }
  }

  getPaymentTransactionInfo(paymentId) {
    this.loadingSpinner.show();
    this.transactionStatementService.getPaymentTransactionInfo(paymentId, this.inquireForm.value.paymentSystemFirm.code).subscribe(res => {
      if (res.success) {
        this.submitted = true;
        switch (this.inquireForm.value.paymentSystemFirm.code) {
          case 0:
            //Iyzico
            this.paymentTransactionInfoIyzico = res.message;
            break;
          case 1:
            //Paratika
            this.paymentTransactionInfoParatika = res.message;
            break;
          case 2:
            //Ziraat
            this.paymentTransactionInfoZiraat = res.message;
            break;
          case 3:
            //Paynet
            this.paymentTransactionInfoPaynet = res.message;
            break;
          case 5:
            //Verifone
            this.paymentTransactionInfoVerifone = res.message;
            break;

          default:
            break;
        }
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      if (error.code === 505) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu Ödeme numarasında bir kayıt bulunmamaktadır!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    });
  }
  getPaymentParameters(paymentId) {
    this.loadingSpinner.show();
    this.transactionStatementService.getPaymentParameters(paymentId, this.inquireForm.value.paymentSystemFirm.code).subscribe(res => {
      if (res.success) {
        this.paymentParameters = res.message;
        this.submitted = true;
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      if (error.code === 505) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu Ödeme numarasında bir kayıt bulunmamaktadır!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    });
  }
  getPaymentAutomatInfo(paymentId) {
    this.loadingSpinner.show();
    this.transactionStatementService.getPaymentAutomatInfo(paymentId, this.inquireForm.value.paymentSystemFirm.code).subscribe(res => {
      if (res.success) {
        this.paymentAutomatInfo = res.message;
        this.submitted = true;
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      if (error.code === 505) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu Ödeme numarasında bir kayıt bulunmamaktadır!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    });
  }
  getPaymentProductInfo(paymentId) {
    this.loadingSpinner.show();
    this.transactionStatementService.getPaymentProductInfo(paymentId, this.inquireForm.value.paymentSystemFirm.code).subscribe(res => {
      if (res.success) {
        this.paymentProductInfo = res.message;
        this.submitted = true;
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      if (error.code === 505) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu Ödeme numarasında bir kayıt bulunmamaktadır!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    });
  }

  getRefundPayment(paymentId) {
    this.loadingSpinner.show();
    this.transactionStatementService.getRefundPayment(paymentId, this.inquireForm.value.paymentSystemFirm.code).subscribe(res => {
      this.loadingSpinner.hide();
      if (res.success) {
        Swal.fire({
          ...this.swalOptions.success,
          text: 'geri ödeme başarıyla yapıldı.'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    }, error => {
      this.loadingSpinner.hide();
      if (error.code === 505) {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Bu Ödeme numarasında bir kayıt bulunmamaktadır!'
        });
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'hata oluştu!'
        });
      }
    });
  }
  openDialog() {
    Swal.fire({
      ...this.swalOptions.question,
      text: 'Geri ödeme istediğine emin misiniz?'
    }).then((result) => {
      if (result.isConfirmed) {
        this.getRefundPayment(this.inquireForm.value.paymentId);
      }
    });
  }
  load() {
    this.loadingSpinner.show();
    this.paymentService.getFirmPaymentMethods().subscribe(response => {
      if (response.success) {
        this.paymentSystemsFirms = response.message;
        this.loadingSpinner.hide();
      } else {
        Swal.fire({
          ...this.swalOptions.error,
          text: 'Ödeme Firma bilgileri alınamadı'
        });
      }
    }, error => {
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Ödeme Firma bilgileri alınamadı'
      });
    });
  }

  searchPaymentSystems(term: string, item: any) {
    return item.name.toLocaleLowerCase().trim().includes(term.toLocaleLowerCase().trim());
  }

}

export class PaymentTransactionInfoIyzico {
  status: string;
  locale: string;
  systemTime: number;
  conversationId: string;
  price: number;
  paidPrice: number;
  installment: number;
  paymentId: string;
  fraudStatus: number;
  merchantCommissionRate: number;
  merchantCommissionRateAmount: number;
  iyziCommissionRateAmount: number;
  iyziCommissionFee: number;
  cardType: string;
  cardAssociation: string;
  cardFamily: string;
  binNumber: string;
  lastFourDigits: string;
  basketId: string;
  currency: string;
  itemTransactions: [
            {
                itemId: string;
                paymentTransactionId: string;
                transactionStatus: number;
                price: number;
                paidPrice: number;
                merchantCommissionRate: number;
                merchantCommissionRateAmount: number;
                iyziCommissionRateAmount: number;
                iyziCommissionFee: number;
                blockageRate: number;
                blockageRateAmountMerchant: number;
                blockageRateAmountSubMerchant: number;
                blockageResolvedDate: string;
                subMerchantPrice: number;
                subMerchantPayoutRate: number;
                subMerchantPayoutAmount: number;
                merchantPayoutAmount: number;
                convertedPayout: {
                    paidPrice: number;
                    iyziCommissionRateAmount: number;
                    iyziCommissionFee: number;
                    blockageRateAmountMerchant: number;
                    blockageRateAmountSubMerchant: number;
                    subMerchantPayoutAmount: number;
                    merchantPayoutAmount: number;
                    iyziConversionRate: number;
                    iyziConversionRateAmount: number;
                    currency: string;
                }
            }
        ];
        authCode: string;
        phase: string;
        hostReference: string;
        paymentStatus: string;
}
export class PaymentTransactionInfoParatika {
  responseCode: string;
  responseMsg: string;
  transactionCount: string;
  totalTransactionCount: string;
  transactionList: [
      {
          pgTranTraceAudit: string;
          pgTranReturnCode: string;
          pgOrderId: string;
          pgTranApprCode: string;
          pgTranId: string;
          pgTranRefId: string;
          timePsSent: string;
          timePsReceived: string;
          timeCreated: string;
          amount: number;
          transactionStatus: string;
          currency: string;
          paymentSystem: string;
          panLast4: string;
          transactionType: string;
          installmentCount: number;
          cardOwnerMasked: string;
          customerId: string;
          bin: string;
      }
  ]

}
export class PaymentTransactionInfoZiraat {
  CC5Response: {
    ErrMsg: string[],
    ProcReturnCode: string[],
    Response: string[],
    OrderId: string[],
    TransId: string[],
    Extra: [
        {
            AUTH_CODE: string[],
            AUTH_DTTM: string[],
            CAPTURE_AMT: string[],
            CAPTURE_DTTM: string[],
            CAVV_3D: string[],
            CHARGE_TYPE_CD: string[],
            ECI_3D: string[],
            HOSTDATE: string[],
            HOST_REF_NUM: string[],
            MDSTATUS: string[],
            NUMCODE: string[],
            ORDERSTATUS: string[],
            ORD_ID: string[],
            ORIG_TRANS_AMT: string[],
            PAN: string[],
            PROC_RET_CD: string[],
            SETTLEID: string[],
            TRANS_ID: string[],
            TRANS_STAT: string[],
            XID_3D: string[],
        }
    ]
}

}
export class PaymentTransactionInfoPaynet {
  Data: [
    {
        xact_id: string;
        xact_date: string;
        transaction_type: number;
        pos_type: number;
        agent_id: string;
        is_tds: boolean,
        bank_id: string;
        bank_name: string;
        instalment: number;
        card_no: string;
        card_brand_name: string;
        card_holder: string;
        card_type: string;
        ratio: number;
        end_user_ratio: number;
        amount: number;
        netAmount: number;
        comission: number;
        end_user_comission: number;
        comission_tax: number;
        currency: string,
        authorization_code: string;
        reference_code: string;
        order_id: string;
        is_succeed: boolean,
        reversed: boolean,
        reversed_xact_id: string;
        reversal_available: boolean,
        reversal_remain_amount: number;
        xact_transaction_id: string;
        email: string;
        phone: string;
        note: string;
        agent_reference: string;
        company_amount: number;
        company_commission: number;
        company_commission_with_tax: number;
        company_net_amount: number;
        agent_amount: number;
        agent_commission: number;
        agent_commission_with_tax: number;
        agent_net_amount: number;
        company_cost_ratio: number;
        company_pay_ratio: number;
        company_vdate: string;
        agent_vdate: string;
        xact_type_desc: string;
        payment_string: string;
        pos_type_desc: string;
        agent_name: string;
        company_name: string;
        instalment_text: string;
        ipaddress: string;
        client_id: string;
        ratio_code: string;
        ratio_code_method: string;
        domain: string;
        user_name: string;
        company_code: string;
        id: number;
        channel_id: number;
        short_name: string;
        is_from_mail_order: boolean,
        mail_order_user_name: string;
        api_file: string;
        campaign_url: string;
        campaign_txt: string;
        cancel_status: boolean,
        plus_installment: number;
        object_name: string;
        code: number;
        message: string;
    }
  ];
  object_name: string;
  code: number;
  message: string;
}

export class PaymentTransactionInfoVerifone {

}

export class PaymentProductInfo{
  productName: string;
  price: number;
  image: string;
  spiralNumber: number;
  createdAt: string;
}
export class PaymentParameters{
  paymentCompleted: boolean;
  isDelivered: boolean;
  isApproved: boolean;
  sensorState: number;
  canceled: boolean;
}
export class PaymentAutomatInfo{
  automatName: string;
  automatModel: string;
  location: string;
  automatGroup: string;
  idNo: number;
  image: string;
}


export interface FormValue {
  paymentSystemsFirmMerchant: string;
  paymentSystemsFirmMerchantApi: string;
  paymentSystemsFirmMerchantPassword: string;
}
