import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SwalHelper} from '../../_helpers/swal.helper';
import {StaffService} from '../../_services/staff.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import {QrCodeService} from '../../_services/qr-code.service';

@Component({
    selector: 'app-qr-codes-sub-users',
    templateUrl: './qr-codes-sub-users.component.html',
    styleUrls: ['./qr-codes-sub-users.component.css']
})
export class QrCodesSubUsersComponent implements OnInit, AfterViewInit {


    public subFirmUser: any = {};
    public subFirmUsers: Array<any>;
    currentUser = JSON.parse(localStorage.getItem('currentUser'));

    automatGroupData: any[] = [];
    selectedGroup;
    loadingAutomatGroup: boolean;

    //Ngx-spinner değişkenleri (Loading)
    public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
    public loadingSpinnerSize = environment.loadingSpinnerSize;
    public loadingSpinnerColor = environment.loadingSpinnerColor;
    public loadingSpinnerType = environment.loadingSpinnerType;
    public loadingSpinnerText = environment.loadingSpinnerText;
    //END : Ngx-spinner değişkenleri (Loading)

    @ViewChild('editModal', {static: false}) editModal: NgbModal;

    swalOptions = new SwalHelper();

    isEdit = false;

    constructor(
        private staffService: StaffService,
        private loadingSpinner: NgxSpinnerService,
        private qrCodeService: QrCodeService,
        private modalService: NgbModal) {
    }


    ngOnInit() {
        this.getFirmUsers();
    }

    ngAfterViewInit() {
    }

    selectQrAutomat(event) {
        this.subFirmUser.qrAutomatGroupId = event._id;
    }


    getFirmQrAutomatGroup() {
        this.qrCodeService.getFirmQrAutomatGroups().subscribe(response => {
            this.automatGroupData = response.result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

            if (this.isEdit) {
                this.selectedGroup = this.automatGroupData.find(item => item._id === this.subFirmUser.qrAutomatGroupId);
            }
        }, error => {
            console.log(error);

            if (error.status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
            }
        });
    }


    getFirmUsers() {
        this.staffService.getSubFirmUserList().subscribe(Response => {
            this.subFirmUsers = Response.data;
            this.loadingSpinner.hide();

        }, error => {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Kullanıcı listesi sunucudan getirilirken hata oluştu!'
            });
            this.loadingSpinner.hide();

        });
    }

    addFirmUser() {
        if (!this.checkIsFieldsOkay()) {
            return;
        }
        this.loadingSpinner.show();
        this.subFirmUser.firm = this.currentUser.user.firm._id;
        this.staffService.addSubFirmUser(this.subFirmUser).subscribe(Response => {
            this.loadingSpinner.hide();
            Swal.fire({
                ...this.swalOptions.success,
                text: 'Kullanıcı başarıyla oluşturuldu!'
            });
            this.modalService.dismissAll();
            this.getFirmUsers();

        }, error => {

            this.loadingSpinner.hide();
            if (error.status === 500) {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Firma Kod Adı Zaten Mevcut!'
                });
            } else {
                Swal.fire({
                    ...this.swalOptions.error,
                    text: 'Kullanıcı oluşturulamadı!'
                });
            }
        });
    }

    setSubFirmUser(subFirmUser) {
        this.subFirmUser = Object.assign({}, subFirmUser);
        this.getFirmQrAutomatGroup();
        this.isEdit = true;
        this.openModal();
    }

    deleteSubFirmUser(id, index) {

        Swal.fire({
            ...this.swalOptions.delete,
            text: 'Bu Kullanıcıyı silmek istediğinize emin misiniz?'
        }).then((result) => {
            if (result.value) {
                this.loadingSpinner.show();
                this.staffService.deleteSubFirmUser(id).subscribe(res => {
                    if (res.success) {
                        this.subFirmUsers.splice(index, 1);
                        Swal.fire({
                            ...this.swalOptions.success,
                            text: 'Kullanıcı başarıyla silindi!'
                        });
                    } else {
                        Swal.fire({
                            ...this.swalOptions.error,
                            text: 'Kullanıcı silme işlemi başarısız!'
                        });
                    }
                    this.loadingSpinner.hide();
                }, err => {
                    Swal.fire({
                        ...this.swalOptions.error,
                        text: 'Kullanıcı silme işlemi başarısız!'
                    });
                    this.loadingSpinner.hide();
                });
            }

        });

    }

    clearSubFirmUser() {
        this.subFirmUser = {};
    }

    openModal() {
        const modalRef = this.modalService.open(this.editModal, {centered: true});
        modalRef.result.then(() => {
            this.resetModal();
        }, () => {
            this.resetModal();
        });
    }

    openNewModal() {
        this.getFirmQrAutomatGroup();
        this.isEdit = false;
        this.clearSubFirmUser();
        this.openModal();
    }

    resetModal() {
        this.clearSubFirmUser();
    }

    updateSubFirmUser() {
        if (!this.checkIsFieldsOkay()) {
            return;
        }

        if (!this.subFirmUser.qrAutomatGroupId) {
            this.subFirmUser.qrAutomatGroupId = null;
        }
        this.loadingSpinner.show();
        this.staffService.updateSubFirmUser(this.subFirmUser, this.subFirmUser._id).subscribe(Response => {

            this.loadingSpinner.hide();
            Swal.fire({
                ...this.swalOptions.success,
                text: 'Kullanıcı başarıyla güncellendi!'
            });
            this.modalService.dismissAll();
            this.getFirmUsers();

        }, error => {
            this.loadingSpinner.hide();
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Kullanıcı güncellenemedi!'
            });
        });
    }

    checkIsFieldsOkay(): boolean {
        let isOkay = true;
        if (this.subFirmUser.name === '' || this.subFirmUser.name === undefined) {
            isOkay = false;
        }
        if (this.subFirmUser.username === '' || this.subFirmUser.username === undefined) {
            isOkay = false;
        }
        if (!this.isEdit && (this.subFirmUser.password === '' || this.subFirmUser.password === undefined)) {
            isOkay = false;
        }
        if (!isOkay) {
            Swal.fire({
                ...this.swalOptions.error,
                text: 'Lütfen tüm alanları doldurunuz!'
            });
            return false;
        } else {
            return true;
        }
    }

    randomPassword() {
        this.subFirmUser.password = Math.random().toString(36).slice(-8);
    }

    showPass() {
        const passVal: any = document.getElementById('subPass');
        console.log(passVal);
        if (passVal.type === 'password') {
            passVal.type = 'text';
        } else {
            passVal.type = 'password';
        }
    }

}
