<div class="modal-header">
  <h4 class="modal-title">{{updateQr? 'Qr Güncelle' : 'Qr Oluştur'}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="container">
      <div class="row mt-3">
        <div class="col-6">
          <button class="btn-primary btn" (click)="d2.toggle()">Son Geçerlilik Tarihi</button>
          <div class="input-group">
            <input
                    class="form-control d-none"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    [(ngModel)]="showExpiredDate"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                    placement="bottom-left"

            />
            <div class="col pt-2" *ngIf="showExpiredDate">
              SKT : {{ showExpiredDate?.day?.toString()}} / {{ showExpiredDate?.month?.toString()}} / {{showExpiredDate?.year?.toString()}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group col" *ngIf="!updateQr">
            <input class="form-control no-border metin-girisi text-center" [(ngModel)]="qrCode.count" #name="ngModel"
                   type="number" min="0" name="count" placeholder="Qr Adet">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <ng-select class="custom"
                     [items]="_groupData"
                     [multiple]="false"
                     appendTo="body"
                     [(ngModel)]="selectedGroup"
                     [virtualScroll]="true"
                     placeholder="QR Otomat Grubu Seçiniz"
                     [loading]="loading"
                     bindLabel="name" bindValue="" name="qrAutoamtGroup">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-6">
          <ng-select class="custom"
                     [items]="qrTypes"
                     [multiple]="false"
                     appendTo="body"
                     [(ngModel)]="selectedQrType"
                     [virtualScroll]="true"
                     placeholder="QR Türü Seçiniz"
                     [loading]="loading"
                     (change)="selectType()"
                     bindLabel="name" bindValue="" name="evendProduct">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mt-3" *ngIf="showProductType">
        <div class="col" >
          <ng-select
            class="custom"
            #brandSelect
            [items]="_brands"
            [(ngModel)]="_brand"
            [virtualScroll]="true"
            placeholder="Markalar"
            [loading]="loading"
            (scrollToEnd)="onScrollBrand()"
            (change)="onSelectUpdate(true, 'brand')"
            (keyup)="onSelectUpdate(true, 'brand')"
            (clear)="onClear()"
            name="brand">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>

        </div>
        <div class="col" >
          <ng-select
            class="custom"
            #categorySelect
            [items]="_categories"
            [(ngModel)]="_category"
            [virtualScroll]="true"
            placeholder="Kategoriler"
            [loading]="loading"
            (scrollToEnd)="onScrollCategory()"
            (change)="onSelectUpdate(true, 'category')"
            (keyup)="onSelectUpdate(true, 'category')"
            (clear)="onClear()"
            name="categories">
            <ng-template ng-label-tmp let-item="item">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>

        </div>
      </div>
      <div class="row mt-3" *ngIf="showProductType">
        <div class="col" id="evendSel">

          <ng-select
            [items]="evendProducts"
            [(ngModel)]="qrCode.evendProduct"
            [virtualScroll]="true"
            placeholder="Ürün seçiniz"
            [loading]="loading"
            (scrollToEnd)="onScroll()"
            #inputstring
            (keyup)="search(inputstring)"
            bindLabel="name" bindValue="" name="evendProduct">
            <ng-template ng-label-tmp let-item="item">
              <img *ngIf="item && item.image" [src]="item.image | image: ProductImagePath" height="50" width="50"
                   default="../../../assets/img/gorsel_yok.svg">
              <span class="align-self-center">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <img *ngIf="item && item.image" [src]="item.image | image: ProductImagePath" height="50" width="50"
                   default="../../../assets/img/gorsel_yok.svg">
              {{item.name}}
            </ng-template>
          </ng-select>

        </div>
      </div>

      <div class="row mt-3" *ngIf="!showProductType">
        <div class="input-group col">

        </div>

        <div class="input-group col">
          <input class="form-control no-border metin-girisi text-center" [(ngModel)]="qrCode.price" #name="ngModel"
                 type="number" min="0" name="unitPrice" placeholder="Qr Fiyat 0.00">
          <div class="input-group-append">
            <span class="input-group-text no-border metin-girisi">₺</span>
          </div>
          <!-- <i class="fa fa-info-circle mt-3" data-toggle="tooltip" data-placement="right" title="Opsiyonal"></i> -->
        </div>
      </div>

    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="col"></div>
  <button class="btn btn-primary" (click)="generateQr()">{{selectedQrType.name}} Qr Oluştur</button>
  <button class="btn btn-outline-primary" (click)="activeModal.dismiss()">İptal</button>
</div>


