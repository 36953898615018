import {SalesService} from '../_services/sales.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SalesHelper {

  today;
  thisWeek;
  thisMonth;

  constructor(private salesService: SalesService) {
  }

  // timeSpan = 1 gün - 2 hafta - 3 ay - 4 özel

  /*customDates verildiğinde fallback olarak görev yapar*/
  getSales(automats: Array<string>, timeSpan: number, customDates?: { start: Moment, end: Moment }): Observable<any> {
    switch (timeSpan) {
      case 1:
        this.today = moment().startOf('day').toISOString(true);
        return this.salesService.salesHourly(automats);
      case 2:
        this.thisWeek = moment().startOf('week').toISOString(true);
        return this.salesService.salesDaily(automats, this.thisWeek, moment().toISOString(true), 'week');
      case 3:
        this.thisMonth = moment().startOf('month').toISOString(true);
        return this.salesService.salesDaily(automats, this.thisMonth, moment().toISOString(true), 'month');
      case 5:
        this.thisMonth = moment().startOf('month').toISOString(true);
        return this.salesService.salesDaily(automats, this.thisMonth, moment().toISOString(true), 'lastMonth');
      default:
        return this.salesService.salesDaily(automats, customDates.start.toISOString(true), customDates.end.toISOString(true), 'custom');

    }

  }

  getPaymentTypes(automats: Array<string>, timeSpan: number, customDates?: { start: Moment, end: Moment }): Observable<any> {
    switch (timeSpan) {
      case 1:
        this.today = moment().startOf('day').toISOString(true);
        return this.salesService.paymentTypesHourly(automats);
      case 2:
        this.thisWeek = moment().startOf('week').toISOString(true);
        return this.salesService.paymentTypesDaily(automats, this.thisWeek, moment().toISOString(true), 'week');
      case 3:
        this.thisMonth = moment().startOf('month').toISOString(true);
        return this.salesService.paymentTypesDaily(automats, this.thisMonth, moment().toISOString(true), 'month');
      default:
        return this.salesService.paymentTypesDaily(automats, customDates.start.toISOString(true), customDates.end.toISOString(true), 'custom');
    }

  }

  getProductSales(automats: Array<string>, timeSpan: number, customDates?: { start: Moment, end: Moment }): Observable<any> {
    switch (timeSpan) {
      case 1:
        this.today = moment().startOf('day').toISOString(true);
        return this.salesService.salesProducts(automats, this.today, moment().toISOString(true));
      case 2:
        this.thisWeek = moment().startOf('week').toISOString(true);
        return this.salesService.salesProducts(automats, this.thisWeek, moment().toISOString(true));
      case 3:
        this.thisMonth = moment().startOf('month').toISOString(true);
        return this.salesService.salesProducts(automats, this.thisMonth, moment().toISOString(true));
      default:
        return this.salesService.salesProducts(automats, customDates.start.toISOString(true), customDates.end.toISOString(true));
    }

  }


}
