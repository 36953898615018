import {FormTemplate} from './formTemplate';
import {PaymentSystems} from './paymentSystems';

export class PaymentSystemsParatika implements PaymentSystems {
  constructor(jsonObj?) {
    if (jsonObj) {
      for (const prop in jsonObj) {
        this[prop] = jsonObj[prop];
      }
    }

  }
  _id: string;
  status;
  paymentSystemsFirmId = '';
  paymentSystemsFirmName = '';
  paymentSystemsFirmCode = 1;

  paymentSystemsFirmMerchant = '';
  paymentSystemsFirmMerchantApi = '';
  paymentSystemsFirmMerchantPassword = '';


  getFormTemplate(): Array<FormTemplate<string>> {
    const template: Array<FormTemplate<string>> = [
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'paymentSystemsFirmMerchant',
        label: 'Merchant',
        value: this.paymentSystemsFirmMerchant,
        required: true
      }),
      new FormTemplate({
        controlType: 'input',
        type: 'text',
        key: 'paymentSystemsFirmMerchantApi',
        label: 'Merchant Api',
        value: this.paymentSystemsFirmMerchantApi,
        required: true
      }),
      new FormTemplate({
        controlType: 'password',
        type: 'password',
        key: 'paymentSystemsFirmMerchantPassword',
        label: 'Merchant Password',
        value: this.paymentSystemsFirmMerchantPassword,
        required: true
      })
    ];

    return template;
  }

}

