import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


import {environment} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineUserGroupService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });


  private idWatchSource = new BehaviorSubject<object>({id: ''});
  Id = this.idWatchSource.asObservable();

  changeId(Id: object) {
    this.idWatchSource.next(Id);
  }

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getOfflineUserGroupByPeriod(id): Observable<any> {
    return this.http.get(environment.apiBaseUrl + 'offline-user-card-group-period/' + id,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
  }

}
