import {Component, Input} from '@angular/core';
import {FormTemplate} from '../../../_models/formTemplate';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-dynamic-form-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent {

  @Input() question: FormTemplate<string>;
  @Input() form: FormGroup;

  get isInvalid() {
    const cnt = this.form.controls[this.question.key];
    return cnt.invalid && (cnt.dirty || cnt.touched);
  }


  switch(question: FormTemplate<string>) {
    question.type = question.type === 'text' ? 'password' : 'text';
  }

}
