import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getAllCities(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-all-cities',
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getDistrictsByCity(key: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-district-by-city-key/' + key,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getNeighborhoodsByDistrict(key: number): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl + 'get-neighborhood-by-district-key/' + key,
      {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

}
