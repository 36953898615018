<div class="col-md-12 m-3">
  <div class="container">
    <div class="row">
      <button *ngFor="let type of notificationTypes" class="btn btn-outline-primary m-1" [ngStyle]="{'opacity': this.listfilter.includes(type.value) ? '1' : '0.5'}"
              (click)="listFilterChange(type.value)">{{type.name}}</button>
    </div>

    <!-- Bildirim Listesi -->
    <div class="row">
      <div class="list-group m-2 container">
        <div *ngFor="let notification of Notifications; let ind = index">
          <div *ngIf="listfilter.includes(notification.type)" class="list-group-item m-1 container">
            <div class="row align-items-center">
              <div class="col-md-1">
                <img class="pointer" src="../../assets/img/bell.svg" (click)="markNotification(notification,false)"
                     [ngStyle]="{'background-color': notification.type, 'padding': '20%', 'border-radius': '50%'}"
                     height="50px" width="50px">
              </div>
              <div class="col-md-2">

                <ng-container *ngIf="DiffTimes[ind].minute < 60;else second">
                  <span [ngStyle]="{'color' : notification.isRead ? '#7b7b7b' : 'black'}">{{DiffTimes[ind].minute}}
                    dakika önce</span>
                </ng-container>
                <ng-template #second>
                  <ng-container *ngIf="DiffTimes[ind].hour < 24;else third">
                    <span [ngStyle]="{'color' : notification.isRead ? '#7b7b7b' : 'black'}">{{DiffTimes[ind].hour}} saat önce</span>
                  </ng-container>
                </ng-template>
                <ng-template #third>
                  <span [ngStyle]="{'color' : notification.isRead ? '#7b7b7b' : 'black'}">{{DiffTimes[ind].day}} gün önce</span>
                </ng-template>

              </div>
              <div class="col-md-7 pointer" (click)="navigateNotification(notification)">
              <span [ngStyle]="{'color' : notification.isRead ? '#7b7b7b' : 'black'}">
                {{notification.message}}
              </span>
              </div>
              <div class="col-md-2 container">
                <div class="row float-right pointer">
                  <img class="m-2" src="../../assets/img/cancel.svg" (click)="deleteNotification(notification,ind)"
                       [ngStyle]="{'cursor': 'pointer'}">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Bildirim Listesi SON-->

  </div>
</div>
