import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CashlessService} from '../_services/cashless.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {Moment} from 'moment';
import {OfflineUserService} from '../_services/offline-user.service';
import {OfflineUserGroupService} from '../_services/offline-user-group.service';
import {ExportsHelperService} from '../_helpers/exports.helper';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {SwalHelper} from '../_helpers/swal.helper';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-offline-card-group-period',
  templateUrl: './offline-card-group-period.component.html',
  styleUrls: ['./offline-card-group-period.component.css']
})
export class OfflineCardGroupPeriodComponent implements OnInit {

  swalOptions = new SwalHelper();
  isDownloadReady = false;
  @ViewChild('showOfflineUserTransaction', {static: false}) showOfflineUserTransaction: ElementRef;
  ProductImagePath: string;
  offlineUserGroupId: string;
  offlineUserGroupData: any;
  offlineUserTransactions = [];
  offlineUsers = [];
  page = 1;
  pages = 1;
  totalLoadedAmount = 0;
  totalAmountSpent = 0;
  totalBalanceBroughtForward = 0;
  periodStartDate;
  periodEndDate;
  hasStartedPeriod = true;
  selectedOfflineUser: any;
  selectedOfflineUserIndex: any;
  selectedOfflineUserTransactions = [];

  customSelectedStartDate: Moment;
  customSelectedEndDate: Moment;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  // Ngx-spinner değişkenleri (Loading)
  public loadingSpinnerBdColor = environment.loadingSpinnerBdColor;
  public loadingSpinnerSize = environment.loadingSpinnerSize;
  public loadingSpinnerColor = environment.loadingSpinnerColor;
  public loadingSpinnerType = environment.loadingSpinnerType;
  public loadingSpinnerText = environment.loadingSpinnerText;
  // END : Ngx-spinner değişkenleri (Loading)


  public tableHeaders = [];

  constructor(private cashlessService: CashlessService,
              private offlineUserService: OfflineUserService,
              private offlineUserGroupService: OfflineUserGroupService,
              private router: Router,
              private loadingSpinner: NgxSpinnerService,
              private exportsHelper: ExportsHelperService,
              private calendar: NgbCalendar,
              public formatter: NgbDateParserFormatter) {
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 10);
    this.toDate = calendar.getToday();
  }

  ngOnInit() {
    this.ProductImagePath = environment.ProductImagePath;
    this.loadingSpinner.show();
    this.offlineUserGroupId = localStorage.getItem('offlineUserGroupId');
    if (this.offlineUserGroupId === null || this.offlineUserGroupId === '' || this.offlineUserGroupId === undefined) {
      this.router.navigate(['/']);
    } else {
      this.tableHeaders = [
        {id: 1, name: 'Ad Soyad', selected: true},
        {id: 2, name: 'Kart Bilgisi', selected: true},
        {id: 14, name: 'Kart Bilgisi 2', selected: true},
        {id: 3, name: 'Kart Durumu', selected: true},
        {id: 4, name: 'Sicil No', selected: true},
        {id: 5, name: 'Görev', selected: true},
        {id: 6, name: 'Birim', selected: true},
        {id: 7, name: 'Açıklama 1', selected: true},
        {id: 8, name: 'Açıklama 2', selected: false},
        {id: 9, name: 'Açıklama 3', selected: false},
        {id: 10, name: 'Yüklenen', selected: true},
        {id: 11, name: 'Devreden', selected: true},
        {id: 12, name: 'Harcanan', selected: true},
        {id: 13, name: 'Bakiye', selected: true},
      ];
      this.getOfflineUserGroupByPeriod();
    }
  }

  showUserTransactionModal(offlineUser, index) {
    this.selectedOfflineUser = offlineUser;
    this.selectedOfflineUserIndex = index;
    this.showOfflineUserTransaction.nativeElement.click();
    this.getOfflineUserTransaction();
  }

  getOfflineUser() {
    this.loadingSpinner.show();
    this.offlineUserService.getOfflineUser(this.selectedOfflineUser._id).subscribe(res => {
      if (res === null) {
        Swal.fire({
          ...this.swalOptions.info,
          text: 'Kart kullanıcı bilgisi getirilemedi!'
        });
      } else {
        this.selectedOfflineUser.firmBalance = res.firmBalance;
        this.offlineUsers[this.selectedOfflineUserIndex] = this.selectedOfflineUser;
      }
      this.loadingSpinner.hide();
    }, error => {
      this.loadingSpinner.hide();
      Swal.fire({
        ...this.swalOptions.error,
        text: 'Kart kullanıcı bilgisi sunucudan getirilemedi!'
      });
    });
  }

  updateColumn(val) {
    const index = this.tableHeaders.findIndex(function (item, i) {
      return item.id === val;
    });
    if (index >= 0) {
      this.tableHeaders[index].selected = !this.tableHeaders[index].selected;
    }

  }

  downloadExcel() {
    this.loadingSpinner.show();
    this.offlineUserService
      .getOfflineUserTransactionsByGroupId(this.offlineUserGroupId)
      .subscribe(res => {
          this.offlineUserTransactions = res.result;
          if (this.offlineUsers.length === 0) {
            Swal.fire({
              ...this.swalOptions.info,
              text: 'Tablo içerisnde veri yok!'
            });
            return;
          } else {
            const anyShowColumn = this.tableHeaders.some(x => x.selected === true);
            if (!anyShowColumn) {
              Swal.fire({
                ...this.swalOptions.info,
                text: 'Gösterilecek alanları(sütünları) seçmelisiniz!'
              });
              return;
            } else {
              this.exportsHelper.offlineCardPeriodDownloadExcel(
                this.offlineUsers,
                this.tableHeaders,
                moment(this.periodStartDate).format('YYYY-MM-DD'),
                moment(this.periodEndDate).format('YYYY-MM-DD'),
                this.offlineUserTransactions
              );


            }
          }
          this.loadingSpinner.hide();
        },
        error => {

          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'İşlem Geçmişleri getirilemedi!'
          });
        });
  }

  getOfflineUserGroupByPeriod() {
    if (this.offlineUserGroupId.length > 0) {
      this.loadingSpinner.show();
      this.offlineUserGroupService.getOfflineUserGroupByPeriod(this.offlineUserGroupId).subscribe(res => {
        this.offlineUsers = res.result;
        this.offlineUserGroupData = res.offlineUserGroup;
        for (let i = 0; i < this.offlineUsers.length; i++) {
          this.totalAmountSpent += this.calOfflineUserTransactionData(this.offlineUsers[i].transaction_data, 2);
          this.totalBalanceBroughtForward += this.calOfflineUserTransactionData(this.offlineUsers[i].transaction_data, 10);
          this.totalLoadedAmount += this.calOfflineUserTransactionData(this.offlineUsers[i].transaction_data, 6);
        }
        this.periodStartDate = res.startDate;
        this.periodEndDate = res.endDate;
        this.checkPeriodStarted(this.periodStartDate);
        this.loadingSpinner.hide();
        this.isDownloadReady = true;
      }, error => {

        this.loadingSpinner.hide();
        Swal.fire({
          ...this.swalOptions.info,
          text: error.message
        });
      });
    }
  }

  setCustomDate() {
    if (this.fromDate === null || this.toDate === null) {
      this.customSelectedStartDate = null;
      this.customSelectedEndDate = null;
      return;
    }
    this.customSelectedStartDate = moment(this.formatter.format(this.fromDate));
    this.customSelectedEndDate = moment(this.formatter.format(this.toDate));
    if (this.fromDate.equals(this.toDate)) {
      this.customSelectedEndDate.set({hour: 23, minute: 59, second: 59, millisecond: 999});
    }
  }

  getOfflineUserTransaction() {
    this.loadingSpinner.show();
    this.page = 1;
    this.setCustomDate();
    this.cashlessService.getOfflineUserTransactions(this.page,
      this.selectedOfflineUser._id,
      this.customSelectedStartDate.toDate(),
      this.customSelectedEndDate.toDate())
      .subscribe(res => {
          this.selectedOfflineUserTransactions = res.docs;
          this.dateSorting();
          this.getOfflineUser();
          this.pages = res.pages;
          $('.transaction-list').scrollTop(0);
          this.loadingSpinner.hide();
        },
        error => {
          this.loadingSpinner.hide();
          Swal.fire({
            ...this.swalOptions.error,
            text: 'İşlem geçmişi alınamadı!'
          });
        });
  }

  getOfflineUserTransactionDateFilter() {
    if (this.fromDate === null || this.toDate === null) {
      return 0;
    }
    this.page = 1;
    this.setCustomDate();
    this.cashlessService.getOfflineUserTransactions(this.page, this.selectedOfflineUser._id,
      this.customSelectedStartDate.toDate(), this.customSelectedEndDate.toDate())
      .subscribe(res => {
        this.selectedOfflineUserTransactions = res.docs;
        this.pages = res.pages;
        this.dateSorting();
        this.loadingSpinner.hide();
      });
  }

  getTransactionDate(transaction) {
    return (transaction.saleDate === null || transaction.saleDate === undefined) ? transaction.createdAt : transaction.saleDate;
  }

  checkPeriodStarted(startDate) {
    const periodStartedDate = new Date(startDate).valueOf();
    const diffTime = periodStartedDate - Date.now();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 0) {
      this.hasStartedPeriod = false;
      Swal.fire({
        ...this.swalOptions.info,
        text: 'Periyot henüz başlamadı!.'
      });
    } else {
      this.hasStartedPeriod = true;
    }

  }

  calOfflineUserTransactionData(transaction_data, processType: number) {
    if (transaction_data.length > 0) {
      return transaction_data.filter(x => x.processType === processType)
        .map(x => x.total)
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    } else {
      return 0;
    }
  }

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

  round(value, decimals) {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }

  isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) {
      return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
      return false;
    }
    if (obj.length === 0) {
      return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') {
      return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
      if (this.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return true;
  }

  onScroll() {
    if (this.page + 1 > this.pages) {
      return;
    }
    // $('#page-loader').fadeIn();
    this.page += 1;
    this.cashlessService.getOfflineUserTransactions(this.page, this.selectedOfflineUser._id,
      this.customSelectedStartDate.toDate(), this.customSelectedEndDate.toDate())
      .subscribe(res => {
        for (const item of res.docs) {
          this.selectedOfflineUserTransactions.push(item);
        }
        this.dateSorting();
        this.pages = res.pages;
      }, error => {
        // $('#page-loader').fadeOut();
        Swal.fire({
          ...this.swalOptions.error,
          text: 'İşlem geçmişinin devamı yüklenemedi!'
        });
      });
  }

  sortingByKey(array, key) {
    return array.sort(function (a, b) {
      const x = a[key];
      const y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  dateSorting() {
    this.selectedOfflineUserTransactions.sort(function (a, b) {
      const aDate = new Date((a.saleDate === null || a.saleDate === undefined) ? a.createdAt : a.saleDate);
      const bDate = new Date((b.saleDate === null || b.saleDate === undefined) ? b.createdAt : b.saleDate);
      return bDate.valueOf() - aDate.valueOf();
    });
  }

  getTransactionProcessTypeText(type) {
    switch (type) {
      case 0:
        return 'İade';
      case 1:
        return 'Manuel Yükleme';
      case 2:
        return 'Harcama';
      case 3:
        return 'Sıfırlama';
      case 4:
        return 'Toplu Yükleme';
      case 5:
        return 'Toplu Sıfırlama';
      case 6:
        return 'Periyodik Yükleme';
      case 7:
        return 'Periyodik Sıfırlama';
      case 10:
        return 'Devreden';
      case 11:
        return 'Düşüm';
      case 12:
        return 'Revalue';
      default:
        return '-';

    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.getOfflineUserTransactionDateFilter();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}

