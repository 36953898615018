import {BehaviorSubject, Observable, throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';


import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class BeaconService {

  private messageSource = new BehaviorSubject<string>('5ab2192ea5ed0569a169ffe3');
  automatId = this.messageSource.asObservable();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.authenticationService.token
  });

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  getFirmBeacons(): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + 'firm-beacons', {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

  getFirmBeaconsForAutomatFormPopup(automatId?:string): Observable<any> {
    /*eslint-disable */
    return this.http.get(environment.apiBaseUrl
      + `firm-beacons-from-create-automat-form/${automatId}`, {headers: this.headers}).pipe(
      // ...errors if any
      catchError((error: any) => observableThrowError(error.error || 'Server error')));
    /*eslint-enable */
  }

}
